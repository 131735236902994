import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';

import ReceiptIcon from '@mui/icons-material/Receipt';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';

import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import Moment from 'moment';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import routes from 'routes';
import { RootState } from 'store';
import { campaignActions } from 'store/entities/Campaigns';
import { MembersActions } from 'store/entities/Members';

import MatButton from 'components/Elements/MatButton/MatButton';
import { PremiumEmulator } from 'components/Emulators';
import RunAgainDrawer from 'components/RunAgainDrawer';
import HomeContainer from 'components/ui/HomeContainer';
import Scaffold from 'components/ui/Scaffold';

import Map from './components/Map';
import MetricsContainer from './components/Metrics/MetricsContainer';
import TeamMemberTable from './components/TeamMemberTable';
import TerminateDrawer from './components/TerminateDrawer';
import { reportActions } from 'store/entities/Reports';
import {
  CircularProgress,
  Collapse,
  LinearProgress,
  createTheme,
} from '@mui/material';
import TeamsTable from './components/TeamTable';
import CardPreview from 'components/CardPreview/CardPreview';
import { teamsSelectors } from 'store/entities/Teams';
import useTappShareCookie from 'store/utils/tappshareCookie';
import { couponActions } from 'store/entities/Coupon';
import ComposeEmail from './components/ComposeEmail';
import GenerateReport from './components/GenerateReport';
import { TeamHubActions } from 'store/entities/TeamHub';

interface DetailsButtonProps {
  display: string;
  onClick: () => void;
}
const ReportButton = styled(Button)<ButtonProps>(() => ({
  color: '#00CA51',
  textTransform: 'capitalize',
  boxShadow: 'none',
  backgroundColor: 'rgba(0, 202, 81, 0.15)',
  '&:hover': {
    backgroundColor: 'rgba(0, 202, 81, 0.15)',
  },
  borderRadius: 14,
  paddingY: 1,
  fontWeight: 600,
}));

const GenerateReportButton: React.FC<DetailsButtonProps> = ({
  display,
  onClick,
}) => (
  <ReportButton
    sx={{ fontSize: '15px', marginRight: '16px' }}
    variant="contained"
    onClick={onClick}
    endIcon={<ReceiptIcon fontSize="medium" />}
  >
    {display}
  </ReportButton>
);

const EditButton = styled(Button)<ButtonProps>(() => ({
  color: 'rgba(0, 122, 255, 1)',
  textTransform: 'capitalize',
  boxShadow: 'none',
  backgroundColor: 'rgba(0, 122, 255, 0.15)',
  '&:hover': {
    backgroundColor: 'rgba(0, 122, 255, 0.15)',
  },
  borderRadius: 14,
  paddingY: 1,
  fontWeight: 600,
}));

const EditCardButton: React.FC<DetailsButtonProps> = ({ display, onClick }) => (
  <EditButton
    sx={{ fontSize: '16px', marginRight: '16px' }}
    variant="contained"
    onClick={onClick}
    endIcon={<ModeEditIcon fontSize="medium" />}
  >
    {display}
  </EditButton>
);

const TerminateButton = styled(Button)<ButtonProps>(() => ({
  color: 'rgba(255, 72, 72, 1)',
  textTransform: 'capitalize',
  boxShadow: 'none',
  backgroundColor: 'rgba(255, 72, 72, 0.15)',
  '&:hover': {
    backgroundColor: 'rgba(255, 72, 72, 0.15)',
  },
  borderRadius: 14,
  paddingY: 1,
  fontWeight: 600,
}));

const TerminateCardButton: React.FC<DetailsButtonProps> = ({
  display,
  onClick,
}) => (
  <TerminateButton
    sx={{ fontSize: '16px' }}
    variant="contained"
    onClick={onClick}
    endIcon={<DeleteIcon fontSize="medium" />}
  >
    {display}
  </TerminateButton>
);

const CardDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const historyLocation: any = useLocation();
  const { campaignId } = useParams();
  const [fullCampaign, setFullCampaign] = useState<any>(null);
  const [showRunAgain, setShowRunAgain] = useState(false);
  const [showTerminate, setShowTerminate] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const cookie = useTappShareCookie();

  const { campaign, breakDownData } = useSelector(
    (state: RootState) => state.campaigns,
  );

  const theme = createTheme();

  useEffect(() => {
    if (fullCampaign !== campaign) {
      setLoading(false);
      setFullCampaign(campaign);
    }
  }, [campaign]);

  useEffect(() => {
    if (campaignId) {
      setLoading(true);
      dispatch(campaignActions.getCampaign({ campaignId }));
      dispatch(campaignActions.getBreakDown({ campaignId }));
      dispatch(MembersActions.fetchMembers({}));
      dispatch(campaignActions.clearStatus());
      dispatch(campaignActions.getGenericLinks({ campaignId }));
      dispatch(MembersActions.getMemberByCampaign({ campaignId }));
      dispatch(reportActions.fetchCampaignMetrics({ campaignId }));
      dispatch(reportActions.clearStatus());
      dispatch(TeamHubActions.getTeamMemberStatus({ campaignId }));
    } else {
      navigate(routes.teamHub.main());
    }
  }, []);

  const handleActions = () => {
    if (cookie && campaignId) {
      dispatch(
        couponActions.setCardMetrics({ cardId: campaignId, cookieId: cookie }),
      );
    }
  };

  const redirectToCampaigns = () => {
    if (historyLocation?.state?.folderId) {
      navigate(routes.teamHub.folder(historyLocation?.state?.folderId));
    }
  };

  const checkValid = () => {
    if (fullCampaign && fullCampaign.terminated) {
      return false;
    } else if (
      fullCampaign &&
      Moment(fullCampaign.endDate).isBefore(Moment(), 'day')
    ) {
      return false;
    }
    return true;
  };

  if (!fullCampaign) {
    return <div />;
  }

  const onCampaignTerminated = () => {
    if (historyLocation?.state?.folderId) {
      navigate(routes.teamHub.folder(historyLocation?.state?.folderId));
    }
    const updatefullCampaign = fullCampaign;
    updatefullCampaign.terminated = true;
    setFullCampaign(updatefullCampaign);
  };

  const handleEdit = () => {
    if (historyLocation?.state?.folderId) {
      navigate(routes.teamHub.build(), {
        state: {
          id: fullCampaign.id,
          type: 'edit',
          folderId: historyLocation?.state?.folderId,
        },
      });
    }
  };

  const renderTags = () => {
    if (
      !fullCampaign ||
      !fullCampaign?.advanced?.tags ||
      !fullCampaign?.advanced?.tags?.length
    ) {
      return null;
    }

    return (
      <Card
        component="section"
        sx={{
          width: '300px',
          [theme.breakpoints.down('md')]: {
            width: '280px',
            marginX: 'auto',
          },
          marginTop: 3,
          borderRadius: '24px',
          padding: '16px',
          '@media max-width:1279.95px': {
            padding: '16px 0',
          },
        }}
      >
        <Typography variant="h6" fontWeight={600} sx={{ paddingBottom: 1 }}>
          {t('tags')}
        </Typography>
        <Box
          display="flex"
          flexWrap="wrap"
          sx={{
            '@media max-width:1279.95px': {
              padding: '0',
            },
          }}
        >
          {!!fullCampaign?.advanced?.tags.length &&
            fullCampaign?.advanced?.tags.map((tag) => (
              <Chip
                sx={{
                  margin: '4px 10px 4px 0',
                  borderRadius: '10px',
                  backgroundColor: '#D9EBFF',
                  color: '#007AFF',
                }}
                key={tag.objectId}
                label={tag.name}
              />
            ))}
        </Box>
      </Card>
    );
  };

  const renderEmulator = () => {
    if (!fullCampaign) {
      return <div />;
    }
    const condtionsConverter = new QuillDeltaToHtmlConverter(
      fullCampaign?.advanced?.conditions,
      {},
    );
    return (
      <>
        {fullCampaign?.data && (
          <CardPreview
            card={{
              ...fullCampaign,
              ...fullCampaign?.data[0]?.item?.info,
              title: fullCampaign?.title,
              action: {
                ...fullCampaign?.data[0].item.info.ctaOne,
                ...fullCampaign?.data[0].item.info.styling,
              },
              isCardDetails: true,
            }}
            folderId={historyLocation?.state?.folderId}
            handleClickTerminate={() => setShowTerminate(true)}
            handleActions={handleActions}
          />
        )}
        {renderTags()}
      </>
    );
  };

  return (
    <Scaffold
      HeaderProps={{
        title: !loading ? fullCampaign?.title : '',
        actions: [
          loading ? (
            <></>
          ) : checkValid() ? (
            <div style={{ display: 'flex' }}>
              {/* <div
                style={{
                  marginRight: 16,
                }}
              >
                <MatButton
                  variant="outlined"
                  size="big"
                  onClick={() => setShowTerminate(true)}
                >
                  {t('terminate')}
                </MatButton>
              </div> */}

              {fullCampaign.type !== 'legacy' && (
                <EditCardButton display={t('edit')} onClick={handleEdit} />
              )}
              <GenerateReportButton
                display="Generate Report"
                onClick={() => setReportOpen(true)}
              />
              <TerminateCardButton
                display={t('terminate')}
                onClick={() => setShowTerminate(true)}
              />
            </div>
          ) : (
            <MatButton
              size="big"
              variant="contained"
              onClick={() => setShowRunAgain(true)}
            >
              {t('runAgain')}
            </MatButton>
          ),
        ],
      }}
    >
      {loading ? (
        <Collapse in={true}>
          <LinearProgress />
        </Collapse>
      ) : (
        <HomeContainer display="flex">
          <Box flexGrow={1}>
            {fullCampaign &&
              fullCampaign.terminated !== null &&
              fullCampaign.terminated && (
                <Box mb={2}>
                  <Typography variant="h6" sx={{ color: Colors.error }}>
                    Terminated Card
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: Colors.error }}>
                    {fullCampaign.termination_reason}
                  </Typography>
                </Box>
              )}
            <Grid
              container
              display="flex"
              justifyContent="space-around"
              spacing={4}
              // sx={{
              //   [theme.breakpoints.up('md')]: {
              //     marginLeft: 1,
              //   },
              // }}
            >
              {/* <Grid lg={1} md={0} sm={0} /> */}
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  [theme.breakpoints.down('md')]: {
                    alignItems: 'center',
                  },
                }}
                item
                lg={4}
                md={6}
                sm={12}
              >
                <Hidden smDown={true}>
                  <Box>{renderEmulator()}</Box>
                </Hidden>
                {/* <Hidden lgUp={true}>
                  <Box>{renderTags()}</Box>
                </Hidden> */}
              </Grid>

              <Grid item lg={3} md={6} sm={12}>
                <MetricsContainer
                  campaignId={fullCampaign.id}
                  type={fullCampaign.type}
                  teamMembers={fullCampaign.teamMembers}
                />
              </Grid>

              <Grid
                sx={{
                  gap: '30px',
                  display: 'flex',
                  flexDirection: 'column',
                  marginX: 'auto',
                }}
                item
                lg={4}
                md={12}
                sm={12}
              >
                <TeamMemberTable
                  campaignId={fullCampaign.id}
                  type={fullCampaign.type}
                />
                <TeamsTable
                  campaignId={fullCampaign.id}
                  type={fullCampaign.type}
                />
              </Grid>
              <Grid lg={1} md={0} sm={0} />
            </Grid>
          </Box>
        </HomeContainer>
      )}

      <GenerateReport
        open={reportOpen}
        onClose={() => setReportOpen(false)}
        campaignId={fullCampaign.id}
      />
      <TerminateDrawer
        open={showTerminate}
        onClose={() => setShowTerminate(false)}
        onSuccess={onCampaignTerminated}
        campaignId={fullCampaign.id}
      />
      <RunAgainDrawer
        open={showRunAgain}
        onClose={() => setShowRunAgain(false)}
        campaign={fullCampaign}
        onSuccess={() => redirectToCampaigns()}
      />
    </Scaffold>
  );
};

export default CardDetails;

import React from 'react';

import Colors from 'constants/colors';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, BoxProps } from '@mui/material';
import IconButton from '@mui/material/IconButton';

export interface ZoomControlProps extends Pick<BoxProps, 'className' | 'style' | 'sx'> {
  onZoomIn: () => any;
  onZoomOut: () => any;
}

const ZoomControl: React.FC<ZoomControlProps> = ({ onZoomIn, onZoomOut, ...props }) => (
  <Box display="flex" flexDirection="column" width="max-content" bgcolor="#fff" {...props}>
    <IconButton
      onClick={onZoomIn}
      size="large"
      sx={{
        border: '1px solid',
        borderColor: Colors.lightBlueGray,
        borderRadius: 1,
        color: Colors.lightBlueGray,
        padding: 0.5,
        '&:first-child': {
          borderBottom: 'none',
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
        },
        '&:last-child': {
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
        },
      }}
    >
      <AddIcon />
    </IconButton>
    <IconButton
      onClick={onZoomOut}
      size="large"
      sx={{
        border: '1px solid',
        borderColor: Colors.lightBlueGray,
        borderRadius: 1,
        color: Colors.lightBlueGray,
        padding: 0.5,
        '&:first-child': {
          borderBottom: 'none',
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
        },
        '&:last-child': {
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
        },
      }}

    >
      <RemoveIcon />
    </IconButton>
  </Box>
);

export default ZoomControl;

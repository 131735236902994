/* eslint-disable no-param-reassign */
import RequestStatus from 'constants/requestStatus';

import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import Parse from 'parse';

const sliceName = 'permissions';
export const getPermissionsId = (businessId, userId) =>
  `${businessId}/${userId}`;

const permissionsAdapter = createEntityAdapter({
  selectId: (permissions: any) =>
    getPermissionsId(permissions?.businessId, permissions?.userId),
});

export interface GetUserPermisionsPayload {
  businessId: string;
  userId: string;
}

export const getUserPermisions = createAsyncThunk(
  `${sliceName}/getUserPermisions`,
  async ({ businessId, userId }: GetUserPermisionsPayload) => {
    try {
      return await Parse.Cloud.run('User:getUserPermissions', {
        businessId,
        userId,
      });
    } catch (error) {
      console.error('error :getting business permissions ', error);
      return null;
    }
  },
);

export interface SetUserPermisionsPayload {
  businessId: string;
  userId: string;
  permissions: string[];
}

export const setUserPermissions = createAsyncThunk(
  `${sliceName}/setUserPermisions`,
  async ({ businessId, userId, permissions }: SetUserPermisionsPayload) => {
    const newPermissions = await Parse.Cloud.run('User:setUserPermissions', {
      businessId,
      userId,
      permissions,
    });
    return newPermissions;
  },
);

const permissionsSlice = createSlice({
  name: sliceName,
  initialState: {
    ...permissionsAdapter.getInitialState(),
    status: RequestStatus.idle,
  },
  extraReducers: (builder) => {
    builder.addCase(getUserPermisions.pending, (state) => {
      state.status = RequestStatus.pending;
    });
    builder.addCase(getUserPermisions.fulfilled, (state, action) => {
      state.status = RequestStatus.fulfilled;
      state = permissionsAdapter.setOne(state, action.payload);
    });
    builder.addCase(getUserPermisions.rejected, (state) => {
      state.status = RequestStatus.rejected;
    });
    builder.addCase(setUserPermissions.pending, (state) => {
      state.status = RequestStatus.pending;
    });
    builder.addCase(setUserPermissions.fulfilled, (state, action) => {
      state.status = RequestStatus.fulfilled;
      state = permissionsAdapter.setOne(state, action.payload);
    });
    builder.addCase(setUserPermissions.rejected, (state) => {
      state.status = RequestStatus.rejected;
    });
  },
  reducers: {},
});

export const permissionsSelector = permissionsAdapter.getSelectors(
  (state: any) => state.permissions,
);

export const permissionsActions = {
  getUserPermisions,
  setUserPermissions,
};

export default permissionsSlice.reducer;

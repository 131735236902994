const Permissions = {
  campaign: {
    create: 'campaign:create',
    edit: 'campaign:edit',
    view: 'campaign:view',
  },
  // announcement: {
  //   create: 'announcement:create',
  //   edit: 'announcement:edit',
  //   view: 'announcement:view',
  // },
  teamHub: {
    create: 'teamHub:create',
    edit: 'teamHub:edit',
    view: 'teamHub:view',
  },
  team: {
    create: 'team:create',
    edit: 'team:edit',
    view: 'team:view',
  },
  user: {
    create: 'user:create',
    edit: 'user:edit',
    view: 'user:view',
  },
  report: {
    create: 'report:create',
  },
  business: {
    edit: 'business:edit',
  },
  subscription: {
    edit: 'subscription:edit',
    view: 'subscription:view',
  },
  integration: {
    edit: 'integration:edit',
  },
};

export const ResourceInfo = {
  campaign: {
    name: 'Campaign',
  },
  teamHub: {
    name: 'Team Hub',
  },
  team: {
    name: 'Team',
  },
  user: {
    name: 'User',
  },
  report: {
    name: 'Report',
  },
  business: {
    name: 'Business',
  },
  subscription: {
    name: 'Subscription',
  },
  integration: {
    name: 'Integration',
  },
};

export const ActionInfo = {
  create: {
    name: 'Create',
  },
  view: {
    name: 'View',
  },
  edit: {
    name: 'Edit',
  },
};

export default Permissions;

import React, { useState, useEffect } from 'react';

import { Collapse, Box } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import { format as formatDate } from 'date-fns';
import { parse as json2csv } from 'json2csv';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { reportActions } from 'store/entities/Reports';

import EmptyReport from './EmptyReport';
import ReportModal from './ReportModal';
import TeamHubReportTable from './TeamhubReportTable';

const GenerateReport = ({ open, onClose, campaignId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getTeamHubCardReport, loadingReport } = useSelector(
    (state: RootState) => state.reports,
  );

  const hasActions = (rows) => rows.filter((row) => row.actions > 0);
  const getRows = () => {
    try {
      return [
        {
          id: getTeamHubCardReport?.card.id,
          title: getTeamHubCardReport?.card.get('title'),
          actions: getTeamHubCardReport.actions,
          hasTeamMember:
            getTeamHubCardReport.byTeamMember &&
            getTeamHubCardReport.byTeamMember?.length > 0,
          byTeamMember: getTeamHubCardReport.byTeamMember
            ? hasActions(
                getTeamHubCardReport.byTeamMember.map((tm) => ({
                  id: tm.teamMember.get('id'),
                  name: `${tm.teamMember.get('firstname')} ${tm.teamMember.get(
                    'lastname',
                  )}`,
                  actions: tm.actions,
                })),
              )
            : undefined,
        },
      ];
    } catch (e) {
      return [];
    }
  };

  const rows = getTeamHubCardReport ? getRows() : [];

  const handleRunReport = () => {
    dispatch(
      reportActions.getTeamHubCardReport({
        id: campaignId,
      }),
    );
  };

  const generateFilename = () =>
    `card-report-${getTeamHubCardReport.card.get('title')}.csv`;

  const generateCsv = () => {
    const json = [
      ...getTeamHubCardReport.byTeamMember.map((tm) => ({
        'team member': `${tm.teamMember.get('firstname')} ${tm.teamMember.get(
          'lastname',
        )}`,
        actions: tm.actions,
      })),
      {
        'team member': '',
        actions: '',
      },
      {
        'team member': 'Total',
        actions: getTeamHubCardReport?.actions,
      },
    ];
    return json2csv(json);
  };

  const handleDownloadCsv = () => {
    const csv = generateCsv();
    const downloadLink = document.createElement('a');
    downloadLink.href = `data:text/plain;charset=utf-8,${encodeURIComponent(
      csv,
    )}`;
    downloadLink.download = generateFilename();
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  useEffect(() => {
    handleRunReport();
  }, []);

  return (
    <ReportModal
      open={open}
      onClose={onClose}
      onCancel={onClose}
      title="Generate Report"
    >
      <Box minWidth={500} maxWidth={100}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {!getTeamHubCardReport ? (
              <EmptyReport />
            ) : (
              <TeamHubReportTable
                rows={rows}
                total={getTeamHubCardReport?.actions ?? 0}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              onClick={handleRunReport}
              disabled={loadingReport}
            >
              {t('runTheReport')}
            </Button>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              disabled={
                !getTeamHubCardReport ||
                !getTeamHubCardReport.actions ||
                loadingReport
              }
              onClick={handleDownloadCsv}
            >
              {t('downloadCsv')}
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Collapse in={loadingReport}>
              <LinearProgress />
            </Collapse>
          </Grid>
        </Grid>
      </Box>
    </ReportModal>
  );
};

export default GenerateReport;

import React, { useState } from 'react';

import Box from '@mui/material/Box';

import HomeContainer from 'components/ui/HomeContainer';
import Scaffold from 'components/ui/Scaffold';

import Campaign from './components/CampaignReport';
import ReportingTabs from './components/ReportingTabs';
import TeamMember from './components/TeamMemberReport';

const Reporting = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const renderPage = () => {
    switch (currentTab) {
      case 0:
        return <Campaign />;
      case 1:
        return <TeamMember />;
      default:
        return <Campaign />;
    }
  };

  return (
    <Scaffold
      HeaderProps={{
        title: 'Reporting',
        tabs: (
          <ReportingTabs
            tab={currentTab}
            onTabChange={(e, val) => {
              setCurrentTab(val);
            }}
          />
        ),
      }}
    >
      <HomeContainer>
        <Box
          sx={{
            '@media (min-device-width: 959px)': {
              px: 12.5,
            },
          }}
          pt={3}
        >
          {renderPage()}
        </Box>
      </HomeContainer>
    </Scaffold>
  );
};

export default Reporting;

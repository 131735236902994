import React from 'react';

import { styled } from '@mui/material';
import Drawer, { DrawerProps } from '@mui/material/Drawer';

export interface VariableWidthDrawerProps extends DrawerProps {
  width?: React.CSSProperties['width']
}

const VariableWidthDrawer = styled<React.FC<VariableWidthDrawerProps>>(Drawer, { shouldForwardProp: (prop) => prop !== 'mini' })(
  ({ theme, width }) => ({
    width,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    '& .MuiDrawer-paper': {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width,
    },
  }),
);

export default VariableWidthDrawer;

import React from 'react';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

interface TextWithToolTipProps {
  text: string;
}

const TextWithToolTip = ({ text }: TextWithToolTipProps) => (
  <Box
    display="flex"
    sx={(theme) => ({
      [theme.breakpoints.down('lg')]: {
        width: 84,
      },
    })}
    overflow="hidden"
    whiteSpace="nowrap"
  >
    <Tooltip title={text} disableHoverListener={text.length < 20}>
      <span style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
        {text}
      </span>
    </Tooltip>
  </Box>
);

export const TextWithToolTipV2 = ({ text }: TextWithToolTipProps) => (
  <Box
    display="flex"
    sx={(theme) => ({
      [theme.breakpoints.down('xl')]: {
        width: 82,
      },
    })}
    overflow="hidden"
    whiteSpace="nowrap"
  >
    <Tooltip title={text} disableHoverListener={text?.length < 20}>
      <span style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
        {text}
      </span>
    </Tooltip>
  </Box>
);

export default TextWithToolTip;

import React from 'react';

import Colors from 'constants/colors';
import Permissions from 'constants/permissions';
import { defaultStyles } from 'constants/styles';

import EditIcon from '@mui/icons-material/Edit';
import { Hidden } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow';
import { createTheme } from '@mui/material/styles';

import TeamsIcon from 'assets/images/teams_icon.svg';
import PropTypes from 'prop-types';
import { BiSolidPencil } from 'react-icons/bi';

import PermissionRequired from 'components/PermissionRequired';
import TextWithToolTip, {
  TextWithToolTipV2,
} from 'components/TextWithToolTip/TextWithToolTip';

import StatusCell from './StatusCell';

const theme = createTheme();

const Row = ({ teamMember, onEdit, onClick }) => {
  const getLocationText = () => teamMember.location;

  return (
    <Box
      key={teamMember.objectId}
      onClick={onClick}
      sx={{
        width: '100%',
        padding: '22px',
        borderRadius: '10px',
        boxShadow: defaultStyles.box.shadow,
        backgroundColor: Colors.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        '&:hover': {
          boxShadow: ' 0px 0px 0px 1px rgb(0 99 255 / 50%)',
          backgroundColor: `${Colors.white} !important`,
        },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Box
          sx={{
            cursor: 'pointer',
            borderRadius: '8px',
            height: '30px',
            width: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor:
              teamMember.status === 'active' ? Colors.active : '#babac4',
          }}
        >
          <img src={TeamsIcon} alt="Members Icon" />
        </Box>
        <Box
          sx={{
            fontSize: 16,
            fontWeight: 700,
            color: '#333',
            border: 'none',
            padding: 0,
          }}
        >
          <TextWithToolTipV2 text={teamMember.name} />
        </Box>
        <Box
          sx={{
            fontSize: 12,
            fontWeight: 400,
            color: '#333',
            border: 'none',
            padding: 0,
          }}
        >
          <TextWithToolTipV2 text={teamMember.email} />
        </Box>
        <Box
          sx={{
            border: 'none',
            padding: 0,
            [theme.breakpoints.down('lg')]: {
              display: 'none',
            },
            fontSize: 12,
            color: 'inherit',
          }}
        >
          {teamMember.tags && teamMember.tags.length > 0 && (
            <Box display="flex" flexWrap="wrap" justifyContent="center">
              {teamMember.tags.map((tag) => (
                <Chip key={tag} label={tag} sx={{ margin: 0.25 }} />
              ))}
            </Box>
          )}
        </Box>
        <Box
          sx={{
            border: 'none',
            padding: 0,
            [theme.breakpoints.down('lg')]: {
              display: 'none',
            },
            fontSize: 16,
            color: 'inherit',
          }}
        >
          {getLocationText()}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2.5 }}>
        <Box
          sx={{
            border: 'none',
            padding: 0,
            fontSize: 16,
            fontWeight: 700,
            [theme.breakpoints.down('lg')]: {
              display: 'none',
            },
            color: teamMember.status === 'active' ? '#00CA51' : '#babac4',
          }}
        >
          {teamMember.status === 'active' ? 'Active' : 'Inactive'}
        </Box>
        <PermissionRequired permissions={[Permissions.user.edit]}>
          <Box
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <Box
              onClick={() => onEdit(teamMember)}
              sx={{
                cursor: 'pointer',
                borderRadius: '8px',
                height: '30px',
                width: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#D9EBFF',
              }}
            >
              <BiSolidPencil color="#007AFF" size={17} />
            </Box>
          </Box>
        </PermissionRequired>
      </Box>
    </Box>
  );
};

Row.propTypes = {
  teamMember: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Row;

import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FaArrowLeftLong } from 'react-icons/fa6';

import { addDays } from 'date-fns';
import Moment from 'moment';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { DragDropContext } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import routes from 'routes';
import { RootState } from 'store';
import { campaignActions } from 'store/entities/Campaigns';
import { TeamHubActions } from 'store/entities/TeamHub';
import useWindowSize from 'utils/hooks/useWindowSize';
import { getPlainText } from 'utils/wysiwyg_filter';

import HomeContainer from 'components/ui/HomeContainer';
import Scaffold from 'components/ui/Scaffold';

import BasicInfo from './components/BasicInfo';
import Builder from './components/Builder';
import Divider from '@mui/material/Divider';

import SubscriptionModal from './components/SubscriptionModal';
import TabPanel from 'components/TabPanel';
import ToolBar from './components/ToolBar';
import checkErrors from './helpers/ErrorCheck';
import createNewItem from './helpers/Item';
import { CircularProgress } from '@mui/material';
import { BusinessFolderUpload } from 'utils/cloudinary';
import OGPEmulator from './components/OGPEmulator';
import TagsModal from './components/TagsModal';
import DragNDropEmulator from 'views/Campaigns/Create/components/DragNDropEmulator';
import CardHub from './components/CardHub';
import CardPreview from 'components/CardPreview/CardPreview';
import { membersSelectors } from 'store/entities/Members';
import BottomButtons from './components/BottomButtons';
import ErrorDrawer from './components/ErrorDrawer';
import Styling from 'components/Styling';
import colors from 'constants/colors';

const CardCreate = () => {
  const navigate = useNavigate();
  const historyLocation: any = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [campaignId, setCampaignId] = useState(null);
  const [folderId, setFolderId] = useState('');
  const [title, setTitle] = useState<any>('');
  const [description, setDescription] = useState('');
  const [duration, setDuration] = useState([Moment(), Moment().add(5, 'days')]);
  const [calenderDuration, setCalenderDuration] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection',
    },
  ]);
  const [image, setImage] = useState<any>(null);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState<any>([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [locationId, setLocationId] = useState<any>('all');
  const [conditions, setConditions] = useState<any>(null);
  const [conditionsHtml, setConditionsHtml] = useState(null);
  const [age, setAge] = useState('all');
  const [pixel, setPixel] = useState(null);
  const [targetAudience, setTargetAudience] = useState(null);
  const [tags, setTags] = useState([]);
  const [data, setData] = useState<any>([]);
  const [dataCtaOne, setDataCtaOne] = useState<any>();
  const [activeRow, setActiveRow] = useState(0);
  const [detailedErrors, setDetailedErrors] = useState([]);
  const [editing, setEditing] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [value, setValue] = useState(1);
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [showTeamMembersModal, setShowTeamMembersModal] = React.useState(false);
  const [actionsForExpire, setActionsForExpire] = useState(0);
  const [expire, setExpire] = useState('no');
  const [recipient, setRecipient] = useState('all');
  const [isAvailableForNewUsers, setIsAvailableForNewUsers] = useState(true);

  const { width } = useWindowSize();

  useEffect(() => {
    if (width < 660) {
      navigate('/');
    }
  }, [width]);

  const { user } = useSelector((state: any) => state.user);
  const { campaign } = useSelector((state: RootState) => state.campaigns);

  const handleOpenTeamMembersModal = () => setShowTeamMembersModal(true);
  const handleTeamMembers = (val) => setSelectedTeamMembers(val);
  const handleTeams = (val) => {
    setSelectedTeams(val);
  };

  const handleSetDuration = (startDate, endDate) => {
    const today = Moment();
    let start = Moment(startDate);
    if (start.isBefore(today)) {
      start = today;
    }
    let end = Moment(endDate);
    if (end.isBefore(today)) {
      end = Moment().add(5, 'days');
    }
    setDuration([start, end]);
  };

  const handleSetDurationV2 = (startDate, endDate) => {
    const today = new Date();
    let start = startDate.substring(0);
    if (start < today) {
      start = today;
    }
    let end = endDate.substring(0);
    if (end < today) {
      end = addDays(new Date(), 5);
    }
    setCalenderDuration([
      {
        startDate: start,
        endDate: end,
        key: 'selection',
      },
    ]);
  };

  const setCampaign = (results, repeat) => {
    if (!repeat) {
      setCampaignId(results?.id);
    }
    setTitle(results?.title);
    setImage(results?.image);
    setDescription(results?.description);
    setSelectedTeamMembers(results?.teamMembers);
    if (results?.isAvailableForNewUsers) {
      setIsAvailableForNewUsers(true);
    } else {
      setIsAvailableForNewUsers(false);
    }
    setSelectedTeams(results?.teamsIds);
    setLocationId(results?.locationId);
    setData([...results?.data]);
    setDataCtaOne(results?.data);
    setRecipient('all');
    setExpire('no');
    if (results?.teamsIds?.length && results?.teamMembers?.length) {
      setRecipient('teamsmembers');
    } else if (results?.teamsIds?.length) {
      setRecipient('teams');
    } else if (results?.teamMembers?.length) {
      setRecipient('members');
    }
    if (results?.actionsForExpire) {
      setExpire('action');
      setActionsForExpire(results?.actionsForExpire);
    }
    if (results?.endDate) {
      setExpire('date');
      if (repeat) {
        // handleSetDuration(results?.startDate, results?.endDate);
        handleSetDurationV2(results?.startDate, results?.endDate);
      } else {
        // setDuration([Moment(results?.startDate), Moment(results?.endDate)]);
        setCalenderDuration([
          {
            startDate: results?.startDate,
            endDate: results?.endDate,
            key: 'selection',
          },
          // .substring(0)
        ]);
      }
    }
    if (results?.advanced) {
      if (results?.advanced.conditions) {
        const conditionsConverter: any = new QuillDeltaToHtmlConverter(
          results?.advanced.conditions,
          {},
        );
        setConditionsHtml(conditionsConverter.convert());
        setConditions(results?.advanced.conditions);
        // Set Conditions
      }
      if (results?.advanced.age) {
        setAge(results?.advanced.age);
      }
      if (results?.advanced.pixel) {
        setPixel(results?.advanced.pixel);
      }
      if (results?.advanced.targetAudience) {
        setTargetAudience(results?.advanced.targetAudience);
      }
      if (results?.advanced.tags) {
        setTags(results?.advanced.tags.map((tag) => tag.name));
      }
    }
  };

  useEffect(() => {
    if (campaign && historyLocation?.state?.id) {
      setLoading(false);
      setCampaign(campaign, historyLocation.state.type === 'runagain');
    }
  }, [campaign]);

  useEffect(() => {
    if (
      historyLocation.state &&
      historyLocation.state.id &&
      historyLocation.state.type
    ) {
      if (historyLocation.state.type === 'edit') {
        setEditing(true);
      }
      setLoading(true);
      dispatch(
        campaignActions.getCampaign({ campaignId: historyLocation.state.id }),
      );
    } else {
      const item = createNewItem('info');
      setData([
        {
          item,
          row: 0,
        },
      ]);
      setActiveRow(0);
    }
    setFolderId(historyLocation.state.folderId);
  }, []);

  // useEffect(() => {
  //   switch (data[activeRow]?.item.type) {
  //     case 'info':
  //       setValue(1);
  //       break;
  //     case 'image':
  //       setValue(1);
  //       break;
  //     case 'video':
  //       setValue(1);
  //       break;
  //     case 'button':
  //       setValue(5);
  //       break;
  //     case 'poll':
  //       setValue(1);
  //       break;
  //     case 'social':
  //       setValue(1);
  //       break;
  //     case 'form':
  //       setValue(1);
  //       break;
  //     case 'carousel':
  //       setValue(1);
  //       break;
  //     case 'mailchimp':
  //       setValue(1);
  //       break;
  //     default:
  //       setValue(1);
  //       break;
  //   }
  // }, [activeRow]);

  // useEffect(() => {
  //   const errors: any = [];
  //   let requiredCard = false;
  //   let requiredCta = false;
  //   data.forEach((val, index) => {
  //     const issues = checkErrors(val.item);
  //     if (issues.length > 0) {
  //       errors.push({
  //         card: index + 1,
  //         issues,
  //       });
  //     }
  //     const { type } = val.item;
  //     if (type === 'info' || type === 'image') {
  //       requiredCard = true;
  //     }

  //     if (type === 'info' || type === 'carousel') {
  //       if (val.item.info.ctaOne.type !== 'none') {
  //         requiredCta = true;
  //       }
  //     } else if (type === 'image') {
  //       if (val.item.info.cta.type !== 'none') {
  //         requiredCta = true;
  //       }
  //     } else if (type === 'button') {
  //       requiredCta = true;
  //     }
  //   });
  //   if (!requiredCard || !requiredCta || !title) {
  //     errors.push({
  //       card: 'Minimum',
  //       issues: [
  //         !requiredCard ? t('includeOneInformationCard') : '',
  //         !requiredCta ? t('includeOneAction') : '',
  //         !title ? t('mustEnterTitleCampaign') : '',
  //       ],
  //     });
  //   }
  //   setDetailedErrors(errors);
  // }, [data, title]);

  const handleUpdateItem = (item) => {
    const newData = JSON.parse(JSON.stringify(data));
    newData[activeRow].item.info = item;
    setData(newData);
    setDataCtaOne(newData);

    if (activeRow === 0) {
      // setTitle(item.title);
      setImage(item.image);
      if (item.description) {
        setDescription(item.description);
      } else {
        setDescription('');
      }
    }
  };

  const onCampaignSaved = (campaign) => {
    setLoading(false);
    navigate(routes.teamHub.folder(folderId));
  };

  const createCampaign = async () => {
    setLoading(true);
    let newImage;
    if (image?.type === 'custom' && image?.upload === true) {
      const uploadUrl = await BusinessFolderUpload({
        businessId: user?.businessId,
        image: image.url,
      });
      newImage = { ...image, url: uploadUrl };
    }
    dispatch(
      TeamHubActions.createCard({
        id: campaignId,
        folderId,
        title,
        image: newImage,
        published: true,
        actionsForExpire,
        isAvailableForNewUsers,
        startDate: calenderDuration[0].startDate.toISOString(),
        endDate:
          expire === 'date'
            ? calenderDuration[0].endDate.toISOString()
            : undefined,
        description,
        teamsIds: selectedTeams,
        teamMembers: selectedTeamMembers,
        locationId,
        info: data,
        callback: onCampaignSaved,
        advanced: {
          conditions: conditions ? conditions.ops : null,
          age,
          pixel,
          targetAudience,
          tags,
        },
      }),
    );
  };

  const saveCampaign = () => {
    setLoading(true);
    dispatch(
      TeamHubActions.createCard({
        id: campaignId,
        folderId,
        title,
        image,
        published: false,
        actionsForExpire,
        isAvailableForNewUsers,
        startDate: calenderDuration[0].startDate.toISOString(),
        endDate:
          expire === 'date'
            ? calenderDuration[0].endDate.toISOString()
            : undefined,
        description,
        teamsIds: selectedTeams,
        teamMembers: selectedTeamMembers,
        locationId,
        info: data,
        callback: onCampaignSaved,
        advanced: {
          conditions: conditions ? conditions.ops : null,
          age,
          pixel,
          targetAudience,
          tags,
        },
      }),
    );
  };

  const handleDeleteItem = () => {
    const newObjects = [...data];
    newObjects.splice(activeRow, 1);
    setActiveRow(10);
    setData(newObjects);
  };

  const onDragEnd = (result) => {
    const { draggableId, source, destination } = result;
    if (!destination) {
      return;
    }

    if (editing) {
      return;
    }

    if (
      source.droppableId === 'template' &&
      destination.droppableId === 'emulator' &&
      data.length < 5
    ) {
      let { index } = destination;
      if (index === 0) {
        index += 1;
      }
      const array = Array.from(data);
      const item = createNewItem(draggableId);
      array.splice(index, 0, {
        item,
        row: index,
      });
      setData(array);
      setActiveRow(index);
    } else if (
      source.droppableId === 'emulator' &&
      destination.droppableId === 'emulator' &&
      destination.index !== 0 &&
      source.index !== 0
    ) {
      const array = Array.from(data);
      const [removed] = array.splice(source.index, 1);
      array.splice(destination.index, 0, removed);
      setData(array);
      setActiveRow(destination.index);
    } else if (
      source.droppableId === 'emulator' &&
      destination.droppableId === 'template'
    ) {
      // TODO Delete Item
    }
  };

  const onMainButton = () => {
    switch (activeStep) {
      case 0:
        setActiveStep(1);
        break;
      case 1:
        createCampaign();
        break;
      default:
        setActiveStep(1);
        break;
    }
  };

  const tabIndexStyling = 1;

  const renderActiveStep = () => {
    switch (activeStep) {
      case 0:
        return (
          <Box
            sx={{
              width: 'calc(100% - 75px)',
              flexGrow: 1,
              display: 'flex',
              overflow: 'hidden',
              paddingLeft: 4,
            }}
          >
            <Grid container spacing={6}>
              <Grid item xs={12} sm={4}>
                <BasicInfo
                  range={duration}
                  actionsForExpire={actionsForExpire}
                  isAvailableForNewUsers={isAvailableForNewUsers}
                  setIsAvailableForNewUsers={setIsAvailableForNewUsers}
                  setActionsForExpire={setActionsForExpire}
                  teamMembers={selectedTeamMembers}
                  teams={selectedTeams}
                  expire={expire}
                  setExpire={setExpire}
                  recipient={recipient}
                  setRecipient={setRecipient}
                  locationId={locationId}
                  handleRange={(range) => setDuration(range)}
                  handleTeamMembers={handleTeamMembers}
                  handleTeams={handleTeams}
                  calenderDuration={calenderDuration}
                  setCalenderDuration={setCalenderDuration}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <CardHub
                    // data={campaign?.data[0]?.item}
                    item={data[0]?.item}
                    tags={tags}
                    setValue={setValue}
                    handleClickTag={handleOpenTeamMembersModal}
                    cardTitle={title}
                    handleUpdateItem={handleUpdateItem}
                    handleTitle={(e: any) => setTitle(e.target.value)}
                    tabIndex={tabIndexStyling}
                    itemStyling={data[activeRow]?.item.info}
                  />
                </DragDropContext>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box
                  position="relative"
                  sx={{
                    paddingTop: 4,
                  }}
                >
                  <TabPanel value={value} index={3}>
                    <Builder
                      data={data}
                      activeRow={activeRow}
                      tabIndex={value}
                      handleUpdateItem={handleUpdateItem}
                      handleDeleteItem={handleDeleteItem}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={5}>
                    <Builder
                      data={data}
                      activeRow={activeRow}
                      tabIndex={value}
                      handleUpdateItem={handleUpdateItem}
                      handleDeleteItem={handleDeleteItem}
                    />
                  </TabPanel>
                </Box>
              </Grid>
            </Grid>
          </Box>
        );
      case 1:
        return (
          <Box
            sx={{
              width: '100%',
              flexGrow: 1,
              display: 'flex',
              px: 3,
              overflowY: 'hidden',
              height: 'calc(100vh - 64px)',
            }}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <div style={{ width: '100%', paddingTop: 24 }}>
                <Typography
                  style={{ marginBottom: 24, textAlign: 'center' }}
                  variant="h5"
                >
                  Card Preview
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <CardPreview
                    card={{
                      ...data[0]?.item.info,
                      title,
                      description,
                      action: {
                        ...data[0]?.item.info.ctaOne,
                        ...data[0]?.item.info.styling,
                      },
                      isPreview: true,
                      tags,
                    }}
                  />
                </Box>
              </div>
            </DragDropContext>
          </Box>
        );
      default:
        return <div />;
    }
  };

  return (
    <Scaffold
      HeaderProps={{
        title: (
          <Box
            sx={{
              p: 2,
              py: 1,
              borderRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              background: '#fff',
            }}
          >
            <FaArrowLeftLong size={20} onClick={() => navigate(-1)} />
            <Divider sx={{ mx: 2 }} orientation="vertical" flexItem />
            <Box sx={{ width: 250, fontWeight: 400, color: '#0000003d' }}>
              Edit card below
            </Box>
          </Box>
        ),
        actions: [
          <ToolBar
            setValue={setValue}
            value={value}
            type={data[activeRow] ? data[activeRow]?.item?.type : 'default'}
            errors={detailedErrors}
            create={onMainButton}
            save={saveCampaign}
            activeStep={activeStep}
            back={() => setActiveStep(0)}
          />,
        ],
      }}
    >
      <HomeContainer
        display="flex"
        height="calc(100vh-64px)"
        sx={{ padding: '0 !important', position: 'relative' }}
        flexDirection="column"
      >
        {loading ? (
          <Box
            position="absolute"
            top={300}
            bottom={0}
            left={0}
            right={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {renderActiveStep()}
            {/* <ToolBar
              setValue={setValue}
              value={value}
              type={data[activeRow] ? data[activeRow]?.item?.type : 'default'}
              errors={detailedErrors}
              create={onMainButton}
              save={saveCampaign}
              activeStep={activeStep}
              back={() => setActiveStep(0)}
            /> */}
          </>
        )}
      </HomeContainer>
      {/* <BottomButtons
        editing={editing}
        onMain={onMainButton}
        onBack={() => setActiveStep(activeStep - 1)}
        onSave={saveCampaign}
        allowSave={image !== null && title && description}
        loading={true}
        step={activeStep}
      /> */}
      {/* <ErrorDrawer
        open={detailedErrors.length > 0}
        onClose={() => setDetailedErrors([])}
        errors={detailedErrors}
      /> */}
      <TagsModal
        locationId={locationId}
        handleTags={(val) => {
          setTags(val);
        }}
        tags={tags}
        open={showTeamMembersModal}
        onClose={() => setShowTeamMembersModal(false)}
        teamMembers={selectedTeamMembers}
      />
      <SubscriptionModal
        open={showSubscriptionModal}
        onClose={() => setShowSubscriptionModal(false)}
      />
    </Scaffold>
  );
};

export default CardCreate;

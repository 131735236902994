import { Cookie } from '@mui/icons-material';
import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
  EntityState,
} from '@reduxjs/toolkit';
import Parse from 'parse';
import { RootState } from 'store';
import { userSelectors } from 'store/domain/user';
import Campaign from 'store/models/Campaign';
import Coupon from 'store/models/Coupon';
import { RequestStatus } from 'store/utils/types';
import { reportActions } from './Reports';

const sliceName = 'cookie';
export const getCookieId = (businessId, userId) => `${businessId}/${userId}`;

const couponAdapter = createEntityAdapter({
  selectId: (permissions: any) =>
    getCookieId(permissions.businessId, permissions.userId),
});

export interface createCookiePayload {
  cookie?: any;
}

export const createCookie = createAsyncThunk(
  `${sliceName}/createCookie`,
  async ({ cookie }: createCookiePayload) => {
    try {
      if (cookie) {
        return cookie;
      }
      return await Parse.Cloud.run('Redemption:createCookie');
    } catch (e) {
      console.error(`Parse Error fetching business card: ${e}`);
      return null;
    }
  },
);

export interface setCardMetricsPayload {
  cardId: string;
  cookieId: string;
}

export const setCardMetrics = createAsyncThunk(
  `${sliceName}/setCardMetrics`,
  async (
    { cardId, cookieId }: setCardMetricsPayload,
    { getState, dispatch },
  ) => {
    try {
      const result = await Parse.Cloud.run(
        'Redemption:setPremiumConversion',
        {
          premiumId: cardId,
          cookieId,
          data: {
            type: 'info',
            action: 1,
          },
          row: 1,
        },
      );
      dispatch(reportActions.fetchCampaignMetrics({ campaignId: cardId }));
      return result;
    } catch (e) {
      console.error(`Parse Error setting premium metrics: ${e}`);
      return null;
    }
  },
);

export interface couponState extends EntityState<any> {
  status: RequestStatus;
  error?: string;
  savedCookie?: any;
}

const initialState: couponState = {
  ...couponAdapter.getInitialState(),
  status: 'idle',
};

const couponSlice = createSlice({
  name: sliceName,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(createCookie.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(createCookie.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.savedCookie = action.payload;
    });
    builder.addCase(createCookie.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    builder.addCase(setCardMetrics.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(setCardMetrics.fulfilled, (state, action) => {
      state.status = 'fulfilled';
    });
    builder.addCase(setCardMetrics.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
  },
  reducers: {},
});

export const couponActions = {
  createCookie,
  setCardMetrics,
};

export default couponSlice.reducer;

import useAccessControl from 'hooks/useAccessControl';

const PermissionRequired = ({ permissions, children }) => {
  const ac = useAccessControl();
  if (process.env.REACT_APP_PERMISSION === 'disable') {
    return children;
  }
  let grant = true;
  if (permissions) {
    permissions.forEach((permission) => {
      grant = grant && ac.allow(permission);
    });
  }
  if (grant) return children;
  return null;
};

export default PermissionRequired;

import React from 'react';

import Colors from 'constants/colors';

import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

import ActionDot from './ActionDot';
import Image from 'views/Campaigns/Create/components/DragNDropEmulator/Image';

const ImageCard = ({ data, results }) => {
  const renderButtons = () => {
    switch (data.cta.type) {
      case 'redirect':
        return (
          <Button
            color="primary"
            variant={data.cta.styling?.buttonVariant}
            disableElevation
            onClick={() => {
              const link = data.cta.link.includes('https://')
                ? data.cta.link
                : `https://${data.cta.link}`;
              const win = window.open(link);
              win?.focus();
            }}
            sx={{
              borderRadius: data.cta.styling?.buttonBorder,
              color: data.cta.styling?.textButtonColor,
              height: 22,
              marginBottom: 1.25,
              marginLeft: 1,
              borderColor:
                data.cta.styling?.buttonVariant === 'outlined'
                  ? data.cta.styling?.buttonColor
                  : '',
              backgroundColor:
                data.cta.styling?.buttonVariant === 'contained'
                  ? data.cta.styling?.buttonColor
                  : '',
            }}
          >
            <Typography sx={{ fontSize: 8 }}>{data.cta.name}</Typography>
          </Button>
        );
      case 'event':
        return (
          <Button
            color="primary"
            sx={{
              borderRadius: 2,
              height: 24,
              marginLeft: 1,
              marginRight: 1,
              marginBottom: 1,
            }}
            disableElevation
            endIcon={<EventOutlinedIcon />}
          >
            <Typography sx={{ fontSize: 8, color: Colors.white }}>
              Add to Calendar
            </Typography>
          </Button>
        );
      default:
        return <div />;
    }
  };

  return (
    <Box
      sx={{
        marginLeft: 1,
        marginRight: 1,
        marginBottom: 2,
      }}
      position="relative"
    >
      <Card
        sx={{
          width: '100%',
          maxHeight: 250,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: data?.styling?.cardBorder,
          position: 'relative',
        }}
      >
        <Box width={1}>
          <Image image={data.image} />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            left: 0,
            width: '100%',
            paddingLeft: 1,
            paddingRight: 1,
            paddingBottom: 1,
          }}
        >
          <Box
            color={data.styling?.mainColor}
            textAlign={data.styling?.mainAlignment}
            fontSize={data.styling?.mainSize}
            fontStyle={
              data.styling?.mainFormat?.includes('italic') ? 'italic' : ''
            }
            fontWeight={
              data.styling?.mainFormat?.includes('bold') ? 'bold' : ''
            }
            sx={{
              textDecoration: data.styling?.mainFormat?.includes('underline')
                ? 'underline'
                : '',
            }}
          >
            <Typography variant="subtitle2">{data.title}</Typography>
          </Box>
          {renderButtons()}
        </Box>
      </Card>
      {results !== null && results.actionOne > 0 && (
        <ActionDot count={results.actionOne} />
      )}
    </Box>
  );
};

ImageCard.propTypes = {
  data: PropTypes.object.isRequired,
  results: PropTypes.object,
};

ImageCard.defaultProps = {
  results: null,
};

export default ImageCard;

/* eslint-disable no-param-reassign */
import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
  EntityState,
  createDraftSafeSelector,
} from '@reduxjs/toolkit';
import Parse from 'parse';
import { RootState } from 'store';
import { userSelectors } from 'store/domain/user';
import {
  getTimeFrame,
  getPreviousTimeFrame,
  calcIncrease,
} from 'store/helpers/DashboardHelpers';
import Business from 'store/models/Business';
import BusinessMembers from 'store/models/BusinessMembers';
import Campaign from 'store/models/Campaign';
import Report, { IReport, ReportType } from 'store/models/Report';
import {
  selectAnnouncementsState,
  selectCampaignsState,
} from 'store/selectors';
import { RequestStatus } from 'store/utils/types';

const sliceName = 'reports';
const getReportId = (Report: IReport) =>
  `${Report.premium_pointer}/${Report.type}`;

const ReportsAdapter = createEntityAdapter<IReport>({ selectId: getReportId });

export interface MetricsPayload {
  startDate?: string;
  endDate?: string;
  campaignId?: string;
  businessId?: string;
  teamMemberId?: string;
  type: ReportType;
}

export interface getMapPayload {
  timeFrame: string;
}

const getMap = createAsyncThunk(
  `${sliceName}/getMap`,
  async ({ timeFrame }: getMapPayload, { getState }) => {
    try {
      const state = getState() as RootState;
      const businessId = userSelectors.getbusinessId(state);
      const dateData = getTimeFrame(timeFrame);
      const { startDate, endDate } = dateData;
      const data = await Parse.Cloud.run('Metrics:getMapEngagementsByDate', {
        businessId,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      });
      return data;
    } catch (e) {
      return new Error('Unabled to get map information');
    }
  },
);

/* export interface getRecentActivityPayload {
  timeFrame: string;
}

const getRecentActivity = createAsyncThunk(
  `${sliceName}/getRecentActivity`,
  async ({ timeFrame }: getMapPayload, { getState }) => {
    const state = getState() as RootState;
    const businessId = userSelectors.getbusinessId(state);
    const dateData = getTimeFrame(timeFrame);
    const { startDate } = dateData;
    const { endDate } = dateData;

    const [data = []] = await Promise.all([
      await Parse.Cloud.run('Metrics:getMapEngagementsByDate', {
        businessId,
        startDate,
        endDate,
      }),
    ]);
  },
); */

const getMetrics = async ({
  teamMemberId,
  campaignId,
  businessId,
  startDate,
  endDate,
  type,
}: MetricsPayload) => {
  try {
    const query = new Parse.Query(Report);

    if (businessId) {
      query.equalTo('business_pointer', Business.getPointer(businessId));
    } else {
      if (campaignId) {
        query.equalTo('premium_pointer', Campaign.getPointer(campaignId));
      }
      if (teamMemberId) {
        query.equalTo(
          'team_member_pointer',
          BusinessMembers.getPointer(teamMemberId),
        );
      }
    }
    query.equalTo('type', type);
    if (startDate) {
      query.greaterThanOrEqualTo('createdAt', new Date(startDate));
    }
    if (endDate) {
      query.lessThanOrEqualTo('createdAt', new Date(endDate));
    }

    return await query.count();
  } catch (e) {
    return new Error('Metrics Not Found');
  }
};

export interface timeFramePayload {
  timeFrame: string;
}

const fetchMetrics = createAsyncThunk(
  `${sliceName}/fetchMetrics`,
  async ({ timeFrame }: timeFramePayload, { getState }: any) => {
    try {
      const state = getState() as RootState;
      const businessId = userSelectors.getbusinessId(state);
      const dateData = getTimeFrame(timeFrame);
      const previousDateData = getPreviousTimeFrame(timeFrame);
      const startDate = dateData.startDate.toISOString();
      const endDate = dateData.endDate.toISOString();
      const prevStartDate = previousDateData.startDate.toISOString();
      const prevEndDate = previousDateData.endDate.toISOString();

      const {
        engagements = 0,
        impressions = 0,
        conversions: actions = 0,
        cardActions = 0,
        teamMembersCount: teamMembers,
      } = await Parse.Cloud.run('Metrics:byDate', {
        startDate,
        endDate,
        businessId,
      });

      const {
        prevEngagements = 0,
        prevImpressions = 0,
        conversions: prevActions = 0,
      } = await Parse.Cloud.run('Metrics:byDate', {
        startDate: prevStartDate,
        endDate: prevEndDate,
        businessId,
      });

      const metrics = {
        engagements,
        impressions,
        actions,
        cardActions,
        teamMembers,
      };

      const previousMetrics = {
        engagements: prevEngagements,
        impressions: prevImpressions,
        actions: prevActions,
      };

      let engagementChange = 0;
      let impressionChange = 0;
      let actionChange = 0;

      if (metrics && previousMetrics) {
        engagementChange = calcIncrease(
          previousMetrics.engagements,
          metrics.engagements,
        );
        impressionChange = calcIncrease(
          previousMetrics.impressions,
          metrics.impressions,
        );
        actionChange = calcIncrease(previousMetrics.actions, metrics.actions);
      }

      return {
        metrics,
        metricsChange: {
          engagements: engagementChange,
          impressions: impressionChange,
          actions: actionChange,
        },
      };
    } catch (error) {
      return new Error('could Not Fetch Metrics');
    }
  },
);

export interface getCampaignReportPayload {
  campaigns: any;
  startDate: any;
  endDate: any;
  includeTeamMembers: any;
}
export interface getTeamHubReportPayload {
  id: any;
  startDate?: any;
  endDate?: any;
  includeTeamMembers?: any;
}

const getCampaignReport = createAsyncThunk(
  `${sliceName}/getCampaignReport`,
  async ({
    campaigns,
    startDate,
    endDate,
    includeTeamMembers,
  }: getCampaignReportPayload) => {
    try {
      return await Parse.Cloud.run('Reporting:getCampaignReport', {
        campaigns,
        startDate,
        endDate,
        includeTeamMembers,
      });
    } catch (e) {
      throw new Error(`Error: ${e}`);
    }
  },
);

const getTeamHubCardReport = createAsyncThunk(
  `${sliceName}/getTeamHubReport`,
  async ({
    id,
    startDate,
    endDate,
    includeTeamMembers,
  }: getTeamHubReportPayload) => {
    try {
      return await Parse.Cloud.run('Reporting:getTeamHubReport', {
        id,
        startDate,
        endDate,
        includeTeamMembers,
      });
    } catch (e) {
      throw new Error(`Error: ${e}`);
    }
  },
);

export interface getTeamMemberReportPayload {
  teamMembers: any;
  startDate: any;
  endDate: any;
  includeCampaigns: any;
}

const getTeamMemberReport = createAsyncThunk(
  `${sliceName}/getTeamMemberReport`,
  async ({
    teamMembers,
    startDate,
    endDate,
    includeCampaigns,
  }: getTeamMemberReportPayload) => {
    try {
      return await Parse.Cloud.run('Reporting:getTeamMemberReport', {
        teamMembers,
        startDate,
        endDate,
        includeCampaigns,
      });
    } catch (e) {
      throw new Error(`Error: ${e}`);
    }
  },
);

const getDataOverTime = createAsyncThunk(
  `${sliceName}/getDataOverTime`,
  async ({ timeFrame }: timeFramePayload, { getState }) => {
    try {
      const state = getState() as RootState;
      const businessId = userSelectors.getbusinessId(state);
      const dateData = getTimeFrame(timeFrame);
      const startDate = dateData.startDate.toISOString();
      const endDate = dateData.endDate.toISOString();
      return await Parse.Cloud.run('Metrics:getGraphData', {
        businessId,
        timeFrame,
        startDate,
        endDate,
      });
    } catch (e) {
      throw new Error(`Error: ${e}`);
    }
  },
);

export interface fetchCampaignMetricsPayload {
  campaignId: string;
}

const fetchCampaignMetrics = createAsyncThunk(
  `${sliceName}/fetchCampaignMetrics`,
  async ({ campaignId }: fetchCampaignMetricsPayload) => {
    const engagementsPromise = getMetrics({
      campaignId,
      type: 'engagement',
    });

    const impressionsPromise = getMetrics({
      campaignId,
      type: 'impression',
    });

    const conversionsPromise = getMetrics({
      campaignId,
      type: 'conversion',
    });

    const cardActionPromise = getMetrics({
      campaignId,
      type: 'card_action',
    });

    const [
      engagements = 0,
      impressions = 0,
      actions = 0,
      cardActions = 0,
    ] = await Promise.all([
      engagementsPromise,
      impressionsPromise,
      conversionsPromise,
      cardActionPromise,
    ]);

    const metrics = {
      engagements,
      impressions,
      actions,
      cardActions,
      campaignId,
    };

    return {
      metrics,
    };
  },
);

export interface Reportstate extends EntityState<IReport> {
  status: RequestStatus;
  error?: string;
  dashboard?: any;
  campaign?: any;
  loadingMetrics: boolean;
  loadingMap: boolean;
  loadingReport: boolean;
  loadingDataOverTime: boolean;
  CampaignReport?: any;
  teamMemberReport?: any;
  getTeamHubCardReport?:any;
  dataByTime?: any;
  mapData?: any;
}

const initialState: Reportstate = {
  ...ReportsAdapter.getInitialState(),
  status: 'idle',
  loadingMetrics: false,
  loadingMap: false,
  loadingReport: false,
  loadingDataOverTime: false,
};

const reportsSlice = createSlice({
  name: sliceName,
  initialState,
  extraReducers: (builder) => {
    // Fetch Dashboard Metrics
    builder.addCase(fetchMetrics.pending, (state) => {
      state.status = 'pending';
      state.loadingMetrics = true;
    });
    builder.addCase(fetchMetrics.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.dashboard = action.payload;
      state.loadingMetrics = false;
    });
    builder.addCase(fetchMetrics.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
      state.loadingMetrics = false;
    });
    // Fetch Campaign Metrics
    builder.addCase(fetchCampaignMetrics.pending, (state) => {
      state.status = 'pending';
      state.loadingMetrics = true;
    });
    builder.addCase(fetchCampaignMetrics.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.campaign = action.payload;
      state.loadingMetrics = false;
    });
    builder.addCase(fetchCampaignMetrics.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
      state.loadingMetrics = false;
    });
    // Load Map Metrics
    builder.addCase(getMap.pending, (state) => {
      state.status = 'pending';
      state.loadingMap = true;
    });
    builder.addCase(getMap.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.loadingMap = false;
      state.mapData = action.payload;
    });
    builder.addCase(getMap.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
      state.loadingMap = false;
    });
    // get Campaign Report
    builder.addCase(getCampaignReport.pending, (state) => {
      state.status = 'pending';
      state.loadingReport = true;
    });
    builder.addCase(getCampaignReport.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.CampaignReport = action.payload;
      state.loadingReport = false;
    });
    builder.addCase(getCampaignReport.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
      state.loadingReport = false;
    });
    // get teamHub Report
    builder.addCase(getTeamHubCardReport.pending, (state) => {
      state.status = 'pending';
      state.loadingReport = true;
    });
    builder.addCase(getTeamHubCardReport.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.getTeamHubCardReport = action.payload;
      state.loadingReport = false;
    });
    builder.addCase(getTeamHubCardReport.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
      state.loadingReport = false;
    });
    // get Campaign Report
    builder.addCase(getTeamMemberReport.pending, (state) => {
      state.status = 'pending';
      state.loadingReport = true;
    });
    builder.addCase(getTeamMemberReport.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.teamMemberReport = action.payload;
      state.loadingReport = false;
    });
    builder.addCase(getTeamMemberReport.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
      state.loadingReport = false;
    });
    // get data by time
    builder.addCase(getDataOverTime.pending, (state) => {
      state.status = 'pending';
      state.loadingDataOverTime = true;
    });
    builder.addCase(getDataOverTime.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.dataByTime = action.payload;
      state.loadingDataOverTime = false;
    });
    builder.addCase(getDataOverTime.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
      state.loadingDataOverTime = false;
    });
  },
  reducers: {
    clearStatus: (state) => {
      state.status = 'idle';
    },
  },
});

const getRecentActivitiy = createDraftSafeSelector(
  [selectCampaignsState],
  (resultB) => {
    if (resultB) {
      /*       const announcements: any[] = [
        ...(resultA.past || []),
        ...(resultA.current || []),
      ]; */
      const premium = resultB?.allCampaigns?.data || [];
      const results: any[] = [...premium];

      /* if (announcements && announcements.length > 0) {
        for (let index = 0; index < announcements.length; index++) {
          results.push({
            type: 'announcement',
            id: announcements[index].objectId,
            title: announcements[index].title,
            description: announcements[index].details,
            image: announcements[index].photo_url,
            createdAt: announcements[index].createdAt,
            end_date: announcements[index].end_date,
            terminated: false,
          });
        }
      } */

      results.sort(
        (a, b) =>
          (new Date(b.createdAt) as any) - (new Date(a.createdAt) as any),
      );

      if (results.length < 5) {
        return results;
      }

      return results.splice(0, 4);
    }

    return [];
  },
);

const reportsSelector = {
  getRecentActivitiy,
};

export const reportActions = {
  fetchMetrics,
  fetchCampaignMetrics,
  getMap,
  getCampaignReport,
  getTeamMemberReport,
  getTeamHubCardReport,
  getDataOverTime,
  ...reportsSlice.actions,
};

export { reportsSelector, getReportId };

export default reportsSlice.reducer;

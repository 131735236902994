import React, { useEffect, useState } from 'react';

import Colors from 'constants/colors';

import PhoneIcon from '@mui/icons-material/Phone';
import WebIcon from '@mui/icons-material/Web';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  businessActions,
  businessesSelectors,
} from 'store/entities/Businesses';

import Edit from './components/edit';
import PermissionRequired from 'components/PermissionRequired';
import Permissions from 'constants/permissions';

const Business = () => {
  const { t } = useTranslation();
  const [showEdit, setShowEdit] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const activeBusiness = useSelector(businessesSelectors.getActiveBusiness);
  const { status, error } = useSelector((state: RootState) => state.businesses);
  const dispatch = useDispatch();

  useEffect(() => {
    switch (status) {
      case 'rejected':
        enqueueSnackbar(error, { variant: 'error' });
        dispatch(businessActions.clearStatus());
        break;
      case 'fulfilled':
        if (successMessage) {
          enqueueSnackbar(successMessage, { variant: 'success' });
        }
        dispatch(businessActions.clearStatus());
        setSuccessMessage('');
        break;
      default:
        break;
    }
  }, [status]);

  return (
    <Box p={3}>
      <Typography variant="h5">{t('business')}</Typography>
      <Box>
        <Box width="100%" mt={2.5} display="flex" alignItems="center">
          {activeBusiness?.imageUrl ? (
            <img
              alt="Business Logo"
              src={activeBusiness?.imageUrl}
              style={{
                height: 125,
                objectFit: 'fill',
                borderRadius: 2.5,
                marginRight: 24,
              }}
            />
          ) : (
            <Box
              width={200}
              height={125}
              borderRadius={2.5}
              borderColor={Colors.inActiveGray}
              mr={3}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                borderStyle: 'dashed',
                borderWidth: 0.4,
              }}
            >
              <Typography variant="subtitle1">{t('missingLogo')}</Typography>
            </Box>
          )}
          <Typography variant="h4">{activeBusiness?.name}</Typography>
        </Box>
        <Box mt={6} mb={4.5}>
          <Typography variant="h6">{t('businessInformation')}</Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <PhoneIcon />
              </ListItemIcon>
              <ListItemText
                primary={activeBusiness?.phoneNumber || t('missing')}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <WebIcon />
              </ListItemIcon>
              <ListItemText primary={activeBusiness?.website || t('missing')} />
            </ListItem>
          </List>
        </Box>
        <Divider />
        <Box mt={6} display="flex" justifyContent="end">
          <PermissionRequired permissions={[Permissions.business.edit]}>
            <Button
              onClick={() => setShowEdit(true)}
              variant="outlined"
              color="primary"
            >
              {t('editBusiness')}
            </Button>
          </PermissionRequired>
        </Box>
      </Box>
      <Edit
        onSuccess={() => setSuccessMessage('Business Edited')}
        open={showEdit}
        onClose={() => setShowEdit(false)}
      />
    </Box>
  );
};

export default Business;

import React from 'react';
import Box from '@mui/material/Box';

function SVG5() {
  return (
    <div
      id="SVG5"
      style={{
        height: '70px',
        width: '70px',
        display: 'flex',
      }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          height: '45px',
          width: '45px',
          stroke: '#ffffff',
          strokeWidth: 2.0,
          margin: 'auto',
        }}
      >
        <path
          d="M6.72 9.56H5.78C4.77829 9.55736 3.81837 9.15826 3.11005 8.44995C2.40174 7.74163 2.00264 6.78171 2 5.78C2 3.7 3.7 2 5.78 2H7.67C8.41975 2.00264 9.13804 2.30164 9.6682 2.8318C10.1984 3.36196 10.4974 4.08025 10.5 4.83V17.1C10.5 18.14 9.65 18.99 8.61 18.99C7.57 18.99 6.72 18.14 6.72 17.1V9.56Z"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.78 6.72C5.53069 6.72 5.2916 6.62096 5.11532 6.44468C4.93903 6.2684 4.84 6.0293 4.84 5.78C4.84 5.5307 4.93903 5.2916 5.11532 5.11532C5.2916 4.93904 5.53069 4.84 5.78 4.84M17.28 9.56H18.22C20.3 9.56 22 7.86 22 5.78C22 3.7 20.3 2 18.22 2H16.33C15.5802 2.00264 14.862 2.30164 14.3318 2.8318C13.8016 3.36196 13.5026 4.08025 13.5 4.83V17.1C13.5 18.14 14.35 18.99 15.39 18.99C16.43 18.99 17.28 18.14 17.28 17.1V9.56Z"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.22 6.71997C18.4693 6.71997 18.7084 6.62093 18.8847 6.44465C19.061 6.26836 19.16 6.02927 19.16 5.77997C19.16 5.53066 19.061 5.29157 18.8847 5.11529C18.7084 4.939 18.4693 4.83997 18.22 4.83997M8.5 22V19M15.5 22V19"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export default SVG5;

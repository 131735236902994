// Calendar Helpers
import Moment from 'moment';

export const DOW = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

export const createInitialCalendar = (): any => {
  try {
    const today = Moment();
    const firstDay = today
      .clone()
      .startOf('month')
      .startOf('week');
    const lastDay = today
      .clone()
      .endOf('month')
      .endOf('week');

    const calendar: any = {
      firstOfMonth: today.clone().startOf('month'),
      weeks: [],
    };
    const date = firstDay;
    while (date.isBefore(lastDay, 'day')) {
      calendar.weeks.push(
        Array(7)
          .fill(0)
          .map(() => {
            const d = date.clone();
            date.add(1, 'day').clone();
            return {
              date: d,
              disabled: d.isBefore(today, 'day'),
            };
          }),
      );
    }

    return calendar;
  } catch (e) {
    console.error(`Error creating calendar: ${e}`);
    return null;
  }
};

export const createPreviousMonthCalendar = (current): any => {
  try {
    const today = Moment();
    const previous = Moment(current).subtract(1, 'months');
    if (previous.isSame(today, 'month')) {
      return createInitialCalendar();
    }
    const firstDay = previous
      .clone()
      .startOf('month')
      .startOf('week');
    const lastDay = previous
      .clone()
      .endOf('month')
      .endOf('week');

    const calendar: any = {
      firstOfMonth: previous.clone().startOf('month'),
      weeks: [],
    };
    const date = firstDay;
    while (date.isBefore(lastDay, 'day')) {
      calendar.weeks.push(
        Array(7)
          .fill(0)
          .map(() => {
            const d = date.clone();
            date.add(1, 'day').clone();
            return {
              date: d,
              disabled: false,
            };
          }),
      );
    }

    return calendar;
  } catch (e) {
    console.error(`Error creating calendar: ${e}`);
    return null;
  }
};

export const createNextMonthCalendar = (current): any => {
  try {
    const next = Moment(current).add(1, 'months');
    const firstDay = next
      .clone()
      .startOf('month')
      .startOf('week');
    const lastDay = next
      .clone()
      .endOf('month')
      .endOf('week');

    const calendar: any = {
      firstOfMonth: next.clone().startOf('month'),
      weeks: [],
    };
    const date = firstDay;
    while (date.isBefore(lastDay, 'day')) {
      calendar.weeks.push(
        Array(7)
          .fill(0)
          .map(() => {
            const d = date.clone();
            date.add(1, 'day').clone();
            return {
              date: d,
              disabled: false,
            };
          }),
      );
    }

    return calendar;
  } catch (e) {
    console.error(`Error creating calendar: ${e}`);
    return null;
  }
};

export const isSameDate = (first, second): any => {
  try {
    return first.isSame(second, 'day');
  } catch (e) {
    console.error(`Error checking dates: ${e}`);
    return false;
  }
};

export const isDateInRange = (date, startDate, endDate): any => {
  try {
    if (date.isBefore(endDate) && date.isAfter(startDate)) {
      return true;
    }
    return false;
  } catch (e) {
    console.error(`Error checking dates: ${e}`);
    return false;
  }
};

export const isPreviousMonthAllowed = (current): any => {
  try {
    const today = Moment();
    if (current.isSame(today, 'month')) {
      return false;
    }
    return true;
  } catch (e) {
    console.error(`Issue getting previous month`);
    return false;
  }
};

import React from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';

import { FaMagnet } from 'react-icons/fa6';
import { HiUserGroup } from 'react-icons/hi';
import { MdTouchApp } from 'react-icons/md';
import { IoEyeSharp } from 'react-icons/io5';

import ActionsIcon from 'assets/images/actions_icon.svg';
import EngagementsIcon from 'assets/images/engagements_icon.svg';
import ImpressionsIcon from 'assets/images/impressions_icon.svg';
import TeamMembersIcon from 'assets/images/team_members_icon.svg';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import MetricsItem from './MetricsItem';

const MetricsContainer = ({
  conversions,
  engagements,
  impressions,
  engagementsIncrease,
  impressionsIncrease,
  conversionsIncrease,
  teamMembers,
  selectedTimeFrame,
  isLoading,
}) => {
  const { t } = useTranslation();

  const METRICS_ARRAY = [
    {
      name: 'engagements',
      title: t('engagements'),
      icon: (
        <FaMagnet
          fontSize={55}
          color="#007AFF"
          style={{ transform: 'rotateY(0deg) rotate(45deg)', margin: 2 }}
        />
      ),
      showIncrease: true,
    },
    {
      name: 'impressions',
      title: t('views'),
      icon: <IoEyeSharp fontSize={60} color="#007AFF" />,
      showIncrease: true,
    },
    {
      name: 'conversions',
      title: t('actions'),
      icon: (
        <MdTouchApp
          fontSize={60}
          color="#007AFF"
          style={{ transform: 'rotateY(0deg) rotate(-25deg)' }}
        />
      ),
      showIncrease: true,
    },
    {
      name: 'teamMembers',
      title: t('teamMembers'),
      icon: <HiUserGroup fontSize={60} color="#007AFF" />,
      showIncrease: false,
    },
  ];

  const getCount = (type) => {
    switch (type) {
      case 'engagements':
        return engagements;
      case 'impressions':
        return impressions;
      case 'conversions':
        return conversions;
      case 'teamMembers':
        return teamMembers;
      default:
        return 0;
    }
  };

  const getIncrease = (type) => {
    switch (type) {
      case 'engagements':
        return engagementsIncrease;
      case 'impressions':
        return impressionsIncrease;
      case 'conversions':
        return conversionsIncrease;
      default:
        return 0;
    }
  };

  const renderMetrics = (metrics) =>
    metrics.map((metric) => (
      <Box key={metric.name}>
        <MetricsItem
          count={getCount(metric.name)}
          selectedTimeFrame={selectedTimeFrame}
          increase={getIncrease(metric.name)}
          showIncrease={metric.showIncrease}
          title={metric.title}
          icon={metric.icon}
        />
      </Box>
    ));

  return (
    <Box width="100%" position="relative" height="100%">
      <Box
        display="grid"
        // height="100%"
        sx={{
          gridTemplateColumns: '1fr 1fr',
          gridTemplateRows: '1fr 1fr',
          gap: '25px',
        }}
      >
        {renderMetrics(METRICS_ARRAY)}
      </Box>
      {isLoading && (
        <Box
          position="absolute"
          top={0}
          bottom={0}
          left={0}
          right={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

MetricsContainer.propTypes = {
  conversions: PropTypes.number.isRequired,
  engagements: PropTypes.number.isRequired,
  impressions: PropTypes.number.isRequired,
  engagementsIncrease: PropTypes.number.isRequired,
  impressionsIncrease: PropTypes.number.isRequired,
  conversionsIncrease: PropTypes.number.isRequired,
  teamMembers: PropTypes.number.isRequired,
  selectedTimeFrame: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

MetricsContainer.defaultProps = {
  isLoading: false,
};

export default MetricsContainer;

import React from 'react';

import Colors from 'constants/colors';
import { defaultStyles } from 'constants/styles';

import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
// import Drawer from '@mui/material/Drawer/Drawer';
import AddIcon from '@mui/material/SvgIcon/SvgIcon';

import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

// eslint-disable-next-line prefer-arrow-callback
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any> | React.ReactNode | any;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles: any = makeStyles({
  common: {
    color: Colors.primaryBlack,
    textTransform: 'capitalize',
  },
  bold: {
    fontWeight: 'bold',
  },
  thin: {
    fontWeight: 100,
  },
  small: {
    fontSize: 14,
  },
  medium: {
    fontSize: 18,
  },
  big: {
    fontSize: 22,
  },
  grey: {
    opacity: '.5',
  },
});

const CalendarModal: React.FC<any> = ({
  children,
  open,
  onClose,
  title,
  description,
  padding,
}) => (
  <Dialog
    open={open}
    TransitionComponent={Transition}
    keepMounted
    onClose={() => onClose(false)}
    sx={{
      '& .MuiDialog-container': {
        alignItems: 'center',
        justifyContent: 'center',
      },
      '& .MuiPaper-root': {
        maxWidth: 'fit-content',
        maxHeight: 'fit-content',
        overflow: 'hidden',
      },
    }}
    PaperProps={{ sx: { borderRadius: '15px' } }}
  >
    <Box flex={1}>{children}</Box>
  </Dialog>
);

const Title = ({ children, size, weight, color, className }) => {
  const classes: any = useStyles();
  return (
    <Typography
      sx={{ color: '#ffffff !important' }}
      className={`${classes[size]} ${classes.common} ${classes[weight]} ${className}`}
    >
      {children}
    </Typography>
  );
};

Title.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  size: PropTypes.string.isRequired,
  weight: PropTypes.any,
  color: PropTypes.any,
  className: PropTypes.object,
};

Title.defaultProps = {
  weight: null,
  color: null,
  className: null,
};

export default CalendarModal;

import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { integrationsActions } from 'store/entities/Integrations';

import IntegrationRow from './components/IntegrationRow';

const Integrations = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [mailChimpData, setMailChimpData] = useState<any>(null);

  useEffect(() => {
    dispatch(
      integrationsActions.getIntegrations({
        callBack: (data) => {
          if (data.mailchimp) {
            setMailChimpData(data.mailchimp);
          }
        },
      }),
    );
  }, []);

  return (
    <div>
      <Typography variant="h5">{t('integrations')}</Typography>
      <Box mt={6.25}>
        <IntegrationRow
          title={t('mailchimp')}
          description={t('mailchimpDescription')}
          link="https://mailchimp.com/"
          status={mailChimpData ? mailChimpData.status : null}
          authLink={mailChimpData ? mailChimpData.link : null}
        />
      </Box>
    </div>
  );
};

export default Integrations;

import React from 'react';

import Facebook from '@mui/icons-material/Facebook';
import { Instagram, LinkedIn, Twitter } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import FacebookIcon from 'assets/images/facebook.svg';
import InstagramIcon from 'assets/images/instagram.svg';
import LinkedInIcon from 'assets/images/linkedin.svg';
import TwitterIcon from 'assets/images/twitter.svg';
import PropTypes from 'prop-types';

const Social = ({ data }) => (
  <Box width="100%" height="100%" display="flex" justifyContent="center">
    {data.facebook !== null &&
    typeof data.facebook !== 'undefined' &&
    data.facebook !== '' ? (
      <Paper
        elevation={2}
        sx={{
          width: 40,
          height: 40,
          borderRadius: data?.styling?.buttonBorder,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: 0.5,
          marginRight: 0.5,
          color: data.styling?.buttonColor,
        }}
      >
        <Facebook />
      </Paper>
    ) : null}
    {data.twitter !== null &&
    typeof data.twitter !== 'undefined' &&
    data.twitter !== '' ? (
      <Paper
        elevation={2}
        sx={{
          width: 40,
          height: 40,
          borderRadius: data?.styling?.buttonBorder,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: 0.5,
          marginRight: 0.5,
          color: data.styling?.buttonColor,
        }}
      >
        <Twitter />
      </Paper>
    ) : null}
    {data.linkedin !== null &&
    typeof data.linkedin !== 'undefined' &&
    data.linkedin !== '' ? (
      <Paper
        elevation={2}
        sx={{
          width: 40,
          height: 40,
          borderRadius: data?.styling?.buttonBorder,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: 0.5,
          marginRight: 0.5,
          color: data.styling?.buttonColor,
        }}
      >
        <LinkedIn />
      </Paper>
    ) : null}
    {data.instagram !== null &&
    typeof data.instagram !== 'undefined' &&
    data.instagram !== '' ? (
      <Paper
        elevation={2}
        sx={{
          width: 40,
          height: 40,
          borderRadius: data?.styling?.buttonBorder,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: 0.5,
          marginRight: 0.5,
          color: data.styling?.buttonColor,
        }}
      >
        <Instagram />
      </Paper>
    ) : null}
  </Box>
);

Social.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Social;

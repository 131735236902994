import React from 'react';
import Permissions from 'constants/permissions';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'store';
// import { subscriptionsSelectors } from 'store/entities/Subscriptions';

import PermissionRequired from 'components/PermissionRequired';

import navigationLinks from './navigationLinks';

export interface LinkItemProps {
  path: string;
  icon: JSX.Element;
  iconFocus: JSX.Element;
  display: string;
  navURl: string;
}

const LinkItem: React.FC<LinkItemProps> = ({
  path,
  icon,
  iconFocus,
  display,
  navURl,
}) => (
  <ListItem
    // sx={{
    //   width: '88%',
    //   mb: 0.5,
    //   mx: 'auto',
    //   borderRadius: navURl === path.split('/')[1] ? '10px' : '0px',
    //   backgroundColor:
    //     navURl === path.split('/')[1] ? Colors.bgBlueLight : 'none',
    //   color: navURl === path.split('/')[1] ? Colors.blue : Colors.lightGrayV2,
    //   '&:hover': {
    //     borderRadius: '10px',
    //     backgroundColor:
    //       navURl === path.split('/')[1] ? Colors.bgBlueLight : 'none',
    //     color: navURl === path.split('/')[1] ? Colors.blue : Colors.lightGrayV2,
    //   },
    // }}
    button
    component={Link}
    to={path}
  >
    <ListItemIcon>
      {/* {navURl === path.split('/')[1] ? iconFocus : icon} */}
      {icon}
    </ListItemIcon>
    <ListItemText
      // sx={{
      //   '& .MuiTypography-root': {
      //     fontWeight: navURl === path.split('/')[1] ? 600 : 400,
      //   },
      // }}
      primary={display}
    />
  </ListItem>
);

export const SubscriptionNavRequired = ({ status, children }) => {
  if (process.env.REACT_APP_PERMISSION === 'disable') {
    return children;
  }

  if (status) return children;
  return null;
};

export const VendorNavRequired = ({ vendor, children }) => {
  if (vendor) return children;
  return null;
};

const FullWidthNavigation = ({ navURl }) => {
  const { user, role, vendorId } = useSelector(
    (state: RootState) => state.user,
  );

  const { accountStatus } = useSelector(
    (state: RootState) => state.subscriptions,
  );

  // const acctStatus = useSelector(subscriptionsSelectors.getAccountStatus);

  return (
    <Box display="flex" flexDirection="column" height={1}>
      <Box display="flex" alignItems="center" p={2}>
        <Avatar
          alt="Profile Photo"
          sx={{ mr: 2 }}
          src={user?.photo_url || null}
        />
        {/* color={Colors.blue} */}
        <Typography fontWeight={500}>{user?.fullName}</Typography>
      </Box>
      <List>
        {!vendorId && (
          <LinkItem
            path={navigationLinks.dashboard.path}
            navURl={navURl}
            icon={navigationLinks.dashboard.icon}
            iconFocus={navigationLinks.dashboard.iconFocus}
            display={navigationLinks.dashboard.display}
          />
        )}
        <VendorNavRequired vendor={vendorId}>
          <LinkItem
            navURl={navURl}
            path={navigationLinks.templates.path}
            icon={navigationLinks.templates.icon}
            iconFocus={navigationLinks.templates.iconFocus}
            display={navigationLinks.templates.display}
          />
        </VendorNavRequired>
        <SubscriptionNavRequired status={accountStatus}>
          <>
            <PermissionRequired permissions={[Permissions.campaign.view]}>
              <LinkItem
                navURl={navURl}
                path={navigationLinks.campaigns.path}
                icon={navigationLinks.campaigns.icon}
                iconFocus={navigationLinks.campaigns.iconFocus}
                display={navigationLinks.campaigns.display}
              />
            </PermissionRequired>

            {/* <LinkItem
              path={navigationLinks.announcements.path}
              icon={navigationLinks.announcements.icon}
              display={navigationLinks.announcements.display}
            /> */}
            <PermissionRequired permissions={[Permissions.teamHub.view]}>
              <LinkItem
                navURl={navURl}
                path={navigationLinks.teamHub.path}
                icon={navigationLinks.teamHub.icon}
                iconFocus={navigationLinks.teamHub.iconFocus}
                display={navigationLinks.teamHub.display}
              />
            </PermissionRequired>
            <PermissionRequired permissions={[Permissions.user.view]}>
              <LinkItem
                navURl={navURl}
                path={navigationLinks.members.path}
                icon={navigationLinks.members.icon}
                iconFocus={navigationLinks.members.iconFocus}
                display={navigationLinks.members.display}
              />
            </PermissionRequired>
            <PermissionRequired permissions={[Permissions.team.view]}>
              <LinkItem
                navURl={navURl}
                path={navigationLinks.teams.path}
                icon={navigationLinks.teams.icon}
                iconFocus={navigationLinks.teams.iconFocus}
                display={navigationLinks.teams.display}
              />
            </PermissionRequired>
            <PermissionRequired permissions={[Permissions.report.create]}>
              <LinkItem
                navURl={navURl}
                path={navigationLinks.reporting.path}
                icon={navigationLinks.reporting.icon}
                iconFocus={navigationLinks.reporting.iconFocus}
                display={navigationLinks.reporting.display}
              />
            </PermissionRequired>
            {/* <PermissionRequired permissions={[Permissions.campaign.create]}>
              <ListItem
                sx={{
                  mt: 3,
                  width: '88%',
                  mx: 'auto',
                  borderRadius: '10px',
                  backgroundColor: Colors.blue,
                  color: Colors.white,
                  '&:hover': {
                    borderRadius: '10px',
                    backgroundColor: Colors.blue,
                    color: Colors.white,
                  },
                }}
                button
                component={Link}
                to="/campaigns/build"
              >
                <ListItemText
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    '& .MuiTypography-root': { fontWeight: 500 },
                  }}
                  primary={
                    <Box gap={0.5} display="flex" alignItems="center">
                      Create Campaign
                      <AddIcon />
                    </Box>
                  }
                />
              </ListItem>
            </PermissionRequired> */}
          </>
        </SubscriptionNavRequired>
      </List>
      <Box flexGrow={1} />
      <List>
        <LinkItem
          navURl={navURl}
          path={navigationLinks.settings.path}
          icon={navigationLinks.settings.icon}
          iconFocus={navigationLinks.settings.iconFocus}
          display={navigationLinks.settings.display}
        />
      </List>
    </Box>
  );
};

export default FullWidthNavigation;

const Subscriptions = {
  premium: {
    type: 'Premium',
    name: 'Premium',
    price: '299',
    userLimit: 'Unlimited',
    locationLimit: '5',
    costAction: '0.25',
    includedAction: '2000',
  },
  premiumPro: {
    type: 'Premium Pro',
    name: 'Premium Pro',
    price: '499',
    userLimit: 'Unlimited',
    locationLimit: '10',
    costAction: '0.20',
    includedAction: '5000',
  },
  custom: {
    type: 'Entreprise',
  },
};

export default Subscriptions;

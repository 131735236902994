import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { campaignActions } from 'store/entities/Campaigns';

import MatDrawer from 'components/Elements/MatDrawer/MatDrawer';
import MatFormLabel from 'components/Elements/MatFormLabel/FormLabel';
import MatInput from 'components/Elements/MatInput/MatInput';

const CreateLinkDrawer = ({ open, onClose, campaignId, type }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
    setName('');
  }, []);

  const handleCreate = () => {
    if (name !== '' && name !== null) {
      setLoading(true);
      dispatch(
        campaignActions.createGenericLink({
          name,
          campaignId,
          callback: () => {
            setName('');
            setLoading(false);
            onClose();
          },
        }),
      );
    }
  };

  return (
    <MatDrawer
      open={open}
      anchor="right"
      onClose={onClose}
      onCancel={onClose}
      onSubmit={handleCreate}
      title="Create Generic Link"
      submitBtn="Create"
      cancelBtn="Cancel"
      isLoading={loading}
    >
      <Box width="100%" mt={0.5} maxWidth="min(450px, calc(100vw - 20px))">
        <Typography variant="subtitle2">
          Create a link that is not associated with a specific Team Member.
          These links can help track data in email blasts or sharing on social
          media.
        </Typography>
        <Box mt={3}>
          <MatFormLabel>Name</MatFormLabel>
          <MatInput
            value={name}
            variant="outlined"
            placeholder=""
            onChange={(e) => setName(e.target.value)}
            helperText="Ex. Corporate Email, Facebook, Twitter Campaign"
            size="small"
          />
        </Box>
      </Box>
    </MatDrawer>
  );
};

CreateLinkDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  campaignId: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default CreateLinkDrawer;

import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import MatDrawer from 'components/Elements/MatDrawer/MatDrawer';

const ActiveBusinessDrawer = ({ open, onClose, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <MatDrawer
      open={open}
      anchor="right"
      onClose={onClose}
      onCancel={onClose}
      onSubmit={onSubmit}
      title={t('switchBusinessAccount')}
      submitBtn={t('switch')}
    >
      <Box maxWidth={450} mt={2}>
        <Typography variant="h6">
          {t('areYouSureSwitch')}
        </Typography>
      </Box>
    </MatDrawer>
  );
};

ActiveBusinessDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ActiveBusinessDrawer;

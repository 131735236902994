import React from 'react';

import { Typography, Box } from '@mui/material';

const Empty = () => (
  <Box sx={{
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    px: 2.5,
  }}
  >
    <Typography variant="h6">
      To get started, click and drag an element from the left and drop it in
      the phone.
    </Typography>
  </Box>
);

export default Empty;

/* eslint-disable no-confusing-arrow */
import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';

import { Button, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { InvitsActions } from 'store/entities/Invits';
import { teamsActions, teamsSelectors } from 'store/entities/Teams';
import CreateTeam from 'views/Teams/components/CreateTeam';
import * as Yup from 'yup';

import CreateLocation from 'components/CreateLocation';
import CustomModalV2 from 'components/Elements/CustomModalV2/CustomModalV2';
import MatButton from 'components/Elements/MatButton/MatButton';
import MatDrawer from 'components/Elements/MatDrawer/MatDrawer';
import Title from 'components/Elements/Title/Title';

import EmailTab from './EmailTab';
import QrCodeTab from './QrCodeTab';
import TabBar from './TabBar';

const InviteDrawer = ({ teamMemberList, open, onClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [multipleEntry, setMultipleEntry] = useState<any>(false);
  const [emailList, setEmailList] = useState(['']);
  const [multiEmails, setMultiEmails] = useState([]);
  const [firstNameList, setFirstNameList] = useState(['']);
  const [lastNameList, setLastNameList] = useState(['']);
  const [emailErrors, setEmailErrors] = useState<any>([false]);
  const [errorMessage, setErrorMessage] = useState('');
  const [removeErrorMessage, setRemoveErrorMessage] = useState(true);
  const [locationId, setLocationId] = useState<any>(null);
  const [activeTab, setActiveTab] = useState(0);
  const [inviteLink, setInviteLink] = useState();
  const [showLocationCreationDrawer, setShowLocationCreationDrawer] = useState(
    false,
  );
  const [loadingActiveLocations, setloadingActiveLocations] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  /* const { activeLocations, loadingActiveLocations } =
    useSelector((state: any) => state.locationsState); */
  /* const { loadingInvite } = useSelector((state: any) => state.teamMembersState); */
  const activeLocations = useSelector(teamsSelectors.getActiveTeams);
  const noLocation =
    !loadingActiveLocations && activeLocations && !activeLocations.length;
  useEffect(() => {
    dispatch(teamsActions.fetchTeams({ filter: { status: 'active' } }));
    setloadingActiveLocations(true);
  }, []);

  useEffect(() => {
    setEmailList(['']);
    setMultiEmails([]);
    setFirstNameList(['']);
    setLastNameList(['']);
    setEmailErrors(false);
    setErrorMessage('');
  }, [open]);

  useEffect(() => {
    if (activeLocations && activeLocations.length > 0) {
      setLocationId(activeLocations[0].id);
    }
    setloadingActiveLocations(false);
  }, [activeLocations]);

  const sendEmails = (emails) => {
    dispatch(
      InvitsActions.inviteMember({
        teamId: locationId,
        emails,
        firstNames: firstNameList,
        lastNames: lastNameList,
        onSuccess: handleSuccess,
      }),
    );

    onClose();
    if (multipleEntry) {
      setMultiEmails([]);
    }
  };

  const schema = Yup.object().shape({
    emailList: Yup.lazy((val) =>
      Array.isArray(val)
        ? Yup.array().of(
            Yup.string()
              .email('Invalid email')
              .required('Email is Required'),
          )
        : Yup.string()
            .email('Invalid email')
            .required('Email is Required'),
    ),
    firstNameList: Yup.lazy((val) =>
      Array.isArray(val)
        ? Yup.array().of(
            Yup.string()
              .min(2, 'First name is too short.')
              .max(50, 'First name is too long.')
              .required('First Name is Required'),
          )
        : Yup.string()
            .min(2, 'First Name is too short.')
            .max(50, 'First Name is too long.')
            .required('First Name is Required'),
    ),
    lastNameList: Yup.lazy((val) =>
      Array.isArray(val)
        ? Yup.array().of(
            Yup.string()
              .min(2, 'Last name is too short.')
              .max(50, 'Last name is too long.')
              .required('Last name is Required'),
          )
        : Yup.string()
            .min(2, 'Last name is too short.')
            .max(50, 'Last name is too long.')
            .required('Last name is Required'),
    ),
  });

  const schemaV2 = Yup.object().shape({
    multiEmails: Yup.lazy((val) =>
      Array.isArray(val)
        ? Yup.array().of(
            Yup.string()
              .email('Invalid email')
              .required('Email is Required'),
          )
        : Yup.string()
            .email('Invalid email')
            .required('Email is Required'),
    ),
  });

  const handleEmailTabSubmitV2 = React.useCallback(async () => {
    // Prevent form from submitting:
    // e.preventDefault();
    setRemoveErrorMessage(false);
    if (multipleEntry) {
      if (multiEmails.length < 2) {
        setErrorMessage(t('enterAtLeastTwoEmails'));
      } else {
        const isFormValid = await schemaV2.isValid(
          { multiEmails },
          {
            abortEarly: false, // Prevent aborting validation after first error
          },
        );
        if (isFormValid) {
          sendEmails(multiEmails);
        } else {
          schemaV2
            .validate(
              { multiEmails },
              {
                abortEarly: false,
              },
            )
            .catch((err) => {
              setErrorMessage(err.inner[0].message);
            });
        }
      }
    } else {
      const isFormValid = await schema.isValid(
        { firstNameList, lastNameList, emailList },
        {
          abortEarly: false, // Prevent aborting validation after first error
        },
      );
      if (isFormValid) {
        sendEmails(emailList);
      } else {
        schema
          .validate(
            { firstNameList, lastNameList, emailList },
            {
              abortEarly: false,
            },
          )
          .catch((err) => {
            setErrorMessage(err.inner[0].message);
          });
      }
    }
  }, [{ firstNameList, lastNameList, emailList, multiEmails }]);

  const handleEmailTabSubmit = () => {
    const reg = /\S+@\S+\.\S+/;
    if (multipleEntry) {
      let err = false;
      if (multiEmails.length < 2) {
        err = true;
        setErrorMessage(t('enterAtLeastTwoEmails'));
      } else {
        for (let x = 0; x < multiEmails.length; x++) {
          if (!err) {
            if (!reg.test(multiEmails[x])) {
              setErrorMessage(`${t('invalidEmail')} ${multiEmails[x]}`);
              err = true;
            }
            // if (firstNameList[x].length < 2) {
            //   setErrorMessage(`First Name is too short: ${firstNameList[x]}`);
            //   err = true;
            // }
            // if (firstNameList[x].length > 50) {
            //   setErrorMessage(`First Name is too long: ${firstNameList[x]}`);
            //   err = true;
            // }
            // if (lastNameList[x].length < 2) {
            //   setErrorMessage(`Last Name is too short: ${lastNameList[x]}`);
            //   err = true;
            // }
            // if (lastNameList[x].length > 50) {
            //   setErrorMessage(`Last Name is too long: ${lastNameList[x]}`);
            //   err = true;
            // }
          }
        }
      }
      if (!err) {
        sendEmails(multiEmails);
      }
    } else {
      let errorPresent = false;
      // eslint-disable-next-line
      let errors: any = [];
      for (let x = 0; x < emailList.length; x++) {
        if (!reg.test(emailList[x])) {
          errors[x] = true;
          errorPresent = true;
          setErrorMessage(t('enterValidEmail'));
        }
        if (firstNameList[x].length < 2) {
          setErrorMessage(`First Name is too short: ${firstNameList[x]}`);
          errors[x] = true;
          errorPresent = true;
        }
        if (firstNameList[x].length > 50) {
          setErrorMessage(`First Name is too long: ${firstNameList[x]}`);
          errors[x] = true;
          errorPresent = true;
        }
        if (lastNameList[x].length < 2) {
          setErrorMessage(`Last Name is too short: ${lastNameList[x]}`);
          errors[x] = true;
          errorPresent = true;
        }
        if (lastNameList[x].length > 50) {
          setErrorMessage(`Last Name is too long: ${lastNameList[x]}`);
          errors[x] = true;
          errorPresent = true;
        }
      }

      if (errorPresent) {
        setEmailErrors(errors);
      } else {
        sendEmails(emailList);
      }
    }
  };

  const handleQrCodeTabSubmit = () => {
    dispatch(
      InvitsActions.inviteMember({
        teamId: locationId,
        mass: true,
        onSuccess: handleSuccess,
      }),
    );
  };

  const handleSuccess = (invite) => {
    enqueueSnackbar('Invite Created SuccessFully', { variant: 'success' });
    onClose();
    setInviteLink(invite.dynamic_link);
  };

  const handleDownload = () => {
    const canvas: any = document.getElementById('qr-code-id-drawer');
    const drawer = document.createElement('canvas');
    const pngUrl = canvas.toDataURL('image/png');
    const ctx = drawer.getContext('2d');
    const imageObj1 = new Image();
    const imageObj2 = new Image();
    imageObj1.src = 'tappshareqr.png';
    drawer.width = 2108;
    drawer.height = 2730;
    if (ctx) {
      imageObj1.onload = () => {
        ctx.drawImage(imageObj1, 0, 0, 2108, 2730);
        imageObj2.src = pngUrl;
        imageObj2.onload = () => {
          ctx.drawImage(imageObj2, 1817, 2434, 215, 215);
          const img = drawer
            .toDataURL('image/png')
            .replace('image/png', 'image/octet-stream');
          const downloadLink = document.createElement('a');
          downloadLink.href = img;
          downloadLink.download = 'seteams-qr-code.png';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        };
      };
    }
  };

  const handleSubmit = () => {
    switch (activeTab) {
      case 0:
        return handleEmailTabSubmitV2();
      case 1:
        if (inviteLink) {
          return handleDownload();
        }
        return handleQrCodeTabSubmit();
      default:
        return null;
    }
  };

  const renderTab = () => {
    switch (activeTab) {
      case 0:
        return (
          <EmailTab
            setShowLocationCreationDrawer={setShowLocationCreationDrawer}
            activeLocations={activeLocations || []}
            multipleEntry={multipleEntry}
            setMultipleEntry={setMultipleEntry}
            emailList={emailList}
            setEmailList={setEmailList}
            multiEmails={multiEmails}
            setMultiEmails={setMultiEmails}
            firstNameList={firstNameList}
            setFirstNameList={setFirstNameList}
            lastNameList={lastNameList}
            setLastNameList={setLastNameList}
            emailErrors={emailErrors}
            setEmailErrors={setEmailErrors}
            setErrorMessage={setErrorMessage}
            setRemoveErrorMessage={setRemoveErrorMessage}
            locationId={locationId}
            setLocationId={setLocationId}
          />
        );
      case 1:
        return (
          <QrCodeTab
            setShowLocationCreationDrawer={setShowLocationCreationDrawer}
            activeLocations={activeLocations || []}
            locationId={locationId}
            setLocationId={setLocationId}
            inviteLink={inviteLink}
          />
        );
      default:
        return null;
    }
  };

  const getSubmitLabel = () => {
    switch (activeTab) {
      case 0:
        return t('invite');
      case 1:
        if (inviteLink) {
          return t('download');
        }
        return t('generateCode');
      default:
        return t('invite');
    }
  };

  const renderContent = () => {
    if (loadingActiveLocations) {
      return (
        <div
          style={{
            height: 100,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      );
    }
    if (noLocation) {
      return (
        <div
          style={{
            textAlign: 'center',
            padding: '20px 0',
          }}
        >
          <Typography align="center" style={{ marginBottom: 20 }}>
            {t('noTeamFound')}
            <br />
            {t('oneTeamIsRequired')}
          </Typography>
          <MatButton onClick={() => setShowLocationCreationDrawer(true)}>
            {t('createNewTeam')}
          </MatButton>
        </div>
      );
    }
    return (
      <>
        <TabBar tab={activeTab} onChange={setActiveTab} />
        <Box mt={2.5}>{renderTab()}</Box>
        {removeErrorMessage === false && (
          <Box width="100%" mt={0.75} textAlign="center">
            <Typography variant="h6" sx={{ color: Colors.error }}>
              {errorMessage}
            </Typography>
          </Box>
        )}
      </>
    );
  };

  const handleCloseCreateLocation = () => {
    dispatch(teamsActions.fetchTeams({ filter: { status: 'active' } }));
    setShowLocationCreationDrawer(false);
  };

  const loadingUpdate = false;

  return (
    <>
      {/* <MatDrawer
        open={!showLocationCreationDrawer && open}
        anchor="center"
        onClose={onClose}
        onCancel={onClose}
        onSubmit={handleSubmit}
        title={t('inviteUsers')}
        submitBtn={getSubmitLabel()}
        cancelBtn={t('cancel')}
        isLoading={false}
      > */}
      <CustomModalV2
        open={!showLocationCreationDrawer && open}
        onClose={onClose}
        isLoading={loadingUpdate}
      >
        <Box width="100%" maxWidth={500}>
          <Box sx={{ mb: 1, mt: 2 }}>
            <Title size="big">{t('inviteUsers')}</Title>
          </Box>
          {renderContent()}
          <Grid item md={12} xs={12} sx={{ marginBottom: 1 }}>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Box display="flex" alignItems="center" gap={2}>
                {loadingUpdate ? (
                  <CircularProgress
                    style={{ width: 40, height: 40, color: Colors.primary }}
                  />
                ) : (
                  <>
                    <Button
                      disableElevation={true}
                      color="primary"
                      onClick={onClose}
                      sx={{
                        color: Colors.black,
                        textTransform: 'capitalize',
                      }}
                    >
                      {t('cancel')}
                    </Button>
                    <Button
                      disableElevation={true}
                      color="primary"
                      // eslint-disable-next-line no-confusing-arrow
                      onClick={handleSubmit}
                      sx={{
                        textTransform: 'capitalize',
                        fontWeight: 600,
                        background: '#D9EBFF',
                        borderRadius: '8px',
                        px: '18px',
                        py: '6px',
                        maxHeight: 'fit-content',
                        height: 'fit-content',
                        '&:hover': {
                          background: '#D9EBFF',
                        },
                      }}
                    >
                      {getSubmitLabel()}
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </Grid>
        </Box>
      </CustomModalV2>

      {/* <CreateLocation
        open={showLocationCreationDrawer}
        onClose={handleCloseCreateLocation}
      /> */}
      <CreateTeam
        open={showLocationCreationDrawer}
        onClose={() => {
          setShowLocationCreationDrawer(false);
        }}
        isEditing={false}
      />
    </>
  );
};

InviteDrawer.propTypes = {
  teamMemberList: PropTypes.array,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default InviteDrawer;

import React from 'react';

import Colors from 'constants/colors';

import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

const IntegrationRow = ({ title, link, description, status, authLink }) => {
  const renderEndComponent = () => {
    switch (status) {
      case 200:
        return (
          <Box display="flex" alignItems="center">
            <CheckIcon
              sx={{
                color: Colors.success,
                width: 24,
                height: 24,
                marginRight: 12,
              }}
            />
            <Typography sx={{ fontSize: 18, color: Colors.success }}>
              Connected
            </Typography>
          </Box>
        );
      case 500:
        return (
          <div>
            <Button
              variant="outlined"
              sx={{ color: Colors.error, borderColor: Colors.error }}
              component="a"
              href={authLink}
            >
              Disconnected
            </Button>
          </div>
        );
      case 404:
        return (
          <div>
            <Button
              variant="outlined"
              color="primary"
              component="a"
              href={authLink}
            >
              Link Account
            </Button>
          </div>
        );
      default:
        return (
          <div>
            <Button variant="outlined" color="primary" disabled>
              Link Account
            </Button>
          </div>
        );
    }
  };

  return (
    <Box width="100%" display="flex" pl={6.25} pr={6.25} alignItems="center">
      <Box
        maxWidth={500}
        sx={{
          '& a:visited': {
            color: Colors.primary,
          },
        }}
      >
        <a
          href={link}
          style={{
            textDecoration: 'none',
            color: Colors.primary,
            fontSize: 24,
            fontWeight: 'bold',
          }}
        >
          {title}
        </a>
        <Typography variant="subtitle2">{description}</Typography>
      </Box>
      <Box flexGrow={1} />
      {renderEndComponent()}
    </Box>
  );
};

IntegrationRow.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  status: PropTypes.number,
  link: PropTypes.string.isRequired,
  authLink: PropTypes.string,
};

IntegrationRow.defaultProps = {
  status: null,
  authLink: null,
};

export default IntegrationRow;

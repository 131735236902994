import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';

import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TablePagination from '@mui/material/TablePagination';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { membersSelectors } from 'store/entities/Members';
import { teamsSelectors } from 'store/entities/Teams';

import MatInput from '../Elements/MatInput/MatInput';

import Body from './components/Body';
import FilterButton from './components/FilterButton';
import FilterDrawer from './components/FilterDrawer';
import HeaderRow from './components/HeaderRow';
import Missing from './components/Missing';
import SelectedCount from './components/SelectedCount';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableContainer,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import FilterDropDown from './components/FilterDropDown';

const DROPDOWN = 'dropdown';
const DRAWER = 'drawer';

const TeamMemberSelectTable = ({
  handleTeamMemberSelect,
  selected,
  activeTeamMembers,
  filterType,
}) => {
  const [search, setSearch] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [rowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [filteredLocationId, setFilteredLocationId] = useState('all');
  const [teamMembers, setTeamMembers] = useState<any[]>([]);
  const [loadingActiveTeamMembers, setLoadingActiveTeamMembers] = useState(
    false,
  );
  const [searchMembers, setSearchMembers] = useState<any[]>([]);
  const [headerChecked, setHeaderChecked] = useState(false);
  const activeLocations = useSelector(teamsSelectors.getActiveTeams);

  useEffect(() => {
    if (search !== '') {
      const searchResults: any[] = [];
      teamMembers.forEach((member: any) => {
        if (member.name.toLowerCase().includes(search.toLowerCase())) {
          searchResults.push(member);
        }
      });
      searchResults.sort((a: any, b: any) => a.name.localeCompare(b.name));
      setPage(0);
      setSearchMembers(searchResults);
    }
  }, [search]);

  useEffect(() => {
    setLoadingActiveTeamMembers(false);
    setTeamMembers(activeTeamMembers);
  }, [activeTeamMembers]);

  useEffect(() => {
    if (activeTeamMembers.length > 0) {
      let selectedMembers: any[] = teamMembers;
      if (searchMembers.length > 0) {
        selectedMembers = searchMembers;
      }
      if (selectedMembers.length > 0) {
        let allChecked = true;
        selectedMembers.forEach((member: any) => {
          if (!selected.includes(member.id)) {
            allChecked = false;
          }
        });
        setHeaderChecked(allChecked);
      }
    }
  }, [selected]);

  const handleChangePage = (e, val) => {
    setPage(val);
  };

  const handleSelect = (id) => {
    const values: any[] = [];
    let inArray = false;
    selected.forEach((originalValue) => {
      if (originalValue === id) {
        inArray = true;
      } else {
        values.push(originalValue);
      }
    });
    if (!inArray) {
      values.push(id);
    }
    handleTeamMemberSelect(values);
  };

  const handleFilter = (location) => {
    setShowFilter(false);
    setFilteredLocationId(location);
    setPage(0);

    const filteredTeamMembers: any[] = [];
    activeTeamMembers.forEach((member: any) => {
      if (location !== 'all') {
        if (member.locationId === location) {
          filteredTeamMembers.push(member);
        }
      } else {
        filteredTeamMembers.push(member);
      }
    });
    filteredTeamMembers.sort((a: any, b: any) => a.name.localeCompare(b.name));
    setTeamMembers(filteredTeamMembers);
  };

  const handleSelectAll = () => {
    const newSelected = [...selected];
    let members: any[] = teamMembers;
    if (searchMembers.length > 0) {
      members = searchMembers;
    }
    if (headerChecked) {
      members.forEach((member: any) => {
        if (newSelected.includes(member.id)) {
          const index = newSelected.indexOf(member.id);
          if (index !== -1) {
            newSelected.splice(index, 1);
          }
        }
      });
    } else {
      members.forEach((member: any) => {
        if (!newSelected.includes(member.id)) {
          newSelected.push(member.id);
        }
      });
    }
    handleTeamMemberSelect(newSelected);
  };

  return (
    <Box
      component="div"
      p={1}
      width="100%"
      border={0.5}
      borderColor={Colors.lightBlueGray}
      borderRadius={1}
    >
      <Box display="flex" alignItems="center">
        <Box mb={2} flexGrow={2}>
          {/* <MatInput
            variant="outlined"
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search"
            value={search}
            size="small"
            sx={
              filterType === DROPDOWN
                ? {
                    '& .MuiInputBase-input': {
                      padding: '14px',
                    },
                  }
                : null
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          /> */}
          <Box
            sx={{
              borderRadius: '13px',
              backgroundColor: '#EEF0F2',
              paddingX: '15px',
              paddingY: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Search
              sx={{
                fontSize: '22px',
                marginRight: '10px',
                '& .MuiBox-root': {
                  width: '100%',
                },
              }}
            />
            <input
              style={{
                outline: 'none',
                border: 'none',
                backgroundColor: 'inherit',
                fontSize: '15px',
                width: '100%',
              }}
              type="text"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>
        </Box>
        {/* <Box flexGrow={1} sx={{ textAlign: 'center' }}>
          {filterType === DROPDOWN ? (
            <FilterDropDown
              options={activeLocations || []}
              value={filteredLocationId}
              onChange={handleFilter}
            />
          ) : (
            <FilterButton onClick={() => setShowFilter(true)} />
          )}
        </Box> */}
      </Box>
      {/* <SelectedCount
        count={selected.length}
        loading={loadingActiveTeamMembers}
      /> */}
      {teamMembers.length < 1 || (search !== '' && searchMembers.length < 1) ? (
        <Missing />
      ) : (
        <TableContainer>
          <Table
            sx={{
              borderSpacing: ' 0 10px !important',
              borderCollapse: 'separate !important' as any,
              '& .MuiTableCell-root': {
                border: 'none !important',
              },
            }}
          >
            <HeaderRow checked={headerChecked} onClick={handleSelectAll} />
            <Body
              teamMembers={search !== '' ? searchMembers : teamMembers}
              rowsPerPage={rowsPerPage}
              page={page}
              selected={selected}
              onSelect={handleSelect}
            />
          </Table>

          {/* <TablePagination
            component="div"
            rowsPerPageOptions={[5]}
            count={search !== '' ? searchMembers.length : teamMembers.length}
            rowsPerPage={rowsPerPage}
            labelDisplayedRows={() =>
              `${page + 1}-${Math.floor(
                (search !== '' ? searchMembers.length : teamMembers.length) /
                  rowsPerPage,
              ) + 1}`
            }
            page={page}
            onPageChange={handleChangePage}
          /> */}
        </TableContainer>
      )}
      {filterType === DRAWER && (
        <FilterDrawer
          open={showFilter}
          onClose={() => setShowFilter(false)}
          onSave={handleFilter}
          locations={activeLocations || []}
          filteredLocation={filteredLocationId}
        />
      )}
    </Box>
  );
};

TeamMemberSelectTable.propTypes = {
  handleTeamMemberSelect: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string),
  activeTeamMembers: PropTypes.array,
  filterType: PropTypes.string,
};

TeamMemberSelectTable.defaultProps = {
  selected: [],
  activeTeamMembers: [],
  filterType: DRAWER,
};

export default TeamMemberSelectTable;

import React from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

const CustomTooltip = ({ active, payload, label }) => {
  const labelStyle = (text) => {
    switch (text) {
      case 'Actions':
        return {
          color: '#FFCC80',
        };
      case 'Impressions':
        return {
          color: Colors.primary,
        };
      case 'Engagements':
        return {
          color: '#673AB7',
        };
      default:
        return {};
    }
  };

  if (active && payload && payload.length) {
    return (
      <Paper sx={{ width: 120, padding: 1, borderRadius: 2 }}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>
          {label}
        </Typography>
        {payload
          .sort((a, b) => {
            if (b.value > a.value) {
              return 1;
            }
            return -1;
          })
          .map((item) => (
            <Box
              pt={0.5}
              pb={0.5}
              display="flex"
              width="100%"
              alignItems="center"
            >
              <Typography
                style={labelStyle(item.name)}
                sx={{ flexGrow: 1, fontSize: 10 }}
              >
                {item.name}
              </Typography>
              <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                {item.value}
              </Typography>
            </Box>
          ))}
      </Paper>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.object,
  label: PropTypes.string,
};

CustomTooltip.defaultProps = {
  active: false,
  payload: null,
  label: '',
};

export default CustomTooltip;

import React from 'react';

import colors from 'constants/colors';
import Permissions from 'constants/permissions';

import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import HubIcon from '@mui/icons-material/Forum';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import LoyaltyOutlinedIcon from '@mui/icons-material/LoyaltyOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

export interface NavigationLink {
  path: string;
  display: string;
  icon: JSX.Element;
  iconFocus: JSX.Element;
  permissions?: string[];
}

const navigationLinks: Record<string, NavigationLink> = {
  dashboard: {
    path: '/',
    display: 'Dashboard',
    icon: <DashboardOutlinedIcon sx={{ color: colors.lightGrayV2 }} />,
    iconFocus: <DashboardOutlinedIcon sx={{ color: colors.blue }} />,
  },
  campaigns: {
    path: '/campaigns',
    display: 'Campaigns',
    icon: <LoyaltyOutlinedIcon sx={{ color: colors.lightGrayV2 }} />,
    iconFocus: <LoyaltyOutlinedIcon sx={{ color: colors.blue }} />,
  },
  templates: {
    path: '/templates',
    display: 'Templates',
    icon: <LoyaltyOutlinedIcon sx={{ color: colors.lightGrayV2 }} />,
    iconFocus: <LoyaltyOutlinedIcon sx={{ color: colors.blue }} />,
  },
  announcements: {
    path: '/announcements',
    display: 'Announcements',
    icon: <AnnouncementOutlinedIcon sx={{ color: colors.lightGrayV2 }} />,
    iconFocus: <AnnouncementOutlinedIcon sx={{ color: colors.blue }} />,
  },
  members: {
    path: '/members',
    display: 'Members',
    icon: <GroupOutlinedIcon sx={{ color: colors.lightGrayV2 }} />,
    iconFocus: <GroupOutlinedIcon sx={{ color: colors.blue }} />,
    permissions: [Permissions.user.view],
  },
  teams: {
    path: '/teams',
    display: 'Teams',
    icon: <GroupsIcon sx={{ color: colors.lightGrayV2 }} />,
    iconFocus: <GroupsIcon sx={{ color: colors.blue }} />,
    permissions: [Permissions.team.view],
  },
  teamHub: {
    path: '/teamHub',
    display: 'Team Hub',
    icon: <HubIcon sx={{ color: colors.lightGrayV2 }} />,
    iconFocus: <HubIcon sx={{ color: colors.blue }} />,
    permissions: [Permissions.teamHub.view],
  },
  reporting: {
    path: '/reporting',
    display: 'Reporting',
    icon: <AssessmentOutlinedIcon sx={{ color: colors.lightGrayV2 }} />,
    iconFocus: <AssessmentOutlinedIcon sx={{ color: colors.blue }} />,
    permissions: [Permissions.report.create],
  },
  settings: {
    path: '/settings',
    display: 'Settings',
    icon: <SettingsOutlinedIcon sx={{ color: colors.lightGrayV2 }} />,
    iconFocus: <SettingsOutlinedIcon sx={{ color: colors.blue }} />,
  },
};

export default navigationLinks;

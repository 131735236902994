import React, { useState, useEffect } from 'react';

import DateRangeIcon from '@mui/icons-material/DateRange';
import { FiLink } from 'react-icons/fi';
import { HiOutlineCalendar, HiCursorClick } from 'react-icons/hi';
import LinkIcon from '@mui/icons-material/Link';
import { Button, Box } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';

import Moment from 'moment';
import PropTypes from 'prop-types';

import CTATextField from 'components/CTATextField';
import DateRangeDrawer from 'components/DateRangeDrawer';
import MatDrawer from 'components/Elements/MatDrawer/MatDrawer';
import MatInput from 'components/Elements/MatInput/MatInput';
import { Save } from '@mui/icons-material';
import ActionDisplay from './ActionDisplay';
import { AutoCompleteInput } from 'components/AutoCompleteInput';

const ActionContainer = ({
  onSubmit,
  item,
  defaultCta,
  ctaOneType,
  handleActionButtonOne,
  deleteActionOne,
}) => {
  const [tab, setTab] = useState(0);
  const [redirect, setRedirect] = useState('');
  const [cta, setCta] = useState('');
  const [showDates, setShowDates] = useState(false);
  const [eventDates, setEventDates] = useState([
    Moment(),
    Moment().add(1, 'days'),
  ]);
  const [address, setAddress] = useState('');

  useEffect(() => {
    setTab(0);
    setCta(item?.ctaOne.name || defaultCta.name);
    setRedirect(item?.ctaOne.link || defaultCta.link);
  }, []);

  useEffect(() => {
    if (defaultCta && defaultCta.type !== 'none') {
      if (defaultCta.type === 'redirect') {
        setTab(0);
        setCta(item?.ctaOne.name || defaultCta.name);
        setRedirect(item?.ctaOne.link || defaultCta.link);
      }
      if (defaultCta.type === 'event') {
        setTab(1);
        setAddress(defaultCta.address);
        setEventDates([
          Moment(defaultCta.startDate),
          Moment(defaultCta.endDate),
        ]);
      }
    }
  }, [defaultCta]);

  const setInitialState = () => {
    setTab(0);
    setRedirect('');
    setCta('');
  };

  const handleSubmit = () => {
    if (tab === 0) {
      onSubmit({
        type: 'redirect',
        link: redirect,
        name: cta,
      });
    } else {
      onSubmit({
        type: 'event',
        startDate: eventDates[0].toISOString(),
        endDate: eventDates[1].toISOString(),
        address,
      });
    }
    // setInitialState();
  };

  const handleSaveDates = (dates) => {
    setEventDates(dates);
    setShowDates(false);
  };

  const handleCTA = (value) => {
    if (value.length < 24) {
      setCta(value);
    }
    if (tab === 0) {
      onSubmit({
        type: 'redirect',
        link: redirect,
        name: value,
      });
    }
  };

  const handleUrl = (value) => {
    setRedirect(value);
    if (tab === 0) {
      onSubmit({
        type: 'redirect',
        link: value,
        name: cta,
      });
    }
  };

  const placeholder = 'Call to Action';

  const suggestedOptions = [
    'Learn More',
    'Book Now',
    'Apply Now',
    'Contact Us',
    'Donate Now',
    'Download',
    'Request Time',
    'See Menu',
    'Shop Now',
    'Sign Up',
  ];

  const renderActionItems = () => {
    switch (tab) {
      case 0:
        return (
          <Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 2,
                width: '100%',
              }}
            >
              {/* <CTATextField
                placeholder="Button Text"
                size="small"
                onChange={handleCTA}
                value={cta}
              /> */}

              <AutoCompleteInput
                placeholder={placeholder}
                onChange={handleCTA}
                autoComplete={suggestedOptions}
                handlerValue={cta}
                isTeamHub={true}
              />

              <ActionDisplay
                type={ctaOneType}
                onClick={handleActionButtonOne}
                onDelete={deleteActionOne}
              />
            </Box>
            <Box sx={{ mb: 3, borderBottom: 1, borderColor: '#E3E5E7' }} />

            {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
              <Button
                sx={[
                  tab === 0 && {
                    backgroundColor: 'rgba(238, 240, 242, 1)',
                  },
                  {
                    p: 1.2,
                    borderRadius: '10px',
                  },
                ]}
                onClick={() => setTab(0)}
              >
                <FiLink />
              </Button>
              <Button
                sx={[
                  tab === 1 && {
                    backgroundColor: 'rgba(238, 240, 242, 1)',
                  },
                  {
                    p: 1.2,
                    borderRadius: '10px',
                  },
                ]}
                onClick={() => setTab(1)}
              >
                <HiOutlineCalendar />
              </Button>
            </Box> */}
            <Box
              sx={{
                marginBottom: 0.5,
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                Url
              </Typography>
              <FiLink />
            </Box>
            <MatInput
              sx={{
                backgroundColor: 'rgba(238, 240, 242, 1)',
                borderRadius: '10px',
                '& fieldset': {
                  border: 'none',
                },
              }}
              value={redirect}
              // placeholder="Redirect URL"
              onChange={(e) => handleUrl(e.target.value)}
              size="small"
            />
          </Box>
        );
      case 1:
        return (
          <Box mt={3}>
            <Box
              onClick={() => setShowDates(true)}
              onKeyDown={() => setShowDates(true)}
              tabIndex={0}
              role="button"
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                mb: 3,
              }}
            >
              <DateRangeIcon style={{ marginRight: 8 }} />
              <Typography variant="subtitle1">
                {`${eventDates[0].format(
                  'MMM D, YYYY h:mm',
                )} - ${eventDates[1].format('MMM D, YYYY h:mm')}`}
              </Typography>
            </Box>
            <MatInput
              sx={{
                backgroundColor: 'rgba(238, 240, 242, 1)',
                borderRadius: '10px',
                '& fieldset': {
                  border: 'none',
                },
              }}
              value={address}
              placeholder="Address (optional)"
              onChange={(e) => setAddress(e.target.value)}
              size="small"
            />
          </Box>
        );
      default:
        return <div />;
    }
  };

  return (
    <>
      <Box maxWidth={450}>{renderActionItems()}</Box>
      {/* <Button
        sx={{
          backgroundColor: '#2085FF',
          color: '#ffffff',
          py: 0.3,
          mt: 1.3,
          display: 'flex',
          ml: 'auto',
          '&:hover': {
            backgroundColor: '#2085FF',
          },
        }}
        onClick={handleSubmit}
      >
        Save
      </Button> */}
      <DateRangeDrawer
        open={showDates}
        onClose={() => setShowDates(false)}
        onSave={(val) => handleSaveDates(val)}
        allowTime={true}
        startDate={eventDates[0]}
        endDate={eventDates[1]}
      />
    </>
  );
};

ActionContainer.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  defaultCta: PropTypes.object,
};

ActionContainer.defaultProps = {
  defaultCta: null,
};

export default ActionContainer;

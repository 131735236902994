import React from 'react';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const AnnouncementAppBar = () => (
  <Box
    width="100%"
    height={40}
    pl={1}
    pr={1}
    pb={0.5}
    pt={0.5}
    display="flex"
    alignItems="center"
    sx={{
      boxShadow: '0 4px 2px -2px #BDBDBD',
    }}
  >
    <ArrowBackIosIcon sx={{ width: 16 }} />
    <Typography
      sx={{
        marginLeft: 6,
        color: 'black',
      }}
      variant="caption"
    >
      Announcements
    </Typography>
  </Box>
);

export default AnnouncementAppBar;

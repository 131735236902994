import React, { useState } from 'react';

import TimeFrames, { ITimeFrameValue } from 'constants/TimeFrames';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import Moment from 'moment';
import { useTranslation } from 'react-i18next';

import MatDrawer from 'components/Elements/MatDrawer/MatDrawer';
import CheckedRadio from 'components/Elements/RadioButtons/CheckedRadio';
import UncheckedRadio from 'components/Elements/RadioButtons/UncheckedRadio';

const today = Moment();

export interface TimeFrameDrawerProps {
  open: boolean
  onClose: () => void
  value: ITimeFrameValue
  onChange: (value: ITimeFrameValue) => void
}

const TimeFrameDrawer: React.FC<TimeFrameDrawerProps> =
  ({ open, onClose, value, onChange }) => {
    const { t } = useTranslation();
    const [timeFrame, setTimeFrame] = useState(value);

    // eslint-disable-next-line
    const listTile = ({ title, subtext, value }) => (
      <ListItem button onClick={() => setTimeFrame(value)}>
        <ListItemIcon>
          {timeFrame === value ? <CheckedRadio /> : <UncheckedRadio />}
        </ListItemIcon>
        <ListItemText primary={title} secondary={subtext} />
      </ListItem>
    );

    return (
      <MatDrawer
        open={open}
        anchor="right"
        onClose={onClose}
        onCancel={onClose}
        onSubmit={() => onChange(timeFrame)}
        title={t('metricTimeFrame')}
        submitBtn={t('apply')}
        cancelBtn={t('cancel')}
      >
        <Box maxWidth={450}>
          <Typography variant="subtitle2">
            {t('chooseTimeParam')}
          </Typography>
          <List>
            {listTile({
              title: t('week'),
              subtext: `${today
                .clone()
                .startOf('week')
                .format('MMM D, YYYY')} - ${today.format('MMM D, YYYY')}`,
              value: TimeFrames.week.value,
            })}
            <Divider />
            {listTile({
              title: t('month'),
              subtext: `${today
                .clone()
                .startOf('month')
                .format('MMM D, YYYY')} - ${today.format('MMM D, YYYY')}`,
              value: TimeFrames.month.value,
            })}
            <Divider />
            {listTile({
              title: t('quarter'),
              subtext: `${today
                .clone()
                .startOf('quarter')
                .format('MMM D, YYYY')} - ${today.format('MMM D, YYYY')}`,
              value: TimeFrames.quarter.value,
            })}
            <Divider />
            {listTile({
              title: t('year'),
              subtext: `${today
                .clone()
                .startOf('year')
                .format('MMM D, YYYY')} - ${today.format('MMM D, YYYY')}`,
              value: TimeFrames.year.value,
            })}
            <Divider />
          </List>
        </Box>
      </MatDrawer>
    );
  };

export default TimeFrameDrawer;

import React from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';
import TableCell, { TableCellProps } from '@mui/material/TableCell/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';

import { ReactComponent as SortIcon } from 'assets/images/sort_icon.svg';

interface HeaderCellProps extends TableCellProps {
  column: any;
  orderBy: any;
  order: any;
  onCreateSortHandler: (key: any) => any;
}

const HeaderCell: React.FC<HeaderCellProps> = ({
  column,
  orderBy,
  order,
  onCreateSortHandler,
}) => (
  <TableCell
    key={column.key}
    sortDirection={(orderBy === column.key ? order : false) as any}
    sx={{
      border: 'none',
      padding: 0,
      minHeight: 64,
    }}
    align={column.align}
  >
    <TableSortLabel
      active={orderBy === column.key}
      direction={order as any}
      onClick={() => onCreateSortHandler(column.key)}
      IconComponent={SortIcon as any}
      hideSortIcon={!column.key}
      sx={{
        icon: {
          color: Colors.white,
        },
        active: {
          color: Colors.white,
        },
      }}
    >
      <Typography variant="subtitle1">{column.name}</Typography>
      {orderBy === column.key ? (
        <Box
          component="span"
          border={0}
          height={1}
          m={-0.1}
          overflow="hidden"
          p={0}
          position="absolute"
          top={20}
          width={1}
          sx={{
            clip: 'rect(0 0 0 0)',
          }}
        >
          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
        </Box>
      ) : null}
    </TableSortLabel>
  </TableCell>
);

export default HeaderCell;

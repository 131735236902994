import React from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import routes from 'routes';
import Permissions from 'constants/permissions';
import PermissionRequired from 'components/PermissionRequired';

const DashboardEmpty = ({
  handleCreateNewCampaign,
  handleCreateNewTeam,
  handleCreateNewUser,
  subscriptionStatus,
  owner,
  verified,
  isActive,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSubscription = () => {
    navigate(routes.settings(), {
      state: {
        type: 'subscription',
      },
    });
  };

  const tiers: any[] = [
    {
      title: t('campaigns'),
      description: [t('campaignsCardDescription')],
      buttonText: t('create'),
      buttonVariant: 'contained',
      onClick: () => handleCreateNewCampaign(),
      permissions: [Permissions.campaign.create],
    },
    {
      title: t('users'),
      description: [t('usersCardDescription')],
      buttonText: 'Invite',
      buttonVariant: 'contained',
      onClick: () => handleCreateNewUser(),
      permissions: [Permissions.user.create],
    },
    {
      title: t('teams'),
      description: [t('teamsCardDescription')],
      buttonText: t('create'),
      buttonVariant: 'contained',
      onClick: () => handleCreateNewTeam(),
      permissions: [Permissions.team.create],
    },
  ];

  // if (!emailVerified) {
  //   return (
  //     <main>
  //       <Container
  //         maxWidth="sm"
  //         component="main"
  //         sx={{
  //           p: 1,
  //         }}
  //       >
  //         <Typography
  //           component="h1"
  //           variant="h2"
  //           align="center"
  //           color="textPrimary"
  //           gutterBottom
  //         >
  //           {t('welcome')}
  //         </Typography>
  //         <Typography
  //           variant="h5"
  //           align="center"
  //           color="textSecondary"
  //           component="p"
  //         >
  //           You have not verified your email address, please check your email
  //           and click on verification link we sent you.
  //         </Typography>
  //       </Container>
  //     </main>
  //   );
  // }

  return (
    <main>
      <Container
        maxWidth="sm"
        component="main"
        sx={{
          p: 1,
        }}
      >
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          {t('welcome')}
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="textSecondary"
          component="p"
        >
          {subscriptionStatus ? (
            t('dashboardEmptyDescription')
          ) : !verified ? (
            'Please check your email and verify Account to continue'
          ) : owner ? (
            'This account is currently disabled. Please go to Subscription Page'
          ) : isActive && !subscriptionStatus ? (
            <div>
              <Box sx={{ mb: 1 }}>Welcome to a user Account.</Box>
              <Box sx={{ mb: 1 }}>Admin has not added a subscription yet</Box>
              <Box>Please contact the Admin to resolve</Box>
            </div>
          ) : (
            'This account is currently disabled. Please contact the Admin to resolve'
          )}
        </Typography>
        {owner && !subscriptionStatus && verified && (
          <Box mt={4}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleSubscription}
                >
                  Go To Subscription
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Container>
      {subscriptionStatus && (
        <Container maxWidth="md" component="main">
          <Grid container spacing={5} alignItems="flex-end">
            {tiers.map((tier) => (
              <Grid item key={tier.title} xs={12} sm={12} md={4}>
                <Card>
                  <CardHeader
                    title={tier.title}
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{ align: 'center' }}
                  />
                  <CardContent>
                    <ul
                      style={{
                        margin: 0,
                        padding: 0,
                        listStyle: 'none',
                      }}
                    >
                      {tier.description.map((line) => (
                        <Typography
                          component="li"
                          variant="subtitle1"
                          align="center"
                          key={line}
                        >
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    <PermissionRequired permissions={tier.permissions}>
                      <Button
                        fullWidth
                        variant={tier.buttonVariant}
                        color="primary"
                        onClick={tier.onClick}
                      >
                        {tier.buttonText}
                      </Button>
                    </PermissionRequired>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      )}
    </main>
  );
};

DashboardEmpty.propTypes = {
  handleCreateNewCampaign: PropTypes.func.isRequired,
  handleCreateNewTeam: PropTypes.func.isRequired,
  handleCreateNewUser: PropTypes.func.isRequired,
  subscriptionStatus: PropTypes.bool.isRequired,
  verified: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  // emailVerified: PropTypes.bool.isRequired,
  owner: PropTypes.bool.isRequired,
};

export default DashboardEmpty;

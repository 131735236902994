import React from 'react';

import Colors from 'constants/colors';

import { Box } from '@mui/material';

import DefaultImage from 'assets/images/default-background.svg';
import PropTypes from 'prop-types';

const Image = ({ image }) => {
  const imageStyle = {
    objectFit: 'cover',
    backgroundSize: 'cover',
    backgroundColor: Colors.gray,
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '18px',
    paddingTop: '56%',
    filter: 'brightness(85%)',
    backgroundPosition: 'center',
  };

  const fetchImage = () => {
    if (!image) {
      return (
        <Box
          sx={imageStyle}
          style={{ backgroundImage: `url(${DefaultImage})` }}
        />
      );
    }
    switch (image.type) {
      case 'custom':
        return (
          <Box
            sx={imageStyle}
            style={{ backgroundImage: `url(${image.url})` }}
          />
        );
      case 'unsplash':
        return (
          <Box
            sx={imageStyle}
            style={{ backgroundImage: `url(${image.url})` }}
          />
        );
      case 'giphy':
        return (
          <Box
            sx={imageStyle}
            style={{ backgroundImage: `url(${image.url})` }}
          />
        );
      default:
        return (
          <Box
            sx={imageStyle}
            style={{ backgroundImage: `url(${DefaultImage})` }}
          />
        );
    }
  };

  return fetchImage();
};

Image.propTypes = {
  image: PropTypes.bool.isRequired,
};

export default Image;

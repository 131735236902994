import React, { useState } from 'react';

import Colors from 'constants/colors';
import Permissions from 'constants/permissions';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';

import useAccessControl from 'hooks/useAccessControl';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import routes from 'routes';
import useWindowSize from 'utils/hooks/useWindowSize';

import CardContainer from 'components/Elements/CardContainer/CardContainer';
import Title from 'components/Elements/Title/Title';
import RunAgainDrawer from 'components/RunAgainDrawer';

import ActivityItem from './ActivityItem';
import PermissionRequired from 'components/PermissionRequired';

const RecentActivity: React.FC<any> = ({
  handleAnnouncementClick,
  isLoading,
  activity,
  refreshActivity,
  handleCreate,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [runAgainCampaign, setRunAgainCampaign] = useState(null);
  const [showRunAgain, setShowRunAgain] = useState(false);
  const { width } = useWindowSize();

  const handleGoToCampaign = (campaign) => {
    if (campaign.type === 'campaign') {
      navigate(routes.campaigns.campaign(campaign.id));
    } else if (campaign.type === 'premium') {
      navigate(routes.campaigns.campaign(campaign.id));
    } else {
      handleAnnouncementClick(campaign.object);
    }
  };

  const handleRunAgain = (campaign) => {
    setRunAgainCampaign(campaign);
    setShowRunAgain(true);
  };

  const handleGoToAnnouncement = (announcement) => {
    handleAnnouncementClick(announcement);
  };

  const ac = useAccessControl();

  const renderActivities = (activities) => {
    if (activities && activities.length > 0) {
      return activities.map((activityItem) => (
        <ActivityItem
          key={activityItem.id}
          data={activityItem}
          handleCampaignView={() => handleGoToCampaign(activityItem)}
          handleAnnouncementView={handleGoToAnnouncement}
          handleRunAgain={() => handleRunAgain(activityItem)}
        />
      ));
    }
    return <div />;
  };

  const reloadActivity = () => {
    refreshActivity();
  };

  return (
    <>
      <CardContainer
        sx={{
          borderRadius: '20px',
        }}
      >
        <Box position="relative" height="100%" m={3}>
          <Box
            display="flex"
            justifyContent="space-between"
            zIndex={1}
            alignItems="center"
          >
            <Title size="medium" weight="bold">
              {t('recentActivity') as any}
            </Title>
            {width >= 660 && (
              <PermissionRequired permissions={[Permissions.campaign.create]}>
                <Button
                  disableElevation={true}
                  startIcon={<AddIcon />}
                  sx={{
                    backgroundColor: Colors.primary,
                    color: Colors.white,
                    height: 37,
                    borderRadius: 4.5,
                    marginRight: 1.25,
                    '&:hover': {
                      backgroundColor: '#0069d9',
                      borderColor: '#0062cc',
                      boxShadow: 'none',
                    },
                  }}
                  variant="contained"
                  onClick={handleCreate}
                >
                  <Box component="span" pr={2.5}>
                    {t('New Campaign')}
                  </Box>
                </Button>
              </PermissionRequired>
            )}
          </Box>
          {isLoading && (
            <CircularProgress
              sx={{ position: 'absolute', top: '43%', left: '50%' }}
            />
          )}
          <Box
            className="folderCard folderCard3"
            maxHeight={350}
            mt={2}
            sx={{
              overflowY: 'scroll',
              overflowX: 'hidden',
              '@media (max-device-width: 769px)': {
                maxHeight: 'none',
                height: '280px',
              },
            }}
          >
            {renderActivities(activity)}
          </Box>
        </Box>
      </CardContainer>
      <RunAgainDrawer
        open={showRunAgain}
        onClose={() => setShowRunAgain(false)}
        campaign={runAgainCampaign}
        onSuccess={reloadActivity}
      />
    </>
  );
};

RecentActivity.propTypes = {
  handleAnnouncementClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  activity: PropTypes.array.isRequired,
  refreshActivity: PropTypes.func.isRequired,
  handleCreate: PropTypes.func.isRequired,
};

export default RecentActivity;

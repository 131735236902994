import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';

import DateRangeIcon from '@mui/icons-material/DateRange';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Box, InputLabel, Select, MenuItem, Grid } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import Moment from 'moment';
import PropTypes from 'prop-types';
import { TwitterPicker } from 'react-color';

import MatFormLabel from 'components/Elements/MatFormLabel/FormLabel';
import MatInput from 'components/Elements/MatInput/MatInput';
import MatSelect from 'components/Elements/MatSelect/MatSelect';
import {
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatItalic,
  FormatUnderlined,
} from '@mui/icons-material';
import { ColorPicker } from 'components/ColorPicker';

const AVAILABLE_COLORS = [
  '#f44336',
  '#E91E63',
  '#673AB7',
  '#2196F3',
  '#00BCD4',
  '#4CAF50',
  '#FFC107',
  '#FF9800',
  '#607D8B',
  '#ef9a9a',
  '#F48FB1',
  '#B39DDB',
  '#90CAF9',
  '#80DEEA',
  '#A5D6A7',
  '#FFE082',
  '#FFCC80',
  '#B0BEC5',
];

const Styling = ({ item, onUpdate, tabIndex }) => {
  const [type, setType] = useState('redirect');
  const [buttonVariant, setButtonVariant] = useState('text');
  const [textSize, setTextSize] = useState(14);
  const [borderCard, setBorderCard] = useState(1);
  const [elevation, setElevation] = useState(1);
  const [border, setBorder] = useState(1);
  const [formats, setFormats] = useState(() => []);
  const [alignment, setAlignment] = useState<string | null>('left');
  //
  const [colorV2, setColorV2] = useState('#ffffff');
  const [buttonTextColorV2, setButtonTextColorV2] = useState('#ffffff');

  useEffect(() => {
    if (item && !item?.styling) {
      const object = JSON.parse(JSON.stringify(item));
      object.styling = {};
      onUpdate(object);
    } else if (item?.styling) {
      const {
        buttonBorder,
        mainFormat,
        mainAlignment,
        mainSize,
        buttonVariant,
        buttonColor,
        textButtonColor,
      } = item.styling;
      setColorV2(buttonColor || '#ffffff');
      setButtonTextColorV2(textButtonColor || '#ffffff');
      setBorder(buttonBorder || 1);
      setFormats(mainFormat || []);
      setAlignment(mainAlignment || 'left');
      setTextSize(mainSize || 14);
      setButtonVariant(buttonVariant || 'text');
    }
  }, [item]);

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null,
  ) => {
    const object = item;
    object.styling.mainAlignment = newAlignment;
    onUpdate(object);
    setAlignment(newAlignment);
  };

  const handleFormat = (
    event: React.MouseEvent<HTMLElement>,
    newFormats: any,
  ) => {
    const object = {
      ...item,
      styling: { ...item.styling, mainFormat: newFormats },
    };
    onUpdate(object);
    setFormats(newFormats);
  };

  // const handleColor = (c) => {
  //   const object = {
  //     ...item,
  //     styling: { ...item.styling, buttonColor: c.hex },
  //   };
  //   onUpdate(object);
  // };

  const handleColorV2 = (e) => {
    const object = {
      ...item,
      styling: { ...item.styling, buttonColor: e.target.value },
    };
    setColorV2(e.target.value);
    onUpdate(object);
  };

  // const handleColorButtonText = (c) => {
  //   const object = {
  //     ...item,
  //     styling: { ...item.styling, textButtonColor: c.hex },
  //   };
  //   onUpdate(object);
  // };

  const handleColorButtonTextV2 = (e) => {
    const object = {
      ...item,
      styling: { ...item.styling, textButtonColor: e.target.value },
    };
    setButtonTextColorV2(e.target.value);
    onUpdate(object);
  };

  const handleColorText = (c) => {
    const object = {
      ...item,
      styling: { ...item.styling, mainColor: c.hex },
    };
    onUpdate(object);
  };

  const handleTextSize = (e) => {
    const object = item;
    object.styling.mainSize = e.target.value;
    setTextSize(e.target.value);
    onUpdate(object);
  };

  const handleTextSizeDecrease = () => {
    if (textSize > 12) {
      const num = textSize - 1;
      const objectBody = {
        ...item,
        styling: { ...item.styling, mainSize: num },
      };
      setTextSize(textSize - 1);
      onUpdate(objectBody);
    }
  };

  const handleTextSizeIncrease = () => {
    if (textSize < 15) {
      const num = textSize + 1;
      const objectBody = {
        ...item,
        styling: { ...item.styling, mainSize: num },
      };
      setTextSize(textSize + 1);
      onUpdate(objectBody);
    }
  };

  // const handleBorder = (e) => {
  //   const object = {
  //     ...item,
  //     styling: { ...item.styling, buttonBorder: e.target.value },
  //   };
  //   setBorder(e.target.value);
  //   onUpdate(object);
  // };

  const handleBorderV2 = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    const object = {
      ...item,
      styling: { ...item.styling, buttonBorder: Number(newAlignment) },
    };
    setBorder(Number(newAlignment));
    onUpdate(object);
  };

  // const handleElevation = (e) => {
  //   const object = {
  //     ...item,
  //     styling: { ...item.styling, cardElevation: e.target.value },
  //   };
  //   onUpdate(object);
  // };

  // const handleBorderCard = (e) => {
  //   const object = {
  //     ...item,
  //     styling: { ...item.styling, cardBorder: e.target.value },
  //   };
  //   setBorderCard(e.target.value);
  //   onUpdate(object);
  // };

  const handleButtonVariant = (e) => {
    const object = {
      ...item,
      styling: { ...item.styling, buttonVariant: e.target.value },
    };
    setButtonVariant(e.target.value);
    onUpdate(object);
  };

  const renderItems = () => {
    switch (tabIndex) {
      case 1:
        return (
          <Box width="100%" px={2}>
            {/* <Typography my={2} color="blue" variant="h6">
              Card Styling
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl
                  variant="outlined"
                  sx={{
                    marginBottom: 2,
                    width: '100%',
                  }}
                  margin="dense"
                >
                  <InputLabel id="demo-simple-select-label">
                    Border Radius
                  </InputLabel>
                  <Select
                    value={borderCard}
                    labelId="demo-simple-select-label"
                    onChange={handleBorderCard}
                    label="Border Radius"
                  >
                    <MenuItem value={1}>4</MenuItem>
                    <MenuItem value={2}>8</MenuItem>
                    <MenuItem value={3}>12</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid> */}
            {/* <Typography my={2} color="blue" variant="h6">
              Text Styling
            </Typography> */}

            <ButtonGroup
              variant="outlined"
              size="medium"
              aria-label="medium button group"
              sx={{
                mb: 2,
                pt: 0,
                mr: 3,
                '& .MuiButton-root': {
                  borderColor: '#E2E4E5',
                  color: '#333',
                  '&:focus': {
                    borderColor: '#E2E4E5',
                    color: '#333',
                  },
                  '&:hover': {
                    borderColor: '#E2E4E5',
                    color: '#333',
                  },
                },
              }}
            >
              <Button
                style={{
                  maxWidth: '30px',
                  maxHeight: '35px',
                  minWidth: '30px',
                  minHeight: '35px',
                }}
                disabled={textSize <= 12}
                onClick={handleTextSizeDecrease}
              >
                -
              </Button>
              <Button
                style={{
                  maxWidth: '35px',
                  maxHeight: '35px',
                  minWidth: '35px',
                  minHeight: '35px',
                }}
              >
                <Box sx={{ mx: 1 }}>{textSize}</Box>
              </Button>
              <Button
                style={{
                  maxWidth: '30px',
                  maxHeight: '35px',
                  minWidth: '30px',
                  minHeight: '35px',
                }}
                disabled={textSize >= 15}
                onClick={handleTextSizeIncrease}
              >
                +
              </Button>
            </ButtonGroup>

            <ToggleButtonGroup
              value={formats}
              onChange={handleFormat}
              size="small"
              aria-label="Small sizes"
              sx={{
                mb: 2,
                verticalAlign: 'top',
                '& .MuiToggleButton-root': {
                  border: 'none',
                  borderRadius: '10px',
                },
                '& .css-7fzq1p-MuiButtonBase-root-MuiToggleButton-root.Mui-disabled': {
                  border: 'none',
                  borderRadius: '10px',
                },
              }}
            >
              <ToggleButton value="bold" aria-label="bold">
                <FormatBold />
              </ToggleButton>
              <ToggleButton value="italic" aria-label="italic">
                <FormatItalic />
              </ToggleButton>
              <ToggleButton value="underline" aria-label="underline">
                <FormatUnderlined />
              </ToggleButton>
              {/* <ToggleButton value="color" aria-label="color" disabled>
                  <FormatColorFillIcon />
                  <ArrowDropDownIcon />
                </ToggleButton> */}
            </ToggleButtonGroup>

            {/* <Grid item xs={12} md={12} lg={12} xl={6}>
                <ToggleButtonGroup
                  value={alignment}
                  exclusive
                  onChange={handleAlignment}
                  aria-label="text alignment"
                >
                  <ToggleButton value="left" aria-label="left aligned">
                    <FormatAlignLeft />
                  </ToggleButton>
                  <ToggleButton value="center" aria-label="centered">
                    <FormatAlignCenter />
                  </ToggleButton>
                  <ToggleButton value="right" aria-label="right aligned">
                    <FormatAlignRight />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid> */}
            {/* <Grid item xs={12}>
                <FormControl variant="outlined" sx={{ width: '100%' }}>
                  <InputLabel id="demo-simple-select-text">
                    Text Size
                  </InputLabel>
                  <Select
                    value={textSize}
                    labelId="demo-simple-select-text"
                    onChange={handleTextSize}
                    label="Border Radius"
                  >
                    <MenuItem value="small">Small</MenuItem>
                    <MenuItem value="medium">Medium</MenuItem>
                    <MenuItem value="large">Large</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
            {/* <Grid item xs={12}>
                <TwitterPicker
                  style={{ minWidth: 238 }}
                  onChange={handleColorText}
                  colors={AVAILABLE_COLORS}
                  triangle="hide"
                />
              </Grid> */}
          </Box>
        );
      case 5:
        return (
          <>
            <Box sx={{ borderBottom: 1, borderColor: '#E3E5E7' }} />
            <Grid mt={2} container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  sx={{ fontSize: 16, fontWeight: 600, marginBottom: 0.5 }}
                >
                  Button Text Color
                </Typography>
                <ColorPicker
                  onChange={handleColorButtonTextV2}
                  value={buttonTextColorV2}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{ fontSize: 16, fontWeight: 600, marginBottom: 0.5 }}
                >
                  Button Background Color
                </Typography>
                <ColorPicker onChange={handleColorV2} value={colorV2} />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{ fontSize: 16, fontWeight: 600, marginBottom: 0.5 }}
                >
                  Border Radius
                </Typography>
                <Stack spacing={2} alignItems="center">
                  <ToggleButtonGroup
                    size="small"
                    value={border}
                    onChange={handleBorderV2}
                    exclusive={true}
                    aria-label="Small sizes"
                    fullWidth
                    sx={{
                      border: 'none',
                      backgroundColor: 'rgba(238, 240, 242, 1)',
                      '& .Mui-selected': {
                        backgroundColor: 'rgba(51, 51, 51, .4)',
                        '&:hover': {
                          backgroundColor: 'rgba(51, 51, 51, .4)',
                        },
                      },
                    }}
                  >
                    <ToggleButton sx={{ border: 'none' }} value={1} key={1}>
                      4
                    </ToggleButton>
                    <ToggleButton sx={{ border: 'none' }} value={2} key={2}>
                      8
                    </ToggleButton>
                    <ToggleButton sx={{ border: 'none' }} value={3} key={3}>
                      12
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{ fontSize: 16, fontWeight: 600, marginBottom: 0.5 }}
                >
                  Appearance
                </Typography>
                <FormControl variant="outlined" sx={{ width: '100%' }}>
                  <Select
                    value={buttonVariant}
                    onChange={handleButtonVariant}
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    sx={{
                      borderRadius: '10px',
                      backgroundColor: 'rgba(238, 240, 242, 1)',
                      '& .MuiSelect-select': {
                        py: 1.2,
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    }}
                  >
                    <MenuItem value="outlined">Outlined</MenuItem>
                    <MenuItem value="contained">Contained</MenuItem>
                    <MenuItem value="text">Text</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </>
        );

      default:
        return <></>;
    }
  };

  return <Box>{renderItems()}</Box>;
};

Styling.propTypes = {
  item: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default Styling;

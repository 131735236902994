import React from 'react';

import Colors from 'constants/colors';

import ErrorIcon from '@mui/icons-material/Error';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

const NoSearchResult = ({ message }) => (
  <Box
    width="100%"
    p={2.5}
    textAlign="center"
  >
    <ErrorIcon
      sx={{
        color: Colors.primary,
        width: 80,
        height: 80,
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    />
    <Typography
      variant="h6"
      sx={{
        color: Colors.black,
      }}
    >
      {message}
    </Typography>
  </Box>
);

NoSearchResult.propTypes = {
  message: PropTypes.string.isRequired,
};

export default NoSearchResult;

import React from 'react';

import Colors from 'constants/colors';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Paper, Box, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';
import Facebook from '@mui/icons-material/Facebook';
import { Instagram, LinkedIn, Twitter } from '@mui/icons-material';

const SocialBar = ({
  onClick,
  cardId,
  facebook,
  twitter,
  linkedin,
  instagram,
  active,
  styling,
}) => {
  const openLink = (link) => {
    const win: any = window.open(link, '_blank');
    win.focus();
  };

  return (
    <Box
      sx={{
        marginLeft: 1,
        marginRight: 1,
        marginBottom: 2,
      }}
    >
      <Box
        sx={
          active
            ? {
                textAlign: 'left',
                borderColor: Colors.primaryLight,
                borderStyle: 'solid',
                borderWidth: 3,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                paddingTop: 1,
                paddingBottom: 1,
              }
            : {
                width: '100%',
                textAlign: 'left',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                paddingTop: 1,
                paddingBottom: 1,
              }
        }
        onClick={onClick}
        onKeyDown={onClick}
        role="button"
        tabIndex={0}
      >
        {facebook !== '' ? (
          <IconButton
            onClick={() => openLink(facebook)}
            sx={{
              width: 40,
              height: 40,
              marginLeft: 0.5,
              marginRight: 0.5,
              borderRadius: styling?.buttonBorder,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: Colors.white,
              color: styling?.buttonColor ? styling?.buttonColor : '#2085ff',
            }}
          >
            <Facebook />
          </IconButton>
        ) : (
          <Box />
        )}
        {twitter !== '' ? (
          <IconButton
            onClick={() => openLink(twitter)}
            sx={{
              width: 40,
              height: 40,
              marginLeft: 0.5,
              marginRight: 0.5,
              borderRadius: styling?.buttonBorder,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: Colors.white,
              color: styling?.buttonColor ? styling?.buttonColor : '#2085ff',
            }}
          >
            <Twitter />
          </IconButton>
        ) : (
          <Box />
        )}
        {linkedin !== '' ? (
          <IconButton
            onClick={() => openLink(linkedin)}
            sx={{
              width: 40,
              height: 40,
              marginLeft: 0.5,
              marginRight: 0.5,
              borderRadius: styling?.buttonBorder,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: Colors.white,
              color: styling?.buttonColor ? styling?.buttonColor : '#2085ff',
            }}
          >
            <LinkedIn />
          </IconButton>
        ) : (
          <Box />
        )}
        {instagram !== '' ? (
          <IconButton
            onClick={() => openLink(instagram)}
            sx={{
              width: 40,
              height: 40,
              marginLeft: 0.5,
              marginRight: 0.5,
              borderRadius: styling?.buttonBorder,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: Colors.white,
              color: styling?.buttonColor ? styling?.buttonColor : '#2085ff',
            }}
          >
            <Instagram />
          </IconButton>
        ) : (
          <Box />
        )}
        {facebook === '' &&
        twitter === '' &&
        linkedin === '' &&
        instagram === '' ? (
          <Paper
            sx={{
              width: 40,
              height: 40,
              marginLeft: 0.5,
              marginRight: 0.5,
              borderRadius: styling?.buttonBorder,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <HelpOutlineIcon style={{ color: Colors.primary }} />
          </Paper>
        ) : (
          <Box />
        )}
        <Box
          sx={{
            top: 0,
            right: 0,
            height: 20,
            marginTop: 1.25,
            marginRight: 0.75,
            paddingLeft: 1.25,
            paddingRight: 1.25,
            position: 'absolute',
            backgroundColor: 'rgba(97,97,97 ,0.7)',
            borderRadius: 6.25,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              color: Colors.white,
              fontSize: 10,
            }}
          >
            {cardId}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

SocialBar.propTypes = {
  facebook: PropTypes.string.isRequired,
  twitter: PropTypes.string.isRequired,
  linkedin: PropTypes.string.isRequired,
  instagram: PropTypes.string.isRequired,
  styling: PropTypes.object.isRequired,
  cardId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
};

export default SocialBar;

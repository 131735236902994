import React, { useEffect, useState } from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import routes from 'routes';

import RunAgainDrawer from 'components/RunAgainDrawer';

import HeaderCell from 'components/HeaderCell';

import Row from './Row';

const columns = [
  // {
  //   name: '',
  //   key: 'status',
  // },
  {
    name: 'Title',
    key: 'title',
  },
  {
    name: 'Engagements',
    key: 'engagements',
  },
  {
    name: 'Views',
    key: 'views',
  },
  {
    name: 'Actions',
    key: 'actions',
  },
  {
    name: 'Created',
    key: 'createdAt',
    hideInMobile: true,
  },
  {
    name: 'Start date',
    key: 'start_date',
    hideInMobile: true,
  },
  {
    name: 'End date',
    key: 'end_date',
    hideInMobile: true,
  },
  {
    name: '',
    key: 'menu',
    hideInMobile: true,
  },
];

const CampaignList = ({
  campaigns,
  sortBy,
  desc,
  handleTableSettings,
  loading,
  showRunAgain,
  reloadCampaigns,
}) => {
  const navigate = useNavigate();
  const [order, setOrder] = useState(true);
  const [orderBy, setOrderBy] = useState();
  const [page, setPage] = useState(0);
  const [runAgain, setRunAgain] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const onClick = (id) => {
    navigate(routes.campaigns.campaign(id));
  };

  const createSortHandler = (property) => {
    if (!loading) {
      handleTableSettings({
        limit: campaigns.limit,
        sort: property,
        desc: !order,
        page,
      });
      if (orderBy === property) {
        setOrder(!order);
      }
      setOrderBy(property);
    }
  };

  const handleChangePage = (event, val) => {
    if (!loading) {
      handleTableSettings({
        limit: campaigns.limit,
        sort: orderBy,
        desc: order,
        page: val,
      });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    if (!loading) {
      handleTableSettings({
        limit: event.target.value,
        sort: orderBy,
        desc: order,
        page,
      });
    }
  };

  const handleMenuButton = (campaign) => {
    setSelectedCampaign(campaign);
    if (showRunAgain) {
      setRunAgain(true);
    } else if (campaign.type === 'premium') {
      navigate(routes.campaigns.build(), {
        state: { id: campaign.id, type: 'edit' },
      });
    } else if (campaign.type === 'standard') {
      navigate('/standard-campaign-builder', {
        state: { id: campaign.id, type: 'edit' },
      });
    }
  };

  useEffect(() => {
    if (campaigns.page > 0) {
      setPage(campaigns.page - 1);
    } else {
      setPage(0);
    }
  }, [campaigns.page]);

  useEffect(() => {
    setOrderBy(sortBy);
  }, [sortBy]);

  useEffect(() => {
    setOrder(desc);
  }, [desc]);

  return (
    <Box width="100%">
      <Table
        sx={{
          borderSpacing: ' 0 10px !important',
          borderCollapse: 'separate !important' as any,
          '& .MuiTableCell-root': {
            border: 'none !important',
          },
        }}
      >
        <TableHead
          sx={{
            background: '#FFF',
            '& th:first-child': {
              borderRadius: '10px 0 0 10px',
            },
            '& th:last-child': {
              borderRadius: '0 10px 10px 0',
            },
            '& *': {
              fontWeight: 500,
              fontSize: '14.5px',
              color: '#007AFF !important',
              py: '2.3px',
            },
          }}
        >
          <TableRow>
            {columns.map((column) => (
              <HeaderCell
                key={column.key}
                column={column}
                orderBy={orderBy}
                order={order ? 'desc' : 'asc'}
                onCreateSortHandler={createSortHandler}
                hideInMobile={column.hideInMobile}
              />
            ))}
          </TableRow>
        </TableHead>
        {campaigns.data && (
          <TableBody
            sx={{
              '& tr': {
                color: Colors.black,
                borderRadius: 2.5,
              },
              '& tr td:first-child': {
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
                overflow: 'hidden',
              },
              '& tr td:last-child': {
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
                overflow: 'hidden',
              },
            }}
          >
            {campaigns.data.map((campaign) => (
              <Row
                key={campaign.id}
                campaign={campaign}
                onClick={() => onClick(campaign.id)}
                onMenuButton={() => handleMenuButton(campaign)}
                showRunAgain={showRunAgain}
              />
            ))}
          </TableBody>
        )}
      </Table>
      {loading && (
        <Box
          width="100%"
          height={36}
          display="flex"
          justifyContent="end"
          alignItems="center"
        >
          <Typography variant="caption">Loading</Typography>
          <CircularProgress sx={{ marginLeft: 1 }} size={16} />
        </Box>
      )}
      {/* : (
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={campaigns.total}
          rowsPerPage={campaigns.limit}
          labelDisplayedRows={() =>
            `${page + 1}-${Math.floor(campaigns.total / campaigns.limit) + 1}`
          }
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) */}
      <RunAgainDrawer
        open={runAgain}
        onClose={() => setRunAgain(false)}
        campaign={selectedCampaign}
        onSuccess={reloadCampaigns}
      />
    </Box>
  );
};

CampaignList.propTypes = {
  campaigns: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  showRunAgain: PropTypes.bool,
  sortBy: PropTypes.string.isRequired,
  desc: PropTypes.bool.isRequired,
  handleTableSettings: PropTypes.func.isRequired,
  reloadCampaigns: PropTypes.func.isRequired,
};

CampaignList.defaultProps = {
  loading: false,
  showRunAgain: false,
};

export default CampaignList;

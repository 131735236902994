import React, { useState, useCallback, useEffect } from 'react';

import Colors from 'constants/colors';

import CropIcon from '@mui/icons-material/Crop';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import BackgroundImage from 'assets/images/default-background.svg';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import CropperDrawerV2 from 'components/CropperDrawerV2';

const UploadImageV2 = ({ currentImage, onChange, open }) => {
  const [initialImage] = useState(currentImage);
  const [uploadPhoto, setUploadPhoto] = useState<any>(null);
  const [showCrop, setShowCrop] = useState(false);

  useEffect(() => {
    if (open) {
      setUploadPhoto(null);
    }
  }, [open]);

  const onDrop = useCallback((acceptedFiles) => {
    const reader = new FileReader();
    reader.onload = () => {
      setUploadPhoto(reader.result);
      onChange({
        type: 'custom',
        upload: true,
        url: reader.result,
      });
    };
    reader.readAsDataURL(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
  });

  const handleDelete = () => {
    setUploadPhoto(null);
    onChange({
      type: 'custom',
      upload: false,
      url: initialImage,
    });
  };

  const handleCropPhoto = (croppedImage) => {
    setShowCrop(false);
    setUploadPhoto(croppedImage);
    onChange({
      type: 'custom',
      upload: true,
      url: croppedImage,
    });
  };

  const renderUploadBox = () => {
    if (uploadPhoto) {
      return (
        <Box
          position="relative"
          width="100%"
          display="flex"
          alignItems="center"
        >
          <Box
            width="100%"
            height={150}
            bgcolor={Colors.lightBlueGray}
            borderRadius="18px"
          >
            <img
              alt="Uploaded"
              src={uploadPhoto}
              style={{
                borderRadius: '18px',
                height: 150,
                width: '100%',
                objectFit: 'cover',
              }}
            />
          </Box>
          <Box
            flexGrow={1}
            pl={1.5}
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="absolute"
            sx={{
              top: '18%',
              left: '50%',
              transform: `translate(-50%, -50%)`,
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              startIcon={<CropIcon />}
              onClick={() => setShowCrop(true)}
              sx={{ bgcolor: 'white' }}
            >
              Crop
            </Button>
            <Button
              variant="outlined"
              startIcon={<DeleteOutlineOutlinedIcon />}
              onClick={handleDelete}
              sx={{
                color: Colors.error,
                borderColor: Colors.error,
                marginLeft: 1.5,
                bgcolor: 'white',
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      );
    } else if (currentImage) {
      return (
        <Box
          {...getRootProps()}
          sx={
            isDragActive
              ? {
                  borderColor: Colors.success,
                  borderWidth: 2,
                  borderStyle: 'solid',
                }
              : {}
          }
          // m={1.5}
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <input {...getInputProps()} />
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              width="100%"
              height={150}
              bgcolor={Colors.lightBlueGray}
              borderRadius="18px"
            >
              <img
                alt="Current"
                src={currentImage}
                style={{
                  borderRadius: '18px',
                  height: 150,
                  width: '100%',
                  objectFit: 'cover',
                }}
              />
            </Box>
          </Box>
          {/* <Box width={150} textAlign="center">
            <Box
              width="100%"
              height={150}
              bgcolor={Colors.lightBlueGray}
              borderRadius={2.5}
            >
              <img
                alt="Current"
                src={BackgroundImage}
                style={{
                  borderRadius: 2.5,
                  height: 150,
                  width: '100%',
                  objectFit: 'contain',
                }}
              />
            </Box>
          </Box> */}
        </Box>
      );
    }
    return (
      <Box
        {...getRootProps()}
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
        sx={
          isDragActive
            ? {
                borderColor: Colors.success,
                borderWidth: 2,
                borderStyle: 'solid',
              }
            : {}
        }
      >
        <input {...getInputProps()} />
        <Box
          width="100%"
          height={150}
          bgcolor={Colors.lightBlueGray}
          borderRadius="18px"
        >
          <img
            alt="Current"
            src={BackgroundImage}
            style={{
              borderRadius: '18px',
              height: 150,
              width: '100%',
              objectFit: 'cover',
            }}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box
      width="100%"
      height={150}
      borderRadius={2.5}
      borderColor={Colors.primary}
      sx={{ cursor: 'pointer' }}
    >
      {renderUploadBox()}
      <CropperDrawerV2
        open={showCrop}
        onClose={() => setShowCrop(false)}
        onSave={handleCropPhoto}
        photo={uploadPhoto}
      />
    </Box>
  );
};

UploadImageV2.propTypes = {
  currentImage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  open: PropTypes.func.isRequired,
};

UploadImageV2.defaultProps = {
  currentImage: null,
};

export default UploadImageV2;

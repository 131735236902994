import Parse, { JSONBaseAttributes, Pointer } from 'parse'
import { createPointer } from 'store/utils/pointers';

export interface BusinessAttributes {
  name: string
  isEnterprise: boolean
  logo_url?: string
  business_phone?: string
  business_url?: string
}

export interface IBusiness extends JSONBaseAttributes {
  name: string
  isEnterprise: boolean
  logoUrl?: string
  businessPhone?: string
  businessUrl?: string
}

class Business extends Parse.Object<BusinessAttributes> {
  static className = 'business'

  constructor(init: BusinessAttributes) {
    super(Business.className, init);
  }

  static getPointer(objectId: string): Pointer {
    return createPointer(Business.className, objectId)
  }

  serialize(): IBusiness {
    const json = super.toJSON();
    return {
      ...json,
      name: json.name,
      logoUrl: json.logo_url,
      businessPhone: json.business_phone,
      businessUrl: json.business_url,
    }
  }
}

export default Business;

import React from 'react';

import Colors from 'constants/colors';
import { defaultStyles } from 'constants/styles';

import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Drawer from '@mui/material/Drawer/Drawer';
import AddIcon from '@mui/material/SvgIcon/SvgIcon';

import CloseIcon from 'assets/images/close.svg';

import MatButton from '../MatButton/MatButton';
import Title from '../Title/Title';

const MatDrawer: React.FC<any> = ({
  open,
  anchor,
  children,
  onClose,
  title,
  description,
  bottomInfo,
  onSubmit,
  onCancel,
  submitBtn,
  cancelBtn,
  hideBottom,
  isLoading,
  padding,
}) => (
  <Drawer
    anchor={anchor}
    open={open}
    BackdropProps={{ style: { opacity: 0.2 } }}
    onClose={() => onClose(false)}
  >
    <Box maxWidth={1}>
      <Box
        sx={{
          boxShadow: defaultStyles.box.shadow,
          padding: 3.75,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <Title size="big">{title}</Title>
        <input
          type="image"
          src={CloseIcon}
          alt="close"
          onClick={() => onClose(false)}
        />
      </Box>
      {description && (
        <p
          style={{
            maxWidth: 600,
            fontSize: 16,
            color: Colors.black,
            padding: 3.75,
            margin: 0,
          }}
        >
          {description}
        </p>
      )}
    </Box>
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height={1}
    >
      <Box p={padding ?? 4} flex={1}>
        {children}
      </Box>
      {submitBtn && !hideBottom && (
        <Box
          sx={{
            borderTop: `1px solid ${Colors.lightBlueGray}`,
            padding: 3.75,
          }}
        >
          {bottomInfo !== null &&
          bottomInfo !== '' &&
          typeof bottomInfo !== 'undefined' ? (
            <p
              style={{
                maxWidth: 600,
                fontSize: 16,
                color: Colors.black,
                margin: 0,
                paddingRight: 145,
                paddingBottom: 40,
              }}
            >
              {bottomInfo}
            </p>
          ) : (
            <Box />
          )}

          <Box display="flex" alignItems="center">
            {isLoading ? (
              <CircularProgress
                style={{ width: 40, height: 40, color: Colors.primary }}
              />
            ) : (
              <>
                <MatButton
                  size="big"
                  onClick={onSubmit}
                  startIcon={<AddIcon />}
                >
                  {submitBtn}
                </MatButton>
                <Button
                  disableElevation={true}
                  color="primary"
                  onClick={onCancel}
                  sx={{
                    color: Colors.black,
                    textTransform: 'capitalize',
                    textDecoration: 'underline',
                    marginLeft: 5.25,
                  }}
                >
                  {cancelBtn}
                </Button>
              </>
            )}
          </Box>
        </Box>
      )}
    </Box>
  </Drawer>
);

export default MatDrawer;

import React from 'react';

import Colors from 'constants/colors';
import TimeRanges from 'constants/timeRanges';

// import AddIcon from '@mui/icons-material/Add';
// import RemoveIcon from '@mui/icons-material/Remove';
import Box from '@mui/material/Box';
// import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

import CardContainer from 'components/Elements/CardContainer/CardContainer';
import Title from 'components/Elements/Title/Title';

function MetricsItem({
  count,
  title,
  icon,
  increase,
  showIncrease,
  selectedTimeFrame,
}) {
  const defineTimeDescription = (range, timeFrames) => {
    const selectedRange = timeFrames.find((item) => item.value === range);
    return selectedRange?.text;
  };

  const renderIncrease = (val) => {
    if (val === 0) {
      return <div />;
    } else if (val > 0) {
      return '+';
    } else if (val < 0) {
      return '-';
    }
    return <div />;
  };

  const renderIncreaseColor = (val) => {
    if (val === 0) {
      return Colors.primary;
    } else if (val > 0) {
      return Colors.success;
    } else if (val < 0) {
      return Colors.error;
    }
    return Colors.primary;
  };

  return (
    <CardContainer
      sx={(theme) => ({
        borderRadius: '20px',
        // width: '280px',
        // [theme.breakpoints.up('lg')]: {
        //   width: '88%',
        // },
        marginX: 'auto',
      })}
    >
      <Box
        height="fit-content !important"
        display="flex"
        // height="100%"
        alignItems="start"
        justifyContent="center"
        sx={{
          '@media (max-device-width: 769px)': {
            flexDirection: 'column-reverse',
          },
        }}
        p="20px"
        gap="20px"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flex="0 1 40%"
          mx="auto"
          bgcolor="#D9EBFF"
          sx={{
            '@media (max-device-width: 959px)': {
              display: 'none',
            },
            '@media (max-device-width: 769px)': {
              display: 'none',
              width: '100%',
            },
          }}
          flexDirection="column"
          p="25px"
          height="fit-content"
          borderRadius="16px"
        >
          {icon}
        </Box>
        <Box
          display="flex"
          // ml={1}
          flexDirection="column"
          justifyContent="space-between"
          sx={{
            '@media (max-device-width: 769px)': {
              margin: '16px',
            },
          }}
          paddingY="5px"
          height="fit-content"
          width="70%"
        >
          <Title size="small">{title}</Title>
          <Typography
            // variant="h6"
            // gutterBottom
            sx={{ fontSize: 35, color: '#515C66', fontWeight: 600 }}
          >
            {count}
          </Typography>
          <Box
            color="#A7B9CE"
            // m="0 0 0 12px"
            sx={{
              '@media (max-device-width: 769px)': {
                margin: '8px 0 0',
              },
            }}
          >
            {showIncrease && (
              <Box
                sx={{
                  '@media (max-device-width: 769px)': {
                    display: 'block',
                  },
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {/* <Chip
                  sx={{
                    width: 'max-content',
                    minWidth: 80,
                    fontSize: 12,
                    fontWeight: 'bold',
                    '& .MuiChip-label': {
                      overflow: 'inherit',
                    },
                  }}
                  icon={renderIncrease(increase)}
                  label={`${+parseInt(
                    increase < 0 ? increase * -1 : increase,
                    10,
                  )} %`}
                  color="secondary"
                  style={{
                    backgroundColor: renderIncreaseColor(increase),
                  }}
                /> */}
                <Typography
                  noWrap
                  sx={{
                    fontSize: 13,
                    fontWeight: 'bold',
                    color: renderIncreaseColor(increase),
                    '& .MuiChip-label': {
                      overflow: 'inherit',
                    },
                  }}
                >
                  {renderIncrease(increase)}
                  {` ${+parseInt(
                    increase < 0 ? increase * -1 : increase,
                    10,
                  )} %`}
                </Typography>
                <Typography
                  noWrap
                  gutterBottom
                  sx={{
                    color: '#A7B9CE',
                    fontSize: 11,
                    margin: '0 0 0 6px',
                    '@media (max-device-width: 769px)': {
                      margin: '8px 0 0',
                    },
                  }}
                >
                  {defineTimeDescription(selectedTimeFrame, TimeRanges)}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </CardContainer>
  );
}

MetricsItem.propTypes = {
  count: PropTypes.number.isRequired,
  selectedTimeFrame: PropTypes.string.isRequired,
  increase: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  showIncrease: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
};

export default MetricsItem;

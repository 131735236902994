import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';

import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';

import MultiActionDot from './MultiActionDot';

const InfoCard = ({ data, results }) => {
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (data.description) {
      let descrip = data.description;
      if (typeof descrip !== 'object') {
        descrip = {
          ops: [{ insert: descrip }],
        };
      }
      const cfg = {};
      const converter = new QuillDeltaToHtmlConverter(descrip.ops, cfg);
      setDescription(converter.convert());
    }
  }, [data.description]);

  const renderCta = (cta) => {
    switch (cta.type) {
      case 'redirect':
        return (
          <Button
            color="primary"
            variant={cta.styling?.buttonVariant}
            disableElevation
            onClick={() => {
              const link = cta.link.includes('https://')
                ? cta.link
                : `https://${cta.link}`;
              const win = window.open(link);
              win?.focus();
            }}
            sx={{
              borderRadius: cta.styling?.buttonBorder,
              color: cta.styling?.textButtonColor,
              height: 22,
              marginBottom: 1.25,
              marginLeft: 1,
              borderColor:
                cta.styling?.buttonVariant === 'outlined'
                  ? cta.styling?.buttonColor
                  : '',
              backgroundColor:
                cta.styling?.buttonVariant === 'contained'
                  ? cta.styling?.buttonColor
                  : '',
            }}
          >
            <Typography sx={{ fontSize: 8 }}>{cta.name}</Typography>
          </Button>
        );
      case 'event':
        return (
          <Button
            color="primary"
            sx={{
              borderRadius: 2,
              height: 20,
              marginLeft: 1,
              marginRight: 1,
              marginBottom: 1,
            }}
            disableElevation
            endIcon={<EventOutlinedIcon />}
          >
            <Typography variant="caption">Add to Calendar</Typography>
          </Button>
        );
      default:
        return <div />;
    }
  };

  return (
    <Box width="100%" pl={2} pr={2} position="relative">
      <Card
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: data?.styling?.cardBorder,
        }}
      >
        <Box width="100%" position="relative">
          <Box
            width="100%"
            pt="56%"
            sx={{
              backgroundImage: `url(${data?.image?.url})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              filter: 'brightness(80%)',
            }}
          />
          <Box position="absolute" bottom={0} ml={2} mr={2} mb={1}>
            <Typography variant="subtitle1" sx={{ color: Colors.white }}>
              {data.title}
            </Typography>
          </Box>
        </Box>
        <CardContent
          sx={{
            paddingTop: 1,
            paddingLeft: 2,
            paddingRight: 2,
            flexGrow: 1,
          }}
        >
          <Box
            color={data.styling?.mainColor}
            textAlign={data.styling?.mainAlignment}
            fontSize={data.styling?.mainSize}
            fontStyle={
              data.styling?.mainFormat?.includes('italic') ? 'italic' : ''
            }
            sx={{
              wordWrap: 'break-word',
              textDecoration: data.styling?.mainFormat?.includes('underline')
                ? 'underline'
                : '',
            }}
            fontWeight={
              data.styling?.mainFormat?.includes('bold') ? 'bold' : ''
            }
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </CardContent>
        <CardActions sx={{ padding: 0, display: 'flex' }}>
          <Box sx={{ flexGrow: 1 }}>{renderCta(data.ctaOne)}</Box>
          <div>{renderCta(data.ctaTwo)}</div>
        </CardActions>
      </Card>
      {results !== null &&
        parseInt(results.actionOne, 10) + parseInt(results.actionTwo, 10) >
          0 && (
          <MultiActionDot
            actionOne={results.actionOne}
            actionTwo={results.actionTwo}
          />
        )}
    </Box>
  );
};

InfoCard.propTypes = {
  data: PropTypes.object.isRequired,
  results: PropTypes.object,
};

InfoCard.defaultProps = {
  results: null,
};

export default InfoCard;

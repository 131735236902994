import React, { useEffect, useState } from 'react';

import Colors from 'constants/colors';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

import PropTypes from 'prop-types';

import Image from './Image';
import IconButton from '@mui/material/IconButton/IconButton';
import MissingAction from './MissingAction';
import TextField from '@mui/material/TextField/TextField';
import MatInput from 'components/Elements/MatInput/MatInput';

const ImageCard = ({
  onClick,
  active,
  title,
  image,
  cta,
  cardId,
  styling,
  setValue,
  onUpdate,
  item,
}) => {
  const [titl, setTitle] = useState('');

  useEffect(() => {
    if (title) {
      setTitle(title);
    }
  }, [title]);

  const handleChangeTitle = (event) => {
    setTitle(event.target.value);
    const object: any = { ...item };
    object.title = event.target.value;
    onUpdate(object);
  };

  const ctaTypeComponent = () => {
    if (cta.type === 'redirect') {
      return (
        <Button
          color="primary"
          variant={styling?.buttonVariant}
          disableElevation
          onClick={() => {
            if (setValue) {
              setValue(5);
            } else {
              const link = cta.link.includes('https://')
                ? cta.link
                : `https://${cta.link}`;
              const win = window.open(link);
              win?.focus();
            }
          }}
          sx={{
            borderRadius: styling?.buttonBorder,
            color: styling?.textButtonColor,
            height: 22,
            marginBottom: 1.25,
            marginTop: 0.75,
            borderColor:
              styling?.buttonVariant === 'outlined' ? styling?.buttonColor : '',
            backgroundColor:
              styling?.buttonVariant === 'contained'
                ? styling?.buttonColor
                : '',
          }}
        >
          <Typography
            sx={{
              fontSize: 8,
              color: Colors.white,
            }}
          >
            {cta.name}
          </Typography>
        </Button>
      );
    } else if (cta.type === 'event') {
      return (
        <Typography
          sx={{
            fontSize: 10,
            color: Colors.white,
            marginLeft: 1,
            marginRight: 1,
            marginTop: 0.5,
            paddingBottom: 1.25,
          }}
        >
          Add to Calendar
        </Typography>
      );
    } else if (setValue) {
      return <MissingAction setValue={setValue} color="white" />;
    }
    return null;
  };

  return (
    <Box
      sx={{
        marginLeft: 1,
        marginRight: 1,
        marginBottom: 2,
      }}
    >
      <Card
        sx={
          active
            ? {
                textAlign: 'left',
                borderColor: Colors.primaryLight,
                borderRadius: styling?.cardBorder,
                borderStyle: 'solid',
                borderWidth: 3,
                position: 'relative',
              }
            : {
                width: '100%',
                textAlign: 'left',
                cursor: 'pointer',
                borderRadius: styling?.cardBorder,
                position: 'relative',
              }
        }
        onClick={onClick}
      >
        <Box width={1}>
          {setValue && (
            <IconButton
              sx={{ position: 'absolute', top: 5, left: 5, zIndex: 9 }}
              aria-label="add image"
              onClick={() => setValue(3)}
            >
              <AddPhotoAlternateIcon sx={{ color: 'white' }} />
            </IconButton>
          )}
          <Image image={image} />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            left: 0,
            width: '100%',
            paddingLeft: 1,
            paddingRight: 1,
            paddingBottom: 1,
          }}
        >
          {setValue ? (
            <MatInput
              id="standard-multiline-flexible"
              label="Title"
              sx={{
                width: '100%',
                '& .MuiInputBase-root': {
                  color: `${styling?.mainColor} !important`,
                  textAlign: styling?.mainAlignment,
                  fontSize: styling?.mainSize,
                  fontStyle: styling?.mainFormat?.includes('italic')
                    ? 'italic'
                    : '',
                  textDecoration: styling?.mainFormat?.includes('underline')
                    ? 'underline'
                    : '',
                  fontWeight: styling?.mainFormat?.includes('bold')
                    ? 'bold'
                    : '',
                },
              }}
              onFocus={() => setValue(1)}
              value={titl}
              onChange={handleChangeTitle}
              variant="standard"
            />
          ) : (
            <Box
              color={styling?.mainColor}
              textAlign={styling?.mainAlignment}
              fontSize={styling?.mainSize}
              fontStyle={
                styling?.mainFormat?.includes('italic') ? 'italic' : ''
              }
              fontWeight={styling?.mainFormat?.includes('bold') ? 'bold' : ''}
              sx={{
                textDecoration: styling?.mainFormat?.includes('underline')
                  ? 'underline'
                  : '',
              }}
            >
              <Typography variant="subtitle2">{title}</Typography>
            </Box>
          )}
          {ctaTypeComponent()}
        </Box>
        <Box
          sx={{
            top: 0,
            right: 0,
            height: 20,
            marginTop: 1.25,
            marginRight: 0.75,
            paddingLeft: 1.25,
            paddingRight: 1.25,
            position: 'absolute',
            backgroundColor: 'rgba(97,97,97 ,0.7)',
            borderRadius: 6,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              color: Colors.white,
              fontSize: 10,
            }}
          >
            {cardId}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
};

ImageCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  styling: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
  cta: PropTypes.object.isRequired,
  cardId: PropTypes.string.isRequired,
  item: PropTypes.object,
  setValue: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default ImageCard;

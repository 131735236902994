import React from 'react';

import { Box } from '@mui/material';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

const DisabledIntegration = ({ title, description, icon }) => (
  <Box height={1}>
    <Tooltip
      placement="right-start"
      title={
        <Typography variant="subtitle2">
          This serive has not been connected with your TappShare account yet.
          To connect to the service go to Settings then click Integrations.
        </Typography>
      }
    >
      <Paper
        elevation={2}
        sx={{
          textAlign: 'left',
          py: 1.5,
          px: 4,
          width: '100%',
          height: '100%',
          cursor: 'pointer',
          borderRadius: 8,
          backgroundColor: '#BDBDBD',
        }}
      >
        <img
          alt="Icon"
          src={icon}
          style={{
            width: 87,
            height: 87,
          }}
        />
        <Typography
          variant="h6"
          style={{
            fontWeight: 'bold',
          }}
        >
          {title}
        </Typography>
        <Typography variant="body2">
          {description}
        </Typography>
      </Paper>
    </Tooltip>
  </Box>
);

DisabledIntegration.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default DisabledIntegration;

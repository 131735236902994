/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import Parse from 'parse';
import { RootState } from 'store';
import { userSelectors } from 'store/domain/user';
import { RequestStatus } from 'store/utils/types';

const sliceName = 'integrations';

export interface GetIntegrationsPayload {
  callBack: (data: any) => void;
}

const getIntegrations = createAsyncThunk(
  `${sliceName}/getIntegrations`,
  async ({ callBack }: GetIntegrationsPayload, { getState }: any) => {
    try {
      const state = getState() as RootState;
      const businessId = userSelectors.getbusinessId(state);

      if (!businessId) {
        throw new Error('No active business.');
      }
      const data = await Parse.Cloud.run('Integrations:checkAccounts', {
        businessId,
      });
      callBack(data);
      return data;
    } catch (e) {
      console.error(`Error getting mail chimp login: ${e}`);
      throw new Error(`Error getting mail chimp login: ${e}`);
    }
  },
);

const getMailChimpAudience = createAsyncThunk(
  `${sliceName}/getMailChimpAudience`,
  async ({ callBack }: GetIntegrationsPayload, { getState }: any) => {
    try {
      const state = getState() as RootState;
      const businessId = userSelectors.getbusinessId(state);

      if (!businessId) {
        throw new Error('No active business.');
      }
      const lists = await Parse.Cloud.run(
        'Integrations:mailChimpAudienceLists',
        {
          businessId,
        },
      );
      callBack(lists);
      return lists;
    } catch (e) {
      console.error(`Error getting mail chimp login: ${e}`);
      throw new Error(`Error getting mail chimp login: ${e}`);
    }
  },
);

const getEligibility = createAsyncThunk(
  `${sliceName}/getEligibility`,
  async (props, { getState }: any) => {
    try {
      const state = getState() as RootState;
      const businessId = userSelectors.getbusinessId(state);

      if (!businessId) {
        throw new Error('No active business.');
      }
      return await Parse.Cloud.run('Integrations:checkIfAllowed', {
        businessId,
      });
    } catch (e) {
      console.error(`Error checking integrations: ${e}`);
      throw new Error(`Error checking integrations: ${e}`);
    }
  },
);

const getMailChimpStatus = createAsyncThunk(
  `${sliceName}/getMailChimpStatus`,
  async (props, { getState }: any) => {
    try {
      const state = getState() as RootState;
      const businessId = userSelectors.getbusinessId(state);

      if (!businessId) {
        throw new Error('No active business.');
      }
      return Parse.Cloud.run('Integrations:checkMailChimp', {
        businessId,
      });
    } catch (e) {
      console.error(`Error checking integrations: ${e}`);
      throw new Error(`Error checking integrations: ${e}`);
    }
  },
);

export interface MailChimpVerificationsPayload {
  accessToken: any;
  callBack: (data: any) => void;
}

const mailChimpVerificationLink = createAsyncThunk(
  `${sliceName}/mailChimpVerificationLink`,
  async (
    { accessToken, callBack }: MailChimpVerificationsPayload,
    { getState }: any,
  ) => {
    try {
      const state = getState() as RootState;
      const businessId = userSelectors.getbusinessId(state);

      if (!businessId) {
        throw new Error('No active business.');
      }
      const data = await Parse.Cloud.run(
        'Integrations:mailChimpVerificationLink',
        {
          accessToken,
          businessId,
        },
      );
      callBack(data);
      return data;
    } catch (e) {
      console.error(`Error linking mailchimp: ${e}`);
      throw new Error(`Error linking mailchimp: ${e}`);
    }
  },
);

const integrationsAdapter = createEntityAdapter();

export interface integrationsState extends EntityState<any> {
  status: RequestStatus;
  error?: string;
  showIntegrations?: any;
  mailChimpConnected?: any;
}

const initialState: integrationsState = {
  ...integrationsAdapter.getInitialState(),
  status: 'idle',
};

const integrationsSlice = createSlice({
  name: sliceName,
  initialState,
  extraReducers: (builder) => {
    // get Integrations
    builder.addCase(getIntegrations.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getIntegrations.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.error = '';
    });
    builder.addCase(getIntegrations.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // get MailChimpAudience
    builder.addCase(getMailChimpAudience.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getMailChimpAudience.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.error = '';
    });
    builder.addCase(getMailChimpAudience.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // get mailChimpVerificationLink
    builder.addCase(mailChimpVerificationLink.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(mailChimpVerificationLink.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.error = '';
    });
    builder.addCase(mailChimpVerificationLink.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // get Eligibility
    builder.addCase(getEligibility.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getEligibility.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.showIntegrations = action.payload;
      state.error = '';
    });
    builder.addCase(getEligibility.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // get Eligibility
    builder.addCase(getMailChimpStatus.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getMailChimpStatus.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.mailChimpConnected = action.payload;
      state.error = '';
    });
    builder.addCase(getMailChimpStatus.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
  },
  reducers: {},
});

export const integrationsSelectors = {};

export const integrationsActions = {
  getIntegrations,
  getEligibility,
  mailChimpVerificationLink,
  getMailChimpAudience,
  getMailChimpStatus,
  ...integrationsSlice.actions,
};

export default integrationsSlice.reducer;

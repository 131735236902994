import React, { useState, useEffect } from 'react';

import Permissions from 'constants/permissions';

import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import routes from 'routes';
import { integrationsActions } from 'store/entities/Integrations';
import { subscriptionActions } from 'store/entities/Subscriptions';

import PermissionRequired from 'components/PermissionRequired';
import PrivacyAndPolicy from 'components/PrivacyAndPolicy';
import TermsAndConditions from 'components/TermsAndConditions';
import HomeContainer from 'components/ui/HomeContainer';
import Scaffold from 'components/ui/Scaffold';

import Business from './components/Business';
import Integrations from './components/Integrations';
import Profile from './components/Profile';
import SettingsTabs from './components/SettingsTabs';
import Subscription from './components/Subscription';

const Settings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(0);
  const [upgradePlan, setUpgradePlan] = useState(false);
  const historyLocation: any = useLocation();

  const { user, role } = useSelector((state: any) => state?.user);
  const { emailVerified } = user;

  const { showIntegrations } = useSelector((state: any) => state.integrations);

  useEffect(() => {
    setUpgradePlan(false);
    dispatch(integrationsActions.getEligibility());
    dispatch(subscriptionActions.fetchBillingHistory());
    if (historyLocation?.state?.type) {
      switch (historyLocation.state.type) {
        case 'profile':
          setCurrentTab(0);
          break;
        case 'business':
          setCurrentTab(1);
          break;
        case 'subscription':
          setUpgradePlan(false);
          setCurrentTab(2);
          break;
        case 'upgrade':
          setUpgradePlan(true);
          setCurrentTab(2);
          break;
        default:
          setCurrentTab(0);
          break;
      }
    }
  }, []);

  const renderPage = () => {
    switch (currentTab) {
      case 0:
        return <Profile />;
      case 1:
        return <Business />;
      case 2:
        return (
          <Subscription
            upgradePlan={upgradePlan}
            setUpgradePlan={setUpgradePlan}
            role={role}
          />
        );
      case 3:
        if (showIntegrations) {
          return <Integrations />;
        }
        return <Profile />;
      case 4:
        return <PrivacyAndPolicy />;
      case 5:
        return <TermsAndConditions />;
      default:
        return <Profile />;
    }
  };

  return (
    <Scaffold
      HeaderProps={{
        title: t('settings'),
        tabs: (
          <SettingsTabs
            tab={currentTab}
            onTabChange={(e, val) => {
              const value = val || e.target.value;
              // if (value === 3) {
              //   navigate(routes.PrivacyAndPolicy());
              // }
              // if (value === 4) {
              //   navigate(routes.TermsAndConditions());
              // }
              setCurrentTab(value);
            }}
            showIntegrations={showIntegrations}
          />
        ),
      }}
    >
      <HomeContainer>
        <Grid item xs={12} sm={12} md={12}>
          <Box
            width="100%"
            pt={3}
            sx={{
              '@media (min-width:900px)': {
                paddingLeft: 12.5,
                paddingRight: 12.5,
              },
            }}
          >
            {renderPage()}
          </Box>
        </Grid>
      </HomeContainer>
    </Scaffold>
  );
};

export default Settings;

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import config from 'config';
import { couponActions } from 'store/entities/Coupon';
import { RootState } from 'store';

function useTappShareCookie() {
  const [cookieId, setCookieId] = useState(null);
  const [cookie, setCookie] = useCookies([config.COOKIE_ID]);
  const { savedCookie } = useSelector((state: RootState) => state.coupon);
  const dispatch = useDispatch();

  useEffect(() => {
    if (savedCookie !== null && typeof savedCookie !== 'undefined') {
      setCookieId(savedCookie);
      setCookie(config.COOKIE_ID, savedCookie, {
        path: '/',
        sameSite: 'strict',
        expires: new Date('Fri, 31 Dec 9999 23:59:59 GMT'),
      });
    } else if (
      cookie[config.COOKIE_ID] !== null &&
      cookie[config.COOKIE_ID] !== 'null' &&
      typeof cookie[config.COOKIE_ID] !== 'undefined'
    ) {
      dispatch(
        couponActions.createCookie({ cookie: cookie[config.COOKIE_ID] }),
      );
      setCookieId(cookie[config.COOKIE_ID]);
    } else {
      dispatch(couponActions.createCookie({}));
    }
  }, [savedCookie]);

  return cookieId;
}

export default useTappShareCookie;

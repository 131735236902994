import React, { useState, useEffect } from 'react';

import { Collapse, Box } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

import { format as formatDate, addDays } from 'date-fns';
import { parse as json2csv } from 'json2csv';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { reportActions } from 'store/entities/Reports';

import CampaignReportTable from './CampaignReportTable';
// import CampaignSelector from './CampaignSelector';
import CampaignSelector from './CampaignSelector';
import EmptyReport from './EmptyReport';
import ReportModal from './ReportModal';
import TimeIntervalSelector from './TimeIntervalSelector';
import { Calendar } from 'views/Reporting/components/CampaignReport';

const timeIntervalFromTimeframe = (timeframe) => {
  let startTs;
  let endTs;
  switch (timeframe) {
    case '7':
      endTs = Date.now();
      startTs = endTs - 1000 * 60 * 60 * 24 * 7;
      break;
    case '30':
      endTs = Date.now();
      startTs = endTs - 1000 * 60 * 60 * 24 * 30;
      break;
    case 'last_year':
      endTs = Date.now();
      startTs = endTs - 1000 * 60 * 60 * 24 * 365;
      break;
    default:
  }

  return {
    startDate: startTs ? new Date(startTs) : undefined,
    endDate: endTs ? new Date(endTs) : undefined,
  };
};

const GenerateReport = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [campaignSelector, setCampaignSelector] = useState('all');
  const [timeframe, setTimeframe] = useState('7');
  const [includeTeamMembers, setIncludeTeamMember] = useState(true);
  const [timeInterval, setTimeInterval] = useState<any>(
    timeIntervalFromTimeframe(timeframe),
  );
  const [campaignSelection, setCampaignSelection] = useState<any[]>([]);
  const [report, setReport] = useState<any>();
  const [loading, setLoading] = useState(false);

  const [createOpen, setCreateOpen] = useState(false);

  const [calenderDuration, setCalenderDuration] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 14),
      key: 'selection',
    },
  ]);

  const { businessId } = useSelector((state: RootState) => state.user);
  const { status, CampaignReport, loadingReport, error } = useSelector(
    (state: RootState) => state.reports,
  );

  useEffect(() => {
    switch (status) {
      case 'rejected':
        enqueueSnackbar(error, { variant: 'error' });
        dispatch(reportActions.clearStatus());
        break;
      case 'pending':
        setLoading(true);
        break;
      case 'fulfilled':
        setReport(CampaignReport);
        dispatch(reportActions.clearStatus());
        break;
      default:
        setLoading(false);
    }
  }, [status, CampaignReport]);

  const handleRunReport = () => {
    const campaigns: any = { selector: campaignSelector };
    switch (campaigns.selector) {
      case 'all':
        campaigns.businessId = businessId;
        break;
      case 'active':
        campaigns.businessId = businessId;
        break;
      case 'custom':
        campaigns.campaignIds = campaignSelection;
        break;
      default:
    }

    if (
      campaigns.selector === 'custom' &&
      (!campaigns.campaignIds || !campaigns.campaignIds.length)
    ) {
      enqueueSnackbar(t('selectOneCampaign'), { variant: 'warning' });
      return;
    }

    setLoading(true);

    dispatch(
      reportActions.getCampaignReport({
        campaigns,
        startDate: timeInterval.startDate?.toISOString(),
        endDate: timeInterval.endDate?.toISOString(),
        includeTeamMembers,
      }),
    );
  };

  const handleCampaignSelection = (selected) => {
    setCampaignSelection((prevSelection: any) => {
      const set: any = new Set(prevSelection);
      if (set.has(selected)) {
        set.delete(selected);
      } else {
        set.add(selected);
      }
      return [...set];
    });
  };

  const generateFilename = () => {
    const dateFormat = 'MM/dd/yyyy';
    const startDate = formatDate(timeInterval.startDate, dateFormat);
    const endDate = formatDate(timeInterval.endDate, dateFormat);
    return `campaign-report-${campaignSelector}-${startDate}-To-${endDate}.csv`;
  };

  const generateCsv = () => {
    let json: any = [];
    if (includeTeamMembers) {
      for (const data of report) {
        json.push({
          'campaign title': data.campaign.get('title'),
          engagements: data.metrics.engagements,
          views: data.metrics.impressions,
          actions: data.metrics.conversions,
        });

        for (const { metrics, teamMember } of data?.byTeamMember) {
          json.push({
            engagements: metrics.engagements,
            views: metrics.impressions,
            actions: metrics.conversions,
            'team member name ': `${teamMember?.get(
              'firstname',
            )} ${teamMember?.get('lastname')}`,
          });
        }
        json.push({ 'campaign title': '' });
      }
    } else {
      json = report.map((data) => ({
        title: data.campaign.get('title'),
        engagements: data.metrics.engagements,
        views: data.metrics.impressions,
        actions: data.metrics.conversions,
      }));
    }

    return json2csv(json);
  };

  const handleDownloadCsv = () => {
    const csv = generateCsv();
    const downloadLink = document.createElement('a');
    downloadLink.href = `data:text/plain;charset=utf-8,${encodeURIComponent(
      csv,
    )}`;
    downloadLink.download = generateFilename();
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const requireImpression = (rows) => rows.filter((row) => row.impressions > 0);

  const getRows = () => {
    try {
      return report.map((item) => ({
        id: item.campaign.id,
        title: item?.campaign?.get('title'),
        ...item.metrics,
        hasTeamMember: item.byTeamMember && item.byTeamMember.length > 0,
        byTeamMember: item.byTeamMember
          ? item.byTeamMember.map((tm) => ({
              id: tm.teamMember.get('id'),
              name: `${tm.teamMember.get('firstname')} ${tm.teamMember.get(
                'lastname',
              )}`,
              ...tm.metrics,
            }))
          : undefined,
      }));
    } catch (e) {
      return [];
    }
  };

  const rows = report ? getRows() : [];

  const getTotal = () =>
    rows.reduce(
      (prev, curr) => ({
        engagements: prev.engagements + curr.engagements,
        impressions: prev.impressions + curr.impressions,
        conversions: prev.conversions + curr.conversions,
      }),
      {
        engagements: 0,
        impressions: 0,
        conversions: 0,
      },
    );

  useEffect(() => {
    handleRunReport();
  }, []);

  useEffect(() => {
    if (timeframe !== 'custom') {
      setTimeInterval(timeIntervalFromTimeframe(timeframe));
    } else if (timeframe === 'custom') {
      setTimeInterval({
        startDate: calenderDuration[0].startDate,
        endDate: calenderDuration[0].endDate,
      });
    }
  }, [timeframe, calenderDuration]);

  return (
    <ReportModal
      open={open}
      onClose={onClose}
      onCancel={onClose}
      title="Generate Report"
    >
      <Box minWidth={500} maxWidth={800}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>{t('selectCampaigns')}</Typography>
            <RadioGroup
              row
              value={campaignSelector}
              onChange={(_, value) => setCampaignSelector(value)}
            >
              <FormControlLabel
                value="all"
                control={<Radio color="primary" />}
                label={t('all') as any}
              />
              <FormControlLabel
                value="active"
                control={<Radio color="primary" />}
                label={t('active') as any}
              />
              <FormControlLabel
                value="custom"
                control={<Radio color="primary" />}
                label={t('custom') as any}
              />
            </RadioGroup>

            <Collapse in={campaignSelector === 'custom'}>
              <CampaignSelector
                selection={campaignSelection}
                onSelect={handleCampaignSelection}
              />
            </Collapse>
          </Grid>

          <Grid item xs={12}>
            <Typography>{t('selectTimeFrame')}</Typography>
            <RadioGroup
              row
              value={timeframe}
              onChange={(_, value) => setTimeframe(value)}
            >
              <FormControlLabel
                value="7"
                control={<Radio color="primary" />}
                label={t('7Days') as any}
              />
              <FormControlLabel
                value="30"
                control={<Radio color="primary" />}
                label={t('30Days') as any}
              />
              <FormControlLabel
                value="last_year"
                control={<Radio color="primary" />}
                label={t('lastYear') as any}
              />
              <FormControlLabel
                value="custom"
                control={<Radio color="primary" />}
                label={t('custom') as any}
              />
              <Collapse in={timeframe === 'custom'}>
                <Button
                  variant="contained"
                  onClick={() => setCreateOpen(!createOpen)}
                >
                  Open Calender
                </Button>
              </Collapse>
            </RadioGroup>

            <Collapse in={timeframe === 'custom'}>
              <TimeIntervalSelector
                startDate={timeInterval.startDate}
                endDate={timeInterval.endDate}
                onStartDateChange={(startDate) =>
                  setTimeInterval((interval) => ({ ...interval, startDate }))
                }
                onEndDateChange={(endDate) =>
                  setTimeInterval((interval) => ({ ...interval, endDate }))
                }
              />
            </Collapse>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox color="primary" />}
              label={t('includeTeamMembers') as any}
              checked={includeTeamMembers}
              onChange={(_, value) => setIncludeTeamMember(value)}
            />
          </Grid>

          <Grid item xs={6}>
            <Button
              variant="contained"
              onClick={handleRunReport}
              disabled={loadingReport}
            >
              {t('runTheReport')}
            </Button>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              disabled={!report || !report.length}
              onClick={handleDownloadCsv}
            >
              {t('downloadCsv')}
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Collapse in={loading}>
              <LinearProgress />
            </Collapse>
          </Grid>

          <Grid item xs={12}>
            {!rows.length ? (
              <EmptyReport />
            ) : (
              <CampaignReportTable rows={rows} total={getTotal()} />
            )}
          </Grid>
        </Grid>
        <Calendar
          open={createOpen}
          onClose={() => setCreateOpen(false)}
          calenderDuration={calenderDuration}
          setCalenderDuration={setCalenderDuration}
        />
      </Box>
    </ReportModal>
  );
};

export default GenerateReport;

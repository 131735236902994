import React, { useState } from 'react';

import Colors from 'constants/colors';
import { defaultStyles } from 'constants/styles';

import { Cancel } from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import { Button, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow';
import { createTheme } from '@mui/material/styles';

import InvitePendingIcon from 'assets/images/invite_pending.svg';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { MembersActions } from 'store/entities/Members';

import TextWithToolTip, {
  TextWithToolTipV2,
} from 'components/TextWithToolTip/TextWithToolTip';

import PendingCell from './PendingCell';

const theme = createTheme();

export interface EmailInviteRowProps {
  teamMember: any;
  onClick?: (a: any) => any;
  onDelete: (a: any) => any;
}

const EmailInviteRow: React.FC<EmailInviteRowProps> = ({
  teamMember,
  onClick,
  onDelete,
}) => {
  const dispatch = useDispatch();
  const [resendingInvite, setResendingInvite] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleResend = () => {
    setResendingInvite(true);
    dispatch(
      MembersActions.resendInviteEmail({
        callBack: ({ status, message }) => {
          setResendingInvite(false);
          switch (status) {
            case 'failure':
              enqueueSnackbar(message, { variant: 'error' });
              break;
            case 'success':
              enqueueSnackbar(message, { variant: 'success' });
              break;
            default:
          }
        },
        inviteId: teamMember.id,
      }),
    );
  };

  const clickHandler = () => {
    if (onClick) {
      onClick(teamMember);
    }
  };

  const deleteHandler = () => {
    onDelete(teamMember.id);
  };

  return (
    <Box
      key={teamMember.id}
      onClick={clickHandler}
      sx={{
        width: '100%',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: defaultStyles.box.shadow,
        backgroundColor: Colors.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        '&:hover': {
          boxShadow: ' 0px 0px 0px 1px rgb(0 99 255 / 50%)',
          backgroundColor: `${Colors.white} !important`,
        },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Box
          sx={{
            cursor: 'pointer',
            borderRadius: '10px',
            height: '35px',
            width: '35px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor:
              teamMember.status === 'active' ? Colors.primary : '#babac4',
          }}
        >
          <img src={InvitePendingIcon} alt="Members Icon" />
        </Box>
        <Box
          sx={{
            fontSize: 10,
            fontWeight: 700,
            border: 'none',
            padding: 0,
            color: Colors.violet,
          }}
        >
          Invite Pending
        </Box>
        <Box
          sx={{
            fontSize: 16,
            fontWeight: 700,
            color: '#333',
            border: 'none',
            padding: 0,
          }}
        >
          <TextWithToolTip
            text={teamMember.name.includes('undefined') ? '' : teamMember.name}
          />
        </Box>
        <Box
          sx={{
            fontSize: 12,
            fontWeight: 400,
            color: '#333',
            border: 'none',
            padding: 0,
          }}
        >
          <TextWithToolTipV2 text={teamMember.email} />
        </Box>
        <Box
          sx={{
            border: 'none',
            padding: 0,
            [theme.breakpoints.down('lg')]: {
              display: 'none',
            },
            fontSize: 16,
            color: 'inherit',
          }}
        >
          {teamMember.location}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2.5 }}>
        <Box
          sx={{
            border: 'none',
            padding: 0,
            fontSize: 16,
            color: 'inherit',
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <IconButton
            aria-label="send invite"
            onClick={handleResend}
            size="small"
          >
            {resendingInvite ? <CircularProgress size="1em" /> : <SendIcon />}
          </IconButton>
        </Box>
        <Box
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <Box
            onClick={deleteHandler}
            sx={{
              cursor: 'pointer',
              borderRadius: '20px',
              height: '32px',
              width: '32px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Cancel sx={{ color: Colors.primary, width: 32 }} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EmailInviteRow;

/* eslint-disable no-param-reassign */
import Subscriptions from 'constants/subscriptions';

import {
  createAsyncThunk,
  createDraftSafeSelector,
  createEntityAdapter,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import Parse from 'parse';
import { RootState } from 'store';
import { userSelectors } from 'store/domain/user';
import { IBusiness } from 'store/models/Business';
import { selectSubscriptionsState } from 'store/selectors';
import { RequestStatus } from 'store/utils/types';

const sliceName = 'subscriptions';
const getBusinessId = (business: IBusiness) => business.objectId;

const SubscriptionsAdapter = createEntityAdapter<IBusiness>({
  selectId: getBusinessId,
});

const fetchSubscriptions = createAsyncThunk(
  `${sliceName}/fetchSubscriptions`,
  async (props, { getState, dispatch }) => {
    try {
      const state = getState() as RootState;
      const businessId = userSelectors.getbusinessId(state);
      const plans = await Parse.Cloud.run('Subscription:getSubscriptionPlans', {
        businessId,
      });

      const packages: any = [];
      plans?.subscriptionPlans
        .filter((el) => el.active)
        .map((el) => {
          if (el.product.name === Subscriptions.premium.name) {
            packages.push({
              id: el.id,
              ...Subscriptions.premium,
            });
          } else if (el.product.name === Subscriptions.premiumPro.name) {
            packages.push({
              id: el.id,
              ...Subscriptions.premiumPro,
            });
          }
          return el;
        });
      dispatch(
        getSubscribedPlans({
          subscriptionPackages: packages,
        }),
      );
      return packages.reverse();
    } catch (e) {
      console.error(`Error: ${e}`);
      throw new Error('Cant fetch Subscription');
    }
  },
);

const fetchBillingHistory = createAsyncThunk(
  `${sliceName}/fetchBillingHistory`,
  async (props, { getState, dispatch }) => {
    try {
      const state = getState() as RootState;
      const businessId = userSelectors.getbusinessId(state);
      const result = await Parse.Cloud.run('Subscription:getInvoices', {
        businessId,
      });

      const invoices: any = [];
      result?.invoices?.data.map((el) => {
        invoices.push({
          total: el.total,
          createdOn: el.created,
          url: el.invoice_pdf,
        });
        return el;
      });
      return invoices;
    } catch (e) {
      console.error(`Error: ${e}`);
      throw new Error(`Error Invoices: ${e}`);
    }
  },
);

const getPaymentMethods = createAsyncThunk(
  `${sliceName}/getPaymentMethods`,
  async (props, { getState }) => {
    try {
      const state = getState() as RootState;
      const businessId = userSelectors.getbusinessId(state);
      const paymentMethods = await Parse.Cloud.run(
        'Subscription:getPaymentMethods',
        {
          businessId,
        },
      );
      const defaultCard = await Parse.Cloud.run('Subscription:getCustomer', {
        businessId,
      });
      return {
        paymentMethods: paymentMethods?.paymentMethods?.data.filter((el) =>
          el.id.includes('pm'),
        ),
        defaultCard:
          defaultCard?.customer?.invoice_settings?.default_payment_method ||
          paymentMethods?.paymentMethods?.data[0]?.id,
      };
    } catch (e) {
      console.error(`Error: ${e}`);
      throw new Error('Cant fetch PaymMethods');
    }
  },
);

export interface UpdateDefaultCardPayload {
  paymentId: string;
}

const updateDefaultCard = createAsyncThunk(
  `${sliceName}/updateDefaultCard`,
  async ({ paymentId }: UpdateDefaultCardPayload, { getState, dispatch }) => {
    try {
      const state = getState() as RootState;
      const businessId = userSelectors.getbusinessId(state);
      await Parse.Cloud.run('Subscription:updateCustomer', {
        businessId,
        newData: {
          invoice_settings: {
            default_payment_method: paymentId,
          },
        },
      });
      dispatch(getPaymentMethods());
      return paymentId;
    } catch (e) {
      console.error(`Error: ${e}`);
      throw new Error('Cant fetch defaultCard');
    }
  },
);

const addNewCard = createAsyncThunk(
  `${sliceName}/addNewCard`,
  async ({ paymentId }: UpdateDefaultCardPayload, { getState, dispatch }) => {
    try {
      const state = getState() as RootState;
      const businessId = userSelectors.getbusinessId(state);
      await Parse.Cloud.run('Subscription:attachPaymentMethod', {
        businessId,
        paymentMethodId: paymentId,
      });
      dispatch(getPaymentMethods());
      return paymentId;
    } catch (e) {
      console.error(`Error: ${e}`);
      throw new Error('Cant fetch defaultCard');
    }
  },
);

export interface DeleteCardPayload {
  cardId: string;
  callBack: (removed: any) => void;
}

const deleteCard = createAsyncThunk(
  `${sliceName}/deleteCard`,
  async ({ cardId, callBack }: DeleteCardPayload, { getState, dispatch }) => {
    const state = getState() as RootState;
    const currentUser = userSelectors.getCurrentUser(state);
    const businessId = userSelectors.getbusinessId(state);
    const result = await Parse.Cloud.run('Subscription:detachPaymentMethod', {
      paymentMethodId: cardId,
      businessId,
      _SessionToken: currentUser.sessionToken,
    });
    if (!result || result !== 'deleted successfully') {
      callBack({
        removed: 'Error removing card',
      });
      return {
        removed: 'Cannot Remove Card',
      };
    }
    dispatch(getPaymentMethods());
    callBack({ result });
    return { result };
  },
);

export interface GetSubscribedPlansPayload {
  subscriptionPackages: any;
}

const getSubscribedPlans = createAsyncThunk(
  `${sliceName}/getSubscribedPlans`,
  async (
    { subscriptionPackages }: GetSubscribedPlansPayload,
    { getState, dispatch },
  ) => {
    try {
      const state = getState() as RootState;
      const businessId = userSelectors.getbusinessId(state);
      const business = await Parse.Cloud.run('Business:getBusiness', {
        businessId,
      });

      const businessObject = business.business.toJSON();

      if (
        businessObject?.isEnterprise &&
        businessObject?.isEnterprise !== false
      ) {
        return {
          type: 'Entreprise',
          status: true,
        };
      }
      const subscribedPlan = await Parse.Cloud.run(
        'Subscription:getSubscribedPlans',
        {
          businessId,
        },
      );

      const now = new Date();

      const startDate = new Date(now.getFullYear(), now.getMonth(), 1);
      const endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);

      const actionsCount = await Parse.Cloud.run(
        'Metrics:getConversionsCount',
        {
          businessId,
          startDate,
          endDate,
        },
      );

      if (subscribedPlan.subscribedPlans.data[0]) {
        const plantype = subscriptionPackages.filter(
          (plan) => plan.id === subscribedPlan.subscribedPlans.data[0].plan.id,
        )[0];
        if (plantype) {
          return {
            type: plantype.name,
            status: true,
            actionsCount: actionsCount?.conversionsCount,
            id: subscribedPlan.subscribedPlans.data[0].id,
            siId: subscribedPlan.subscribedPlans.data[0].items.data[0].id,
            nextPayment:
              subscribedPlan.subscribedPlans.data[0].current_period_end,
          };
        }
      }
      return {
        type: 'unsebscribed',
        status: true,
      };
    } catch (e) {
      console.error(`Error: ${e}`);
      throw new Error('Cant fetch Subscription');
    }
  },
);

export interface NewSubscriptionPayload {
  planId: string;
  paymentId: string;
  planDetails: any;
  card: any;
}

const createNewSubscription = createAsyncThunk(
  `${sliceName}/createNewSubscription`,
  async (
    { planId, paymentId, planDetails, card }: NewSubscriptionPayload,
    { getState, dispatch },
  ) => {
    const state = getState() as RootState;
    const businessId = userSelectors.getbusinessId(state);
    const stripeCustomerRef = userSelectors.getStripeCustomerRef(state);
    if (!stripeCustomerRef) {
      await Parse.Cloud.run('Subscription:createCustomer', {
        businessId,
      });
    }
    await Parse.Cloud.run('Subscription:attachPaymentMethod', {
      businessId,
      paymentMethodId: paymentId,
    });
    const subscription = await Parse.Cloud.run(
      'Subscription:createSubscription',
      {
        businessId,
        data: {
          items: [
            {
              price: planId,
            },
          ],
          payment_method: paymentId,
        },
      },
    );
    dispatch(fetchBillingHistory());
    dispatch(getPaymentMethods());
    return {
      accountStatus: true,
      subscriptionPackages: [],
      subscriptionId: subscription.subscription.id,
      subscriptionItemsId: subscription.subscription.items.data[0].id,
      subscriptionType: planDetails.name,
      defaultCard: card,
      billingHistory: [],
      planDetails,
      paymentId,
    };
  },
);

const updateSubscription = createAsyncThunk(
  `${sliceName}/updateSubscription`,
  async (
    { planId, paymentId, planDetails, card }: NewSubscriptionPayload,
    { getState, dispatch },
  ) => {
    const accountStatus = true;
    const state = getState() as RootState;
    const businessId = userSelectors.getbusinessId(state);
    const subscription = await Parse.Cloud.run(
      'Subscription:updateSubscription',
      {
        businessId,
        subscriptionId: planId,
        newData: {
          items: [
            {
              billing_thresholds: '',
              id: planDetails.siId,
              quantity: '1',
              tax_rates: '',
              price: planDetails.id,
            },
          ],
        },
      },
    );
    dispatch(
      getSubscribedPlans({
        subscriptionPackages: state.subscriptions.subscriptionPackages,
      }),
    );
    return {
      accountStatus,
      subscriptionPackages: [],
      subscriptionType: planDetails.name,
      subscriptionId: subscription.subscription.id,
      subscriptionItemsId: subscription.subscription.items.data[0].id,
      defaultCard: card,
      billingHistory: [],
      planDetails,
      paymentId,
    };
  },
);

export interface CancelSubscriptionPayload {
  planId: string;
}

const cancelSubscription = createAsyncThunk(
  `${sliceName}/cancelSubscription`,
  async ({ planId }: CancelSubscriptionPayload, { getState }) => {
    const subscriptionType = 'unsubscribed';
    const state = getState() as RootState;
    const businessId = userSelectors.getbusinessId(state);
    await Parse.Cloud.run('Subscription:cancelSubscription', {
      businessId,
      subscriptionId: planId,
    });
    return {
      accountStatus: false,
      subscriptionPackages: [],
      subscriptionType,
      defaultCard: null,
      billingHistory: [],
      planDetails: {},
      paymentId: null,
    };
  },
);

export interface SubscriptionsState extends EntityState<IBusiness> {
  status: RequestStatus;
  subscriptions?: any;
  subscriptionPackages?: any;
  cards?: any;
  subscriptionType?: string;
  subscriptionId?: string;
  subscriptionItemsId?: string;
  actionsCount?: number;
  billingHistory?: string;
  accountStatus: boolean;
  statusIsLoading: boolean;
  error?: string;
  nextPayment?: string;
  paymentId?: string;
  defaultCard?: any;
  removed?: any;
}

const initialState: SubscriptionsState = {
  ...SubscriptionsAdapter.getInitialState(),
  status: 'idle',
  accountStatus: false,
  statusIsLoading: true,
};

const subscriptionsSlice = createSlice({
  name: sliceName,
  initialState,
  extraReducers: (builder) => {
    // Fetch Subscriptions
    builder.addCase(fetchSubscriptions.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchSubscriptions.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.subscriptionPackages = action.payload;
    });
    builder.addCase(fetchSubscriptions.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Fetch Subscriptions
    builder.addCase(getSubscribedPlans.pending, (state) => {
      state.status = 'pending';
      state.statusIsLoading = true;
    });
    builder.addCase(getSubscribedPlans.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.subscriptionType = action.payload.type;
      state.subscriptionId = action.payload.id;
      state.subscriptionItemsId = action.payload.siId;
      state.nextPayment = action.payload.nextPayment;
      state.actionsCount = action.payload.actionsCount;
      state.accountStatus = action.payload.status;
      state.statusIsLoading = false;
    });
    builder.addCase(getSubscribedPlans.rejected, (state, action) => {
      state.status = 'rejected';
      state.statusIsLoading = false;
      state.error = action.error.message;
    });
    // Fetch Billing History
    builder.addCase(fetchBillingHistory.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchBillingHistory.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.billingHistory = action.payload;
    });
    builder.addCase(fetchBillingHistory.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Get Payment Methods
    builder.addCase(getPaymentMethods.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getPaymentMethods.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.cards = action.payload.paymentMethods;
      state.paymentId = action.payload.defaultCard;
      state.defaultCard = action.payload.defaultCard;
    });
    builder.addCase(getPaymentMethods.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Update Payment Methods
    builder.addCase(updateDefaultCard.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateDefaultCard.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.paymentId = action.payload;
    });
    builder.addCase(updateDefaultCard.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Add New Card
    builder.addCase(addNewCard.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(addNewCard.fulfilled, (state, action) => {
      state.status = 'fulfilled';
    });
    builder.addCase(addNewCard.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Create
    builder.addCase(createNewSubscription.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(createNewSubscription.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.subscriptions = action.payload;
      state.accountStatus = true;
      state.subscriptionType = action.payload.subscriptionType;
      state.subscriptionId = action.payload.subscriptionId;
      state.subscriptionItemsId = action.payload.subscriptionItemsId;
    });
    builder.addCase(createNewSubscription.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Update
    builder.addCase(updateSubscription.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateSubscription.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.subscriptions = action.payload;
      state.accountStatus = true;
      state.subscriptionType = action.payload.subscriptionType;
      state.subscriptionId = action.payload.subscriptionId;
      state.subscriptionItemsId = action.payload.subscriptionItemsId;
    });
    builder.addCase(updateSubscription.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Delete
    builder.addCase(deleteCard.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(deleteCard.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.removed = action.payload;
    });
    builder.addCase(deleteCard.rejected, (state, action) => {
      state.status = 'rejected';
      state.removed = action.payload;
    });
    // Cancel
    builder.addCase(cancelSubscription.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(cancelSubscription.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.subscriptions = action.payload;
      state.accountStatus = false;
      state.subscriptionType = 'unsubscribed';
      state.subscriptionId = '';
      state.subscriptionItemsId = '';
    });
    builder.addCase(cancelSubscription.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
  },
  reducers: {
    clearStatus: (state) => {
      state.status = 'idle';
    },
    setOne: SubscriptionsAdapter.setOne,
    setMany(state, action) {
      SubscriptionsAdapter.setMany(state, action.payload);
    },
    removeOne: SubscriptionsAdapter.removeOne,
    removeMany: SubscriptionsAdapter.removeMany,
  },
});

const getAccountStatus = createDraftSafeSelector(
  selectSubscriptionsState,
  (subscriptions) => subscriptions.accountStatus,
);

export const subscriptionsSelectors = {
  getAccountStatus,
};

export const subscriptionActions = {
  fetchSubscriptions,
  createNewSubscription,
  cancelSubscription,
  updateSubscription,
  getSubscribedPlans,
  getPaymentMethods,
  updateDefaultCard,
  addNewCard,
  deleteCard,
  fetchBillingHistory,
  ...subscriptionsSlice.actions,
};

export default subscriptionsSlice.reducer;

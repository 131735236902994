import React from 'react';
import Box from '@mui/material/Box';

function SVG10() {
  return (
    <div
      id="SVG10"
      style={{
        height: '70px',
        width: '70px',
        display: 'flex',
      }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          height: '45px',
          width: '45px',
          stroke: '#ffffff',
          strokeWidth: 2.0,
          margin: 'auto',
        }}
      >
        <path
          d="M12.2 11.8L10.79 13.21C10.01 13.99 10.01 15.26 10.79 16.04C11.57 16.82 12.84 16.82 13.62 16.04L15.84 13.82C16.5892 13.0686 17.0098 12.0509 17.0098 10.99C17.0098 9.92896 16.5892 8.91126 15.84 8.15995C15.0887 7.4108 14.071 6.99011 13.01 6.99011C11.949 6.99011 10.9313 7.4108 10.18 8.15995L7.76 10.58C7.44137 10.8983 7.1886 11.2763 7.01615 11.6924C6.84369 12.1085 6.75492 12.5545 6.75492 13.005C6.75492 13.4554 6.84369 13.9014 7.01615 14.3175C7.1886 14.7336 7.44137 15.1116 7.76 15.43"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export default SVG10;

import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

import MatInput from 'components/Elements/MatInput/MatInput';
import { FullImageSelector } from 'components/ImageSelectors';

import ActionDisplay from './ActionDisplay';
import ActionDrawer from './ActionDrawer';

const ImageBuilder = ({ item, onUpdate, cardId, onDelete, tabIndex }) => {
  const [title, setTitle] = useState(item.title);
  const [showActionDrawer, setShowActionDrawer] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [initialImage, setInitialImage] = useState(null);

  useEffect(() => {
    if (item.title !== title) {
      setTitle(item.title);
    }
    setInitialImage(item.image);
  }, [item]);

  const handleImageSelect = (photo) => {
    const object = { ...item };
    object.image = photo;
    onUpdate(object);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    const object = { ...item };
    object.title = e.target.value;
    onUpdate(object);
  };

  const handleActionSubmit = (data) => {
    const object = { ...item };
    object.cta = data;
    onUpdate(object);
    setShowActionDrawer(false);
  };

  const deleteAction = () => {
    const object = { ...item };
    object.cta = {
      type: 'none',
      name: '',
      link: '',
    };
    onUpdate(object);
  };

  const handleAction = () => {
    setSelectedAction(item.cta || null);
    setShowActionDrawer(true);
  };

  const renderItems = () => {
    switch (tabIndex) {
      case 1:
        return (
          <MatInput
            value={title}
            variant="outlined"
            placeholder="Title (Optional)"
            onChange={handleTitleChange}
            size="small"
          />
        );
        break;
      case 3:
        return (
          <FullImageSelector
            onSelect={handleImageSelect}
            defaultImage={initialImage}
          />
        );
        break;
      case 5:
        return (
          <>
            <Box
              width={1}
              sx={{
                borderBottomStyle: 'solid',
                borderBottomWidth: 1,
                borderBottomColor: Colors.primary,
                my: 3,
              }}
            >
              <Typography variant="h6">Actions</Typography>
            </Box>
            {item.cta.type === 'none' ? (
              <Button
                startIcon={<AddCircleOutlineIcon />}
                variant="text"
                color="primary"
                onClick={handleAction}
              >
                Add Action
              </Button>
            ) : (
              <ActionDisplay
                type={item.cta.type}
                onClick={handleAction}
                onDelete={deleteAction}
              />
            )}
            <ActionDrawer
              defaultCta={selectedAction}
              open={showActionDrawer}
              onClose={() => setShowActionDrawer(false)}
              onSubmit={handleActionSubmit}
            />
          </>
        );
        break;
      case 7:
        return null;
        break;
      default:
        return (
          <MatInput
            value={title}
            variant="outlined"
            placeholder="Title (Optional)"
            onChange={handleTitleChange}
            size="small"
          />
        );
        break;
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        textAlign: 'left',
        backgroundColor: Colors.white,
        borderRadius: 2,
        pb: 2,
        pt: 1,
      }}
    >
      <Box width={1} display="flex" alignItems="center" mb={0.5}>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          {`Edit Card ${cardId}`}
        </Typography>
        <IconButton
          aria-label="delete"
          onClick={onDelete}
          size="large"
          sx={{ color: Colors.error }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
      {renderItems()}
    </Box>
  );
};

ImageBuilder.propTypes = {
  item: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  cardId: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ImageBuilder;

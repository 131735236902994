import React from 'react';

import Colors from 'constants/colors';
import Permissions from 'constants/permissions';
import { defaultStyles } from 'constants/styles';

import EditIcon from '@mui/icons-material/Edit';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Hidden from '@mui/material/Hidden';
import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow';

import TeamsIcon from 'assets/images/teams_icon.svg';
import Moment from 'moment';
import PropTypes from 'prop-types';
import { BiSolidPencil } from 'react-icons/bi';
import { VscDebugRerun } from 'react-icons/vsc';

import PermissionRequired from 'components/PermissionRequired';
import TextWithToolTip from 'components/TextWithToolTip/TextWithToolTip';

import StatusCell from './StatusCell';

const Row = ({ campaign, onClick, onMenuButton, showRunAgain }) => {
  const clickHandler = () => {
    onClick(campaign.objectId);
  };

  return (
    <TableRow
      hover
      key={campaign.id}
      onClick={clickHandler}
      sx={{
        height: 72,
        boxShadow: defaultStyles.box.shadow,
        backgroundColor: Colors.white,
        borderRadius: 2.5,
        '&:hover': {
          boxShadow: ' 0px 0px 0px 1px rgb(0 99 255 / 50%)',
          backgroundColor: `${Colors.white} !important`,
          cursor: 'pointer',
        },
      }}
    >
      {/* <TableCell
        align="center"
        sx={{
          border: 'none',
          padding: 0,
          minHeight: 64,
          fontSize: 16,
          color: 'inherit',
          width: 84,
        }}
      >
        <StatusCell isActive={!!(campaign.type === 'active' || 'future')} />
      </TableCell> */}
      <TableCell
        align="center"
        sx={{
          border: 'none',
          padding: 0,
          minHeight: 64,
          fontSize: 16,
          fontWeight: 700,
          color: 'inherit',
        }}
      >
        <Box px={2.5} display="flex" alignItems="center">
          <Box
            sx={{
              cursor: 'pointer',
              borderRadius: '8px',
              height: '30px',
              width: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mr: '20px',
              backgroundColor:
                // eslint-disable-next-line no-constant-condition
                campaign.type === 'active' || 'future' ? '#6C70F0' : '#babac4',
            }}
          >
            <img src={TeamsIcon} alt="Teams Icon" />
          </Box>
          <Box display="flex" py={1} flexDirection="column">
            <TextWithToolTip text={campaign.title} />
            <Hidden lgDown={true}>
              {campaign.tags && !!campaign.tags.length && (
                <Box
                  display="flex"
                  flexWrap="wrap"
                  p="4px 0 4px"
                  sx={{
                    '@media max-width:1279.95px': {
                      display: 'none',
                    },
                  }}
                >
                  {campaign.tags.map((tag) => (
                    <Chip
                      key={tag}
                      label={tag}
                      sx={{ margin: 0.25, fontWeight: 400, fontSize: 12 }}
                    />
                  ))}
                </Box>
              )}
            </Hidden>
          </Box>
        </Box>
      </TableCell>
      <TableCell
        align="center"
        sx={{
          border: 'none',
          padding: 0,
          minHeight: 64,
          fontSize: 16,
          color: 'inherit',
        }}
      >
        {campaign.engagements}
      </TableCell>
      <TableCell
        align="center"
        sx={{
          border: 'none',
          padding: 0,
          minHeight: 64,
          fontSize: 16,
          color: 'inherit',
        }}
      >
        {campaign.impressions}
      </TableCell>
      <TableCell
        align="center"
        sx={{
          border: 'none',
          padding: 0,
          minHeight: 64,
          fontSize: 16,
          color: 'inherit',
          '@media max-width:1279.95px': {
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
          },
        }}
      >
        {campaign.conversions}
      </TableCell>
      <Hidden lgDown={true}>
        <TableCell
          align="center"
          sx={{
            border: 'none',
            padding: 0,
            minHeight: 64,
            fontSize: 16,
            color: 'inherit',
            '@media max-width:1279.95px': {
              display: 'none',
            },
          }}
        >
          {Moment(campaign.createdAt).format('MM/D/YYYY')}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            border: 'none',
            padding: 0,
            minHeight: 64,
            fontSize: 16,
            color: 'inherit',
            '@media max-width:1279.95px': {
              display: 'none',
            },
          }}
        >
          {Moment(campaign.start_date).format('MM/D/YYYY')}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            border: 'none',
            padding: 0,
            minHeight: 64,
            fontSize: 16,
            color: 'inherit',
            '@media max-width:1279.95px': {
              display: 'none',
            },
          }}
        >
          {Moment(campaign.end_date).format('MM/D/YYYY')}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            border: 'none',
            padding: 0,
            minHeight: 64,
            fontSize: 16,
            color: 'inherit',
            width: 115,
            '@media max-width:1279.95px': {
              display: 'none',
            },
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {campaign.type !== 'legacy' &&
            (showRunAgain ? (
              <Button
                sx={{
                  color: Colors.primary,
                  marginLeft: 0.5,
                  marginRight: 1.5,
                  '@media max-width:1279.95px': {
                    display: 'none',
                  },
                }}
                onClick={onMenuButton}
              >
                Repeat
                <VscDebugRerun
                  color={Colors.primary}
                  size={20}
                  style={{ marginLeft: 2.5 }}
                />
                {/* <PlayArrowIcon
                  sx={{ color: Colors.primary, marginLeft: 0.5 }}
                /> */}
              </Button>
            ) : (
              <PermissionRequired permissions={[Permissions.campaign.edit]}>
                <Box
                  onClick={onMenuButton}
                  sx={{
                    ml: 'auto',
                    marginRight: 2.5,
                    cursor: 'pointer',
                    borderRadius: '8px',
                    height: '30px',
                    width: '30px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#D9EBFF',
                  }}
                >
                  <BiSolidPencil color="#007AFF" size={17} />
                </Box>
              </PermissionRequired>
            ))}
        </TableCell>
      </Hidden>
    </TableRow>
  );
};

Row.propTypes = {
  campaign: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onMenuButton: PropTypes.func.isRequired,
  showRunAgain: PropTypes.bool,
};

Row.defaultProps = {
  showRunAgain: false,
};

export default Row;

import React from 'react';

import Colors from 'constants/colors';

import { FormLabel, FormLabelProps } from '@mui/material';

export interface MatFormLabelProps extends FormLabelProps {
  icon?: any;
  children?: string | JSX.Element | React.ReactNode;
}

const MatFormLabel: React.FC<MatFormLabelProps> = ({
  icon,
  children,
  ...props
}) => (
  <FormLabel
    sx={{
      fontSize: 15,
      color: Colors.black,
      marginBottom: 1.75,
      marginTop: 1.75,
      display: 'flex',
      alignItems: 'flex-end',
      '&.MuiFormLabel-root.Mui-focused': {
        color: Colors.black,
      },
    }}
    {...props}
  >
    {icon && <span style={{ display: 'flex', marginRight: 12 }}>{icon}</span>}
    {children}
  </FormLabel>
);

export default MatFormLabel;

import axios from 'axios';

export const BusinessFolderUpload = async ({ image, businessId }) => {
  try {
    const result = await axios.post(
      `https://api.cloudinary.com/v1_1/pulls/image/upload?upload_preset=offerUpload&folder=Development/Business/${businessId}`,
      {
        api_key: '565576631873624',
        file: image,
      },
    );
    return result.data.secure_url;
  } catch (e) {
    console.error(`Error Uploading Campaign Image: ${e}`);
    return null;
  }
};

export const CampaignUpload = async (image) => {
  try {
    const result = await axios.post(
      'https://api.cloudinary.com/v1_1/pulls/image/upload?upload_preset=offerUpload&folder=Production/Offers',
      {
        api_key: '565576631873624',
        file: image,
      },
    );
    return result.data.secure_url;
  } catch (e) {
    console.error(`Error Uploading Campaign Image: ${e}`);
    return null;
  }
};

export const AnnouncementUpload = async (image) => {
  try {
    const result = await axios.post(
      'https://api.cloudinary.com/v1_1/pulls/image/upload?upload_preset=offerUpload&folder=Production/Announcements',
      {
        api_key: '565576631873624',
        file: image,
      },
    );
    return result.data.secure_url;
  } catch (e) {
    console.error(`Error Uploading Campaign Image: ${e}`);
    return null;
  }
};

export const BusinessUpload = async (image) => {
  try {
    const result = await axios.post(
      'https://api.cloudinary.com/v1_1/pulls/image/upload?upload_preset=offerUpload&folder=Production/Businesses',
      {
        api_key: '565576631873624',
        file: image,
      },
    );
    return result.data.secure_url;
  } catch (e) {
    console.error(`Error Uploading Campaign Image: ${e}`);
    return null;
  }
};

export const LocationUpload = async (image) => {
  try {
    const result = await axios.post(
      'https://api.cloudinary.com/v1_1/pulls/image/upload?upload_preset=offerUpload&folder=Production/Locations',
      {
        api_key: '565576631873624',
        file: image,
      },
    );
    return result.data.secure_url;
  } catch (e) {
    console.error(`Error Uploading Campaign Image: ${e}`);
    return null;
  }
};

export const AgentUpload = async (image) => {
  try {
    const result = await axios.post(
      'https://api.cloudinary.com/v1_1/pulls/image/upload?upload_preset=offerUpload&folder=Production/Agents',
      {
        api_key: '565576631873624',
        file: image,
      },
    );
    return result.data.secure_url;
  } catch (e) {
    console.error(`Error Uploading Campaign Image: ${e}`);
    return null;
  }
};

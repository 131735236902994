import React, { useState } from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ImageIcon from 'assets/images/image_icon.svg';
import RunIcon from 'assets/images/run_icon.svg';
import ViewIcon from 'assets/images/view_icon.svg';
import Moment from 'moment';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import routes from 'routes';

const ActivityItem: React.FC<any> = ({ data, handleRunAgain }) => {
  const navigate = useNavigate();
  const [card] = useState(data);

  const handleClick = () => {
    if (card.type === 'announcement') {
      navigate(routes.announcements.announcement(card.id));
    } else {
      navigate(routes.campaigns.campaign(card.id));
    }
  };

  const checkExpired = () => {
    if (card.terminated) {
      return true;
    } else if (card.type === 'announcement') {
      return false;
    } else if (Moment(card.end_date).isBefore(Moment(), 'day')) {
      return true;
    }
    return false;
  };

  return (
    <Grid container spacing={2} style={{ marginTop: 8 }}>
      <Grid item xs={6}>
        <Box
          display="flex"
          minHeight={82}
          onClick={handleClick}
          onKeyDown={handleClick}
          role="button"
          tabIndex={0}
        >
          <div>
            <CardMedia
              sx={{
                width: 82,
                height: 82,
                borderRadius: 1.5,
                marginRight: 2,
              }}
              image={card.image ? card.image : ImageIcon}
              title={card.title}
            />
          </div>
          <Box width="100%" overflow="hidden" whiteSpace="nowrap">
            <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} variant="h6">{card.title}</Typography>
            <Typography
              sx={{ maxHeight: 50, overflow: 'hidden', textOverflow: 'ellipsis' }}
              variant="subtitle2"
            >
              {card.type === 'announcement' ? '' : card.description}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6} sx={{ textAlign: 'end' }}>
        {checkExpired() && (
          <Button
            variant="outlined"
            sx={{
              minWidth: 116,
              fontSize: 11,
              padding: '2px 10px',
              color: Colors.primaryBlack,
              border: '1px solid',
              borderColor: Colors.lightBlueGray,
              margin: '2px 10px 2px 0',
              '@media (max-device-width: 769px)': {
                '&:last-child': {
                  marginLeft: 0.5,
                  marginRight: 0.5,
                },
              },
            }}
            startIcon={<img src={RunIcon} alt="" />}
            onClick={handleRunAgain}
          >
            Run Again
          </Button>
        )}
        <Button
          variant="outlined"
          sx={{
            minWidth: 119,
            fontSize: 11,
            padding: '2px 0px',
            color: Colors.primaryBlack,
            border: '1px solid',
            borderColor: Colors.lightBlueGray,
            margin: '2px 10px 2px 0',
          }}
          startIcon={<img src={ViewIcon} alt="" />}
          onClick={handleClick}
        >
          View
        </Button>
      </Grid>
    </Grid>
  );
};

ActivityItem.propTypes = {
  data: PropTypes.object.isRequired,
  handleRunAgain: PropTypes.func.isRequired,
};

export default ActivityItem;

import React from 'react';

import Colors from 'constants/colors';
import { defaultStyles } from 'constants/styles';

import Typography from '@mui/material/Typography';

import MailIcon from '@mui/icons-material/Mail';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import { TbInfoOctagonFilled } from 'react-icons/tb';
import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

import PropTypes from 'prop-types';

import StatusCell from './StatusCell';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export default function Row({
  id,
  name,
  email,
  // engagements,
  // impressions,
  // conversions,
  setCreateOpen,
}) {
  const [openTooltip, setOpenTooltipValue] = React.useState(false);

  const { teamMemberStatus } = useSelector((state: RootState) => state.teamHub);

  const filteredImpressions = teamMemberStatus?.filter((item) =>
    item.id.includes(id),
  )[0].impressions;

  return (
    <Box
      // hover
      key={name}
      sx={{ width: '100%' }}
      // sx={{
      //   // boxShadow: defaultStyles.box.shadow,
      //   backgroundColor: Colors.white,
      //   // borderRadius: 2.5,
      //   '&:hover': {
      //     boxShadow: ' 0px 0px 0px 1px rgb(0 99 255 / 50%)',
      //     backgroundColor: `${Colors.white} !important`,
      //     cursor: 'pointer',
      //   },
      // }}
    >
      {/* <TableCell
      sx={{
        border: 'none',
        padding: 0,
        minHeight: 64,

        color: 'inherit',
      }}
    >
      <StatusCell />
    </TableCell> */}
      {/* <Tooltip
        arrow
        TransitionComponent={Zoom}
        title={
                filteredImpressions > 0
                  ? 'Team Member has opened campaign card'
                  : 'Team Member has not opened campaign card'
              }
      > */}
      <Box
        sx={{
          wrap: 'nowrap',
          whiteSpace: 'nowrap',
          border: 'none',
          padding: 0,
          minHeight: 64,
          color: 'inherit',
          width: '100%',
          cursor: 'pointer',
        }}
      >
        <Box width="100%" display="flex" alignItems="center">
          <StatusCell />
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                fontWeight: 700,
                fontSize: 16,
                marginBottom: 0.3,
                width: 'fit-content',
              }}
            >
              {name}
            </Box>
            <Box sx={{ fontSize: 14, width: '100%' }}>
              <Typography noWrap sx={{ fontSize: 14 }}>
                {email}
              </Typography>
            </Box>
          </Box>
          <ClickAwayListener onClickAway={() => setOpenTooltipValue(false)}>
            <Tooltip
              onClose={() => setOpenTooltipValue(false)}
              open={openTooltip === true}
              PopperProps={{
                disablePortal: true,
              }}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              arrow
              TransitionComponent={Zoom}
              title={
                filteredImpressions > 0
                  ? 'Team Member has opened card'
                  : 'Team Member has not opened card'
              }
            >
              <Box
                sx={{
                  marginLeft: 'auto',
                  marginTop: '8px',
                  marginRight: '10px',
                  minWidth: 'fit-content',
                }}
                onClick={() => setOpenTooltipValue(true)}
              >
                <TbInfoOctagonFilled
                  color={filteredImpressions > 0 ? 'green' : 'grey'}
                  size={20}
                />
              </Box>
            </Tooltip>
          </ClickAwayListener>
          {/* <Button
          sx={{
            marginLeft: 'auto',
            marginRight: '10px',
            minWidth: 'fit-content',
            padding: '4px',
            backgroundColor: '#D9EBFF',
          }}
          onClick={() => setCreateOpen(true)}
        >
          <MailIcon sx={{ fontSize: '20px' }} />
        </Button> */}
        </Box>
      </Box>
      {/* </Tooltip> */}
      {/* <TableCell
      align="center"
      sx={{
        border: 'none',
        padding: 0,
        minHeight: 64,
        fontSize: 16,
        color: 'inherit',
      }}
    >
      {conversions}
    </TableCell> */}
      {/* <TableCell
      align="center"
      sx={{
        border: 'none',
        padding: 0,
        minHeight: 64,
        fontSize: 16,
        color: 'inherit',
      }}
    >
      <Button
        sx={{
          marginLeft: 'auto',
          minWidth: 'fit-content',
          padding: '3px',
          backgroundColor: '#D9EBFF',
        }}
        onClick={() => setCreateOpen(true)}
      >
        <MailIcon sx={{ fontSize: '18px' }} />
      </Button>
    </TableCell> */}
    </Box>
  );
}

Row.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  // engagements: PropTypes.number.isRequired,
  // impressions: PropTypes.number.isRequired,
  // conversions: PropTypes.number.isRequired,
};

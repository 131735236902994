import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import UnsplashBackground from 'assets/images/UnsplashBackground.jpg';
import { Outlet } from 'react-router-dom';

const AuthLayout: React.FC = () => (
  <Grid container sx={{ height: '100vh', width: '100%', display: 'flex' }}>
    <Grid item xs={false} sm={4} md={7} lg={8} xl={9}>
      <Box
        sx={{
          height: 1,
          width: 1,
          backgroundImage: `url(${UnsplashBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
    </Grid>
    <Grid item xs={12} sm={8} md={5} lg={4} xl={3}>
      <Paper elevation={6} square sx={{ height: 1, width: 1 }}>
        <Outlet />
      </Paper>
    </Grid>
  </Grid>
);

export default AuthLayout;

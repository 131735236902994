import React from 'react';

import Colors from 'constants/colors';
import { defaultStyles } from 'constants/styles';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow';

import PropTypes from 'prop-types';

import StatusCell from './StatusCell';

const Row = ({ name, engagements, impressions, conversions }) => (
  <TableRow
    hover
    key={name}
    sx={{
      boxShadow: defaultStyles.box.shadow,
      backgroundColor: Colors.white,
      borderRadius: 2.5,
      '&:hover': {
        boxShadow: ' 0px 0px 0px 1px rgb(0 99 255 / 50%)',
        backgroundColor: `${Colors.white} !important`,
        cursor: 'pointer',
      },
    }}
  >
    <TableCell
      sx={{
        border: 'none',
        padding: 0,
        minHeight: 64,
        fontSize: 16,
        fontWeight: 700,
        color: 'inherit',
      }}
    >
      <Box display="flex" alignItems="center">
        <StatusCell />
        {name}
      </Box>
    </TableCell>
    <TableCell
      align="center"
      sx={{
        border: 'none',
        padding: 0,
        minHeight: 64,
        fontSize: 16,
        color: 'inherit',
      }}
    >
      {engagements}
    </TableCell>
    <TableCell
      align="center"
      sx={{
        border: 'none',
        padding: 0,
        minHeight: 64,
        fontSize: 16,
        color: 'inherit',
      }}
    >
      {impressions}
    </TableCell>
    <TableCell
      align="center"
      sx={{
        border: 'none',
        padding: 0,
        minHeight: 64,
        fontSize: 16,
        color: 'inherit',
      }}
    >
      {conversions}
    </TableCell>
  </TableRow>
);

Row.propTypes = {
  name: PropTypes.string.isRequired,
  engagements: PropTypes.number.isRequired,
  impressions: PropTypes.number.isRequired,
  conversions: PropTypes.number.isRequired,
};

export default Row;

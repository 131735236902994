import React, { useEffect, useState } from 'react';

import Colors from 'constants/colors';
import Permissions from 'constants/permissions';

import { Save } from '@mui/icons-material';
import {
  Autocomplete,
  Chip,
  TextField,
  Box,
  Button,
  CircularProgress,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { ReactComponent as AlertIcon } from 'assets/images/alert_icon.svg';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { MembersActions } from 'store/entities/Members';
import { teamsActions, teamsSelectors } from 'store/entities/Teams';

import CustomModalV2 from 'components/Elements/CustomModalV2/CustomModalV2';
import MatDrawer from 'components/Elements/MatDrawer/MatDrawer';
import MatFormLabel from 'components/Elements/MatFormLabel/FormLabel';
import MatSelect from 'components/Elements/MatSelect/MatSelect';
import MatSwitch from 'components/Elements/MatSwitch/MatSwtich';
import Title from 'components/Elements/Title/Title';
import PermissionRequired from 'components/PermissionRequired';

import PermissionDrawer from './PermissionDrawer';

const EditDrawer = ({ teamMember, open, onClose, onEdit }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [locationId, setLocationId] = useState('');
  const [status, setStatus] = useState(true);
  const [editingPermission, setEditingPermission] = useState(false);
  const [tags, setTags] = useState<any>([]);

  const { businessId } = useSelector((state: any) => state.user);
  const activeLocations = useSelector(teamsSelectors.getActiveTeams);

  useEffect(() => {
    dispatch(teamsActions.fetchTeams({ filter: { status: 'active' } }));
  }, []);

  useEffect(() => {
    if (teamMember) {
      setLocationId(teamMember.locationId);
      setStatus(teamMember.status === 'active');
      setTags(teamMember.tags);
    }
  }, [teamMember]);

  /* const handleAddTag = (tag) => {
    const newTags: any = [...tags];
    newTags.push(tag);
    setTags(newTags);
  };

  const handleRemoveTag = (index) => {
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
  }; */

  const handleSave = async () => {
    await dispatch(
      MembersActions.updateMember({
        teamMemberId: teamMember?.id,
        locationId,
        status,
        tagsArray: tags,
      }),
    );
    onEdit();
    onClose();
  };

  const handlePermissionDrawerClose = () => {
    setEditingPermission(false);
  };

  const loadingUpdate = false;

  return (
    <>
      <CustomModalV2
        open={open && !editingPermission}
        onClose={onClose}
        // onSubmit={handleSave}
        isLoading={loadingUpdate}
      >
        <Box width={500}>
          <Box sx={{ mb: 2, mt: 2 }}>
            <Title size="big">{t('editTeamMember')}</Title>
          </Box>
          {teamMember && (
            <div>
              <Typography variant="h6">{teamMember.name}</Typography>
              <Typography variant="subtitle2" sx={{ marginBottom: 2 }}>
                {teamMember.email}
              </Typography>

              <Grid container spacing={2}>
                {teamMember.role !== 'owner' && (
                  <Grid item md={12} xs={12}>
                    <MatFormLabel
                      icon={
                        <Tooltip title={t('selectLocationAddTeam') as any}>
                          <AlertIcon />
                        </Tooltip>
                      }
                    >
                      {t('Team')}
                    </MatFormLabel>
                    <MatSelect
                      value={locationId || ''}
                      onChange={(e) => setLocationId(e.target.value)}
                      options={activeLocations || []}
                      nameKey="name"
                      valueKey="id"
                      size="small"
                    />
                  </Grid>
                )}

                <Grid item md={12} xs={12} sx={{ marginBottom: 1.5 }}>
                  <FormControl>
                    <MatSwitch
                      labelActive={t('active')}
                      labelInactive={t('inactive')}
                      defaultChecked={status}
                      onChange={() => setStatus(!status)}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <FormControl variant="outlined" sx={{ width: '100%' }}>
                <MatFormLabel
                  icon={
                    <Tooltip title={t('enterTagsKeywords') as any}>
                      <AlertIcon />
                    </Tooltip>
                  }
                >
                  {t('tags')}
                </MatFormLabel>
                {/* <MatChip
                  value={tags}
                  onAdd={(tag) => handleAddTag(tag)}
                  onDelete={(_, index) => handleRemoveTag(index)}
                  fullWidth={true}
                  placeholder={t('tags')}
                /> */}
                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={[]}
                  defaultValue={tags}
                  freeSolo
                  value={tags}
                  onChange={(event, newInputValue) => {
                    setTags(newInputValue ?? []);
                  }}
                  renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => (
                      <Chip
                        color="primary"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Tags"
                    />
                  )}
                />
              </FormControl>

              <div style={{ height: 20 }} />
              {teamMember.role !== 'owner' ? (
                <PermissionRequired permissions={[Permissions.business.edit]}>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => setEditingPermission(true)}
                  >
                    {t('editPermissions')}
                  </Button>
                </PermissionRequired>
              ) : null}

              <Box sx={{ marginBottom: 0.5 }}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Box display="flex" alignItems="center" gap={2}>
                    {loadingUpdate ? (
                      <CircularProgress
                        style={{
                          width: 40,
                          height: 40,
                          color: Colors.primary,
                        }}
                      />
                    ) : (
                      <>
                        <Button
                          disableElevation={true}
                          color="primary"
                          onClick={onClose}
                          sx={{
                            color: Colors.black,
                            textTransform: 'capitalize',
                          }}
                        >
                          {t('cancel')}
                        </Button>
                        <Button
                          disableElevation={true}
                          color="primary"
                          // eslint-disable-next-line no-confusing-arrow
                          onClick={() => handleSave()}
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: 600,
                            background: '#D9EBFF',
                            borderRadius: '8px',
                            px: '18px',
                            py: '6px',
                            maxHeight: 'fit-content',
                            height: 'fit-content',
                            '&:hover': {
                              background: '#D9EBFF',
                            },
                          }}
                        >
                          <Save />
                        </Button>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </div>
          )}
        </Box>
      </CustomModalV2>

      <PermissionDrawer
        open={editingPermission}
        onClose={handlePermissionDrawerClose}
        userId={teamMember && teamMember.id}
        businessId={businessId}
      />
    </>
  );
};

EditDrawer.propTypes = {
  teamMember: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

EditDrawer.defaultProps = {
  teamMember: null,
};

export default EditDrawer;

import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';

import DateRangeIcon from '@mui/icons-material/DateRange';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Box } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import Moment from 'moment';
import PropTypes from 'prop-types';
import { GithubPicker } from 'react-color';

import CTATextField from 'components/CTATextField';
import DateRangeDrawer from 'components/DateRangeDrawer';
import MatInput from 'components/Elements/MatInput/MatInput';

const AVAILABLE_COLORS = [
  '#f44336',
  '#E91E63',
  '#673AB7',
  '#2196F3',
  '#00BCD4',
  '#4CAF50',
  '#FFC107',
  '#FF9800',
  '#607D8B',
  '#ef9a9a',
  '#F48FB1',
  '#B39DDB',
  '#90CAF9',
  '#80DEEA',
  '#A5D6A7',
  '#FFE082',
  '#FFCC80',
  '#B0BEC5',
];

const ButtonBuilder = ({ item, onUpdate, cardId, onDelete, tabIndex }) => {
  const [type, setType] = useState('redirect');
  const [redirect, setRedirect] = useState('');
  const [cta, setCta] = useState('');
  const [showDates, setShowDates] = useState(false);
  const [eventDates, setEventDates] = useState([
    Moment(),
    Moment().add(1, 'days'),
  ]);
  const [address, setAddress] = useState('');

  useEffect(() => {
    if (item.version && item.version === 2) {
      if (item.cta.text !== cta) {
        setType(item.cta.type);
        setCta(item.cta.name);
        setRedirect(item.cta.link);
      }
    } else if (item && item.link) {
      setCta(item.cta);
      setRedirect(item.link);

      const newObject = {
        version: 2,
        cta: {
          link: item.link,
          name: item.cta,
          type: 'redirect',
        },
        color: item.color,
        text: Colors.white,
      };
      onUpdate(newObject);
    }
  }, [item, cta]);

  const handleChangeType = (val) => {
    setType(val);
    const object = item;
    object.cta.type = val;
    onUpdate(object);
  };

  const handleRedirectChange = (e) => {
    setRedirect(e.target.value);
    const object = item;
    object.cta.link = e.target.value;
    onUpdate(object);
  };

  const handleCta = (value) => {
    if (value.length < 24) {
      setCta(value);
      const object = item;
      object.cta.name = value;
      onUpdate(object);
    }
  };

  const handleColor = (c) => {
    const object = item;
    object.color = c.hex;
    onUpdate(object);
  };

  const handleAddress = (e) => {
    setAddress(e.target.value);
    const object = item;
    object.cta.address = e.target.value;
    onUpdate(object);
  };

  const handleSaveDates = (dates) => {
    setEventDates(dates);
    setShowDates(false);
    const object = item;
    object.cta.startDate = dates[0].toISOString();
    object.cta.endDate = dates[1].toISOString();
    onUpdate(object);
  };

  const renderActions = () => {
    switch (type) {
      case 'redirect':
        return (
          <Box my={3}>
            <CTATextField
              placeholder="Call to Action"
              onChange={handleCta}
              value={cta}
              size="small"
            />
            <MatInput
              value={redirect}
              variant="outlined"
              placeholder="Redirect URL"
              onChange={handleRedirectChange}
              size="small"
              sx={{ my: 2 }}
            />
          </Box>
        );
      case 'event':
        return (
          <Box my={3}>
            <Box
              onClick={() => setShowDates(true)}
              onKeyDown={() => setShowDates(true)}
              tabIndex={0}
              role="button"
              width={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
              mb={3}
              sx={{ cursor: 'pointer' }}
            >
              <DateRangeIcon style={{ marginRight: 8 }} />
              <Typography variant="subtitle1">
                {`${eventDates[0].format(
                  'MMM D, YYYY h:mm',
                )} - ${eventDates[1].format('MMM D, YYYY h:mm')}`}
              </Typography>
            </Box>
            <MatInput
              value={address}
              variant="outlined"
              placeholder="Address (optional)"
              onChange={handleAddress}
              size="small"
            />
          </Box>
        );
      default:
        return <div />;
    }
  };

  const renderItems = () => {
    switch (tabIndex) {
      case 5:
        return (
          <>
            <Box>
              <ButtonGroup
                color="primary"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: 1,
                }}
              >
                <Button
                  onClick={() => handleChangeType('redirect')}
                  variant={type === 'redirect' ? 'contained' : 'outlined'}
                  sx={{
                    paddingLeft: 3,
                    paddingRight: 3,
                    borderTopLeftRadius: 6,
                    borderBottomLeftRadius: 6,
                  }}
                >
                  Redirect
                </Button>
                <Button
                  onClick={() => handleChangeType('event')}
                  variant={type === 'event' ? 'contained' : 'outlined'}
                  sx={{
                    paddingLeft: 3,
                    paddingRight: 3,
                  }}
                >
                  Event
                </Button>
                <Button
                  onClick={() => handleChangeType('share')}
                  variant={type === 'share' ? 'contained' : 'outlined'}
                  sx={{
                    paddingLeft: 3,
                    paddingRight: 3,
                    borderTopRightRadius: 6,
                    borderBottomRightRadius: 6,
                  }}
                >
                  Share
                </Button>
              </ButtonGroup>
            </Box>
            {renderActions()}
          </>
        );
        break;
      case 3:
        return null;
        break;
      case 1:
        return null;
        break;
      case 7:
        return (
          <>
            <Typography variant="h6">Button Color</Typography>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <GithubPicker
                style={{ minWidth: 238 }}
                onChange={handleColor}
                colors={AVAILABLE_COLORS}
                triangle="hide"
              />
            </Box>
          </>
        );
        break;

      default:
        return (
          <>
            <Box>
              <ButtonGroup
                color="primary"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: 1,
                }}
              >
                <Button
                  onClick={() => handleChangeType('redirect')}
                  variant={type === 'redirect' ? 'contained' : 'outlined'}
                  sx={{
                    paddingLeft: 3,
                    paddingRight: 3,
                    borderTopLeftRadius: 6,
                    borderBottomLeftRadius: 6,
                  }}
                >
                  Redirect
                </Button>
                <Button
                  onClick={() => handleChangeType('event')}
                  variant={type === 'event' ? 'contained' : 'outlined'}
                  sx={{
                    paddingLeft: 3,
                    paddingRight: 3,
                  }}
                >
                  Event
                </Button>
                <Button
                  onClick={() => handleChangeType('share')}
                  variant={type === 'share' ? 'contained' : 'outlined'}
                  sx={{
                    paddingLeft: 3,
                    paddingRight: 3,
                    borderTopRightRadius: 6,
                    borderBottomRightRadius: 6,
                  }}
                >
                  Share
                </Button>
              </ButtonGroup>
            </Box>
            {renderActions()}
          </>
        );
        break;
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        textAlign: 'left',
        backgroundColor: Colors.white,
        borderRadius: 2,
        px: 2,
        pt: 1,
        pb: 2,
      }}
    >
      <Box width={1} display="flex" alignItems="center" mb={1}>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          {`Edit Card ${cardId}`}
        </Typography>
        <IconButton
          aria-label="delete"
          onClick={onDelete}
          size="large"
          sx={{ color: Colors.error }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
      {renderItems()}
      <DateRangeDrawer
        open={showDates}
        onClose={() => setShowDates(false)}
        onSave={(val) => handleSaveDates(val)}
        allowTime={true}
        startDate={eventDates[0]}
        endDate={eventDates[1]}
      />
    </Box>
  );
};

ButtonBuilder.propTypes = {
  item: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  cardId: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ButtonBuilder;

import React from 'react';

import Colors from 'constants/colors';

import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/styles';

import PropTypes from 'prop-types';

const ImgStyled = styled('img')({
  width: '100%',
  height: 100,
  objectFit: 'contain',
  borderRadius: 2.5,
  transition: '.3s ease-in-out',
  '&:hover': {
    opacity: 0.5,
  },
});

const GiphyPhotos = ({ photos, onSelect }) => {
  const selectPhoto = (photo) => {
    onSelect({
      type: 'giphy',
      url: photo.images.original.url,
      info: {},
    });
  };

  return (
    <Box width="100%" height="100%">
      {photos === null || photos.length < 1 ? (
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <ImageSearchIcon />
          <Typography variant="subtitle1">Search for gifs</Typography>
        </Box>
      ) : (
        <Box
          className="folderCard"
          width="100%"
          px={0.2}
          height={250}
          overflow="auto"
          position="relative"
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1.5,
            }}
          >
            {photos.map((photo) => (
              <Box
                width="100%"
                height={100}
                borderRadius={2.5}
                bgcolor={Colors.lightBlueGray}
                onClick={() => selectPhoto(photo)}
                onKeyDown={() => selectPhoto(photo)}
                role="button"
                tabIndex={0}
                sx={{
                  cursor: 'pointer',
                }}
              >
                <ImgStyled alt="gifs" src={photo.images.original.url} />
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

GiphyPhotos.propTypes = {
  photos: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
};

GiphyPhotos.defaultProps = {
  photos: null,
};

export default GiphyPhotos;

import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { MembersActions } from 'store/entities/Members';
import { teamsSelectors, teamsActions } from 'store/entities/Teams';

import MatCheckbox from 'components/Elements/MatCheckbox/MatCheckbox';
import MatDrawer from 'components/Elements/MatDrawer/MatDrawer';
import MatFormLabel from 'components/Elements/MatFormLabel/FormLabel';
import MatSelect from 'components/Elements/MatSelect/MatSelect';
import MatSelectMultiple from 'components/Elements/MatSelect/MatSelectMultiple';

const FilterDrawer = ({ open, onClose, onFilter }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const ACCOUNT_STATUS = [
    {
      name: t('active'),
      key: 'active',
      status: true,
    },
    {
      name: t('inactive'),
      key: 'inactive',
      status: false,
    },
    {
      name: t('pending'),
      key: 'pending',
      status: true,
    },
  ];
  const [status, setStatus] = useState(ACCOUNT_STATUS);
  const [selectedLocation, setSelectedLocation] = useState('all');
  const [selectedTags, setSelectedTags] = useState<any>([]);

  const activeLocations = useSelector(teamsSelectors.getActiveTeams)
  const { businessUserTags } = useSelector(
    (state: RootState) => state.members,
  );

  useEffect(() => {
    dispatch(teamsActions.fetchTeams({}));
    dispatch(MembersActions.getUsersTags());
  }, []);

  const handleFilter = () => {
    onFilter(
      status,
      selectedLocation,
      selectedTags.length ? selectedTags : null,
    );
    onClose();
  };

  const resetFilters = () => {
    setStatus(ACCOUNT_STATUS);
    setSelectedLocation('all');
    setSelectedTags([]);
  };

  const handleSelectChange = (event) => {
    setSelectedTags(event.target.value);
  };

  const handleAccountStatusClick = (key) => {
    const newStatus = status.map((field) => {
      if (field.key === key) {
        return {
          name: field.name,
          key,
          status: !field.status,
        };
      }
      return field;
    });
    setStatus(newStatus);
  };

  const handleLocation = (val) => {
    setSelectedLocation(val);
  };

  return (
    <MatDrawer
      open={open}
      anchor="right"
      onClose={onClose}
      onCancel={resetFilters}
      onSubmit={handleFilter}
      title={t('filters')}
      submitBtn={t('apply')}
      cancelBtn={t('resetFilters')}
    >
      <Box maxWidth={465} width="100%" pl={2} pr={2}>
        <Box display="flex" justifyContent="space-between">
          <FormControl
            component="fieldset"
            sx={{
              '&:first-child': {
                marginRight: '90px',
              },
            }}
            key="status"
          >
            <MatFormLabel style={{ marginBottom: 2.75 }}>Status</MatFormLabel>
            <FormGroup>
              {status.map((field) => (
                <FormControlLabel
                  key={field.key}
                  sx={{
                    color: Colors.black,
                    fontSize: 14,
                    '& .MuiTypography-root': {
                      marginLeft: 1.25,
                    },
                  }}
                  label={<span>{field.name}</span>}
                  control={
                    <MatCheckbox
                      checked={field.status}
                      onChange={() => handleAccountStatusClick(field.key)}
                      name={field.name}
                    />
                  }
                />
              ))}
            </FormGroup>
          </FormControl>
        </Box>

        {activeLocations && (
          <Box mt={3}>
            <MatFormLabel style={{ marginBottom: 2.75 }}>
              {t('By Team')}
            </MatFormLabel>
            <MatSelect
              value={selectedLocation}
              defaultValue={t('all')}
              onChange={(e) => handleLocation(e.target.value)}
              options={[{ name: t('all'), id: 'all' }, ...activeLocations]}
              nameKey="name"
              valueKey="id"
              style={{
                [`& fieldset`]: {
                  borderColor: Colors.primary,
                  opacity: '.5',
                },
              }}
            />
          </Box>
        )}

        {businessUserTags && businessUserTags.length > 0 && (
          <FormControl
            component="fieldset"
            sx={{
              width: '100%',
              marginTop: 3,
            }}
            key="tags"
          >
            <MatFormLabel style={{ marginBottom: 2.75 }}>
              {t('tags')}
            </MatFormLabel>

            <FormGroup>
              <MatSelectMultiple
                nameKey="name"
                valueKey="name"
                value={selectedTags}
                onChange={handleSelectChange}
                options={businessUserTags}
                style={{ width: '100%' }}
              />
            </FormGroup>
          </FormControl>
        )}
      </Box>
    </MatDrawer>
  );
};

FilterDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
};

export default FilterDrawer;

import React from 'react';
import Colors from 'constants/colors';
import Permissions from 'constants/permissions';
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  adaptV4Theme,
} from '@mui/material/styles';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AuthLayout from 'layouts/Auth';
import HomeLayout from 'layouts/Home';
import { SnackbarProvider } from 'notistack';
import Parse from 'parse';
import { Route, BrowserRouter, Navigate, Routes } from 'react-router-dom';
import AnnouncementCreate from 'views/Announcements/Create';
import AnnouncementDetails from 'views/Announcements/Details';
import Announcements from 'views/Announcements/Main';
import CreateBusinessAccount from 'views/Auth/CreateBusinessAccount';
import CreateVendorAccount from 'views/Auth/CreateVendorAccount';
import ForgotPassword from 'views/Auth/ForgotPassword';
import Login from 'views/Auth/Login';
import PasswordReset from 'views/Auth/PasswordReset';
import CampaignBuilder from 'views/Campaigns/Create';
import CampaignDetails from 'views/Campaigns/Details';
import Campaigns from 'views/Campaigns/Main';
import Dashboard from 'views/Dashboard';
import Reporting from 'views/Reporting';
import Settings from 'views/Settings';
import CardCreate from 'views/TeamHub/Card/Create';
import CardDetails from 'views/TeamHub/Card/Details';
import Folder from 'views/TeamHub/Folder';
import TeamHub from 'views/TeamHub/Main';
import TeamMembers from 'views/TeamMembers';
import Teams from 'views/Teams';
import TemplatesBuilder from 'views/Templates/Create';
import TemplateDetails from 'views/Templates/Details';
import Templates from 'views/Templates/Main';

import AuthRequired from 'components/AuthRequired';
import PermissionRequired from 'components/PermissionRequired';
import PrivacyAndPolicy from 'components/PrivacyAndPolicy';
import SubscriptionRequired from 'components/SubscriptionRequired';
import TermsAndConditions from 'components/TermsAndConditions';
import VendorRequired from 'components/VendorRequired';

import config from './config';
import DeleteAccount from 'views/Settings/DeleteAccount';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

Parse.initialize(config.PARSE_APP_ID, '');
Parse.serverURL = config.PARSE_URL;

const theme = createTheme({
  palette: {
    text: {
      primary: Colors.font,
    },
    primary: {
      main: Colors.primary,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        * {
          font-family: Arial, Helvetica, sans-serif;
        }
      `,
    },
    MuiAppBar: {
      defaultProps: {
        color: 'default',
      },
    },
  },
});

const stripePromise = loadStripe(config.STRIPE_PUBLIC_KEY);

// const SnackbarCloseButtonAction = (key) => (
//   <IconButton onClick={() => closeSnackbar(key)}>
//     <CloseIcon htmlColor="#FFF" />
//   </IconButton>
// );

const App = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider
        autoHideDuration={2500}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Elements stripe={stripePromise}>
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <AuthRequired>
                    <HomeLayout />
                  </AuthRequired>
                }
              >
                <Route index element={<Dashboard />} />
                <Route path="campaigns">
                  <Route
                    index
                    element={
                      <SubscriptionRequired>
                        <PermissionRequired
                          permissions={[Permissions.campaign.view]}
                        >
                          <Campaigns />
                        </PermissionRequired>
                      </SubscriptionRequired>
                    }
                  />
                  <Route
                    path="build"
                    element={
                      <SubscriptionRequired>
                        <PermissionRequired
                          permissions={[Permissions.campaign.create]}
                        >
                          <CampaignBuilder />
                        </PermissionRequired>
                      </SubscriptionRequired>
                    }
                  />
                  <Route
                    path=":campaignId"
                    element={
                      <SubscriptionRequired>
                        <PermissionRequired
                          permissions={[Permissions.campaign.view]}
                        >
                          <CampaignDetails />
                        </PermissionRequired>
                      </SubscriptionRequired>
                    }
                  />
                </Route>
                <Route path="templates">
                  <Route
                    index
                    element={
                      <VendorRequired>
                        <Templates />
                      </VendorRequired>
                    }
                  />
                  <Route
                    path="build"
                    element={
                      <VendorRequired>
                        <TemplatesBuilder />
                      </VendorRequired>
                    }
                  />
                  <Route
                    path=":campaignId"
                    element={
                      <VendorRequired>
                        <TemplateDetails />
                      </VendorRequired>
                    }
                  />
                </Route>
                {/* <Route path="announcements">
                  <Route
                    index
                    element={
                      <SubscriptionRequired>
                        <Announcements />
                      </SubscriptionRequired>
                    }
                  />
                  <Route
                    path="build"
                    element={
                      <SubscriptionRequired>
                        <AnnouncementCreate />
                      </SubscriptionRequired>
                    }
                  />
                  <Route
                    path=":announcementId"
                    element={
                      <SubscriptionRequired>
                        <AnnouncementDetails />
                      </SubscriptionRequired>
                    }
                  />
                </Route> */}
                {/*  */}
                <Route path="teamHub">
                  <Route
                    index
                    element={
                      <SubscriptionRequired>
                        <PermissionRequired
                          permissions={[Permissions.teamHub.view]}
                        >
                          <TeamHub />
                        </PermissionRequired>
                      </SubscriptionRequired>
                    }
                  />
                  <Route
                    path="folder/:folderId"
                    element={
                      <SubscriptionRequired>
                        <Folder />
                      </SubscriptionRequired>
                    }
                  />
                  <Route
                    path="card/:campaignId"
                    element={
                      <SubscriptionRequired>
                        <CardDetails />
                      </SubscriptionRequired>
                    }
                  />
                  <Route
                    path="card/build"
                    element={
                      <SubscriptionRequired>
                        <PermissionRequired
                          permissions={[Permissions.teamHub.create]}
                        >
                          <CardCreate />
                        </PermissionRequired>
                      </SubscriptionRequired>
                    }
                  />
                </Route>
                {/*  */}
                <Route
                  path="teams"
                  element={
                    <SubscriptionRequired>
                      <PermissionRequired permissions={[Permissions.team.view]}>
                        <Teams />
                      </PermissionRequired>
                    </SubscriptionRequired>
                  }
                />
                <Route
                  path="reporting"
                  element={
                    <SubscriptionRequired>
                      <PermissionRequired
                        permissions={[Permissions.report.create]}
                      >
                        <Reporting />
                      </PermissionRequired>
                    </SubscriptionRequired>
                  }
                />
                <Route
                  path="members"
                  element={
                    <SubscriptionRequired>
                      <PermissionRequired permissions={[Permissions.user.view]}>
                        <TeamMembers />
                      </PermissionRequired>
                    </SubscriptionRequired>
                  }
                />
                {/* <Route path="settings" element={<Settings />} /> */}
                <Route path="settings">
                  <Route index element={<Settings />} />
                </Route>
              </Route>
              <Route path="account/delete" element={<DeleteAccount />} />
              <Route path="*" element={<Navigate to="/" />} />
              <Route path="/auth" element={<AuthLayout />}>
                <Route index element={<Login />} />
                <Route path="signin" element={<Login />} />
                <Route path="signup" element={<CreateBusinessAccount />} />
                <Route path="signup/vendor" element={<CreateVendorAccount />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="password-reset" element={<PasswordReset />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Route>
              <Route
                path="terms-and-conditions"
                element={<TermsAndConditions />}
              />
              <Route path="privacy-statement" element={<PrivacyAndPolicy />} />
            </Routes>
          </BrowserRouter>
        </Elements>
      </SnackbarProvider>
    </ThemeProvider>
  </StyledEngineProvider>
);

export default App;

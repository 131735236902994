import React from 'react';

import Box from '@mui/material/Box';

import Iphone from 'assets/images/iphone-with-notch.png';
import PropTypes from 'prop-types';

const Phone = ({ children }) => (
  <Box
    bgcolor="#FFFFFF"
    borderRadius={10}
    height={595}
    width={305}
    pt={4.3}
    pr={2.62}
    pl={2.62}
    pb={2.1}
    display="flex"
    flexDirection="column"
    position="relative"
    sx={{
      backgroundImage: `url(${Iphone})`,
      backgroundSize: '305px 595px',
    }}
  >
    {children}
  </Box>
);

Phone.propTypes = {
  children: PropTypes.any,
};

Phone.defaultProps = {
  children: null,
};

export default Phone;

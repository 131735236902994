import React from 'react';

import Colors from 'constants/colors';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton/IconButton';

export interface MissingActionProps {
  setValue: (a: any) => any;
  color?: string;
}

const MissingAction: React.FC<MissingActionProps> = ({ setValue, color }) => (
  <IconButton
    sx={{
      display: 'flex',
      alignItems: 'center',
      borderRadius: 0,
    }}
    onClick={() => setValue(5)}
  >
    <AddCircleOutlineIcon
      sx={{
        color: color || Colors.primary,
        width: 16,
        height: 16,
      }}
    />
    <Typography
      sx={{
        fontSize: 10,
        color: color || Colors.primary,
        fontWeight: 'bold',
      }}
    >
      Action
    </Typography>
  </IconButton>
);

export default MissingAction;

import React from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

import Row from './Row';

const columns = [
  {
    name: 'Name',
    key: 'name',
  },
  {
    name: 'Status',
    key: 'status',
  },
];

const BusinessList = ({ businesses, onClick }) => (
  <Box width="100%">
    <Table
      sx={{
        borderSpacing: ' 0 10px !important',
        borderCollapse: 'separate !important' as any,
        '& .MuiTableCell-root': {
          border: 'none !important',
        },
      }}
    >
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={column.name}
              sx={{ border: 'none', padding: 0, minHeight: 64 }}
              align={column.key === 'name' ? 'left' : 'center'}
            >
              <Typography
                variant="subtitle1"
                sx={
                  column.key === 'name'
                    ? {
                        color: '#212121',
                        fontSize: 10,
                        marginLeft: 10.5,
                      }
                    : {
                        color: '#212121',
                        fontSize: 10,
                      }
                }
              >
                {column.name}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      {businesses && (
        <TableBody
          sx={{
            '& tr': {
              color: Colors.black,
              borderRadius: 2.5,
            },
            '& tr td:first-child': {
              borderTopLeftRadius: 2.5,
              borderBottomLeftRadius: 2.5,
              overflow: 'hidden',
            },
            '& tr td:last-child': {
              borderTopRightRadius: 2.5,
              borderBottomRightRadius: 2.5,
              overflow: 'hidden',
            },
          }}
        >
          {businesses.map((business) => (
            <Row
              key={business?.objectId}
              business={business}
              // onClick={() => onClick(business)}
            />
          ))}
        </TableBody>
      )}
    </Table>
  </Box>
);

BusinessList.propTypes = {
  businesses: PropTypes.array.isRequired,
  // onClick: PropTypes.func.isRequired,
};

export default BusinessList;

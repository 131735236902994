import React, { useState } from 'react';

import SendIcon from '@mui/icons-material/Send';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import 'react-quill/dist/quill.bubble.css';

import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { TeamHubActions } from 'store/entities/TeamHub';

import RichTextEditor from 'components/RichTextEditor';

import EmailModal from './EmailModal';

const ComposeEmail = ({ open, onClose, membersEmail }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [title, setTitle] = useState<any>('');
  const [value, setValue] = useState('');

  // function that handles send email to team members: it accepts the
  // following paramters (team member email, subject of email, text, to and cc)

  const handleSendMail = () => {
    if (title === undefined || title === '') {
      enqueueSnackbar('no title', { variant: 'error' });
    } else if (value === undefined || value === '') {
      enqueueSnackbar('no message composed', { variant: 'error' });
    } else {
      dispatch(
        TeamHubActions.sendEmail({
          to: membersEmail,
          text: value,
          subject: title,
          cc: membersEmail,
        }),
      );
    }
    setTitle('');
    setValue('');
    onClose();
  };

  return (
    <EmailModal
      open={open}
      onClose={onClose}
      onCancel={onClose}
      title="Compose Email"
    >
      <Box margin="20px" marginTop="15px" width={520} height={400}>
        <TextField
          sx={{ marginBottom: 1.8, width: '100%' }}
          id="standard-basic"
          placeholder="To"
          // label="To"
          variant="standard"
          value={membersEmail}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          sx={{ marginBottom: 2, width: '100%' }}
          id="standard-basic"
          placeholder="Subject"
          // label="Title"
          variant="standard"
          value={title}
          onChange={(e: any) => setTitle(e.target.value)}
        />
        <Box sx={{ marginBottom: 2, height: 260 }}>
          <RichTextEditor
            onChange={setValue}
            placeholder="Compose"
            size="extra"
          />
        </Box>
        <Button
          onClick={handleSendMail}
          sx={{ borderRadius: '20px', paddingX: 3 }}
          variant="contained"
          endIcon={<SendIcon />}
        >
          Send
        </Button>
      </Box>
    </EmailModal>
  );
};

// ComposeEmail.propTypes = {
//   campaignId: PropTypes.string.isRequired,
//   type: PropTypes.string.isRequired,
// };

export default ComposeEmail;

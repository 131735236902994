import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

import MatInput from 'components/Elements/MatInput/MatInput';

const FormBuilder = ({
  item,
  onUpdate,
  cardId,
  editingMode,
  onDelete,
  tabIndex,
}) => {
  const [title, setTitle] = useState(item.title);
  const [questions, setQuestions] = useState(item.fields);
  const [titleError, setTitleError] = useState(false);
  const [questionError, setQuestionError] = useState(false);

  useEffect(() => {
    if (item.title !== title) {
      setTitle(item.title);
      setQuestions(item.fields);
    }
  }, [item]);

  const handleTitle = (e) => {
    const { value } = e.target;
    setTitle(value);
    const object = item;
    object.title = value;
    onUpdate(object);

    if (titleError) {
      setTitleError(false);
    }
  };

  const handleQuestions = (e, index) => {
    const update = [...questions];
    update[index] = e.target.value;
    setQuestions(update);
    const object = item;
    object.fields = update;
    onUpdate(object);

    if (questionError) {
      setQuestionError(false);
    }
  };

  const handleNewQuestion = () => {
    if (questions.length < 6) {
      let valid = true;
      for (let x = 0; x < questions.length; x++) {
        if (questions[x] === null || questions[x] === '') {
          valid = false;
        }
      }
      if (valid) {
        const newQuestions = [...questions];
        newQuestions.push('');
        setQuestions(newQuestions);
      } else {
        setQuestionError(true);
        if (!title) {
          setTitleError(true);
        }
      }
    }
  };

  const deleteRow = (index) => {
    const temp = [...questions];
    if (temp.length >= index) {
      temp.splice(index, 1);
      setQuestions(temp);
      const object = item;
      object.fields = temp;
      onUpdate(object);
    }
  };

  const renderItems = () => {
    switch (tabIndex) {
      case 1:
        return (
          <>
            <MatInput
              value={title}
              variant="outlined"
              placeholder="Title"
              onChange={handleTitle}
              size="small"
              error={titleError}
            />
            {questions.length > 0
              ? questions.map((q, index) => (
                  <Box mt={2} display="flex" width={1}>
                    <MatInput
                      value={q}
                      variant="outlined"
                      placeholder={`Question ${index + 1}`}
                      onChange={(e) => handleQuestions(e, index)}
                      size="small"
                      error={questionError}
                    />
                    {questions.length > 1 && (
                      <div style={{ paddingLeft: 12, width: 36, height: 24 }}>
                        <IconButton
                          aria-label="delete"
                          onClick={() => deleteRow(index)}
                          size="large"
                        >
                          <DeleteIcon style={{ width: 16, height: 16 }} />
                        </IconButton>
                      </div>
                    )}
                  </Box>
                ))
              : null}
            {questions.length < 6 && !editingMode ? (
              <IconButton onClick={handleNewQuestion} size="large">
                <AddCircleOutlineIcon
                  sx={{ width: 24, height: 24, color: Colors.primary }}
                />
              </IconButton>
            ) : null}
          </>
        );
        break;
      case 3:
        return null;
        break;
      case 5:
        return null;
        break;
      case 7:
        return null;
        break;
      default:
        return (
          <>
            <MatInput
              value={title}
              variant="outlined"
              placeholder="Title"
              onChange={handleTitle}
              size="small"
              error={titleError}
            />
            {questions.length > 0
              ? questions.map((q, index) => (
                  <Box mt={2} display="flex" width={1}>
                    <MatInput
                      value={q}
                      variant="outlined"
                      placeholder={`Question ${index + 1}`}
                      onChange={(e) => handleQuestions(e, index)}
                      size="small"
                      error={questionError}
                    />
                    {questions.length > 1 && (
                      <div style={{ paddingLeft: 12, width: 36, height: 24 }}>
                        <IconButton
                          aria-label="delete"
                          onClick={() => deleteRow(index)}
                          size="large"
                        >
                          <DeleteIcon style={{ width: 16, height: 16 }} />
                        </IconButton>
                      </div>
                    )}
                  </Box>
                ))
              : null}
            {questions.length < 6 && !editingMode ? (
              <IconButton onClick={handleNewQuestion} size="large">
                <AddCircleOutlineIcon
                  sx={{ width: 24, height: 24, color: Colors.primary }}
                />
              </IconButton>
            ) : null}
          </>
        );
        break;
    }
  };

  return (
    <Paper
      sx={{
        width: '100%',
        textAlign: 'left',
        backgroundColor: Colors.white,
        borderRadius: 2,
        px: 2,
        pb: 2,
        pt: 1,
      }}
    >
      <Box width={1} display="flex" alignItems="center" mb={0.5}>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          {`Edit Card ${cardId}`}
        </Typography>
        <IconButton
          aria-label="delete"
          onClick={onDelete}
          size="large"
          sx={{ color: Colors.error }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
      {renderItems()}
    </Paper>
  );
};

FormBuilder.propTypes = {
  tabIndex: PropTypes.number,
  item: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  cardId: PropTypes.string.isRequired,
  editingMode: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default FormBuilder;

import React from 'react';

import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import routes from 'routes';

const SubscriptionModal = ({ open, onClose }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(routes.settings(), {
      state: {
        type: 'upgrade',
      },
    });
  };

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="sm">
      <Box
        sx={{
          textAlign: 'center',
          py: 6,
          px: 19,
          width: '100%',
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }} color="primary" variant="h5">
          You discovered a Premium Pro Feature
        </Typography>
        <Typography variant="body1" sx={{ my: 6 }}>
          Upgrading TappShare will unlock it.
        </Typography>
        <Button variant="contained" color="primary" onClick={handleClick}>
          Go Premium Pro
        </Button>
      </Box>
    </Dialog>
  );
};

SubscriptionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SubscriptionModal;

import React from 'react';

import Colors from 'constants/colors';
import Permissions from 'constants/permissions';
import { defaultStyles } from 'constants/styles';

import { Box } from '@mui/material';

import TeamsIcon from 'assets/images/teams_icon.svg';
import PropTypes from 'prop-types';
import { BiSolidPencil } from 'react-icons/bi';
import { VscSymbolEnumMember } from 'react-icons/vsc';

import PermissionRequired from 'components/PermissionRequired';
import TextWithToolTip from 'components/TextWithToolTip/TextWithToolTip';

const Row: React.FC<any> = ({ location, onClick, onEdit, setShowMembers }) => {
  const clickHandler = () => {
    onClick(location);
  };

  return (
    <Box
      key={location.id}
      // onClick={clickHandler}
      sx={{
        width: '100%',
        padding: '22px',
        borderRadius: '10px',
        boxShadow: defaultStyles.box.shadow,
        backgroundColor: Colors.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&:hover': {
          boxShadow: ' 0px 0px 0px 1px rgb(0 99 255 / 50%)',
          backgroundColor: `${Colors.white} !important`,
        },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Box
          sx={{
            cursor: 'pointer',
            borderRadius: '8px',
            height: '30px',
            width: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: location.is_active ? Colors.active : '#babac4',
          }}
        >
          {/* <StatusCell isActive={location.is_active} /> */}
          <img src={TeamsIcon} alt="Teams Icon" />
        </Box>
        <Box
          sx={{
            fontSize: 16,
            fontWeight: 700,
            color: '#333',
            border: 'none',
            padding: 0,
          }}
        >
          <TextWithToolTip text={location.name} />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2.5 }}>
        <Box
          sx={{
            border: 'none',
            padding: 0,
            fontSize: 16,
            fontWeight: 700,
            color: location.is_active && '#00CA51',
          }}
        >
          {location.is_active ? 'Active' : 'Inactive'}
        </Box>
        <Box
          onClick={() => setShowMembers(location)}
          sx={{
            cursor: 'pointer',
            height: '30px',
            width: '30px',
            fontSize: 16,
            fontWeight: 700,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <VscSymbolEnumMember size={25} />
        </Box>
        <PermissionRequired permissions={[Permissions.team.edit]}>
          <Box
            onClick={() => onEdit(location)}
            sx={{
              cursor: 'pointer',
              borderRadius: '8px',
              height: '30px',
              width: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#D9EBFF',
            }}
          >
            <BiSolidPencil color="#007AFF" size={17} />
          </Box>
        </PermissionRequired>
      </Box>
    </Box>
  );
};

Row.propTypes = {
  location: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  setShowMembers: PropTypes.func.isRequired,
};

export default Row;

import React from 'react'

import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom';
import routes from 'routes';
import { RootState } from 'store'

const SubscriptionRequired: React.FC = ({ children }) => {
  const { accountStatus } = useSelector((state: RootState) => state?.subscriptions);
  if (accountStatus) return <>{children}</>;
  return <Navigate to={routes.dashboard()} />
}

export default SubscriptionRequired;

import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

import ActionDot from './ActionDot';

const MailChimpCard = ({ data, results }) => (
  <Box width="100%" pl={2} pr={2} mb={2} position="relative">
    <Card
      sx={{
        width: '100%',
        textAlign: 'left',
        cursor: 'pointer',
        position: 'relative',
        borderRadius: data?.styling?.cardBorder,
      }}
    >
      <Box
        p={2}
        color={data?.styling?.mainColor}
        textAlign={data?.styling?.mainAlignment}
        fontSize={data?.styling?.mainSize}
        fontStyle={
          data?.styling?.mainFormat?.includes('italic') ? 'italic' : ''
        }
        fontWeight={data?.styling?.mainFormat?.includes('bold') ? 'bold' : ''}
        sx={{
          textDecoration: data?.styling?.mainFormat?.includes('underline')
            ? 'underline'
            : '',
        }}
      >
        <Typography variant="subtitle1">
          {data.title || 'Join our email list for more offers'}
        </Typography>
        <TextField
          label="Email"
          sx={{
            marginTop: 2,
            marginBottom: 2,
          }}
          variant="outlined"
          size="small"
          fullWidth
          disabled
        />
        <Box width="100%" pl={2} display="flex" justifyContent="center">
          <Button
            color="primary"
            variant={data.styling?.buttonVariant}
            disableElevation
            sx={{
              borderRadius: data.styling?.buttonBorder,
              color: data.styling?.textButtonColor,
              height: 22,
              marginBottom: 1.25,
              marginLeft: 1,
              borderColor:
                data.styling?.buttonVariant === 'outlined'
                  ? data.styling?.buttonColor
                  : '',
              backgroundColor:
                data.styling?.buttonVariant === 'contained'
                  ? data.styling?.buttonColor
                  : '',
            }}
          >
            <Typography sx={{ fontSize: 12 }}>Submit</Typography>
          </Button>
        </Box>
      </Box>
    </Card>
    {results !== null && results.actionOne > 0 && (
      <ActionDot count={results.actionOne} />
    )}
  </Box>
);

MailChimpCard.propTypes = {
  data: PropTypes.object.isRequired,
  results: PropTypes.object,
};

MailChimpCard.defaultProps = {
  results: null,
};

export default MailChimpCard;

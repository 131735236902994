import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

import MatInput from 'components/Elements/MatInput/MatInput';
import { FullImageSelector } from 'components/ImageSelectors';

import ActionDisplay from './ActionDisplay';
import ActionDrawer from './ActionDrawer';

const ImageCarouselBuilder = ({
  item,
  cardId,
  onDelete,
  onUpdate,
  tabIndex,
}) => {
  const [images, setImages] = useState(item.images);
  const [activeImage, setActiveImage] = useState(0);
  const [showActionDrawer, setShowActionDrawer] = useState(false);
  const [editActionTwo, setEditActionTwo] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);

  useEffect(() => {
    if (item.images !== images) {
      setImages(item.images);
      setActiveImage(0);
    }
  }, [item]);

  const handleImageSelect = (photo) => {
    const newImages = [...images];
    newImages[activeImage].image = photo;

    const object = item;
    object.images = newImages;
    onUpdate(object);
  };

  const handleDescriptionChange = (e) => {
    if (e.target.value.length < 164) {
      const newImages = [...images];
      newImages[activeImage].description = e.target.value;
      setImages(newImages);

      const object = item;
      object.images = newImages;
      onUpdate(object);
    }
  };

  const handleAddImage = () => {
    const newImages = [...images];
    newImages.push({
      image: {
        url: '',
        type: '',
        info: {},
      },
      description: '',
    });
    setImages(newImages);
    setActiveImage(newImages.length - 1);
  };

  const handleActionButtonOne = () => {
    setSelectedAction(item.ctaOne || null);
    setEditActionTwo(false);
    setShowActionDrawer(true);
  };

  const handleActionButtonTwo = () => {
    setSelectedAction(item.ctaTwo || null);
    setEditActionTwo(true);
    setShowActionDrawer(true);
  };

  const handleActionSubmit = (data) => {
    const object = item;
    if (editActionTwo) {
      object.ctaTwo = data;
    } else {
      object.ctaOne = data;
    }
    onUpdate(object);
    setShowActionDrawer(false);
  };

  const deleteActionOne = () => {
    const object = item;
    if (object.ctaTwo.type !== 'none') {
      object.ctaOne = object.ctaTwo;
      object.ctaTwo = {
        type: 'none',
        name: '',
        link: '',
      };
    } else {
      object.ctaOne = {
        type: 'none',
        name: '',
        link: '',
      };
    }
    onUpdate(object);
  };

  const deleteActionTwo = () => {
    const object = item;
    object.ctaTwo = {
      type: 'none',
      name: '',
      link: '',
    };
    onUpdate(object);
  };

  const renderItems = () => {
    switch (tabIndex) {
      case 1:
        return null;
        break;
      case 3:
        return (
          <>
            <FullImageSelector
              onSelect={handleImageSelect}
              defaultImage={images[activeImage].image || null}
            />
            <Box
              sx={{
                width: '100%',
                maxWidth: 525,
                display: 'flex',
                overflowX: 'auto',
                marginBottom: 1,
                paddingBottom: 1,
              }}
            >
              {images.map((image, index) => (
                <Box
                  sx={
                    activeImage === index
                      ? {
                          borderRadius: 2.5,
                          width: 150,
                          minWidth: 150,
                          height: 100,
                          marginRight: 2,
                          borderColor: Colors.primary,
                          borderStyle: 'solid',
                          borderWidth: 2,
                          textAlign: 'center',
                        }
                      : {
                          borderRadius: 2.5,
                          width: 150,
                          minWidth: 150,
                          height: 100,
                          marginRight: 2,
                          borderColor: Colors.darkGray,
                          borderStyle: 'solid',
                          borderWidth: 0.5,
                          textAlign: 'center',
                        }
                  }
                  onClick={() => setActiveImage(index)}
                  onKeyDown={() => setActiveImage(index)}
                  role="button"
                  tabIndex={0}
                >
                  {image?.image?.url !== null && image?.image?.url !== '' ? (
                    <img
                      alt="Header"
                      src={image?.image?.url}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: 2.5,
                      }}
                    />
                  ) : (
                    <Typography style={{ marginTop: 35 }}>
                      {`Image ${index + 1}`}
                    </Typography>
                  )}
                </Box>
              ))}
              {images.length < 4 ? (
                <Box
                  onClick={handleAddImage}
                  onKeyDown={handleAddImage}
                  tabIndex={0}
                  role="button"
                  sx={{
                    borderRadius: 2.5,
                    width: 150,
                    minWidth: 150,
                    height: 100,
                    marginRight: 2,
                    borderColor: Colors.darkGray,
                    borderStyle: 'solid',
                    borderWidth: 0.5,
                    textAlign: 'center',
                  }}
                >
                  <Typography sx={{ marginTop: 4.5 }}>+ Add Image</Typography>
                </Box>
              ) : null}
            </Box>
          </>
        );
        break;
      case 5:
        return (
          <>
            <Box
              sx={{
                width: '100%',
                borderBottomStyle: 'solid',
                borderBottomWidth: 1,
                borderBottomColor: Colors.primary,
                marginBottom: 2,
                marginTop: 3,
              }}
            >
              <Typography variant="h6">Actions</Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item md={6}>
                {item.ctaOne.type === 'none' ? (
                  <Button
                    startIcon={<AddCircleOutlineIcon />}
                    variant="text"
                    color="primary"
                    onClick={handleActionButtonOne}
                  >
                    Add Action
                  </Button>
                ) : (
                  <ActionDisplay
                    type={item.ctaOne.type}
                    onClick={handleActionButtonOne}
                    onDelete={deleteActionOne}
                  />
                )}
              </Grid>
              {item.ctaOne.type !== 'none' && item.ctaOne.type !== null && (
                <Grid item md={6}>
                  {item.ctaTwo.type === 'none' ? (
                    <Button
                      startIcon={<AddCircleOutlineIcon />}
                      variant="text"
                      color="primary"
                      onClick={handleActionButtonTwo}
                    >
                      Add Action
                    </Button>
                  ) : (
                    <ActionDisplay
                      type={item.ctaTwo.type}
                      onClick={handleActionButtonTwo}
                      onDelete={deleteActionTwo}
                    />
                  )}
                </Grid>
              )}
            </Grid>
          </>
        );
        break;
      case 7:
        return null;
        break;
      default:
        return (
          <>
            <FullImageSelector
              onSelect={handleImageSelect}
              defaultImage={images[activeImage].image || null}
            />
            <Box
              sx={{
                width: '100%',
                maxWidth: 525,
                display: 'flex',
                overflowX: 'auto',
                marginBottom: 1,
                paddingBottom: 1,
              }}
            >
              {images.map((image, index) => (
                <Box
                  sx={
                    activeImage === index
                      ? {
                          borderRadius: 2.5,
                          width: 150,
                          minWidth: 150,
                          height: 100,
                          marginRight: 2,
                          borderColor: Colors.primary,
                          borderStyle: 'solid',
                          borderWidth: 2,
                          textAlign: 'center',
                        }
                      : {
                          borderRadius: 2.5,
                          width: 150,
                          minWidth: 150,
                          height: 100,
                          marginRight: 2,
                          borderColor: Colors.darkGray,
                          borderStyle: 'solid',
                          borderWidth: 0.5,
                          textAlign: 'center',
                        }
                  }
                  onClick={() => setActiveImage(index)}
                  onKeyDown={() => setActiveImage(index)}
                  role="button"
                  tabIndex={0}
                >
                  {image?.image?.url !== null && image?.image?.url !== '' ? (
                    <img
                      alt="Header"
                      src={image?.image?.url}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: 2.5,
                      }}
                    />
                  ) : (
                    <Typography style={{ marginTop: 35 }}>
                      {`Image ${index + 1}`}
                    </Typography>
                  )}
                </Box>
              ))}
              {images.length < 4 ? (
                <Box
                  onClick={handleAddImage}
                  onKeyDown={handleAddImage}
                  tabIndex={0}
                  role="button"
                  sx={{
                    borderRadius: 2.5,
                    width: 150,
                    minWidth: 150,
                    height: 100,
                    marginRight: 2,
                    borderColor: Colors.darkGray,
                    borderStyle: 'solid',
                    borderWidth: 0.5,
                    textAlign: 'center',
                  }}
                >
                  <Typography sx={{ marginTop: 4.5 }}>+ Add Image</Typography>
                </Box>
              ) : null}
            </Box>
          </>
        );
        break;
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        textAlign: 'left',
        backgroundColor: Colors.white,
        borderRadius: 2,
        paddingRight: 2,
        paddingTop: 1,
        paddingBottom: 2,
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          marginBottom: 2,
        }}
      >
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          {`Edit Card ${cardId}`}
        </Typography>
        <IconButton
          aria-label="delete"
          onClick={onDelete}
          size="large"
          sx={{
            color: Colors.error,
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
      {renderItems()}
      <ActionDrawer
        defaultCta={selectedAction}
        open={showActionDrawer}
        onClose={() => setShowActionDrawer(false)}
        onSubmit={handleActionSubmit}
      />
    </Box>
  );
};

ImageCarouselBuilder.propTypes = {
  tabIndex: PropTypes.number,
  item: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  cardId: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ImageCarouselBuilder;

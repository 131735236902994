import React from 'react';

import { Box } from '@mui/material';

import ButtonBuilder from './ButtonBuilder';
import Empty from './Empty';
import FormBuilder from './FormBuilder';
import ImageBuilder from './ImageBuilder';
import ImageCarouselBuilder from './ImageCarouselBuilder';
import InfoBuilder from './InfoBuilder';
import MailChimpBuilder from './MailChimpBuilder';
import PollBuilder from './PollBuilder';
import SocialBuilder from './SocialBuilder';
import VideoBuilder from './VideoBuilder';
import Styling from 'components/Styling';

export interface BuilderProps {
  data: any;
  activeRow: any;
  handleUpdateItem: (a: any) => any;
  handleDeleteItem: () => any;
  editingMode?: any;
  tabIndex: number;
}

const Builder: React.FC<BuilderProps> = ({
  data,
  activeRow,
  handleDeleteItem,
  handleUpdateItem,
  editingMode,
  tabIndex,
}) => {
  const renderItems = () => {
    if (activeRow < 6 && data.length > 0 && data[activeRow]) {
      switch (data[activeRow].item.type) {
        case 'info':
          return (
            <InfoBuilder
              tabIndex={tabIndex}
              item={data[activeRow].item.info}
              onUpdate={(item) => {
                handleUpdateItem(item);
              }}
              onDelete={handleDeleteItem}
              cardId={activeRow + 1}
            />
          );
        case 'image':
          return (
            <ImageBuilder
              tabIndex={tabIndex}
              item={data[activeRow].item.info}
              onUpdate={(item) => {
                handleUpdateItem(item);
              }}
              onDelete={handleDeleteItem}
              cardId={activeRow + 1}
            />
          );
        case 'video':
          return (
            <VideoBuilder
              tabIndex={tabIndex}
              item={data[activeRow].item.info}
              onUpdate={(item) => {
                handleUpdateItem(item);
              }}
              onDelete={handleDeleteItem}
              cardId={activeRow + 1}
            />
          );
        case 'button':
          return (
            <ButtonBuilder
              tabIndex={tabIndex}
              item={data[activeRow].item.info}
              onUpdate={(item) => {
                handleUpdateItem(item);
              }}
              onDelete={handleDeleteItem}
              cardId={activeRow + 1}
            />
          );
        case 'poll':
          return (
            <PollBuilder
              tabIndex={tabIndex}
              item={data[activeRow].item.info}
              onUpdate={(item) => {
                handleUpdateItem(item);
              }}
              onDelete={handleDeleteItem}
              cardId={(activeRow + 1).toString()}
              editingMode={editingMode}
            />
          );
        case 'social':
          return (
            <SocialBuilder
              tabIndex={tabIndex}
              item={data[activeRow].item.info}
              onUpdate={(item) => {
                handleUpdateItem(item);
              }}
              onDelete={handleDeleteItem}
              cardId={(activeRow + 1).toString()}
            />
          );
        case 'form':
          return (
            <FormBuilder
              tabIndex={tabIndex}
              item={data[activeRow].item.info}
              onUpdate={(item) => {
                handleUpdateItem(item);
              }}
              onDelete={handleDeleteItem}
              cardId={(activeRow + 1).toString()}
              editingMode={editingMode}
            />
          );
        case 'carousel':
          return (
            <ImageCarouselBuilder
              tabIndex={tabIndex}
              item={data[activeRow].item.info}
              onUpdate={(item) => {
                handleUpdateItem(item);
              }}
              onDelete={handleDeleteItem}
              cardId={activeRow + 1}
            />
          );
        case 'mailchimp':
          return (
            <MailChimpBuilder
              item={data[activeRow].item.info}
              tabIndex={tabIndex}
              onUpdate={(item) => {
                handleUpdateItem(item);
              }}
              onDelete={handleDeleteItem}
              cardId={(activeRow + 1).toString()}
            />
          );
        default:
          return <Empty />;
      }
    }
    return <Empty />;
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        paddingTop: 3,
      }}
    >
      {renderItems()}
      <Styling
        tabIndex={tabIndex}
        item={data[activeRow]?.item.info}
        onUpdate={(item) => {
          handleUpdateItem(item);
        }}
      />
    </Box>
  );
};

export default Builder;

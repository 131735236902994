import React from 'react'

import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom';
import routes from 'routes';
import { RootState } from 'store'

const VendorRequired: React.FC = ({ children }) => {
  const { vendorId } = useSelector((state: RootState) => state?.user);
  if (vendorId) return <>{children}</>;
  return <Navigate to={routes.dashboard()} />
}

export default VendorRequired;

import React from 'react';

import Colors from 'constants/colors';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import PropTypes from 'prop-types';
import useWindowSize from 'utils/hooks/useWindowSize';
import { FormControl, MenuItem, Select } from '@mui/material';

const ReportingTabs = ({ tab, onTabChange }) => {
  const { width } = useWindowSize();
  return width < 660 ? (
    <FormControl variant="standard" sx={{ my: 1, mx: 5, minWidth: 120 }}>
      <Select
        labelId="demo-simple-select-standard-label"
        value={tab}
        onChange={(e) => onTabChange(e)}
      >
        <MenuItem value={0}>Campaigns</MenuItem>
        <MenuItem value={1}>Team Members</MenuItem>
      </Select>
    </FormControl>
  ) : (
    <Tabs
      sx={{ marginLeft: 6.25 }}
      value={tab}
      onChange={onTabChange}
      TabIndicatorProps={{
        style: { color: Colors.primary },
      }}
    >
      <Tab
        sx={{ fontSize: 12, width: 124, minWidth: 100 }}
        style={tab === 0 ? { color: Colors.primary } : undefined}
        label="Campaigns"
      />
      <Tab
        sx={{ fontSize: 12, width: 124, minWidth: 100 }}
        style={tab === 1 ? { color: Colors.primary } : undefined}
        label="Team Members"
      />
      {/* <Tab
            sx={{fontSize: 12,
    width: 124,
    minWidth: 100,}}
            style={tab === 2 ? { color: Colors.primary } : null}
            label="Locations"
          /> */}
      {/* <Tab
            sx={{fontSize: 12,
    width: 124,
    minWidth: 100,}}
            style={tab === 3 ? { color: Colors.primary } : null}
            label="Announcement"
          /> */}
    </Tabs>
  );
};

ReportingTabs.propTypes = {
  tab: PropTypes.number.isRequired,
  onTabChange: PropTypes.func.isRequired,
};

export default ReportingTabs;

import React, { useEffect, useState } from 'react';

import Colors from 'constants/colors';

import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/styles';

import PropTypes from 'prop-types';

import CropperDrawer from '../../CropperDrawer';

const ImgStyled = styled('img')({
  width: '100%',
  height: 160,
  objectFit: 'cover',
  borderRadius: 2.5,
  transition: '.3s ease-in-out',
  '&:hover': {
    opacity: 0.5,
  },
});

const UnsplashPhotos = ({ photos, onSelect }) => {
  const [showCrop, setShowCrop] = useState(false);
  const [selected, setSelected] = useState<any>({});

  useEffect(() => {
    if (selected.type === 'unsplash') {
      setShowCrop(true);
    }
  }, [selected]);

  const selectPhoto = (photo) => {
    setSelected({
      type: 'unsplash',
      url: photo.urls.regular,
      info: {
        photographer: photo.user.name,
        link: photo.user.links.html,
      },
    });
  };

  const handleCropPhoto = (croppedImage) => {
    setShowCrop(false);
    onSelect({
      type: 'custom',
      upload: true,
      url: croppedImage,
    });
  };

  return (
    <Box width="100%" height="100%">
      {photos === null || photos.length < 1 ? (
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <ImageSearchIcon />
          <Typography variant="subtitle1">Search for stock photos</Typography>
        </Box>
      ) : (
        <Box
          className="folderCard"
          width="100%"
          height="100%"
          p={1}
          sx={{ overflowY: 'auto' }}
        >
          <Grid container spacing={1}>
            {photos.map((photo) => (
              <Grid item xs={12}>
                <Box
                  width="100%"
                  height={190}
                  onClick={() => selectPhoto(photo)}
                  onKeyDown={() => selectPhoto(photo)}
                  role="button"
                  tabIndex={0}
                >
                  <ImgStyled alt="Stock" src={photo.urls.small} />
                  <Box width="100%" textAlign="center">
                    <Box component="span" fontSize={10}>
                      Photo by
                    </Box>
                    <a
                      style={{
                        fontSize: 10,
                        fontStyle: 'italic',
                        color: Colors.primaryLight,
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${photo.user.links.html} `}
                    >
                      {`${photo.user.name} `}
                    </a>
                    <Box component="span" fontSize={10}>
                      on
                    </Box>
                    <a
                      style={{
                        fontSize: 10,
                        fontStyle: 'italic',
                        color: Colors.primaryLight,
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://unsplash.com/?utm_source=speakeasy&utm_medium=referral"
                    >
                      Unsplash
                    </a>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      <CropperDrawer
        open={showCrop}
        onClose={() => setShowCrop(false)}
        onSave={handleCropPhoto}
        photo={selected.url}
      />
    </Box>
  );
};

UnsplashPhotos.propTypes = {
  photos: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
};

UnsplashPhotos.defaultProps = {
  photos: null,
};

export default UnsplashPhotos;

import React, { useState, useRef, useEffect } from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import Typography from '@mui/material/Typography';

import mapboxgl from 'mapbox-gl';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { campaignActions } from 'store/entities/Campaigns';

import CardContainer from 'components/Elements/CardContainer/CardContainer';
import ZoomControl from 'components/Elements/MapControls/ZoomControl';
import Title from 'components/Elements/Title/Title';

import config from '../../../../../config';

mapboxgl.accessToken = config.MAPBOX_ACCESS_TOKEN;

const ANIMATE_DURATATION = 1000;

const Map = ({ campaignId, type }) => {
  const dispatch = useDispatch();
  const [map, setMap] = useState<any>(null);
  const [loadingMapData, setLoadingMapData] = useState(false);
  const mapContainer: any = useRef(null);

  const { mapData } = useSelector((state: any) => state.campaigns);

  const onZoomIn = () => {
    map.zoomIn({ duration: ANIMATE_DURATATION });
  };

  const onZoomOut = () => {
    map.zoomOut({ duration: ANIMATE_DURATATION });
  };

  const loadMap = (data) => {
    if (mapContainer.current) {
      const newMap = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [0, 0],
        zoom: 0.1,
        attributionControl: false,
      });

      newMap.on('style.load', () => {
        newMap.addSource('engagements', {
          type: 'geojson',
          data: { features: [...data] },
          cluster: true,
          clusterMaxZoom: 14,
          clusterRadius: 30,
        });

        newMap.addLayer({
          id: 'clusters',
          type: 'circle',
          source: 'engagements',
          filter: ['has', 'point_count'],
          paint: {
            'circle-color': [
              'step',
              ['get', 'point_count'],
              Colors.primaryLight,
              100,
              Colors.primary,
              750,
              Colors.primaryDark,
            ],
            'circle-radius': [
              'step',
              ['get', 'point_count'],
              10,
              100,
              15,
              750,
              20,
            ],
          },
        });

        newMap.addLayer({
          id: 'cluster-count',
          type: 'symbol',
          source: 'engagements',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-size': 12,
          },
        });

        newMap.addLayer({
          id: 'unclustered-point',
          type: 'circle',
          source: 'engagements',
          filter: ['!', ['has', 'point_count']],
          paint: {
            'circle-color': Colors.primary,
            'circle-radius': 4,
          },
        });
      });
      setMap(newMap);
    }
  };

  useEffect(() => {
    dispatch(campaignActions.getMapData({ campaignId }));
    setLoadingMapData(true);
  }, [campaignId]);

  useEffect(() => {
    setLoadingMapData(false);
    if (mapData !== null) {
      loadMap(mapData);
    } else {
      loadMap([]);
    }
  }, [mapData]);

  return (
    <CardContainer>
      <Box
        width="100%"
        height={350}
        p="18px 18px 18px 30px"
        position="relative"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1.25}
        >
          <Title size="medium" weight="bold">
            Engagements by Geography
          </Title>
        </Box>
        <Box
          width="100%"
          height="100%"
          maxHeight={260}
          overflow="hidden"
          sx={{
            '& .mapboxgl-canvas-container': {
              height: '100%',
            },
            '& .mapboxgl-canvas': {
              width: '100% !Important',
              height: '100% !Important',
            },
            '& .mapboxgl-canvas:focus': {
              outline: 'none',
            },
          }}
          ref={
            (el) => (mapContainer.current = el) // eslint-disable-line
          }
        >
          {loadingMapData && (
            <CircularProgress
              sx={{ position: 'absolute', left: '50%', top: '50%' }}
            />
          )}
        </Box>
        <ZoomControl
          sx={{ position: 'absolute', left: 22, bottom: 22 }}
          onZoomIn={onZoomIn}
          onZoomOut={onZoomOut}
        />
        <Box position="absolute" bottom={0} right={0} pr={2.5} pl={0.75}>
          <Typography variant="caption">
            * Map only shows engagements with available location data.
          </Typography>
        </Box>
      </Box>
    </CardContainer>
  );
};

Map.propTypes = {
  campaignId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default Map;

import React from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';

import { ReactComponent as SortIcon } from 'assets/images/sort_icon.svg';
import PropTypes from 'prop-types';
import { Hidden } from '@mui/material';

const HeaderCell: React.FC<any> = ({
  column,
  orderBy,
  order,
  hideInMobile,
  onCreateSortHandler,
  className,
  sx,
}) => (
  <Hidden lgDown={hideInMobile}>
    <TableCell
      key={column.key}
      sortDirection={(orderBy === column.key ? order : false) as any}
      sx={{
        border: 'none',
        padding: 0,
        height: 46,
        pl: column.key === 'title' ? 2.5 : 3,
        // eslint-disable-next-line no-constant-condition
        pr: column.key === 'qr' ? 2.5 : column.key === 'qr' ? 3 : 0,
        ...sx,
      }}
      // eslint-disable-next-line
      align={
        column.key === 'title' || // eslint-disable-line
        column.key === 'is_active' || // eslint-disable-line
        column.key === 'name' || // eslint-disable-line
        column.key === 'email' // eslint-disable-line
          ? 'left' // eslint-disable-line
          : column.key === 'qr' // eslint-disable-line
          ? 'right' // eslint-disable-line
          : 'center' // eslint-disable-line
      }
    >
      <TableSortLabel
        active={orderBy === column.key}
        direction={order as any}
        onClick={() => onCreateSortHandler(column.key)}
        IconComponent={SortIcon as any}
        hideSortIcon={!column.key}
        sx={{
          '& .MuiTableSortLabel-icon': {
            height: '18px',
            width: '18px',
          },
          icon: {
            color: Colors.gray,
          },
          active: {
            color: Colors.black,
          },
        }}
      >
        <Typography
          variant="subtitle1"
          sx={
            column.key === 'is_active'
              ? {
                  color: '#212121',
                  fontSize: 10,
                  marginLeft: 10.5,
                }
              : {
                  color: '#212121',
                  fontSize: 10,
                }
          }
        >
          {column.name}
        </Typography>
        {orderBy === column.key ? (
          <Box
            component="span"
            border={0}
            height={1}
            m={-0.1}
            overflow="hidden"
            p={0}
            position="absolute"
            top={20}
            width={1}
            sx={{ clip: 'rect(0 0 0 0)' }}
          >
            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </Box>
        ) : null}
      </TableSortLabel>
    </TableCell>
  </Hidden>
);

HeaderCell.propTypes = {
  column: PropTypes.object.isRequired,
  orderBy: PropTypes.string,
  order: PropTypes.string.isRequired,
  onCreateSortHandler: PropTypes.func.isRequired,
  hideInMobile: PropTypes.bool,
  className: PropTypes.string,
};

HeaderCell.defaultProps = {
  orderBy: null,
  hideInMobile: false,
  className: '',
};

export default HeaderCell;

import React, { useEffect } from 'react';

import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import routes from 'routes';
import { RootState } from 'store';
import { userActions } from 'store/domain/user';
import * as Yup from 'yup';

import PasswordTextField from 'components/PasswordTextField';

const SigninSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  password: Yup.string()
    .min(8, 'This password is too short.')
    .max(50, 'This password is too long.')
    .required('Required'),
});

const Form = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { status, error } = useSelector((state: RootState) => state.user);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    switch (status) {
      case 'rejected':
        enqueueSnackbar(error, { variant: 'error' });
        dispatch(userActions.clearStatus());
        break;
      case 'fulfilled':
        enqueueSnackbar('Authentication successful.', { variant: 'success' });
        navigate(routes.dashboard());
        dispatch(userActions.clearStatus());
        break;
      default:
        break;
    }
  }, [status]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: SigninSchema,
    onSubmit: (data) => {
      dispatch(
        userActions.login({
          username: data.email,
          password: data.password,
        }),
      );
    },
  });

  return (
    <Box>
      <Typography variant="h5" align="center" sx={{ mb: 4 }}>
        {t('signIn')}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="email"
              label={t('emailAddress') as any}
              name="email"
              autoComplete="email"
              autoFocus
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordTextField
              variant="outlined"
              required
              fullWidth
              name="password"
              label={t('password') as any}
              id="password"
              autoComplete="current-password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <Box width={1} display="flex" pt={1}>
              <Link
                variant="body2"
                component={RouterLink}
                to={routes.auth.forgotPassword()}
              >
                {t('forgotPassword')}
              </Link>
              <Box flexGrow={1} />
              <Box display="grid">
                <Link
                  variant="body2"
                  component={RouterLink}
                  to={routes.auth.signup()}
                >
                  Create business account
                </Link>
                {/* <Link
                  variant="body2"
                  component={RouterLink}
                  to={routes.auth.vendor()}
                >
                  Create vendor account
                </Link> */}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Button
              disableElevation={true}
              color="primary"
              variant="contained"
              type="submit"
              sx={{
                width: 300,
                marginTop: 2,
                borderRadius: 8,
                height: 50,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {t('signIn')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;

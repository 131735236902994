import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Moment from 'moment';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { DragDropContext } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import routes from 'routes';
import { RootState } from 'store';
import { campaignActions } from 'store/entities/Campaigns';
import useWindowSize from 'utils/hooks/useWindowSize';
import { getPlainText } from 'utils/wysiwyg_filter';

import HomeContainer from 'components/ui/HomeContainer';
import Scaffold from 'components/ui/Scaffold';

import BasicInfo from './components/BasicInfo';
import Builder from './components/Builder';
import Styling from 'components/Styling';
import Components from './components/Components';
import DragNDropEmulator from './components/DragNDropEmulator';
import SubscriptionModal from './components/SubscriptionModal';
import TabPanel from 'components/TabPanel';
import ToolBar from './components/ToolBar';
import checkErrors from './helpers/ErrorCheck';
import createNewItem from './helpers/Item';
import { CircularProgress } from '@mui/material';
import { BusinessFolderUpload } from 'utils/cloudinary';
import OGPEmulator from './components/OGPEmulator';
import { templatesActions } from 'store/entities/Templates';

const TemplateBuilder = () => {
  const navigate = useNavigate();
  const historyLocation: any = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [campaignId, setCampaignId] = useState(null);
  const [title, setTitle] = useState<any>('');
  const [description, setDescription] = useState('');
  const [duration, setDuration] = useState([Moment(), Moment().add(5, 'days')]);
  const [image, setImage] = useState<any>(null);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
  const [locationId, setLocationId] = useState<any>('all');
  const [conditions, setConditions] = useState<any>(null);
  const [conditionsHtml, setConditionsHtml] = useState(null);
  const [age, setAge] = useState('all');
  const [pixel, setPixel] = useState(null);
  const [targetAudience, setTargetAudience] = useState(null);
  const [tags, setTags] = useState([]);
  const [data, setData] = useState<any>([]);
  const [activeRow, setActiveRow] = useState(-1);
  const [detailedErrors, setDetailedErrors] = useState([]);
  const [editing, setEditing] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const { width } = useWindowSize();

  useEffect(() => {
    if (width < 660) {
      navigate('/');
    }
  }, [width]);

  const { user } = useSelector((state: any) => state.user);
  const { campaign } = useSelector((state: RootState) => state.templates);

  const handleSetDuration = (startDate, endDate) => {
    const today = Moment();
    let start = Moment(startDate);
    if (start.isBefore(today)) {
      start = today;
    }
    let end = Moment(endDate);
    if (end.isBefore(today)) {
      end = Moment().add(5, 'days');
    }
    setDuration([start, end]);
  };

  const setCampaign = (results, repeat) => {
    if (!repeat) {
      setCampaignId(results.id);
    }
    setTitle(results.title);
    setImage(results.image);
    setSelectedTeamMembers(results.teamMembers);
    setLocationId(results.locationId);
    setData([...results.data]);
    if (repeat) {
      handleSetDuration(results.startDate, results.endDate);
    } else {
      setDuration([Moment(results.startDate), Moment(results.endDate)]);
    }
    if (results.advanced) {
      if (results.advanced.conditions) {
        const conditionsConverter: any = new QuillDeltaToHtmlConverter(
          results.advanced.conditions,
          {},
        );
        setConditionsHtml(conditionsConverter.convert());
        setConditions(results.advanced.conditions);
        // Set Conditions
      }
      if (results.advanced.age) {
        setAge(results.advanced.age);
      }
      if (results.advanced.pixel) {
        setPixel(results.advanced.pixel);
      }
      if (results.advanced.targetAudience) {
        setTargetAudience(results.advanced.targetAudience);
      }
      if (results.advanced.tags) {
        setTags(results.advanced.tags.map((tag) => tag.name));
      }
    }
  };

  useEffect(() => {
    if (campaign && historyLocation?.state?.id) {
      setLoading(false);
      setCampaign(campaign, historyLocation.state.type === 'runagain');
    }
  }, [campaign]);

  useEffect(() => {
    if (
      historyLocation.state &&
      historyLocation.state.id &&
      historyLocation.state.type
    ) {
      if (historyLocation.state.type === 'edit') {
        setEditing(true);
      }
      setLoading(true);
      dispatch(templatesActions.getTemplate({ id: historyLocation.state.id }));
    } else {
      const item = createNewItem('info');
      setData([
        {
          item,
          row: 0,
        },
      ]);
      setActiveRow(-1);
    }
  }, []);

  useEffect(() => {
    switch (data[activeRow]?.item.type) {
      case 'info':
        setValue(1);
        break;
      case 'image':
        setValue(1);
        break;
      case 'video':
        setValue(1);
        break;
      case 'button':
        setValue(5);
        break;
      case 'poll':
        setValue(1);
        break;
      case 'social':
        setValue(1);
        break;
      case 'form':
        setValue(1);
        break;
      case 'carousel':
        setValue(1);
        break;
      case 'mailchimp':
        setValue(1);
        break;
      default:
        setValue(9);
        break;
    }
  }, [activeRow]);

  useEffect(() => {
    const errors: any = [];
    let requiredCard = false;
    let requiredCta = false;
    data.forEach((val, index) => {
      const issues = checkErrors(val.item);
      if (issues.length > 0) {
        errors.push({
          card: index + 1,
          issues,
        });
      }
      const { type } = val.item;
      if (type === 'info' || type === 'image') {
        requiredCard = true;
      }

      if (type === 'info' || type === 'carousel') {
        if (val.item.info.ctaOne.type !== 'none') {
          requiredCta = true;
        }
      } else if (type === 'image') {
        if (val.item.info.cta.type !== 'none') {
          requiredCta = true;
        }
      } else if (type === 'button') {
        requiredCta = true;
      }
    });
    if (!requiredCard || !requiredCta || !title || data.length < 1) {
      errors.push({
        card: 'Minimum',
        issues: [
          data.length < 1 ? t('addAtLeast1Cards') : '',
          !requiredCard ? t('includeOneInformationCard') : '',
          !requiredCta ? t('includeOneAction') : '',
          !title ? t('mustEnterTitleCampaign') : '',
        ],
      });
    }
    setDetailedErrors(errors);
  }, [data, title]);

  const handleUpdateItem = (item) => {
    const newData = JSON.parse(JSON.stringify(data));
    newData[activeRow].item.info = item;
    setData(newData);

    if (activeRow === 0) {
      // setTitle(item.title);
      setImage(item.image);
      if (item.description) {
        setDescription(item.description);
      } else {
        setDescription('');
      }
    }
  };

  const onCampaignCreated = (campaign) => {
    setLoading(false);
    navigate(routes.templates.campaign(campaign.objectId));
  };

  const onCampaignSaved = (campaign) => {
    setLoading(false);
    navigate(routes.templates.main());
  };

  const createCampaign = async () => {
    setLoading(true);
    let newImage;
    if (image?.type === 'custom' && image?.upload === true) {
      const uploadUrl = await BusinessFolderUpload({
        businessId: user?.vendor_id,
        image: image.url,
      });
      newImage = { ...image, url: uploadUrl };
    }
    dispatch(
      templatesActions.createTemplate({
        id: campaignId,
        title,
        image: newImage,
        published: true,
        startDate: duration[0].toISOString(),
        endDate: duration[1].toISOString(),
        description,
        info: data,
        callback: onCampaignCreated,
        advanced: {
          conditions: conditions ? conditions.ops : null,
          age,
          pixel,
          targetAudience,
          tags,
        },
      }),
    );
  };

  const saveCampaign = () => {
    setLoading(true);
    dispatch(
      templatesActions.createTemplate({
        id: campaignId,
        title,
        image,
        published: false,
        startDate: duration[0].toISOString(),
        endDate: duration[1].toISOString(),
        description,
        info: data,
        callback: onCampaignCreated,
        advanced: {
          conditions: conditions ? conditions.ops : null,
          age,
          pixel,
          targetAudience,
          tags,
        },
      }),
    );
  };

  const handleDeleteItem = () => {
    const newObjects = [...data];
    newObjects.splice(activeRow, 1);
    setActiveRow(10);
    setData(newObjects);
  };

  const onDragEnd = (result) => {
    const { draggableId, source, destination } = result;
    if (!destination) {
      return;
    }

    if (editing) {
      return;
    }

    if (
      source.droppableId === 'template' &&
      destination.droppableId === 'emulator' &&
      data.length < 5
    ) {
      let { index } = destination;
      if (index === 0) {
        index += 1;
      }
      const array = Array.from(data);
      const item = createNewItem(draggableId);
      array.splice(index, 0, {
        item,
        row: index,
      });
      setData(array);
      setActiveRow(index);
    } else if (
      source.droppableId === 'emulator' &&
      destination.droppableId === 'emulator' &&
      destination.index !== 0 &&
      source.index !== 0
    ) {
      const array = Array.from(data);
      const [removed] = array.splice(source.index, 1);
      array.splice(destination.index, 0, removed);
      setData(array);
      setActiveRow(destination.index);
    } else if (
      source.droppableId === 'emulator' &&
      destination.droppableId === 'template'
    ) {
      // TODO Delete Item
    }
  };

  const onMainButton = () => {
    switch (activeStep) {
      case 0:
        setActiveStep(1);
        break;
      case 1:
        createCampaign();
        break;
      default:
        setActiveStep(1);
        break;
    }
  };

  const renderActiveStep = () => {
    switch (activeStep) {
      case 0:
        return (
          <Box
            sx={{
              width: 'calc(100% - 75px)',
              flexGrow: 1,
              display: 'flex',
              pl: 3,
              overflowY: 'hidden',
              height: 'calc(100vh - 64px)',
            }}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Components
                editing={editing}
                onShowSubscription={() => setShowSubscriptionModal(true)}
                title={title}
                handleTitle={(e: any) => setTitle(e.target.value)}
              />
              <DragNDropEmulator
                data={data}
                setValue={setValue}
                businessName={title}
                businessPhoto={user.imageUrl}
                activeRow={activeRow}
                setActiveRow={(val) => setActiveRow(val)}
                editing={editing}
                conditions={conditionsHtml}
                handleUpdateItem={handleUpdateItem}
              />
            </DragDropContext>
            <Box width={850} position="relative" height="calc(100vh - 64px)">
              <TabPanel value={value} index={1}>
                <Typography variant="h6" mb={1}>
                  Inputs
                </Typography>
                <Builder
                  data={data}
                  activeRow={activeRow}
                  tabIndex={value}
                  handleUpdateItem={handleUpdateItem}
                  handleDeleteItem={handleDeleteItem}
                />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Typography variant="h6">Photos</Typography>
                <Builder
                  data={data}
                  activeRow={activeRow}
                  tabIndex={value}
                  handleUpdateItem={handleUpdateItem}
                  handleDeleteItem={handleDeleteItem}
                />
              </TabPanel>
              <TabPanel value={value} index={5}>
                <Typography variant="h6">Actions</Typography>
                <Builder
                  data={data}
                  activeRow={activeRow}
                  tabIndex={value}
                  handleUpdateItem={handleUpdateItem}
                  handleDeleteItem={handleDeleteItem}
                />
              </TabPanel>
              <TabPanel value={value} index={9}>
                <Typography variant="h6" mb={1}>
                  Default Inputs
                </Typography>
                <BasicInfo
                  range={duration}
                  teamMembers={selectedTeamMembers}
                  age={age}
                  facebookPixel={pixel}
                  targetAudience={targetAudience}
                  tags={tags}
                  conditions={conditions}
                  locationId={locationId}
                  handleRange={(range) => setDuration(range)}
                  handleTeamMembers={(val) => setSelectedTeamMembers(val)}
                  handleLocationId={(val) => setLocationId(val)}
                  handleAge={(val) => setAge(val)}
                  handlePixel={(val) => setPixel(val)}
                  handleAudience={(val) => setTargetAudience(val)}
                  handleTags={(val) => {
                    setTags(val);
                  }}
                  title={title}
                  handleTitle={(e: any) => setTitle(e.target.value)}
                  handleConditions={(delta, html) => {
                    setConditionsHtml(html);
                    setConditions(delta);
                  }}
                />
              </TabPanel>
            </Box>
          </Box>
        );
      case 1:
        return (
          <Box
            sx={{
              width: '100%',
              flexGrow: 1,
              display: 'flex',
              px: 3,
              overflowY: 'hidden',
              height: 'calc(100vh - 64px)',
            }}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <div
                style={{ width: '100%', paddingTop: 24, textAlign: 'center' }}
              >
                <Typography style={{ marginBottom: 24 }} variant="h5">
                  Template Preview
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <DragNDropEmulator
                    data={data}
                    businessName={user.firstname}
                    businessPhoto={user.imageUrl}
                    activeRow={20}
                    setActiveRow={() => console.error('nope')}
                    editing={true}
                    conditions={conditionsHtml}
                  />
                </Box>
              </div>
            </DragDropContext>
          </Box>
        );
      default:
        return <div />;
    }
  };

  return (
    <Scaffold
      HeaderProps={{
        title: 'Build Your Own Template',
      }}
    >
      <HomeContainer
        display="flex"
        height="calc(100vh - 64px)"
        sx={{ padding: '0 !important' }}
        flexDirection="column"
      >
        {loading ? (
          <Box
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {renderActiveStep()}
            <ToolBar
              setValue={setValue}
              value={value}
              type={data[activeRow] ? data[activeRow]?.item?.type : 'default'}
              errors={detailedErrors}
              create={onMainButton}
              save={saveCampaign}
              activeStep={activeStep}
              back={() => setActiveStep(0)}
            />
          </>
        )}
      </HomeContainer>
      {/* <BottomButtons
        editing={editing}
        onMain={onMainButton}
        onBack={() => setActiveStep(activeStep - 1)}
        onSave={saveCampaign}
        allowSave={image !== null && title && description}
        loading={creating}
        step={activeStep}
      /> */}
      {/* <ErrorDrawer
        open={detailedErrors.length > 0}
        onClose={() => setDetailedErrors([])}
        errors={detailedErrors}
      /> */}
      <SubscriptionModal
        open={showSubscriptionModal}
        onClose={() => setShowSubscriptionModal(false)}
      />
    </Scaffold>
  );
};

export default TemplateBuilder;

import React, { useEffect, useState } from 'react';

import Colors from 'constants/colors';

import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import TextField from '@mui/material/TextField';

import DefaultImage from 'assets/images/default-background.svg';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton/IconButton';
import MissingAction from './MissingAction';
import MatInput from 'components/Elements/MatInput/MatInput';

const ImageCarousel = ({
  onClick,
  cardId,
  item,
  active,
  styling,
  setValue,
  onUpdate,
  setActiveImage,
}) => {
  const handleChangeDescription = (e, activeImage) => {
    if (e.target.value.length < 164) {
      const newImages = [...item.images];
      newImages[activeImage].description = e.target.value;

      const object = item;
      object.images = newImages;
      onUpdate(object);
    }
  };

  const renderCta = (cta) => {
    if (cta.type === 'redirect') {
      return (
        <Button
          color="primary"
          variant={cta.styling?.buttonVariant}
          disableElevation
          onClick={() => {
            if (setValue) {
              setValue(5);
            } else {
              const link = cta.link.includes('https://')
                ? cta.link
                : `https://${cta.link}`;
              const win = window.open(link);
              win?.focus();
            }
          }}
          sx={{
            borderRadius: cta.styling?.buttonBorder,
            color: cta.styling?.textButtonColor,
            height: 22,
            marginLeft: 1,
            marginRight: 1,
            marginBottom: 1.25,
            borderColor:
              cta.styling?.buttonVariant === 'outlined'
                ? cta.styling?.buttonColor
                : '',
            backgroundColor:
              cta.styling?.buttonVariant === 'contained'
                ? cta.styling?.buttonColor
                : '',
          }}
        >
          <Typography
            sx={{
              fontSize: 8,
            }}
          >
            {cta.name}
          </Typography>
        </Button>
      );
    } else if (cta.type === 'event') {
      return (
        <Button
          color="primary"
          disableElevation
          sx={{
            borderRadius: styling?.buttonBorder,
            color: styling?.textButtonColor,
            height: 22,
            marginLeft: 1,
            marginRight: 1,
            marginBottom: 1.25,
            borderColor:
              styling?.buttonVariant === 'outlined' ? styling?.buttonColor : '',
            backgroundColor:
              styling?.buttonVariant === 'contained'
                ? styling?.buttonColor
                : '',
          }}
          endIcon={
            <EventOutlinedIcon
              sx={{
                width: 12,
                height: 12,
              }}
            />
          }
        >
          <Typography sx={{ fontSize: 8 }}>Add to Calendar</Typography>
        </Button>
      );
    } else if (setValue) {
      return <MissingAction setValue={setValue} />;
    }
    return null;
  };

  return (
    <Box
      sx={{
        marginLeft: 1,
        marginRight: 1,
        marginBottom: 2,
      }}
    >
      <Box
        onClick={onClick}
        onKeyDown={onClick}
        role="button"
        tabIndex={0}
        sx={
          active
            ? {
                textAlign: 'left',
                borderColor: Colors.primaryLight,
                borderRadius: styling?.cardBorder,
                borderStyle: 'solid',
                borderWidth: 3,
                position: 'relative',
              }
            : {
                width: '100%',
                textAlign: 'left',
                cursor: 'pointer',
                borderRadius: styling?.cardBorder,
                position: 'relative',
              }
        }
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            overflowX: 'auto',
          }}
        >
          {setValue && (
            <IconButton
              sx={{ position: 'absolute', top: 5, left: 5, zIndex: 9 }}
              aria-label="add image"
              onClick={() => setValue(3)}
            >
              <AddPhotoAlternateIcon sx={{ color: 'white' }} />
            </IconButton>
          )}
          {item.images.map((image, index) => (
            <Box
              sx={{
                width: '80%',
                minWidth: '80%',
                maxWidth: '80%',
                marginRight: 2,
                textAlign: 'left',
              }}
            >
              {image?.image?.url !== null && image?.image?.url !== '' ? (
                <img
                  alt="Custom"
                  src={image?.image?.url}
                  style={{
                    width: '100%',
                    height: 125,
                    objectFit: 'cover',
                    borderRadius: 2.5,
                  }}
                />
              ) : (
                <img
                  alt="Default"
                  src={DefaultImage}
                  style={{
                    width: '100%',
                    height: 125,
                    objectFit: 'cover',
                    borderRadius: 2.5,
                  }}
                />
              )}
              <Box
                sx={{
                  width: '100%',
                  paddingLeft: 1,
                  paddingRight: 1,
                  textDecoration: styling?.mainFormat?.includes('underline')
                    ? 'underline'
                    : '',
                }}
                color={styling?.mainColor}
                textAlign={styling?.mainAlignment}
                fontSize={styling?.mainSize}
                fontStyle={
                  styling?.mainFormat?.includes('italic') ? 'italic' : ''
                }
                fontWeight={styling?.mainFormat?.includes('bold') ? 'bold' : ''}
              >
                {image.description ? (
                  <Typography
                    sx={{
                      cursor: `${setValue ? 'text' : ''}`,
                    }}
                    onClick={() => {
                      if (setValue) {
                        setValue(1);
                        setActiveImage(index);
                      }
                    }}
                    variant="subtitle2"
                  >
                    {image.description}
                  </Typography>
                ) : (
                  <Typography
                    onClick={() => {
                      if (setValue) {
                        setValue(1);
                        setActiveImage(index);
                      }
                    }}
                    sx={{
                      fontSize: 12,
                      cursor: `${setValue ? 'text' : ''}`,
                      fontStyle: 'italic',
                    }}
                  >
                    Enter a description
                  </Typography>
                )}
              </Box>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            textAlign: 'left',
            marginTop: 2,
          }}
        >
          <Box style={{ flexGrow: 1 }}>{renderCta(item.ctaOne)}</Box>
          <Box>{renderCta(item.ctaTwo)}</Box>
        </Box>
        <Box
          sx={{
            top: 0,
            right: 0,
            height: 20,
            marginTop: 1.25,
            marginRight: 0.75,
            paddingLeft: 1.25,
            paddingRight: 1.25,
            position: 'absolute',
            backgroundColor: 'rgba(97,97,97,0.7)',
            borderRadius: 6,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              color: Colors.white,
              fontSize: 10,
            }}
          >
            {cardId}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

ImageCarousel.propTypes = {
  cardId: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  styling: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  setValue: PropTypes.func,
  setActiveImage: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default ImageCarousel;

import React from 'react';

import Colors from 'constants/colors';
import { defaultStyles } from 'constants/styles';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow';

import PropTypes from 'prop-types';

import StatusCell from './StatusCell';
import { Button } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

const Row = ({ name, engagements, impressions, conversions }) => (
  <Box
    // hover
    key={name}
  >
    <Box
      sx={{
        border: 'none',
        padding: 0,
        minHeight: 64,
        fontSize: 16,
        fontWeight: 700,
        color: 'inherit',
      }}
    >
      <Box justifyContent="space-between" display="flex" alignItems="center">
        <StatusCell />

        {name}
        <Button
          sx={{
            marginLeft: 'auto',
            marginRight: '10px',
            minWidth: 'fit-content',
            borderRadius: '8px',
            padding: '3px',
            backgroundColor: 'rgba(51, 51, 51, 0.15)',
          }}
          // onClick={() => setCreateOpen(true)}
        >
          <KeyboardArrowDown sx={{ color: 'black', fontSize: '20px' }} />
        </Button>
      </Box>
    </Box>
  </Box>
);

Row.propTypes = {
  name: PropTypes.string.isRequired,
  engagements: PropTypes.number.isRequired,
  impressions: PropTypes.number.isRequired,
  conversions: PropTypes.number.isRequired,
};

export default Row;

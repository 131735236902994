import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';
import Permissions from 'constants/permissions';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Collapse,
  Container,
  FormGroup,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
  styled,
  Button,
  ButtonProps,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { createTheme } from '@mui/material/styles';

import ActiveIcon from 'assets/images/campaigns_active.svg';
import CompletedIcon from 'assets/images/campaigns_completed.svg';
import SavedIcon from 'assets/images/campaigns_saved.svg';
import { ReactComponent as SettingsIcon } from 'assets/images/settings_icon.svg';
import { useTranslation } from 'react-i18next';
import { MdUpcoming } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import routes from 'routes';
import { campaignActions } from 'store/entities/Campaigns';
import useWindowSize from 'utils/hooks/useWindowSize';

import MatInput from 'components/Elements/MatInput/MatInput';
import EmptyPage from 'components/EmptyPage';
import HomeContainer from 'components/ui/HomeContainer';
import Scaffold from 'components/ui/Scaffold';

import CampaignFilterDrawer from './components/CampaignFilterDrawer';
import CampaignList from './components/CampaignList/CampaignList';
import SavedList from './components/SavedList';
import PermissionRequired from 'components/PermissionRequired';

const CreateCampaignButton: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Button
      variant="contained"
      component={Link}
      to={routes.campaigns.build()}
      startIcon={<AddIcon />}
    >
      {t('create')}
    </Button>
  );
};

interface AddCampaignButtonProps {
  display: string;
  onClick: () => void;
}

const AddButton = styled(Button)<ButtonProps>(() => ({
  color: '#00CA51',
  textTransform: 'capitalize',
  boxShadow: 'none',
  backgroundColor: 'rgba(0, 202, 81, 0.10)',
  '&:hover': {
    backgroundColor: 'rgba(0, 202, 81, 0.10)',
  },
  borderRadius: 14,
  paddingY: 1,
  fontWeight: 600,
}));

const AddCampaignButton: React.FC<AddCampaignButtonProps> = ({
  display,
  onClick,
}) => (
  <AddButton
    sx={{ fontSize: '15px' }}
    variant="contained"
    onClick={onClick}
    endIcon={<AddIcon fontSize="medium" />}
  >
    {display}
  </AddButton>
);

const theme = createTheme();

const Campaigns = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const [filterTags, setFilterTags] = useState(null);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [activeLimit, setActiveLimit] = useState(50);
  const [activePage, setActivePage] = useState(0);
  const [activeSort, setActiveSort] = useState('createdAt');
  const [activeDesc, setActiveDesc] = useState(true);
  const [pastLimit, setPastLimit] = useState(50);
  const [pastPage, setPastPage] = useState(0);
  const [pastSort, setPastSort] = useState('createdAt');
  const [pastDesc, setPastDesc] = useState(true);
  const [futureLimit, setFutureLimit] = useState(50);
  const [savedLimit, setSavedLimit] = useState(50);
  const [savedPage, setSavedPage] = useState(0);
  const [futurePage, setFuturePage] = useState(0);
  const [futureSort, setFutureSort] = useState('createdAt');
  const [futureDesc, setFutureDesc] = useState(true);
  const [searchType, setSearchType] = useState('title');
  const [filter, setFilter] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [tabSwitch, setTabSwitch] = useState(0);

  const {
    activeCampaigns,
    pastCampaigns,
    futureCampaigns,
    savedCampaigns,
    activeLoading,
    pastLoading,
    futureLoading,
    savedLoading,
    businessCampaignTags,
  } = useSelector((state: any) => state.campaigns);

  useEffect(() => {
    dispatch(campaignActions.getCampaignTags());
    dispatch(campaignActions.clearStatus());
  }, []);

  const [filterList, setFilterList] = useState<any>([]);

  useEffect(() => {
    setFilterList([
      ...activeCampaigns?.data,
      ...pastCampaigns?.data,
      ...futureCampaigns?.data,
      ...savedCampaigns?.data,
    ]);
  }, [activeCampaigns, pastCampaigns, futureCampaigns, savedCampaigns]);

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    const search = event.target.value;
    if (search !== null && search !== '') {
      const updatedList: any[] = [];
      [
        ...activeCampaigns?.data,
        ...pastCampaigns?.data,
        ...futureCampaigns?.data,
        ...savedCampaigns?.data,
      ].forEach((campaignItem) => {
        if (campaignItem.title.toLowerCase().includes(search.toLowerCase())) {
          updatedList.push(campaignItem);
        }
      });
      setFilterList(updatedList);
    } else {
      setFilterList([
        ...activeCampaigns?.data,
        ...pastCampaigns?.data,
        ...futureCampaigns?.data,
        ...savedCampaigns?.data,
      ]);
    }
  };

  const clearSearch = () => {
    setSearchValue('');
    setFilterList([
      ...activeCampaigns?.data,
      ...pastCampaigns?.data,
      ...futureCampaigns?.data,
      ...savedCampaigns?.data,
    ]);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      const filterObj = {};
      filterObj[searchType] = searchValue;
      setFilter(filterObj);
    }
  };

  const handleActiveSettings = ({ limit, page, sort, desc }) => {
    if (limit !== activeLimit) {
      setActiveLimit(limit);
      if (
        parseInt((activeCampaigns.total / activeCampaigns.limit) as any, 10) < 1
      ) {
        setActivePage(0);
      }
    }
    if (page !== activePage) {
      setActivePage(page);
    }
    if (sort !== activeSort) {
      setActiveSort(sort);
    }
    if (desc !== activeDesc) {
      setActiveDesc(desc);
    }
  };

  const handlePastSettings = ({ limit, page, sort, desc }) => {
    if (limit !== pastLimit) {
      setPastLimit(limit);
      if (
        parseInt((pastCampaigns.total / pastCampaigns.limit) as any, 10) < 1
      ) {
        setPastPage(0);
      }
    }
    if (page !== pastPage) {
      setPastPage(page);
    }
    if (sort !== pastSort) {
      setPastSort(sort);
    }
    if (desc !== pastDesc) {
      setPastDesc(desc);
    }
  };

  const handleFutureSettings = ({ limit, page, sort, desc }) => {
    if (limit !== futureLimit) {
      setFutureLimit(limit);
      if (
        parseInt((futureCampaigns.total / futureCampaigns.limit) as any, 10) < 1
      ) {
        setFuturePage(0);
      }
    }
    if (page !== futurePage) {
      setFuturePage(page);
    }
    if (sort !== futureSort) {
      setFutureSort(sort);
    }
    if (desc !== futureDesc) {
      setFutureDesc(desc);
    }
  };

  const handleSavedSettings = ({ limit, page }) => {
    if (limit !== savedLimit) {
      setSavedLimit(limit);
      if (
        parseInt((savedCampaigns.total / savedCampaigns.limit) as any, 10) < 1
      ) {
        setSavedPage(0);
      }
    }
    if (page !== futurePage) {
      setSavedPage(page);
    }
  };

  const onApplyFilter = () => {
    dispatch(
      campaignActions.fetchCurrentCampaigns({
        limit: activeLimit,
        page: 1 + activePage,
        sort: activeSort,
        desc: activeDesc,
        filter,
        filterTags,
      }),
    );
    dispatch(
      campaignActions.fetchPastCampaigns({
        limit: pastLimit,
        page: 1 + pastPage,
        sort: pastSort,
        desc: pastDesc,
        filter,
        filterTags,
      }),
    );
    dispatch(
      campaignActions.fetchFutureCampaigns({
        limit: futureLimit,
        page: 1 + futurePage,
        sort: futureSort,
        desc: futureDesc,
        filter,
        filterTags,
      }),
    );

    dispatch(
      campaignActions.fetchSavedCampaigns({
        limit: savedLimit,
        page: 1 + savedPage,
        filter,
        filterTags,
      }),
    );
  };

  /* useEffect(() => {
    onApplyFilter();
  }, [filter]); */

  useEffect(() => {
    if (activeLoading || pastLoading || futureLoading || savedLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [activeLoading, pastLoading, futureLoading, savedLoading]);

  useEffect(() => {
    setIsLoading(activeLoading && pastLoading && futureLoading && savedLoading);
  }, [activeLoading, pastLoading, futureLoading, savedLoading]);

  useEffect(() => {
    dispatch(
      campaignActions.fetchCurrentCampaigns({
        limit: activeLimit,
        page: 1 + activePage,
        sort: activeSort,
        desc: activeDesc,
        filter,
        filterTags,
      }),
    );
  }, [activeLimit, activePage, activeSort, activeDesc, filterTags, filter]);

  useEffect(() => {
    dispatch(
      campaignActions.fetchSavedCampaigns({
        limit: savedLimit,
        page: 1 + savedPage,
        filter,
        filterTags,
      }),
    );
  }, [savedLimit, savedPage, filterTags, filter]);

  useEffect(() => {
    dispatch(
      campaignActions.fetchSavedCampaigns({
        limit: savedLimit,
        page: 1 + savedPage,
        filter,
        filterTags,
      }),
    );
  }, [savedLimit, savedPage, filterTags]);

  useEffect(() => {
    dispatch(
      campaignActions.fetchPastCampaigns({
        limit: pastLimit,
        page: 1 + pastPage,
        sort: pastSort,
        desc: pastDesc,
        filter,
        filterTags,
      }),
    );
  }, [pastLimit, pastPage, pastSort, pastDesc, filterTags, filter]);

  useEffect(() => {
    dispatch(
      campaignActions.fetchFutureCampaigns({
        limit: futureLimit,
        page: 1 + futurePage,
        sort: futureSort,
        desc: futureDesc,
        filter,
        filterTags,
      }),
    );
  }, [futureLimit, futurePage, futureSort, futureDesc, filterTags, filter]);

  const showActive = () => {
    if (
      activeCampaigns !== null &&
      typeof activeCampaigns !== 'undefined' &&
      activeCampaigns?.data !== null &&
      typeof activeCampaigns?.data !== 'undefined' &&
      activeCampaigns?.data?.length > 0
    ) {
      return true;
    }
    return false;
  };

  const showPast = () => {
    if (
      pastCampaigns !== null &&
      typeof pastCampaigns !== 'undefined' &&
      pastCampaigns.data !== null &&
      typeof pastCampaigns.data !== 'undefined' &&
      pastCampaigns?.data?.length > 0
    ) {
      return true;
    }
    return false;
  };

  const showSaved = () => {
    if (
      savedCampaigns !== null &&
      typeof savedCampaigns !== 'undefined' &&
      savedCampaigns.data !== null &&
      typeof savedCampaigns.data !== 'undefined' &&
      savedCampaigns?.data?.length > 0
    ) {
      return true;
    }
    return false;
  };

  const showFuture = () => {
    if (
      futureCampaigns !== null &&
      typeof futureCampaigns !== 'undefined' &&
      futureCampaigns.data !== null &&
      typeof futureCampaigns.data !== 'undefined' &&
      futureCampaigns?.data?.length > 0
    ) {
      return true;
    }
    return false;
  };

  // const isLoading = () => {
  //   if (
  //     typeof pastCampaigns !== 'undefined' ||
  //     typeof futureCampaigns !== 'undefined' ||
  //     typeof activeCampaigns !== 'undefined' ||
  //     typeof savedCampaigns !== 'undefined'
  //   ) {
  //     return true;
  //   }
  //   return false;
  // };

  const onRefresh = () => {
    setActivePage(0);
    dispatch(
      campaignActions.fetchCurrentCampaigns({
        limit: activeLimit,
        page: 1 + 0,
        sort: activeSort,
        desc: activeDesc,
        filter,
        filterTags,
      }),
    );
  };

  const handleFilterSubmit = (selectedTags = null) => {
    setFilterTags(selectedTags);
  };

  const handleOptionChange = (event) => {
    setSearchType(event.target.value as string);
  };

  function SwitchTabs({
    iconPadding,
    clickValue,
    onClick,
    campaignIcon,
    campaignTitle,
    campaignNumber = 0,
  }) {
    return (
      <Box
        sx={{
          p: 2.3,
          borderRadius: '20px',
          background: '#FFF',
          border: '3px solid #fff',
          borderColor: clickValue && '#007AFF',
          display: 'flex',
          cursor: 'pointer',
          gap: 2,
          [theme.breakpoints.down('lg')]: {
            p: 2,
          },
          [theme.breakpoints.down('md')]: {
            p: 1,
          },
        }}
        onClick={onClick}
      >
        <Box
          sx={{
            width: '89px',
            [theme.breakpoints.down('lg')]: {
              width: '70px',
            },
            [theme.breakpoints.down('md')]: {
              width: '60px',
              mx: 'auto',
            },
          }}
        >
          <Box
            sx={{
              width: '89px',
              height: '89px',
              background: '#D9EBFF',
              borderRadius: '16px',
              p: iconPadding,
              [theme.breakpoints.down('lg')]: {
                width: '70px',
                height: '70px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                p: 0,
              },
              [theme.breakpoints.down('md')]: {
                width: '60px',
                height: '60px',
              },
            }}
          >
            {campaignIcon}
          </Box>
        </Box>
        <Box
          sx={{
            color: '#333',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            [theme.breakpoints.down('lg')]: {
              display: 'flex',
              my: 'auto',
            },
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
          }}
        >
          <Typography
            // noWrap
            sx={{
              fontSize: '16px',
              fontWeight: 100,
              lineHeight: '107.023%',
              mb: 1.3,
              [theme.breakpoints.down('lg')]: {
                display: 'none',
              },
            }}
          >
            {campaignTitle}
          </Typography>
          <Typography
            sx={{
              fontSize: '40px',
              fontWeight: 100,
              lineHeight: '107.023%',
            }}
          >
            {campaignNumber}
          </Typography>
        </Box>
      </Box>
    );
  }

  function EmptyTab({ description }) {
    return (
      <Container maxWidth="sm">
        <Typography
          component="h4"
          variant="h4"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          oopss.
        </Typography>
        <Typography variant="h6" align="center" color="textSecondary" paragraph>
          {`There is nothing in ${description} campaign`}
        </Typography>
      </Container>
    );
  }

  // const handleScroll = (e) => {
  //   const bottom =
  //     e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
  //   if (bottom) {
  //     if (tabSwitch === 0 && activeCampaigns?.data?.length === activeLimit) {
  //       setActiveLimit(activeLimit + 5);
  //     }
  //     if (tabSwitch === 1 && pastCampaigns?.data?.length === pastLimit) {
  //       setPastLimit(pastLimit + 5);
  //     }
  //     if (tabSwitch === 2 && savedCampaigns?.data?.length === savedLimit) {
  //       setSavedLimit(savedLimit + 5);
  //     }
  //     if (tabSwitch === 3 && futureCampaigns?.data?.length === futureLimit) {
  //       setFutureLimit(futureLimit + 5);
  //     }
  //   }
  // };

  const activeCampaignsList = filterList.filter((t: any) =>
    activeCampaigns.data.some((f) => f.id === t.id),
  );

  const pastCampaignsList = filterList.filter((t: any) =>
    pastCampaigns.data.some((f) => f.id === t.id),
  );

  const futureCampaignsList = filterList.filter((t: any) =>
    futureCampaigns.data.some((f) => f.id === t.id),
  );

  const savedCampaignsList = filterList.filter((t: any) =>
    savedCampaigns.data.some((f) => f.id === t.id),
  );

  const checkEmptyPage = () => {
    if (
      futureCampaignsList?.length > 0 ||
      pastCampaignsList?.length > 0 ||
      activeCampaignsList?.length > 0 ||
      savedCampaignsList?.length > 0
    ) {
      return true;
    }
    return false;
  };

  return (
    <Scaffold
      HeaderProps={{
        title: t('campaigns'),
        actions: [
          width < 660 ? (
            <></>
          ) : (
            <PermissionRequired permissions={[Permissions.campaign.create]}>
              <AddCampaignButton
                display="Create"
                onClick={() => navigate(routes.campaigns.build())}
              />
            </PermissionRequired>
          ),
        ],
        children: (
          <Box display="flex" tabIndex={0} ml={8} role="button">
            <MatInput
              variant="outlined"
              onChange={handleSearch}
              onKeyPress={handleSearch}
              placeholder={t('searchCampaigns')}
              sx={{
                backgroundColor: '#fff',
                borderRadius: '10px',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '& .MuiInputBase-root': {
                  minWidth: 450,
                  [theme.breakpoints.down('lg')]: {
                    minWidth: 350,
                  },
                  [theme.breakpoints.down('md')]: {
                    display: 'none',
                  },
                  borderRadius: '10px',
                  pr: 2,
                  pl: 0,
                  py: 0.5,
                },
                input: {
                  '&::placeholder': {
                    textOverflow: 'ellipsis !important',
                    color: 'blue',
                  },
                },
              }}
              value={searchValue}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton size="large">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    {searchValue.length > 0 && (
                      <ClearIcon
                        onClick={clearSearch}
                        sx={{ cursor: 'pointer', color: Colors.black, mr: 2 }}
                      />
                    )}
                    <IconButton
                      onClick={() => setIsFiltersOpen(true)}
                      size="small"
                    >
                      <SettingsIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        ),
      }}
    >
      {isLoading ? (
        <Collapse in={true}>
          <LinearProgress />
        </Collapse>
      ) : (
        <HomeContainer
          // onScroll={handleScroll}
          style={{ overflowY: 'scroll', maxHeight: '100%' }}
        >
          <Box display="flex" tabIndex={0} role="button">
            <MatInput
              variant="outlined"
              onChange={handleSearch}
              onKeyPress={handleSearch}
              placeholder={t('searchCampaigns')}
              sx={{
                backgroundColor: '#fff',
                borderRadius: '10px',
                mb: 4,
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '& .MuiInputBase-root': {
                  minWidth: '100%',
                  [theme.breakpoints.up('md')]: {
                    display: 'none',
                  },
                  pr: 2,
                  pl: 0,
                  py: 1.2,
                },
                input: {
                  '&::placeholder': {
                    textOverflow: 'ellipsis !important',
                    color: 'blue',
                  },
                },
              }}
              value={searchValue}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton size="large">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    {searchValue.length > 0 && (
                      <ClearIcon
                        onClick={clearSearch}
                        sx={{ cursor: 'pointer', color: Colors.black, mr: 2 }}
                      />
                    )}
                    <IconButton
                      onClick={() => setIsFiltersOpen(true)}
                      size="small"
                    >
                      <SettingsIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {/* <Grid container spacing={1}>
            <Grid item xs={12} md={9} lg={10} xl={10}>
              <FormGroup>
                <MatInput
                  variant="outlined"
                  placeholder={t('searchCampaigns')}
                  onChange={handleSearch}
                  onKeyPress={handleSearch}
                  sx={{
                    borderRadius: 1,
                    backgroundColor: Colors.white,
                    [`& fieldset`]: {
                      borderColor: Colors.primary,
                      opacity: '.5',
                    },
                  }}
                  value={searchValue}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        {searchValue.length > 0 ? (
                          <ClearIcon onClick={clearSearch} />
                        ) : (
                          <SearchIcon />
                        )}
                      </InputAdornment>
                    ),
                    startAdornment: (
                      <Select
                        value={searchType}
                        onChange={handleOptionChange}
                        sx={{
                          backgroundColor: Colors.white,
                          color: Colors.black,
                          marginRight: '1rem',
                        }}
                        displayEmpty
                        variant="standard"
                      >
                        <MenuItem value="title">{t('title')}</MenuItem>

                        <MenuItem value="tag">{t('tag')}</MenuItem>
                      </Select>
                    ),
                    inputProps: {
                      style: {},
                    },
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={3} lg={2} xl={2}>
              <Button
                sx={{
                  backgroundColor: Colors.white,
                  color: Colors.black,
                  height: 50,
                  width: '100%',
                  borderRadius: 1,
                  boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
                  '&:hover': {
                    backgroundColor: Colors.lightGray,
                  },
                }}
                variant="contained"
                onClick={() => setIsFiltersOpen(true)}
              >
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <SettingsIcon />
                  <Typography
                    style={{ flexGrow: 1, marginRight: 10 }}
                    variant="subtitle1"
                  >
                    {t('filter')}
                  </Typography>
                </div>
              </Button>
            </Grid>
          </Grid> */}
          <Grid container spacing={3} sx={{ marginTop: 1.5 }}>
            <Grid item xs={6} sm={6} md={3} sx={{ flex: 1 }}>
              <SwitchTabs
                iconPadding="22px"
                clickValue={tabSwitch === 0}
                onClick={() => setTabSwitch(0)}
                campaignIcon={
                  <img
                    src={ActiveIcon}
                    alt="Active Icon"
                    width="45px"
                    height="45px"
                  />
                }
                campaignTitle="Active Campaigns"
                campaignNumber={activeCampaignsList?.length}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} sx={{ flex: 1 }}>
              <SwitchTabs
                iconPadding="26px"
                clickValue={tabSwitch === 1}
                onClick={() => setTabSwitch(1)}
                campaignIcon={
                  <img
                    src={CompletedIcon}
                    alt="Completed Icon"
                    width="37px"
                    height="37px"
                  />
                }
                campaignTitle="Completed Campaigns"
                campaignNumber={pastCampaignsList?.length}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} sx={{ flex: 1 }}>
              <SwitchTabs
                iconPadding="28px"
                clickValue={tabSwitch === 2}
                onClick={() => setTabSwitch(2)}
                campaignIcon={
                  <img
                    src={SavedIcon}
                    alt="Saved Icon"
                    width="33px"
                    height="33px"
                  />
                }
                campaignTitle="Saved Campaigns"
                campaignNumber={savedCampaignsList?.length}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} sx={{ flex: 1 }}>
              <SwitchTabs
                iconPadding="22px"
                clickValue={tabSwitch === 3}
                onClick={() => setTabSwitch(3)}
                campaignIcon={
                  <MdUpcoming className="" color="#007AFF" size="45px" />
                }
                campaignTitle="Scheduled Campaigns"
                campaignNumber={futureCampaignsList?.length}
              />
            </Grid>
          </Grid>
          <Grid container sx={{ marginTop: '64px' }}>
            <Grid item md={12} sx={{ flex: 1 }}>
              {!checkEmptyPage() && !(searchValue.length > 0 || filterTags) && (
                <EmptyPage
                  actions={[
                    <AddCampaignButton
                      display="Create"
                      onClick={() => navigate(routes.campaigns.build())}
                    />,
                  ]}
                  title={t('campaigns')}
                  description={t('emptyCampaignDescription')}
                  permissions={[Permissions.campaign.create]}
                />
              )}

              {tabSwitch === 0 &&
              showActive() &&
              activeCampaignsList?.length > 0 ? (
                <div>
                  <Typography variant="h6">{t('active')}</Typography>
                  <CampaignList
                    campaigns={{
                      data: activeCampaignsList,
                      limit: activeCampaigns.limit,
                      next: activeCampaigns.next,
                      page: activeCampaigns.page,
                      total: activeCampaigns.total,
                      type: activeCampaigns.type,
                    }}
                    loading={activeLoading}
                    sortBy={activeSort}
                    desc={activeDesc}
                    handleTableSettings={handleActiveSettings}
                    reloadCampaigns={onRefresh}
                  />
                </div>
              ) : (
                <>
                  {checkEmptyPage() &&
                    !(searchValue.length > 0 || filterTags) &&
                    tabSwitch === 0 && <EmptyTab description="active" />}
                  {(searchValue.length > 0 || filterTags) &&
                    activeCampaignsList.length === 0 &&
                    tabSwitch === 0 && (
                      <EmptyPage
                        actions={[<></>]}
                        description={t('noCampaignsFromSearch')}
                        title={' '}
                        permissions={[Permissions.campaign.create]}
                      />
                    )}
                </>
              )}

              {tabSwitch === 1 &&
              showPast() &&
              pastCampaignsList?.length > 0 ? (
                <div>
                  <Typography variant="h6">{t('completed')}</Typography>
                  <CampaignList
                    campaigns={{
                      data: pastCampaignsList,
                      limit: pastCampaigns.limit,
                      next: pastCampaigns.next,
                      page: pastCampaigns.page,
                      total: pastCampaigns.total,
                      type: pastCampaigns.type,
                    }}
                    loading={pastLoading}
                    sortBy={pastSort}
                    desc={pastDesc}
                    handleTableSettings={handlePastSettings}
                    showRunAgain={true}
                    reloadCampaigns={onRefresh}
                  />
                </div>
              ) : (
                <>
                  {checkEmptyPage() &&
                    !(searchValue.length > 0 || filterTags) &&
                    tabSwitch === 1 && <EmptyTab description="completed" />}
                  {(searchValue.length > 0 || filterTags) &&
                    pastCampaignsList.length === 0 &&
                    tabSwitch === 1 && (
                      <EmptyPage
                        actions={[<></>]}
                        description={t('noCampaignsFromSearch')}
                        title={' '}
                        permissions={[Permissions.campaign.create]}
                      />
                    )}
                </>
              )}

              {tabSwitch === 2 &&
              showSaved() &&
              savedCampaignsList.length > 0 ? (
                <div>
                  <Typography variant="h6">{t('saved')}</Typography>
                  <SavedList
                    campaigns={{
                      data: savedCampaignsList,
                      limit: savedCampaigns.limit,
                      next: savedCampaigns.next,
                      page: savedCampaigns.page,
                      total: savedCampaigns.total,
                      type: savedCampaigns.type,
                    }}
                    loading={savedLoading}
                    handleTableSettings={handleSavedSettings}
                  />
                </div>
              ) : (
                <>
                  {checkEmptyPage() &&
                    !(searchValue.length > 0 || filterTags) &&
                    tabSwitch === 2 && <EmptyTab description="saved" />}
                  {(searchValue.length > 0 || filterTags) &&
                    savedCampaignsList.length === 0 &&
                    tabSwitch === 2 && (
                      <EmptyPage
                        actions={[<></>]}
                        description={t('noCampaignsFromSearch')}
                        title={' '}
                        permissions={[Permissions.campaign.create]}
                      />
                    )}
                </>
              )}

              {tabSwitch === 3 &&
              showFuture() &&
              futureCampaignsList?.length > 0 ? (
                <div>
                  <Typography variant="h6">Scheduled</Typography>
                  <CampaignList
                    campaigns={{
                      data: futureCampaignsList,
                      limit: futureCampaigns.limit,
                      next: futureCampaigns.next,
                      page: futureCampaigns.page,
                      total: futureCampaigns.total,
                      type: futureCampaigns.type,
                    }}
                    loading={futureLoading}
                    sortBy={futureSort}
                    desc={futureDesc}
                    handleTableSettings={handleFutureSettings}
                    reloadCampaigns={onRefresh}
                  />
                </div>
              ) : (
                <>
                  {checkEmptyPage() &&
                    !(searchValue.length > 0 || filterTags) &&
                    tabSwitch === 3 && <EmptyTab description="scheduled" />}
                  {(searchValue.length > 0 || filterTags) &&
                    futureCampaignsList.length === 0 &&
                    tabSwitch === 3 && (
                      <EmptyPage
                        actions={[<></>]}
                        description={t('noCampaignsFromSearch')}
                        title={' '}
                        permissions={[Permissions.campaign.create]}
                      />
                    )}
                </>
              )}
            </Grid>
          </Grid>
        </HomeContainer>
      )}
      <CampaignFilterDrawer
        open={isFiltersOpen}
        onClose={() => setIsFiltersOpen(false)}
        onFilter={handleFilterSubmit}
        options={businessCampaignTags}
      />
    </Scaffold>
  );
};

export default Campaigns;

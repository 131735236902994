import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';

import DeleteIcon from '@mui/icons-material/Delete';
import { Typography, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { integrationsActions } from 'store/entities/Integrations';

import MatInput from 'components/Elements/MatInput/MatInput';

const MailChimpBuilder = ({ item, onUpdate, cardId, onDelete, tabIndex }) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState(item.title);
  const [error, setError] = useState(false);
  const [audienceLists, setAudienceLists] = useState([]);
  const [selectedList, setSelectedList] = useState(null);

  useEffect(() => {
    dispatch(
      integrationsActions.getMailChimpAudience({
        callBack: (lists) => {
          if (!lists || lists.length < 1) {
            setError(true);
          }
          setAudienceLists(lists);
          setSelectedList(lists[0].id);
          const object = item;
          object.listId = lists[0].id;
          onUpdate(object);
        },
      }),
    );
  }, []);

  const handleTitle = (e) => {
    const { value } = e.target;
    if (value.length < 48) {
      setTitle(value);
      const object = item;
      object.title = value;
      onUpdate(object);
    }
  };

  const handleSelectedList = (event) => {
    setSelectedList(event.target.value);
    const object = item;
    object.listId = event.target.value;
    onUpdate(object);
  };

  const renderItems = () => {
    switch (tabIndex) {
      case 1:
        return (
          <>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                marginBottom: 0.5,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  flexGrow: 1,
                }}
              >
                {`Edit Card ${cardId}`}
              </Typography>
              <IconButton
                aria-label="delete"
                onClick={onDelete}
                size="large"
                sx={{
                  color: Colors.error,
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
            {error ? (
              <div>
                <Typography>
                  Unabled to connect to Mailchimp. Make sure TappShare still has
                  access.
                </Typography>
              </div>
            ) : (
              <div>
                <Typography variant="subtitle2" style={{ marginBottom: 24 }}>
                  Allow new customers to sign up to your Mailchimp mailing list.
                  Simply, select the Audience list and then send the campaign.
                </Typography>
                {audienceLists.length > 0 ? (
                  <div>
                    <Typography variant="h6">
                      Mailchimp Audience List
                    </Typography>
                    <Select
                      id="audience-list"
                      labelId="audience-label"
                      value={selectedList}
                      onChange={handleSelectedList}
                      label="Audience"
                      variant="outlined"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: 'inherit',
                        textTransform: 'inherit',
                        color: Colors.black,
                        '& .MuiSelect-selectMenu': {
                          padding: '17.5px 14px',
                        },
                        '&:focus': {
                          backgroundColor: 'transparent !important',
                        },
                        [`& fieldset`]: {
                          border: ' 1px solid',
                          borderWidth: '1px !important',
                          borderColor: Colors.primary,
                          boxShadow: '0 2px 7px 0 rgba(111,135,183,0.2)',
                          borderRadius: 1,
                        },
                        height: 40,
                        marginTop: 1,
                      }}
                    >
                      {audienceLists.map((val: any) => (
                        <MenuItem value={val.id}>{val.name}</MenuItem>
                      ))}
                    </Select>
                  </div>
                ) : (
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <CircularProgress color="primary" size={30} />
                    <Typography variant="subtitle2" sx={{ marginTop: 1 }}>
                      Fetching Mailchimp Lists
                    </Typography>
                  </Box>
                )}
                <MatInput
                  value={title}
                  variant="outlined"
                  placeholder="Title (Optional)"
                  onChange={handleTitle}
                  size="small"
                  sx={{ mt: 3 }}
                />
              </div>
            )}
          </>
        );
        break;
      case 3:
        return null;
        break;
      case 5:
        return null;
        break;
      case 7:
        return null;
        break;
      default:
        return (
          <>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                marginBottom: 0.5,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  flexGrow: 1,
                }}
              >
                {`Edit Card ${cardId}`}
              </Typography>
              <IconButton
                aria-label="delete"
                onClick={onDelete}
                size="large"
                sx={{
                  color: Colors.error,
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
            {error ? (
              <div>
                <Typography>
                  Unabled to connect to Mailchimp. Make sure TappShare still has
                  access.
                </Typography>
              </div>
            ) : (
              <div>
                <Typography variant="subtitle2" style={{ marginBottom: 24 }}>
                  Allow new customers to sign up to your Mailchimp mailing list.
                  Simply, select the Audience list and then send the campaign.
                </Typography>
                {audienceLists.length > 0 ? (
                  <div>
                    <Typography variant="h6">
                      Mailchimp Audience List
                    </Typography>
                    <Select
                      id="audience-list"
                      labelId="audience-label"
                      value={selectedList}
                      onChange={handleSelectedList}
                      label="Audience"
                      variant="outlined"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: 'inherit',
                        textTransform: 'inherit',
                        color: Colors.black,
                        '& .MuiSelect-selectMenu': {
                          padding: '17.5px 14px',
                        },
                        '&:focus': {
                          backgroundColor: 'transparent !important',
                        },
                        [`& fieldset`]: {
                          border: ' 1px solid',
                          borderWidth: '1px !important',
                          borderColor: Colors.primary,
                          boxShadow: '0 2px 7px 0 rgba(111,135,183,0.2)',
                          borderRadius: 1,
                        },
                        height: 40,
                        marginTop: 1,
                      }}
                    >
                      {audienceLists.map((val: any) => (
                        <MenuItem value={val.id}>{val.name}</MenuItem>
                      ))}
                    </Select>
                  </div>
                ) : (
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <CircularProgress color="primary" size={30} />
                    <Typography variant="subtitle2" sx={{ marginTop: 1 }}>
                      Fetching Mailchimp Lists
                    </Typography>
                  </Box>
                )}
                <MatInput
                  value={title}
                  variant="outlined"
                  placeholder="Title (Optional)"
                  onChange={handleTitle}
                  size="small"
                  sx={{ mt: 3 }}
                />
              </div>
            )}
          </>
        );
        break;
    }
  };

  return (
    <Box>
      {renderItems()}
    </Box>
  );
};

MailChimpBuilder.propTypes = {
  item: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  cardId: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default MailChimpBuilder;

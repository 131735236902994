import React, { useEffect, useState } from 'react';

import Colors from 'constants/colors';

import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import routes from 'routes';
import { userActions } from 'store/domain/user';
import {
  businessActions,
  businessesSelectors,
} from 'store/entities/Businesses';

import ActiveBusinessDrawer from './components/ActiveBusinessDrawer';
import BusinessList from './components/BusinessList/BusinessList';
import ChangePasswordDrawer from './components/ChangePasswordDrawer';
import Edit from './components/edit';

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showEdit, setShowEdit] = useState(false);
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [showActiveBusiness, setShowActiveBusiness] = useState(false);
  const [updateBusiness, setUpdateBusiness] = useState<any>(null);
  const [successMessage, setSuccessMessage] = useState('');

  const { user, status, error } = useSelector((state: any) => state?.user);
  const { fullName, email, phoneNumber, photo_url, emailVerified } = user;
  const businesses = useSelector(businessesSelectors.getActiveBusiness);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    switch (status) {
      case 'rejected':
        enqueueSnackbar(error, { variant: 'error' });
        dispatch(userActions.clearStatus());
        break;
      case 'fulfilled':
        if (successMessage) {
          enqueueSnackbar(successMessage, { variant: 'success' });
        }
        dispatch(userActions.clearStatus());
        setSuccessMessage('');
        break;
      default:
        break;
    }
  }, [status]);

  const handleLogOut = () => {
    dispatch(userActions.logoutUser());
  };

  const handleBusinessClick = (business) => {
    setUpdateBusiness(business);
    setShowActiveBusiness(true);
  };

  const switchActiveBusiness = () => {
    dispatch(
      businessActions.setActiveBusiness({
        businessId: updateBusiness?.id,
      }),
    );
    setShowActiveBusiness(false);
  };

  return (
    <Box p={3}>
      <Typography variant="h5">{t('profile')}</Typography>
      <div>
        <Box width="100%" mt={2.5} display="flex" alignItems="center">
          <Avatar
            sx={{
              width: 75,
              height: 75,
              color: Colors.white,
              backgroundColor: Colors.primary,
              marginRight: 2,
            }}
            src={photo_url || null}
          >
            {!photo_url && <PersonIcon sx={{ width: 45, height: 45 }} />}
          </Avatar>
          <Typography variant="h4">{fullName}</Typography>
        </Box>
        <Box mt={6} mb={4.5}>
          <Typography variant="h6">{t('accountInformation')}</Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <EmailIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <div>
                    {email}
                    {!emailVerified
                      ? '   ( Verify Email )'
                      : '    ( Email verified )'}
                  </div>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <PhoneIcon />
              </ListItemIcon>
              <ListItemText primary={phoneNumber || t('missing')} />
            </ListItem>
          </List>
        </Box>
        <Divider />
        <Box mt={6} mb={6}>
          <Typography variant="h6">{t('connectedBusinesses')}</Typography>
          <Box>
            <BusinessList
              businesses={[businesses]}
              onClick={handleBusinessClick}
            />
          </Box>
        </Box>
        <Divider />
        <Box mt={6} display="flex" width="100%">
          <Button
            onClick={handleLogOut}
            sx={{ marginLeft: 3 }}
            variant="outlined"
            color="primary"
          >
            {t('signOut')}
          </Button>
          <Box flexGrow={1} />
          <Button
            onClick={() => setShowPasswordChange(true)}
            sx={{ marginLeft: 3 }}
            variant="outlined"
            color="primary"
          >
            {t('changePassword')}
          </Button>
          <Button
            onClick={() => setShowEdit(true)}
            sx={{ marginLeft: 3 }}
            variant="outlined"
            color="primary"
          >
            {t('editProfile')}
          </Button>
          <Button
            onClick={() => navigate(routes.deleteAccount())}
            sx={{ marginLeft: 3 }}
            variant="outlined"
            color="error"
          >
            Delete Account
          </Button>
        </Box>
      </div>
      <Edit
        onSuccess={() => setSuccessMessage('Profile Edited')}
        open={showEdit}
        onClose={() => setShowEdit(false)}
      />
      <ChangePasswordDrawer
        open={showPasswordChange}
        onSuccess={() => setSuccessMessage('Password Changed')}
        onClose={() => setShowPasswordChange(false)}
      />
      <ActiveBusinessDrawer
        open={showActiveBusiness}
        onClose={() => setShowActiveBusiness(false)}
        onSubmit={switchActiveBusiness}
      />
    </Box>
  );
};

export default Profile;

import React from 'react'

import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom';
import routes from 'routes';
import { RootState } from 'store'

const AuthRequired: React.FC = ({ children }) => {
  const { user } = useSelector((state: RootState) => state?.user);
  if (user) return <>{children}</>;
  return <Navigate to={routes.auth.signin()} />
}

export default AuthRequired;

import React, { useEffect, useMemo, useState } from 'react';

import Colors from 'constants/colors';
import Permissions from 'constants/permissions';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SearchIcon from '@mui/icons-material/Search';
import WindowIcon from '@mui/icons-material/Window';
import { styled, Button, ButtonProps } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';

import { ReactComponent as SettingsIcon } from 'assets/images/settings_icon.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { teamsActions } from 'store/entities/Teams';

import CreateLocation from 'components/CreateLocation';
import MatInput from 'components/Elements/MatInput/MatInput';
import EmptyPage from 'components/EmptyPage';
import MissingData from 'components/MissingData';
import NoSearchResult from 'components/NoSearchResult';
import PermissionRequired from 'components/PermissionRequired';
import HomeContainer from 'components/ui/HomeContainer';
import Scaffold from 'components/ui/Scaffold';

import CreateTeam from './components/CreateTeam';
import EditLocation from './components/EditLocation';
import FilterDrawer from './components/FilterDrawer';
import LocationsList from './components/LocationsList/LocationsList';
import MembersDrawer from './components/MembersDrawer';

interface AddTeamButtonProps {
  display: string;
  onClick: () => void;
}

const AddTeamButton: React.FC<AddTeamButtonProps> = ({ display, onClick }) => (
  <Button variant="contained" onClick={onClick} startIcon={<AddIcon />}>
    {display}
  </Button>
);

interface AddTeamButtonProps {
  display: string;
  onClick: () => void;
}

const AddButton = styled(Button)<ButtonProps>(() => ({
  color: '#00CA51',
  textTransform: 'capitalize',
  boxShadow: 'none',
  backgroundColor: 'rgba(0, 202, 81, 0.15)',
  '&:hover': {
    backgroundColor: 'rgba(0, 202, 81, 0.15)',
  },
  borderRadius: 14,
  paddingY: 1,
  fontWeight: 600,
}));

const AddFolderButton: React.FC<AddTeamButtonProps> = ({
  display,
  onClick,
}) => (
  <AddButton
    sx={{ fontSize: '15px' }}
    variant="contained"
    onClick={onClick}
    endIcon={<AddIcon fontSize="medium" />}
  >
    {display}
  </AddButton>
);

const theme = createTheme();

const Locations = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [filterState, setFilterState] = useState('active');
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  // const [selectedFilter, setSelectedFilter] = useState(undefined);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [membersDrawerOpen, setMembersDrawerOpen] = useState(false);
  const [teamObj, setTeamObj] = useState({});
  const [locationList, setLocations] = useState<any>([]);
  const [loadingLocations, setloadingLocations] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [switchDisplay, setSwitchDisplay] = useState('grid');

  const { ids, entities, status } = useSelector((state: any) => state.teams);

  useEffect(() => {
    dispatch(teamsActions.fetchTeams({ filter: { status: 'active' } }));
  }, []);

  useEffect(() => {
    const teamsList = ids.map((id) => entities[id]);
    setLocations(teamsList);
  }, [entities]);

  useEffect(() => {
    switch (status) {
      case 'pending':
        setloadingLocations(true);
        break;
      case 'fulfilled':
        setloadingLocations(false);
        break;
      default:
        setloadingLocations(false);
        break;
    }
  }, [status]);

  const handleLocationClick = (location) => {
    setSelectedLocation(location);
    setCreateOpen(true);
    setIsEditing(true);
    // setIsEditOpen(true);
  };

  const handleShowMembers = (locationId) => {
    setTeamObj(locationId);
    setMembersDrawerOpen(true);
  };

  const menuOptions = useMemo(
    () => [
      { name: t('editInfo'), click: handleLocationClick },
      // { name: 'Active Account', click: () => {} }
    ],
    undefined,
  );

  const clearSearch = () => {
    setSearchValue('');
  };

  const locations = ids.map((id) => entities[id]);

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    const search = event.target.value;
    if (search !== null && search !== '') {
      const updatedLocations: any[] = [];
      locations.forEach((location) => {
        if (location.name.toLowerCase().includes(search.toLowerCase())) {
          updatedLocations.push(location);
        }
      });
      setLocations(updatedLocations);
    } else {
      setLocations(locations);
    }
  };

  const handleFilter = (status) => {
    //  dispatch(getLocationList({ status }));
    setFilterState(status);
    dispatch(teamsActions.fetchTeams({ filter: { status } }));
  };

  const getLocationTable = () => (
    <LocationsList
      switchDisplay={switchDisplay}
      locations={locationList}
      menuOptions={menuOptions}
      onClick={handleLocationClick}
      onEdit={handleLocationClick}
      setShowMembers={handleShowMembers}
      loading={loadingLocations}
    />
  );

  return (
    <Scaffold
      HeaderProps={{
        title: t('teams'),
        actions: [
          <Box
            sx={{
              marginRight: 4,
              display: 'flex',
              alignItems: 'center',
              [theme.breakpoints.down('lg')]: {
                display: 'none',
              },
            }}
          >
            <Button
              onClick={() => setSwitchDisplay('grid')}
              sx={{
                borderRadius: '10px 0 0 10px',
                paddingY: 0.8,
                backgroundColor: switchDisplay === 'grid' ? '#B3B3B3' : 'white',
                color: switchDisplay === 'grid' ? 'white' : '#B3B3B3',
                '&:hover': {
                  backgroundColor:
                    switchDisplay === 'grid' ? '#B3B3B3' : 'white',
                  color: switchDisplay === 'grid' ? 'white' : '#B3B3B3',
                },
                '&.MuiButton-root': {
                  minWidth: '40px',
                },
              }}
            >
              <WindowIcon fontSize="small" />
            </Button>
            <Button
              onClick={() => setSwitchDisplay('list')}
              sx={{
                borderRadius: '0 10px 10px 0',
                paddingY: 0.8,
                backgroundColor: switchDisplay === 'list' ? '#B3B3B3' : 'white',
                color: switchDisplay === 'list' ? 'white' : '#B3B3B3',
                '&:hover': {
                  backgroundColor:
                    switchDisplay === 'list' ? '#B3B3B3' : 'white',
                  color: switchDisplay === 'list' ? 'white' : '#B3B3B3',
                },
                '&.MuiButton-root': {
                  minWidth: '40px',
                },
              }}
            >
              <FormatListBulletedIcon fontSize="small" />
            </Button>
          </Box>,
          // locationList && locationList.length > 0 ? (
          <PermissionRequired permissions={[Permissions.team.create]}>
            <AddFolderButton
              display="Create team"
              onClick={() => setCreateOpen(true)}
            />
          </PermissionRequired>,
          // ) : (
          //   <></>
          // ),
        ],
        children: (
          <Box display="flex" tabIndex={0} ml={8} role="button">
            <MatInput
              variant="outlined"
              onChange={handleSearch}
              placeholder="Search"
              onKeyPress={handleSearch}
              sx={{
                backgroundColor: '#fff',
                borderRadius: '10px',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '& .MuiInputBase-root': {
                  minWidth: 450,
                  [theme.breakpoints.down('lg')]: {
                    minWidth: 350,
                  },
                  [theme.breakpoints.down('md')]: {
                    display: 'none',
                  },
                  borderRadius: '10px',
                  pr: 2,
                  pl: 0,
                  py: 0.5,
                },
                input: {
                  '&::placeholder': {
                    textOverflow: 'ellipsis !important',
                    color: 'blue',
                  },
                },
              }}
              value={searchValue}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton size="large">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    {searchValue.length > 0 && (
                      <ClearIcon
                        onClick={clearSearch}
                        sx={{ cursor: 'pointer', color: Colors.black, mr: 2 }}
                      />
                    )}
                    <IconButton
                      onClick={() => setIsFiltersOpen(true)}
                      size="small"
                    >
                      <SettingsIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        ),
      }}
    >
      <HomeContainer>
        <Box display="flex" tabIndex={0} role="button">
          <MatInput
            variant="outlined"
            onChange={handleSearch}
            placeholder="Search"
            onKeyPress={handleSearch}
            sx={{
              backgroundColor: '#fff',
              borderRadius: '10px',
              mb: 4,
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '& .MuiInputBase-root': {
                minWidth: '100%',
                [theme.breakpoints.up('md')]: {
                  display: 'none',
                },
                pr: 2,
                pl: 0,
                py: 1.2,
              },
              input: {
                '&::placeholder': {
                  textOverflow: 'ellipsis !important',
                  color: 'blue',
                },
              },
            }}
            value={searchValue}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton size="large">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start">
                  {searchValue.length > 0 && (
                    <ClearIcon
                      onClick={clearSearch}
                      sx={{ cursor: 'pointer', color: Colors.black, mr: 2 }}
                    />
                  )}
                  <IconButton
                    onClick={() => setIsFiltersOpen(true)}
                    size="small"
                  >
                    <SettingsIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        {locationList && locationList.length > 0 ? (
          <Grid container sx={{ marginTop: 3.5 }}>
            {getLocationTable()}
          </Grid>
        ) : (
          <>
            {searchValue !== '' && locationList.length === 0 && (
              <NoSearchResult message={t('noTeamsFound')} />
            )}
            {searchValue === '' && locationList.length === 0 && (
              <EmptyPage
                actions={
                  filterState === 'inactive'
                    ? []
                    : [
                        <AddTeamButton
                          display={t('addTeam')}
                          onClick={() => setCreateOpen(true)}
                        />,
                      ]
                }
                title={t('teams')}
                description={t(
                  filterState === 'inactive'
                    ? 'inactiveTeamsEmptyDescription'
                    : 'teamsEmptyDescription',
                )}
                permissions={[Permissions.team.create]}
              />
            )}
          </>
        )}
      </HomeContainer>
      {/* <EditLocation
        open={isEditOpen}
        onClose={() => setIsEditOpen(false)}
        location={selectedLocation}
      /> */}
      <MembersDrawer
        open={membersDrawerOpen}
        onClose={() => {
          setMembersDrawerOpen(false);
        }}
        teamObj={teamObj}
      />
      <CreateTeam
        open={createOpen}
        onClose={() => {
          setCreateOpen(false);
          if (isEditing) {
            setIsEditing(false);
          }
        }}
        isEditing={isEditing}
        location={selectedLocation}
      />

      {/* <CreateLocation open={createOpen} onClose={() => setCreateOpen(false)} /> */}
      <FilterDrawer
        open={isFiltersOpen}
        onClose={() => setIsFiltersOpen(false)}
        onFilter={handleFilter}
      />
    </Scaffold>
  );
};

Locations.propTypes = {};

export default Locations;

import { Pointer } from 'parse';

import { DatePointer } from './types';

export function createPointer(className: string, objectId: string): Pointer {
  return {
    __type: 'Pointer',
    className,
    objectId,
  };
}

export function createDatePointer(date: string): DatePointer {
  return {
    __type: 'Date',
    iso: date,
  };
}

import React from 'react';

import Colors from 'constants/colors';

import { makeStyles } from '@mui/styles';

const useStyles: any = makeStyles({
  radio: {
    width: 25,
    height: 25,
    borderRadius: '50%',
    border: `1px solid ${Colors.primary}`,
    position: 'relative',
    backgroundColor: Colors.white,
  },
  circle: {
    width: 11,
    height: 11,
    borderRadius: '50%',
    position: 'absolute',
    left: '6px',
    top: '6px',
    backgroundColor: Colors.primary,
  },
});

const CheckedRadio = () => {
  const classes: any = useStyles();
  return (
    <div className={classes.radio}>
      <div className={classes.circle} />
    </div>
  );
};

export default CheckedRadio;

/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createDraftSafeSelector,
  createEntityAdapter,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import Parse from 'parse';
import { RootState } from 'store';
import { userSelectors } from 'store/domain/user';
import Business from 'store/models/Business';
import { IBusinessMembers } from 'store/models/BusinessMembers';
import Invite from 'store/models/Invite';
import UserTags from 'store/models/UserTags';
import { selectMembersState } from 'store/selectors';
import { RequestStatus } from 'store/utils/types';

const sliceName = 'Members';
const getBusinessMembersId = (businessMembers: any) =>
  businessMembers.team_member_id?.objectId || businessMembers?.id;

export interface inviteMemberPayload {
  email?: string;
  firstName?: string;
  lastName?: string;
  teamId: string;
  mass?: boolean;
}

const inviteMember = createAsyncThunk(
  `${sliceName}/inviteMember`,
  async (
    { email, firstName, lastName, teamId, mass }: inviteMemberPayload,
    { getState }: any,
  ) => {
    const state = getState() as RootState;
    const businessId = userSelectors.getbusinessId(state);

    if (!businessId) {
      throw new Error('No active business.');
    }
    const invite = await Parse.Cloud.run('Business:emailInvite', {
      firstNames: [firstName],
      lastNames: [lastName],
      locationId: teamId,
      businessId,
      role: 'agent',
      emails: [email?.trim()],
      mass,
    });

    return invite.toJSON();
  },
);

export interface updateTeamPayload {
  teamMemberId: string;
  locationId?: string;
  status?: boolean;
  tagsArray?: any;
}

const updateMember = createAsyncThunk(
  `${sliceName}/updateMember`,
  async (
    { teamMemberId, locationId, status, tagsArray }: updateTeamPayload,
    { getState, dispatch },
  ) => {
    try {
      // TO DO : teamMemberId is User ID
      const state = getState() as RootState;
      const businessId = userSelectors.getbusinessId(state);
      const tags: any = tagsArray;
      if (!businessId) {
        throw new Error('No active business.');
      }
      if (!businessId || !teamMemberId || status === null) {
        throw new Error('Missing required parameters');
      }
      const result = await Parse.Cloud.run(
        'Business:updateTeamMemberSettings',
        {
          businessId,
          teamMemberId,
          status,
          locationId,
          tags,
        },
      );
      return result;
    } catch (e) {
      console.error(`Error updating Team Member Settings: ${e}`);
      return null;
    }
  },
);

export interface MemberStatsPayload {
  teamMemberId: string;
}

const getMemberStats = createAsyncThunk(
  `${sliceName}/getMemberStats`,
  async ({ teamMemberId }: MemberStatsPayload, { getState }: any) => {
    try {
      const state = getState() as RootState;
      const businessId = userSelectors.getbusinessId(state);
      if (!businessId) {
        throw new Error('No active business.');
      }
      return await Parse.Cloud.run('Metrics:byTeamMemberYTD', {
        businessId,
        teamMemberId,
      });
    } catch (e) {
      console.error(`Error getting Team Member YTD Stats: ${e}`);
      throw new Error(`Error getting Team Member YTD Stats: ${e}`);
    }
  },
);

const getUsersTags = createAsyncThunk(
  `${sliceName}/getUsersTags`,
  async (props, { getState }: any) => {
    try {
      const state = getState() as RootState;
      const businessId = userSelectors.getbusinessId(state);
      if (!businessId) {
        throw new Error('No active business.');
      }
      const userTags = await Parse.Cloud.run('Business:getUserTags', {
        businessId,
      });
      return userTags;
    } catch (e) {
      console.error(`Error getting Users Tags: ${e}`);
      throw new Error(`Error getting Users Tags: ${e}`);
    }
  },
);

export interface MemberByCampaignPayload {
  campaignId: string;
}

const getMemberByCampaign = createAsyncThunk(
  `${sliceName}/getMemberByCampaign`,
  async ({ campaignId }: MemberByCampaignPayload) => {
    try {
      return await Parse.Cloud.run('Campaigns:getTeamMembersAndMetrics', {
        campaignId,
        type: 'premium',
      });
    } catch (e) {
      console.error(`Error: ${e}`);
      return new Error(`Error: ${e}`);
    }
  },
);

export interface ResendInvitePayload {
  inviteId: string;
  callBack: (response: any) => void;
}

const resendInviteEmail = createAsyncThunk(
  `${sliceName}/resendInviteEmail`,
  async ({ inviteId, callBack }: ResendInvitePayload) => {
    try {
      const result = await Parse.Cloud.run('Business:resendInviteEmail', {
        inviteId,
      });

      let response;

      if (result.error) {
        response = {
          status: 'failure',
          message: 'Failed to resend invite. Please try again.',
        };
      } else {
        response = {
          status: 'success',
          message: 'Invite sent successfully.',
        };
      }

      callBack(response);

      return response;
    } catch (e) {
      console.error(`Error: ${e}`);
      return new Error(`Error: ${e}`);
    }
  },
);

export interface CancelInvitePayload {
  inviteId: string;
  callBack: (removed: any) => void;
}

const cancelInvite = createAsyncThunk(
  `${sliceName}/cancelInvite`,
  async ({ inviteId, callBack }: CancelInvitePayload, { dispatch }) => {
    Parse.Cloud.run('Business:cancelInvite', { inviteId }).then(
      (myObject) => {
        dispatch(fetchMembers({}));
        callBack({
          status: 'success',
          message: 'Invite canceled successfully.',
        });
        return { success: true };
      },
      (e) => {
        callBack({
          status: 'failure',
          message: 'Failed to cancel invite. Please try again.',
        });
        return {
          error: 'Cannot Cancel Invite',
        };
      },
    );
  },
);

export interface MemberByAnnouncementPayload {
  announcementId: string;
}

const getMemberByAnnouncement = createAsyncThunk(
  `${sliceName}/getMemberByAnnouncement`,
  async ({ announcementId }: MemberByAnnouncementPayload) => {
    try {
      return await Parse.Cloud.run('Announcements:getTeamMembers', {
        announcementId,
      });
    } catch (e) {
      console.error(`Error fetching announcement team members: ${e}`);
      return new Error(`Error fetching announcement team members: ${e}`);
    }
  },
);

export interface FetchMembersPayload {
  status?: any;
  locationId?: string;
  tags?: any;
  search?: string;
}

const fetchMembers = createAsyncThunk(
  `${sliceName}/fetchMembers`,
  async (
    { status, locationId, tags, search }: FetchMembersPayload,
    { getState, dispatch }: any,
  ) => {
    try {
      const state = getState() as RootState;
      const businessId = userSelectors.getbusinessId(state);
      if (!businessId) {
        throw new Error('No active business.');
      }
      if (!status) {
        status = [
          {
            name: 'active',
            key: 'active',
            status: true,
          },
          {
            name: 'inactive',
            key: 'inactive',
            status: false,
          },
          {
            name: 'pending',
            key: 'pending',
            status: true,
          },
        ];
      }
      /* .forEach((member: any) => {
        if (member.name.toLowerCase().includes(search.toLowerCase())) {
          updatedTeamMembers.push(member);
        }
      }); */

      const teamMemberList = await Parse.Cloud.run('Business:getTeamMembers', {
        businessId,
        status,
        locationId,
        tags,
      });
      if (search) {
        return teamMemberList.filter((member: any) => {
          const isNameMatched = member.name
            .toLowerCase()
            .includes(search.toLowerCase());
          const isEmailMatched = member.email
            .toLowerCase()
            .includes(search.toLowerCase());
          const isTagMatched =
            member.tags?.length &&
            member.tags.filter((tag) => tag.includes(search.toLowerCase()))
              .length;
          return isNameMatched || isTagMatched || isEmailMatched;
        });
      }
      return teamMemberList;
    } catch (e) {
      console.error(`Error getting team member list: ${e}`);
      throw new Error(`Error getting team member list: ${e}`);
    }
  },
);

const MembersAdapter = createEntityAdapter<IBusinessMembers>({
  selectId: getBusinessMembersId,
});

export interface MembersState extends EntityState<IBusinessMembers> {
  status: RequestStatus;
  error?: string;
  memberStats?: any;
  membersCampaign?: any;
  membersAnnouncement?: any;
  businessUserTags?: any;
}

const initialState: MembersState = {
  ...MembersAdapter.getInitialState(),
  status: 'idle',
};

const MembersSlice = createSlice({
  name: sliceName,
  initialState,
  extraReducers: (builder) => {
    // Fetch
    builder.addCase(fetchMembers.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchMembers.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state = MembersAdapter.setAll(state, action.payload);
      state.error = '';
    });
    builder.addCase(fetchMembers.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // getMemberStats
    builder.addCase(getMemberStats.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getMemberStats.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.memberStats = action.payload;
      state.error = '';
    });
    builder.addCase(getMemberStats.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Fetch Business User Tags
    builder.addCase(getUsersTags.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getUsersTags.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.businessUserTags = action.payload;
      state.error = '';
    });
    builder.addCase(getUsersTags.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // resend invite email
    builder.addCase(resendInviteEmail.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(resendInviteEmail.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.error = '';
    });
    builder.addCase(resendInviteEmail.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // cancel Invite
    builder.addCase(cancelInvite.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(cancelInvite.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.error = '';
    });
    builder.addCase(cancelInvite.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Team Members by Campaign
    builder.addCase(getMemberByCampaign.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getMemberByCampaign.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.membersCampaign = action.payload;
      state.error = '';
    });
    builder.addCase(getMemberByCampaign.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Team Members by Announcement
    builder.addCase(getMemberByAnnouncement.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getMemberByAnnouncement.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.membersAnnouncement = action.payload;
      state.error = '';
    });
    builder.addCase(getMemberByAnnouncement.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Update
    builder.addCase(updateMember.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateMember.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.error = '';
    });
    builder.addCase(updateMember.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
  },
  reducers: {
    setOne (state, action) {
      MembersAdapter.setOne(state, action.payload);
    },
    setMany (state, action) {
      MembersAdapter.setMany(state, action.payload);
    },
    removeOne: MembersAdapter.removeOne,
    removeMany: MembersAdapter.removeMany,
  },
});

const getMembers = createDraftSafeSelector(selectMembersState, (members) =>
  members.ids.map((id) => members.entities[id]),
);

const getActiveMembers = createDraftSafeSelector(
  selectMembersState,
  (members) =>
    members.ids
      .map((id) => members.entities[id])
      .filter((member) => member.status === 'active'),
);

export const membersSelectors = {
  getMembers,
  getActiveMembers,
};

export const MembersActions = {
  fetchMembers,
  inviteMember,
  updateMember,
  getMemberStats,
  getMemberByAnnouncement,
  getMemberByCampaign,
  getUsersTags,
  resendInviteEmail,
  cancelInvite,
  ...MembersSlice.actions,
};
export default MembersSlice.reducer;

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const HomeContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}))

export default HomeContainer;

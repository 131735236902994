import React, { useState, useEffect } from 'react';

import { Box } from '@mui/material';

import Moment from 'moment';
import PropTypes from 'prop-types';

import {
  createInitialCalendar,
  isSameDate,
  isDateInRange,
  isPreviousMonthAllowed,
  createPreviousMonthCalendar,
  createNextMonthCalendar,
} from '../helpers';

import Day from './Day';
import Header from './Header';

const Calendar = ({ range, onDateSelect, allowPastDates }) => {
  const [displayMonth, setDisplayMonth] = useState(Moment());
  const [weeks, setWeeks] = useState<any[]>([]);

  useEffect(() => {
    const calendar = createInitialCalendar();
    if (!calendar) {
      return;
    }
    setWeeks(calendar.weeks);
    setDisplayMonth(calendar.firstOfMonth);
  }, []);

  const handlePreviousMonth = () => {
    const calendar = createPreviousMonthCalendar(displayMonth);
    if (!calendar) {
      return;
    }
    setWeeks(calendar.weeks);
    setDisplayMonth(calendar.firstOfMonth);
  };

  const hanldeNextMonth = () => {
    const calendar = createNextMonthCalendar(displayMonth);
    if (!calendar) {
      return;
    }
    setWeeks(calendar.weeks);
    setDisplayMonth(calendar.firstOfMonth);
  };

  const checkPreviousMonth = () => {
    if (allowPastDates) {
      return true;
    }
    return isPreviousMonthAllowed(displayMonth);
  };

  return (
    <Box>
      <Header
        month={displayMonth.format('MMMM')}
        year={displayMonth.format('YYYY')}
        onBack={checkPreviousMonth() ? handlePreviousMonth : null}
        onNext={hanldeNextMonth}
      />
      {weeks.map((week, index) => (
        <Box display="flex" key={`week-${index.toString()}`}>
          {week.map((day) => (
            <Day
              key={day.date.format('D')}
              day={day.date.format('D')}
              disabled={allowPastDates ? false : day.disabled}
              firstSelected={isSameDate(day.date, range[0])}
              lastSelected={isSameDate(day.date, range[1])}
              selected={isDateInRange(day.date, range[0], range[1])}
              onClick={() => onDateSelect(day.date)}
            />
          ))}
        </Box>
      ))}
    </Box>
  );
};

Calendar.propTypes = {
  range: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDateSelect: PropTypes.func.isRequired,
  allowPastDates: PropTypes.bool,
};

Calendar.defaultProps = {
  allowPastDates: false,
};

export default Calendar;

import React from 'react'

export interface INavigationContext {
  state: 'open' | 'close';
  open: () => void;
  close: () => void;
}

const NavigationContext = React.createContext<INavigationContext|null>(null)

export default NavigationContext;

import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { userActions } from 'store/domain/user';
import * as Yup from 'yup';

import MatDrawer from 'components/Elements/MatDrawer/MatDrawer';
import MatFormLabel from 'components/Elements/MatFormLabel/FormLabel';
import MatInput from 'components/Elements/MatInput/MatInput';

const validationSchema = Yup.object().shape({
  newPassword: Yup.string().required('Password is required'),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('newPassword'), null],
    'Passwords must match',
  ),
});

const ChangePasswordDrawer = ({ open, onClose, onSuccess }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      originalPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: ({ originalPassword, newPassword }) => {
      dispatch(
        userActions.changePassword({
          oldPassword: originalPassword,
          newPassword,
        }),
      );
      dispatch(userActions.clearStatus());
      formik.resetForm();
      onClose();
      onSuccess();
    },
  });

  return (
    <MatDrawer
      open={open}
      anchor="right"
      onClose={onClose}
      onCancel={onClose}
      onSubmit={() => formik.handleSubmit()}
      title={t('changePassword')}
      submitBtn={t('update')}
      cancelBtn={t('cancel')}
      isLoading={false}
    >
      <Box maxWidth={450}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MatFormLabel>{t('currentPassword')}</MatFormLabel>
            <MatInput
              variant="outlined"
              placeholder=""
              name="originalPassword"
              value={formik.values.originalPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.originalPassword &&
                Boolean(formik.errors.originalPassword)
              }
              helperText={
                formik.touched.originalPassword &&
                formik.errors.originalPassword
              }
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <MatFormLabel>{t('newPassword')}</MatFormLabel>
            <MatInput
              variant="outlined"
              placeholder=""
              name="newPassword"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.newPassword && Boolean(formik.errors.newPassword)
              }
              helperText={
                formik.touched.newPassword && formik.errors.newPassword
              }
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <MatFormLabel>{t('confirmPassword')}</MatFormLabel>
            <MatInput
              variant="outlined"
              placeholder=""
              name="confirmPassword"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              size="small"
            />
          </Grid>
        </Grid>
      </Box>
    </MatDrawer>
  );
};

ChangePasswordDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default ChangePasswordDrawer;

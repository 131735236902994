/* eslint-disable no-console */
/* eslint-disable comma-spacing */
/* eslint-disable padded-blocks */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-undef */
/* eslint-disable no-trailing-spaces */
import React, { useEffect, useState } from 'react';

import Colors from 'constants/colors';

import { Box, SxProps } from '@mui/material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import Iphone from 'assets/images/iphone-with-notch.png';
import Logo from 'assets/images/logo_blue.png';
import Menu from 'assets/images/menu.svg';
import PropTypes from 'prop-types';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import EmulatorDrawer from 'components/Emulators/components/EmulatorDrawer';

import CardDisplay from './CardDisplay';
import Empty from './Empty';
import { createTheme } from '@mui/material/styles';
// Typescript module augmentation
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxs: true;
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
    xxxl: true;
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xxs: 0,
      xs: 300,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1500,
      xxl: 1800,
      xxxl: 2000,
    },
  },
});

const DragNDropEmulator = ({
  data,
  businessPhoto,
  businessName,
  activeRow,
  setActiveRow,
  editing,
  conditions,
  setValue,
  handleUpdateItem,
  showBusiness,
  setActiveImage,
}) => {
  const [showDrawer, setShowDrawer] = useState(false);

  const checkType = (val) => {
    if (
      val === 'info' ||
      val === 'image' ||
      val === 'video' ||
      val === 'button' ||
      val === 'social' ||
      val === 'form' ||
      val === 'poll' ||
      val === 'carousel'
    ) {
      return true;
    }
    return false;
  };

  const getDragStyle = (dragging, draggingWith): SxProps => {
    if (dragging) {
      if (editing) {
        return {
          borderColor: Colors.error,
          backgroundColor: Colors.lightRed,
          borderStyle: 'solid',
          borderWidth: 4,
          borderRadius: 2.5,
          width: '100%',
          height: '100%',
          paddingTop: 2,
        };
      } else if (data?.length > 4 && checkType(draggingWith)) {
        return {
          borderColor: Colors.error,
          backgroundColor: Colors.lightRed,
          borderStyle: 'solid',
          borderWidth: 4,
          borderRadius: 2.5,
          width: '100%',
          height: '100%',
          paddingTop: 2,
        };
      }
      return {
        borderColor: Colors.success,
        backgroundColor: Colors.lightGreen,
        borderStyle: 'solid',
        borderWidth: 4,
        borderRadius: 2.5,
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        paddingTop: 2,
      };
    }
    return {
      width: '100%',
      height: '100%',
      paddingTop: 2,
      overflowY: 'auto',
    };
  };

  return (
    <Box
      sx={{
        width: 350,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        mx: 3,
        [theme.breakpoints.up('md')]: {
          width: '40%',
        },
        [theme.breakpoints.up('xxl')]: {
          width: '35%',
        },
      }}
    >
      <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Box style={{ display: 'inline-block' }}>
          <Box
            sx={{
              backgroundImage: `url(${Iphone})`,
              backgroundSize: '210px 400px',
              height: 400,
              width: 210,
              paddingTop: 1.9,
              paddingLeft: 1.8,
              paddingRight: 1.8,
              paddingBottom: 1.6,
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              [theme.breakpoints.up('xl')]: {
                backgroundSize: '280px 520px',
                height: 520,
                width: 280,
                paddingTop: 2.2,
                paddingLeft: 2.3,
                paddingRight: 2.3,
                paddingBottom: 2,
              },
              [theme.breakpoints.up('xxl')]: {
                backgroundSize: '320px 580px',
                height: 580,
                width: 320,
                paddingTop: 2.5,
              },
              [theme.breakpoints.up('xxxl')]: {
                backgroundSize: '370px 680px',
                height: 680,
                width: 370,
                paddingTop: 3.0,
                paddingLeft: 2.8,
                paddingRight: 2.8,
                paddingBottom: 2.8,
              },
            }}
          >
            <Paper
              sx={{
                width: '100%',
                height: 50,
                backgroundColor: Colors.white,
                borderRadius: '20px 20px 0 0',
                display: 'flex',
                textAlign: 'left',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              elevation={2}
            >
              <Box display="flex" sx={{ marginLeft: 1 }}>
                {businessPhoto && (
                  <img
                    alt="Tappshare Logo"
                    src={businessPhoto}
                    style={{
                      height: 25,
                    }}
                  />
                )}
              </Box>
              {showBusiness && (
                <Box>
                  <Typography variant="subtitle2">{businessName}</Typography>
                </Box>
              )}
              <Box sx={{ paddingTop: 1.25, marginRight: 1 }}>
                <input
                  type="image"
                  alt="Menu"
                  src={Menu}
                  onClick={() => setShowDrawer(true)}
                />
              </Box>
            </Paper>
            <Box
              sx={{
                flexGrow: 1,
                backgroundColor: Colors.background,
                borderRadius: '0 0 20px 20px',
                overflowX: 'hidden',
                overflowY: 'hidden',
                width: '100%',
              }}
            >
              <Droppable droppableId="emulator">
                {(provided, snapshot) => (
                  <Box
                    className="folderCard"
                    ref={provided.innerRef}
                    sx={getDragStyle(
                      snapshot.isDraggingOver,
                      snapshot.draggingOverWith,
                    )}
                  >
                    {data?.length > 0 ? (
                      data.map((array, index) => (
                        <Draggable
                          key={array.item.id}
                          draggableId={array.item.id}
                          index={index}
                        >
                          {(prov) => (
                            <div
                              ref={prov.innerRef}
                              {...prov.draggableProps}
                              {...prov.dragHandleProps}
                            >
                              <CardDisplay
                                item={array.item}
                                setValue={setValue}
                                cardId={`Card ${index + 1}`}
                                index={index}
                                activeRow={activeRow}
                                handleActiveRow={(val) => setActiveRow(val)}
                                onUpdate={(item) => {
                                  handleUpdateItem(item);
                                }}
                                setActiveImage={setActiveImage}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))
                    ) : (
                      <Empty />
                    )}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </Box>
            <EmulatorDrawer
              open={showDrawer}
              onClose={() => setShowDrawer(false)}
              conditions={conditions}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

DragNDropEmulator.propTypes = {
  data: PropTypes.array.isRequired,
  businessPhoto: PropTypes.string.isRequired,
  businessName: PropTypes.string.isRequired,
  editing: PropTypes.bool.isRequired,
  showBusiness: PropTypes.bool.isRequired,
  activeRow: PropTypes.number.isRequired,
  setActiveRow: PropTypes.func.isRequired,
  setValue: PropTypes.func,
  setActiveImage: PropTypes.func,
  handleUpdateItem: PropTypes.func,
  conditions: PropTypes.string,
};

DragNDropEmulator.defaultProps = {
  conditions: null,
};

export default DragNDropEmulator;

import Parse, { Object, JSONBaseAttributes, Pointer } from 'parse'
import { createPointer } from 'store/utils/pointers';

export interface LocationAttributes{
  business_id: Pointer;
  is_active: boolean;
  name: string;
  phone: string;
  url: string;
  logo_url: string;
}

export type ILocation = Object.ToJSON<LocationAttributes> & JSONBaseAttributes;

class Location extends Parse.Object<LocationAttributes> {
  static className = 'location'

  constructor(init: LocationAttributes) {
    super(Location.className, init);
  }

  static getPointer(objectId: string): Pointer {
    return createPointer(Location.className, objectId)
  }
}

export default Location;

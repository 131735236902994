import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';

import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

import MatInput from 'components/Elements/MatInput/MatInput';

const VideoBuilder = ({ item, onUpdate, cardId, onDelete, tabIndex }) => {
  const [service, setService] = useState('youtube');
  const [title, setTitle] = useState('');
  const [youtubeLink, setYoutubeLink] = useState('');
  const [youtubeError, setYoutubeError] = useState(false);
  const [vimeoLink, setVimeoLink] = useState('');
  const [vimeoError, setVimeoError] = useState(false);

  useEffect(() => {
    if (item) {
      setTitle(item.title);
      if (item.type === 'youtube') {
        setService('youtube');
        setYoutubeLink(`https://youtu.be/${item.code}`);
      } else if (item.type === 'vimeo') {
        setService('vimeo');
        setYoutubeLink(`https://vimeo.com/${item.code}`);
      }
    }
  }, [item]);

  const handleYoutubeLink = (e) => {
    const { value } = e.target;
    let code = '';
    if (value === '') {
      setYoutubeError(false);
    } else if (value.length > 15) {
      if (value.substring(0, 16) === 'https://youtu.be') {
        if (value.length > 27) {
          setYoutubeError(false);
          code = value.substring(17, value.length);
          const object = item;
          object.type = 'youtube';
          object.code = code;
          onUpdate(object);
        } else {
          setYoutubeError(false);
        }
      } else if (
        value.length > 32 &&
        value.substring(0, 32) === 'https://www.youtube.com/watch?v='
      ) {
        if (value.length > 41) {
          setYoutubeError(false);
          code = value.substring(32, value.length);
          const object = item;
          object.type = 'youtube';
          object.code = code;
          onUpdate(object);
        } else {
          setYoutubeError(true);
        }
      } else {
        setYoutubeError(true);
      }
    } else {
      setYoutubeError(true);
    }

    setYoutubeLink(value);
  };

  const handleVimeoLink = (e) => {
    // https://vimeo.com/105946748
    const { value } = e.target;
    if (value === '') {
      setVimeoError(false);
    } else if (
      value.length > 17 &&
      value.substring(0, 17) === 'https://vimeo.com'
    ) {
      if (value.length > 25) {
        const code = value.substring(18, value.length);
        const object = item;
        object.type = 'vimeo';
        object.code = code;
        onUpdate(object);
        setVimeoError(false);
      } else {
        setVimeoError(true);
      }
    } else {
      setVimeoError(true);
    }

    setVimeoLink(e.target.value);
  };

  const handleTitle = (e) => {
    const { value } = e.target;
    setTitle(value);
    const object = item;
    object.title = value;
    onUpdate(object);
  };

  const renderItems = () => {
    switch (tabIndex) {
      case 1:
        return (
          <>
            <ButtonGroup
              color="primary"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 2,
              }}
            >
              <Button
                onClick={() => setService('youtube')}
                variant={service === 'youtube' ? 'contained' : 'outlined'}
                sx={{
                  px: 3,
                  borderTopLeftRadius: 6,
                  borderBottomLeftRadius: 6,
                }}
              >
                YouTube
              </Button>
              <Button
                onClick={() => setService('vimeo')}
                variant={service === 'vimeo' ? 'contained' : 'outlined'}
                sx={{
                  px: 3,
                  borderTopRightRadius: 6,
                  borderBottomRightRadius: 6,
                }}
              >
                Vimeo
              </Button>
            </ButtonGroup>
            <MatInput
              value={title}
              variant="outlined"
              placeholder="Title"
              onChange={handleTitle}
              size="small"
              sx={{
                marginTop: 2,
                marginBottom: 2,
              }}
            />
            {service === 'youtube' ? (
              <MatInput
                value={youtubeLink}
                variant="outlined"
                placeholder="YouTube Link"
                onChange={handleYoutubeLink}
                size="small"
                helperText="https://www.youtube.com/watch?v=12345"
                error={youtubeError}
              />
            ) : (
              <MatInput
                value={vimeoLink}
                variant="outlined"
                placeholder="Vimeo Link"
                onChange={handleVimeoLink}
                size="small"
                helperText="https://vimeo.com/12345"
                error={vimeoError}
              />
            )}
          </>
        );
        break;
      case 3:
        return null;
        break;
      case 5:
        return null;
        break;
      case 7:
        return null;
        break;
      default:
        return (
          <>
            <ButtonGroup
              color="primary"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 2,
              }}
            >
              <Button
                onClick={() => setService('youtube')}
                variant={service === 'youtube' ? 'contained' : 'outlined'}
                sx={{
                  px: 3,
                  borderTopLeftRadius: 6,
                  borderBottomLeftRadius: 6,
                }}
              >
                YouTube
              </Button>
              <Button
                onClick={() => setService('vimeo')}
                variant={service === 'vimeo' ? 'contained' : 'outlined'}
                sx={{
                  px: 3,
                  borderTopRightRadius: 6,
                  borderBottomRightRadius: 6,
                }}
              >
                Vimeo
              </Button>
            </ButtonGroup>
            <MatInput
              value={title}
              variant="outlined"
              placeholder="Title"
              onChange={handleTitle}
              size="small"
              sx={{
                marginTop: 2,
                marginBottom: 2,
              }}
            />
            {service === 'youtube' ? (
              <MatInput
                value={youtubeLink}
                variant="outlined"
                placeholder="YouTube Link"
                onChange={handleYoutubeLink}
                size="small"
                helperText="https://www.youtube.com/watch?v=12345"
                error={youtubeError}
              />
            ) : (
              <MatInput
                value={vimeoLink}
                variant="outlined"
                placeholder="Vimeo Link"
                onChange={handleVimeoLink}
                size="small"
                helperText="https://vimeo.com/12345"
                error={vimeoError}
              />
            )}
          </>
        );
        break;
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        textAlign: 'left',
        backgroundColor: Colors.white,
        borderRadius: 2,
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: 1,
        paddingBottom: 2,
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          marginBottom: 1,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            flexGrow: 1,
          }}
        >
          {`Edit Card ${cardId}`}
        </Typography>
        <IconButton
          aria-label="delete"
          onClick={onDelete}
          size="large"
          sx={{
            color: Colors.error,
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
      {renderItems()}
    </Box>
  );
};

VideoBuilder.propTypes = {
  tabIndex: PropTypes.number,
  item: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  cardId: PropTypes.string.isRequired,
};

export default VideoBuilder;

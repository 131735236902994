export const Filter = (data) => {
  try {
    let results = JSON.stringify(data.ops);
    results = results.replace(/"bold":true/g, '"b":true');
    results = results.replace(/"italic":true/g, '"i":true');
    results = results.replace(/"bullet":true/g, '"block":"ul"');
    results = results.replace(/"list":true/g, '"block":"ol"');
    results = results.replace(/"list":"bullet"/g, '"block":"ul"');
    results = results.replace(/"list":"ordered"/g, '"block":"ol"');
    results = results.replace(/"link"/g, '"a"');
    return results;
  } catch (e) {
    console.error(`Error Filtering WYSIWYG: ${e}`);
    return null;
  }
};

export const Unfilter = (data) => {
  try {
    let results = data;
    results = results.replace(/"b":true/g, '"bold":true');
    results = results.replace(/"i":true/g, '"italic":true');
    // results = results.replace(/"block":"ul"/g, '"bullet":"true"',);
    // results = results.replace(/"block":"ol"/g, '"list":true',);
    results = results.replace(/"block":"ul"/g, '"list":"bullet"');
    results = results.replace(/"block":"ol"/g, '"list":"ordered"');
    results = results.replace(/"a"/g, '"link"');
    results = JSON.parse(results);
    return { ops: results };
  } catch (e) {
    console.error(`Error Unfiltering WYSIWYG: ${e}`);
    return null;
  }
};

export const getPlainText = (data) => {
  try {
    let filteredDescription = '';
    for (let x = 0; x < data.length; x++) {
      if (data[x].insert) {
        let text = data[x].insert;
        text = text.replace(/[\r\n]+/gm, '');
        // eslint-disable-next-line
        filteredDescription = filteredDescription + text + ' ';
      }
    }
    return filteredDescription;
  } catch (e) {
    console.error(`Error filtering out wysiwyg: ${e}`);
    return null;
  }
};

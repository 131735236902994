import React from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

const FormCard = ({ data }) => (
  <Box width="100%" pl={2} pr={2}>
    <Card sx={{ padding: 2 }}>
      <Box display="flex" alignItems="center">
        <Typography variant="subtitle1" sx={{ marginLeft: 8, flexGrow: 1 }}>
          {data.title}
        </Typography>
      </Box>
      {data.fields.length > 0
        ? data.fields.map((item, index) => {
            if (item === '') {
              return <div />;
            }
            return (
              <TextField
                id={index}
                label={item}
                sx={{
                  marginTop: 10,
                  backgroundColor: Colors.white,
                  flexGrow: 1,
                  [`& fieldset`]: {
                    borderRadius: 2.5,
                  },
                  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                    borderColor: Colors.primary,
                  },
                }}
                variant="outlined"
                size="small"
                fullWidth
                disabled
              />
            );
          })
        : null}
      <Button
        color="primary"
        variant="contained"
        sx={{
          borderRadius: 8,
          height: 30,
          marginTop: 20,
          paddingLeft: 20,
          paddingRight: 20,
        }}
        disableElevation
      >
        <Typography sx={{ fontSize: 10, color: Colors.white }}>
          Submit
        </Typography>
      </Button>
    </Card>
  </Box>
);

FormCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FormCard;

import { styled } from '@mui/system';

export const HeaderComponent = styled('h2')({
  fontSize: 24,
  fontWeight: 'bold',
  margin: '16px 0px',
});

export const TitleComponent = styled('h1')({
  fontSize: 40,
  fontWeight: 'bold',
  margin: '27px 0px',
});
export const SubtitleComponent = styled('h2')({
  fontSize: 32,
  fontWeight: 'bold',
  margin: '26.5px 0px',
});

export const ContentComponent = styled('div')({
  fontSize: 16,
  margin: '16px 0px',
});

export const ListComponent = styled('li')({
  fontSize: 16,
  margin: '16px 0px',
});

export const SublistComponent = styled('li')({
  fontSize: 16,
  padding: '5px 0px',
});

/* eslint-disable no-underscore-dangle */
/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';

import { Button, Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';

import { ReactComponent as AlertIcon } from 'assets/images/alert_icon.svg';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { teamsActions } from 'store/entities/Teams';
import * as Yup from 'yup';

import CustomModalV2 from 'components/Elements/CustomModalV2/CustomModalV2';
import MatFormLabel from 'components/Elements/MatFormLabel/FormLabel';
import MatInput from 'components/Elements/MatInput/MatInput';
import MatSwitch from 'components/Elements/MatSwitch/MatSwtich';
import { UploadImageV2 } from 'components/ImageSelectors';

// import FolderModal from './FolderModal';
// import { SVG_images } from './folder_svg/SvgData';

const TeamSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'This name is too short.')
    .max(50, 'This name is too long.')
    .required('Required'),
  phoneNumber: Yup.string().matches(
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    'Phone number is not valid',
  ),
  website: Yup.string().matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    'Enter correct website!',
  ),
  active: Yup.boolean().default(true),
});

const CreateTeam = ({ open, onClose, isEditing, location }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [active, setActive] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [website, setWebsite] = useState('');
  const [image, setImage] = useState({
    type: '',
    upload: false,
    url: '',
  });

  useEffect(() => {
    setLoadingUpdate(true);
    if (location) {
      setName(location.name);
      setActive(location.is_active);
      setPhoneNumber(location.phone);
      setWebsite(location.url);
      if (location.logo_url) {
        setImage({
          type: 'custom',
          upload: false,
          url: location.logo_url,
        } as any);
      }
    }
    setLoadingUpdate(false);
  }, [location]);

  const handleImage = (val) => {
    setImage(val);
  };

  const onSubmit = () => {
    if (name && name !== '') {
      setLoadingUpdate(true);
      dispatch(
        teamsActions.updateTeam({
          teamId: location.objectId,
          name,
          phoneNumber,
          website,
          imageUrl: image.url,
          status: active,
        }),
      );
      setActive(true);
      onClose();
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      phoneNumber: '',
      website: '',
      active: true,
      imageUrl: '',
    },
    validationSchema: TeamSchema,
    onSubmit: ({ name, phoneNumber, website }, { resetForm }) => {
      dispatch(
        teamsActions.createTeam({
          name,
          phoneNumber,
          website,
          isActive: active,
          imageUrl: image.url,
        }),
      );
      setImage({
        type: '',
        upload: false,
        url: '',
      });
      setActive(true);
      resetForm(); // Reset form values
      onClose();
    },
  });

  return (
    <CustomModalV2
      open={open}
      onClose={onClose}
      // onSubmit={() => (isEditing ? onSubmit() : formik.handleSubmit())}
      isLoading={loadingUpdate}
    >
      <Box width={1} maxWidth={320}>
        <Grid container pt={2} spacing={1}>
          <Grid item md={12} xs={12} sx={{ mb: 2 }}>
            {loadingUpdate ? (
              <CircularProgress
                style={{ width: 40, height: 40, color: Colors.primary }}
              />
            ) : (
              <UploadImageV2
                currentImage={isEditing ? image?.url : null}
                onChange={handleImage}
                open={open}
              />
            )}
          </Grid>
          <Grid item md={12} xs={12} sx={{ mb: 1 }}>
            <MatFormLabel
              sx={{
                mb: 0.5,
                display: 'flex',
                alignItems: 'center',
              }}
              icon={
                <Tooltip title={`${t('giveTeamDistinctName')}`}>
                  <AlertIcon />
                </Tooltip>
              }
            >
              <Typography
                sx={{
                  fontWeight: 600,
                }}
              >
                {t('teamName')}
              </Typography>
            </MatFormLabel>
            <MatInput
              variant="outlined"
              placeholder=""
              size="small"
              name="name"
              value={isEditing ? name : formik.values.name}
              onChange={
                isEditing ? (e) => setName(e.target.value) : formik.handleChange
              }
              error={
                isEditing
                  ? name === ''
                  : formik.touched.name && Boolean(formik.errors.name)
              }
              helperText={
                isEditing
                  ? name === ''
                  : formik.touched.name && formik.errors.name
              }
              sx={{
                '& .MuiOutlinedInput-root': {
                  maxHeight: 53,
                  paddingY: '4px',
                  background: '#EEF0F2',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  display: 'inline-flex',
                },
                [`& fieldset`]: {
                  width: '100%',
                  border: 'none',
                },
              }}
            />
          </Grid>
          <Grid item md={12} xs={12} sx={{ mb: 1 }}>
            <MatFormLabel
              sx={{
                mb: 0.5,
                display: 'flex',
                alignItems: 'center',
              }}
              icon={
                <Tooltip title={`${t('phoneNumberReachTeam')}`}>
                  <AlertIcon />
                </Tooltip>
              }
            >
              <Typography
                sx={{
                  fontWeight: 600,
                }}
              >
                Phone Number
              </Typography>
            </MatFormLabel>
            <MatInput
              variant="outlined"
              placeholder=""
              name="phoneNumber"
              size="small"
              value={isEditing ? phoneNumber : formik.values.phoneNumber}
              error={
                isEditing
                  ? phoneNumber === ''
                  : formik.touched.phoneNumber &&
                    Boolean(formik.errors.phoneNumber)
              }
              helperText={
                isEditing
                  ? phoneNumber === ''
                  : formik.touched.phoneNumber && formik.errors.phoneNumber
              }
              onChange={
                isEditing
                  ? (e) => setPhoneNumber(e.target.value)
                  : formik.handleChange
              }
              sx={{
                '& .MuiOutlinedInput-root': {
                  maxHeight: 53,
                  paddingY: '4px',
                  background: '#EEF0F2',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  display: 'inline-flex',
                },
                [`& fieldset`]: {
                  width: '100%',
                  border: 'none',
                },
              }}
            />
          </Grid>
          <Grid item md={12} xs={12} sx={{ mb: 1 }}>
            <MatFormLabel
              sx={{
                mb: 0.5,
                display: 'flex',
                alignItems: 'center',
              }}
              icon={
                <Tooltip title={`${t('websiteSpecificTeam')}`}>
                  <AlertIcon />
                </Tooltip>
              }
            >
              <Typography
                sx={{
                  fontWeight: 600,
                }}
              >
                Website URL
              </Typography>
            </MatFormLabel>
            <MatInput
              variant="outlined"
              placeholder=""
              size="small"
              name="website"
              value={isEditing ? website : formik.values.website}
              onChange={
                isEditing
                  ? (e) => setWebsite(e.target.value)
                  : formik.handleChange
              }
              error={
                isEditing
                  ? website === ''
                  : formik.touched.website && Boolean(formik.errors.website)
              }
              helperText={
                isEditing
                  ? website === ''
                  : formik.touched.website && formik.errors.website
              }
              sx={{
                '& .MuiOutlinedInput-root': {
                  maxHeight: 53,
                  paddingY: '4px',
                  background: '#EEF0F2',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  display: 'inline-flex',
                },
                [`& fieldset`]: {
                  width: '100%',
                  border: 'none',
                },
              }}
            />
          </Grid>
          <Grid item md={12} xs={12} sx={{ marginBottom: 1 }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MatSwitch
                labelActive="active"
                labelInactive="inactiveee"
                defaultChecked={!!active}
                onChange={() => {
                  setActive(!active);
                }}
              />
              <Box display="flex" alignItems="center" gap={2}>
                {loadingUpdate ? (
                  <CircularProgress
                    style={{ width: 40, height: 40, color: Colors.primary }}
                  />
                ) : (
                  <>
                    <Button
                      disableElevation={true}
                      color="primary"
                      onClick={onClose}
                      sx={{
                        color: Colors.black,
                        textTransform: 'capitalize',
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disableElevation={true}
                      color="primary"
                      // eslint-disable-next-line no-confusing-arrow
                      onClick={() =>
                        isEditing ? onSubmit() : formik.handleSubmit()
                      }
                      sx={{
                        textTransform: 'capitalize',
                        fontWeight: 600,
                        background: '#D9EBFF',
                        borderRadius: '8px',
                        px: '18px',
                        py: '6px',
                        maxHeight: 'fit-content',
                        height: 'fit-content',
                        '&:hover': {
                          background: '#D9EBFF',
                        },
                      }}
                    >
                      {isEditing ? 'Save' : 'Create'}
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </CustomModalV2>
  );
};

CreateTeam.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  location: PropTypes.object,
};

export default CreateTeam;

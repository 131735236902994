import React from 'react';

import { Box } from '@mui/material';

export interface LoginProps { }

const Container: React.FC<LoginProps> = ({ children }) => (
  <Box
    px={4}
    py={8}
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    height={1}
  >
    {children}
  </Box>
);

export default Container;

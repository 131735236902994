import React, { useState } from 'react'

import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { membersSelectors } from 'store/entities/Members'

import MatCheckbox from 'components/Elements/MatCheckbox/MatCheckbox'
import MatTable from 'components/MatTable'

const teamMemberTableColumns = (onSelect) => [
  {
    id: 'check',
    key: 'check',
    name: 'Check',
    render: (row) => (
      <MatCheckbox
        checked={row.selected}
        onChange={() => onSelect(row)}
        onClick={(e) => e.stopPropagation()}
      />
    ),
    hideHeader: true,
    width: 44,
  },
  {
    id: 'name',
    key: 'name',
    name: 'Team Member',
    align: 'left',
  },
];

function teamMembersWithSelection(teamMembers, selection) {
  return teamMembers.map((teamMember) => ({
    ...teamMember,
    selected: selection.includes(teamMember.id),
  }))
}

function TeamMemberSelector({ selection, onSelect }) {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const teamMembers = useSelector(membersSelectors.getActiveMembers);

  const handleSelect = (teamMember) => {
    if (onSelect) {
      onSelect(teamMember.id)
    }
  }

  const handleChangePage = (_, val) => {
    setPage(val);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <MatTable
      rows={teamMembersWithSelection(
        teamMembers.slice(page * rowsPerPage, (page + 1) * rowsPerPage),
        selection,
      )}
      columns={teamMemberTableColumns(handleSelect)}
      onRowClick={handleSelect}
      TablePaginationProps={{
        onPageChange: handleChangePage,
        onRowsPerPageChange: handleChangeRowsPerPage,
        page,
        rowsPerPage,
        count: teamMembers.length,
        rowsPerPageOptions: [5, 10, 15],
      }}
    />
  );
}

TeamMemberSelector.propTypes = {
  selection: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default TeamMemberSelector

import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { userActions } from 'store/domain/user';

import MatDrawer from 'components/Elements/MatDrawer/MatDrawer';
import MatFormLabel from 'components/Elements/MatFormLabel/FormLabel';
import MatInput from 'components/Elements/MatInput/MatInput';
import { UploadImage } from 'components/ImageSelectors';
import { Save } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

const Edit = ({ open, onClose, onSuccess }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [editFirstName, setEditFirstName] = useState('');
  const [editLastName, setEditLastName] = useState('');
  const [editPhone, setEditPhone] = useState('');
  const [editEmail, setEditEmail] = useState('');
  const [selectedImage, setSelectedImage] = useState<any>(null);

  const { user, status, error } = useSelector(
    (state: RootState) => state?.user,
  );
  const { firstname, lastname, phoneNumber, photo_url, email } = user;

  useEffect(() => {
    setEditFirstName(firstname);
    setEditLastName(lastname);
    setEditPhone(phoneNumber);
    setEditEmail(email);
    setSelectedImage({
      type: 'custom',
      upload: false,
      url: photo_url,
    });
  }, [firstname, lastname, phoneNumber, photo_url, email]);

  useEffect(() => {
    switch (status) {
      case 'rejected':
        setEditFirstName(firstname);
        setEditLastName(lastname);
        setEditPhone(phoneNumber);
        setEditEmail(email);
        setSelectedImage({
          type: 'custom',
          upload: false,
          url: photo_url,
        });
        break;
      default:
        break;
    }
  }, [status]);

  const handlePhoto = (data) => {
    setSelectedImage(data);
  };

  const handleSave = async () => {
    if (editFirstName && editLastName) {
      dispatch(
        userActions.updateProfile({
          firstName: editFirstName,
          lastName: editLastName,
          phoneNumber: editPhone,
          image: selectedImage,
          email: editEmail,
        }),
      );
      dispatch(userActions.clearStatus());
      onClose();
      onSuccess();
    }
  };

  return (
    <MatDrawer
      open={open}
      anchor="right"
      onClose={onClose}
      onCancel={onClose}
      onSubmit={handleSave}
      title={t('editProfile')}
      submitBtn={<Save />}
      cancelBtn={t('cancel')}
      isLoading={false}
    >
      <Box maxWidth={450}>
        <UploadImage currentImage={photo_url} onChange={handlePhoto} />
        <Grid container spacing={2} mt={3}>
          <Grid item md={6} xs={12}>
            <MatFormLabel>{t('firstName')}</MatFormLabel>
            <MatInput
              value={editFirstName}
              variant="outlined"
              placeholder=""
              onChange={(e) => setEditFirstName(e.target.value)}
              size="small"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <MatFormLabel>{t('lastName')}</MatFormLabel>
            <MatInput
              value={editLastName}
              variant="outlined"
              placeholder=""
              onChange={(e) => setEditLastName(e.target.value)}
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <MatFormLabel>{t('phoneNumber')}</MatFormLabel>
            <MatInput
              value={editPhone}
              variant="outlined"
              placeholder=""
              onChange={(e) => setEditPhone(e.target.value)}
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <MatFormLabel>{t('Email')}</MatFormLabel>
            <MatInput
              value={editEmail}
              variant="outlined"
              disabled
              placeholder=""
              onChange={(e) => setEditEmail(e.target.value)}
              size="small"
            />
          </Grid>
        </Grid>
      </Box>
    </MatDrawer>
  );
};

Edit.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default Edit;

import React from 'react';

import Colors from 'constants/colors';

import ContentCopy from '@mui/icons-material/ContentCopy';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';

import MatDrawer from 'components/Elements/MatDrawer/MatDrawer';

function generateRandomString(length: number): string {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
const randomString = generateRandomString(10);
const downloadFileName = randomString.concat('.png');

const QRDrawer: React.FC<any> = ({
  open,
  campaignTitle,
  onClose,
  genericLink,
}) => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const handleLinkCopy = (link) => {
    navigator.clipboard.writeText(link);
    enqueueSnackbar('Link copied', { variant: 'success' });
  };

  const handleDownload = () => {
    const canvas: any = document.getElementById('qr-code-id');
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    const getCurrentDate = new Date();
    const day = getCurrentDate.getDate();
    const month = getCurrentDate.getMonth() + 1;
    const year = getCurrentDate.getFullYear();
    const currentDate = `${day < 10 ? `0${day.toString()}` : day.toString()}/${
      month < 10 ? `0${month.toString()}` : month.toString()
    }/${year.toString()}`;

    downloadLink.download = `${campaignTitle}_QRCode_${currentDate}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <MatDrawer
      open={open}
      anchor="right"
      onClose={onClose}
      onCancel={onClose}
      onSubmit={handleDownload}
      title={campaignTitle}
      submitBtn="Download"
      cancelBtn="Close"
      isLoading={false}
    >
      {genericLink && (
        <Box maxWidth={450} mt={0.5}>
          <Typography variant="subtitle2">
            Scan this QR Code to share the campaign.
          </Typography>
          <Box
            mt={6}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <QRCode
              id="qr-code-id"
              value={genericLink.link}
              fgColor={Colors.primary}
              size={200}
            />
          </Box>
          <Box width="100%" display="flex" mt={9.25} alignItems="center">
            <Typography sx={{ flexGrow: 1 }} variant="subtitle2">
              {genericLink.link}
            </Typography>
            <Button
              disableElevation={true}
              startIcon={<ContentCopy />}
              sx={{
                    backgroundColor: Colors.primary,
                    color: Colors.white,
                    height: 37,
                    width: '100%',
                    marginLeft: 1.25,
                    '&:hover': {
                      backgroundColor: '#0069d9',
                      borderColor: '#0062cc',
                      boxShadow: 'none',
                    },
                  }}
              variant="contained"
              onClick={() => handleLinkCopy(genericLink.link)}
            >
              <Box component="span">
                {t('Copy')}
              </Box>
            </Button>
          </Box>
        </Box>
      )}
    </MatDrawer>
  );
};

QRDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  genericLink: PropTypes.object,
};

QRDrawer.defaultProps = {
  genericLink: null,
};

export default QRDrawer;

import React, { useEffect, useState } from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from 'recharts';

import CardContainer from 'components/Elements/CardContainer/CardContainer';
import MatSelect from 'components/Elements/MatSelect/MatSelect';
import Title from 'components/Elements/Title/Title';

import CustomTooltip from './CustomTooltip';
import { Grid, Typography } from '@mui/material';
import EmptyPage from 'components/EmptyPage';

const DataByTime = ({ data, isLoading }) => {
  const [display, setDisplay] = useState('All');
  const [filteredData, setFilteredData] = useState(data);
  const [isChartData, setIsChartData] = useState(0);

  const { t } = useTranslation();
  const DATA_TYPES = [
    {
      id: 'conversions',
      name: t('actions'),
      firstColor: '#FFCC80',
      secondColor: '#FF9800',
    },
    {
      id: 'impressions',
      name: t('Views'),
      firstColor: Colors.primary,
      secondColor: Colors.primaryLight,
    },
    {
      id: 'engagements',
      name: t('engagements'),
      firstColor: '#673AB7',
      secondColor: '#B39DDB',
    },
  ];
  const DISPLAY_OPTIONS = [
    {
      title: 'All',
      value: t('all'),
    },
    {
      title: t('actions'),
      value: 'conversions',
    },
    {
      title: t('views'),
      value: 'impressions',
    },
    {
      title: t('engagements'),
      value: 'engagements',
    },
  ];

  const filterData = (originalData, value) => {
    if (value === 'All') {
      setFilteredData(originalData);
      return;
    }
    const filtered = originalData.map((item) => ({
      date: item.date,
      label: item.label,
      [value]: item[value],
    }));
    setFilteredData(filtered);
  };

  const handleDisplayChange = (event) => {
    const { value } = event.target;
    setDisplay(value);
    filterData(data, value);
  };

  const renderGradient = (types) =>
    types.map((type) => (
      <linearGradient key={type.id} id={type.id} x1="0" y1="0" x2="0" y2="1">
        <stop offset="5%" stopColor={type.firstColor} stopOpacity={0.9} />
        <stop offset="95%" stopColor={type.secondColor} stopOpacity={9} />
      </linearGradient>
    ));

  const renderArea = (types) =>
    types.map((type) => (
      <Area
        key={type.id}
        type="monotone"
        dataKey={type.id}
        name={type.name}
        stroke="none"
        fill={`url(#${type.id})`}
        fillOpacity={0.6}
      />
    ));

  useEffect(() => {
    filterData(data, display);

    // eslint-disable-next-line arrow-body-style
    const sum = data.reduce((accumulator, currentValue) => {
      return (
        accumulator +
        currentValue.conversions +
        currentValue.engagements +
        currentValue.impressions
      );
    }, 0);
    setIsChartData(sum);
  }, [data]);

  return (
    <CardContainer
      sx={{
        borderRadius: '20px',
        pr: 2.5,
        py: 1,
      }}
    >
      {isChartData > 0 ? (
        <Box
          maxWidth="100%"
          sx={(theme) => ({
            [theme.breakpoints.up('lg')]: {
              height: 'calc(100% - 48px)',
            },
            [theme.breakpoints.down('lg')]: {
              height: '322px',
            },
          })}
          maxHeight="100%"
          m={1}
          position="relative"
        >
          <Box
            pl={3.5}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={{ fontWeight: 500, mb: 1 }}>
              {t('Data By Time') as any}
            </Typography>

            <Box display="flex">
              <MatSelect
                value={display}
                onChange={handleDisplayChange}
                nameKey="title"
                valueKey="value"
                options={DISPLAY_OPTIONS}
                style={{ minWidth: 128, height: 40, marginLeft: 1.25 }}
                borderColor="grey"
              />
            </Box>
          </Box>
          {isLoading && (
            <CircularProgress
              sx={{ position: 'absolute', top: '43%', left: '50%' }}
            />
          )}
          <ResponsiveContainer width="100%" height="80%">
            <AreaChart data={filteredData}>
              <CartesianGrid
                // strokeArray="2 2"
                vertical={false}
                stroke={Colors.lightBlueGray}
              />

              <defs>{renderGradient(DATA_TYPES)}</defs>

              <XAxis
                dataKey="label"
                stroke={Colors.primaryBlack}
                tickLine={false}
                axisLine={false}
                tickMargin={15}
                tick={{ fill: Colors.primaryBlack, fontSize: 11 }}
              />
              <YAxis
                stroke={Colors.primaryBlack}
                axisLine={false}
                tickLine={false}
                tickMargin={15}
                tick={{ fill: Colors.primaryBlack, fontSize: 11 }}
              />

              {renderArea(DATA_TYPES)}
              <Tooltip content={<CustomTooltip />} />
              <Legend wrapperStyle={{ bottom: -5 }} />
            </AreaChart>
          </ResponsiveContainer>
        </Box>
      ) : (
        <Grid
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          item
          xs={12}
        >
          <EmptyPage
            actions={[]}
            title=""
            description="No data to display yet. Share a campaign link to see analysis"
          />
        </Grid>
      )}
    </CardContainer>
  );
};

DataByTime.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default DataByTime;

import React from 'react';

import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

import MatCheckbox from '../../Elements/MatCheckbox/MatCheckbox';

const HeaderRow = ({ checked, onClick }) => (
  <TableHead>
    <TableRow
      sx={{
        mb: 2,
        border: 1,
        boxShadow: '0 2px 5px 0 rgba(182,194,213,0.5)',
      }}
    >
      <TableCell
        key="active"
        align="left"
        sx={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <MatCheckbox checked={checked} onChange={onClick} />
      </TableCell>
      <TableCell
        key="name"
        align="left"
        sx={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
          Team Member
        </Typography>
      </TableCell>
      <TableCell
        key="location"
        align="left"
        sx={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
          Team
        </Typography>
      </TableCell>
    </TableRow>
  </TableHead>
);

HeaderRow.propTypes = {
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default HeaderRow;

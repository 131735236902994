import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';

import { Box, Grid, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme } from '@mui/material/styles';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { MembersActions } from 'store/entities/Members';

import CustomModalV2 from 'components/Elements/CustomModalV2/CustomModalV2';
import MatFormLabel from 'components/Elements/MatFormLabel/FormLabel';
import Title from 'components/Elements/Title/Title';

export default function MembersDrawer({ open, onClose, teamObj }) {
  const theme = createTheme();
  const dispatch = useDispatch();
  const [filterList, setFilterList] = useState<any>([]);

  const { ids, entities, status } = useSelector((state: any) => state.members);

  useEffect(() => {
    const teamMemberList = ids.map((id) => entities[id]);
    setFilterList(teamMemberList);
  }, [entities]);

  useEffect(() => {
    dispatch(
      MembersActions.fetchMembers({
        locationId: teamObj.objectId,
      }),
    );
  }, [teamObj]);

  const teamMembersPending = filterList.filter(
    (t: any) => !t.mass && t.status === 'pending',
  );

  const teamMembersActive = filterList.filter(
    (t: any) => !t.mass && t.status === 'active',
  );

  const teamMembersInactive = filterList.filter(
    (t: any) => !t.mass && t.status === 'inactive',
  );

  return (
    <CustomModalV2 padding={0} open={open} onClose={onClose} isLoading={false}>
      <Box
        className="folderCard"
        sx={{ overflow: 'auto', p: 3, py: 0 }}
        height={450}
        width={450}
      >
        {status !== 'fulfilled' ? (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress
              style={{
                width: 40,
                height: 40,
                color: Colors.primary,
              }}
            />
          </Box>
        ) : (
          <>
            <Box sx={{ mb: 2, mt: 2 }}>
              <Title size="big">{`${teamObj.name} Members List`}</Title>
            </Box>
            {filterList.length === 0 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  mt: 4,
                }}
              >
                There are no Members added to this team
              </Box>
            )}
            {teamMembersActive.length > 0 && (
              <Grid item md={12} xs={12} sx={{ mb: 3 }}>
                <MatFormLabel
                  sx={{
                    mb: 0.5,
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom: 0.2,
                    borderBottomColor: 'grey',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      color: '#00CA51',
                    }}
                  >
                    Active
                  </Typography>
                </MatFormLabel>
                {teamMembersActive.map((members) => (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <Typography variant="h6">{members.name}</Typography>
                      <Typography variant="subtitle2" sx={{ marginBottom: 2 }}>
                        {members.email}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        border: 'none',
                        padding: 0,
                        fontSize: 12,
                        fontWeight: 700,
                        [theme.breakpoints.down('lg')]: {
                          display: 'none',
                        },
                        color: members.status === 'active' ? '#00CA51' : '',
                      }}
                    >
                      {members.status === 'active' ? 'Active' : ''}
                    </Box>
                  </Box>
                ))}
              </Grid>
            )}
            {teamMembersInactive.length > 0 && (
              <Grid item md={12} xs={12} sx={{ mb: 3 }}>
                <MatFormLabel
                  sx={{
                    mb: 0.5,
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom: 0.2,
                    borderBottomColor: 'grey',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      color: '#babac4',
                    }}
                  >
                    Inactive
                  </Typography>
                </MatFormLabel>
                {teamMembersInactive.map((members) => (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <Typography variant="h6">{members.name}</Typography>
                      <Typography variant="subtitle2" sx={{ marginBottom: 2 }}>
                        {members.email}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        border: 'none',
                        padding: 0,
                        fontSize: 12,
                        fontWeight: 700,
                        [theme.breakpoints.down('lg')]: {
                          display: 'none',
                        },
                        color: members.status === 'inactive' ? '#babac4' : '',
                      }}
                    >
                      {members.status === 'inactive' ? 'Inactive' : ''}
                    </Box>
                  </Box>
                ))}
              </Grid>
            )}
            {teamMembersPending.length > 0 && (
              <Grid item md={12} xs={12} sx={{ mb: 3 }}>
                <MatFormLabel
                  sx={{
                    mb: 0.5,
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom: 0.2,
                    borderBottomColor: 'grey',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      color: '#ccc90c',
                    }}
                  >
                    Pending
                  </Typography>
                </MatFormLabel>
                {teamMembersPending.map((members) => (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <Typography variant="h6">{members.name}</Typography>
                      <Typography variant="subtitle2" sx={{ marginBottom: 2 }}>
                        {members.email}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        border: 'none',
                        padding: 0,
                        fontSize: 12,
                        fontWeight: 700,
                        [theme.breakpoints.down('lg')]: {
                          display: 'none',
                        },
                        color: members.status === 'pending' ? '#ccc90c' : '',
                      }}
                    >
                      {members.status === 'pending' ? 'Pending' : ''}
                    </Box>
                  </Box>
                ))}
              </Grid>
            )}
          </>
        )}
      </Box>
    </CustomModalV2>
  );
}

MembersDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  teamObj: PropTypes.object.isRequired,
};

import { combineReducers } from 'redux';
import user from 'store/domain/user';
import announcements from 'store/entities/Announcements';
import businesses from 'store/entities/Businesses';
import campaigns from 'store/entities/Campaigns';
import images from 'store/entities/Images';
import integrations from 'store/entities/Integrations';
import invits from 'store/entities/Invits';
import members from 'store/entities/Members';
import permissions from 'store/entities/Permissions';
import reports from 'store/entities/Reports';
import subscriptions from 'store/entities/Subscriptions';
import teams from 'store/entities/Teams';
import teamHub from 'store/entities/TeamHub';
import coupon from 'store/entities/Coupon';
import templates from 'store/entities/Templates';

const CLEAR_STATE = 'user/logoutUser';
const appReducer = combineReducers({
  permissions,
  user,
  businesses,
  members,
  teams,
  teamHub,
  invits,
  announcements,
  campaigns,
  reports,
  subscriptions,
  images,
  integrations,
  coupon,
  templates,
});

const rootReducer = (state, action) => {
  if (action.type === CLEAR_STATE) {
    // eslint-disable-next-line
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;

import React, { useEffect, useState } from 'react';

import Colors from 'constants/colors';
import { defaultStyles } from 'constants/styles';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Collapse, Divider, IconButton } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import get from 'lodash.get';
import PropTypes from 'prop-types';

import MatTable from 'components/MatTable/MatTable';

const columns = (onOpen) => [
  {
    id: 'toogle',
    key: 'toogle',
    name: 'Toogle',
    render: (row) => (
      <IconButton
        sx={{ margin: 1.25, opacity: row.hasTeamMember ? 1 : 0 }}
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          onOpen(row);
        }}
      >
        <ChevronRightIcon
          sx={{
            transform: row.open ? 'rotate(90deg)' : 'rotate(0deg)',
            transition: '0.3s',
          }}
        />
      </IconButton>
    ),
    hideHeader: true,
    width: '6px',
  },
  {
    name: 'Card',
    key: 'title',
    align: 'left',
    width: '70%',
  },
  {
    name: 'Actions',
    key: 'actions',
    align: 'center',
    width: '30%',
  },
];

const ReportTable = ({ rows, total }) => {
  const [opens, setOpens] = useState(new Set([rows[0]?.id]));
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const handleToogleOpen = (row) => {
    const set = new Set(opens);
    if (set.has(row.id)) {
      set.delete(row.id);
    } else {
      set.add(row.id);
    }
    return setOpens(set);
  };

  const renderBodyCell = (row, column) => {
    if (column.render) {
      return column.render(row, column);
    }
    return get(row, column.key);
  };

  const renderRow = (_row, _columns) => (
    <>
      <TableRow
        sx={{
          boxShadow: defaultStyles.box.shadow,
          backgroundColor: Colors.white,
          '&:hover': {
            boxShadow: ' 0px 0px 0px 1px rgb(0 99 255 / 50%)',
            backgroundColor: `${Colors.white} !important`,
            cursor: 'pointer',
          },
        }}
        onClick={_row.hasTeamMember ? () => handleToogleOpen(_row) : undefined}
        component="div"
      >
        {_columns.map((column) => (
          <TableCell
            sx={{
              width: column.width,
              border: 'none',
              padding: 0,
              minHeight: 64,
            }}
            align={column.align}
          >
            {renderBodyCell(_row, column)}
          </TableCell>
        ))}
      </TableRow>
      {_row.hasTeamMember ? (
        <TableRow sx={{ padding: 0 }}>
          <TableCell colSpan={5} sx={{ padding: 0 }}>
            <Collapse in={_row.open}>
              <Table>
                {_row.byTeamMember.map((data) => (
                  <TableRow>
                    <TableCell sx={{ padding: '4px 0', width: '8%' }} />

                    <TableCell sx={{ padding: '4px 0', width: '64%' }}>
                      {data.name}
                    </TableCell>
                    <TableCell
                      sx={{ padding: '4px 0', width: '28%' }}
                      align="center"
                    >
                      {data.actions}
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : (
        <TableRow sx={{ padding: 0 }} />
      )}
    </>
  );

  const renderBody = (_rows, _columns) => (
    <TableBody
      sx={{
        '& tr': {
          color: Colors.black,
          borderRadius: 2.5,
        },
        '& tr td:first-child': {
          borderTopLeftRadius: 2.5,
          borderBottomLeftRadius: 2.5,
          overflow: 'hidden',
        },
        '& tr td:last-child': {
          borderTopRightRadius: 2.5,
          borderBottomRightRadius: 2.5,
          overflow: 'hidden',
        },
      }}
    >
      {_rows.map((row) => renderRow(row, _columns))}

      <TableRow>
        <TableCell colSpan={5} sx={{ padding: 0 }}>
          <Divider />
        </TableCell>
      </TableRow>

      <TableRow sx={{ fontWeight: 'bold' }}>
        <TableCell
          sx={{ fontWeight: 'bold', padding: 0, width: _columns[0].width }}
        />
      </TableRow>
    </TableBody>
  );

  const withOpens = (_rows) =>
    _rows.map((row) => ({ ...row, open: opens.has(row.id) }));

  const handleChangePage = (_, val) => {
    setPage(val);
    if (rows[rowsPerPage * val]) {
      setOpens(new Set([rows[rowsPerPage * val].id]));
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <MatTable
      rows={withOpens(rows.slice(page * rowsPerPage, (page + 1) * rowsPerPage))}
      columns={columns(handleToogleOpen)}
      renderBody={renderBody}
      TablePaginationProps={{
        onPageChange: handleChangePage,
        onRowsPerPageChange: handleChangeRowsPerPage,
        page,
        rowsPerPage,
        count: rows.length,
        rowsPerPageOptions: [5, 10, 15],
      }}
    />
  );
};

ReportTable.propTypes = {
  rows: PropTypes.array.isRequired,
  total: PropTypes.array.isRequired,
};

ReportTable.defaultProps = {};

export default ReportTable;

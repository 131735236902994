import Parse, { JSONBaseAttributes, Pointer } from 'parse'
import { createPointer } from 'store/utils/pointers';

export interface UserAttributes {
  firstname?: string
  lastname?: string
  emailVerified?: boolean
  username?: string
  password?: string
  email?: string
  photo_url?: string
  phone?: string
  business_id?: string
  stripe_customer_ref?: string
  role?: string
}

export interface IUser extends JSONBaseAttributes {
  firstname: string
  lastname: string
  fullName?: string
  emailVerified: boolean
  username: string
  email: string
  photo_url: string
  phoneNumber: string
  businessId?: string
  vendor_id?: string
  role?: string
}

class User extends Parse.User<UserAttributes> {
  static className = '__User'

  constructor(init: UserAttributes = {}) {
    super(init as UserAttributes)
  }

  static getPointer (objectId: string): Pointer {
    return createPointer('_User', objectId)
  }

  serialize (): IUser {
    const json = super.toJSON();
    return {
      ...json,
      firstname: json.firstname || '',
      lastname: json.lastname || '',
      emailVerified: !!json.emailVerified,
      username: json.username as string,
      email: json.email as string,
      fullName: [json.firstname, json.lastname].join(' '),
      photo_url: json.photo_url || '',
      phoneNumber: json.phone || '',
      businessId: json.business_id || '',
    }
  }
}

export default User;

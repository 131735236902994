/* eslint-disable no-param-reassign */

import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
  EntityState,
} from '@reduxjs/toolkit';
import Moment from 'moment';
import Parse from 'parse';
import { RootState } from 'store';
import { userSelectors } from 'store/domain/user';
import Annoucement, {
  AnnoucementAttributes,
  IAnnoucement,
} from 'store/models/Announcement';
import Business from 'store/models/Business';
import User from 'store/models/User';
import { FetchPayload, RequestStatus, Sort } from 'store/utils/types';

const sliceName = 'annoucements';

const getAnnoucementsId = (announcement: IAnnoucement) =>
  `${announcement?.objectId}`;

const annoucementsAdapter = createEntityAdapter<IAnnoucement>({
  selectId: getAnnoucementsId,
});

export interface AnnouncementFilter {}

export type AnnouncementSort = Sort<keyof AnnoucementAttributes>;

export interface fetchAnnouncementsPayload
  extends FetchPayload<AnnouncementFilter, AnnouncementSort> {}

//  const defaultPageSize = 10;

const fetchCurrentAnnouncements = createAsyncThunk(
  `${sliceName}/fetchCurrentAnnouncements`,
  async (
    { filter, sort, page, pageSize }: fetchAnnouncementsPayload,
    { getState }: any,
  ) => {
    const state = getState() as RootState;
    const businessId = userSelectors.getbusinessId(state);
    if (!businessId) {
      throw new Error('No active business.');
    }
    const query = new Parse.Query(Annoucement);
    query.equalTo('business_point', Business.getPointer(businessId));
    query.withCount(true);
    query.greaterThanOrEqualTo('end_date', Moment.utc().toDate());
    // sort
    if (sort) {
      for (const sortItem of sort) {
        switch (sortItem[1]) {
          case 'asc':
            query.ascending(sortItem[0]);
            break;
          case 'desc':
            query.descending(sortItem[0]);
            break;
          default:
            throw new Error(`Order not nsupported: ${sortItem[1]}.`);
        }
      }
    }

    // pagination
    /* const limit: number = pageSize || defaultPageSize;
    const skip: number = limit * ((page || 1) - 1); */
    /* query.skip(skip);
    query.limit(limit); */

    const announcements = await query.findAll();
    return announcements.map((announcement) => announcement.toJSON());
  },
);

const fetchPastAnnouncements = createAsyncThunk(
  `${sliceName}/fetchPastAnnouncements`,
  async (
    { filter, sort, page, pageSize }: fetchAnnouncementsPayload,
    { getState }: any,
  ) => {
    const state = getState() as RootState;
    const businessId = userSelectors.getbusinessId(state);
    if (!businessId) {
      throw new Error('No active business.');
    }
    const query = new Parse.Query(Annoucement);
    query.equalTo('business_point', Business.getPointer(businessId));
    query.lessThan('end_date', Moment.utc().toDate());
    query.withCount(true);
    // sort
    if (sort) {
      for (const sortItem of sort) {
        switch (sortItem[1]) {
          case 'asc':
            query.ascending(sortItem[0]);
            break;
          case 'desc':
            query.descending(sortItem[0]);
            break;
          default:
            throw new Error(`Order not nsupported: ${sortItem[1]}.`);
        }
      }
    }

    // pagination
    /* const limit: number = pageSize || defaultPageSize;
    const skip: number = limit * ((page || 1) - 1); */
    /* query.skip(skip);
    query.limit(limit); */

    const announcements = await query.findAll();
    return announcements.map((announcement) => announcement.toJSON());
  },
);

export interface GetAnnouncementPayload {
  announcementId: string;
}

const getAnnouncement = createAsyncThunk(
  `${sliceName}/getAnnouncement`,
  async ({ announcementId }: GetAnnouncementPayload) => {
    const query = new Parse.Query(Annoucement);
    const announcement = await query.get(announcementId);
    return announcement.toJSON();
  },
);

export interface CreateAnnouncementPayload {
  imageUrl: string;
  description: string;
  title: string;
  teamMembers: string[];
  duration: number;
  onSuccess: (id: string) => void;
}

const createAnnouncement = createAsyncThunk(
  `${sliceName}/createAnnouncement`,
  async (
    {
      imageUrl,
      description,
      title,
      teamMembers,
      duration,
      onSuccess,
    }: CreateAnnouncementPayload,
    { getState },
  ) => {
    const state = getState() as RootState;
    const businessId = userSelectors.getbusinessId(state);
    const { objectId } = userSelectors.getCurrentUser(state);
    const today = new Date();
    let endDate;
    if (duration === 1) {
      endDate = new Date(today.setTime(today.getTime() + 1 * 86400000));
    } else if (duration === 7) {
      endDate = new Date(today.setTime(today.getTime() + 7 * 86400000));
    } else {
      endDate = new Date(today.setTime(today.getTime() + 30 * 86400000));
    }
    const announcement = new Annoucement({
      photo_url: imageUrl,
      details: description,
      title,
      createdBy_point: User.getPointer(objectId),
      business_point: Business.getPointer(businessId),
      end_date: endDate,
      team_member_ids: teamMembers,
    });
    await announcement.save();
    onSuccess(announcement.toJSON().objectId);
    return announcement.toJSON();
  },
);

/* export interface UpdateAnnouncementPayload {}

const updateAnnouncement = createAsyncThunk(
  `${sliceName}/updateAnnouncement`,
  async () => {
    const query = new Parse.Query(Annoucement);
    const announcements = await query.findAll();
    return announcements.map((announcement) => announcement.toJSON());
  },
); */

/* export interface DeleteAnnouncementPayload {}

const deleteAnnouncement = createAsyncThunk(
  `${sliceName}/deleteAnnouncement`,
  async () => {
    const query = new Parse.Query(Annoucement);
    const announcements = await query.findAll();
    return announcements.map((announcement) => announcement.toJSON());
  },
); */

export interface AnnouncementState extends EntityState<IAnnoucement> {
  status: RequestStatus;
  error?: string;
  current?: any;
  past?: any;
  recents?: any;
  announcement?: any;
}

const initialState: AnnouncementState = {
  ...annoucementsAdapter.getInitialState(),
  status: 'idle',
};

const annoucementssliceName = createSlice({
  name: 'annoucements',
  initialState,
  extraReducers: (builder) => {
    // Fetch Current
    builder.addCase(fetchCurrentAnnouncements.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchCurrentAnnouncements.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.current = action.payload;
    });
    builder.addCase(fetchCurrentAnnouncements.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Fetch Past
    builder.addCase(fetchPastAnnouncements.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchPastAnnouncements.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.past = action.payload;
    });
    builder.addCase(fetchPastAnnouncements.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Get
    builder.addCase(getAnnouncement.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getAnnouncement.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.announcement = action.payload;
    });
    builder.addCase(getAnnouncement.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Create
    builder.addCase(createAnnouncement.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(createAnnouncement.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.announcement = action.payload;
    });
    builder.addCase(createAnnouncement.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Update
    /* builder.addCase(updateAnnouncement.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateAnnouncement.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state = annoucementsAdapter.setOne(state, action.payload);
    });
    builder.addCase(updateAnnouncement.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Delete
    builder.addCase(deleteAnnouncement.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(deleteAnnouncement.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state = annoucementsAdapter.setOne(state, action.payload);
    });
    builder.addCase(deleteAnnouncement.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    }); */
  },
  reducers: {
    clearStatus: (state) => {
      state.status = 'idle';
    },
  },
});

const annoucementsSelector = {};

export const announcementActions = {
  fetchCurrentAnnouncements,
  createAnnouncement,
  fetchPastAnnouncements,
  getAnnouncement,
  ...annoucementssliceName.actions,
};

export { annoucementsSelector, getAnnoucementsId };

export default annoucementssliceName.reducer;

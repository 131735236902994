import React, { useState, useEffect } from 'react';

import DateRangeIcon from '@mui/icons-material/DateRange';
import { Button, Box } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';

import Moment from 'moment';
import PropTypes from 'prop-types';

import CTATextField from 'components/CTATextField';
import DateRangeDrawer from 'components/DateRangeDrawer';
import MatDrawer from 'components/Elements/MatDrawer/MatDrawer';
import MatInput from 'components/Elements/MatInput/MatInput';
import { Save } from '@mui/icons-material';

const ActionDrawer = ({ open, onClose, onSubmit, defaultCta }) => {
  const [tab, setTab] = useState(0);
  const [redirect, setRedirect] = useState('');
  const [cta, setCta] = useState('');
  const [showDates, setShowDates] = useState(false);
  const [eventDates, setEventDates] = useState([
    Moment(),
    Moment().add(1, 'days'),
  ]);
  const [address, setAddress] = useState('');

  useEffect(() => {
    if (defaultCta && defaultCta.type !== 'none') {
      if (defaultCta.type === 'redirect') {
        setTab(0);
        setCta(defaultCta.name);
        setRedirect(defaultCta.link);
      }
      if (defaultCta.type === 'event') {
        setTab(1);
        setAddress(defaultCta.address);
        setEventDates([
          Moment(defaultCta.startDate),
          Moment(defaultCta.endDate),
        ]);
      }
    }
  }, [defaultCta]);

  const setInitialState = () => {
    setTab(0);
    setRedirect('');
    setCta('');
  };

  const handleSubmit = () => {
    if (tab === 0) {
      onSubmit({
        type: 'redirect',
        link: redirect,
        name: cta,
      });
    } else {
      onSubmit({
        type: 'event',
        startDate: eventDates[0].toISOString(),
        endDate: eventDates[1].toISOString(),
        address,
      });
    }
    setInitialState();
  };

  const handleSaveDates = (dates) => {
    setEventDates(dates);
    setShowDates(false);
  };

  const handleCTA = (value) => {
    if (value.length < 24) {
      setCta(value);
    }
  };

  const renderActionItems = () => {
    switch (tab) {
      case 0:
        return (
          <Box mt={6}>
            <CTATextField
              placeholder="Call to Action"
              onChange={handleCTA}
              value={cta}
              size="small"
            />
            <Box style={{ height: 16 }} />
            <MatInput
              value={redirect}
              variant="outlined"
              placeholder="Redirect URL"
              onChange={(e) => setRedirect(e.target.value)}
              size="small"
            />
          </Box>
        );
      case 1:
        return (
          <Box mt={6}>
            <Box
              onClick={() => setShowDates(true)}
              onKeyDown={() => setShowDates(true)}
              tabIndex={0}
              role="button"
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                mb: 3,
              }}
            >
              <DateRangeIcon style={{ marginRight: 8 }} />
              <Typography variant="subtitle1">
                {`${eventDates[0].format(
                  'MMM D, YYYY h:mm',
                )} - ${eventDates[1].format('MMM D, YYYY h:mm')}`}
              </Typography>
            </Box>
            <MatInput
              value={address}
              variant="outlined"
              placeholder="Address (optional)"
              onChange={(e) => setAddress(e.target.value)}
              size="small"
            />
          </Box>
        );
      default:
        return <div />;
    }
  };

  return (
    <MatDrawer
      open={open}
      anchor="right"
      onClose={onClose}
      onCancel={onClose}
      onSubmit={handleSubmit}
      title="Add Action"
      submitBtn={<Save />}
      cancelBtn="Cancel"
    >
      <Box maxWidth={450}>
        <ButtonGroup
          color="primary"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: 1,
          }}
        >
          <Button
            onClick={() => setTab(0)}
            variant={tab === 0 ? 'contained' : 'outlined'}
            sx={{
              px: 3,
              borderTopLeftRadius: 6,
              borderBottomLeftRadius: 6,
            }}
          >
            Redirect
          </Button>
          <Button
            onClick={() => setTab(1)}
            variant={tab === 1 ? 'contained' : 'outlined'}
            sx={{
              px: 3,
              borderTopRightRadius: 6,
              borderBottomRightRadius: 6,
            }}
          >
            Event
          </Button>
        </ButtonGroup>
        <Typography variant="subtitle2" align="center">
          {tab === 0
            ? 'Use the redirect button to send recipients to a specific location.'
            : 'Save an event to a recipients calendar.'}
        </Typography>
        {renderActionItems()}
      </Box>
      <DateRangeDrawer
        open={showDates}
        onClose={() => setShowDates(false)}
        onSave={(val) => handleSaveDates(val)}
        allowTime={true}
        startDate={eventDates[0]}
        endDate={eventDates[1]}
      />
    </MatDrawer>
  );
};

ActionDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  defaultCta: PropTypes.object,
};

ActionDrawer.defaultProps = {
  defaultCta: null,
};

export default ActionDrawer;

import React from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

const EnterpriseOverview = () => {
  const { t } = useTranslation();

  return (
    <Box
      width="100%"
      pt={1}
      pb={1}
      pl={5}
      pr={5}
      borderRadius={1}
      borderColor={Colors.lightBlueGray}
      border={0.5}
    >
      <Box display="flex" alignItems="center">
        <Typography sx={{ flexGrow: 1, fontWeight: 'bold', fontSize: 16 }}>
          {t('planDetails')}
        </Typography>
      </Box>
      <Grid container sx={{ marginTop: 3, marginBottom: 3 }}>
        <Grid item md={3}>
          <Typography variant="caption">{t('plan')}</Typography>
          <Typography variant="h6">{t('enterpriseBenefits')}</Typography>
        </Grid>
      </Grid>
      <div>
        <Typography variant="subtitle2">{t('enjoyFullAccess')}</Typography>
        <Box mt={3} mb={3}>
          <Typography variant="caption">
            {t('entrepriseAccountsMonthlyInvoices')}
          </Typography>
        </Box>
      </div>
    </Box>
  );
};

export default EnterpriseOverview;

/* eslint-disable no-param-reassign */
import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
  EntityState,
  createDraftSafeSelector,
} from '@reduxjs/toolkit';
import Parse from 'parse';
import { RootState } from 'store';
import { userSelectors } from 'store/domain/user';
import Campaign from 'store/models/Campaign';
import Coupon from 'store/models/Coupon';
import Location, { ILocation, LocationAttributes } from 'store/models/Location';
import { selectTemplatesState } from 'store/selectors';
import { RequestStatus, Sort, FetchPayload } from 'store/utils/types';
import { BusinessFolderUpload, LocationUpload } from 'utils/cloudinary';

const sliceName = 'Templates';
const getTemplateId = (team: ILocation) =>
  `${team.business_id}/${team?.objectId}`;

const TemplatesAdapter = createEntityAdapter<ILocation>({
  selectId: getTemplateId,
});

const formatCampaign = (campaign) => {
  const data: any[] = [];
  if (campaign.row_one) {
    data.push({
      item: campaign.row_one,
      row: 0,
    });
  }
  if (campaign.row_two) {
    data.push({
      item: campaign.row_two,
      row: 1,
    });
  }
  if (campaign.row_three) {
    data.push({
      item: campaign.row_three,
      row: 2,
    });
  }
  if (campaign.row_four) {
    data.push({
      item: campaign.row_four,
      row: 3,
    });
  }
  if (campaign.row_five) {
    data.push({
      item: campaign.row_five,
      row: 4,
    });
  }

  return {
    id: campaign.objectId,
    title: campaign.title,
    image: campaign.image,
    description: campaign.description,
    data,
    startDate: campaign.start_date,
    endDate: campaign.end_date,
    vendor: campaign.vendor_id,
    terminated: campaign.terminated,
    advanced: {
      conditions: campaign.conditions,
      age: campaign.age,
      pixel: campaign.facebook_pixel,
      targetAudience: campaign.target_audience,
    },
  };
};

const getTemplatesByVendor = createAsyncThunk(
  `${sliceName}/getTemplatesByVendor`,
  async (props) => {
    try {
      const premiumCampaigns = await Parse.Cloud.run(
        'CampaignTemplate:getVendorTemplates',
      );
      const data: any = [];
      if (
        premiumCampaigns !== null &&
        typeof premiumCampaigns !== 'undefined' &&
        premiumCampaigns.length > 0
      ) {
        const premiumPromises = premiumCampaigns.map(
          async (premiumCampaign) => {
            data.push({
              createdAt: premiumCampaign.get('createdAt'),
              title: premiumCampaign.get('title'),
              image: premiumCampaign.get('row_one').info.image
                ? premiumCampaign.get('row_one').info.image.url
                : null,
              description: premiumCampaign.get('description'),
              start_date: premiumCampaign.get('start_date'),
              end_date: premiumCampaign.get('end_date'),
              id: premiumCampaign.id,
              terminated: premiumCampaign.get('terminated')
                ? premiumCampaign.get('terminated')
                : false,
            });
          },
        );

        await Promise.all(premiumPromises);
      }
      return data;
    } catch (e) {
      throw new Error(`Error: ${e}`);
    }
  },
);

export interface GetTemplatePayload {
  id: string;
}

const getTemplate = createAsyncThunk(
  `${sliceName}/getTemplate`,
  async ({ id }: GetTemplatePayload) => {
    try {
      const campaign = await Parse.Cloud.run('CampaignTemplate:getOne', {
        id,
      });

      return formatCampaign(campaign.toJSON());
    } catch (e) {
      throw new Error(`Error: ${e}`);
    }
  },
);

export interface createTemplatePayload {
  title: string;
  published: boolean;
  description: string;
  image: any;
  info: any;
  startDate: string;
  endDate: string;
  id?: string | null;
  advanced: any;
  callback: (campaignJson: any) => void;
}

const createTemplate = createAsyncThunk(
  `${sliceName}/createTemplate`,
  async (
    {
      title,
      description,
      image,
      published,
      info,
      startDate,
      endDate,
      id,
      advanced,
      callback,
    }: createTemplatePayload,
    { getState },
  ) => {
    try {
      const state = getState() as RootState;
      const businessId = userSelectors.getbusinessId(state);
      const infoArray: any = [];
      for (let x = 0; x < info.length; x++) {
        let name = 'row_one';
        if (x === 1) {
          name = 'row_two';
        } else if (x === 2) {
          name = 'row_three';
        } else if (x === 3) {
          name = 'row_four';
        } else if (x === 4) {
          name = 'row_five';
        }
        const rowDetail: any = JSON.parse(JSON.stringify(info[x].item));
        switch (rowDetail.type) {
          case 'info':
            if (
              rowDetail.info.image !== null &&
              typeof rowDetail.info.image !== 'undefined'
            ) {
              if (rowDetail?.info?.image?.type === 'custom') {
                // eslint-disable-next-line
                const imageUrl = await BusinessFolderUpload({
                  businessId,
                  image: rowDetail.info?.image?.url,
                });
                rowDetail.info.image = {
                  type: 'custom',
                  url: imageUrl,
                };
              }
            }
            break;
          case 'image':
            if (
              rowDetail.info.image !== null &&
              typeof rowDetail.info.image !== 'undefined'
            ) {
              if (rowDetail.info.image.type === 'custom') {
                // eslint-disable-next-line
                const imageUrl = await BusinessFolderUpload({
                  businessId,
                  image: rowDetail.info?.image?.url,
                });
                rowDetail.info.image = {
                  type: 'custom',
                  url: imageUrl,
                };
              }
            }
            break;
          case 'carousel':
            if (
              rowDetail.info.images !== null &&
              typeof rowDetail.info.images !== 'undefined' &&
              rowDetail.info.images.length > 0
            ) {
              for (let y = 0; y < rowDetail.info.images.length; y++) {
                if (rowDetail.info.images[y].image.type === 'custom') {
                  // eslint-disable-next-line
                  const imageUrl = await BusinessFolderUpload({
                    businessId,
                    image: rowDetail.info.images[y].image.url,
                  });
                  rowDetail.info.images[y].image.url = imageUrl;
                }
              }
            }
            break;
          default:
            // does nothing
            break;
        }
        infoArray.push({ name, rowDetail });
      }

      const template = await Parse.Cloud.run('CampaignTemplate:create', {
        title,
        description,
        image,
        published,
        info: infoArray,
        startDate,
        endDate,
        id,
        advanced,
      });
      callback(template.toJSON());
      return template.toJSON();
    } catch (e) {
      console.error(`Error creating run again campaign: ${e}`);
      console.error(e);
      return new Error(`Error creating campaign: ${e}`);
    }
  },
);

const fetchTemplates = createAsyncThunk(
  `${sliceName}/fetchTemplates`,
  async (props) => {
    try {
      const templates = await Parse.Cloud.run('CampaignTemplate:getAll', {
        page: 1,
        limit: 10,
      });
      return {
        ...templates,
        result: templates.result.map((el) => formatCampaign(el)),
      };
    } catch (e) {
      throw new Error(`Error: ${e}`);
    }
  },
);

export interface terminateTemplatePayload {
  campaignId: string;
  reason: string;
  callback: () => void;
}

const CampaignTemplate = Parse.Object.extend('campaign_template');

const terminateTemplate = createAsyncThunk(
  `${sliceName}/terminateTemplate`,
  async ({ campaignId, reason, callback }: terminateTemplatePayload) => {
    const query = new Parse.Query(CampaignTemplate);
    const campaign = await query.get(campaignId);
    if (!campaign) {
      throw new Error('Campaign Not Found');
    }
    campaign.set('terminated', true);
    campaign.set('termination_reason', reason);
    await campaign.save();
    callback();
    return campaign.toJSON();
  },
);

export interface activateTemplatePayload {
  campaignId: string;
  callback: () => void;
}

const activateTemplate = createAsyncThunk(
  `${sliceName}/activateTemplate`,
  async ({ campaignId, callback }: activateTemplatePayload) => {
    const query = new Parse.Query(CampaignTemplate);
    const campaign = await query.get(campaignId);
    if (!campaign) {
      throw new Error('Campaign Not Found');
    }
    campaign.set('terminated', false);
    await campaign.save();
    callback();
    return campaign.toJSON();
  },
);

export interface TemplateState extends EntityState<ILocation> {
  status: RequestStatus;
  error?: string;
  templates?: any;
  campaign?: any;
  templatesVendor?: any;
}

const initialState: TemplateState = {
  ...TemplatesAdapter.getInitialState(),
  status: 'idle',
};

const TemplatesSliceName = createSlice({
  name: sliceName,
  initialState,
  extraReducers: (builder) => {
    // Fetch
    builder.addCase(fetchTemplates.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchTemplates.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.templates = action.payload;
      state.error = '';
    });
    builder.addCase(fetchTemplates.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Get
    builder.addCase(getTemplatesByVendor.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getTemplatesByVendor.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.templatesVendor = action.payload;
      state.error = '';
    });
    builder.addCase(getTemplatesByVendor.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Get One
    builder.addCase(getTemplate.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getTemplate.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.campaign = action.payload;
      state.error = '';
    });
    builder.addCase(getTemplate.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Create
    builder.addCase(createTemplate.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(createTemplate.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.error = '';
    });
    builder.addCase(createTemplate.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Terminate
    builder.addCase(terminateTemplate.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(terminateTemplate.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.campaign = action.payload;
    });
    builder.addCase(terminateTemplate.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Activate
    builder.addCase(activateTemplate.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(activateTemplate.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.campaign = action.payload;
    });
    builder.addCase(activateTemplate.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
  },
  reducers: {
    setOne(state, action) {
      TemplatesAdapter.setOne(state, action.payload);
    },
    setMany(state, action) {
      TemplatesAdapter.setMany(state, action.payload);
    },
    removeOne: TemplatesAdapter.removeOne,
    removeMany: TemplatesAdapter.removeMany,
  },
});

const getActiveTemplates = createDraftSafeSelector(
  selectTemplatesState,
  (templates) =>
    templates.templatesVendor?.filter((template) => !template.terminated),
);

const getInActiveTemplates = createDraftSafeSelector(
  selectTemplatesState,
  (templates) =>
    templates.templatesVendor?.filter((template) => template.terminated),
);

export const TemplatesSelectors = { getActiveTemplates, getInActiveTemplates };

export const templatesActions = {
  fetchTemplates,
  createTemplate,
  getTemplatesByVendor,
  getTemplate,
  terminateTemplate,
  activateTemplate,
  ...TemplatesSliceName.actions,
};

export default TemplatesSliceName.reducer;

/* eslint-disable no-param-reassign */
import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
  EntityState,
  createDraftSafeSelector,
} from '@reduxjs/toolkit';
import Parse from 'parse';
import { RootState } from 'store';
import { userSelectors } from 'store/domain/user';
import Location, { ILocation, LocationAttributes } from 'store/models/Location';
import { selectTeamsState } from 'store/selectors';
import { RequestStatus, Sort, FetchPayload } from 'store/utils/types';
import { LocationUpload } from 'utils/cloudinary';

const sliceName = 'teams';
const getTeamId = (team: ILocation) => `${team.business_id}/${team?.objectId}`;

const TeamsAdapter = createEntityAdapter<ILocation>({ selectId: getTeamId });

export interface getTeamPayload {
  TeamId: string;
}

const getTeam = createAsyncThunk(
  `${sliceName}/getTeam`,
  async ({ TeamId }: getTeamPayload) => {
    const query = new Parse.Query(Location);
    const team = await query.get(TeamId);
    return team.toJSON();
  },
);

export interface createTeamPayload {
  name: string;
  phoneNumber: string;
  website: string;
  imageUrl: string;
  isActive: boolean;
}

const createTeam = createAsyncThunk(
  `${sliceName}/createTeam`,
  async (
    { name, phoneNumber, website, imageUrl, isActive }: createTeamPayload,
    { getState }: any,
  ) => {
    const state = getState() as RootState;
    const businessId = userSelectors.getbusinessId(state);

    if (!businessId) {
      throw new Error('No active business.');
    }
    const image = await LocationUpload(imageUrl);
    const team = new Location({
      business_id: businessId,
      name,
      is_active: isActive,
      phone: phoneNumber,
      url: website,
      logo_url: image,
    });

    await team.save();
    return team.toJSON();
  },
);

export interface updateTeamPayload {
  teamId: string;
  name: string;
  phoneNumber: string;
  website: string;
  imageUrl: string;
  status: boolean;
}

const updateTeam = createAsyncThunk(
  `${sliceName}/updateTeam`,
  async ({
    teamId,
    name,
    phoneNumber,
    website,
    imageUrl,
    status,
  }: updateTeamPayload) => {
    const image = await LocationUpload(imageUrl);
    const team = await Parse.Cloud.run('Business:updateLocation', { status, name, phoneNumber, website, imageUrl: image, locationId: teamId })

    return team.toJSON();
  },
);

export type TeamStatus = 'active' | 'inactive' | 'all';

export interface TeamFilter {
  status?: TeamStatus;
}

export type TeamSort = Sort<keyof LocationAttributes>;

export interface FetchTeamsPayload extends FetchPayload<TeamFilter, TeamSort> { }

/* const defaultPageSize = 10; */

const fetchTeams = createAsyncThunk(
  `${sliceName}/fetchTeams`,
  async (
    { filter, sort, page, pageSize }: FetchTeamsPayload,
    { getState }: any,
  ) => {
    const state = getState() as RootState;
    const businessId = userSelectors.getbusinessId(state);
    if (!businessId) {
      throw new Error('No active business.');
    }

    const sortObject = {} as any
    // sort
    if (sort) {
      for (const sortItem of sort) {
        switch (sortItem[1]) {
          case 'asc':
            sortObject.type = 'asc';
            [sortObject.sortItem] = sortItem;
            break;
          case 'desc':
            sortObject.type = 'desc';
            [sortObject.sortItem] = sortItem;
            break;
          default:
            throw new Error(`Order not supported: ${sortItem[0]}.`);
        }
      }
    }

    const teams = await Parse.Cloud.run('Business:getTeams', { status: filter?.status || {}, businessId, sort: sortObject })
    return teams.map((team) => team.toJSON());
  },
);

export interface TeamState extends EntityState<ILocation> {
  status: RequestStatus;
  error?: string;
}

const initialState: TeamState = {
  ...TeamsAdapter.getInitialState(),
  status: 'idle',
};

const TeamsSliceName = createSlice({
  name: sliceName,
  initialState,
  extraReducers: (builder) => {
    // Fetch
    builder.addCase(fetchTeams.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchTeams.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state = TeamsAdapter.setAll(state, action.payload);
      state.error = '';
    });
    builder.addCase(fetchTeams.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Get
    builder.addCase(getTeam.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getTeam.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state = TeamsAdapter.setOne(state, action.payload);
      state.error = '';
    });
    builder.addCase(getTeam.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Create
    builder.addCase(createTeam.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(createTeam.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state = TeamsAdapter.setOne(state, action.payload);
      state.error = '';
    });
    builder.addCase(createTeam.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Update
    builder.addCase(updateTeam.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateTeam.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state = TeamsAdapter.setOne(state, action.payload);
      state.error = '';
    });
    builder.addCase(updateTeam.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
  },
  reducers: {
    clearStatus: (state) => {
      state.status = 'idle';
    },
  },
});

const getActiveTeams = createDraftSafeSelector(selectTeamsState, (teams) =>
  teams.ids
    .map((id) => teams.entities[id])
    .filter((team) => team.is_active)
    .map((team) => ({
      name: team.name,
      id: team.objectId,
      photoUrl: team.logo_url,
    })),
);

export const teamsSelectors = {
  getActiveTeams,
};

export const teamsActions = {
  fetchTeams,
  createTeam,
  updateTeam,
  ...TeamsSliceName.actions,
};

export default TeamsSliceName.reducer;

import React, { useEffect, useState } from 'react';

import Colors from 'constants/colors';

import DateRangeIcon from '@mui/icons-material/DateRange';
import { Autocomplete, Chip, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import Typography from '@mui/material/Typography';

import { ReactComponent as AlertIcon } from 'assets/images/alert_icon.svg';
import Moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import routes from 'routes';
import { RootState } from 'store';
import { campaignActions } from 'store/entities/Campaigns';

import DateRangeDrawer from '../DateRangeDrawer';
import MatDrawer from '../Elements/MatDrawer/MatDrawer';
import MatFormLabel from '../Elements/MatFormLabel/FormLabel';

const RunAgainDrawer = ({ campaign, open, onClose, onSuccess }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDateDrawer, setShowDateDrawer] = useState(false);
  const [duration, setDuration] = useState([Moment(), Moment().add(5, 'days')]);
  const { runAgainLoading } = useSelector((state: RootState) => state.campaigns);

  const [tags, setTags] = useState<any[]>([]);

  useEffect(() => {
    if (campaign && campaign.tags) {
      setTags(campaign.tags);
    }
  }, [campaign]);

  const handleDateRange = (value) => {
    setDuration(value);
    setShowDateDrawer(false);
  };

  const handleSave = () => {
    onClose();
    dispatch(
      campaignActions.runAgainCampaign({
        campaignId: campaign.id,
        tags,
        startDate: duration[0].toISOString(),
        endDate: duration[1].toISOString(),
        onSuccess: () => {
          onSuccess();
        },
      }),
    );
  };

  const onAdvanced = () => {
    navigate(routes.campaigns.build(), {
      state: { id: campaign.id, type: 'runagain' },
    });
  };

  return (
    <MatDrawer
      open={open}
      anchor="right"
      onClose={onClose}
      onCancel={onClose}
      onSubmit={handleSave}
      title="Run Campaign Again"
      submitBtn="Create"
      cancelBtn="Cancel"
      isLoading={runAgainLoading}
    >
      {campaign && (
        <Box maxWidth={450} height="100%" display="flex" flexDirection="column">
          <Box display="flex" width="100%" alignItems="center">
            {campaign.image && (
              <Box
                borderRadius={1}
                width={200}
                height={100}
                bgcolor={Colors.lightBlueGray}
                mr={1.5}
                textAlign="center"
              >
                <img
                  alt="Campaign"
                  src={campaign.image}
                  style={{
                    height: '100%',
                    objectFit: 'contain',
                    borderRadius: 4,
                  }}
                />
              </Box>
            )}
            <Typography variant="h6">{campaign.title}</Typography>
          </Box>
          <Box
            width="100%"
            sx={{
              cursor: 'pointer',
            }}
            mt={3}
            mb={3}
            onClick={() => setShowDateDrawer(true)}
            onKeyDown={() => setShowDateDrawer(true)}
            tabIndex={0}
            role="button"
          >
            <MatFormLabel
              icon={
                <Tooltip title="Set the duration that this campaign will run for">
                  <AlertIcon />
                </Tooltip>
              }
            >
              Duration
            </MatFormLabel>
            <Box
              width="100%"
              pt={0.5}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <DateRangeIcon sx={{ marginRight: 1 }} />
              <Typography variant="subtitle1">
                {`${duration[0].format('MMM D, YYYY')} - ${duration[1].format(
                  'MMM D, YYYY',
                )}`}
              </Typography>
            </Box>
          </Box>

          <FormControl variant="outlined" margin="dense">
            <MatFormLabel
              icon={
                <Tooltip title="Enter tags or keywords to be associated with this campaign">
                  <AlertIcon />
                </Tooltip>
              }
            >
              Tags
            </MatFormLabel>

            {/* <MatChip
              value={tags}
              onAdd={(tag) => handleAddTag(tag)}
              onDelete={(_, index) => handleRemoveTag(index)}
              fullWidth={true}
              placeholder="Tags"
            /> */}

            <Autocomplete
              multiple
              id="tags-filled"
              options={[]}
              defaultValue={tags}
              freeSolo
              onChange={(event, newInputValue) => {
                setTags([...newInputValue, ...tags]);
              }}
              renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => (
                  <Chip
                    color="primary"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" placeholder="Tags" />
              )}
            />
          </FormControl>

          <Box
            sx={{
              overflowY: 'auto',
            }}
            flexGrow={1}
            pt={3}
            pb={3}
          >
            <Typography variant="subtitle2">{campaign.description}</Typography>
          </Box>

          <Box width="100%" display="flex" justifyContent="end" mt={1}>
            <Button
              variant="text"
              onClick={onAdvanced}
              style={{
                width: 150,
                color: Colors.primary,
              }}
            >
              Advanced
            </Button>
          </Box>
        </Box>
      )}
      <DateRangeDrawer
        open={showDateDrawer}
        onClose={() => setShowDateDrawer(false)}
        onSave={handleDateRange}
      />
    </MatDrawer>
  );
};

RunAgainDrawer.propTypes = {
  campaign: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

RunAgainDrawer.defaultProps = {
  campaign: null,
};

export default RunAgainDrawer;

import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function BasicMenu({
  folder,
  handleClickEdit,
  anchorElFolderMenu,
  openFolderMenu,
  handleCloseFolderMenu,
  handleClickOpenAlertDialog,
}) {
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorElFolderMenu}
      open={openFolderMenu}
      onClose={handleCloseFolderMenu}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem
        onClick={() => {
          handleClickEdit(folder);
          handleCloseFolderMenu();
        }}
      >
        Edit
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleClickOpenAlertDialog();
          handleCloseFolderMenu();
        }}
      >
        Delete
      </MenuItem>
    </Menu>
  );
}

BasicMenu.propTypes = {
  folder: PropTypes.object.isRequired,
  handleClickEdit: PropTypes.func.isRequired,
  anchorElFolderMenu: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.elementType,
  ]).isRequired,
  openFolderMenu: PropTypes.bool.isRequired,
  handleCloseFolderMenu: PropTypes.func.isRequired,
  handleClickOpenAlertDialog: PropTypes.func.isRequired,
};

const desc = (a, b, orderBy) => {
  if (orderBy === 'end_date') {
    if (b[orderBy].iso < a[orderBy].iso) {
      return -1;
    }
    if (b[orderBy].iso > a[orderBy].iso) {
      return 1;
    }
    return 0;
  } else if (
    orderBy === 'viewed' ||
    orderBy === 'liked' ||
    orderBy === 'disliked'
  ) {
    let aSort = 0;
    if (a[orderBy]) {
      aSort = a[orderBy].length;
    }
    let bSort = 0;
    if (b[orderBy]) {
      bSort = b[orderBy].length;
    }

    if (bSort < aSort) {
      return -1;
    }
    if (bSort > aSort) {
      return 1;
    }
    return 0;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const getSorting = (order, orderBy) => {
  if (order === 'desc') {
    return (a, b) => desc(a, b, orderBy);
  }
  return (a, b) => -desc(a, b, orderBy);
};

import React from 'react';
import Box from '@mui/material/Box';

function SVG8() {
  return (
    <div
      id="SVG8"
      style={{
        height: '70px',
        width: '70px',
        display: 'flex',
      }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          height: '45px',
          width: '45px',
          stroke: '#ffffff',
          strokeWidth: 2.0,
          margin: 'auto',
        }}
      >
        <path
          d="M19.26 18.9V7.1C19.26 5.6 18.62 5 17.03 5H15.99C14.4 5 13.76 5.6 13.76 7.1V18.9M5.26 18.9V12.1C5.26 10.6 5.9 10 7.49 10H8.53C10.12 10 10.76 10.6 10.76 12.1V18.9M2 19H22"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export default SVG8;

import React from 'react';
import Box from '@mui/material/Box';

function SVG7() {
  return (
    <div
      id="SVG7"
      style={{
        height: '70px',
        width: '70px',
        display: 'flex',
      }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          height: '45px',
          width: '45px',
          stroke: '#ffffff',
          strokeWidth: 2.0,
          margin: 'auto',
        }}
      >
        <path
          d="M2 22H22M12 2V3M4 4L5 5M20 4L19 5M12 6C9.61305 6 7.32387 6.94821 5.63604 8.63604C3.94821 10.3239 3 12.6131 3 15V22H21V15C21 12.6131 20.0518 10.3239 18.364 8.63604C16.6761 6.94821 14.3869 6 12 6Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export default SVG7;

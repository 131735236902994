const checkErrors = (data) => {
  const { type } = data;
  const errors: any = [];
  switch (type) {
    case 'info':
      // Description
      if (!data?.info?.description) {
        errors.push(`Missing Description`);
      }

      // Image
      if (data?.info?.image?.url === null || data?.info?.image?.url === '') {
        errors.push(`Missing Image`);
      }

      // Action One
      if (data?.info.ctaOne.type === 'redirect') {
        if (data?.info?.ctaOne?.name === null || data?.info?.ctaOne?.name === '') {
          errors.push(`Action One missing button text`);
        }
        if (data?.info?.ctaOne?.link === null || data?.info?.ctaOne?.link === '') {
          errors.push(`Action One missing redirct URL`);
        }
      } else if (data?.info?.ctaOne?.type === 'event') {
        if (
          data?.info?.ctaOne?.startDate === null ||
          typeof data?.info?.ctaOne?.startDate === 'undefined' ||
          data?.info?.ctaOne?.startDate === ''
        ) {
          errors.push(`Action One missing start date`);
        }
        if (
          data?.info?.ctaOne?.endDate === null ||
          typeof data?.info?.ctaOne?.endDate === 'undefined' ||
          data?.info?.ctaOne?.endDate === ''
        ) {
          errors.push(`Action One missing end date`);
        }
      }

      // Action Two
      if (data?.info.ctaTwo.type === 'redirect') {
        if (data?.info.ctaTwo.name === null || data?.info.ctaTwo.name === '') {
          errors.push(`Action Two missing button text`);
        }
        if (data?.info.ctaTwo.link === null || data?.info.ctaTwo.link === '') {
          errors.push(`Action Two missing redirct URL`);
        }
      } else if (data?.info.ctaTwo.type === 'event') {
        if (
          data?.info.ctaTwo.startDate === null ||
          typeof data?.info.ctaTwo.startDate === 'undefined' ||
          data?.info.ctaTwo.startDate === ''
        ) {
          errors.push(`Action Two missing start date`);
        }
        if (
          data?.info.ctaTwo.endDate === null ||
          typeof data?.info.ctaTwo.endDate === 'undefined' ||
          data?.info.ctaTwo.endDate === ''
        ) {
          errors.push(`Action Two missing end date`);
        }
      }
      return errors;
    case 'image':
      // Image
      if (data?.info?.image?.url === null || data?.info?.image?.url === '') {
        errors.push(`Missing Image`);
      }

      // Action
      if (data?.info.cta.type === 'redirect') {
        if (data?.info.cta.name === null || data?.info.cta.name === '') {
          errors.push(`Action missing button text`);
        }
        if (data?.info.cta.link === null || data?.info.cta.link === '') {
          errors.push(`Action missing redirct URL`);
        }
      } else if (data?.info.cta.type === 'event') {
        if (
          data?.info.cta.startDate === null ||
          typeof data?.info.cta.startDate === 'undefined' ||
          data?.info.cta.startDate === ''
        ) {
          errors.push(`Action missing start date`);
        }
        if (
          data?.info.cta.endDate === null ||
          typeof data?.info.cta.endDate === 'undefined' ||
          data?.info.cta.endDate === ''
        ) {
          errors.push(`Action missing end date`);
        }
      }
      return errors;
    case 'video':
      // Link
      if (data?.info.code === null || data?.info.code === '') {
        errors.push(`Missing video link`);
      }
      return errors;
    case 'button':
      // Text
      if (data?.info.cta.type === 'redirect') {
        if (!data?.info.cta.name) {
          errors.push(`Missing button text`);
        }
        if (!data?.info.cta.link) {
          errors.push(`Missing redirect link`);
        }
      }
      return errors;
    case 'social':
      if (
        !data?.info.facebook &&
        !data?.info.twitter &&
        !data?.info.linkedin &&
        !data?.info.instagram
      ) {
        errors.push(`Must enter at least one social media site`);
      }

      return errors;
    case 'form':
      // Title
      if (data?.info.title === null || data?.info.title === '') {
        errors.push(`Missing title`);
      }

      // Fields
      if (data?.info.fields === null || data?.info.fields.length < 0) {
        errors.push(`Missing form fields`);
      } else {
        for (let x = 0; x < data?.info.fields.length; x++) {
          if (data?.info.fields[x] === '') {
            errors.push(`Incomplete field`);
          }
        }
      }

      return errors;
    case 'poll':
      // Question
      if (data?.info.question === null || data?.info.question === '') {
        errors.push(`Missing Poll Question`);
      }

      // Fields
      if (data?.info.answers === null || data?.info.answers.length < 2) {
        errors.push(`Missing Poll Answers`);
      } else {
        for (let x = 0; x < data?.info.answers.length; x++) {
          if (data?.info.answers[x] === null || data?.info.answers[x] === '') {
            errors.push(`Incomplete Poll Answer`);
          }
        }
      }

      return errors;
    case 'carousel':
      // Images
      if (data?.info.images === null || data?.info.images.length < 1) {
        errors.push(`Missing images`);
      } else {
        let missing = false;
        for (let x = 0; x < data?.info.images.length; x++) {
          if (!data?.info?.images[x]?.image?.url && !missing) {
            errors.push(`Missing image`);
            missing = true;
          }
        }
      }

      // Action One
      if (data?.info.ctaOne.type === 'redirect') {
        if (data?.info.ctaOne.name === null || data?.info.ctaOne.name === '') {
          errors.push(`Action One missing button text`);
        }
        if (data?.info.ctaOne.link === null || data?.info.ctaOne.link === '') {
          errors.push(`Action One missing redirct URL`);
        }
      } else if (data?.info.ctaOne.type === 'event') {
        if (
          data?.info.ctaOne.startDate === null ||
          typeof data?.info.ctaOne.startDate === 'undefined' ||
          data?.info.ctaOne.startDate === ''
        ) {
          errors.push(`Action One missing start date`);
        }
        if (
          data?.info.ctaOne.endDate === null ||
          typeof data?.info.ctaOne.endDate === 'undefined' ||
          data?.info.ctaOne.endDate === ''
        ) {
          errors.push(`Action One missing end date`);
        }
      }

      // Action Two
      if (data?.info.ctaTwo.type === 'redirect') {
        if (data?.info.ctaTwo.name === null || data?.info.ctaTwo.name === '') {
          errors.push(`Action Two missing button text`);
        }
        if (data?.info.ctaTwo.link === null || data?.info.ctaTwo.link === '') {
          errors.push(`Action Two missing redirct URL`);
        }
      } else if (data?.info.ctaTwo.type === 'event') {
        if (
          data?.info.ctaTwo.startDate === null ||
          typeof data?.info.ctaTwo.startDate === 'undefined' ||
          data?.info.ctaTwo.startDate === ''
        ) {
          errors.push(`Action Two missing start date`);
        }
        if (
          data?.info.ctaTwo.endDate === null ||
          typeof data?.info.ctaTwo.endDate === 'undefined' ||
          data?.info.ctaTwo.endDate === ''
        ) {
          errors.push(`Action Two missing end date`);
        }
      }

      return errors;
    case 'mailchimp':
      // List ID
      if (!data?.info.listId) {
        errors.push(`Missing Mailchimp Audience`);
      }

      return errors;
    default:
      return errors;
  }
};

export default checkErrors;

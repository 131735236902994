import React from 'react';

import Colors from 'constants/colors';

import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

import MultiActionDot from './MultiActionDot';

const ImageCarousel = ({ data, results }) => {
  const renderButton = (action) => {
    switch (action.type) {
      case 'redirect':
        return (
          <Button
            color="primary"
            variant={action.styling?.buttonVariant}
            disableElevation
            onClick={() => {
              const link = action.link.includes('https://')
                ? action.link
                : `https://${action.link}`;
              const win = window.open(link);
              win?.focus();
            }}
            sx={{
              borderRadius: action.styling?.buttonBorder,
              color: action.styling?.textButtonColor,
              height: 22,
              marginBottom: 1.25,
              marginLeft: 1,
              borderColor:
                action.styling?.buttonVariant === 'outlined'
                  ? action.styling?.buttonColor
                  : '',
              backgroundColor:
                action.styling?.buttonVariant === 'contained'
                  ? action.styling?.buttonColor
                  : '',
            }}
          >
            <Typography sx={{ fontSize: 8 }}>{action.name}</Typography>
          </Button>
        );
      case 'event':
        return (
          <Button
            color="primary"
            sx={{ borderRadius: 8, height: 20 }}
            disableElevation
            endIcon={<EventOutlinedIcon />}
          >
            <Typography sx={{ fontSize: 8, color: Colors.primary }}>
              Add to Calendar
            </Typography>
          </Button>
        );
      default:
        return <div />;
    }
  };

  return (
    <Box
      width="100%"
      position="relative"
      sx={{ borderRadius: data?.styling?.cardBorder }}
    >
      <div>
        <Box
          display="flex"
          width="100%"
          pl={2}
          sx={{
            overflowX: 'auto',
            scrollbarWidth: 'none',
          }}
        >
          {data.images !== null && data.images.length > 0
            ? data.images.map((image) => (
                <Box
                  width="80%"
                  minWidth="80%"
                  mr={2}
                  color={data?.styling?.mainColor}
                  textAlign={data?.styling?.mainAlignment}
                  fontSize={data?.styling?.mainSize}
                  fontStyle={
                    data?.styling?.mainFormat?.includes('italic')
                      ? 'italic'
                      : ''
                  }
                  fontWeight={
                    data?.styling?.mainFormat?.includes('bold') ? 'bold' : ''
                  }
                  sx={{
                    textDecoration: data?.styling?.mainFormat?.includes(
                      'underline',
                    )
                      ? 'underline'
                      : '',
                  }}
                >
                  <img
                    style={{
                      width: '100%',
                      height: 150,
                      objectFit: 'cover',
                      borderRadius: 2.5,
                    }}
                    src={image?.image?.url}
                    alt="selected carousel data"
                  />
                  <Typography variant="subtitle1">
                    {image.description}
                  </Typography>
                </Box>
              ))
            : null}
        </Box>
        <Box display="flex" width="100%" pl={2} mt={1.25} mb={3}>
          {renderButton(data.ctaOne)}
          {renderButton(data.ctaTwo)}
        </Box>
      </div>
      {results !== null && results.actionOne + results.actionTwo > 0 && (
        <MultiActionDot
          actionOne={results.actionOne}
          actionTwo={results.actionTwo}
        />
      )}
    </Box>
  );
};

ImageCarousel.propTypes = {
  data: PropTypes.object.isRequired,
  results: PropTypes.object,
};

ImageCarousel.defaultProps = {
  results: null,
};

export default ImageCarousel;

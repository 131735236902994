import Parse, { Object, JSONBaseAttributes, Pointer } from 'parse'
import { createPointer } from 'store/utils/pointers';

export interface AnnoucementAttributes{
  photo_url: string
  details: string
  createdBy_point: Pointer
  business_point: Pointer
  end_date: Date
  team_member_ids: string[]
  title: string
  createdAt?: Date
  objectId?: string
}

export type IAnnoucement = Object.ToJSON<AnnoucementAttributes> & JSONBaseAttributes;

class Annoucement extends Parse.Object<AnnoucementAttributes> {
  static className = 'announcement'

  constructor(init: AnnoucementAttributes) {
    super(Annoucement.className, init);
  }

  static getPointer(objectId: string): Pointer {
    return createPointer(Annoucement.className, objectId)
  }
}

export default Annoucement;

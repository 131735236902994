import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';
import Permissions from 'constants/permissions';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';

import { ReactComponent as SettingsIcon } from 'assets/images/settings_icon.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import routes from 'routes';
import { campaignActions } from 'store/entities/Campaigns';

import MatInput from 'components/Elements/MatInput/MatInput';
import EmptyPage from 'components/EmptyPage';
import HomeContainer from 'components/ui/HomeContainer';
import Scaffold from 'components/ui/Scaffold';

import CampaignFilterDrawer from './components/CampaignFilterDrawer';
import CampaignList from './components/CampaignList/CampaignList';
import SavedList from './components/SavedList';
import { CircularProgress, Collapse, LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import CardPreview from 'components/CardPreview/CardPreview';
import { templatesActions, TemplatesSelectors } from 'store/entities/Templates';

const CreateCampaignButton: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Button
      variant="contained"
      component={Link}
      to={routes.templates.build()}
      startIcon={<AddIcon />}
    >
      {t('create')}
    </Button>
  );
};

const Templates = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [templates, setTemplates] = useState<any>([]);
  const { vendorId, user } = useSelector((state: any) => state.user);
  const { templatesVendor } = useSelector((state: any) => state.templates);
  const activeTemplates = useSelector(TemplatesSelectors.getActiveTemplates);
  const inActiveTemplates = useSelector(
    TemplatesSelectors.getInActiveTemplates,
  );

  useEffect(() => {
    dispatch(templatesActions.getTemplatesByVendor());
  }, []);

  useEffect(() => {
    if (activeTemplates?.length) {
      setTemplates(activeTemplates);
    }
  }, [activeTemplates]);

  const isVendor = () => {
    if (vendorId) {
      return true;
    }
    return false;
  };

  const isLoading = () => {
    if (typeof templates === 'undefined') {
      return true;
    }
    return false;
  };

  const checkEmptyPage = () => {
    if (activeTemplates?.length > 0 || inActiveTemplates?.length > 0) {
      return true;
    }
    return false;
  };

  return (
    <Scaffold
      HeaderProps={{
        title: 'Templates',
        actions: [
          isVendor() && user?.is_active ? <CreateCampaignButton /> : <></>,
        ],
      }}
    >
      {isLoading() ? (
        <Collapse in={true}>
          <LinearProgress />
        </Collapse>
      ) : checkEmptyPage() ? (
        <HomeContainer>
          <Grid container spacing={1}>
            {activeTemplates?.length ? (
              <>
                <Grid item md={12} sx={{ flex: 1 }}>
                  <Typography variant="h6">{t('active')}</Typography>
                </Grid>
                {activeTemplates?.map((card, index) => (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={4}
                    xl={2}
                    key={`template-${card.title}`}
                  >
                    <CardPreview card={card} />
                  </Grid>
                ))}
              </>
            ) : null}
            {inActiveTemplates?.length ? (
              <>
                <Grid item md={12} sx={{ flex: 1 }}>
                  <Typography variant="h6">{t('Inactive')}</Typography>
                </Grid>
                {inActiveTemplates?.map((card, index) => (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={4}
                    xl={2}
                    key={`template-${card.title}`}
                  >
                    <CardPreview card={card} />
                  </Grid>
                ))}
              </>
            ) : null}
          </Grid>
        </HomeContainer>
      ) : (
        <EmptyPage
          actions={[<CreateCampaignButton />]}
          title="Templates"
          description={
            user?.is_active
              ? 'Create a new Template'
              : 'This account is currently disabled.'
          }
          permissions={[]}
          isActive={user?.is_active || false}
          isVendor={isVendor()}
        />
      )}
    </Scaffold>
  );
};

export default Templates;

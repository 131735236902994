import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import InputAdornment from '@mui/material/InputAdornment';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { imagesActions } from 'store/entities/Images';

import MatInput from '../Elements/MatInput/MatInput';

import GiphyPhotos from './components/GiphyPhotos';
import ImageTabBar from './components/ImageTabBar';
import LibraryPhotos from './components/LibraryPhotos';
import LoadingBar from './components/LoadingBar';
import UnsplashPhotos from './components/UnsplashPhotos';

const TYPES = [
  {
    name: 'Library',
    index: 0,
  },
  {
    name: 'Photos',
    index: 1,
  },
  {
    name: 'Gifs',
    index: 2,
  },
];

const FullImageSelector = ({ onSelect, defaultImage }) => {
  const dispatch = useDispatch();
  const [selectedIndicator, setSelectedIndicator] = useState(TYPES[0]);
  const [search, setSearch] = useState('');

  const {
    libraryPhotos,
    unsplashPhotos,
    giphyPhotos,
    libraryLoading,
    unsplashLoading,
    giphyLoading,
  } = useSelector((state: RootState) => state.images);

  useEffect(() => {
    dispatch(imagesActions.getLibraryPhotos());
  }, []);

  const handleTabChange = (index) => {
    setSelectedIndicator(TYPES[index]);
  };

  const handleImageSelect = (result) => {
    onSelect(result);
  };

  const handleSearch = () => {
    switch (selectedIndicator.index) {
      case TYPES[1].index:
        dispatch(imagesActions.searchUnsplash({ query: search }));
        break;
      case TYPES[2].index:
        dispatch(imagesActions.searchGiphy({ query: search }));
        break;
      default:
        break;
    }
  };

  const renderImageGrid = () => {
    switch (selectedIndicator.index) {
      case TYPES[0].index:
        return (
          <div>
            <Box width="100%" height={250} mt={1}>
              <LibraryPhotos
                photos={libraryPhotos}
                onSelect={handleImageSelect}
                defaultImage={defaultImage}
              />
              <LoadingBar
                text="Refreshing library photos"
                isLoading={libraryLoading}
              />
            </Box>
          </div>
        );
      case TYPES[1].index:
        return (
          <div>
            <Box width="100%" height={250} mt={1}>
              <UnsplashPhotos
                photos={unsplashPhotos}
                onSelect={handleImageSelect}
              />
              <LoadingBar
                text="Searching for photos"
                isLoading={unsplashLoading}
              />
            </Box>
          </div>
        );
      case TYPES[2].index:
        return (
          <div>
            <Box width="100%" height={250} mt={1}>
              <GiphyPhotos photos={giphyPhotos} onSelect={handleImageSelect} />
              <LoadingBar text="Searching for gifs" isLoading={giphyLoading} />
            </Box>
          </div>
        );
      default:
        return <div />;
    }
  };

  return (
    <div>
      <Box flexGrow={1} mb={2}>
        <MatInput
          variant="outlined"
          disabled={selectedIndicator.index === TYPES[0].index}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search"
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              handleSearch();
            }
          }}
          sx={{
            backgroundColor: '#EEF0F2',
            borderRadius: '10px',
            mb: 2,
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '& .MuiInputBase-root': {
              borderRadius: '10px',
              px: 0,
              py: 0.5,
            },
          }}
          value={search}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton size="large">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">
                {search.length > 0 ? (
                  <IconButton onClick={() => setSearch('')} size="large">
                    <ClearIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => handleSearch()} size="large">
                    <ArrowCircleRightOutlinedIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box borderRadius={4} p={3} borderColor="#E2E4E5" border={0.8}>
        <ImageTabBar tab={selectedIndicator.index} onChange={handleTabChange} />
        {renderImageGrid()}
      </Box>
    </div>
  );
};

FullImageSelector.propTypes = {
  onSelect: PropTypes.func.isRequired,
  defaultImage: PropTypes.string,
};

FullImageSelector.defaultProps = {
  defaultImage: null,
};

export default FullImageSelector;

import React from 'react';

import Colors from 'constants/colors';

import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import LoyaltyOutlinedIcon from '@mui/icons-material/LoyaltyOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

const MissingData = ({ icon, message }) => {
  const renderIcon = () => {
    if (icon === 0) {
      return (
        <LoyaltyOutlinedIcon
          sx={{
            color: Colors.primaryDark,
            width: 125,
            height: 125,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: 2.5,
          }}
        />
      );
    } else if (icon === 1) {
      return (
        <AnnouncementOutlinedIcon
          sx={{
            color: Colors.primaryDark,
            width: 125,
            height: 125,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: 2.5,
          }}
        />
      );
    } else if (icon === 2) {
      return (
        <GroupOutlinedIcon
          sx={{
            color: Colors.primaryDark,
            width: 125,
            height: 125,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: 2.5,
          }}
        />
      );
    }
    return (
      <RoomOutlinedIcon
        sx={{
          color: Colors.primaryDark,
          width: 125,
          height: 125,
          marginLeft: 'auto',
          marginRight: 'auto',
          marginBottom: 2.5,
        }}
      />
    );
  };

  return (
    <Box width="100%" pl={6.25} pr={6.25} mt={18.75} textAlign="center">
      {renderIcon}
      <Typography variant="h6">{message}</Typography>
    </Box>
  );
};

MissingData.propTypes = {
  message: PropTypes.string.isRequired,
  icon: PropTypes.number.isRequired,
};

export default MissingData;

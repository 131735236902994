import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';

import DeleteIcon from '@mui/icons-material/Delete';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

import MatInput from 'components/Elements/MatInput/MatInput';

const FACEBOOK_URL = 'https://www.facebook.com/';
const TWITTER_URL = 'https://twitter.com/';
const LINKEDIN_URL = 'https://www.linkedin.com/';
const INSTAGRAM_URL = 'https://www.instagram.com/';

const SocialBuilder = ({ item, onUpdate, cardId, onDelete, tabIndex }) => {
  const [facebook, setFacebook] = useState(item.facebook);
  const [twitter, setTwitter] = useState(item.twitter);
  const [linkedin, setLinkedin] = useState(item.linkedin);
  const [instagram, setInstagram] = useState(item.instagram);
  const [facebookError, setFacebookError] = useState(false);
  const [twitterError, setTwitterError] = useState(false);
  const [linkedinError, setLinkedinError] = useState(false);
  const [instagramError, setInstagramError] = useState(false);

  useEffect(() => {
    if (
      item.facebook !== facebook ||
      item.twitter !== twitter ||
      item.linkedin !== linkedin ||
      item.instagram !== instagram
    ) {
      setFacebook(item.facebook);
      setTwitter(item.twitter);
      setLinkedin(item.linkedin);
      setInstagram(item.instagram);
    }
  }, [item]);

  const handleFacebook = (e) => {
    const { value } = e.target;
    setFacebook(value);
    setFacebookError(false);
    const object = item;
    if (value.length > 26) {
      if (value.substring(0, 25) === FACEBOOK_URL) {
        setFacebookError(false);
        object.facebook = value;
      } else {
        setFacebookError(true);
        object.facebook = '';
      }
    } else {
      object.facebook = '';
    }
    onUpdate(object);
  };

  const handleTwitter = (e) => {
    const { value } = e.target;
    setTwitter(value);
    setTwitterError(false);
    const object = item;
    if (value.length > 21) {
      if (value.substring(0, 20) === TWITTER_URL) {
        setTwitterError(false);
        object.twitter = value;
      } else {
        setTwitterError(true);
        object.twitter = '';
      }
    } else {
      object.twitter = '';
    }
    onUpdate(object);
  };

  const handleLinkedin = (e) => {
    const { value } = e.target;
    setLinkedin(value);
    setLinkedinError(false);
    const object = item;
    if (value.length > 29) {
      if (value.substring(0, 25) === LINKEDIN_URL) {
        setLinkedinError(false);
        object.linkedin = value;
      } else {
        setLinkedinError(true);
        object.linkedin = '';
      }
    } else {
      object.linkedin = '';
    }
    onUpdate(object);
  };

  const handleInstagram = (e) => {
    const { value } = e.target;
    setInstagram(value);
    setInstagramError(false);
    const object = item;
    if (value.length > 27) {
      if (value.substring(0, 26) === INSTAGRAM_URL) {
        setInstagramError(false);
        object.instagram = value;
      } else {
        setInstagramError(true);
        object.instagram = '';
      }
    } else {
      object.instagram = '';
    }
    onUpdate(object);
  };

  const renderItems = () => {
    switch (tabIndex) {
      case 1:
        return (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                marginBottom: 2,
              }}
            >
              <FacebookIcon
                sx={{
                  marginRight: 1,
                  width: 30,
                  height: 30,
                  marginBottom: 2.5,
                }}
              />
              <MatInput
                value={facebook}
                variant="outlined"
                placeholder="Facebook"
                onChange={handleFacebook}
                size="small"
                error={facebookError}
                helperText="https://www.facebook.com/your-profile"
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                marginBottom: 2,
              }}
            >
              <TwitterIcon
                sx={{
                  marginRight: 1,
                  width: 30,
                  height: 30,
                  marginBottom: 2.5,
                }}
              />
              <MatInput
                value={twitter}
                variant="outlined"
                placeholder="Twitter"
                onChange={handleTwitter}
                size="small"
                error={twitterError}
                helperText="https://twitter.com/your-profile"
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                marginBottom: 2,
              }}
            >
              <LinkedInIcon
                sx={{
                  marginRight: 1,
                  width: 30,
                  height: 30,
                  marginBottom: 2.5,
                }}
              />
              <MatInput
                value={linkedin}
                variant="outlined"
                placeholder="LinkedIn"
                onChange={handleLinkedin}
                size="small"
                error={linkedinError}
                helperText="https://www.linkedin.com/in/your-profile"
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                marginBottom: 2,
              }}
            >
              <InstagramIcon
                sx={{
                  marginRight: 1,
                  width: 30,
                  height: 30,
                  marginBottom: 2.5,
                }}
              />
              <MatInput
                value={instagram}
                variant="outlined"
                placeholder="Instagram"
                onChange={handleInstagram}
                size="small"
                error={instagramError}
                helperText="https://www.instagram.com/your-profile"
              />
            </Box>
          </>
        );
        break;
      case 3:
        return null;
        break;
      case 5:
        return null;
        break;
      case 7:
        return null;
        break;
      default:
        return (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                marginBottom: 2,
              }}
            >
              <FacebookIcon
                sx={{
                  marginRight: 1,
                  width: 30,
                  height: 30,
                  marginBottom: 2.5,
                }}
              />
              <MatInput
                value={facebook}
                variant="outlined"
                placeholder="Facebook"
                onChange={handleFacebook}
                size="small"
                error={facebookError}
                helperText="https://www.facebook.com/your-profile"
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                marginBottom: 2,
              }}
            >
              <TwitterIcon
                sx={{
                  marginRight: 1,
                  width: 30,
                  height: 30,
                  marginBottom: 2.5,
                }}
              />
              <MatInput
                value={twitter}
                variant="outlined"
                placeholder="Twitter"
                onChange={handleTwitter}
                size="small"
                error={twitterError}
                helperText="https://twitter.com/your-profile"
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                marginBottom: 2,
              }}
            >
              <LinkedInIcon
                sx={{
                  marginRight: 1,
                  width: 30,
                  height: 30,
                  marginBottom: 2.5,
                }}
              />
              <MatInput
                value={linkedin}
                variant="outlined"
                placeholder="LinkedIn"
                onChange={handleLinkedin}
                size="small"
                error={linkedinError}
                helperText="https://www.linkedin.com/in/your-profile"
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                marginBottom: 2,
              }}
            >
              <InstagramIcon
                sx={{
                  marginRight: 1,
                  width: 30,
                  height: 30,
                  marginBottom: 2.5,
                }}
              />
              <MatInput
                value={instagram}
                variant="outlined"
                placeholder="Instagram"
                onChange={handleInstagram}
                size="small"
                error={instagramError}
                helperText="https://www.instagram.com/your-profile"
              />
            </Box>
          </>
        );
        break;
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        textAlign: 'left',
        backgroundColor: Colors.white,
        borderRadius: 2,
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: 1,
        paddingBottom: 2,
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          marginBottom: 2,
        }}
      >
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          {`Edit Card ${cardId}`}
        </Typography>
        <IconButton
          aria-label="delete"
          onClick={onDelete}
          size="large"
          sx={{
            color: Colors.error,
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
      {renderItems()}
    </Box>
  );
};

SocialBuilder.propTypes = {
  tabIndex: PropTypes.number,
  item: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  cardId: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default SocialBuilder;

import React from 'react';

import Colors from 'constants/colors';

import { Box } from '@mui/material';

import { MdQrCodeScanner } from 'react-icons/md';

const StatusCell = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <Box
      height={64}
      width={64}
      mr={2.5}
      bgcolor={Colors.primary}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <MdQrCodeScanner style={{ width: 32, height: 32, color: Colors.white }} />
    </Box>
  </Box>
);

export default StatusCell;

import React from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import PropTypes from 'prop-types';

import ActionDot from './ActionDot';

const PremiumButton = ({ data, results }) => {
  const getText = () => {
    if (data.version && data.version === 2) {
      switch (data.cta.type) {
        case 'redirect':
          return data.cta.name;
        case 'event':
          return 'Add to Calendar';
        case 'share':
          return 'Share';
        default:
          return 'CTA';
      }
    }
    return data.cta;
  };

  return (
    <Box width="100%" pl={2} pr={2} position="relative">
      <Button
        fullWidth
        variant="contained"
        onClick={() => {
          const link = data.cta.link.includes('https://')
            ? data.cta.link
            : `https://${data.cta.link}`;
          const win = window.open(link);
          win?.focus();
        }}
        sx={{
          borderRadius: data.styling?.buttonBorder,
          color: data.styling?.textButtonColor,
          height: 40,
          marginBottom: 1.25,
          marginLeft: 1,
          backgroundColor: data?.styling?.buttonColor
            ? data?.styling?.buttonColor
            : '#2085ff',
        }}
      >
        {getText()}
      </Button>
      {results !== null && results.actionOne > 0 && (
        <ActionDot count={results.actionOne} />
      )}
    </Box>
  );
};

PremiumButton.propTypes = {
  data: PropTypes.object.isRequired,
  results: PropTypes.object,
};

PremiumButton.defaultProps = {
  results: null,
};

export default PremiumButton;

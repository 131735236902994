import { RootState } from 'store';

export const getTeamMemberState = (state) => state.teamMembersState;
export const getLocationState = (state) => state.locationsState;
export const getCampaignState = (state) => state.campaignsState;
export const getUserState = (state) => state.user;

export const selectState = (state: RootState) => state;
export const selectBusinessesState = (state: RootState) => state.businesses;
export const selectSubscriptionsState = (state: RootState) =>
  state.subscriptions;
export const selectMembersState = (state: RootState) => state.members;
export const selectInvitsState = (state: RootState) => state.invits;
export const selectTeamsState = (state: RootState) => state.teams;
export const selectTeamHubState = (state: RootState) => state.teamHub;
export const selectUserState = (state: RootState) => state.user;
export const selectAnnouncementsState = (state: RootState) =>
  state?.announcements;
export const selectCampaignsState = (state: RootState) => state?.campaigns;
export const selectTemplatesState = (state: RootState) => state?.templates;

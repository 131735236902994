import React from 'react';

import {
  Button,
  Divider,
  IconButton,
  Modal,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import EditIcon from '@mui/icons-material/Edit';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import BrushIcon from '@mui/icons-material/Brush';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import ToolBarDrawer from './ToolBarDrawer';
import { Save } from '@mui/icons-material';
import { styled } from '@mui/styles';

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const ToolBarCampaign = ({
  setValue,
  value,
  type,
  errors,
  create,
  save,
  activeStep,
  back,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [openModal, setOpenModal] = React.useState(false);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      color: 'rgba(255, 255, 255, 0.7)',
      '&.Mui-selected': {
        color: '#fff',
      },
    }),
  );

  return (
    <Box>
      <Box display="flex" alignItems="center">
        {/* <Divider /> */}
        {/* {errors.length > 0 && (
          <IconButton
            sx={{ borderRadius: 0 }}
            aria-label="save"
            size="large"
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            onClick={handleOpen}
          >
            <InfoIcon fontSize="medium" color="error" />
          </IconButton>
        )} */}
        {/* <Divider /> */}
        {activeStep === 0 ? (
          <>
            {/* <Tooltip title="Save Heam Hub card" placement="left-start">
                <IconButton
                  sx={{ width: '100%', borderRadius: 0 }}
                  onClick={errors.length > 0 ? handleOpen : back}
                  color="primary"
                  aria-label="save"
                  size="large"
                >
                  <Save />
                </IconButton>
              </Tooltip> */}
          </>
        ) : (
          <Tooltip title="Back to builder" placement="left-start">
            <Button onClick={back} size="large" variant="text" color="primary">
              Back
            </Button>
          </Tooltip>
        )}
        {/* <Divider /> */}
        <Tooltip
          title={
            activeStep === 0 ? 'Preview Team Hub card' : 'Create Team Hub card'
          }
          placement="left-start"
        >
          <Button
            onClick={errors.length > 0 ? handleOpen : create}
            size="small"
            variant="text"
            sx={{
              px: 3,
              py: 1,
              ml: 2,
              textTransform: 'capitalize',
              height: 'fit-content',
              borderRadius: '8px',
              background:
                activeStep === 0 ? '#007bff6a' : 'rgba(0, 202, 81, 0.15)',
              color: activeStep === 0 ? '#fff' : '#00CA51',
              '&:focus': {
                background:
                  activeStep === 0 ? '#007bff6a' : 'rgba(0, 202, 81, 0.15)',
                color: activeStep === 0 ? '#fff' : '#00CA51',
              },
              '&:hover': {
                background:
                  activeStep === 0 ? '#007bff6a' : 'rgba(0, 202, 81, 0.15)',
                color: activeStep === 0 ? '#fff' : '#00CA51',
              },
            }}
          >
            {activeStep === 0 ? 'Preview' : 'Create Card'}
            {activeStep !== 0 ? (
              <CheckCircleIcon
                sx={{ ml: 1 }}
                fontSize="medium"
                color="success"
              />
            ) : errors.length > 0 ? (
              <IconButton
                sx={{ borderRadius: 0, p: 0, width: 'fit-content', ml: 1 }}
                aria-label="save"
                size="small"
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                onClick={handleOpen}
              >
                <InfoIcon fontSize="medium" color="error" />
              </IconButton>
            ) : (
              <CheckCircleIcon
                sx={{ ml: 1, color: '#fff' }}
                fontSize="medium"
              />
            )}
          </Button>
        </Tooltip>
      </Box>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box padding={2}>
          {errors.map((error) => (
            <Box mb={3}>
              <Typography variant="h6">
                {`Card ${error.card.toString()}`}
              </Typography>
              {error.issues
                .filter((el) => el !== '')
                .map((val) => (
                  <Box display="flex">
                    <InfoIcon fontSize="small" color="error" />
                    <Typography sx={{ ml: 2 }} variant="subtitle2">
                      {val}
                    </Typography>
                  </Box>
                ))}
            </Box>
          ))}
        </Box>
      </Popover>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800,
            bgcolor: 'background.paper',
            border: 'none',
            boxShadow: 24,
          }}
        >
          <Box p={2} borderBottom="0.5px solid #000">
            <Typography variant="h6">Required Information</Typography>
            It looks like this campaign is missing some important data. Review
            the list below and fill out the required data to publish.
          </Box>

          {errors.map((error) => (
            <Box p={2}>
              <Typography variant="h6">
                {`Card ${error.card.toString()}`}
              </Typography>
              {error.issues
                .filter((el) => el !== '')
                .map((val) => (
                  <Box display="flex">
                    <InfoIcon fontSize="small" color="error" />
                    <Typography sx={{ ml: 2 }} variant="subtitle2">
                      {val}
                    </Typography>
                  </Box>
                ))}
            </Box>
          ))}
          <Box
            p={2}
            display="flex"
            justifyContent="flex-end"
            borderTop="0.5px solid #000"
          >
            <Button onClick={handleClose} variant="text">
              Ok
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ToolBarCampaign;

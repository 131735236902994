import React from 'react';

import { makeStyles } from '@mui/styles';

const useStyles: any = makeStyles({
  radio: {
    width: 25,
    height: 25,
    borderRadius: '50%',
    border: '1px solid #75B0F6',
  },
});

const UncheckedRadio = () => {
  const classes: any = useStyles();
  return <div className={classes.radio} />;
};

export default UncheckedRadio;

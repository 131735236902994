import React from 'react';

import Colors from 'constants/colors';
import { defaultStyles } from 'constants/styles';

import BusinessIcon from '@mui/icons-material/Business';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow';

import PropTypes from 'prop-types';

const Row = ({ business }) => (
  <TableRow
    key={business?.id}
    sx={{
      boxShadow: defaultStyles.box.shadow,
      backgroundColor: Colors.white,
      borderRadius: 2.5,
      '&:hover': {
        boxShadow: ' 0px 0px 0px 1px rgb(0 99 255 / 50%)',
        backgroundColor: `${Colors.white} !important`,
        // cursor: 'pointer',
      },
    }}
    // onClick={onClick}
  >
    <TableCell
      sx={{
        border: 'none',
        padding: 0,
        minHeight: 64,
        fontSize: 16,
        fontWeight: 700,
        color: 'inherit',
      }}
    >
      <Box display="flex" alignItems="center">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={64}
          width={64}
          mr={2.5}
          bgcolor={Colors.primary}
        >
          <BusinessIcon
            sx={{
              color: Colors.white,
              width: 36,
              height: 36,
            }}
          />
        </Box>
        {business?.name}
      </Box>
    </TableCell>
    <TableCell
      align="center"
      sx={{
        border: 'none',
        padding: 0,
        minHeight: 64,
        fontSize: 16,
        color: 'inherit',
      }}
    >
      {business?.status ? 'Active' : 'Inactive'}
    </TableCell>
  </TableRow>
);

Row.propTypes = {
  business: PropTypes.object.isRequired,
  // onClick: PropTypes.func.isRequired,
};

export default Row;

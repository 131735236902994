import React, { useState } from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import HeaderCell from 'components/HeaderCell';
import PendingRow from './PendingRow';
import Row from './Row';
import { Grid } from '@mui/material';

const columns = [
  {
    name: '',
    key: 'icon',
  },
  {
    name: 'Status',
    key: 'status',
    hideInMobile: true,
  },
  {
    name: 'Name',
    key: 'name',
  },
  {
    name: 'Email',
    key: 'email',
  },
  {
    name: 'Tags',
    key: 'tags',
    hideInMobile: true,
  },
  {
    name: 'Team',
    key: 'team',
    hideInMobile: true,
  },
  {
    name: '',
    key: 'menu',
    hideInMobile: true,
  },
];

const TeamMembersList: React.FC<any> = ({
  switchDisplay,
  teamMembers,
  onClick,
  onEdit,
  onDelete,
  loading,
}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const desc = (a, b, oBy) => {
    let aInfo;
    let bInfo;
    if (oBy === 'status') {
      aInfo = a.status;
      bInfo = b.status;
    }

    if (oBy === 'name') {
      aInfo = a.name;
      bInfo = b.name;
    }

    if (oBy === 'email') {
      aInfo = a.email;
      bInfo = b.email;
    }

    if (oBy === 'location') {
      aInfo = a.location;
      bInfo = b.location;
    }

    if (bInfo < aInfo) {
      return -1;
    }
    if (bInfo > aInfo) {
      return 1;
    }
    return 0;
  };

  const createSortHandler = (property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event, val) => {
    setPage(val);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const o = cmp(a[0], b[0]);
      if (o !== 0) return o;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getSorting = (o, oBy) => {
    if (o === 'desc') {
      return (a, b) => desc(a, b, oBy);
    }
    return (a, b) => -desc(a, b, oBy);
  };

  return (
    <Grid container spacing={3}>
      {stableSort(teamMembers, getSorting(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((teamMember) => {
          if (teamMember.status === 'pending') {
            return (
              <Grid
                item
                xs={12}
                lg={switchDisplay === 'grid' ? 6 : 12}
                width="100%"
              >
                <PendingRow
                  key={teamMember.id}
                  teamMember={teamMember}
                  onDelete={onDelete}
                />
              </Grid>
            );
          }
          return (
            <Grid
              item
              xs={12}
              md={switchDisplay === 'grid' ? 6 : 12}
              width="100%"
            >
              <Row
                key={teamMember.id}
                teamMember={teamMember}
                onClick={() => onClick(teamMember)}
                onEdit={onEdit}
              />
            </Grid>
          );
        })}
      {/* <Table
        sx={{
          borderSpacing: ' 0 10px !important',
          borderCollapse: 'separate !important' as any,
          '& .MuiTableCell-root': {
            border: 'none !important',
          },
        }}
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <HeaderCell
                key={column.key}
                column={column}
                orderBy={orderBy}
                order={order}
                onCreateSortHandler={createSortHandler}
                hideInMobile={column.hideInMobile}
              />
            ))}
          </TableRow>
        </TableHead>
        {teamMembers && (
          <TableBody
            sx={{
              '& tr': {
                color: Colors.black,
                borderRadius: 2.5,
              },
              '& tr td:first-child': {
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
                overflow: 'hidden',
              },
              '& tr td:last-child': {
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
                overflow: 'hidden',
              },
            }}
          >
            {stableSort(teamMembers, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((teamMember) => {
                if (teamMember.status === 'pending') {
                  return (
                    <PendingRow
                      key={teamMember.id}
                      teamMember={teamMember}
                      onDelete={onDelete}
                    />
                  );
                }
                return (
                  <Row
                    key={teamMember.id}
                    teamMember={teamMember}
                    onClick={() => onClick(teamMember)}
                    onEdit={onEdit}
                  />
                );
              })}
          </TableBody>
        )}
      </Table> */}
      {loading && (
        <Box
          width="100%"
          height={36}
          display="flex"
          justifyContent="end"
          alignItems="center"
        >
          <Typography variant="caption">Loading</Typography>
          <CircularProgress sx={{ marginLeft: 1 }} size={16} />
        </Box>
      )}
    </Grid>
  );
};

export default TeamMembersList;

// : (
//   <TablePagination
//     rowsPerPageOptions={[10, 15, 20]}
//     component="div"
//     count={teamMembers.length}
//     rowsPerPage={rowsPerPage}
//     page={page}
//     labelDisplayedRows={() =>
//       `${page + 1}-${Math.floor(teamMembers.length / rowsPerPage) + 1}`
//     }
//     onPageChange={handleChangePage}
//     onRowsPerPageChange={handleChangeRowsPerPage}
//   />
// )

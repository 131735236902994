import Parse, { Object, JSONBaseAttributes, Pointer } from 'parse'
import { createPointer } from 'store/utils/pointers';

export interface CouponAttributes{
  premium_pointer: Pointer
  agent_id: string
  generic: string
}

export type ICoupon = Object.ToJSON<CouponAttributes> & JSONBaseAttributes;

class Coupon extends Parse.Object<CouponAttributes> {
  static className = 'coupons'

  constructor(init: CouponAttributes) {
    super(Coupon.className, init);
  }

  static getPointer(objectId: string): Pointer {
    return createPointer(Coupon.className, objectId)
  }
}

export default Coupon;

import React, { useState, useCallback } from 'react';

import { Box, Button, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Slider from '@mui/material/Slider';

import Cropper from 'react-easy-crop';

import MatDrawer from '../Elements/MatDrawer/MatDrawer';

import { getCroppedImg } from './cropImage';
import { Save } from '@mui/icons-material';
import CustomModalV2 from 'components/Elements/CustomModalV2/CustomModalV2';
import Colors from 'constants/colors';

const CropperDrawerV2 = ({ open, onClose, onSave, photo }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [cropSize, setCropSize] = useState({ width: 500, height: 250 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [loading, setLoading] = useState(false);

  const onCropComplete = useCallback(async (croppedArea, pixels) => {
    setCroppedAreaPixels(pixels);
  }, []);

  const handleSaveCrop = async () => {
    setLoading(true);
    const croppedImage = await getCroppedImg(photo, croppedAreaPixels, 0);
    onSave(croppedImage);
    setLoading(false);
  };

  return (
    // <MatDrawer
    //   open={open}
    //   anchor="right"
    //   onClose={onClose}
    //   onCancel={onClose}
    //   onSubmit={handleSaveCrop}
    //   title="Crop Photo"
    //   submitBtn={<Save />}
    //   cancelBtn="Cancel"
    //   isLoading={loading}
    // >
    <CustomModalV2
      open={open}
      onClose={onClose}
      // onSubmit={handleSaveCrop}
      isLoading={loading}
    >
      <Box width={500} mt={2}>
        <Box width={1} height="50vh" position="relative">
          <Box
            width={1}
            height={1}
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
          >
            <Cropper
              image={photo}
              crop={crop}
              zoom={zoom}
              minZoom={0.1}
              cropSize={cropSize}
              onCropSizeChange={setCropSize}
              aspect={4 / 1.7}
              restrictPosition={false}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </Box>
          <Box
            position="absolute"
            bottom={25}
            left={0}
            right={0}
            height={0}
            display="flex"
            alignItems="center"
            pl={3}
            pr={3}
          >
            <Box style={{ flexGrow: 1, paddingRight: 24 }}>
              <Typography id="zoom-slider" color="white">
                Zoom :
              </Typography>
              <Slider
                value={zoom}
                min={0.3}
                max={3}
                step={0.1}
                aria-labelledby="zoom-slider"
                onChange={(e, z: any) => setZoom(z)}
              />
            </Box>
          </Box>
          <Box
            position="absolute"
            bottom={80}
            left={0}
            right={0}
            height={0}
            display="flex"
            alignItems="center"
            pl={3}
            pr={3}
          >
            <Box style={{ flexGrow: 1, paddingRight: 24 }}>
              <Typography id="width-slider" color="white">
                Size :
              </Typography>
              <Slider
                value={cropSize.width}
                min={314}
                max={500}
                step={1}
                aria-labelledby="width-slider"
                onChange={(e, z: any) => setCropSize({ width: z, height: 250 })}
              />
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap={2}
          mt={2}
        >
          {loading ? (
            <CircularProgress
              style={{ width: 40, height: 40, color: Colors.primary }}
            />
          ) : (
            <>
              <Button
                disableElevation={true}
                color="primary"
                onClick={onClose}
                sx={{
                  color: Colors.black,
                  textTransform: 'capitalize',
                }}
              >
                Cancel
              </Button>
              <Button
                disableElevation={true}
                color="primary"
                // eslint-disable-next-line no-confusing-arrow
                onClick={handleSaveCrop}
                sx={{
                  textTransform: 'capitalize',
                  fontWeight: 600,
                  background: '#D9EBFF',
                  borderRadius: '8px',
                  px: '18px',
                  py: '6px',
                  maxHeight: 'fit-content',
                  height: 'fit-content',
                  '&:hover': {
                    background: '#D9EBFF',
                  },
                }}
              >
                Crop
              </Button>
            </>
          )}
        </Box>
      </Box>
    </CustomModalV2>
    // </MatDrawer>
  );
};

export default CropperDrawerV2;

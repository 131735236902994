import React from 'react';

import Colors from 'constants/colors';

import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';
import MatInput from 'components/Elements/MatInput/MatInput';

const FormCard = ({ onClick, active, item, cardId }) => (
  <Box sx={{
    marginLeft: 1,
    marginRight: 1,
    marginBottom: 2,
  }}
  >
    <Card
      sx={active ? {
        textAlign: 'left',
        borderColor: Colors.primaryLight,
        borderStyle: 'solid',
        borderWidth: 3,
        position: 'relative',
      } : {
        width: '100%',
        textAlign: 'left',
        cursor: 'pointer',
        position: 'relative',
      }}
      onClick={onClick}
    >
      <CardContent sx={{
        paddingTop: 1,
        paddingBottom: '8px !important',
        paddingLeft: 2,
        paddingRight: 2,
      }}
      >
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
        }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              flexGrow: 1,
            }}
          >
            {item.title || 'Header'}
          </Typography>
        </Box>
        {item.fields.length > 0
          ? item.fields.map((val) => {
            if (val === '') {
              return <div />;
            }
            return (
              <MatInput
                label={val}
                variant="outlined"
                size="small"
                fullWidth
                disabled
                sx={{
                  marginTop: 1.25,
                  backgroundColor: Colors.white,
                  flexGrow: 1,
                  [`& fieldset`]: {
                    borderRadius: 2.5,
                  },
                  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                    borderColor: Colors.primary,
                  },
                }}
              />
            );
          })
          : null}
        <Button
          color="primary"
          variant="contained"
          disableElevation
          sx={{
            borderRadius: 6,
            height: 22,
            marginTop: 1.25,
          }}
        >
          <Typography sx={{
            fontSize: 8,
            color: Colors.white,
          }}
          >
            Submit

          </Typography>
        </Button>
      </CardContent>
      <Box sx={{
        top: 0,
        right: 0,
        height: 20,
        marginTop: 1.25,
        marginRight: 0.75,
        paddingLeft: 1.25,
        paddingRight: 1.25,
        position: 'absolute',
        backgroundColor: 'rgba(97,97,97 ,0.7)',
        borderRadius: 6,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        <Typography sx={{
          color: Colors.white,
          fontSize: 10,
        }}
        >
          {cardId}
        </Typography>
      </Box>
    </Card>
  </Box>
);

FormCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  cardId: PropTypes.string.isRequired,
};

export default FormCard;

import Colors from 'constants/colors';

import Moment from 'moment';

export default (type) => {
  switch (type) {
    case 'info':
      return {
        type,
        id: Moment().toISOString(),
        info: {
          image: {
            url: '',
            type: '',
            info: {},
          },
          title: '',
          description: { ops: [] },
          ctaOne: {
            type: 'none',
            name: '',
            link: '',
          },
          ctaTwo: {
            type: 'none',
            name: '',
            link: '',
          },
        },
      };
    case 'image':
      return {
        type,
        id: Moment().toISOString(),
        info: {
          image: {
            url: '',
            type: '',
            info: {},
          },
          title: '',
          cta: {
            link: '',
            name: '',
            type: 'none',
          },
        },
      };
    case 'video':
      return {
        type,
        id: Moment().toISOString(),
        info: {
          type: null,
          code: '',
          title: '',
        },
      };
    case 'button':
      return {
        type,
        id: Moment().toISOString(),
        info: {
          version: 2,
          cta: {
            link: '',
            name: '',
            type: 'redirect',
          },
          color: Colors.primary,
          text: Colors.white,
        },
      };
    case 'social':
      return {
        type,
        id: Moment().toISOString(),
        info: {
          facebook: '',
          twitter: '',
          linkedin: '',
          instagram: '',
        },
      };
    case 'form':
      return {
        type,
        id: Moment().toISOString(),
        info: {
          title: '',
          fields: [''],
        },
      };
    case 'poll':
      return {
        type,
        id: Moment().toISOString(),
        info: {
          question: '',
          answers: [],
        },
      };
    case 'carousel':
      return {
        type,
        id: Moment().toISOString(),
        info: {
          images: [
            {
              image: {
                url: '',
                type: '',
                info: {},
              },
              description: '',
            },
          ],
          ctaOne: {
            type: 'none',
            name: '',
            link: '',
          },
          ctaTwo: {
            type: 'none',
            name: '',
            link: '',
          },
        },
      };
    case 'mailchimp':
      return {
        type,
        id: Moment().toISOString(),
        info: {
          title: '',
          listId: '',
        },
      };
    default:
      return null;
  }
};

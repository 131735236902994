import React, { useEffect, useState } from 'react';

import Colors from 'constants/colors';

import GroupIcon from '@mui/icons-material/Group';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import HeaderCell from 'components/HeaderCell';

import Row from './Row';
import { TableContainer } from '@mui/material';
import { MembersActions } from 'store/entities/Members';
import ComposeEmail from '../ComposeEmail';
import { Search } from '@mui/icons-material';

const columns = [
  {
    name: 'Name',
    key: 'name',
  },
  {
    name: 'Actions',
    key: 'conversions',
  },
];

const TeamMembersTable = ({ campaignId, type }) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [page, setPage] = useState(0);
  const [teamMembers, setTeamMembers] = useState<any>([]);
  const [loadingTeamMembers, setLoadingTeamMembers] = useState(false);

  const [createOpen, setCreateOpen] = useState(false);

  const { membersCampaign, entities } = useSelector(
    (state: RootState) => state.members,
  );

  useEffect(() => {
    setLoadingTeamMembers(false);
    setTeamMembers(membersCampaign);
  }, [membersCampaign]);

  const [filterText, setFilterText] = useState('');

  const filteredItems = teamMembers?.filter((item) =>
    item.name.toLocaleLowerCase().includes(filterText),
  );

  const itemsToDisplay = filterText ? filteredItems : teamMembers;

  const desc = (a, b, oBy) => {
    const aInfo = a[oBy];
    const bInfo = b[oBy];

    if (bInfo < aInfo) {
      return -1;
    }
    if (bInfo > aInfo) {
      return 1;
    }
    return 0;
  };

  const createSortHandler = (property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event, val) => {
    setPage(val);
  };

  const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const o = cmp(a[0], b[0]);
      if (o !== 0) return o;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getSorting = (o, oBy) => {
    if (o === 'desc') {
      return (a, b) => desc(a, b, oBy);
    }
    return (a, b) => -desc(a, b, oBy);
  };

  if (loadingTeamMembers) {
    return (
      <Box width="100%" height="100%">
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <CircularProgress sx={{ width: 48, height: 48 }} />
          <Typography variant="subtitle2">Loading Team Members</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: '#fafafa',
        borderRadius: '20px',
        padding: '20px',
        // maxHeight: '300px',
        height: 'fit-content',
      }}
      width="100%"
      // height="100%"
    >
      <Typography fontWeight={600} mb={2} variant="h6">
        Team Members
      </Typography>
      {teamMembers && teamMembers.length > 0 ? (
        <Box
          className="folderCard"
          sx={{
            width: '100%',
            borderSpacing: ' 0 10px !important',
            borderCollapse: 'separate !important' as any,
            '& .MuiTableCell-root': {
              border: 'none !important',
            },
          }}
        >
          <Box
            sx={{
              '& tr': {
                color: Colors.black,
                borderRadius: 2.5,
              },
              '& tr td:first-child': {
                borderTopLeftRadius: 2.5,
                borderBottomLeftRadius: 2.5,
                overflow: 'hidden',
              },
              '& tr td:last-child': {
                borderTopRightRadius: 2.5,
                borderBottomRightRadius: 2.5,
                overflow: 'hidden',
              },
            }}
          >
            <Box
              sx={{
                borderRadius: '13px',
                backgroundColor: '#EEF0F2',
                paddingX: '15px',
                paddingY: '10px',
                marginBottom: '10px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Search
                sx={{
                  fontSize: '22px',
                  marginRight: '10px',
                }}
              />
              <input
                style={{
                  outline: 'none',
                  border: 'none',
                  backgroundColor: 'inherit',
                  fontSize: '15px',
                }}
                type="text"
                placeholder="Search"
                value={filterText}
                onChange={(e) =>
                  setFilterText(e.target.value.toLocaleLowerCase())
                }
              />
            </Box>
            {!filteredItems.length && (
              <Box
                width="100%"
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                sx={{ overflow: 'auto' }}
              >
                <GroupIcon sx={{ width: 48, height: 48 }} />
                <Typography variant="subtitle1">
                  No Team Member data to display
                </Typography>
              </Box>
            )}
            <Box
              className="folderCard"
              sx={{
                maxHeight: '180px',
                width: '100%',
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
            >
              {stableSort(itemsToDisplay, getSorting(order, orderBy))
                .slice(page * 5, page * 5 + 5)
                .map((teamMember) => (
                  <>
                    <Row
                      id={teamMember.id}
                      name={teamMember.name}
                      email={entities[teamMember.id].email}
                      // engagements={teamMember.engagements}
                      // impressions={teamMember.impressions}
                      // conversions={teamMember.conversions}
                      setCreateOpen={setCreateOpen}
                    />
                    <Divider variant="inset" />
                  </>
                ))}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <GroupIcon sx={{ width: 48, height: 48 }} />
          <Typography variant="subtitle1">
            No Team Member data to display
          </Typography>
        </Box>
      )}

      {stableSort(teamMembers, getSorting(order, orderBy))
        .slice(page * 5, page * 5 + 5)
        .map((teamMember) => (
          <ComposeEmail
            open={createOpen}
            onClose={() => setCreateOpen(false)}
            membersEmail={entities[teamMember.id].email}
          />
        ))}
    </Box>
  );
};

TeamMembersTable.propTypes = {
  campaignId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default TeamMembersTable;

import React from 'react';

import { useTranslation } from 'react-i18next';
import { CollectedInformation, UsingInformation, SharingInformation } from './PrivacyContent';
import { ListComponent, SublistComponent } from '../../TemplateComponents';
import Box from '@mui/material/Box';

const CollectedInformationList = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <ul>
        {CollectedInformation.map((item) => (
          <ListComponent>
            <b>{t(item.title)}</b>
            {t(item.content)}
          </ListComponent>
        ))}
      </ul>
    </Box>
  );
};

const UsingInformationList = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <ul>
        {UsingInformation.map((item) => (
          <SublistComponent>{t(item.content)}</SublistComponent>
        ))}
      </ul>
    </Box>
  );
};

const SharingInformationList = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <ul>
        {SharingInformation.map((item) => (
          <SublistComponent>{t(item.content)}</SublistComponent>
        ))}
      </ul>
    </Box>
  );
};

export { CollectedInformationList, UsingInformationList, SharingInformationList };

import React from 'react';

import Colors from 'constants/colors';
import { defaultStyles } from 'constants/styles';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import StatusCell from './StatusCell';
import { Hidden } from '@mui/material';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    background: '#0d1117b3',
    fontSize: '15px',
  },
});

const Row = ({
  name,
  originalName,
  link,
  engagements,
  impressions,
  conversions,
  onClick,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleLinkCopy = (l) => {
    const el = document.createElement('textarea');
    el.value = l;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    enqueueSnackbar('Link copied', { variant: 'success' });
  };

  return (
    <TableRow
      hover
      key={name}
      sx={{
        boxShadow: defaultStyles.box.shadow,
        backgroundColor: Colors.white,
        borderRadius: 2.5,
        '&:hover': {
          boxShadow: ' 0px 0px 0px 1px rgb(0 99 255 / 50%)',
          backgroundColor: `${Colors.white} !important`,
          cursor: 'pointer',
        },
      }}
    >
      <TableCell
        sx={{
          border: 'none',
          padding: 0,
          minHeight: 64,
          fontSize: 16,
          fontWeight: 700,
          color: 'inherit',
        }}
        onClick={onClick}
      >
        <Box display="flex" alignItems="center" height="100%">
          <StatusCell />
          <CustomTooltip title={originalName} placement="top" arrow>
            <Box
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              {name}
            </Box>
          </CustomTooltip>
        </Box>
      </TableCell>
      <TableCell align="center">
        <Button
          onClick={() => handleLinkCopy(link)}
          variant="text"
          color="primary"
          sx={{
            whiteSpace: 'nowrap',
            padding: 0,
            fontSize: 16,
            minWidth: 84,
          }}
        >
          Copy Link
        </Button>
      </TableCell>
      <Hidden lgDown>
        <TableCell
          align="center"
          sx={{
            border: 'none',
            padding: 0,
            minHeight: 64,
            fontSize: 16,
            color: 'inherit',
          }}
          onClick={onClick}
        >
          {engagements}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            border: 'none',
            padding: 0,
            minHeight: 64,
            fontSize: 16,
            color: 'inherit',
          }}
          onClick={onClick}
        >
          {impressions}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            border: 'none',
            padding: 0,
            minHeight: 64,
            fontSize: 16,
            color: 'inherit',
          }}
          onClick={onClick}
        >
          {conversions}
        </TableCell>
      </Hidden>
    </TableRow>
  );
};

Row.propTypes = {
  name: PropTypes.string.isRequired,
  engagements: PropTypes.number.isRequired,
  impressions: PropTypes.number.isRequired,
  conversions: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
};

export default Row;

import React from 'react';
import Box from '@mui/material/Box';

function SVG4() {
  return (
    <div
      id="SVG4"
      style={{
        height: '70px',
        width: '70px',
        display: 'flex',
      }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          height: '45px',
          width: '45px',
          stroke: '#ffffff',
          strokeWidth: 2.0,
          margin: 'auto',
        }}
      >
        <path
          d="M9.03 21.69L11.36 19.73C11.71 19.43 12.29 19.43 12.64 19.73L14.97 21.69C15.51 21.96 16.17 21.69 16.37 21.11L16.81 19.78C16.92 19.46 16.81 18.99 16.57 18.75L14.3 16.47C14.13 16.31 14 15.99 14 15.76V12.91C14 12.49 14.31 12.29 14.7 12.45L19.61 14.57C20.38 14.9 21.01 14.49 21.01 13.65V12.36C21.01 11.69 20.51 10.92 19.89 10.66L14.3 8.25001C14.2143 8.20602 14.1416 8.14047 14.089 8.0598C14.0364 7.97913 14.0057 7.88615 14 7.79001V4.79001C14 3.85001 13.31 2.74001 12.47 2.31001C12.17 2.16001 11.82 2.16001 11.52 2.31001C10.68 2.74001 9.99 3.86001 9.99 4.80001V7.80001C9.99 7.98001 9.85 8.19001 9.69 8.26001L4.11 10.67C3.49 10.92 2.99 11.69 2.99 12.36V13.65C2.99 14.49 3.62 14.9 4.39 14.57L9.3 12.45C9.68 12.28 10 12.49 10 12.91V15.76C10 15.99 9.87 16.31 9.71 16.47L7.44 18.75C7.2 18.99 7.09 19.45 7.2 19.78L7.64 21.11C7.82 21.69 8.48 21.97 9.03 21.69Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export default SVG4;

/* eslint-disable max-len */
/* eslint-disable react/jsx-fragments */
import React, { useEffect, useMemo, useRef, useState } from 'react';

// import Colors from 'constants/colors';
import Permissions from 'constants/permissions';

import AddIcon from '@mui/icons-material/Add';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
// import FolderIcon from '@mui/icons-material/Folder';
import WindowIcon from '@mui/icons-material/Window';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import routes from 'routes';
import { RootState } from 'store';
import { TeamHubActions } from 'store/entities/TeamHub';

import ElementMaker from 'components/ElementMaker';
import EmptyPage from 'components/EmptyPage';
import PermissionRequired from 'components/PermissionRequired';
import HomeContainer from 'components/ui/HomeContainer';
import Scaffold from 'components/ui/Scaffold';

import AlertDialog from './components/AlertDialog';
import BasicMenu from './components/BasicMenu';
import CreateFolder from './components/CreateFolder';
import SVG1 from './components/folder_svg/SVG1';
import useAccessControl from 'hooks/useAccessControl';

interface AddTeamButtonProps {
  display: string;
  onClick: () => void;
}

const AddButton = styled(Button)<ButtonProps>(() => ({
  color: '#00CA51',
  textTransform: 'capitalize',
  boxShadow: 'none',
  backgroundColor: 'rgba(0, 202, 81, 0.15)',
  '&:hover': {
    backgroundColor: 'rgba(0, 202, 81, 0.15)',
  },
  borderRadius: 14,
  paddingY: 1,
  fontWeight: 600,
}));

const AddFolderButton: React.FC<AddTeamButtonProps> = ({
  display,
  onClick,
}) => (
  <AddButton
    sx={{ fontSize: '15px' }}
    variant="contained"
    onClick={onClick}
    endIcon={<AddIcon fontSize="medium" />}
  >
    {display}
  </AddButton>
);

const TeamHub = () => {
  const ref = useRef();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { folders } = useSelector((state: RootState) => state.teamHub);
  const [createOpen, setCreateOpen] = useState(false);
  const [showInputEle, setShowInputEle] = useState(false);
  const [folderId, setFolderId] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [folderIdEdit, setFolderIdEdit] = useState();
  const [folderIdDel, setFolderIdDel] = useState('');
  const [folderName, setFolderName] = useState('');
  const [folderList, setFolderList] = useState<any>([]);

  // more button useState
  // const [isShown, setIsShown] = useState(false);
  //
  // menu for edit and delete button - folder card
  //
  const [
    anchorElFolderMenu,
    setAnchorElFolderMenu,
  ] = React.useState<null | HTMLElement>(null);
  const openFolderMenu = Boolean(anchorElFolderMenu);
  const handleClickFolderMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorElFolderMenu(event.currentTarget);
  };
  const handleCloseFolderMenu = () => {
    setAnchorElFolderMenu(null);
  };
  //
  // dialog for delete action button - folder card
  //
  // const [deleteEleID, setDeleteEleID] = React.useState(false);

  const [openAlertDialog, setOpenAlertDialog] = React.useState(false);

  const handleClickOpenAlertDialog = () => {
    setOpenAlertDialog(true);
  };

  const handleCloseAlertDialog = () => {
    setOpenAlertDialog(false);
  };

  useEffect(() => {
    dispatch(TeamHubActions.fetchFolders());
  }, []);

  useEffect(() => {
    if (folders) {
      setFolderList(folders);
    }
  }, [folders]);

  const updateFolderName = (e) => {
    const newState = folderList.map((obj) => {
      if (obj.id === folderId) {
        return { ...obj, name: e.target.value };
      }
      return obj;
    });
    setFolderList(newState);
  };

  const handleClickEdit = (e) => {
    setFolderIdEdit(e);
    setIsEditing(true);
    setCreateOpen(true);
  };

  const handleClickTerminate = (eleID) => {
    dispatch(TeamHubActions.deleteFolder({ folderId: eleID }));
  };

  const ac = useAccessControl();
  const handleClickEditFolderName = (permissions, folder) => {
    let grant = true;
    if (permissions) {
      permissions.forEach((permission) => {
        grant = grant && ac.allow(permission);
      });
    }
    if (grant) {
      navigate(routes.teamHub.folder(folder.id), {
        state: { folderName: folder.name },
      });
    }
  };

  const htmlString = renderToString(<SVG1 />);
  return (
    <Scaffold
      HeaderProps={{
        title: 'Team Hub',
        actions: [
          <PermissionRequired permissions={[Permissions.teamHub.create]}>
            <AddFolderButton
              display="Create Folder"
              onClick={() => {
                setIsEditing(false);
                setCreateOpen(true);
              }}
            />
          </PermissionRequired>,
        ],
      }}
    >
      <HomeContainer>
        <Grid container spacing={2}>
          {!folderList || folderList.length === 0 ? (
            // && (filterState === 'active' || filterState === 'all')
            <Grid item xs={12}>
              <EmptyPage
                actions={[
                  <AddFolderButton
                    display="Create Folder"
                    onClick={() => {
                      setIsEditing(false);
                      setCreateOpen(true);
                    }}
                  />,
                ]}
                title={t('teamHub')}
                description={t('teamHubEmptyDescription')}
                permissions={[Permissions.teamHub.create]}
              />
            </Grid>
          ) : (
            folderList?.map((folder) => {
              const folderIcon =
                folder.icon === undefined
                  ? htmlString
                  : folder?.icon
                  ? JSON.parse(folder.icon).iconFun
                  : '';
              return (
                <Grid item xs={6} sm={6} md={3} lg={3} key={folder.id}>
                  <Box
                    width="fit-content"
                    margin="auto"
                    textAlign="center"
                    position="relative"
                  >
                    <PermissionRequired
                      permissions={[Permissions.teamHub.edit]}
                    >
                      <Button
                        sx={{
                          position: 'absolute',
                          top: '5px',
                          right: '-30px',
                          zIndex: 5,
                          width: 'fit-content',
                          maxWidth: 'none',
                          paddingX: 0.5,
                          paddingY: 0,
                          boxShadow: '0px 0px 14px 0px rgba(0, 0, 0, 0.15)',
                          borderRadius: '6px',
                          background: '#ffffff',
                          '& hover': {
                            background: '#ffffff',
                          },
                        }}
                        id="basic-button"
                        aria-controls={
                          openFolderMenu ? 'basic-menu' : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={openFolderMenu ? 'true' : undefined}
                        onClick={(event) => {
                          handleClickFolderMenu(event);
                          setFolderIdDel(folder.id);
                        }}
                      >
                        <MoreHorizIcon />
                      </Button>
                    </PermissionRequired>
                    {folderIdDel === folder.id && (
                      <BasicMenu
                        folder={folder}
                        handleClickEdit={handleClickEdit}
                        anchorElFolderMenu={anchorElFolderMenu}
                        openFolderMenu={openFolderMenu}
                        handleCloseFolderMenu={handleCloseFolderMenu}
                        handleClickOpenAlertDialog={handleClickOpenAlertDialog}
                      />
                    )}
                    {folderIdDel === folder.id && (
                      <AlertDialog
                        folder={folder}
                        handleClickTerminate={handleClickTerminate}
                        openAlertDialog={openAlertDialog}
                        handleCloseAlertDialog={handleCloseAlertDialog}
                      />
                    )}
                    <Box
                      sx={{
                        zIndex: 3,
                        height: '120px',
                        width: '120px',
                        background: '#ffffff',
                        borderRadius: '25px',
                        display: 'flex',
                        marginBottom: '10px',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        handleClickEditFolderName(
                          [Permissions.teamHub.edit],
                          folder,
                        )
                      }
                    >
                      <Box
                        sx={{
                          margin: 'auto',
                          zIndex: 3,
                          height: '70px',
                          width: '70px',
                          background: folder.color,
                          borderRadius: '20px',
                        }}
                        dangerouslySetInnerHTML={{ __html: folderIcon }}
                      />
                    </Box>
                    <ElementMaker
                      value={folder.name}
                      handleChange={updateFolderName}
                      handleDoubleClick={() => {
                        setFolderId(folder.id);
                        setFolderName(folder.name);
                      }}
                      handleBlur={() => {
                        if (folderName !== folder.name) {
                          dispatch(
                            TeamHubActions.updateFolder({
                              folderId: folder.id,
                              name: folder.name,
                            }),
                          );
                        }
                        setFolderName('');
                        setFolderId('');
                      }}
                      showInputEle={folder.id === folderId}
                    />
                  </Box>
                </Grid>
              );
            })
          )}
        </Grid>
        <CreateFolder
          isEditing={isEditing}
          folderData={folderIdEdit}
          open={createOpen}
          onClose={() => setCreateOpen(false)}
        />
      </HomeContainer>
    </Scaffold>
  );
};

TeamHub.propTypes = {};

export default TeamHub;

import React, { useEffect, useState } from 'react';

import Colors from 'constants/colors';

import DateRangeIcon from '@mui/icons-material/DateRange';
import { Autocomplete, Chip, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import Typography from '@mui/material/Typography';

import { ReactComponent as AlertIcon } from 'assets/images/alert_icon.svg';
import Moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import routes from 'routes';
import { RootState } from 'store';
import { campaignActions } from 'store/entities/Campaigns';

import DateRangeDrawer from '../DateRangeDrawer';
import MatDrawer from '../Elements/MatDrawer/MatDrawer';
import MatFormLabel from '../Elements/MatFormLabel/FormLabel';
import { templatesActions } from 'store/entities/Templates';

const RunTemplateDrawer = ({ campaign, open, onClose, onSuccess }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDateDrawer, setShowDateDrawer] = useState(false);
  const [duration, setDuration] = useState([Moment(), Moment().add(5, 'days')]);
  const { runAgainLoading } = useSelector((state: RootState) => state.campaigns);

  const [tags, setTags] = useState<any[]>([]);

  useEffect(() => {
    if (campaign && campaign.tags) {
      setTags(campaign.tags);
    }
  }, [campaign]);

  const handleDateRange = (value) => {
    setDuration(value);
    setShowDateDrawer(false);
  };

  const handleSave = () => {
    onClose();
    dispatch(
      templatesActions.activateTemplate({
        campaignId: campaign.id,
        callback: () => {
          onSuccess();
        },
      }),
    );
  };

  const onAdvanced = () => {
    navigate(routes.campaigns.build(), {
      state: { id: campaign.id, type: 'runagain' },
    });
  };

  return (
    <MatDrawer
      open={open}
      anchor="right"
      onClose={onClose}
      onCancel={onClose}
      onSubmit={handleSave}
      title="Activate Template Again"
      submitBtn="Activate"
      cancelBtn="Cancel"
      isLoading={runAgainLoading}
    >
      {campaign && (
        <Box maxWidth={450} height="100%" display="flex" flexDirection="column">
          <Typography variant="h6">{campaign.title}</Typography>
          <Box display="flex" width="100%" alignItems="center">
            {campaign.image && (
              <Box
                borderRadius={1}
                width={200}
                height={100}
                bgcolor={Colors.lightBlueGray}
                mr={1.5}
                textAlign="center"
              >
                <img
                  alt="Campaign"
                  src={campaign.image.url}
                  style={{
                    height: '100%',
                    objectFit: 'contain',
                    borderRadius: 4,
                  }}
                />
              </Box>
            )}
          </Box>
          <Box
            sx={{
              overflowY: 'auto',
            }}
            flexGrow={1}
            pt={3}
            pb={3}
          >
            <Typography variant="subtitle2">{campaign.description}</Typography>
          </Box>
        </Box>
      )}
    </MatDrawer>
  );
};

RunTemplateDrawer.propTypes = {
  campaign: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

RunTemplateDrawer.defaultProps = {
  campaign: null,
};

export default RunTemplateDrawer;

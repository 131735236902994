import React from 'react';

import { useTranslation } from 'react-i18next';
import { Details, ContentRestrictions, DMCA } from './TermsContent';
import { ListComponent, SublistComponent } from '../../TemplateComponents';
import Box from '@mui/material/Box';

const DetailList = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <ul>
        {Details.map((item) => (
          <ListComponent>
            <b>{t(item.title)}</b>
            {t(item.content)}
          </ListComponent>
        ))}
      </ul>
    </Box>
  );
};

const ConentRestrictionsList = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <ul>
        {ContentRestrictions.map((item) => (
          <SublistComponent>{t(item.content)}</SublistComponent>
        ))}
      </ul>
    </Box>
  );
};

const DMCAList = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <ul>
        {DMCA.map((item) => (
          <SublistComponent>{t(item.content)}</SublistComponent>
        ))}
      </ul>
    </Box>
  );
};

export { DetailList, ConentRestrictionsList, DMCAList };

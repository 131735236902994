import React from 'react'

import { Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';

const EmptyReport = () => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 240,
        width: '100%',
        background: '#0001',
        borderRadius: 20,
      }}
    >
      <Typography>
        {t('noReportToShow')}
      </Typography>

      <Typography>
        {t('adjustSettingsClickReport')}
      </Typography>
    </div>
  )
}

export default EmptyReport;

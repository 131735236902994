import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Typography from '@mui/material/Typography';

import Moment from 'moment';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import routes from 'routes';
import { RootState } from 'store';
import { campaignActions } from 'store/entities/Campaigns';
import { MembersActions } from 'store/entities/Members';

import MatButton from 'components/Elements/MatButton/MatButton';
import { PremiumEmulator } from 'components/Emulators';
import RunAgainDrawer from 'components/RunAgainDrawer';
import HomeContainer from 'components/ui/HomeContainer';
import Scaffold from 'components/ui/Scaffold';

import GenericLinksTable from './components/GenericLinksTable';
import Map from './components/Map';
import MetricsContainer from './components/Metrics/MetricsContainer';
import TeamMemberTable from './components/TeamMemberTable';
import TerminateDrawer from './components/TerminateDrawer';
import { reportActions } from 'store/entities/Reports';
import { CircularProgress, Collapse, LinearProgress } from '@mui/material';

const CampaignDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const historyLocation: any = useLocation();
  const { campaignId } = useParams();
  const [fullCampaign, setFullCampaign] = useState<any>(null);
  const [showRunAgain, setShowRunAgain] = useState(false);
  const [showTerminate, setShowTerminate] = useState(false);
  const [loading, setLoading] = useState(true);

  const { campaign } = useSelector((state: RootState) => state.campaigns);

  useEffect(() => {
    if (fullCampaign !== campaign && campaign?.id === campaignId) {
      setLoading(false);
      setFullCampaign(campaign);
    }
  }, [campaign]);

  useEffect(() => {
    if (campaignId) {
      setLoading(true);
      dispatch(campaignActions.getCampaign({ campaignId }));
      dispatch(campaignActions.getBreakDown({ campaignId }));
      dispatch(MembersActions.fetchMembers({}));
      dispatch(campaignActions.clearStatus());
      dispatch(campaignActions.getGenericLinks({ campaignId }));
      dispatch(MembersActions.getMemberByCampaign({ campaignId }));
      dispatch(reportActions.fetchCampaignMetrics({ campaignId }));
      dispatch(reportActions.clearStatus());
    } else {
      navigate(routes.campaigns.main());
    }
  }, []);

  const redirectToCampaigns = () => {
    navigate(routes.campaigns.main());
  };

  const checkValid = () => {
    if (fullCampaign && fullCampaign.terminated) {
      return false;
    } else if (
      fullCampaign &&
      Moment(fullCampaign.endDate).isBefore(Moment(), 'day')
    ) {
      return false;
    }
    return true;
  };

  if (!fullCampaign) {
    return <div />;
  }

  const onCampaignTerminated = () => {
    navigate(routes.campaigns.main());
    const updatefullCampaign = fullCampaign;
    updatefullCampaign.terminated = true;
    setFullCampaign(updatefullCampaign);
  };

  const handleEdit = () => {
    navigate(routes.campaigns.build(), {
      state: { id: fullCampaign.id, type: 'edit' },
    });
  };

  const renderTags = () => {
    if (
      !fullCampaign ||
      !fullCampaign?.advanced?.tags ||
      !fullCampaign?.advanced?.tags?.length
    ) {
      return null;
    }

    return (
      <Box
        component="section"
        sx={{
          padding: '16px',
          '@media max-width:1279.95px': {
            padding: '16px 0',
          },
        }}
      >
        <Typography variant="h6" sx={{ paddingBottom: 2 }}>
          {t('tags')}
        </Typography>
        <Box
          display="flex"
          flexWrap="wrap"
          sx={{
            '@media max-width:1279.95px': {
              padding: '0',
            },
          }}
        >
          {!!campaign?.advanced?.tags.length &&
            campaign?.advanced?.tags.map((tag) => (
              <Chip
                sx={{ margin: '4px 4px 4px 0' }}
                key={tag.objectId}
                label={tag.name}
              />
            ))}
        </Box>
      </Box>
    );
  };

  const renderEmulator = () => {
    if (!fullCampaign) {
      return <div />;
    }
    const condtionsConverter = new QuillDeltaToHtmlConverter(
      fullCampaign?.advanced?.conditions,
      {},
    );
    return (
      <>
        {fullCampaign?.data && (
          <PremiumEmulator
            rowOne={fullCampaign?.data[0]?.item}
            rowTwo={fullCampaign?.data[1]?.item}
            rowThree={fullCampaign?.data[2]?.item}
            rowFour={fullCampaign?.data[3]?.item}
            rowFive={fullCampaign?.data[4]?.item}
            age={fullCampaign?.advanced?.age}
            conditionsHtml={
              fullCampaign?.advanced?.conditions
                ? condtionsConverter?.convert()
                : null
            }
            showBusinessName={fullCampaign?.shouldShowBusinessName}
          />
        )}
        {renderTags()}
      </>
    );
  };

  return (
    <Scaffold
      HeaderProps={{
        title: !loading ? fullCampaign?.title : '',
        actions: [
          loading ? (
            <></>
          ) : checkValid() ? (
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  marginRight: 16,
                }}
              >
                <MatButton
                  variant="outlined"
                  size="big"
                  onClick={() => setShowTerminate(true)}
                >
                  {t('terminate')}
                </MatButton>
              </div>
              {fullCampaign.type !== 'legacy' && (
                <MatButton size="big" variant="contained" onClick={handleEdit}>
                  {t('edit')}
                </MatButton>
              )}
            </div>
          ) : (
            <MatButton
              size="big"
              variant="contained"
              onClick={() => setShowRunAgain(true)}
            >
              {t('runAgain')}
            </MatButton>
          ),
        ],
      }}
    >
      {loading ? (
        <Collapse in={true}>
          <LinearProgress />
        </Collapse>
      ) : (
        <HomeContainer display="flex">
          <Box flexGrow={1}>
            {fullCampaign &&
              fullCampaign.terminated !== null &&
              fullCampaign.terminated && (
                <Box mb={2}>
                  <Typography variant="h6" sx={{ color: Colors.error }}>
                    {t('terminatedCampaign')}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: Colors.error }}>
                    {fullCampaign.termination_reason}
                  </Typography>
                </Box>
              )}
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <MetricsContainer
                  campaignId={fullCampaign.id}
                  type={fullCampaign.type}
                  teamMembers={fullCampaign.teamMembers}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Map campaignId={fullCampaign.id} type={fullCampaign.type} />
              </Grid>
              <Grid item md={historyLocation?.state?.folderId ? 12 : 6} xs={12}>
                <TeamMemberTable
                  campaignId={fullCampaign.id}
                  members={fullCampaign.teamMembers}
                />
              </Grid>
              {fullCampaign.type !== 'legacy' &&
                !historyLocation?.state?.folderId && (
                  <Grid item md={6} xs={12}>
                    <GenericLinksTable
                      campaignId={fullCampaign.id}
                      campaignTitle={fullCampaign.title}
                      type={fullCampaign.type}
                      allowNewLinks={checkValid()}
                    />
                  </Grid>
                )}
            </Grid>
            <Hidden lgUp={true}>
              <Box>{renderTags()}</Box>
            </Hidden>
          </Box>
          <Hidden lgDown={true}>
            <Box pl={6.25}>{renderEmulator()}</Box>
          </Hidden>
        </HomeContainer>
      )}

      <TerminateDrawer
        open={showTerminate}
        onClose={() => setShowTerminate(false)}
        onSuccess={onCampaignTerminated}
        campaignId={fullCampaign.id}
        type={fullCampaign.type}
      />
      <RunAgainDrawer
        open={showRunAgain}
        onClose={() => setShowRunAgain(false)}
        campaign={fullCampaign}
        onSuccess={() => redirectToCampaigns()}
      />
    </Scaffold>
  );
};

export default CampaignDetails;

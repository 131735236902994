/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createDraftSafeSelector,
  createEntityAdapter,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import Parse from 'parse';
import { RootState } from 'store';
import { userActions, userSelectors } from 'store/domain/user';
import Business, { IBusiness } from 'store/models/Business';
import BusinessMembers from 'store/models/BusinessMembers';
import User from 'store/models/User';
import { selectBusinessesState, selectState } from 'store/selectors';
import { RequestStatus } from 'store/utils/types';
import { AgentUpload } from 'utils/cloudinary';

const sliceName = 'businesses';
const getBusinessId = (business: IBusiness) => business.objectId;

const BusinessesAdapter = createEntityAdapter<IBusiness>({
  selectId: getBusinessId,
});

const fetchBusinesses = createAsyncThunk(
  `${sliceName}/fetchBusinesses`,
  async () => {
    try {
      const memberships = await Parse.Cloud.run('Business:getBusinessesOfUser');
      const businessToJson = memberships.map((membership) =>
        membership.toJSON(),
      );
      return businessToJson.map((business) => ({
        status: business.active,
        ...business.business_id,
      }));
    } catch (error) {
      console.error(error);
      throw new Error(`Error: ${error}`);
    }
  },
);

export interface UpdateBusinessPayload {
  name: string;
  website?: string;
  image: any;
  phoneNumber?: string;
}

const updateBusiness = createAsyncThunk(
  `${sliceName}/updateBusiness`,
  async (
    { name, website, image, phoneNumber }: UpdateBusinessPayload,
    { getState, dispatch },
  ) => {
    const state = getState() as RootState;
    const businessId = userSelectors.getbusinessId(state);
    if (!businessId) {
      throw new Error('No active business.');
    }
    let imageUrl;
    if (image.upload) {
      imageUrl = await AgentUpload(image.url);
    } else {
      imageUrl = image.url;
    }
    const business = await Parse.Cloud.run('Business:updateBusiness', {
      businessId,
      name,
      businessUrl: website,
      logoUrl: imageUrl,
      businessPhone: phoneNumber,
    });

    dispatch(fetchBusinesses());
    return business;
  },
);

export interface SetActiveBusinessPayload {
  businessId: string;
}

const setActiveBusiness = createAsyncThunk(
  `${sliceName}/setActiveBusiness`,
  async ({ businessId }: SetActiveBusinessPayload, { dispatch }) => {
    try {
      if (!businessId) {
        throw new Error('No active business.');
      }
      const user = User.current<User>();

      if (!user) {
        throw new Error('No active user found.');
      }
      const businessMembers = new Parse.Query(BusinessMembers);
      const businessActive = await businessMembers
        .equalTo('team_member_id', user)
        .equalTo('active', true)
        .findAll();
      businessActive.map(async (business) => {
        business.set('active', false);
        await business.save();
        return business;
      });

      const businessSelected = await businessMembers
        .equalTo('team_member_id', user)
        .equalTo('business_id', Business.getPointer(businessId))
        .first();

      businessSelected?.set('active', true);
      await businessSelected?.save();
      dispatch(fetchBusinesses());
      dispatch(userActions.setBusinessId());
      return businessSelected;
    } catch (e) {
      throw new Error(`Set Active Business Error:  ${e}`);
    }
  },
);

export interface BusinessesState extends EntityState<IBusiness> {
  status: RequestStatus;
  businesses?: any[];
  error?: string;
}

const initialState: BusinessesState = {
  ...BusinessesAdapter.getInitialState(),
  status: 'idle',
};

const BusinessesSlice = createSlice({
  name: sliceName,
  initialState,
  extraReducers: (builder) => {
    // Fetch Memberships
    builder.addCase(fetchBusinesses.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchBusinesses.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.businesses = action.payload;
    });
    builder.addCase(fetchBusinesses.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Fetch Memberships
    builder.addCase(setActiveBusiness.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(setActiveBusiness.fulfilled, (state) => {
      state.status = 'fulfilled';
    });
    builder.addCase(setActiveBusiness.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Update
    builder.addCase(updateBusiness.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateBusiness.fulfilled, (state, action) => {
      state.status = 'fulfilled';
    });
    builder.addCase(updateBusiness.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
  },
  reducers: {
    clearStatus: (state) => {
      state.status = 'idle';
    },
    setOne: BusinessesAdapter.setOne,
    setMany(state, action) {
      BusinessesAdapter.setMany(state, action.payload);
    },
    removeOne: BusinessesAdapter.removeOne,
    removeMany: BusinessesAdapter.removeMany,
  },
});

const getBusinesses = createDraftSafeSelector(
  selectBusinessesState,
  (businesses) =>
    businesses?.businesses?.map((business) => ({
      id: business.objectId,
      name: business.name,
      status: business.status,
      imageUrl: business.logo_url,
      phoneNumber: business.business_phone,
      website: business.business_url,
    })),
);

const getActiveBusiness = createDraftSafeSelector(selectState, (state) => {
  const activeBusinesse = getBusinesses(state)?.filter(
    (business) => business.status,
  )[0];
  if (!activeBusinesse) {
    return undefined;
  }
  return activeBusinesse;
});

export const businessesSelectors = {
  getBusinesses,
  getActiveBusiness,
};

export const businessActions = {
  setActiveBusiness,
  fetchBusinesses,
  updateBusiness,
  ...BusinessesSlice.actions,
};

export default BusinessesSlice.reducer;

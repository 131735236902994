/* eslint-disable no-confusing-arrow */
import React, { useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import CheckoutDrawer from './CheckoutDrawer';
import { useNavigate } from 'react-router-dom';
import routes from 'routes';
import { subscriptionActions } from 'store/entities/Subscriptions';

const ALL_PACKAGES = [
  'No-Code Campaign Builder',
  'Unlimited Monthly Campaigns',
  'iOS & Android Mobile Team App',
  'Live Campaign Edit & Early Campaign Termination',
  'Unlimited Engagements',
  'Campaign & Team Member Metrics',
  'Team Leaderboard',
  'Digital Business Cards for all Team Members',
  'Team Announcement App Feature',
  'TappShare Jotter - A note-taking application',
  'Save to Wallet Campaign Feature',
  'Add to Calendar Campaign Feature',
];

const SelectPackage = ({ upgradePlan, setUpgradePlan }) => {
  const { t } = useTranslation();
  const [showCheckoutDrawer, setShowCheckoutDrawer] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({
    name: '',
    price: '',
    id: '',
  });

  const {
    subscriptions,
    subscriptionPackages,
    subscriptionType,
    subscriptionId,
    paymentId,
    defaultCard,
    subscriptionItemsId,
  } = useSelector((state: any) => state.subscriptions);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /* const { subscriptionPackages } = useSelector(
    (state: any) => state.subscriptionState,
  ); */

  const handlePlanChange = (plan) => {
    if (upgradePlan && paymentId) {
      dispatch(
        subscriptionActions.updateSubscription({
          planId: subscriptionId,
          paymentId,
          planDetails: {
            id: plan.id,
            siId: subscriptionItemsId,
          },
          card: defaultCard,
        }),
      );
      setUpgradePlan(false);
    } else {
      setSelectedPlan(plan);
      setShowCheckoutDrawer(true);
    }
  };

  const handleEnterprise = () => {
    const win: any = window.open('https://tappshare.io/', '_blank');
    win.focus();
  };

  const renderUserLicense = () => (
    <TableRow>
      <TableCell align="left">{t('includeUserLicense')}</TableCell>
      {subscriptionPackages.map((item) => (
        <TableCell align="center">{item.userLimit}</TableCell>
      ))}
      <TableCell align="center">{t('custom')}</TableCell>
    </TableRow>
  );

  const renderincludedActions = () => (
    <TableRow>
      <TableCell align="left">Included Actions (Monthly)</TableCell>
      {subscriptionPackages.map((item) => (
        <TableCell align="center">{item.includedAction}</TableCell>
      ))}
      <TableCell align="center">{t('custom')}</TableCell>
    </TableRow>
  );

  const renderCostAction = () => (
    <TableRow>
      <TableCell align="left">Cost / Extra Action Click</TableCell>
      {subscriptionPackages.map((item) => (
        <TableCell align="center">{`$${item.costAction}`}</TableCell>
      ))}
      <TableCell align="center">{t('custom')}</TableCell>
    </TableRow>
  );

  const renderLocationLicense = () => (
    <TableRow>
      <TableCell align="left">{t('includedTeams')}</TableCell>
      {subscriptionPackages.map((item) => (
        <TableCell align="center">{item.locationLimit}</TableCell>
      ))}
      <TableCell align="center">{t('unlimited')}</TableCell>
    </TableRow>
  );

  const renderShareableLinks = () => (
    <TableRow>
      <TableCell align="left">YouTube / Vimeo Video Feature</TableCell>
      {subscriptionPackages.map((item, index) => (
        <TableCell align="center">
          {index === 0 ? <CloseIcon /> : <CheckIcon />}
        </TableCell>
      ))}
      <TableCell align="center">
        <CheckIcon />
      </TableCell>
    </TableRow>
  );

  const renderQRCodes = () => (
    <TableRow>
      <TableCell align="left">MailChimp Email Integration</TableCell>
      {subscriptionPackages.map((item, index) => (
        <TableCell align="center">
          {index === 0 ? <CloseIcon /> : <CheckIcon />}
        </TableCell>
      ))}
      <TableCell align="center">
        <CheckIcon />
      </TableCell>
    </TableRow>
  );

  const renderAllChecks = (title) => (
    <TableRow>
      <TableCell align="left">{title}</TableCell>
      {subscriptionPackages.map(() => (
        <TableCell align="center">
          <CheckIcon />
        </TableCell>
      ))}
      <TableCell align="center">
        <CheckIcon />
      </TableCell>
    </TableRow>
  );

  const renderButtons = () => (
    <TableRow>
      <TableCell align="left" />
      {subscriptionPackages.map((plan) =>
        plan.name === 'Premium' ? (
          <TableCell align="center">
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                subscriptionType === plan.name
                  ? setUpgradePlan(false)
                  : handlePlanChange(plan)
              }
            >
              {upgradePlan
                ? subscriptionType === plan.name
                  ? 'Current'
                  : 'Downgrade'
                : t('subscribe')}
            </Button>
          </TableCell>
        ) : (
          <TableCell align="center">
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                subscriptionType === plan.name
                  ? setUpgradePlan(false)
                  : handlePlanChange(plan)
              }
            >
              {upgradePlan
                ? subscriptionType === plan.name
                  ? 'Current'
                  : 'Upgrade'
                : t('subscribe')}
            </Button>
          </TableCell>
        ),
      )}
      <TableCell align="center">
        <Button
          sx={{ whiteSpace: 'nowrap' }}
          variant="contained"
          color="primary"
          onClick={handleEnterprise}
        >
          {t('contactUs')}
        </Button>
      </TableCell>
    </TableRow>
  );

  return (
    <Box width="100%" textAlign="center" p={12.5}>
      <Typography variant="h4" color="primary" sx={{ marginBottom: 6.25 }}>
        {t('selectYourPackage')}
      </Typography>
      {subscriptionPackages && subscriptionPackages.length > 0 && (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              {subscriptionPackages.map((item) => (
                <TableCell>
                  <Box textAlign="center">
                    <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                      {item.name}
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 20 }}>
                      {`$${item.price}/month`}
                    </Typography>
                  </Box>
                </TableCell>
              ))}
              <TableCell>
                <Box textAlign="center">
                  <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                    {t('enterprise')}
                  </Typography>
                  <Typography sx={{ fontWeight: 'bold', fontSize: 20 }}>
                    {t('custom')}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderUserLicense()}
            {renderLocationLicense()}
            <TableRow>
              <TableCell align="left">Included Engagements</TableCell>
              {subscriptionPackages.map(() => (
                <TableCell align="center">{t('unlimited')}</TableCell>
              ))}
              <TableCell align="center">{t('unlimited')}</TableCell>
            </TableRow>
            {renderincludedActions()}
            {renderCostAction()}
            {ALL_PACKAGES.map((title) => renderAllChecks(title))}
            {renderShareableLinks()}
            {renderQRCodes()}
            {renderButtons()}
          </TableBody>
        </Table>
      )}

      <CheckoutDrawer
        open={showCheckoutDrawer}
        onClose={() => setShowCheckoutDrawer(false)}
        planName={selectedPlan.name}
        planDetails={selectedPlan}
        planId={selectedPlan.id}
        price={selectedPlan.price}
      />
    </Box>
  );
};

SelectPackage.prototype = {
  upgradePlan: PropTypes.bool,
  setUpgradePlan: PropTypes.func,
};

export default SelectPackage;

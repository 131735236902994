import React from 'react';

import { Box } from '@mui/material';

import Logo from 'components/Logo';
import Container from 'components/ui/auth/Container';
import Copyright from 'components/ui/auth/Copyright';

import Form from './components/Form';

export interface LoginProps { }

const PasswordReset: React.FC<LoginProps> = () => (
  <Container>
    <Box maxWidth={80}>
      <Logo />
    </Box>
    <Box pt={4}>
      <Form />
    </Box>
    <Box pt={4}>
      <Copyright />
    </Box>
  </Container>
);

export default PasswordReset;

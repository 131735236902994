import React, { useState } from 'react';

import Colors from 'constants/colors';
import { defaultStyles } from 'constants/styles';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';

import get from 'lodash.get';

import HeaderCell from './HeaderCell';

const MatTable: React.FC<any> = ({
  rows,
  columns,
  onRowClick,
  renderRow,
  renderBody,
  renderHeader,
  TablePaginationProps,
  TableProps,
}) => {
  const [order, setOrder] = useState<any>();
  const [orderBy, setOrderBy] = useState();

  const renderBodyCell = (row, column) => {
    if (column.render) {
      return column.render(row, column);
    }

    return get(row, column.key);
  };

  const renderHeaderCell = (column) => {
    if (column.hideHeader) {
      return null;
    }
    return column.name;
  };

  const desc = (a, b, oBy) => {
    let aInfo = a[oBy];
    let bInfo = b[oBy];
    const oByColumn = columns.find((c) => c.key === oBy);

    if (oByColumn && oByColumn.orderInfo) {
      aInfo = oByColumn.orderInfo(a);
      bInfo = oByColumn.orderInfo(b);
    }

    if (bInfo < aInfo) {
      return -1;
    }
    if (bInfo > aInfo) {
      return 1;
    }
    return 0;
  };

  const createSortHandler = (property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const o = cmp(a[0], b[0]);
      if (o !== 0) return o;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getSorting = (o, oBy) => {
    if (o === 'desc') {
      return (a, b) => desc(a, b, oBy);
    }
    return (a, b) => -desc(a, b, oBy);
  };

  const renderRowLocal = (row) => {
    if (renderRow) {
      return renderRow(row, columns);
    }

    return (
      <TableRow
        sx={{
          boxShadow: defaultStyles.box.shadow,
          backgroundColor: Colors.white,
          borderRadius: 2.5,
          '&:hover': {
            boxShadow: ' 0px 0px 0px 1px rgb(0 99 255 / 50%)',
            backgroundColor: `${Colors.white} !important`,
            cursor: 'pointer',
          },
        }}
        onClick={onRowClick ? () => onRowClick(row) : undefined}
      >
        {columns.map((column) => (
          <TableCell
            sx={{
              width: column.width,
              border: 'none',
              padding: 0,
              minHeight: 64,
            }}
            align={column.align}
          >
            {renderBodyCell(row, column)}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  const renderBodyLocal = () => {
    const sortedRows = stableSort(rows, getSorting(order, orderBy));
    if (renderBody) {
      return renderBody(sortedRows, columns);
    }

    return (
      <TableBody
        sx={{
          '& tr': {
            color: Colors.black,
            borderRadius: 2.5,
          },
          '& tr td:first-child': {
            borderTopLeftRadius: 2.5,
            borderBottomLeftRadius: 2.5,
            overflow: 'hidden',
          },
          '& tr td:last-child': {
            borderTopRightRadius: 2.5,
            borderBottomRightRadius: 2.5,
            overflow: 'hidden',
          },
        }}
      >
        {sortedRows.map(renderRowLocal)}
      </TableBody>
    );
  };

  const renderHeaderLocal = () => {
    if (renderHeader) {
      return renderHeader(columns);
    }

    return (
      <TableHead>
        {columns.map((column) => (
          column.hideHeader ?
          <TableCell />
           :
            <HeaderCell
              key={column.key}
              column={column}
              orderBy={orderBy}
              order={order}
              onCreateSortHandler={createSortHandler}
              style={{
                width: column.width,
              }}
            >
              {renderHeaderCell(column)}
            </HeaderCell>
        ))}
      </TableHead>
    );
  };

  return (
    <div>
      <Table
        sx={{
          borderSpacing: ' 0 10px !important',
          borderCollapse: 'separate !important' as any,
          '& .MuiTableCell-root': {
            border: 'none !important',
          },
        }}
        {...TableProps}
      >
        {renderHeaderLocal()}
        {renderBodyLocal()}
      </Table>

      <TablePagination component="div" {...TablePaginationProps} />
    </div>
  );
};

export default MatTable;

import * as React from 'react';
import { Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { styled } from '@mui/system';

import './styles.css';
import colors from 'constants/colors';

// const Container = styled('span')``;

export function ColorPicker(props) {
  return (
    <Box
      sx={{
        maxHeight: 53,
        padding: '8px',
        paddingX: '12px',
        background: '#EEF0F2',
        borderRadius: '10px',
        overflow: 'hidden',
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'inline-flex',
        width: '100%',
        border: props.border ? 1 : 'none',
        borderColor: props.border ? colors.primary : 'none',
      }}
      className="container"
    >
      <input className="inputText" type="text" {...props} />
      <label htmlFor="inputCol" className="colorCont">
        <input id="inputCol" className="inputColor" type="color" {...props} />
        <ExpandMoreIcon />
      </label>
    </Box>
  );
}

import React from 'react';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

const Empty = () => (
  <Box sx={{
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 6,
    paddingRight: 6,
    textAlign: 'center',
  }}
  >
    <DragIndicatorIcon sx={{
      width: 50,
      height: 50,
      marginBottom: 3,
    }}
    />
    <Typography variant="subtitle2">Drag and Drop a component</Typography>
  </Box>
);

export default Empty;

import React, { useState } from 'react';

import Colors from 'constants/colors';

import Autocomplete from '@mui/lab/Autocomplete';
import { Box } from '@mui/material';
import TextField from '@mui/material/TextField';

import { HiCursorClick } from 'react-icons/hi';
import { TbEdit } from 'react-icons/tb';

// TODO: Integregate as classes={{ popper: classes.popper }} in Autocomplete
// const useStyles: any = makeStyles({
//   popper: {
//     '& li': {
//       color: Colors.black,
//       fontSize: 14,
//     },
//   },
// });

const suggestedOptions = [
  'Learn More',
  'Book Now',
  'Apply Now',
  'Contact Us',
  'Donate Now',
  'Download',
  'Request Time',
  'See Menu',
  'Shop Now',
  'Sign Up',
];

const CTATextField: React.FC<any> = ({
  multiple,
  value,
  item,
  onChange,
  placeholder,
  size,
}) => {
  const [editInput, setEditInput] = useState(false);
  const [inputChange, setInputChange] = useState('');
  const [val, setVal] = useState('');
  const [updated, setUpdated] = useState(false);

  const onInputChange = (e, newInputValue) => {
    setUpdated(true);
    setInputChange(newInputValue);
  };

  React.useEffect(() => {
    setVal(value);
  }, [value]);

  React.useEffect(() => {
    // if (updated === true) {
    onChange(inputChange);
    // }
  }, [inputChange]);

  return (
    <Box width={1}>
      <Autocomplete
        freeSolo
        id="controllable-states-demo"
        disabled={!editInput}
        multiple={multiple}
        value={val}
        inputValue={val}
        onInputChange={onInputChange}
        onChange={onInputChange}
        disableClearable
        options={suggestedOptions.map((option) => option)}
        filterOptions={(option) => option}
        getOptionLabel={(option) => option}
        size={size || 'medium'}
        renderInput={(params) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              borderRadius: 2,
              // backgroundColor: 'rgba(226, 228, 229, 0.5)',
              px: 1,
            }}
          >
            <HiCursorClick size={25} />
            <TextField
              {...params}
              disabled={!editInput}
              placeholder={
                placeholder !== null && typeof placeholder !== 'undefined'
                  ? placeholder
                  : 'Button Text'
              }
              sx={{
                flexGrow: 1,
                cursor: 'pointer',
                // [`& fieldset`]: {
                //   borderRadius: 2,
                // },
                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                  borderColor: Colors.primary,
                  border: 'none',
                },
                '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
                  color: Colors.black,
                  fontSize: 14,
                },
                '& .MuiInputLabel-outlined': {
                  color: Colors.black,
                },
                '& .MuiInputBase-input::placeholder': {
                  color: Colors.primaryBlack,
                  opacity: 1,
                },
              }}
              variant="outlined"
              size={size || 'medium'}
              fullWidth
              InputProps={{ ...params.InputProps, type: 'search' }}
            />
            <TbEdit
              style={{ cursor: 'pointer' }}
              onClick={() => setEditInput(!editInput)}
              size={25}
            />
          </Box>
        )}
      />
    </Box>
  );
};

export default CTATextField;

import React, { useEffect, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { Autocomplete, Box, Chip, Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
//
//
import { styled } from '@mui/material/styles';
import Radio, { RadioProps } from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
//
//
import { FaArrowRightLong } from 'react-icons/fa6';
import { format, addDays } from 'date-fns';
//
//
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';

import { ReactComponent as AlertIcon } from 'assets/images/alert_icon.svg';
import PropTypes from 'prop-types';

import DateRangeDrawer from 'components/DateRangeDrawer';
import MatFormLabel from 'components/Elements/MatFormLabel/FormLabel';
import MatInput from 'components/Elements/MatInput/MatInput';
import MatSelect from 'components/Elements/MatSelect/MatSelect';
import { teamsActions, teamsSelectors } from 'store/entities/Teams';
import { useDispatch, useSelector } from 'react-redux';
import { membersSelectors } from 'store/entities/Members';
import colors from 'constants/colors';
import TeamMemberSelectTable from 'components/TeamMemberSelectTable';
import TeamsSelectTable from 'components/TeamsSelectTable';
import CalendarModal from './CalendarModal';
// import CalendarModal from './CalendarModal';

const BasicInfo = ({
  range,
  teamMembers,
  teams,
  handleRange,
  handleTeamMembers,
  handleTeams,
  expire,
  setExpire,
  locationId,
  actionsForExpire,
  setActionsForExpire,
  isAvailableForNewUsers,
  setIsAvailableForNewUsers,
  recipient,
  setRecipient,
  calenderDuration,
  setCalenderDuration,
}) => {
  const [showMembers, setShowMembers] = useState(false);
  const [showTeams, setShowTeams] = useState(false);
  const [showDuration, setShowDuration] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [showDateDrawer, setShowDateDrawer] = useState(false);
  const [members, setMembers] = useState<any[]>([]);

  const [createOpen, setCreateOpen] = useState(false);

  const dispatch = useDispatch();
  const activeTeamMembers = useSelector(membersSelectors.getActiveMembers);
  const activeLocations = useSelector(teamsSelectors.getActiveTeams);

  useEffect(() => {
    dispatch(teamsActions.fetchTeams({ filter: { status: 'active' } }));
  }, []);

  useEffect(() => {
    if (locationId !== 'all' && locationId) {
      setMembers(
        activeTeamMembers.filter((el) => el.locationId === locationId),
      );
    } else {
      setMembers(activeTeamMembers);
    }
  }, [activeTeamMembers]);

  useEffect(() => {
    if (recipient === 'all') {
      const allTeamMembersSelected = [...teamMembers];
      const allTeamsSelected = [...teams];
      activeTeamMembers.forEach((team: any) => {
        if (!allTeamMembersSelected.includes(team.id)) {
          allTeamMembersSelected.push(team.id);
        }
      });
      activeLocations.forEach((team: any) => {
        if (!allTeamsSelected.includes(team.id)) {
          allTeamsSelected.push(team.id);
        }
      });
      handleTeamMembers(allTeamMembersSelected);
      handleTeams(allTeamsSelected);
    }
  }, [recipient]);

  useEffect(() => {
    switch (recipient) {
      case 'members':
        setShowTeams(false);
        setShowMembers(true);
        break;
      case 'teams':
        setShowTeams(true);
        setShowMembers(false);
        break;
      case 'teamsmembers':
        setShowMembers(true);
        setShowTeams(true);
        break;
      default:
        setShowMembers(false);
        setShowTeams(false);
        break;
    }
  }, [recipient]);

  useEffect(() => {
    switch (expire) {
      case 'date':
        setShowDuration(true);
        setShowActions(false);
        break;
      case 'action':
        setShowActions(true);
        setShowDuration(false);
        break;
      case 'no':
        setShowDuration(false);
        setShowActions(false);
        break;
      default:
        setShowDuration(false);
        setShowActions(false);
        break;
    }
  }, [expire]);

  const handleDateRange = (value) => {
    handleRange(value);
    setShowDateDrawer(false);
  };

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box
      className="folderCard folderCard2"
      sx={{
        marginTop: 4,
      }}
    >
      <List
        sx={{
          borderRadius: 2,
          backgroundColor: '#ffffff',
          boxShadow: '0 2px 7px 0 rgba(111,135,183,0.2)',
          padding: '10px',
          '&:hover': {
            backgroundColor: 'none',
          },
        }}
        // onClick={handleClick}
      >
        <ListItemButton
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            fontWeight: 600,
          }}
          onClick={handleClick}
        >
          Your Card
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* <MatFormLabel
              icon={
                <Tooltip title="Select which location to add the team member to">
                  <AlertIcon />
                </Tooltip>
              }
            >
              Make Available for new members ?
            </MatFormLabel>
            <MatSelect
              value={isAvailableForNewUsers}
              onChange={(e) => setIsAvailableForNewUsers(e.target.value)}
              options={[
                {
                  name: 'Yes',
                  id: true,
                },
                {
                  name: 'No',
                  id: false,
                },
              ]}
              nameKey="name"
              valueKey="id"
              size="small"
            /> */}
            {/* <MatFormLabel
              icon={
                <Tooltip title="Select which location to add the team member to">
                  <AlertIcon />
                </Tooltip>
              }
            >
              Does content expire?
            </MatFormLabel>
            <MatSelect
              value={expire}
              onChange={(event) => setExpire(event.target.value)}
              options={[
                {
                  name: 'No',
                  id: 'no',
                },
                {
                  name: 'Yes - By Date',
                  id: 'date',
                },
                {
                  name: 'Yes - By Action Count',
                  id: 'action',
                },
              ]}
              nameKey="name"
              valueKey="id"
              size="small"
            /> */}

            <CustomizedRadios
              isAvailableForNewUsers={isAvailableForNewUsers}
              setIsAvailableForNewUsers={setIsAvailableForNewUsers}
              expire={expire}
              setExpire={setExpire}
            />
            {showActions && (
              <FormControl
                variant="outlined"
                sx={{
                  marginBottom: 2,
                  width: '100%',
                }}
                margin="dense"
              >
                <MatFormLabel
                  icon={
                    <Tooltip title="Enter title to be associated with this campaign">
                      <AlertIcon />
                    </Tooltip>
                  }
                >
                  Actions Count
                </MatFormLabel>
                <MatInput
                  value={actionsForExpire.toString()}
                  variant="outlined"
                  label="Actions Count"
                  onChange={(e) => {
                    setActionsForExpire(Number(e.target.value));
                  }}
                  size="small"
                  type="number"
                  inputProps={{ min: 0, max: 100 }}
                />
              </FormControl>
            )}
            {showDuration && (
              <Box>
                <MatFormLabel
                  icon={
                    <Tooltip title="Set the duration that this campaign will run for">
                      <AlertIcon />
                    </Tooltip>
                  }
                >
                  Duration
                </MatFormLabel>
                <Box
                  sx={{
                    width: '100%',
                    // paddingTop: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {/* <Typography
                    onClick={() => setShowDateDrawer(true)}
                    onKeyDown={() => setShowDateDrawer(true)}
                    tabIndex={0}
                    role="button"
                    sx={{ fontSize: '16px' }}
                    variant="subtitle1"
                  >
                    {`${range[0].format('MMM D, YYYY')} - ${range[1].format(
                      'MMM D, YYYY',
                    )}`}
                  </Typography> */}
                  <Typography
                    sx={{
                      fontSize: '16px',
                    }}
                    variant="subtitle1"
                  >
                    {format(calenderDuration[0].startDate, 'MMM do, yyyy')}
                    <span
                      style={{
                        marginLeft: '8px',
                        marginRight: '8px',
                        paddingTop: '8px',
                      }}
                    >
                      <FaArrowRightLong color="#007AFF" />
                    </span>
                    {format(calenderDuration[0].endDate, 'MMM do, yyyy')}
                  </Typography>

                  <Box
                    onClick={() => setCreateOpen(true)}
                    onKeyDown={() => setCreateOpen(true)}
                    tabIndex={0}
                    role="button"
                    sx={{
                      cursor: 'pointer',
                      backgroundColor: '#D9EBFF',
                      borderRadius: '8px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginLeft: 8,
                      width: '28px',
                      height: '28px',
                    }}
                  >
                    <EditIcon sx={{ color: '#007AFF', fontSize: '18px' }} />
                  </Box>
                </Box>
              </Box>
            )}
          </List>
        </Collapse>
      </List>

      <List
        sx={{
          mt: 3,
          borderRadius: 2,
          backgroundColor: '#ffffff',
          boxShadow: '0 2px 7px 0 rgba(111,135,183,0.2)',
          padding: '10px',
          paddingTop: '10px',
          paddingBottom: '10px',
          '&:hover': {
            backgroundColor: 'none',
          },
        }}
      >
        <ListItemButton
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            fontWeight: 600,
          }}
        >
          Recipients
        </ListItemButton>
        <List component="div" disablePadding>
          {/* <MatFormLabel
            icon={
              <Tooltip title="Select which location to add the team member to">
                <AlertIcon />
              </Tooltip>
            }
          >`
            Choose Recipients
          </MatFormLabel> */}
          <MatSelect
            value={recipient}
            onChange={(event) => setRecipient(event.target.value)}
            options={[
              {
                name: 'Select one option',
                id: 'blank',
              },
              {
                name: 'All Members and Teams',
                id: 'all',
              },
              {
                name: 'Select Members',
                id: 'members',
              },
              {
                name: 'Select Teams',
                id: 'teams',
              },
              {
                name: 'Select members and teams',
                id: 'teamsmembers',
              },
            ]}
            nameKey="name"
            valueKey="id"
            size="small"
          />
          {showMembers && (
            <Box mb={3}>
              {/* <MatFormLabel
                icon={
                  <Tooltip title="Select the team members to add to this card">
                    <AlertIcon />
                  </Tooltip>
                }
              >
                Select Team Members
              </MatFormLabel> */}
              <TeamMemberSelectTable
                selected={teamMembers}
                activeTeamMembers={members}
                handleTeamMemberSelect={(members) => handleTeamMembers(members)}
              />
            </Box>
          )}
          {showTeams && (
            <>
              {/* <MatFormLabel
                icon={
                  <Tooltip title="Select the teams to add to this card">
                    <AlertIcon />
                  </Tooltip>
                }
              >
                Select Teams
              </MatFormLabel> */}
              <TeamsSelectTable
                selected={teams}
                handleTeamSelect={(teams) => handleTeams(teams)}
              />
            </>
          )}
          <DateRangeDrawer
            open={showDateDrawer}
            onClose={() => setShowDateDrawer(false)}
            onSave={handleDateRange}
            startDate={range[0]}
            endDate={range[1]}
            allowTime={true}
          />
          <Calendar
            open={createOpen}
            onClose={() => setCreateOpen(false)}
            calenderDuration={calenderDuration}
            setCalenderDuration={setCalenderDuration}
          />
        </List>
      </List>
    </Box>
  );
};

BasicInfo.propTypes = {
  range: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleRange: PropTypes.func.isRequired,
  handleTeamMembers: PropTypes.func.isRequired,
  handleTeams: PropTypes.func.isRequired,
  teamMembers: PropTypes.arrayOf(PropTypes.string),
  teams: PropTypes.arrayOf(PropTypes.string),
  expire: PropTypes.string.isRequired,
  setExpire: PropTypes.func.isRequired,
  recipient: PropTypes.string.isRequired,
  setRecipient: PropTypes.func.isRequired,
  actionsForExpire: PropTypes.number.isRequired,
  setActionsForExpire: PropTypes.func.isRequired,
  isAvailableForNewUsers: PropTypes.bool.isRequired,
  setIsAvailableForNewUsers: PropTypes.func.isRequired,
  locationId: PropTypes.string,
  calenderDuration: PropTypes.arrayOf(PropTypes.any).isRequired,
  setCalenderDuration: PropTypes.func.isRequired,
};

BasicInfo.defaultProps = {
  teamMembers: [],
  locationId: 'all',
};

// const BpIcon = styled('span')(({ theme }) => ({
//   borderRadius: '15%',
//   width: 16,
//   height: 16,
//   boxShadow:
//     theme.palette.mode === 'dark'
//       ? '0 0 0 1px rgb(16 22 26 / 40%)'
//       : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
//   backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
//   backgroundImage:
//     theme.palette.mode === 'dark'
//       ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
//       : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
//   '.Mui-focusVisible &': {
//     outline: '2px auto rgba(19,124,189,.6)',
//     outlineOffset: 2,
//   },
//   'input:hover ~ &': {
//     backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
//   },
//   'input:disabled ~ &': {
//     boxShadow: 'none',
//     background:
//       theme.palette.mode === 'dark'
//         ? 'rgba(57,75,89,.5)'
//         : 'rgba(206,217,224,.5)',
//   },
// }));

// const BpCheckedIcon = styled(BpIcon)({
//   backgroundColor: '#2085FF',
//   backgroundImage:
//     'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
//   '&:before': {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     width: 15,
//     height: 15,
//     color: '#ffffff',
//     fontWeight: 500,
//     margin: 'auto',
//     fontSize: 13,
//     content: '"\u2713"',
//   },
//   'input:hover ~ &': {
//     backgroundColor: '#2085FF',
//   },
// });

// // Inspired by blueprintjs
// function BpRadio(props: RadioProps) {
//   return (
//     <Radio
//       disableRipple
//       color="default"
//       checkedIcon={<BpCheckedIcon />}
//       icon={<BpIcon />}
//       {...props}
//     />
//   );
// }

function CustomizedRadios({
  isAvailableForNewUsers,
  setIsAvailableForNewUsers,
  expire,
  setExpire,
}) {
  const handleCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAvailableForNewUsers(event.target.checked);
  };

  const handleExpiringChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExpire((event.target as HTMLInputElement).value);
  };

  return (
    <>
      <FormControlLabel
        sx={{ marginBottom: 2 }}
        required
        control={
          <Checkbox
            size="small"
            checked={isAvailableForNewUsers}
            onChange={handleCheckedChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        }
        label="Make Available for New Members"
      />
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">
          Does content expire?
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="no"
          name="radio-buttons-group"
          value={expire}
          onChange={handleExpiringChange}
        >
          <FormControlLabel value="no" control={<Radio />} label="No" />
          <FormControlLabel
            value="date"
            control={<Radio />}
            label="Content Expiration date"
          />
          <FormControlLabel
            value="action"
            control={<Radio />}
            label="Action Expiration"
          />
        </RadioGroup>
      </FormControl>
    </>
  );
}

function Calendar({ open, onClose, calenderDuration, setCalenderDuration }) {
  return (
    <CalendarModal
      open={open}
      onClose={onClose}
      onCancel={onClose}
      // title="Compose Email"
    >
      <Box>
        <DateRange
          months={2}
          linkedCalendars={true}
          direction="horizontal"
          moveRangeOnFirstSelection={false}
          minDate={new Date()}
          ranges={calenderDuration}
          editableDateInputs={true}
          onChange={(item) => setCalenderDuration([item.selection])}
          theme={{
            Calendar: { width: 100 },
            PredefinedRanges: { marginLeft: 10, marginTop: 10 },
          }}
        />
        <Box
          sx={{
            boxShadow: '0 2px 7px 0 rgba(111,135,183,0.2)',
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: 3,
            pb: 2,
          }}
        >
          <Box className="example-custom-input">
            {format(calenderDuration[0].startDate, 'MMM do, yyyy')}
            <span
              style={{
                marginLeft: '8px',
                marginRight: '8px',
                paddingTop: '8px',
              }}
            >
              <FaArrowRightLong color="#007AFF" />
            </span>
            {format(calenderDuration[0].endDate, 'MMM do, yyyy')}
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Button
              sx={{
                backgroundColor: 'none',
                py: 0.3,
                color: '#9A9A9A',
                fontSize: 12,
                '&:hover': {
                  backgroundColor: 'none',
                },
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: '#2085FF',
                color: '#ffffff',
                py: 0.3,
                fontSize: 12,
                '&:hover': {
                  backgroundColor: '#2085FF',
                },
              }}
              onClick={onClose}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </CalendarModal>
  );
}

export default BasicInfo;

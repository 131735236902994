import React from 'react';

import Colors from 'constants/colors';

import VariableWidthDrawer, {
  VariableWidthDrawerProps,
} from 'components/VariableWidthDrawer';

const DRAWER_WIDTH = 245;
const NAVIGATION_RAIL_WIDTH = 75;

export interface ToolBarDrawerProps
  extends Omit<VariableWidthDrawerProps, 'width'> {
  railWidth?: boolean;
}

const ToolBarDrawer: React.FC<ToolBarDrawerProps> = ({
  railWidth,
  sx,
  ...props
}) => (
  <VariableWidthDrawer
    width={railWidth ? NAVIGATION_RAIL_WIDTH : DRAWER_WIDTH}
    anchor="right"
    sx={{
      '& .MuiDrawer-paper': {
        backgroundColor: Colors.white,
        color: '#000000de',
        overflow: 'hidden',
      },
      ...sx,
    }}
    {...props}
  />
);

export default ToolBarDrawer;

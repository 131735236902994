import React, { useEffect, useState } from 'react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { MembersActions } from 'store/entities/Members';
import useWindowSize from 'utils/hooks/useWindowSize';

import MatDrawer from 'components/Elements/MatDrawer/MatDrawer';
import Title from 'components/Elements/Title/Title';
import CustomModalV2 from 'components/Elements/CustomModalV2/CustomModalV2';

// const data = [
//   {
//     engagements: 10,
//     impressions: 5,
//     actions: 12,
//   },
// ];

const TeamMemberDrawer = ({ open, onClose, teamMember }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [statsOverview, setStatsOverview] = useState<any>({});
  const [statsBreakdown, setStatsBreakdown] = useState([]);
  const [loadingStats, setLoadingStats] = useState(false);

  const { memberStats } = useSelector((state: any) => state.members);

  useEffect(() => {
    if (memberStats) {
      setStatsOverview({
        engagements: memberStats.engagements,
        impressions: memberStats.impressions,
        actions: memberStats.conversions,
      });
      setStatsBreakdown(memberStats.breakdown);
      setLoadingStats(false);
    }
  }, [memberStats]);

  const { width } = useWindowSize();
  const chartSize = width && width > 600 ? 500 : 300;

  useEffect(() => {
    if (teamMember) {
      setLoadingStats(true);
      dispatch(MembersActions.getMemberStats({ teamMemberId: teamMember.id }));
    }
  }, [teamMember]);

  const loadingUpdate = false;

  return (
    // <MatDrawer open={open} anchor="right" onClose={onClose} title="User">
    <CustomModalV2
      open={open}
      onClose={onClose}
      // onSubmit={handleSave}
      isLoading={loadingUpdate}
    >
      <Box sx={{ mb: 1, mt: 2 }}>
        <Title size="big">User</Title>
      </Box>
      {teamMember && (
        <Box width={550} textAlign="center">
          <Box display="flex" justifyContent="center" width="100%" mb={2}>
            <Avatar
              sx={{ width: 80, height: 80 }}
              alt={teamMember.name}
              src={teamMember.photoUrl}
            />
          </Box>
          <Typography variant="h6">{teamMember.name}</Typography>
          {loadingStats ? (
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              alignItems="center"
              textAlign="start"
              mt={3.75}
              mb={3.75}
            >
              <CircularProgress />
              <Typography>{t('fetchingMetrics')}</Typography>
            </Box>
          ) : (
            <div>
              <Box width="100%" textAlign="start" mt={3.75} mb={3.75}>
                <Typography variant="h6">{t('metricsYtd')}</Typography>
                <Box width="100%" display="flex" alignItems="center" mt={1.25}>
                  <Box flexGrow={1} textAlign="center">
                    <Typography variant="caption">{t('engagments')}</Typography>
                    <Typography variant="h6">
                      {statsOverview ? statsOverview.engagements : 0}
                    </Typography>
                  </Box>
                  <Box flexGrow={1} textAlign="center">
                    <Typography variant="caption">
                      {t('impressions')}
                    </Typography>
                    <Typography variant="h6">
                      {statsOverview ? statsOverview.impressions : 0}
                    </Typography>
                  </Box>
                  <Box flexGrow={1} textAlign="center">
                    <Typography variant="caption">{t('actions')}</Typography>
                    <Typography variant="h6">
                      {statsOverview ? statsOverview.actions : 0}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {statsBreakdown && statsBreakdown.length > 0 && (
                <LineChart width={chartSize} height={250} data={statsBreakdown}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="engagements"
                    stroke="#673AB7"
                  />
                  <Line
                    type="monotone"
                    dataKey="impressions"
                    stroke="#03A9F4"
                  />
                  <Line type="monotone" dataKey="actions" stroke="#4CAF50" />
                </LineChart>
              )}
            </div>
          )}
        </Box>
      )}
    </CustomModalV2>
    // </MatDrawer>
  );
};

TeamMemberDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  teamMember: PropTypes.object,
};

TeamMemberDrawer.defaultProps = {
  teamMember: null,
};

export default TeamMemberDrawer;

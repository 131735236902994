import React, { useState, useEffect, useRef } from 'react';

import Colors from 'constants/colors';

import { Card, Box, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';

import Image from './Image';
import MissingAction from './MissingAction';
import { capitalizeWords } from 'utils/formatTools';
import MatInput from 'components/Elements/MatInput/MatInput';
import Styling from 'components/Styling';

const InfoCard = ({
  description,
  active,
  setValue,
  image,
  title,
  tags,
  ctaOne,
  styling,
  handleClickTag,
  onUpdate,
  item,
  handleTitle,
  tabIndex,
  itemStyling,
}) => {
  const [descrip, setDescription] = useState('');
  const [ctaOneInfo, setCtaOneInfo] = useState<any>();
  const editableRef: any = useRef();

  useEffect(() => {
    if (description) {
      setDescription(description);
    }
  }, [description]);

  useEffect(() => {
    if (ctaOne) {
      setCtaOneInfo(ctaOne);
    }
  }, []);

  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
    const object: any = { ...item };
    object.description = event.target.value;
    onUpdate(object);
  };

  return (
    <Box
      sx={{
        marginLeft: 1,
        marginRight: 1,
      }}
    >
      <Card
        sx={[
          { borderRadius: '24px', paddingTop: 2, marginBottom: 3 },
          active
            ? {
                textAlign: 'left',
                // borderColor: Colors.primaryLight,
                // borderStyle: 'solid',
                borderRadius: styling?.cardBorder,
                borderWidth: 3,
                position: 'relative',
              }
            : {
                width: '100%',
                textAlign: 'left',
                cursor: 'pointer',
                borderRadius: styling?.cardBorder,
                position: 'relative',
              },
        ]}
      >
        <Box
          sx={{
            width: '100%',
            position: 'relative',
            cursor: 'pointer',
          }}
          onClick={() => setValue(3)}
        >
          <Image image={image} />
        </Box>
        <CardContent
          sx={{
            paddingTop: 2,
            paddingLeft: 3,
            paddingRight: 3,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 1,
            }}
          >
            <MatInput
              sx={{
                '& .MuiInputBase-input': {
                  fontSize: 16,
                },
              }}
              id="standard-basic"
              label="Title"
              variant="standard"
              value={title}
              onChange={handleTitle}
            />
          </Box>
          <MatInput
            id="standard-multiline-static"
            InputProps={{ disableUnderline: true }}
            label="Description"
            multiline
            rows={3}
            maxRows={4}
            sx={{
              marginBottom: 2,
              width: '100%',
              '& .MuiInputBase-input': {
                fontSize: styling?.mainSize,
                fontStyle: styling?.mainFormat?.includes('italic')
                  ? 'italic'
                  : '',
                textDecoration: styling?.mainFormat?.includes('underline')
                  ? 'underline'
                  : '',
                fontWeight: styling?.mainFormat?.includes('bold') ? 'bold' : '',
                color: `${styling?.mainColor} !important`,
                textAlign: styling?.mainAlignment,
              },
              // '& .MuiInputBase-root': {},
            }}
            onFocus={() => setValue(1)}
            value={descrip}
            onChange={handleChangeDescription}
            variant="standard"
          />
          {ctaOne.link ? (
            <Button
              color="primary"
              variant={styling?.buttonVariant}
              disableElevation
              onClick={() => {
                if (setValue) {
                  setValue(5);
                } else {
                  const link = ctaOne.link.includes('https://')
                    ? ctaOne.link
                    : `https://${ctaOne.link}`;
                  const win = window.open(link);
                  win?.focus();
                }
              }}
              sx={{
                width: '100%',
                margin: 'auto',
                whiteSpace: 'nowrap',
                minWidth: 'inherit',
                borderRadius: styling?.buttonBorder,
                color: styling?.textButtonColor,
                height: 38,
                borderColor:
                  styling?.buttonVariant === 'outlined'
                    ? styling?.buttonColor
                    : '',
                backgroundColor:
                  styling?.buttonVariant === 'contained'
                    ? styling?.buttonColor
                    : '',
                textTransform: 'initial',
                '&:hover': {
                  color: styling?.textButtonColor,
                  backgroundColor:
                    styling?.buttonVariant === 'contained'
                      ? styling?.buttonColor
                      : '',
                },
              }}
            >
              {capitalizeWords(ctaOne.name)}
            </Button>
          ) : (
            <Button
              color="primary"
              variant="contained"
              disableElevation
              onClick={() => {
                setValue(5);
              }}
              sx={{
                height: 38,
                width: '100%',
              }}
            >
              Button
            </Button>
          )}
        </CardContent>
      </Card>
      <Card
        sx={{
          borderRadius: '18px',
          paddingTop: 1,
          paddingBottom: 1,
          paddingRight: 2,
          borderWidth: 3,
          marginBottom: 3,
        }}
      >
        <CardActions
          sx={{
            paddingLeft: 1,
            paddingRight: 1,
            paddingBottom: 0.5,
            display: 'flex',
          }}
        >
          <Box style={{ flexGrow: 1 }}>
            <Styling
              tabIndex={tabIndex}
              item={itemStyling}
              onUpdate={(item) => {
                onUpdate(item);
              }}
            />
          </Box>
        </CardActions>
      </Card>
      <Card
        sx={{
          borderRadius: '18px',
          paddingTop: 1,
          paddingBottom: 1,
          paddingRight: 2,
          marginBottom: 3,
          borderWidth: 3,
        }}
      >
        <CardActions
          sx={{
            paddingLeft: 1,
            paddingRight: 1,
            paddingBottom: 0.5,
            display: 'flex',
          }}
        >
          <Box style={{ flexGrow: 1 }}>
            <IconButton
              sx={{ marginRight: 2 }}
              aria-label="tag"
              onClick={handleClickTag}
            >
              <LocalOfferIcon />
            </IconButton>
            <List
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                rowGap: 2,
                paddingLeft: 1,
                alignItems: 'center',
              }}
            >
              {tags &&
                tags.map((tagsName) => (
                  <Chip
                    sx={{
                      margin: '4px 10px 4px 0',
                      borderRadius: '10px',
                      backgroundColor: '#D9EBFF',
                      color: '#007AFF',
                    }}
                    label={tagsName}
                  />
                ))}
            </List>
          </Box>
        </CardActions>
      </Card>
    </Box>
  );
};

InfoCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  ctaOne: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  styling: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  handleTitle: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired,
  itemStyling: PropTypes.object.isRequired,
};

export default InfoCard;

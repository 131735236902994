/* eslint-disable no-underscore-dangle */
/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';

import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { Button, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';

import { ReactComponent as AlertIcon } from 'assets/images/alert_icon.svg';
import PropTypes from 'prop-types';
import { TwitterPicker } from 'react-color';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { TeamHubActions } from 'store/entities/TeamHub';

import { ColorPicker } from 'components/ColorPicker';
import BasicPopover from 'components/Elements/BasicPopover/BasicPopover';
import MatFormLabel from 'components/Elements/MatFormLabel/FormLabel';
import MatInput from 'components/Elements/MatInput/MatInput';
import { UploadImage } from 'components/ImageSelectors';

import FolderModal from './FolderModal';
import { SVG_images } from './folder_svg/SvgData';

const AVAILABLE_COLORS = [
  '#f44336',
  '#E91E63',
  '#673AB7',
  '#2196F3',
  '#00BCD4',
  '#4CAF50',
  '#FFC107',
  '#FF9800',
  '#607D8B',
  '#ef9a9a',
  '#F48FB1',
  '#B39DDB',
  '#90CAF9',
  '#80DEEA',
  '#A5D6A7',
  '#FFE082',
  '#FFCC80',
  '#B0BEC5',
];

const CreateFolder = ({ open, onClose, isEditing, folderData }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [color, setColor] = useState('#ffffff');
  const [image, setImage] = useState('');
  const [icon, setIcon] = useState<any>({
    iconFun: renderToString(SVG_images[1].icon()),
    name: SVG_images[1].icon().props.id,
  });

  useEffect(() => {
    if (isEditing) {
      setColor(folderData.color);
      setName(folderData.name);
      if (folderData.icon) setIcon(JSON.parse(folderData.icon));
    }
  }, [folderData]);

  /* const { loadingCreate } = useSelector((s: any) => s.locationsState); */

  const handleColor = (e) => {
    setColor(e.target.value);
  };

  const onSubmit = () => {
    if (name !== '' && color !== '' && icon !== '') {
      const iconJSONString = JSON.stringify(icon);
      if (name && name !== '') {
        if (isEditing) {
          dispatch(
            TeamHubActions.updateFolder({
              name,
              color,
              icon: iconJSONString,
              folderId: folderData.id,
            }),
          );
        } else {
          dispatch(
            TeamHubActions.createFolder({ name, color, icon: iconJSONString }),
          );
        }
        onClose();
      }
      setName('');
      setColor('');
      setIcon('');
    }
  };

  const loadingUpdate = false;

  return (
    <FolderModal
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      isLoading={false}
    >
      <Box minWidth={250} maxWidth={300}>
        <Grid spacing={1}>
          <Grid item md={12} xs={12} sx={{ mb: 1 }}>
            <MatFormLabel
              icon={
                <Tooltip title="add folder name">
                  <AlertIcon />
                </Tooltip>
              }
            >
              Folder Name
            </MatFormLabel>
            <MatInput
              value={name}
              variant="outlined"
              placeholder=""
              size="small"
              onChange={(e) => setName(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  maxHeight: 53,
                  paddingY: '4px',
                  background: '#EEF0F2',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  display: 'inline-flex',
                },
                [`& fieldset`]: {
                  borderColor: Colors.primary,
                  width: '100%',
                },
              }}
            />
          </Grid>
          <Grid item>
            <MatFormLabel
              icon={
                <Tooltip title="Choose folder color">
                  <AlertIcon />
                </Tooltip>
              }
            >
              Folder Color
            </MatFormLabel>

            <ColorPicker border onChange={handleColor} value={color} />
            {/* <TwitterPicker
              style={{ minWidth: 238 }}
              onChange={handleColor}
              colors={AVAILABLE_COLORS}
              triangle="hide"
            /> */}
          </Grid>
          <Grid item md={12} xs={12} sx={{ mb: 3 }}>
            <MatFormLabel
              icon={
                <Tooltip title="add folder icon">
                  <AlertIcon />
                </Tooltip>
              }
            >
              Folder Icon
            </MatFormLabel>
            <Grid container spacing={2}>
              {SVG_images.map((images_) => {
                const icon_string = images_.icon();
                return (
                  <Grid
                    key={images_.image}
                    onClick={() => {
                      setIcon({
                        iconFun: renderToString(icon_string),
                        name: icon_string.props.id,
                      });
                    }}
                    sx={{ cursor: 'pointer' }}
                    item
                    xs={2}
                    md={2}
                  >
                    <Box
                      sx={[
                        {
                          border: 3,
                          borderColor: 'transparent',
                          borderRadius: '5px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        },
                        icon.name === icon_string.props.id && {
                          borderColor: Colors.primary,
                        },
                      ]}
                    >
                      <img alt="Apple Calendar" src={images_.image} />
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item md={12} xs={12}>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Box display="flex" alignItems="center" gap={2}>
                {loadingUpdate ? (
                  <CircularProgress
                    style={{ width: 40, height: 40, color: Colors.primary }}
                  />
                ) : (
                  <>
                    <Button
                      disableElevation={true}
                      color="primary"
                      onClick={onClose}
                      sx={{
                        color: Colors.black,
                        textTransform: 'capitalize',
                      }}
                    >
                      {t('cancel')}
                    </Button>
                    <Button
                      disableElevation={true}
                      color="primary"
                      // eslint-disable-next-line no-confusing-arrow
                      onClick={onSubmit}
                      sx={{
                        textTransform: 'capitalize',
                        fontWeight: 600,
                        background: '#D9EBFF',
                        borderRadius: '8px',
                        px: '18px',
                        py: '6px',
                        maxHeight: 'fit-content',
                        height: 'fit-content',
                        '&:hover': {
                          background: '#D9EBFF',
                        },
                      }}
                    >
                      {isEditing ? 'Save' : 'Create'}
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </FolderModal>
  );
};

CreateFolder.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  folderData: PropTypes.object,
};

export default CreateFolder;

import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import 'react-quill/dist/quill.bubble.css';

import ReactQuill from 'react-quill';

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
  ],
};

const ReactQuillStyled = styled(ReactQuill, {
  shouldForwardProp: (prop) => prop !== 'theme',
  overridesResolver: (props) => [props.theme === 'bubble'],
})``;

const formats = [
  'bold',
  'italic',
  'underline',
  'strike',
  'list',
  'bullet',
  'indent',
  'link',
];

export interface RichTextEditorProps {
  placeholder?: string;
  defaultValue?: string;
  size?: string;
  key?: string;
  disabled?: boolean;
  onChange: (a: any, b: any) => any;
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({
  placeholder,
  defaultValue,
  size,
  key,
  disabled,
  onChange,
}) => {
  const [height, setHeight] = useState(150);

  useEffect(() => {
    switch (size) {
      case 'small':
        setHeight(100);
        break;
      case 'medium':
        setHeight(150);
        break;
      case 'large':
        setHeight(200);
        break;
      case 'extra':
        setHeight(260);
        break;
      default:
        setHeight(150);
        break;
    }
  }, [size]);

  const handleChange = (value, delta, source, editor) => {
    onChange(editor.getText(), value);
  };

  return (
    <Box width="100%" mb={4}>
      <ReactQuillStyled
        id={key}
        defaultValue={defaultValue}
        modules={modules}
        formats={formats}
        readOnly={disabled}
        placeholder={placeholder || 'Enter a description'}
        onChange={handleChange}
        sx={{
          '& .ql-editor>*': {
            fontSize: 14,
          },
          '& .ql-toolbar.ql-snow': {
            display: 'none',
            // borderColor: "Colors.primary",
            // borderColor: 'grey',
            borderTop: 'none',
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
          },
          '& .ql-toolbar.ql-snow + .ql-container.ql-snow': {
            // borderColor: Colors.primary,
            // borderColor: 'grey',
            border: 'none',
            // border: 1,
            color: Colors.black,
            borderBottomRightRadius: 4,
            borderBottomLeftRadius: 4,
          },
          '& .ql-editor.ql-blank::before': {
            fontStyle: 'normal',
            color: Colors.black,
            fontSize: 14,
          },
          height,
        }}
      />
    </Box>
  );
};

export default RichTextEditor;

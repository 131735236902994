import React, { useState } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';

type PasswordTextFieldProps = TextFieldProps;

const PasswordTextField = React.forwardRef<HTMLDivElement, PasswordTextFieldProps>(
  ({ InputProps, ...props }, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    const handlePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    return (
      <TextField
        ref={ref}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handlePasswordVisibility} edge="end">
                {showPassword ? (
                  <VisibilityOffIcon />
                ) : (
                  <VisibilityIcon />
                )}
              </IconButton>
            </InputAdornment>
          ),
          ...InputProps,
        }}
        {...props}
      />
    );
  },
);

export default PasswordTextField;

import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

import CardContainer from 'components/Elements/CardContainer/CardContainer';
import Title from 'components/Elements/Title/Title';

const MetricsItem = ({ count, title, icon }) => (
  <CardContainer
    sx={(theme) => ({
      borderRadius: '20px',
      width: '280px',
      [theme.breakpoints.up('lg')]: {
        width: '88%',
      },
      marginX: 'auto',
    })}
  >
    <Box height="fit-content !important" p="20px" gap="20px" display="flex">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width="40%"
        p="20px"
        height="fit-content"
        borderRadius="16px"
        bgcolor="#D9EBFF"
      >
        {/* <img style={{ height: '58px' }} src={icon} alt={title} /> */}
        {icon}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        paddingY="5px"
        height="fit-content"
        // justifyContent="space-between"
        width="65%"
        // alignItems="center"
      >
        <Typography
          variant="h6"
          noWrap
          sx={{
            fontSize: 14,
            color: '#515C66',
            fontWeight: 500,
            textTransform: 'capitalize',
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontSize: 22,
            color: '#515C66',
            fontWeight: 600,
          }}
        >
          {Number.isInteger(count) ? count : 0}
        </Typography>
      </Box>
    </Box>
  </CardContainer>
);

MetricsItem.propTypes = {
  count: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default MetricsItem;

import React from 'react';

import Colors from 'constants/colors';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import Logo from 'assets/images/logo_blue.png';
import PropTypes from 'prop-types';

const EmulatorDrawer = ({ open, onClose, conditions }) => {
  if (!open) {
    return <div />;
  }

  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      pl={2.1}
      pr={2.1}
      pt={2.1}
      pb={2.1}
    >
      <Box
        bgcolor="rgba(0,0,0,.25)"
        width="100%"
        height="100%"
        display="flex"
        onClick={onClose}
        onKeyDown={onClose}
        tabIndex={0}
        role="button"
      >
        <Box flexGrow={1} />
        <Paper
          elevation={2}
          sx={{
            width: 130,
            height: '100%',
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            flexGrow={1}
            pl={1}
            pr={1}
            pt={0.5}
            pb={0.5}
            sx={{
              overflowY: 'auto',
            }}
          >
            {conditions && (
              <div>
                <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
                  Conditions
                </Typography>
                <Box width="100%">
                  <Box
                    fontSize={10}
                    lineHeight={1}
                    dangerouslySetInnerHTML={{
                      __html: conditions, // eslint-disable-line
                    }}
                  />
                </Box>
              </div>
            )}
          </Box>
          <Box
            width="100%"
            pb={1}
            pr={1}
            textAlign="center"
            sx={{
              borderTopColor: Colors.gray,
              borderTopStyle: 'solid',
              borderTopWidth: 1,
              overflow: 'hidden',
            }}
          >
            <img
              alt="TappShare Logo"
              src={Logo}
              style={{ width: 45, marginBottom: 4, marginTop: 8 }}
            />
            <Box display="flex" alignItems="center" px={1} pt={0.75} pb={0.75}>
              <InfoOutlinedIcon
                sx={{ width: 20, height: 20, marginRight: 3 }}
              />
              <Typography variant="caption">What is TappShare?</Typography>
            </Box>
            <Box display="flex" alignItems="center" px={1} pt={0.75} pb={0.75}>
              <LockOutlinedIcon
                sx={{ width: 20, height: 20, marginRight: 4.5 }}
              />
              <Typography variant="caption">Privacy</Typography>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

EmulatorDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  conditions: PropTypes.string,
};

EmulatorDrawer.defaultProps = {
  conditions: null,
};

export default EmulatorDrawer;

import React from 'react';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { DOW } from '../helpers';

const Header = ({ month, year, onBack, onNext }) => (
  <Box width={315}>
    <Box width={1} display="flex" alignItems="center">
      <Box>
        <IconButton onClick={onBack} disabled={onBack === null} size="large">
          <ArrowBackIosIcon sx={{ width: 16, height: 16 }} />
        </IconButton>
      </Box>
      <Box flexGrow={1} textAlign="center">
        <Typography sx={{ fontWeight: 'bold', fontSize: 18 }}>{`${month} ${year}`}</Typography>
      </Box>
      <Box>
        <IconButton onClick={onNext} disabled={onNext === null} size="large">
          <ArrowForwardIosIcon sx={{ width: 16, height: 16 }} />
        </IconButton>
      </Box>
    </Box>
    <Box width={1} display="flex" alignItems="center" style={{ marginTop: 8 }}>
      {DOW.map((day) => (
        <Box width={45} textAlign="center" key={day}>
          <Typography sx={{ fontSize: 8, fontWeight: 'bold' }}>{day}</Typography>
        </Box>
      ))}
    </Box>
  </Box>
);

export default Header;

import React from 'react';

import Colors from 'constants/colors';
import { defaultStyles } from 'constants/styles';

import Delete from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow';

import SavedIcon from 'assets/images/campaigns_savedV2.svg';
import PropTypes from 'prop-types';

import StatusCell from '../CampaignList/StatusCell';

const Row = ({ campaign, onClick, onDelete }) => {
  const clickHandler = () => {
    onClick(campaign);
  };

  const deleteHandler = () => {
    onDelete(campaign.id);
  };

  return (
    <TableRow
      hover
      key={campaign.id}
      onClick={clickHandler}
      sx={{
        height: 72,
        boxShadow: defaultStyles.box.shadow,
        backgroundColor: Colors.white,
        borderRadius: 2.5,
        '&:hover': {
          boxShadow: ' 0px 0px 0px 1px rgb(0 99 255 / 50%)',
          backgroundColor: `${Colors.white} !important`,
          cursor: 'pointer',
        },
      }}
    >
      <TableCell
        sx={{
          border: 'none',
          padding: 0,
          minHeight: 64,
          fontSize: 16,
          fontWeight: 700,
          color: 'inherit',
        }}
      >
        <Box px={2.5} display="flex" alignItems="center">
          {/* <StatusCell isActive={true} /> */}
          <Box
            sx={{
              cursor: 'pointer',
              borderRadius: '8px',
              height: '30px',
              width: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mr: '20px',
              backgroundColor:
                // eslint-disable-next-line no-constant-condition
                campaign.type === 'active' || 'future' ? '#2085FF' : '#babac4',
            }}
          >
            <img src={SavedIcon} alt="Saved Icon" width="16px" height="16px" />
          </Box>
          {campaign.title}
        </Box>
      </TableCell>
      <TableCell
        align="center"
        sx={{
          border: 'none',
          padding: 0,
          minHeight: 64,
          fontSize: 16,
          color: 'inherit',
          width: 115,
          '@media max-width:1279.95px': {
            display: 'none',
          },
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Button
          sx={{
            color: Colors.primary,
            marginLeft: 0.5,
            '@media max-width:1279.95px': {
              display: 'none',
            },
          }}
          onClick={deleteHandler}
        >
          {/* Delete */}
          <Delete sx={{ color: Colors.primary, marginLeft: 1.25, width: 25 }} />
        </Button>
      </TableCell>
    </TableRow>
  );
};

Row.propTypes = {
  campaign: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default Row;

export const Details = [
  {
    title: 'Application',
    content:
      ' means the software program provided by the Company downloaded by You on any electronic device, named TappShare',
  },
  {
    title: 'Application Store',
    content:
      ' means the digital distribution service operated and developed by Apple Inc. (Apple App Store) or Google Inc. (Google Play Store) in which the Application has been downloaded.',
  },
  {
    title: 'Affiliate',
    content:
      'means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.',
  },
  { title: 'Country', content: ' refers to: Kentucky, United States' },
  {
    title: 'Company',
    content:
      ' (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to SpeakEasy Software LLC DBA TappShare, 709 Pike Street, Suite 2 Covington, KY 41011.',
  },
  {
    title: 'Content',
    content:
      ' refers to content such as text, images, or other information that can be posted, uploaded, linked to or otherwise made available by You, regardless of the form of that content.',
  },
  {
    title: 'Device',
    content:
      ' means any device that can access the Service such as a computer, a cellphone or a digital tablet.',
  },
  {
    title: 'Feedback',
    content:
      ' means feedback, innovations or suggestions sent by You regarding the attributes, performance or features of our Service.',
  },
  {
    title: 'Free Trial',
    content:
      ' refers to a limited period of time that may be free when purchasing a Subscription.',
  },
  { title: 'Service', content: ' refers to the Application.' },
  {
    title: 'Subscriptions',
    content:
      ' refer to the services or access to the Service offered on a subscription basis by the Company to You.',
  },
  {
    title: 'Terms and Conditions',
    content:
      ' (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.',
  },
  {
    title: 'Third-party Social Media Service',
    content:
      ' means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.',
  },
  {
    title: 'You',
    content:
      ' means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.',
  },
];

export const ContentRestrictions = [
  {
    content: 'Unlawful or promoting unlawful activity.',
  },
  {
    content:
      'Defamatory, discriminatory, or mean-spirited content, including references or commentary about religion, race, sexual orientation, gender, national/ethnic origin, or other targeted groups.',
  },
  {
    content:
      'Spam, machine – or randomly – generated, constituting unauthorized or unsolicited advertising, chain letters, any other form of unauthorized solicitation, or any form of lottery or gambling.',
  },
  {
    content:
      'Containing or installing any viruses, worms, malware, trojan horses, or other content that is designed or intended to disrupt, damage, or limit the functioning of any software, hardware or telecommunications equipment or to damage or obtain unauthorized access to any data or other information of a third person.',
  },
  {
    content:
      'Infringing on any proprietary rights of any party, including patent, trademark, trade secret, copyright, right of publicity or other rights.',
  },
  {
    content:
      'Impersonating any person or entity including the Company and its employees or representatives.',
  },
  { content: 'Violating the privacy of any third person.' },
  { content: 'False information and features.' },
];

export const DMCA = [
  {
    content: `An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright's interest.`,
  },
  {
    content:
      'A description of the copyrighted work that You claim has been infringed, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work.',
  },
  {
    content:
      'Identification of the URL or other specific location on the Service where the material that You claim is infringing is located.',
  },
  { content: 'Your address, telephone number, and email address.' },
  {
    content:
      'A statement by You that You have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.',
  },
  {
    content: `A statement by You, made under penalty of perjury, that the above information in Your notice is accurate and that You are the copyright owner or authorized to act on the copyright owner's behalf.`,
  },
];

import React from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

import AnnouncementAppBar from './components/AnnouncementAppBar';
import Phone from './components/Phone';

const AnnouncementEmulator = ({ image, title, descriptionHtml }) => (
  <Box display="flex" height="100%" flexDirection="column">
    <Phone>
      <AnnouncementAppBar />
      <Box
        width="100%"
        height={150}
        bgcolor={Colors.lightBlueGray}
        position="relative"
      >
        {image && (
          <img
            src={image}
            style={{
              height: 150,
              width: '100%',
              objectFit: 'cover',
              filter: 'brightness(80%)',
            }}
            alt="announcement"
          />
        )}
        <Typography
          sx={{
            position: 'absolute',
            bottom: 10,
            left: 8,
            color: Colors.white,
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box
        flexGrow={1}
        mt={0.1}
        bgcolor={Colors.background}
        borderRadius="0 0 20px 20px"
        width="100%"
        pl={2}
        pr={2}
        pt={1.25}
        sx={{
          overflowY: 'auto',
        }}
      >
        <Box
          fontSize={12}
          lineHeight={1.5}
          dangerouslySetInnerHTML={{
            __html: descriptionHtml, // eslint-disable-line
          }}
        />
      </Box>
    </Phone>
  </Box>
);

AnnouncementEmulator.propTypes = {
  image: PropTypes.object,
  title: PropTypes.string,
  descriptionHtml: PropTypes.string,
};

AnnouncementEmulator.defaultProps = {
  image: null,
  title: null,
  descriptionHtml: null,
};

export default AnnouncementEmulator;

import React from 'react';

import Colors from 'constants/colors';

import { Card, Box } from '@mui/material';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

const VideoCard = ({ onClick, active, type, code, title, cardId, styling }) => {
  const renderVideoCard = () => {
    switch (type) {
      case 'youtube':
        return (
          <div>
            <iframe
              title="youtube"
              width="100%"
              src={`https://youtube.com/embed/${code}`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            />
          </div>
        );
      case 'vimeo':
        return (
          <div>
            <iframe
              title="vimeo"
              src={`https://player.vimeo.com/video/${code}?color=a802f5`}
              width="100%"
              frameBorder="0"
              allow="autoplay; fullscreen"
            />
          </div>
        );
      default:
        return <div />;
    }
  };

  return (
    <Box
      sx={{
        marginLeft: 1,
        marginRight: 1,
        marginBottom: 2,
      }}
    >
      <Card
        sx={
          active
            ? {
                textAlign: 'left',
                borderColor: Colors.primaryLight,
                borderStyle: 'solid',
                borderWidth: 3,
                borderRadius: styling?.cardBorder,
                position: 'relative',
              }
            : {
                width: '100%',
                textAlign: 'left',
                cursor: 'pointer',
                borderRadius: styling?.cardBorder,
                position: 'relative',
              }
        }
        onClick={onClick}
      >
        {type === null ||
        code === null ||
        typeof code === 'undefined' ||
        code === '' ? (
          <Box
            sx={{
              width: '100%',
              height: 150,
              textAlign: 'center',
              paddingTop: 5.5,
              paddingLeft: 3,
              paddingRight: 3,
            }}
          >
            <Typography color="primary">
              Add a video from Youtube or Vimeo
            </Typography>
          </Box>
        ) : (
          renderVideoCard()
        )}
        {title !== null && title !== '' ? (
          <Box
            color={styling?.mainColor}
            textAlign={styling?.mainAlignment}
            fontSize={styling?.mainSize}
            fontStyle={styling?.mainFormat?.includes('italic') ? 'italic' : ''}
            fontWeight={styling?.mainFormat?.includes('bold') ? 'bold' : ''}
            sx={{
              padding: 1,
              textDecoration: styling?.mainFormat?.includes('underline')
                ? 'underline'
                : '',
            }}
          >
            {title}
          </Box>
        ) : null}
        <Box
          sx={{
            top: 0,
            right: 0,
            height: 20,
            marginTop: 1,
            marginRight: 0.75,
            paddingLeft: 1,
            paddingRight: 1,
            position: 'absolute',
            backgroundColor: 'rgba(97,97,97 ,0.7)',
            borderRadius: 6.25,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              color: Colors.white,
              fontSize: 10,
            }}
          >
            {cardId}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
};

VideoCard.propTypes = {
  active: PropTypes.bool.isRequired,
  cardId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  styling: PropTypes.object.isRequired,
  code: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default VideoCard;

import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';

import { Box } from '@mui/material';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

const useStyles: any = makeStyles({
  root: {
    fontWeight: 600,
    width: 50,
    textTransform: 'uppercase',
  },
  statusLabel: {
    color: '#2ECA45',
  },
  inactiveLabel: {
    color: '#babac4',
  },
});

export interface MatSwitchProps {
  labelActive: string;
  className?: string;
  labelInactive: string;
  defaultChecked: boolean;
  onChange: (a: any) => any;
}

const MatSwitch: React.FC<MatSwitchProps> = ({
  labelActive,
  labelInactive,
  defaultChecked,
  onChange,
  className,
}) => {
  const classes: any = useStyles();
  const [status, setStatus] = useState(defaultChecked);

  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 75,
    height: 30,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      paddingLeft: 1,
      margin: 4,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(42px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor:
            theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 23,
      height: 23,
    },
    '& .MuiSwitch-track': {
      borderRadius: 46 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#babac4' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
      '&:before, &:after': {
        content: '""',
        color: '#FFF',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-60%)',
        fontSize: '11.5px',
        width: 14,
        height: 14,
      },
      '&:before': {
        content: status && '"Active"',
        left: 10,
      },
      '&:after': {
        content: !status && '"Inactive"',
        right: 30,
      },
    },
  }));

  useEffect(() => {
    setStatus(defaultChecked);
  }, [defaultChecked]);

  const onChangeHandler = (event) => {
    onChange(event.target.checked);
    setStatus(event.target.checked);
  };

  return (
    <Box
      position="relative"
      width="max-content"
      display="flex"
      alignItems="center"
      gap={2}
    >
      <IOSSwitch
        checked={status}
        onChange={onChangeHandler}
        className={className}
      />
    </Box>
  );
};

export default MatSwitch;

import React from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

const LoadingBar = ({ text, isLoading }) => (
  <Box
    width="100%"
    height={36}
    display="flex"
    justifyContent="end"
    alignItems="center"
  >
    {isLoading && <Typography variant="caption">{text}</Typography>}
    {isLoading && (
      <CircularProgress
        sx={{
          marginLeft: 1,
        }}
        size={16}
      />
    )}
  </Box>
);

LoadingBar.propTypes = {
  text: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default LoadingBar;

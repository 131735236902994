import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

import CardContainer from 'components/Elements/CardContainer/CardContainer';
import Title from 'components/Elements/Title/Title';

const MetricsItem = ({ count, title, icon }) => (
  <CardContainer>
    <Box display="flex" height={175}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width="61%"
        alignItems="center"
      >
        <Title size="small" weight="bold">
          {title}
        </Title>
        <Typography
          variant="h6"
          sx={{
            fontSize: 38,
            color: '#515C66',
            fontWeight: 600,
          }}
        >
          {Number.isInteger(count) ? count : 0}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width="40%"
        p="25px 15px 40px 15px"
        bgcolor="#F3F8FF"
      >
        <img src={icon} alt={title} />
      </Box>
    </Box>
  </CardContainer>
);

MetricsItem.propTypes = {
  count: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default MetricsItem;

import React from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import BillingOverview from './components/BillingOverview';
import SelectPackage from './components/SelectPackage';
import PermissionRequired from 'components/PermissionRequired';
import Permissions from 'constants/permissions';

const Subscription = ({ upgradePlan, setUpgradePlan, role }) => {
  const { accountStatus } = useSelector((state: any) => state.subscriptions);

  const Packages = () => {
    if (!accountStatus || upgradePlan) {
      return (
        <PermissionRequired permissions={[Permissions.subscription.edit]}>
          <SelectPackage
            upgradePlan={upgradePlan}
            setUpgradePlan={setUpgradePlan}
          />
        </PermissionRequired>
      );
    }
    return <BillingOverview setUpgradePlan={setUpgradePlan} />;
  };

  return (
    <div>
      <PermissionRequired permissions={[Permissions.subscription.view]}>
        <Packages />
      </PermissionRequired>
    </div>
  );
};

Subscription.prototype = {
  upgradePlan: PropTypes.bool,
  setUpgradePlan: PropTypes.func,
  role: PropTypes.string,
};

export default Subscription;

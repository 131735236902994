import React from 'react';

import LinkIcon from '@mui/icons-material/Link';
import { Typography, Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { BsTrash3 } from 'react-icons/bs';

import PropTypes from 'prop-types';

const ActionDisplay = ({ type, onClick, onDelete }) => {
  switch (type.toLowerCase()) {
    case 'redirect':
      return (
        <Box
          sx={{
            width: 'fit-content',
            paddingTop: 0.5,
            paddingBottom: 0.5,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            ml: 'auto',
          }}
        >
          {/* <LinkIcon
            sx={{
              width: 24,
              height: 24,
              marginRight: 1,
            }}
            onClick={onClick}
          />
          <Typography variant="subtitle1" onClick={onClick}>
            Redirect Action
          </Typography> */}
          <BsTrash3 onClick={onDelete} size={18} />
        </Box>
      );
    case 'event':
      return (
        <Box
          sx={{
            width: 'fit-content',
            paddingTop: 0.5,
            paddingBottom: 0.5,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            ml: 'auto',
          }}
        >
          {/* <DateRangeIcon
            sx={{
              width: 24,
              height: 24,
              marginRight: 1,
            }}
            onClick={onClick}
          />
          <Typography variant="subtitle1" onClick={onClick}>
            Event Action
          </Typography> */}
          <IconButton
            sx={{
              width: 24,
              height: 24,
            }}
            onClick={onDelete}
            size="large"
          >
            <BsTrash3 size={25} />
          </IconButton>
        </Box>
      );
    default:
      return <Box />;
  }
};

ActionDisplay.propTypes = {
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ActionDisplay;

import React from 'react';

import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';
import { Link, Box } from '@mui/material';

import { Link as RouterLink } from 'react-router-dom';
import routes from 'routes';

function Copyright() {
  const { t } = useTranslation();

  return (
    <Box>
      <Box display="flex" justifyContent="center">
        <Link
          variant="body2"
          component={RouterLink}
          to={routes.PrivacyAndPolicy()}
          target="_blank"
        >
          {t('privacyStatement')}
        </Link>
      </Box>
      <Box>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          {t('appName')}
          {new Date().getFullYear()}
          .
        </Typography>
      </Box>
    </Box>
  );
}

export default Copyright;

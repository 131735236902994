/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import Parse from 'parse';
import { RootState } from 'store';
import { userSelectors } from 'store/domain/user';
import { RequestStatus } from 'store/utils/types';

const sliceName = 'images';

export interface searchPayload {
  query?: any;
}

const getLibraryPhotos = createAsyncThunk(
  `${sliceName}/getLibraryPhotos`,
  async (props, { getState }: any) => {
    try {
      const state = getState() as RootState;
      const businessId = userSelectors.getbusinessId(state);

      if (!businessId) {
        throw new Error('No active business.');
      }
      return await Parse.Cloud.run('Service:getLibraryPhotos', {
        businessId,
      });
    } catch (e) {
      console.error(`Error getting library photos: ${e}`);
      throw new Error(`Error getting library photos: ${e}`);
    }
  },
);

const searchUnsplash = createAsyncThunk(
  `${sliceName}/searchUnsplash`,
  async ({ query }: searchPayload) => {
    try {
      return await Parse.Cloud.run('Service:searchUnsplash', {
        query,
      });
    } catch (e) {
      console.error(`Error getting unsplash photos: ${e}`);
      throw new Error(`Error getting unsplash photos: ${e}`);
    }
  },
);

const searchGiphy = createAsyncThunk(
  `${sliceName}/searchGiphy`,
  async ({ query }: searchPayload) => {
    try {
      return await Parse.Cloud.run('Service:searchGiphy', {
        query,
      });
    } catch (e) {
      console.error(`Error getting unsplash photos: ${e}`);
      throw new Error(`Error getting giphy photos: ${e}`);
    }
  },
);

const imagesAdapter = createEntityAdapter();

export interface imagesState extends EntityState<any> {
  status: RequestStatus;
  error?: string;
  libraryPhotos: Array<any>;
  unsplashPhotos: Array<any>;
  giphyPhotos: Array<any>;
  libraryLoading?: any;
  unsplashLoading?: any;
  giphyLoading?: any;
}

const initialState: imagesState = {
  ...imagesAdapter.getInitialState(),
  status: 'idle',
  libraryPhotos: [],
  unsplashPhotos: [],
  giphyPhotos: [],
};

const imagesSlice = createSlice({
  name: sliceName,
  initialState,
  extraReducers: (builder) => {
    // Library Photos
    builder.addCase(getLibraryPhotos.pending, (state) => {
      state.status = 'pending';
      state.libraryLoading = true;
    });
    builder.addCase(getLibraryPhotos.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.libraryPhotos = action.payload;
      state.libraryLoading = false;
      state.error = '';
    });
    builder.addCase(getLibraryPhotos.rejected, (state, action) => {
      state.status = 'rejected';
      state.libraryLoading = false;
      state.libraryPhotos = [];
      state.error = action.error.message;
    });
    // Search Unsplash
    builder.addCase(searchUnsplash.pending, (state) => {
      state.status = 'pending';
      state.unsplashLoading = true;
    });
    builder.addCase(searchUnsplash.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.unsplashPhotos = action.payload;
      state.unsplashLoading = false;
      state.error = '';
    });
    builder.addCase(searchUnsplash.rejected, (state, action) => {
      state.status = 'rejected';
      state.unsplashLoading = false;
      state.unsplashPhotos = [];
      state.error = action.error.message;
    });
    // Search Giphy
    builder.addCase(searchGiphy.pending, (state) => {
      state.status = 'pending';
      state.giphyLoading = true;
    });
    builder.addCase(searchGiphy.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.giphyPhotos = action.payload;
      state.giphyLoading = false;
      state.error = '';
    });
    builder.addCase(searchGiphy.rejected, (state, action) => {
      state.status = 'rejected';
      state.giphyLoading = false;
      state.giphyPhotos = [];
      state.error = action.error.message;
    });
  },
  reducers: {},
});

export const imagesSelectors = {};

export const imagesActions = {
  getLibraryPhotos,
  searchGiphy,
  searchUnsplash,
  ...imagesSlice.actions,
};

export default imagesSlice.reducer;

import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import routes from 'routes';
import { RootState } from 'store';
import { userActions } from 'store/domain/user';
import * as Yup from 'yup';

import Loadable from 'components/Loadable';
import PasswordTextField from 'components/PasswordTextField';

const SignupSchema = Yup.object().shape({
  vendorName: Yup.string()
    .min(2, 'This name is too short.')
    .max(50, 'This name is too long.')
    .required('Required'),
  firstName: Yup.string()
    .min(2, 'This name is too short.')
    .max(50, 'This name is too long.')
    .required('Required'),
  lastName: Yup.string()
    .min(2, 'This name is too short.')
    .max(50, 'This name is too long.')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  password: Yup.string()
    .min(8, 'This password is too short.')
    .max(50, 'This password is too long.')
    .required('Required'),
});

const Form = () => {
  const dispatch = useDispatch();
  const { status, error } = useSelector((state: RootState) => state.user);
  const [type, setType] = useState<any>(undefined);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location: any = useLocation();

  useEffect(() => {
    switch (status) {
      case 'rejected':
        enqueueSnackbar(error, { variant: 'error' });
        break;
      case 'fulfilled':
        enqueueSnackbar('Business account created', { variant: 'success' });
        navigate('/');
        break;
      default:
        break;
    }
  }, [status]);

  useEffect(() => {
    if (location?.state) {
      setType(location.state.type);
    }
  }, [location]);

  const formik = useFormik({
    initialValues: {
      vendorName: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
    validationSchema: SignupSchema,
    onSubmit: (data) => {
      dispatch(
        userActions.createVendorAccount({
          vendorName: data.vendorName,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          password: data.password,
        }),
      );
      dispatch(userActions.clearStatus());
    },
  });

  return (
    <Box>
      <Typography variant="h5" align="center" sx={{ mb: 4 }}>
        Create Vendor Account
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              required
              fullWidth
              variant="outlined"
              label={t('vendorName') as any}
              name="vendorName"
              value={formik.values.vendorName}
              onChange={formik.handleChange}
              error={
                formik.touched.vendorName && Boolean(formik.errors.vendorName)
              }
              helperText={formik.touched.vendorName && formik.errors.vendorName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              label={t('firstName') as any}
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              label={t('lastName') as any}
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              id="email"
              label={t('emailAddress') as any}
              name="email"
              autoComplete="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordTextField
              required
              fullWidth
              variant="outlined"
              name="password"
              label={t('password') as any}
              autoComplete="current-password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <Box display="flex" pt={1}>
              <Box flexGrow={1} />
              <Link
                variant="body2"
                component={RouterLink}
                to={routes.auth.signin()}
              >
                {t('login')}
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Button
              disableElevation={true}
              color="primary"
              variant="contained"
              type="submit"
              sx={{
                width: 300,
                marginTop: 2,
                borderRadius: 8,
                height: 50,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              <Loadable
                loading={status === 'pending'}
                CircularProgressProps={{
                  size: '1em',
                  color: 'inherit',
                }}
              >
                {t('signUp')}
              </Loadable>
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;

import React, { useEffect, useMemo, useState } from 'react';

// import Colors from 'constants/colors';
import Permissions from 'constants/permissions';

import AddIcon from '@mui/icons-material/Add';
// import IconButton, { IconButtonProps } from '@mui/material/IconButton';
// import { red } from '@mui/material/colors';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import LocalOfferIcon from '@mui/icons-material/LocalOffer';
// import MoreVertIcon from '@mui/icons-material/MoreVert';

import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
// import Typography from '@mui/material/Typography';
// import Card from '@mui/material/Card';
// import CardHeader from '@mui/material/CardHeader';
// import CardMedia from '@mui/material/CardMedia';
// import CardContent from '@mui/material/CardContent';
// import CardActions from '@mui/material/CardActions';

// import { useTranslation } from 'react-i18next';

import Scaffold from 'components/ui/Scaffold';
import PermissionRequired from 'components/PermissionRequired';
import HomeContainer from 'components/ui/HomeContainer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import routes from 'routes';
import { TeamHubActions } from 'store/entities/TeamHub';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

// import Moment from 'moment';
import { Collapse, LinearProgress } from '@mui/material';
import EmptyPage from 'components/EmptyPage';
import CardPreview from 'components/CardPreview/CardPreview';
import TagsModal from '../Card/Create/components/TagsModal';
import TerminateDrawer from '../Card/Details/components/TerminateDrawer';

interface AddTeamButtonProps {
  display: string;
  onClick: () => void;
}
const AddButton = styled(Button)<ButtonProps>(() => ({
  color: '#00CA51',
  textTransform: 'capitalize',
  boxShadow: 'none',
  backgroundColor: 'rgba(0, 202, 81, 0.15)',
  '&:hover': {
    backgroundColor: 'rgba(0, 202, 81, 0.15)',
  },
  borderRadius: 14,
  paddingY: 1,
  fontWeight: 600,
}));

const AddSubFolderButton: React.FC<AddTeamButtonProps> = ({
  display,
  onClick,
}) => (
  <AddButton
    sx={{ fontSize: '15px' }}
    variant="contained"
    onClick={onClick}
    endIcon={<AddIcon fontSize="medium" />}
  >
    {display}
  </AddButton>
);

const Folder = () => {
  // const { t } = useTranslation();
  const navigate = useNavigate();
  const historyLocation: any = useLocation();
  const { folderId } = useParams();
  const dispatch = useDispatch();
  const [cardList, setCardList] = useState<any>([]);
  const [cardId, setCardId] = useState<any>([]);
  const [isLoading, setLoading] = useState(false);
  const [showTerminate, setShowTerminate] = useState(false);
  const [open, setOpen] = useState(false);
  const { cards } = useSelector((state: RootState) => state.teamHub);

  useEffect(() => {
    setLoading(true);
    if (folderId) {
      dispatch(TeamHubActions.getFolderCards({ folderId }));
    }
  }, [folderId]);

  useEffect(() => {
    if (cards) {
      setLoading(false);
      setCardList(cards.data);
    }
  }, [cards]);

  useEffect(() => {
    setLoading(true);
  }, []);

  const onCardTerminated = () => {
    if (folderId) {
      dispatch(TeamHubActions.getFolderCards({ folderId }));
    }
  };

  const handleClickTerminate = (cardId) => {
    setCardId(cardId);
    setShowTerminate(true);
  };

  return (
    <Scaffold
      HeaderProps={{
        title: historyLocation?.state?.folderName
          ? historyLocation?.state?.folderName
          : 'Folder',
        actions: [
          cardList.length > 0 ? (
            <PermissionRequired permissions={[Permissions.teamHub.create]}>
              <AddSubFolderButton
                display="Create Card"
                onClick={() =>
                  navigate(routes.teamHub.build(), {
                    state: { folderId },
                  })
                }
              />
            </PermissionRequired>
          ) : (
            <></>
          ),
        ],
      }}
    >
      {isLoading ? (
        <Collapse in={true}>
          <LinearProgress />
        </Collapse>
      ) : cardList.length > 0 ? (
        <HomeContainer>
          <Grid container spacing={3}>
            {cardList.map((card) => (
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <CardPreview
                  handleOpen={() => setOpen(true)}
                  card={{ ...card, styling: { ...card.action } }}
                  folderId={folderId}
                  handleClickTerminate={handleClickTerminate}
                />
                <TagsModal
                  open={open}
                  tags={card.tags}
                  onClose={() => setOpen(false)}
                  readOnly={true}
                />
              </Grid>
            ))}
          </Grid>
        </HomeContainer>
      ) : (
        <EmptyPage
          actions={[
            <AddSubFolderButton
              display="Create Card"
              onClick={() =>
                navigate(routes.teamHub.build(), {
                  state: { folderId },
                })
              }
            />,
          ]}
          title="Cards"
          description="Create a new Card"
          permissions={[Permissions.teamHub.create]}
        />
      )}
      <TerminateDrawer
        open={showTerminate}
        onClose={() => setShowTerminate(false)}
        onSuccess={onCardTerminated}
        campaignId={cardId}
      />
    </Scaffold>
  );
};

Folder.propTypes = {};

export default Folder;

import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  businessActions,
  businessesSelectors,
} from 'store/entities/Businesses';

import MatDrawer from 'components/Elements/MatDrawer/MatDrawer';
import MatFormLabel from 'components/Elements/MatFormLabel/FormLabel';
import MatInput from 'components/Elements/MatInput/MatInput';
import { UploadImage } from 'components/ImageSelectors';
import { Save } from '@mui/icons-material';

const Edit = ({ open, onClose, onSuccess }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [editName, setEditName] = useState('');
  const [editPhone, setEditPhone] = useState('');
  const [editWebsite, setEditWebsite] = useState('');
  const [selectedImage, setSelectedImage] = useState<any>(null);

  const activeBusiness = useSelector(businessesSelectors.getActiveBusiness);

  useEffect(() => {
    setEditName(activeBusiness.name);
    setEditPhone(activeBusiness.phoneNumber);
    setEditWebsite(activeBusiness.website);
    setSelectedImage({
      type: 'custom',
      upload: false,
      url: activeBusiness.imageUrl,
    });
  }, [activeBusiness]);

  const handlePhoto = (data) => {
    setSelectedImage(data);
  };

  const handleSave = async () => {
    if (editName !== '') {
      dispatch(
        businessActions.updateBusiness({
          name: editName,
          website: editWebsite,
          phoneNumber: editPhone,
          image: selectedImage,
        }),
      );
      onClose();
      onSuccess();
    }
  };

  return (
    <MatDrawer
      open={open}
      anchor="right"
      onClose={onClose}
      onCancel={onClose}
      onSubmit={handleSave}
      title={t('editBusiness')}
      submitBtn={<Save />}
      cancelBtn={t('cancel')}
      isLoading={false}
    >
      <Box maxWidth={450}>
        <MatFormLabel>{t('changeLogo')}</MatFormLabel>
        <UploadImage
          currentImage={activeBusiness.imageUrl}
          onChange={handlePhoto}
        />
        <Grid container spacing={2} style={{ marginTop: 16 }}>
          <Grid item md={12} xs={12}>
            <MatFormLabel>{t('businessName')}</MatFormLabel>
            <MatInput
              value={editName}
              variant="outlined"
              placeholder=""
              onChange={(e) => setEditName(e.target.value)}
              size="small"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <MatFormLabel>{t('website')}</MatFormLabel>
            <MatInput
              value={editWebsite}
              variant="outlined"
              placeholder=""
              onChange={(e) => setEditWebsite(e.target.value)}
              size="small"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <MatFormLabel>{t('phoneNumber')}</MatFormLabel>
            <MatInput
              value={editPhone}
              variant="outlined"
              placeholder=""
              onChange={(e) => setEditPhone(e.target.value)}
              size="small"
            />
          </Grid>
        </Grid>
      </Box>
    </MatDrawer>
  );
};

Edit.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default Edit;

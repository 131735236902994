import 'date-fns';
import React from 'react';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Grid, TextField } from '@mui/material';

import PropTypes from 'prop-types';

const TimeIntervalSelector: React.FC<any> = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
}) => (
  // <LocalizationProvider dateAdapter={AdapterDateFns}>
  <Grid container spacing={2}>
    <Grid item>
      <DatePicker
        inputFormat="MM/dd/yyyy"
        label="Start Date"
        value={startDate}
        onChange={onStartDateChange}
        renderInput={(params) => <TextField {...params} />}
      />
    </Grid>
    <Grid item>
      <DatePicker
        inputFormat="MM/dd/yyyy"
        label="End Date"
        value={endDate}
        onChange={onEndDateChange}
        renderInput={(params) => <TextField {...params} />}
      />
    </Grid>
  </Grid>
  // </LocalizationProvider>
);

TimeIntervalSelector.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  onStartDateChange: PropTypes.func.isRequired,
  onEndDateChange: PropTypes.func.isRequired,
};

export default TimeIntervalSelector;

export type ITimeFrameValue = 'today'|'week'|'month'|'quarter'|'year'|'current_year'

export interface ITimeFrame {
  name: string
  value: ITimeFrameValue
  description: string
}

const TimeFrames: Record<ITimeFrameValue, ITimeFrame> = {
  today: {
    name: 'Today',
    value: 'today',
    description: 'Since yesterday',
  },
  week: {
    name: 'Week',
    value: 'week',
    description: 'Since last week',
  },
  month: {
    name: 'Month',
    value: 'month',
    description: 'Since last month',
  },
  quarter: {
    name: 'Quarter',
    value: 'quarter',
    description: 'Since last quarter',
  },
  year: {
    name: 'Year',
    value: 'year',
    description: 'Since last year',
  },
  current_year: {
    name: 'This year',
    value: 'current_year',
    description: 'Since January 1st',
  },
}

export default TimeFrames;

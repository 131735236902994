import React from 'react';

import Colors from 'constants/colors';

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Box from '@mui/material/Box';

const StatusCell = () => (
  <Box display="flex" alignItems="center">
    <Box
      height={50}
      width={50}
      mr={2.5}
      bgcolor={Colors.active}
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="18%"
    >
      <PersonOutlineOutlinedIcon
        sx={{ width: 32, height: 32, color: Colors.white }}
      />
    </Box>
  </Box>
);

export default StatusCell;

import React from 'react';

import Permissions from 'constants/permissions';

import { IconButton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import List from '@mui/material/List';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'store';

import PermissionRequired from 'components/PermissionRequired';

import navigationLinks from './navigationLinks';
import {
  SubscriptionNavRequired,
  VendorNavRequired,
} from './FullWidthNavigation';
import Colors from 'constants/colors';

export interface LinkItemProps {
  navURl: string;
  path: string;
  icon: JSX.Element;
  iconFocus: JSX.Element;
  display: string;
}

const LinkItem: React.FC<LinkItemProps> = ({
  navURl,
  path,
  icon,
  iconFocus,
  display,
}) => (
  <Box py={1}>
    <IconButton
      // sx={{
      //   borderRadius: navURl === path.split('/')[1] ? '8px' : '0px',
      //   backgroundColor:
      //     navURl === path.split('/')[1] ? Colors.bgBlueLight : 'none',
      //   '&:hover': {
      //     borderRadius: '8px',
      //     backgroundColor:
      //       navURl === path.split('/')[1] ? Colors.bgBlueLight : 'none',
      //   },
      // }}
      component={Link}
      to={path}
    >
      {icon}
      {/* {navURl === path.split('/')[1] ? iconFocus : icon} */}
    </IconButton>
  </Box>
);

const MiniNavigation = ({ navURl }) => {
  const { user, vendorId } = useSelector((state: RootState) => state.user);
  const { accountStatus } = useSelector(
    (state: RootState) => state.subscriptions,
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      height={1}
      maxWidth={75}
    >
      <Box my={2}>
        <Avatar alt="Profile Photo" src={user?.photo_url || null} />
      </Box>
      {!vendorId && (
        <LinkItem
          navURl={navURl}
          path={navigationLinks.dashboard.path}
          icon={navigationLinks.dashboard.icon}
          iconFocus={navigationLinks.dashboard.iconFocus}
          display={navigationLinks.dashboard.display}
        />
      )}
      <VendorNavRequired vendor={vendorId}>
        <LinkItem
          navURl={navURl}
          path={navigationLinks.templates.path}
          icon={navigationLinks.templates.icon}
          iconFocus={navigationLinks.templates.iconFocus}
          display={navigationLinks.templates.display}
        />
      </VendorNavRequired>
      <SubscriptionNavRequired status={accountStatus}>
        <PermissionRequired permissions={[Permissions.campaign.view]}>
          <LinkItem
            navURl={navURl}
            path={navigationLinks.campaigns.path}
            icon={navigationLinks.campaigns.icon}
            iconFocus={navigationLinks.campaigns.iconFocus}
            display={navigationLinks.campaigns.display}
          />
        </PermissionRequired>
        {/* <LinkItem
        path={navigationLinks.announcements.path}
        icon={navigationLinks.announcements.icon}
        display={navigationLinks.announcements.display}
      /> */}
        <PermissionRequired permissions={[Permissions.teamHub.view]}>
          <LinkItem
            navURl={navURl}
            path={navigationLinks.teamHub.path}
            icon={navigationLinks.teamHub.icon}
            iconFocus={navigationLinks.teamHub.iconFocus}
            display={navigationLinks.teamHub.display}
          />
        </PermissionRequired>
        <PermissionRequired permissions={[Permissions.user.view]}>
          <LinkItem
            navURl={navURl}
            path={navigationLinks.members.path}
            icon={navigationLinks.members.icon}
            iconFocus={navigationLinks.members.iconFocus}
            display={navigationLinks.members.display}
          />
        </PermissionRequired>
        <PermissionRequired permissions={[Permissions.team.view]}>
          <LinkItem
            navURl={navURl}
            path={navigationLinks.teams.path}
            icon={navigationLinks.teams.icon}
            iconFocus={navigationLinks.teams.iconFocus}
            display={navigationLinks.teams.display}
          />
        </PermissionRequired>
        <PermissionRequired permissions={[Permissions.report.create]}>
          <LinkItem
            navURl={navURl}
            path={navigationLinks.reporting.path}
            icon={navigationLinks.reporting.icon}
            iconFocus={navigationLinks.reporting.iconFocus}
            display={navigationLinks.reporting.display}
          />
        </PermissionRequired>
      </SubscriptionNavRequired>
      <Box flexGrow={1} />
      <List>
        <LinkItem
          navURl={navURl}
          path={navigationLinks.settings.path}
          icon={navigationLinks.settings.icon}
          iconFocus={navigationLinks.settings.iconFocus}
          display={navigationLinks.settings.display}
        />
      </List>
    </Box>
  );
};

export default MiniNavigation;

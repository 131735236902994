import React, { useState } from 'react';

import Colors from 'constants/colors';

import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

import { FaArrowLeftLong } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import routes from 'routes';
import { RootState } from 'store';

import MatInput from 'components/Elements/MatInput/MatInput';
import Title from 'components/Elements/Title/Title';
import HomeContainer from 'components/ui/HomeContainer';
import Scaffold from 'components/ui/Scaffold';

export default function DeleteAccount() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state: RootState) => state.user);

  const renderActiveStep = () => (
    <Box
      sx={{
        background: '#ffffff',
        borderRadius: '18px',
      }}
      maxWidth={450}
      p={3}
      flex={1}
    >
      <Box mb={1.5}>
        <Title weight="bold" size="medium">
          Are you sure about deleting your account?
        </Title>
      </Box>
      <Grid spacing={1}>
        <Grid item md={12} xs={12}>
          <Box sx={{ mb: 0.8 }}>
            This action is irreversible and will result in the permanent removal
            of all your account data.
          </Box>
          <Box sx={{ mb: 0.8 }}>
            If you want to edit your profile instead please click the &#39;Go To
            Settings&#39; at the top left corner of this page.
          </Box>
          <Box sx={{ mb: 0.8 }}>
            To continue please type in your email and &#39;delete my
            account&#39; below before clicking on &#39;Confirm Deletion&#39;.
          </Box>
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Grid item md={12} xs={12} sx={{ mb: 1.8 }}>
          <Box sx={{ mb: 0.5 }}>Your Email:</Box>
          <MatInput
            // value={name}
            variant="outlined"
            placeholder=""
            size="small"
            // onChange={(e) => setName(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                minHeight: 40,
                paddingY: '2px',
                background: 'rgba(236, 236, 236, 0.4)',
                borderRadius: '4px',
                overflow: 'hidden',
                justifyContent: 'flex-start',
                alignItems: 'center',
                display: 'inline-flex',
              },
              [`& fieldset`]: {
                borderColor: '#808080',
                width: '100%',
              },
            }}
          />
        </Grid>
        <Grid item md={12} xs={12} sx={{ mb: 1.8 }}>
          <Box sx={{ mb: 0.8 }}>
            Type &#39;
            <Box style={{ display: 'inline-flex', color: 'red' }}>
              delete my account
            </Box>
            &#39; below to verify:
          </Box>
          <MatInput
            // value={name}
            variant="outlined"
            placeholder=""
            size="small"
            // onChange={(e) => setName(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                minHeight: 40,
                paddingY: '2px',
                background: 'rgba(236, 236, 236, 0.4)',
                borderRadius: '4px',
                overflow: 'hidden',
                justifyContent: 'flex-start',
                alignItems: 'center',
                display: 'inline-flex',
              },
              [`& fieldset`]: {
                borderColor: '#808080',
                width: '100%',
              },
            }}
          />
        </Grid>
        <Button
          sx={{
            width: '100%',
            textTransform: 'capitalize',
            fontWeight: 600,
            background: Colors.error,
            color: Colors.white,
            borderRadius: '4px',
            px: '18px',
            py: '6px',
            maxHeight: 'fit-content',
            height: 'fit-content',
            '&:hover': {
              background: Colors.error,
            },
          }}
        >
          Confirm Deletion
        </Button>
      </Grid>
    </Box>
  );

  return (
    <Scaffold
      HeaderProps={{
        title: (
          <Box
            sx={{
              p: 2,
              py: 1,
              borderRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              background: '#fff',
            }}
          >
            <FaArrowLeftLong
              style={{ cursor: 'pointer' }}
              size={20}
              onClick={() => navigate(routes.settings())}
            />
            <Divider sx={{ mx: 2 }} orientation="vertical" flexItem />
            <Box sx={{ width: 150, fontWeight: 400, color: '#0000003d' }}>
              Go To Settings
            </Box>
          </Box>
        ),
      }}
    >
      <HomeContainer
        display="flex"
        height="calc(100vh-64px)"
        sx={{ padding: '0 !important', position: 'relative' }}
        flexDirection="column"
      >
        {loading ? (
          <Box
            position="absolute"
            top={300}
            bottom={0}
            left={0}
            right={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            position="absolute"
            top={300}
            bottom={0}
            left={0}
            right={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {renderActiveStep()}
          </Box>
        )}
      </HomeContainer>
    </Scaffold>
  );
}

import React from 'react';

import Box from '@mui/material/Box';
import { BoxProps } from '@mui/system/Box';

import HeaderAppBar, { HeaderAppBarProps } from 'components/HeaderAppBar';

export interface ScaffoldProps {
  HeaderProps?: HeaderAppBarProps;
  BoxProps?: BoxProps;
}

const Scaffold: React.FC<ScaffoldProps> = ({
  children,
  HeaderProps,
  BoxProps,
}) => (
  <>
    <HeaderAppBar {...HeaderProps} position="sticky" />
    <Box height="calc(100vh - 64px)" {...BoxProps}>
      {children}
    </Box>
  </>
);

export default Scaffold;

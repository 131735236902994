import React from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

const ActionDot = ({ count }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      sx={{
        cursor: 'pointer',
      }}
      position="absolute"
      top={-10}
      right={0}
      width={30}
      height={30}
      color={Colors.white}
      borderRadius={12.5}
      bgcolor={Colors.success}
      display="flex"
      justifyContent="center"
      alignItems="center"
      minWidth={30}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>{count}</Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          '& .MuiPopover-paper': { padding: 1.5 },
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{ pointerEvents: 'none' }}
        onClose={handlePopoverClose}
      >
        <Typography variant="h6">Actions Clicked</Typography>
        <Box width="100%" display="flex">
          <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
            Actions:
          </Typography>
          <Typography variant="subtitle2">{count}</Typography>
        </Box>
      </Popover>
    </Box>
  );
};

ActionDot.propTypes = {
  count: PropTypes.number.isRequired,
};

export default ActionDot;

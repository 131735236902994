import React from 'react';

import Colors from 'constants/colors';
import permissions from 'constants/permissions';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { FormControl, MenuItem, Select } from '@mui/material';

import useAccessControl from 'hooks/useAccessControl';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useWindowSize from 'utils/hooks/useWindowSize';
import { useDispatch, useSelector } from 'react-redux';

import { permissionsActions } from 'store/entities/Permissions';
import TimeFrames, { ITimeFrameValue } from 'constants/TimeFrames';

const SettingsTabs = ({ tab, onTabChange, showIntegrations }) => {
  const { t } = useTranslation();
  const ac = useAccessControl();
  const { width } = useWindowSize();

  const { user, businessId } = useSelector((state: any) => state?.user);
  const { emailVerified, objectId } = user;

  const dispatch = useDispatch();

  const [timeFrame, setTimeFrame] = React.useState<ITimeFrameValue>('month');

  React.useEffect(() => {
    dispatch(
      permissionsActions.getUserPermisions({ businessId, userId: objectId }),
    );
  }, [timeFrame, businessId]);

  return width < 660 ? (
    <FormControl variant="standard" sx={{ my: 1, mx: 5, minWidth: 120 }}>
      <Select
        labelId="demo-simple-select-standard-label"
        value={tab}
        onChange={(e) => onTabChange(e)}
      >
        <MenuItem value={0}>{t('profile')}</MenuItem>

        <MenuItem value={1}>{t('business')}</MenuItem>

        {ac.allow(permissions.subscription.view) && (
          <MenuItem value={2}>{t('subscription')}</MenuItem>
        )}
        {showIntegrations && <MenuItem value={3}>{t('integrations')}</MenuItem>}
        <MenuItem value={4}>{t('privacy statement')}</MenuItem>
        <MenuItem value={5}>{t('terms and conditions')}</MenuItem>
      </Select>
    </FormControl>
  ) : (
    <Tabs
      sx={{ marginLeft: '3%' }}
      value={tab}
      onChange={onTabChange}
      TabIndicatorProps={{
        style: { color: Colors.primary },
      }}
    >
      <Tab
        value={0}
        sx={{ fontSize: 12 }}
        style={tab === 0 ? { color: Colors.primary } : undefined}
        label={t('profile') as any}
      />

      {/* {ac.allow(permissions.business.edit) && */}
      {/* ac.allow(permissions.subscription.edit) && ( */}
      <Tab
        value={1}
        sx={{ fontSize: 12 }}
        style={tab === 1 ? { color: Colors.primary } : undefined}
        label={t('business') as any}
      />
      {/*  )} */}
      {emailVerified && ac.allow(permissions.subscription.view) && (
        <Tab
          value={2}
          sx={{ fontSize: 12 }}
          style={tab === 2 ? { color: Colors.primary } : undefined}
          label={t('subscription') as any}
        />
      )}

      {showIntegrations && (
        <Tab
          value={3}
          sx={{ fontSize: 12 }}
          style={tab === 3 ? { color: Colors.primary } : undefined}
          label={t('integrations') as any}
        />
      )}
      <Tab
        value={4}
        sx={{ fontSize: 12 }}
        style={tab === 4 ? { color: Colors.primary } : undefined}
        label={t('privacy statement') as any}
      />
      <Tab
        value={5}
        sx={{ fontSize: 12 }}
        style={tab === 5 ? { color: Colors.primary } : undefined}
        label={t('terms and conditions') as any}
        // href="terms-and-conditions"
      />
    </Tabs>
  );
};

//

SettingsTabs.propTypes = {
  tab: PropTypes.number.isRequired,
  onTabChange: PropTypes.func.isRequired,
  showIntegrations: PropTypes.bool,
};

SettingsTabs.defaultProps = {
  showIntegrations: false,
};

export default SettingsTabs;

// ElementMaker.js

import React from 'react';
import { TextField } from '@mui/material';

// Creat an ElementMaker component
function ElementMaker(props) {
  return (
    <div>
      {// Use JavaScript's ternary operator to specify <span>'s inner content
      props.showInputEle ? (
        <TextField
          variant="standard"
          value={props.value}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
        />
      ) : (
        <span
          onDoubleClick={props.handleDoubleClick}
          style={{
            display: 'inline-block',
            height: '25px',
          }}
        >
          {props.value}
        </span>
      )}
    </div>
  );
}

export default ElementMaker;

import React from 'react';
import Box from '@mui/material/Box';

function SVG2() {
  return (
    <div
      id="SVG2"
      style={{
        height: '70px',
        width: '70px',
        display: 'flex',
      }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          height: '45px',
          width: '45px',
          stroke: '#ffffff',
          strokeWidth: 2.0,
          margin: 'auto',
        }}
      >
        <path
          d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.33 14.49L9.71 11.4C10.05 10.96 10.68 10.88 11.12 11.22L12.95 12.66C13.39 13 14.02 12.92 14.36 12.49L16.67 9.51001"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export default SVG2;

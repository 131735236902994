import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  getUserPermisions,
  permissionsSelector,
  getPermissionsId,
} from 'store/entities/Permissions';

function useAccessControl() {
  const { user, businessId } = useSelector((state: any) => state.user);
  const { permissions } =
    useSelector((state) =>
      permissionsSelector.selectById(
        state,
        getPermissionsId(businessId, user.objectId),
      ),
    ) || {};
  /* const dispatch = useDispatch();
  useEffect(() => {
    if (user && businessId && !permissions?.length) {
      dispatch(
        getUserPermisions({
          userId: user.objectId,
          businessId,
        }),
      );
    }
  }, [user, businessId]); */

  const allow = (permission) => {
    if (!permissions) {
      return false;
    }
    return permissions.includes(permission);
  };

  return { allow };
}

export default useAccessControl;

import { defaultStyles } from 'constants/styles';

import { styled } from '@mui/material';
import Card from '@mui/material/Card/Card';

export interface CardContainerProps { }

const CardContainer = styled(Card)<CardContainerProps>((theme) => ({
  width: '100%',
  height: '100%',
  borderRadius: defaultStyles.box.radius,
  boxShadow: defaultStyles.box.shadow,
}))

export default CardContainer;

import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

import MatDrawer from 'components/Elements/MatDrawer/MatDrawer';

const CancelDrawer = ({ open, onClose, onSubmit }) => (
    <MatDrawer
      open={open}
      anchor="right"
      onClose={onClose}
      onCancel={onClose}
      onSubmit={() => onSubmit()}
      title="Cancel Subscription"
      submitBtn="Unsubscribe"
      cancelBtn="Cancel"
      isLoading={false}
    >
      <Box maxWidth={450}>
        <Typography variant="subtitle2">
          Are you sure you wish to cancel your TappShare subscription? You will
          still have access to your account till the end of the current billing
          cycle.
        </Typography>
      </Box>
    </MatDrawer>
  );

CancelDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CancelDrawer;

import Parse, { Object, JSONBaseAttributes, Pointer } from 'parse';
import { createPointer } from 'store/utils/pointers';

import CampaignTag from './CampaignTag';

export interface CampaignAttributes {
  row_one?: any;
  terminated?: boolean;
  row_two?: any;
  campaign_tags?: CampaignTag;
  row_four?: any;
  start_date?: Date;
  created_by?: Pointer;
  published?: boolean;
  end_date?: Date;
  app_bar?: any;
  team_members?: string[];
  teamsIds?: string[];
  actionsForExpire?: number;
  isAvailableForNewUsers?: boolean;
  shouldShowBusinessName?: boolean;
  row_five?: any;
  title?: string;
  row_three?: any;
  image?: any;
  description?: string;
  business_pointer?: Pointer;
  age?: string;
  locationId?: string;
  termination_reason?: string;
  conditions?: any;
  facebook_pixel?: any;
  target_audience?: any;
}

export type ICampaign = Object.ToJSON<CampaignAttributes> & JSONBaseAttributes;

class Campaign extends Parse.Object<CampaignAttributes> {
  static className = 'premium_campaign';

  constructor(init: CampaignAttributes) {
    super(Campaign.className, init);
  }

  static getPointer(objectId: string): Pointer {
    return createPointer(Campaign.className, objectId);
  }
}

export default Campaign;

import React from 'react';

import DateRangeIcon from '@mui/icons-material/DateRange';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';
import { Typography, Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import PropTypes from 'prop-types';

const ActionDisplay = ({ type, onClick, onDelete }) => {
  switch (type.toLowerCase()) {
    case 'redirect':
      return (
        <Box
          sx={{
            width: '100%',
            paddingTop: 0.5,
            paddingBottom: 0.5,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <LinkIcon
            sx={{
              width: 24,
              height: 24,
              marginRight: 1,
            }}
            onClick={onClick}
          />
          <Typography variant="subtitle1" onClick={onClick}>
            Redirect Action
          </Typography>
          <IconButton
            sx={{
              width: 24,
              height: 24,
              marginLeft: 1,
            }}
            onClick={onDelete}
            size="large"
          >
            <DeleteIcon
              sx={{
                width: 18,
                height: 18,
              }}
            />
          </IconButton>
        </Box>
      );
    case 'event':
      return (
        <Box
          sx={{
            width: '100%',
            paddingTop: 0.5,
            paddingBottom: 0.5,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <DateRangeIcon
            sx={{
              width: 24,
              height: 24,
              marginRight: 1,
            }}
            onClick={onClick}
          />
          <Typography variant="subtitle1" onClick={onClick}>
            Event Action
          </Typography>
          <IconButton
            sx={{
              width: 24,
              height: 24,
              marginLeft: 1,
            }}
            onClick={onDelete}
            size="large"
          >
            <DeleteIcon
              sx={{
                width: 18,
                height: 18,
              }}
            />
          </IconButton>
        </Box>
      );
    default:
      return <Box />;
  }
};

ActionDisplay.propTypes = {
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ActionDisplay;

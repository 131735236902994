import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import Moment from 'moment';
import { useNavigate } from 'react-router-dom';

import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import routes from 'routes';
import DOMPurify from 'dompurify';
import { Modal } from '@mui/material';
import { createTheme } from '@mui/material/styles';

const CardPreview = ({
  card,
  folderId,
  handleOpen,
  handleClickTerminate,
  handleActions,
}) => {
  const navigate = useNavigate();

  const theme = createTheme();

  // const [showTeamMembersModal, setShowTeamMembersModal] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openTags, setOpenTags] = useState(false);
  const [posX, setPosX] = useState(0);
  const [posY, setPosY] = useState(0);

  const handleClickTags = (event) => {
    const { pageX, pageY } = event;
    if (pageX >= 890) {
      setPosX(pageX - 300);
      setPosY(pageY);
    } else {
      setPosX(pageX);
      setPosY(pageY);
    }
    setOpenTags(true);
  };

  const handleCloseTags = () => {
    setOpenTags(false);
  };
  const open = Boolean(anchorEl);

  const handleClickEdit = (id) => {
    navigate(routes.teamHub.build(), {
      state: { id, type: 'edit', folderId },
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onClickCard = (id) => {
    if (folderId) {
      navigate(routes.teamHub.card(id), {
        state: { folderId },
      });
    } else {
      navigate(routes.templates.campaign(id));
    }
  };

  return (
    <Card
      sx={[
        {
          borderRadius: '24px',
          paddingLeft: '6px',
          paddingRight: '6px',
          width: '280px',
          [theme.breakpoints.down('md')]: {
            width: '280px',
            marginX: 'auto',
          },
          [theme.breakpoints.down('sm')]: {
            width: '220px',
            marginX: 'auto',
          },
        },
        card?.isPreview && {
          width: '280px',
        },
        card?.isCardDetails && {
          width: '300px',
        },
      ]}
    >
      <CardHeader
        sx={{ paddingTop: '10px', paddingBottom: '8px' }}
        action={
          folderId ? (
            <div>
              <IconButton onClick={handleClick} aria-label="settings">
                <MoreHorizIcon />
              </IconButton>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem onClick={() => handleClickEdit(card.id)}>
                  Edit
                </MenuItem>
                <MenuItem onClick={() => handleClickTerminate(card.id)}>
                  Terminate
                </MenuItem>
              </Menu>
            </div>
          ) : null
        }
        subheader={Moment(card.createdAt).format('LL')}
      />
      <CardMedia
        component="img"
        height="140"
        image={card?.image?.url ? card?.image?.url : card.image}
        alt={card.id}
        sx={[
          {
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '92%',
            borderRadius: '18px',
          },
          card?.isCardDetails && {
            height: 180,
          },
        ]}
      />
      <CardContent
        sx={[
          {
            paddingY: 1.2,
            paddingBottom: '15px !important',
            height: '160px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          },
          card?.isCardDetails && {
            height: '140px',
          },
        ]}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 0.3,
          }}
        >
          <Typography
            sx={{ fontSize: '20px', fontWeight: 600 }}
            variant="body2"
            color="text.secondary"
          >
            {card.title}
          </Typography>
        </Box>
        <div
          style={{
            overflow: 'hidden',
          }}
        >
          <Typography
            noWrap
            color={card.styling?.mainColor}
            textAlign={card.styling?.mainAlignment}
            fontSize={card.styling?.mainSize}
            fontStyle={
              card.styling?.mainFormat?.includes('italic') ? 'italic' : ''
            }
            sx={{
              fontSize: '14px',
              paddingRight: 1,
              textDecoration: card.styling?.mainFormat?.includes('underline')
                ? 'underline'
                : '',
            }}
            fontWeight={
              card.styling?.mainFormat?.includes('bold') ? 'bold' : ''
            }
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                card?.description?.ops
                  ? card.description.ops[0].insert
                  : card?.description,
                {
                  USE_PROFILES: { html: true },
                },
              ),
            }}
          />
        </div>
        {!card?.isCardDetails && (
          <Box display="flex" sx={{ alignItems: 'center' }}>
            {!card?.isPreview && (
              <Button
                sx={{
                  fontWeight: 500,
                  paddingLeft: 0,
                  paddingRight: 0,
                  textTransform: 'capitalize',
                  marginTop: 0.3,
                  paddingTop: 0.3,
                  paddingBottom: 0.3,
                  cursor: 'pointer',
                }}
                onClick={() => onClickCard(card.id)}
              >
                View More
              </Button>
            )}
            <CardActions
              disableSpacing
              sx={{ minHeight: 15, padding: 0, marginLeft: 'auto' }}
            >
              {card?.tags?.length > 0 ? (
                <IconButton aria-label="share" onClick={handleClickTags}>
                  <LocalOfferIcon sx={{ padding: 0.3 }} />
                </IconButton>
              ) : null}
              <Modal
                open={openTags}
                onClose={handleCloseTags}
                BackdropProps={{ invisible: true }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: posY,
                    left: posX,
                    p: 2,
                    bgcolor: 'background.paper',
                    boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                    borderRadius: '10px',
                  }}
                >
                  <Typography variant="h6" sx={{ marginBottom: 1 }}>
                    Tags
                  </Typography>
                  <Box sx={{ display: 'flex' }}>
                    {card?.tags?.map((val) => (
                      <Typography
                        sx={{
                          width: 'fit-content',
                          height: 'fit-content',
                          borderRadius: '10px',
                          backgroundColor: '#D9EBFF',
                          color: '#007AFF',
                          padding: 0.5,
                          paddingLeft: 2,
                          paddingRight: 2,
                          marginRight: 2,
                        }}
                        variant="subtitle2"
                      >
                        {val}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              </Modal>
            </CardActions>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {card.action && (
            <Button
              color="primary"
              variant={card.action?.buttonVariant}
              disableElevation
              onClick={() => {
                if (handleActions) {
                  handleActions();
                }
                const link = card.action.link.includes('https://')
                  ? card.action.link
                  : `https://${card.action.link}`;
                const win = window.open(link);
                win?.focus();
              }}
              sx={{
                width: '100%',
                borderRadius: card.action?.buttonBorder,
                color: card.action?.textButtonColor,
                height: 35,
                borderColor:
                  card.action?.buttonVariant === 'outlined'
                    ? card.action?.buttonColor
                    : '',
                backgroundColor:
                  card.action?.buttonVariant === 'contained'
                    ? card.action?.buttonColor
                    : '',
                '&:hover': {
                  backgroundColor:
                    card.action?.buttonVariant === 'contained'
                      ? card.action?.buttonColor
                      : '',
                },
              }}
            >
              {card.action.name}
            </Button>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

CardPreview.propTypes = {
  card: PropTypes.object.isRequired,
  handleOpen: PropTypes.func,
  handleClickTerminate: PropTypes.func,
  handleActions: PropTypes.func,
  folderId: PropTypes.string,
};

export default CardPreview;

import Colors from 'constants/colors';

import { styled } from '@mui/material';
import Button from '@mui/material/Button';

export interface MatButtonProps {}

const MatButton: any = styled(Button)<MatButtonProps>(() => ({
  borderRadius: 6.25,
  height: 50,
  fontSize: 16,
  fontWeight: 500,
  textTransform: 'capitalize',
  '& .MuiSvgIcon-root': {
    fontSize: 28,
    stroke: Colors.primary,
    strokeWidth: 0.5,
  },
  '& .MuiButton-startIcon': {
    position: 'absolute',
    left: '18px',
  },
  '&.MuiButton-outlinedPrimary': {
    borderColor: Colors.primary,
  },

  big: {
    padding: '6px 55px',
  },
  medium: {
    height: 45,
    padding: '6px 30px',
  },
  mediumWithIcon: {
    padding: '6px 46px',
  },
  danger: {
    '& .MuiButton-label': {
      color: Colors.error,
    },
    '&.MuiButton-outlinedPrimary': {
      borderColor: Colors.error,
    },
    '& .MuiButton-outlined': {
      borderColor: Colors.error,
    },
  },
}));

export default MatButton;

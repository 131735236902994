import React, { useEffect, useMemo, useState } from 'react';

import Colors from 'constants/colors';
import Permissions from 'constants/permissions';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SearchIcon from '@mui/icons-material/Search';
import WindowIcon from '@mui/icons-material/Window';
import { styled, Button, ButtonProps } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';

import { ReactComponent as SettingsIcon } from 'assets/images/settings_icon.svg';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { MembersActions, membersSelectors } from 'store/entities/Members';
import { teamsActions } from 'store/entities/Teams';

import MatInput from 'components/Elements/MatInput/MatInput';
import EmptyPage from 'components/EmptyPage';
import MissingData from 'components/MissingData';
import HomeContainer from 'components/ui/HomeContainer';
import Scaffold from 'components/ui/Scaffold';

import EditDrawer from './components/EditDrawer';
import FilterDrawer from './components/FilterDrawer';
import InviteDrawer from './components/InviteDrawer';
import QrInvitesList from './components/QrInviteList/QrInvitesList';
import TeamMemberDrawer from './components/TeamMemberDrawer';
import TeamMembersList from './components/TeamMembersList/TeamMembersList';
import PermissionRequired from 'components/PermissionRequired';

interface AddMemberButtonProps extends ButtonProps {
  display: string;
  onClick: () => void;
}

const AddButton = styled(Button)<ButtonProps>(() => ({
  color: '#00CA51',
  textTransform: 'capitalize',
  boxShadow: 'none',
  backgroundColor: 'rgba(0, 202, 81, 0.15)',
  '&:hover': {
    backgroundColor: 'rgba(0, 202, 81, 0.15)',
  },
  borderRadius: 14,
  paddingY: 1,
  fontWeight: 600,
}));

const AddMemberButton: React.FC<AddMemberButtonProps> = ({
  display,
  onClick,
  ...props
}) => (
  <AddButton
    sx={{ fontSize: '15px' }}
    variant="contained"
    onClick={onClick}
    endIcon={<AddIcon fontSize="medium" />}
    {...props}
  >
    {display}
  </AddButton>
);

const theme = createTheme();

const TeamMembers = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isInviteOpen, setIsInviteOpen] = useState(false);
  const [teamMemberForEdit, setTeamMemberForEdit] = useState(null);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [showTeamMemberDrawer, setShowTeamMemberDrawer] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);
  const [switchDisplay, setSwitchDisplay] = useState('grid');

  const { enqueueSnackbar } = useSnackbar();
  const [filters, setFilters] = useState<any>({});

  const [filterList, setFilterList] = useState<any>([]);

  const { ids, entities, status } = useSelector((state: any) => state.members);

  useEffect(() => {
    dispatch(teamsActions.fetchTeams({ filter: {} }));
    dispatch(MembersActions.fetchMembers({}));
  }, []);

  useEffect(() => {
    const teamMemberList = ids.map((id) => entities[id]);
    setFilterList(teamMemberList);
  }, [entities]);

  const onEditClickHandler = (teamMember) => {
    setIsEditOpen(true);
    setTeamMemberForEdit(teamMember);
  };

  const onDeleteClickHandler = (inviteId) => {
    dispatch(
      MembersActions.cancelInvite({
        inviteId,
        callBack: ({ status, message }) => {
          switch (status) {
            case 'failure':
              enqueueSnackbar(message, { variant: 'error' });
              break;
            case 'success':
              enqueueSnackbar(message, { variant: 'success' });
              break;
            default:
          }
        },
      }),
    );
  };

  const menuOptions = useMemo(
    () => [{ name: t('editInfo'), click: onEditClickHandler }],
    [],
  );

  const teamMembersData = ids.map((id) => entities[id]);

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    const search = event.target.value;
    if (search !== null && search !== '') {
      const updatedList: any[] = [];
      teamMembersData.forEach((members) => {
        if (members?.name.toLowerCase().includes(search.toLowerCase())) {
          updatedList.push(members);
        }
      });
      setFilterList(updatedList);
    } else {
      setFilterList(teamMembersData);
    }
  };

  const handleKeyPress = () => {
    dispatch(
      MembersActions.fetchMembers({
        status: filters.status,
        locationId: filters.locationId,
        search: searchValue,
        tags: filters.tags,
      }),
    );
  };

  const clearSearch = () => {
    setSearchValue('');
  };

  const handleFilter = (status, locationId, tags) => {
    setFilters({ status, locationId, tags });
    dispatch(
      MembersActions.fetchMembers({
        status,
        locationId,
        tags,
      }),
    );
  };

  const showTeamMember = (member) => {
    setSelectedTeamMember(member);
    setShowTeamMemberDrawer(true);
  };

  const teamMembers = filterList.filter((t: any) => !t.mass);
  const qrInvites = filterList.filter((t: any) => t.mass);

  return (
    <Scaffold
      HeaderProps={{
        title: 'Members',
        actions: [
          <Box
            sx={{
              marginRight: 4,
              display: 'flex',
              alignItems: 'center',
              [theme.breakpoints.down('lg')]: {
                display: 'none',
              },
            }}
          >
            <Button
              onClick={() => setSwitchDisplay('grid')}
              sx={{
                borderRadius: '10px 0 0 10px',
                paddingY: 0.8,
                backgroundColor: switchDisplay === 'grid' ? '#B3B3B3' : 'white',
                color: switchDisplay === 'grid' ? 'white' : '#B3B3B3',
                '&:hover': {
                  backgroundColor:
                    switchDisplay === 'grid' ? '#B3B3B3' : 'white',
                  color: switchDisplay === 'grid' ? 'white' : '#B3B3B3',
                },
                '&.MuiButton-root': {
                  minWidth: '40px',
                },
              }}
            >
              <WindowIcon fontSize="small" />
            </Button>
            <Button
              onClick={() => setSwitchDisplay('list')}
              sx={{
                borderRadius: '0 10px 10px 0',
                paddingY: 0.8,
                backgroundColor: switchDisplay === 'list' ? '#B3B3B3' : 'white',
                color: switchDisplay === 'list' ? 'white' : '#B3B3B3',
                '&:hover': {
                  backgroundColor:
                    switchDisplay === 'list' ? '#B3B3B3' : 'white',
                  color: switchDisplay === 'list' ? 'white' : '#B3B3B3',
                },
                '&.MuiButton-root': {
                  minWidth: '40px',
                },
              }}
            >
              <FormatListBulletedIcon fontSize="small" />
            </Button>
          </Box>,
          (teamMembers && teamMembers.length > 0) ||
          (qrInvites && qrInvites.length > 0) ? (
            <PermissionRequired permissions={[Permissions.user.create]}>
              <AddMemberButton
                display={t('addUser')}
                onClick={() => setIsInviteOpen(true)}
              />
            </PermissionRequired>
          ) : (
            <></>
          ),
        ],
        children: (
          <Box display="flex" tabIndex={0} ml={8} role="button">
            <MatInput
              variant="outlined"
              onChange={handleSearch}
              placeholder="Search"
              onKeyPress={handleSearch}
              sx={{
                backgroundColor: '#fff',
                borderRadius: '10px',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '& .MuiInputBase-root': {
                  minWidth: 450,
                  [theme.breakpoints.down('lg')]: {
                    minWidth: 350,
                  },
                  [theme.breakpoints.down('md')]: {
                    display: 'none',
                  },
                  borderRadius: '10px',
                  pr: 2,
                  pl: 0,
                  py: 0.5,
                },
                input: {
                  '&::placeholder': {
                    textOverflow: 'ellipsis !important',
                    color: 'blue',
                  },
                },
              }}
              value={searchValue}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton size="large">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    {searchValue.length > 0 && (
                      <ClearIcon
                        onClick={clearSearch}
                        sx={{ cursor: 'pointer', color: Colors.black, mr: 2 }}
                      />
                    )}
                    <IconButton
                      onClick={() => setIsFiltersOpen(true)}
                      size="small"
                    >
                      <SettingsIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        ),
      }}
    >
      <HomeContainer>
        <Box display="flex" tabIndex={0} role="button">
          <MatInput
            variant="outlined"
            onChange={handleSearch}
            placeholder="Search"
            onKeyPress={handleSearch}
            sx={{
              backgroundColor: '#fff',
              borderRadius: '10px',
              mb: 4,
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '& .MuiInputBase-root': {
                minWidth: '100%',
                [theme.breakpoints.up('md')]: {
                  display: 'none',
                },
                pr: 2,
                pl: 0,
                py: 1.2,
              },
              input: {
                '&::placeholder': {
                  textOverflow: 'ellipsis !important',
                  color: 'blue',
                },
              },
            }}
            value={searchValue}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton size="large">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start">
                  {searchValue.length > 0 && (
                    <ClearIcon
                      onClick={clearSearch}
                      sx={{ cursor: 'pointer', color: Colors.black, mr: 2 }}
                    />
                  )}
                  <IconButton
                    onClick={() => setIsFiltersOpen(true)}
                    size="small"
                  >
                    <SettingsIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        {(teamMembers && teamMembers.length > 0) ||
        (qrInvites && qrInvites.length > 0) ? (
          <Grid container>
            <Box flex={1}>
              {teamMembers && teamMembers.length > 0 ? (
                <>
                  {/* <Typography gutterBottom variant="h6">
                    {t('users')}
                  </Typography> */}
                  <TeamMembersList
                    switchDisplay={switchDisplay}
                    teamMembers={teamMembers}
                    onClick={showTeamMember}
                    onEdit={onEditClickHandler}
                    menuOptions={menuOptions}
                    onDelete={onDeleteClickHandler}
                  />
                </>
              ) : (
                <MissingData message={t('noUsersFound')} icon={2} />
              )}
            </Box>
            {qrInvites && qrInvites.length > 0 ? (
              <Grid item md={12}>
                <>
                  <Typography gutterBottom variant="h6">
                    {t('qrCodeInvites')}
                  </Typography>
                  <QrInvitesList
                    qrInvites={qrInvites}
                    onClick={showTeamMember}
                    onEdit={onEditClickHandler}
                    menuOptions={menuOptions}
                    onDelete={onDeleteClickHandler}
                  />
                </>
              </Grid>
            ) : null}
          </Grid>
        ) : (
          <MissingData message={t('noUsersFound')} icon={2} />
        )}
      </HomeContainer>
      <FilterDrawer
        open={isFiltersOpen}
        onClose={() => setIsFiltersOpen(false)}
        onFilter={handleFilter}
      />
      <EditDrawer
        teamMember={teamMemberForEdit}
        onClose={() => setIsEditOpen(false)}
        onEdit={() =>
          dispatch(
            MembersActions.fetchMembers({
              status: filters.status,
              locationId: filters.locationId,
              tags: filters.tags,
            }),
          )
        }
        open={isEditOpen}
      />
      <InviteDrawer
        teamMemberList={teamMembersData}
        open={isInviteOpen}
        onClose={() => setIsInviteOpen(false)}
      />
      <TeamMemberDrawer
        teamMember={selectedTeamMember}
        open={showTeamMemberDrawer}
        onClose={() => setShowTeamMemberDrawer(false)}
      />
    </Scaffold>
  );
};

export default TeamMembers;

import React from 'react';

import Colors from 'constants/colors';

import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

const Day = ({
  day,
  disabled,
  selected,
  firstSelected,
  lastSelected,
  onClick,
}) => {
  const renderTextBox = () => {
    if (disabled) {
      return (
        <Typography sx={{
          fontWeight: 100,
          color: '#BDBDBD',
          fontSize: 14,
        }}
        >
          {day}
        </Typography>);
    } else if (firstSelected && lastSelected) {
      return (
        <Box sx={{
          backgroundColor: Colors.primary,
          color: Colors.white,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 7.5,
        }}
        >
          <Typography sx={{ fontSize: 16 }}>{day}</Typography>
        </Box>
      );
    } else if (firstSelected) {
      return (
        <Box sx={{
          backgroundColor: Colors.primary,
          color: Colors.white,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderTopLeftRadius: 20,
          borderBottomLeftRadius: 20,
        }}
        >
          <Typography sx={{ fontSize: 16 }}>{day}</Typography>
        </Box>
      );
    } else if (lastSelected) {
      return (
        <Box sx={{
          backgroundColor: Colors.primary,
          color: Colors.white,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderTopRightRadius: 20,
          borderBottomRightRadius: 20,
        }}
        >
          <Typography sx={{ fontSize: 16 }}>{day}</Typography>
        </Box>
      );
    } else if (selected) {
      return (
        <Box sx={{
          backgroundColor: Colors.primary,
          color: Colors.white,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          <Typography sx={{ fontSize: 16 }}>{day}</Typography>
        </Box>
      );
    }
    return <Typography sx={{ fontSize: 16 }}>{day}</Typography>;
  };

  return (
    <Box
      style={disabled ? undefined : { cursor: 'pointer' }}
      onClick={onClick}
      onKeyDown={onClick}
      tabIndex={0}
      role="button"
      key={day}
      width={45}
      height={45}
      display="flex"
      justifyContent="center"
      alignItems="center"
      py={0.5}
    >
      {renderTextBox()}
    </Box>
  );
};

export default Day;

import React from 'react';

import { CircularProgress } from '@mui/material'

import PropTypes from 'prop-types';

const Loadable = ({ loading, children, CircularProgressProps }) => {
  if (loading) { return <CircularProgress {...CircularProgressProps} /> }
  return <>{children}</>
}

Loadable.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  CircularProgressProps: PropTypes.object,
}

Loadable.defaultProps = {
  CircularProgressProps: {},
}

export default Loadable;

import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import PropTypes from 'prop-types';

const ImageTabBar = ({ tab, onChange }) => (
  <Box>
    <Button
      onClick={() => onChange(0)}
      sx={{
        width: '100%',
        fontWeight: tab === 0 ? 700 : 300,
        py: 1,
        mb: 2,
        color: '#333',
        border: 'none',
        borderRadius: '7px',
        background: '#E2E4E5',
        paddingLeft: 3,
        paddingRight: 3,
        '&:hover': {
          background: '#E2E4E5',
        },
        '&:focus': {
          background: '#E2E4E5',
        },
        '&.MuiButton-contained': {
          boxShadow: 'none',
        },
        '&:first-child': {
          borderTopLeftRadius: 6,
          borderBottomLeftRadius: 6,
        },
        '&:last-child': {
          borderTopRightRadius: 6,
          borderBottomRightRadius: 6,
        },
      }}
      // variant={tab === 0 ? 'contained' : 'outlined'}
    >
      Library
    </Button>
    <Button
      onClick={() => onChange(1)}
      sx={{
        width: '100%',
        fontWeight: tab === 1 ? 700 : 300,
        py: 1,
        mb: 2,
        color: '#333',
        border: 'none',
        borderRadius: '7px',
        background: '#E2E4E5',
        paddingLeft: 3,
        paddingRight: 3,
        '&:hover': {
          background: '#E2E4E5',
        },
        '&:focus': {
          background: '#E2E4E5',
        },
        '&.MuiButton-contained': {
          boxShadow: 'none',
        },
        '&:first-child': {
          borderTopLeftRadius: 6,
          borderBottomLeftRadius: 6,
        },
        '&:last-child': {
          borderTopRightRadius: 6,
          borderBottomRightRadius: 6,
        },
      }}
      // variant={tab === 1 ? 'contained' : 'outlined'}
    >
      Unsplash
    </Button>
    <Button
      onClick={() => onChange(2)}
      sx={{
        width: '100%',
        fontWeight: tab === 2 ? 700 : 300,
        py: 1,
        color: '#333',
        border: 'none',
        borderRadius: '7px',
        background: '#E2E4E5',
        paddingLeft: 3,
        paddingRight: 3,
        '&:hover': {
          background: '#E2E4E5',
        },
        '&:focus': {
          background: '#E2E4E5',
        },
        '&.MuiButton-contained': {
          boxShadow: 'none',
        },
        '&:first-child': {
          borderTopLeftRadius: 6,
          borderBottomLeftRadius: 6,
        },
        '&:last-child': {
          borderTopRightRadius: 6,
          borderBottomRightRadius: 6,
        },
      }}
      // variant={tab === 2 ? 'contained' : 'outlined'}
    >
      Gifs
    </Button>
  </Box>
);

ImageTabBar.propTypes = {
  tab: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ImageTabBar;

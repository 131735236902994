import React, { useEffect, useState } from 'react';

import {
  Button,
  Modal,
  Typography,
  Autocomplete,
  Box,
  Chip,
} from '@mui/material';
import TextField from '@mui/material/TextField';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { membersSelectors } from 'store/entities/Members';
import { teamsActions } from 'store/entities/Teams';
import MatInput from 'components/Elements/MatInput/MatInput';

const TagsModal = ({
  locationId,
  open,
  onClose,
  handleTags,
  tags,
  teamMembers,
  readOnly,
}) => {
  const [teamMembersByLocation, setTeamMembersByLocation] = useState<any[]>([]);

  const dispatch = useDispatch();
  const activeTeamMembers = useSelector(membersSelectors.getActiveMembers);

  useEffect(() => {
    dispatch(teamsActions.fetchTeams({ filter: { status: 'active' } }));
  }, []);

  useEffect(() => {
    if (locationId !== 'all' && locationId) {
      setTeamMembersByLocation(
        activeTeamMembers.filter((el) => el.locationId === locationId),
      );
    } else {
      setTeamMembersByLocation(activeTeamMembers);
    }
  }, [teamMembers]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          border: 'none',
          boxShadow: 24,
        }}
      >
        <Box p={2} borderBottom="0.5px solid #000">
          <Typography variant="h6">Tags</Typography>
        </Box>
        <Box p={2}>
          <Autocomplete
            multiple
            readOnly={readOnly}
            id="tags-filled"
            options={[]}
            value={tags}
            freeSolo
            onChange={(event, newInputValue) => {
              handleTags(newInputValue);
            }}
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip
                  color="primary"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <MatInput {...params} variant="outlined" placeholder="Tags" />
            )}
          />
        </Box>
        <Box
          p={2}
          display="flex"
          justifyContent="flex-end"
          borderTop="0.5px solid #000"
        >
          <Button onClick={onClose} variant="text">
            Ok
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

TagsModal.propTypes = {
  handleTags: PropTypes.func,
  locationId: PropTypes.string,
  tags: PropTypes.array,
  open: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  teamMembers: PropTypes.arrayOf(PropTypes.string),
};

export default TagsModal;

import React, { useState } from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

import ActionDot from './ActionDot';

const PollCard = ({ data, results }) => {
  const [radioValue] = useState('');

  return (
    <Box pr={2} pl={2} width="100%" position="relative">
      <Card sx={{ padding: 2 }}>
        <Box display="flex" alignItems="center">
          <Typography
            variant="body2"
            sx={{
              marginLeft: 1,
              flexGrow: 1,
            }}
          >
            {data.question}
          </Typography>
        </Box>
        <RadioGroup value={radioValue}>
          {data.answers.map((answer, index) => (
            <FormControlLabel
              sx={{
                marginLeft: 1,
                marginTop: 1.25,
              }}
              value={index.toString()}
              control={
                <Radio sx={{ padding: 0, marginRight: 6 }} color="primary" />
              }
              label={answer}
            />
          ))}
        </RadioGroup>
        <Button
          color="primary"
          variant="contained"
          sx={{
            borderRadius: 8,
            height: 30,
            marginTop: 2.5,
            paddingLeft: 2.5,
            paddingRight: 2.5,
          }}
          disableElevation
        >
          <Typography sx={{ fontSize: 10, color: Colors.white }}>
            Submit
          </Typography>
        </Button>
      </Card>
      {results !== null && results.actionOne > 0 && (
        <ActionDot count={results.actionOne} />
      )}
    </Box>
  );
};

PollCard.propTypes = {
  data: PropTypes.object.isRequired,
  results: PropTypes.object,
};

PollCard.defaultProps = {
  results: null,
};

export default PollCard;

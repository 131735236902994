import { styled } from '@mui/material';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox/Checkbox';

interface MatCheckboxProps extends CheckboxProps {
}

const MatCheckbox = styled(Checkbox)<MatCheckboxProps>(() => ({
}))

export default MatCheckbox;

import React, { useState, useEffect } from 'react';

import { Button, Collapse } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

import { format as formatDate, addDays } from 'date-fns';
import { parse as json2csv } from 'json2csv';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { reportActions } from 'store/entities/Reports';

import EmptyReport from './EmptyReport';
import TeamMemberReportTable from './TeamMemberReportTable';
import TeamMemberSelector from './TeamMemberSelector';
import TimeIntervalSelector from './TimeIntervalSelector';
import { Calendar } from './CampaignReport';

const timeIntervalFromTimeframe = (timeframe) => {
  let startTs;
  let endTs;
  switch (timeframe) {
    case '7':
      endTs = Date.now();
      startTs = endTs - 1000 * 60 * 60 * 24 * 7;
      break;
    case '30':
      endTs = Date.now();
      startTs = endTs - 1000 * 60 * 60 * 24 * 30;
      break;
    case 'last_year':
      endTs = Date.now();
      startTs = endTs - 1000 * 60 * 60 * 24 * 365;
      break;
    default:
  }

  return {
    startDate: startTs ? new Date(startTs) : undefined,
    endDate: endTs ? new Date(endTs) : undefined,
  };
};

const TeamMemberReport = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [teamMemberSelector, setTeamMemberSelector] = useState('all');
  const [timeframe, setTimeframe] = useState('7');
  const [includeCampaigns, setIncludeCampaigns] = useState(true);
  const [timeInterval, setTimeInterval] = useState(
    timeIntervalFromTimeframe(timeframe),
  );
  const [teamMemberSelection, setTeamMemberSelection] = useState<any>([]);
  const [report, setReport] = useState<any>();
  const [loading, setLoading] = useState(false);

  const [createOpen, setCreateOpen] = useState(false);

  const [calenderDuration, setCalenderDuration] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 14),
      key: 'selection',
    },
  ]);

  const { businessId } = useSelector((state: any) => state.user);
  const { status, teamMemberReport, loadingReport, error } = useSelector(
    (state: RootState) => state.reports,
  );

  useEffect(() => {
    switch (status) {
      case 'rejected':
        enqueueSnackbar(error, { variant: 'error' });
        dispatch(reportActions.clearStatus());
        break;
      case 'pending':
        setLoading(true);
        break;
      case 'fulfilled':
        setReport(teamMemberReport);
        dispatch(reportActions.clearStatus());
        break;
      default:
        setLoading(false);
    }
  }, [status]);

  const handleRunReport = () => {
    const teamMembers: any = { selector: teamMemberSelector };
    switch (teamMembers.selector) {
      case 'all':
        teamMembers.businessId = businessId;
        break;
      case 'active':
        teamMembers.businessId = businessId;
        break;
      case 'custom':
        teamMembers.teamMemberIds = teamMemberSelection;
        break;
      default:
    }

    if (
      teamMembers.selector === 'custom' &&
      (!teamMembers.teamMemberIds || !teamMembers.teamMemberIds.length)
    ) {
      enqueueSnackbar(t('selectOneTeamMember'), { variant: 'warning' });
      return;
    }

    setLoading(true);
    dispatch(
      reportActions.getTeamMemberReport({
        teamMembers,
        startDate: timeInterval.startDate?.toISOString(),
        endDate: timeInterval.endDate?.toISOString(),
        includeCampaigns,
      }),
    );
  };

  const handleTeamMemberSelection = (selected) => {
    setTeamMemberSelection((prevSelection) => {
      const set: any = new Set(prevSelection);
      if (set.has(selected)) {
        set.delete(selected);
      } else {
        set.add(selected);
      }
      return [...set];
    });
  };

  const generateFilename = () => {
    const dateFormat = 'MM/dd/yyyy';
    const startDate = formatDate(timeInterval.startDate as any, dateFormat);
    const endDate = formatDate(timeInterval.endDate as any, dateFormat);
    return `campaign-report-${teamMemberSelector}-${startDate}-To-${endDate}.csv`;
  };

  const generateCsv = () => {
    let json: any = [];
    if (includeCampaigns) {
      //
      for (const data of report) {
        json.push({
          'team member name': `${data.teamMember.get(
            'firstname',
          )} ${data.teamMember.get('lastname')}`,
          engagements: data.metrics.engagements,
          views: data.metrics.impressions,
          actions: data.metrics.conversions,
        });
        for (const { campaign, metrics } of data?.byCampaign) {
          json.push({
            engagements: metrics.engagements,
            views: metrics.impressions,
            actions: metrics.conversions,
            'campaign title': campaign.get('title'),
          });
        }
        json.push({ name: '' });
      }
    } else {
      json = report.map((data) => ({
        name: `${data.teamMember.get('firstname')} ${data.teamMember.get(
          'lastname',
        )}`,
        engagements: data.metrics.engagements,
        views: data.metrics.impressions,
        actions: data.metrics.conversions,
      }));
    }

    return json2csv(json);
  };

  const handleDownloadCsv = () => {
    const csv = generateCsv();
    const downloadLink = document.createElement('a');
    downloadLink.href = `data:text/plain;charset=utf-8,${encodeURIComponent(
      csv,
    )}`;
    downloadLink.download = generateFilename();
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const getRows = () =>
    report.map((item) => ({
      id: item.teamMember.id,
      name: `${item.teamMember?.get('firstname')} ${item.teamMember?.get(
        'lastname',
      )}`,
      ...item.metrics,
      hasCampaign: item.byCampaign && item.byCampaign.length > 0,
      byCampaign: item.byCampaign
        ? item.byCampaign.map((tm) => ({
            id: tm.campaign.get('id'),
            title: tm.campaign.get('title'),
            ...tm.metrics,
          }))
        : undefined,
    }));

  const rows = report ? getRows() : [];

  const getTotal = () =>
    rows.reduce(
      (prev, curr) => ({
        engagements: prev.engagements + curr.engagements,
        impressions: prev.impressions + curr.impressions,
        conversions: prev.conversions + curr.conversions,
      }),
      {
        engagements: 0,
        impressions: 0,
        conversions: 0,
      },
    );

  useEffect(() => {
    handleRunReport();
  }, []);

  useEffect(() => {
    if (timeframe !== 'custom') {
      setTimeInterval(timeIntervalFromTimeframe(timeframe));
    }
  }, [timeframe]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography>{t('selectTeamMembers')}</Typography>
          <RadioGroup
            row
            value={teamMemberSelector}
            onChange={(_, value) => setTeamMemberSelector(value)}
          >
            <FormControlLabel
              value="all"
              control={<Radio color="primary" />}
              label={t('all') as any}
            />
            <FormControlLabel
              value="active"
              control={<Radio color="primary" />}
              label={t('active') as any}
            />
            <FormControlLabel
              value="custom"
              control={<Radio color="primary" />}
              label={t('custom') as any}
            />
          </RadioGroup>

          <Collapse in={teamMemberSelector === 'custom'}>
            <TeamMemberSelector
              selection={teamMemberSelection}
              onSelect={handleTeamMemberSelection}
            />
          </Collapse>
        </Grid>

        <Grid item xs={12}>
          <Typography>{t('selectTimeFrame')}</Typography>
          <RadioGroup
            row
            value={timeframe}
            onChange={(_, value) => setTimeframe(value)}
          >
            <FormControlLabel
              value="7"
              control={<Radio color="primary" />}
              label={t('7Days') as any}
            />
            <FormControlLabel
              value="30"
              control={<Radio color="primary" />}
              label={t('30Days') as any}
            />
            <FormControlLabel
              value="last_year"
              control={<Radio color="primary" />}
              label={t('lastYear') as any}
            />
            <FormControlLabel
              value="custom"
              control={<Radio color="primary" />}
              label={t('custom') as any}
            />
            <Collapse in={timeframe === 'custom'}>
              <Button
                variant="contained"
                onClick={() => setCreateOpen(!createOpen)}
              >
                Open Calender
              </Button>
            </Collapse>
          </RadioGroup>

          {/* <Collapse in={timeframe === 'custom'}>
            <TimeIntervalSelector
              startDate={timeInterval.startDate}
              endDate={timeInterval.endDate}
              onStartDateChange={(startDate) =>
                setTimeInterval((interval) => ({ ...interval, startDate }))
              }
              onEndDateChange={(endDate) =>
                setTimeInterval((interval) => ({ ...interval, endDate }))
              }
            />
          </Collapse> */}
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="primary" />}
            label={t('includeCampaigns') as any}
            checked={includeCampaigns}
            onChange={(_, value) => setIncludeCampaigns(value)}
          />
        </Grid>

        <Grid item xs={6}>
          <Button
            variant="contained"
            disabled={loadingReport}
            onClick={handleRunReport}
          >
            {t('runTheReport')}
          </Button>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            disabled={!report || !report.length}
            onClick={handleDownloadCsv}
          >
            {t('downloadCsv')}
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Collapse in={loading}>
            <LinearProgress />
          </Collapse>
        </Grid>

        <Grid item xs={12}>
          {!rows.length ? (
            <EmptyReport />
          ) : (
            <TeamMemberReportTable rows={rows} total={getTotal()} />
          )}
        </Grid>
      </Grid>
      <Calendar
        open={createOpen}
        onClose={() => setCreateOpen(false)}
        calenderDuration={calenderDuration}
        setCalenderDuration={setCalenderDuration}
      />
    </div>
  );
};

export default TeamMemberReport;

import React, { useState } from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import MatCheckbox from 'components/Elements/MatCheckbox/MatCheckbox';
import MatDrawer from 'components/Elements/MatDrawer/MatDrawer';
import MatFormLabel from 'components/Elements/MatFormLabel/FormLabel';

const FilterDrawer = ({ open, onClose, onFilter }) => {
  const { t } = useTranslation();
  const LOCATION_STATUS = [
    {
      name: t('active'),
      key: 'active',
      status: true,
    },
    {
      name: t('inactive'),
      key: 'inactive',
      status: false,
    },
  ];
  const [status, setStatus] = useState(LOCATION_STATUS);

  const handleFilter = () => {
    const filter = status.filter((el) => el.status)
    if (filter.length >= 2) {
      onFilter('all');
    } else {
      onFilter(filter[0]?.key);
    }
    onClose();
  };

  const resetFilters = () => {
    setStatus(LOCATION_STATUS);
  };

  const handleAccountStatusClick = (key) => {
    const newStatus = status.map((field) => {
      if (field.key === key) {
        return {
          name: field.name,
          key,
          status: !field.status,
        };
      }
      return field;
    });
    setStatus(newStatus);
  };

  return (
    <MatDrawer
      open={open}
      anchor="right"
      onClose={onClose}
      onCancel={resetFilters}
      onSubmit={handleFilter}
      title={t('filters')}
      submitBtn={t('apply')}
      cancelBtn={t('resetFilters')}
    >
      <Box maxWidth={465} pl={2} pr={2} width="100%">
        <Box display="flex" justifyContent="space-between">
          <FormControl
            component="fieldset"
            sx={{
              '&:first-child': {
                marginRight: 11.25,
              },
            }}
            key="status"
          >
            <MatFormLabel
              sx={{
                color: Colors.black,
                fontSize: 14,
                '& .MuiTypography-root': {
                  marginLeft: 1.25,
                },
              }}
            >
              {t('status')}
            </MatFormLabel>
            <FormGroup>
              {status.map((field) => (
                <FormControlLabel
                  key={field.key}
                  sx={{
                    color: Colors.black,
                    fontSize: 14,
                    '& .MuiTypography-root': {
                      marginLeft: 10,
                    },
                  }}
                  label={<span>{field.name}</span>}
                  control={
                    <MatCheckbox
                      checked={field.status}
                      onChange={() => handleAccountStatusClick(field.key)}
                      name={field.name}
                    />
                  }
                />
              ))}
            </FormGroup>
          </FormControl>
        </Box>
      </Box>
    </MatDrawer>
  );
};

FilterDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
};

export default FilterDrawer;

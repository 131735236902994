/* eslint-disable no-console */
/* eslint-disable comma-spacing */
/* eslint-disable padded-blocks */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-undef */
/* eslint-disable no-trailing-spaces */
import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import PropTypes from 'prop-types';
import InfoCard from './InfoCard';

const CardHub = ({
  // data,
  item,
  tags,
  setValue,
  handleClickTag,
  cardTitle,
  handleUpdateItem,
  handleTitle,
  tabIndex,
  itemStyling,
}) => (
  <Box
    sx={{
      // width: '50%',
      // display: 'flex',
      // alignItems: 'flex-start',
      // justifyContent: 'center',
      marginTop: '32px',
      mx: 1,
    }}
  >
    <Box style={{ width: '100%' }}>
      {item && (
        <InfoCard
          setValue={setValue}
          image={item.info.image}
          title={cardTitle}
          item={item.info}
          description={item.info.description}
          ctaOne={item?.info?.ctaOne}
          tags={tags}
          styling={item.info.styling}
          active={true}
          handleClickTag={handleClickTag}
          onUpdate={handleUpdateItem}
          handleTitle={handleTitle}
          tabIndex={tabIndex}
          itemStyling={itemStyling}
        />
      )}
    </Box>
  </Box>
);

CardHub.propTypes = {
  item: PropTypes.object.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  setValue: PropTypes.func.isRequired,
  handleUpdateItem: PropTypes.func.isRequired,
  handleClickTag: PropTypes.func.isRequired,
  cardTitle: PropTypes.string.isRequired,
  handleTitle: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired,
  itemStyling: PropTypes.object.isRequired,
};

export default CardHub;

import React, { useState, useEffect } from 'react';

import TimeFrames, { ITimeFrameValue } from 'constants/TimeFrames';
import Permissions from 'constants/permissions';

import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Collapse, LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import useAccessControl from 'hooks/useAccessControl';
import Parse from 'parse';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import routes from 'routes';
import { RootState } from 'store';
import { userActions, userSelectors } from 'store/domain/user';
import { announcementActions } from 'store/entities/Announcements';
import { businessActions } from 'store/entities/Businesses';
import { campaignActions } from 'store/entities/Campaigns';
import { permissionsActions } from 'store/entities/Permissions';
import { reportActions, reportsSelector } from 'store/entities/Reports';
import { TeamHubActions } from 'store/entities/TeamHub';
import { getUserState } from 'store/selectors';
import useWindowSize from 'utils/hooks/useWindowSize';

import PermissionRequired from 'components/PermissionRequired';
import HomeContainer from 'components/ui/HomeContainer';
import Scaffold from 'components/ui/Scaffold';

import DataByTime from './components/DataByTime/DataByTime';
import EmptyDashboard from './components/EmptyDashboard/EmptyDashboard';
import EngagementsByGeography from './components/EngagementsByGeography/EngagementsByGeography';
import GenerateReport from './components/GenerateReport';
import MetricsContainer from './components/Metrics/MetricsContainer';
import RangeDrawer from './components/RangeDrawer';
import RecentActivity from './components/Recents/RecentActivity';
import TimeFrameDrawer from './components/TimeFrameDrawer';
import { styled } from '@mui/material/styles';

import config from 'config';
import queryString from 'query-string';
// import { useLocation } from 'react-router-dom';

interface CreateCampaignButtonProps {
  display: string;
}

const CreateCampaignButton: React.FC<CreateCampaignButtonProps> = ({
  display,
}) => (
  <PermissionRequired permissions={[Permissions.campaign.create]}>
    <Button
      component={Link}
      to={routes.campaigns.build()}
      variant="contained"
      startIcon={<AddIcon />}
    >
      {display}
    </Button>
  </PermissionRequired>
);

interface TimeFrameButtonProps {
  display: string;
  onClick: () => void;
}

const TimeFrameButton: React.FC<TimeFrameButtonProps> = ({
  display,
  onClick,
}) => (
  <Box
    width={124}
    sx={{
      cursor: 'pointer',
      backgroundColor: '#fff',
      borderRadius: '10px',
      px: 2,
      py: 0.8,
    }}
    ml={3}
    onClick={onClick}
    onKeyPress={onClick}
    tabIndex={0}
    role="button"
    display="flex"
  >
    <Typography sx={{ flexGrow: 1 }}>{display}</Typography>
    <KeyboardArrowDown />
  </Box>
);

interface DetailsButtonProps {
  display: string;
  onClick: () => void;
}

const ReportButton = styled(Button)<ButtonProps>(() => ({
  color: '#00CA51',
  textTransform: 'capitalize',
  boxShadow: 'none',
  backgroundColor: 'rgba(0, 202, 81, 0.15)',
  '&:hover': {
    backgroundColor: 'rgba(0, 202, 81, 0.15)',
  },
  borderRadius: 10,
  paddingY: 1,
  fontWeight: 400,
}));

const GenerateReportButton: React.FC<DetailsButtonProps> = ({
  display,
  onClick,
}) => (
  <ReportButton
    sx={{ fontSize: '15px' }}
    variant="contained"
    onClick={onClick}
    endIcon={<ReceiptIcon style={{ marginLeft: '5px' }} fontSize="small" />}
  >
    {display}
  </ReportButton>
);

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showTimeFrame, setShowTimeFrame] = useState(false);
  const [timeFrame, setTimeFrame] = useState<ITimeFrameValue>('month');
  const [timeFrameDrawerOpen, setTimeFrameDrawerOpen] = useState(false);
  const [metrics, setMetrics] = useState<any>([]);
  const [metricsChange, setMetricsChange] = useState<any>([]);
  const [loadingRecent, setLoadingRecent] = useState(true);
  const [loadingDashboard, setLoadingDashboard] = useState(true);
  const [reportOpen, setReportOpen] = useState(false);

  const onTimeFrameButtonClick = () => {
    setTimeFrameDrawerOpen(!timeFrameDrawerOpen);
  };

  const {
    dashboard,
    loadingMetrics,
    loadingDataOverTime,
    dataByTime,
  } = useSelector((state: RootState) => state.reports);
  const { accountStatus, statusIsLoading } = useSelector(
    (state: RootState) => state.subscriptions,
  );
  const { businessId, role, user, vendorId, session } = useSelector(
    getUserState,
  );
  const recentActivity = useSelector(reportsSelector.getRecentActivitiy);
  // const currentUser = useSelector(userSelectors.getCurrentUser);

  useEffect(() => {
    if (vendorId) {
      navigate(routes.templates.main());
    }
  }, [vendorId]);

  useEffect(() => {
    if (dashboard) {
      setMetrics(dashboard.metrics);
      setMetricsChange(dashboard.metricsChange);
    }
  }, [dashboard]);

  useEffect(() => {
    setLoadingRecent(false);
  }, [recentActivity]);

  useEffect(() => {
    dispatch(reportActions.fetchMetrics({ timeFrame }));
    //  dispatch(announcementActions.fetchCurrentAnnouncements({ filter: {} }));
    //  dispatch(announcementActions.fetchPastAnnouncements({ filter: {} }));
    dispatch(campaignActions.fetchAllCampaigns({}));
    dispatch(reportActions.getDataOverTime({ timeFrame }));
    dispatch(reportActions.clearStatus());
    dispatch(campaignActions.clearStatus());
    dispatch(userActions.refetchUserData());
    dispatch(userActions.getSession());
    setLoadingDashboard(true);
  }, [timeFrame, businessId]);

  useEffect(() => {
    const now = new Date();
    if (session.isValid === false || now >= session.expiration) {
      dispatch(userActions.logoutUser());
    }
  }, [session]);

  const handleCreateNewCampaign = () => {
    navigate(routes.campaigns.build());
  };

  const handleCreateNewTeam = () => {
    navigate(routes.teams());
  };

  const handleCreateNewUser = () => {
    navigate(routes.members());
  };

  const handleCampaignClick = () => {
    // dispatch(setSelectedCampaign({ campaign }));
    navigate('/CampaignDetails');
  };

  const handleAnnouncementClick = (announcement) => {
    navigate(routes.announcements.announcement(announcement.id));
  };

  const updateTimeFrame = (val) => {
    setTimeFrame(val);
    setShowTimeFrame(false);
  };

  const refreshActivity = () => {
    /* dispatch(getRecent({ timeFrame })); */
  };
  const ac = useAccessControl();
  const showDashboard = () =>
    ((metrics && metrics.teamMembers > 1) ||
      (recentActivity && recentActivity.length > 0)) &&
    accountStatus;

  return (
    <Scaffold
      HeaderProps={{
        title: 'Dashboard',
        actions: [
          // accountStatus && width >= 660 ? (
          //   <CreateCampaignButton display="new Campaign" />
          // ) : (
          //   <></>
          // ),
          accountStatus && showDashboard() && (
            <PermissionRequired permissions={[Permissions.report.create]}>
              <GenerateReportButton
                display="Generate Report"
                onClick={() => setReportOpen(true)}
              />
            </PermissionRequired>
          ),
          accountStatus && showDashboard() && (
            <TimeFrameButton
              display={TimeFrames[timeFrame].name}
              onClick={onTimeFrameButtonClick}
            />
          ),
        ],
        // children: accountStatus && (
        //   <TimeFrameButton
        //     display={TimeFrames[timeFrame].name}
        //     onClick={onTimeFrameButtonClick}
        //   />
        // ),
      }}
    >
      {statusIsLoading && !accountStatus ? (
        <Collapse in={true}>
          <LinearProgress />
        </Collapse>
      ) : showDashboard() ? (
        <HomeContainer>
          <Box
            display="grid"
            minHeight="322px"
            sx={(theme) => ({
              marginBottom: 5,
              gridTemplateColumns: '3fr 2fr',
              // gridTemplateRows: '1fr 1fr',
              gap: theme.spacing(4),
              [theme.breakpoints.down('lg')]: {
                display: 'flex',
                flexDirection: 'column',
              },
            })}
          >
            <div>
              <MetricsContainer
                isLoading={loadingMetrics}
                engagements={metrics?.engagements || 0}
                impressions={metrics?.impressions || 0}
                conversions={metrics?.actions || 0}
                teamMembers={metrics?.teamMembers || 0}
                engagementsIncrease={metricsChange?.engagements || 0}
                impressionsIncrease={metricsChange?.impressions || 0}
                conversionsIncrease={metricsChange?.actions || 0}
                selectedTimeFrame={timeFrame}
              />
            </div>
            <div>
              <EngagementsByGeography timeFrame={timeFrame} />
            </div>
            <Box
              minHeight="400px"
              sx={(theme) => ({
                [theme.breakpoints.up('md')]: {
                  minWidth: '574px',
                },
              })}
            >
              <DataByTime data={dataByTime} isLoading={loadingDataOverTime} />
            </Box>
            <div>
              <RecentActivity
                isLoading={loadingRecent}
                activity={recentActivity}
                handleCampaignClick={handleCampaignClick}
                handleAnnouncementClick={handleAnnouncementClick}
                refreshActivity={refreshActivity}
                handleCreate={handleCreateNewCampaign}
              />
            </div>
          </Box>
          {/* <Box
            display="grid"
            sx={(theme) => ({
              gridTemplateColumns: '3fr 2fr',
              gap: theme.spacing(4),
              [theme.breakpoints.down('lg')]: {
                display: 'flex',
                flexDirection: 'column',
              },
            })}
          >
            <Box
              minHeight="322px"
              sx={(theme) => ({
                [theme.breakpoints.up('md')]: {
                  minWidth: '574px',
                },
              })}
            >
              <DataByTime data={dataByTime} isLoading={loadingDataOverTime} />
            </Box>
            <div>
              <RecentActivity
                isLoading={loadingRecent}
                activity={recentActivity}
                handleCampaignClick={handleCampaignClick}
                handleAnnouncementClick={handleAnnouncementClick}
                refreshActivity={refreshActivity}
                handleCreate={handleCreateNewCampaign}
              />
            </div>
          </Box> */}
        </HomeContainer>
      ) : (
        <EmptyDashboard
          handleCreateNewCampaign={handleCreateNewCampaign}
          handleCreateNewTeam={handleCreateNewTeam}
          handleCreateNewUser={handleCreateNewUser}
          subscriptionStatus={accountStatus}
          owner={role === 'owner'}
          verified={user?.emailVerified}
          isActive={user?.is_active || false}
        />
      )}
      <GenerateReport open={reportOpen} onClose={() => setReportOpen(false)} />
      <RangeDrawer
        open={showTimeFrame}
        onClose={() => setShowTimeFrame(false)}
        onSubmit={updateTimeFrame}
        defaultTimeFrame={timeFrame}
      />
      <TimeFrameDrawer
        open={timeFrameDrawerOpen}
        onClose={() => setTimeFrameDrawerOpen(false)}
        onChange={(value) => {
          setTimeFrame(value);
          setTimeFrameDrawerOpen(false);
        }}
        value={timeFrame}
      />
    </Scaffold>
  );
};

export default Dashboard;

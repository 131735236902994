import React from 'react';
import Box from '@mui/material/Box';

function SVG9() {
  return (
    <div
      id="SVG9"
      style={{
        height: '70px',
        width: '70px',
        display: 'flex',
      }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          height: '45px',
          width: '45px',
          stroke: '#ffffff',
          strokeWidth: 2.0,
          margin: 'auto',
        }}
      >
        <path
          d="M3.1 9.8V6.4L12 2L20.9 6.4V9.8M3.1 14.2V17.6L12 22M12 22L20.9 17.6V14.2M12 22V16.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 16.4C13.167 16.4 14.2861 15.9364 15.1113 15.1112C15.9364 14.2861 16.4 13.1669 16.4 12C16.4 10.833 15.9364 9.71387 15.1113 8.88871C14.2861 8.06355 13.167 7.59998 12 7.59998C10.833 7.59998 9.71389 8.06355 8.88873 8.88871C8.06357 9.71387 7.6 10.833 7.6 12C7.6 13.1669 8.06357 14.2861 8.88873 15.1112C9.71389 15.9364 10.833 16.4 12 16.4Z"
          strokeMiterlimit="10"
        />
      </svg>
    </div>
  );
}

export default SVG9;

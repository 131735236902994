import React from 'react';

import Colors from 'constants/colors';

import { Box, SxProps } from '@mui/material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';
import useWindowSize from 'utils/hooks/useWindowSize';

const TemplateCard = ({
  title,
  description,
  editingMode,
  icon,
  disabled,
  onClick,
}) => {
  const cardStyle = (): SxProps => {
    if (disabled) {
      return {
        textAlign: 'left',
        py: 2,
        px: 2,
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        borderRadius: 2,
        backgroundColor: '#BDBDBD',
      };
    }
    if (editingMode) {
      return {
        textAlign: 'left',
        py: 2,
        px: 2,
        width: '100%',
        height: '100%',
        cursor: 'not-allowed',
        borderRadius: 2,
        backgroundColor: Colors.white,
        '&:hover': {
          backgroundColor: Colors.darkGray,
          '& $title': {
            color: Colors.white,
          },
          '& $subtitle': {
            color: Colors.white,
          },
        },
      };
    }
    return {
      textAlign: 'left',
      py: 2,
      px: 2,
      width: '100%',
      height: '100%',
      cursor: 'pointer',
      borderRadius: 2,
      backgroundColor: Colors.white,
      '&:hover': {
        backgroundColor: Colors.primary,
        '& $title': {
          color: Colors.white,
        },
        '& $subtitle': {
          color: Colors.white,
        },
      },
    };
  };

  const { width } = useWindowSize();

  return (
    <Box height={1} sx={{ maxHeight: 100 }}>
      <Paper
        elevation={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          ...cardStyle(),
        }}
        onClick={onClick}
      >
        {width > 900 ? (
          <img
            alt="Icon"
            src={icon}
            style={{
              width: 50,
              height: 50,
            }}
          />
        ) : (
          <img
            alt="Icon"
            src={icon}
            style={{
              width: 10,
              height: 10,
            }}
          />
        )}
        <Typography
          sx={{
            fontSize: '0.75em',
            fontWeight: 'bold',
            p: 1,
            overflowWrap: 'break-word',
          }}
        >
          {title}
        </Typography>
        {/* <Typography variant="body2">
          {description}
        </Typography> */}
      </Paper>
    </Box>
  );
};

TemplateCard.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  editingMode: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

TemplateCard.defaultProps = {
  disabled: false,
  onClick: null,
};

export default TemplateCard;

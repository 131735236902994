import React, { useEffect } from 'react';

import Subscriptions from 'constants/subscriptions';

import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import ButtonIcon from 'assets/images/button_icon.svg';
import FormIcon from 'assets/images/form_icon.svg';
import ImageIcon from 'assets/images/image_icon.svg';
import InfoIcon from 'assets/images/info_icon.svg';
import PollIcon from 'assets/images/poll_icon.svg';
import SocialIcon from 'assets/images/social_icon.svg';
import VideoIcon from 'assets/images/video_icon.svg';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { integrationsActions } from 'store/entities/Integrations';

import MatInput from 'components/Elements/MatInput/MatInput';

import DisabledIntegrationCard from './DisabledIntegrationCard';
import TemplateCard from './TemplateCard';

function Components({ editing, onShowSubscription, title, handleTitle }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { subscriptionType } = useSelector(
    (state: any) => state.subscriptions,
  );

  const componentTypes = [
    {
      id: 'info-template',
      name: 'info',
      icon: InfoIcon,
      description: t('addCardWithImage'),
      title: t('informationCard'),
    },
    {
      id: 'image-template',
      name: 'image',
      icon: ImageIcon,
      description: t('addImageDisplayDifferentProducts'),
      title: t('imageCard'),
    },
    {
      id: 'video-template',
      name: 'video',
      icon: VideoIcon,
      description: t('embedVideoYoutubeVimeo'),
      title: t('videoCard'),
    },
    {
      id: 'button-template',
      name: 'button',
      icon: ButtonIcon,
      description: t('addClickableButton'),
      title: t('button'),
    },
    {
      id: 'social-template',
      name: 'social',
      icon: SocialIcon,
      description: t('addSocialMediaLink'),
      title: t('socialMedia'),
    },
    /* {
      id: 'form-template',
      name: 'form',
      icon: FormIcon,
      description: t('createSmallForm'),
      title: t('forms'),
    },
    {
      id: 'poll-template',
      name: 'poll',
      icon: PollIcon,
      description: t('takeDisplayPollResult'),
      title: t('survey'),
    }, */
    {
      id: 'carousel-template',
      name: 'carousel',
      icon: ImageIcon,
      description: t('scrollableImageCarossel'),
      title: t('imageCarousel'),
    },
    {
      id: 'mail-chimp',
      name: 'mailchimp',
      icon: InfoIcon,
      description: t('useMailchimpForm'),
      title: t('mailchimp'),
    },
  ];

  useEffect(() => {
    dispatch(integrationsActions.getEligibility());
    dispatch(integrationsActions.getMailChimpStatus());
  }, []);

  const { showIntegrations, mailChimpConnected } = useSelector(
    (state: RootState) => state.integrations,
  );
  // const { validElements } = useSelector((state) => state.campaignsState);

  // eslint-disable-next-line
  const checkDisabled = (item) => {
    // if (validElements && !validElements[item.name]) {
    //   return true;
    // }
    if (
      (item.name === 'mailchimp' || item.name === 'video') &&
      subscriptionType === Subscriptions.premium.type
    ) {
      return true;
    }
    return false;
  };

  return (
    <Box
      py={3}
      sx={{
        width: '100%',
        flexGrow: 1,
        minWidth: 300,
        overflowY: 'auto',
        overflowX: 'hidden',
        flexWrap: 'nowrap',
        paddingRight: 1,
      }}
    >
      {/* <Paper
        sx={{
          padding: 1,
          marginBottom: 3,
          borderRadius: 1,
        }}
      >
        <MatInput
          value={title}
          variant="outlined"
          placeholder={t('campaignTitle')}
          onChange={handleTitle}
          size="small"
        />
      </Paper> */}
      <Droppable droppableId="template" isDropDisabled={true}>
        {(provided) => (
          <div ref={provided.innerRef}>
            <Grid container spacing={2} alignItems="stretch">
              {componentTypes.map((item, index) => {
                const disabled = checkDisabled(item);
                if (item.name === 'mailchimp' && !showIntegrations) {
                  return null;
                } else if (item.name === 'mailchimp' && !mailChimpConnected) {
                  return (
                    <Grid item md={12} xs={12} xl={6}>
                      <DisabledIntegrationCard
                        icon={item.icon}
                        description={item.description}
                        title={item.title}
                      />
                    </Grid>
                  );
                }
                return (
                  <Grid item md={12} xs={12} xl={6}>
                    <Draggable
                      key={item.name}
                      draggableId={item.name}
                      index={index}
                      isDragDisabled={disabled}
                      style={{ height: '100%' }}
                      sx={{
                        height: '100% !important',
                      }}
                    >
                      {(prov) => (
                        <Box
                          sx={{
                            height: '100% !important',
                          }}
                          ref={prov.innerRef}
                          {...prov.draggableProps}
                          {...prov.dragHandleProps}
                        >
                          <TemplateCard
                            icon={item.icon}
                            description={item.description}
                            title={item.title}
                            editingMode={editing}
                            disabled={disabled}
                            onClick={disabled ? onShowSubscription : null}
                          />
                        </Box>
                      )}
                    </Draggable>
                  </Grid>
                );
              })}
            </Grid>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </Box>
  );
}

export default Components;

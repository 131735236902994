import React, { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';

function SVG1() {
  return (
    <div
      id="SVG1"
      style={{
        height: '70px',
        width: '70px',
        display: 'flex',
      }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          height: '45px',
          width: '45px',
          stroke: '#ffffff',
          strokeWidth: 2.0,
          margin: 'auto',
        }}
      >
        <path
          d="M8 16L12 7L16 16M8 12H12"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
          strokeMiterlimit="10"
        />
      </svg>
    </div>
  );
}

export default SVG1;

import { Theme } from '@mui/material';
import { Styles } from '@mui/styles';

import Colors from './colors';

export const defaultStyles = {
  content: {
    padding: '25px 50px',
  },
  box: {
    shadow: '0 2px 5px 0 rgba(182,194,213,0.5)',
    radius: 8,
  },
  input: {
    shadow: '0 2px 7px 0 rgba(111,135,183,0.2)',
  },
};

export const tableStyles: Styles<Theme, any> = {
  table: {
    borderSpacing: ' 0 10px !important',
    borderCollapse: 'separate !important' as any,
    '& .MuiTableCell-root': {
      border: 'none !important',
    },
  },
  tBody: {
    '& tr': {
      color: Colors.black,
      borderRadius: 2.5,
    },
    '& tr td:first-child': {
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
      overflow: 'hidden',
    },
    '& tr td:last-child': {
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
      overflow: 'hidden',
    },
  },
  cell: {
    border: 'none',
    padding: 0,
    minHeight: 64,
  },
  smallText: {
    fontSize: 12,
    color: 'inherit',
  },
  boldText: {
    fontSize: 16,
    fontWeight: 700,
    color: 'inherit',
  },
  mediumText: {
    fontSize: 16,
    color: 'inherit',
  },
  bodyRow: {
    boxShadow: defaultStyles.box.shadow,
    backgroundColor: Colors.white,
    borderRadius: 2.5,
    '&:hover': {
      boxShadow: ' 0px 0px 0px 1px rgb(0 99 255 / 50%)',
      backgroundColor: `${Colors.white} !important`,
      cursor: 'pointer',
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  icon: {
    color: Colors.white,
  },
  headerText: {
    color: '#212121',
    fontSize: 10,
  },
  firstHeaderText: {
    color: '#212121',
    fontSize: 10,
    marginLeft: 84,
  },
};

export const quillStyles = {
  '& .ql-editor>*': {
    fontSize: 14,
  },
  '& .ql-toolbar.ql-snow': {
    borderColor: Colors.primary,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
  },
  '& .ql-toolbar.ql-snow + .ql-container.ql-snow': {
    borderColor: Colors.primary,
    color: Colors.black,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
  },
  '& .ql-editor.ql-blank::before': {
    fontStyle: 'normal',
    color: Colors.black,
    fontSize: 14,
  },
};

/* eslint-disable no-console */
/* eslint-disable comma-spacing */
/* eslint-disable padded-blocks */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-undef */
/* eslint-disable no-trailing-spaces */
import React, { useEffect, useState } from 'react';

import Colors from 'constants/colors';

import { Box, SxProps } from '@mui/material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import Iphone from 'assets/images/iphone-with-notch.png';
import Menu from 'assets/images/menu.svg';
import PropTypes from 'prop-types';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import EmulatorDrawer from 'components/Emulators/components/EmulatorDrawer';

import CardDisplay from './CardDisplay';
import Empty from './Empty';

const DragNDropEmulator = ({
  data,
  businessPhoto,
  businessName,
  activeRow,
  setActiveRow,
  editing,
  conditions,
  setValue,
  handleUpdateItem,
}) => {
  const [showDrawer, setShowDrawer] = useState(false);

  const checkType = (val) => {
    if (
      val === 'info' ||
      val === 'image' ||
      val === 'video' ||
      val === 'button' ||
      val === 'social' ||
      val === 'form' ||
      val === 'poll' ||
      val === 'carousel'
    ) {
      return true;
    }
    return false;
  };

  const getDragStyle = (dragging, draggingWith): SxProps => {
    if (dragging) {
      if (editing) {
        return {
          borderColor: Colors.error,
          backgroundColor: Colors.lightRed,
          borderStyle: 'solid',
          borderWidth: 4,
          borderRadius: 2.5,
          width: '100%',
          height: '100%',
          paddingTop: 2,
        };
      } else if (data?.length > 4 && checkType(draggingWith)) {
        return {
          borderColor: Colors.error,
          backgroundColor: Colors.lightRed,
          borderStyle: 'solid',
          borderWidth: 4,
          borderRadius: 2.5,
          width: '100%',
          height: '100%',
          paddingTop: 2,
        };
      }
      return {
        borderColor: Colors.success,
        backgroundColor: Colors.lightGreen,
        borderStyle: 'solid',
        borderWidth: 4,
        borderRadius: 2.5,
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        paddingTop: 2,
      };
    }
    return {
      width: '100%',
      height: '100%',
      paddingTop: 2,
      overflowY: 'auto',
    };
  };

  return (
    <Box
      sx={{
        width: 350,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        mx: 3,
        height: 'calc(100vh - 64px)',
      }}
    >
      <Box style={{ width: '100%' }}>
        <Box style={{ display: 'inline-block' }}>
          <Box
            sx={{
              backgroundImage: `url(${Iphone})`,
              backgroundSize: '275px 550px',
              height: 550,
              width: 275,
              paddingTop: 2.25,
              paddingLeft: 2.5,
              paddingRight: 2.5,
              paddingBottom: 2,
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
            }}
          >
            <Paper
              sx={{
                width: '100%',
                height: 50,
                backgroundColor: Colors.white,
                borderRadius: '20px 20px 0 0',
                display: 'flex',
                textAlign: 'left',
                alignItems: 'center',
              }}
              elevation={2}
            >
              {businessPhoto && businessPhoto !== null && businessPhoto !== '' && (
                <img
                  alt="Business"
                  src={businessPhoto}
                  style={{
                    height: 35,
                    marginLeft: 1,
                    borderRadius: 4,
                  }}
                />
              )}

              <Box
                sx={{
                  flexGrow: 1,
                  marginLeft: 4,
                }}
              >
                <Typography variant="subtitle2">{businessName}</Typography>
              </Box>
              <Box sx={{ paddingTop: 1.25, marginRight: 1 }}>
                <input
                  type="image"
                  alt="Menu"
                  src={Menu}
                  onClick={() => setShowDrawer(true)}
                />
              </Box>
            </Paper>
            <Box
              sx={{
                flexGrow: 1,
                backgroundColor: Colors.background,
                borderRadius: '0 0 20px 20px',
                overflowX: 'hidden',
                overflowY: 'hidden',
                width: '100%',
              }}
            >
              <Droppable droppableId="emulator">
                {(provided, snapshot) => (
                  <Box
                    ref={provided.innerRef}
                    sx={getDragStyle(
                      snapshot.isDraggingOver,
                      snapshot.draggingOverWith,
                    )}
                  >
                    {data?.length > 0 ? (
                      data.map((array, index) => (
                        <Draggable
                          key={array.item.id}
                          draggableId={array.item.id}
                          index={index}
                        >
                          {(prov) => (
                            <div
                              ref={prov.innerRef}
                              {...prov.draggableProps}
                              {...prov.dragHandleProps}
                            >
                              <CardDisplay
                                item={array.item}
                                setValue={setValue}
                                cardId={`Card ${index + 1}`}
                                index={index}
                                activeRow={activeRow}
                                handleActiveRow={(val) => setActiveRow(val)}
                                onUpdate={(item) => {
                                  handleUpdateItem(item);
                                }}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))
                    ) : (
                      <Empty />
                    )}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </Box>
            <EmulatorDrawer
              open={showDrawer}
              onClose={() => setShowDrawer(false)}
              conditions={conditions}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

DragNDropEmulator.propTypes = {
  data: PropTypes.object.isRequired,
  businessPhoto: PropTypes.string.isRequired,
  businessName: PropTypes.string.isRequired,
  editing: PropTypes.bool.isRequired,
  activeRow: PropTypes.number.isRequired,
  setActiveRow: PropTypes.func.isRequired,
  setValue: PropTypes.func,
  handleUpdateItem: PropTypes.func,
  conditions: PropTypes.string,
};

DragNDropEmulator.defaultProps = {
  conditions: null,
};

export default DragNDropEmulator;

import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';

import MatDrawer from 'components/Elements/MatDrawer/MatDrawer';
import { useSnackbar } from 'notistack';
import { subscriptionActions } from 'store/entities/Subscriptions';
import { useDispatch } from 'react-redux';

const AddCardDrawer = ({ open, onClose }) => {
  const stripe: any = useStripe();
  const elements: any = useElements();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const addNewCard = async () => {
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    } else {
       dispatch(
        subscriptionActions.addNewCard({
          paymentId: paymentMethod.id,
         }),
       );
       onClose();
    }
  };

  return (
    <MatDrawer
      open={open}
      anchor="right"
      onClose={onClose}
      onCancel={onClose}
      onSubmit={addNewCard}
      title="Add New Credit Card"
      submitBtn="Update"
      cancelBtn="Cancel"
      isLoading={false}
    >
      <Box maxWidth={450}>
        <Typography sx={{ marginBottom: 2, flexGrow: 1, fontSize: 14 }}>
          Card Information
        </Typography>
        <CardElement />
      </Box>
    </MatDrawer>
  );
};

AddCardDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddCardDrawer;

import React from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';

import { ReactComponent as SortIcon } from 'assets/images/sort_icon.svg';
import PropTypes from 'prop-types';

const HeaderCell = ({ column, orderBy, order, onCreateSortHandler }) => (
  <TableCell
    key={column.key}
    sortDirection={(orderBy === column.key ? order : false) as any}
    sx={{
      border: 'none',
      padding: 0,
      minHeight: 64,
    }}
    // eslint-disable-next-line
    align={
      column.key === 'title' || // eslint-disable-line
      column.key === 'is_active' || // eslint-disable-line
      column.key === 'status' // eslint-disable-line
        ? 'left' // eslint-disable-line
        : column.key === 'qr' // eslint-disable-line
        ? 'right' // eslint-disable-line
        : 'center' // eslint-disable-line
    }
  >
    <TableSortLabel
      active={orderBy === column.key}
      direction={order as any}
      onClick={() => onCreateSortHandler(column.key)}
      IconComponent={SortIcon as any}
      hideSortIcon={!column.key}
      sx={{
        icon: {
          color: Colors.white,
        },
        active: {
          color: Colors.white,
        },
      }}
    >
      <Typography
        variant="subtitle1"
        sx={
          column.key === 'title' ||
          column.key === 'is_active' ||
          column.key === 'status'
            ? {
                color: '#212121',
                fontSize: 10,
                marginLeft: 10.5,
              }
            : {
                color: '#212121',
                fontSize: 10,
              }
        }
      >
        {column.name}
      </Typography>
      {orderBy === column.key ? (
        <Box
          component="span"
          border={0}
          height={1}
          m={-0.1}
          overflow="hidden"
          p={0}
          position="absolute"
          top={20}
          width={1}
          sx={{ clip: 'rect(0 0 0 0)' }}
        >
          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
        </Box>
      ) : null}
    </TableSortLabel>
  </TableCell>
);

HeaderCell.propTypes = {
  column: PropTypes.object.isRequired,
  orderBy: PropTypes.string,
  order: PropTypes.string.isRequired,
  onCreateSortHandler: PropTypes.func.isRequired,
};

HeaderCell.defaultProps = {
  orderBy: null,
};

export default HeaderCell;

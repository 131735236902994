import React from 'react';

import Colors from 'constants/colors';

import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';
import MatInput from 'components/Elements/MatInput/MatInput';

const MailChimpCard = ({ onClick, active, cardId, title, styling }) => (
  <Box
    sx={{
      marginLeft: 1,
      marginRight: 1,
      marginBottom: 2,
    }}
  >
    <Card
      sx={
        active
          ? {
              textAlign: 'left',
              borderColor: Colors.primaryLight,
              borderStyle: 'solid',
              borderWidth: 3,
              position: 'relative',
              borderRadius: styling?.cardBorder,
            }
          : {
              width: '100%',
              textAlign: 'left',
              cursor: 'pointer',
              position: 'relative',
              borderRadius: styling?.cardBorder,
            }
      }
      onClick={onClick}
    >
      <Box
        sx={{
          padding: 2,
        }}
      >
        <Box
          color={styling?.mainColor}
          textAlign={styling?.mainAlignment}
          fontSize={styling?.mainSize}
          fontStyle={styling?.mainFormat?.includes('italic') ? 'italic' : ''}
          sx={{
            textDecoration: styling?.mainFormat?.includes('underline')
              ? 'underline'
              : '',
          }}
        >
          <Typography variant="subtitle1">
            {title || 'Join our email list for more offers'}
          </Typography>
        </Box>
        <MatInput
          label="Email"
          variant="outlined"
          size="small"
          fullWidth
          disabled
          sx={{
            marginTop: 2,
            marginBottom: 2,
          }}
        />
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            color="primary"
            variant={styling?.buttonVariant}
            disableElevation
            sx={{
              borderRadius: styling?.buttonBorder,
              color: styling?.textButtonColor,
              height: 24,
              borderColor:
                styling?.buttonVariant === 'outlined'
                  ? styling?.buttonColor
                  : '',
              backgroundColor:
                styling?.buttonVariant === 'contained'
                  ? styling?.buttonColor
                  : '',
            }}
          >
            <Typography
              sx={{
                fontSize: 12,
              }}
            >
              Submit
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          top: 0,
          right: 0,
          height: 20,
          marginTop: 1.25,
          marginRight: 0.75,
          paddingLeft: 1.25,
          paddingRight: 1.25,
          position: 'absolute',
          backgroundColor: 'rgba(97,97,97 ,0.7)',
          borderRadius: 6.25,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            color: Colors.white,
            fontSize: 10,
          }}
        >
          {cardId}
        </Typography>
      </Box>
    </Card>
  </Box>
);

MailChimpCard.propTypes = {
  active: PropTypes.bool.isRequired,
  cardId: PropTypes.string.isRequired,
  styling: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
};

MailChimpCard.defaultProps = {
  title: null,
};

export default MailChimpCard;

import React from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { ReactComponent as AlertIcon } from 'assets/images/alert_icon.svg';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';

import MatFormLabel from 'components/Elements/MatFormLabel/FormLabel';
import MatSelect from 'components/Elements/MatSelect/MatSelect';

const QrCodeTab = ({
  activeLocations,
  locationId,
  setLocationId,
  inviteLink,
  setShowLocationCreationDrawer,
}) => {
  const handleSelectLocation = (event) => {
    setLocationId(event.target.value);
  };

  const renderQrCode = () => {
    if (!inviteLink) {
      return null;
    }

    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <QRCode
          id="qr-code-id-drawer"
          value={inviteLink}
          fgColor={Colors.primary}
          size={200}
        />
        <Box mt={5}>
          <Typography align="center">
            Click the download button to download the code.
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} sx={{ marginBottom: 2 }}>
          <Typography align="center">
            This will generate and download a QR Code to your machine. The QR
            Code, when scanned will redirect the team member to a link for
            signup.
          </Typography>
        </Grid>
        <Grid item md={12} xs={12} sx={{ marginBottom: 2 }}>
          <MatFormLabel
            icon={
              <Tooltip title="Select which location to add the team member to">
                <AlertIcon />
              </Tooltip>
            }
          >
            Team
          </MatFormLabel>
          <MatSelect
            value={locationId}
            onChange={handleSelectLocation}
            options={activeLocations}
            nameKey="name"
            valueKey="id"
            size="small"
          />
          <Typography
            onClick={() => setShowLocationCreationDrawer(true)}
            sx={{
              color: Colors.primary,
              display: 'inline-block',
              cursor: 'pointer',
              marginTop: 2,
            }}
          >
            Add Team
          </Typography>
        </Grid>
        <Grid xs={12}>{renderQrCode()}</Grid>
      </Grid>
    </>
  );
};

QrCodeTab.defaultProps = {
  inviteLink: '',
};

QrCodeTab.propTypes = {
  activeLocations: PropTypes.array.isRequired,
  locationId: PropTypes.string.isRequired,
  setLocationId: PropTypes.func.isRequired,
  setShowLocationCreationDrawer: PropTypes.func.isRequired,
  inviteLink: PropTypes.string,
};

export default QrCodeTab;

import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { campaignActions } from 'store/entities/Campaigns';

import MatDrawer from 'components/Elements/MatDrawer/MatDrawer';
import MatFormLabel from 'components/Elements/MatFormLabel/FormLabel';
import MatInput from 'components/Elements/MatInput/MatInput';

const TerminateDrawer = ({ open, onClose, campaignId, type, onSuccess }) => {
  const dispatch = useDispatch();
  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleTerminate = () => {
    if (reason != null && reason !== '') {
      setLoading(true);
      setIsError(false);
      dispatch(
        campaignActions.terminateCampaign({
          campaignId,
          reason,
          callback: () => {
            setLoading(false);
            setReason('');
            onSuccess();
            onClose();
          },
        }),
      );
    } else {
      setIsError(true);
    }
  };

  return (
    <MatDrawer
      open={open}
      anchor="right"
      onClose={onClose}
      onCancel={onClose}
      onSubmit={handleTerminate}
      title="Terminate Campaign"
      submitBtn="Terminate"
      cancelBtn="Cancel"
      isLoading={loading}
    >
      <Box maxWidth={450}>
        <Typography variant="subtitle2">
          End this campaign before it reaches its end date.
        </Typography>
        <Box mt={3}>
          <MatFormLabel>Reason for Termination</MatFormLabel>
          <MatInput
            value={reason}
            multiline={true} // eslint-disable-line
            rows={5}
            variant="outlined"
            placeholder=""
            onChange={(e) => setReason(e.target.value)}
            helperText={isError ? 'Reason for Termination is Required' : ''}
            error={isError}
          />
        </Box>
      </Box>
    </MatDrawer>
  );
};

TerminateDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  campaignId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default TerminateDrawer;

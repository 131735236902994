import React from 'react';

import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import {
  TitleComponent,
  SubtitleComponent,
  ContentComponent,
} from '../TemplateComponents';

import {
  CollectedInformationList,
  UsingInformationList,
  SharingInformationList,
} from './components/PrivacyList';

let theme = createTheme();
theme = responsiveFontSizes(theme);
function PrivacyAndPolicy() {
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={theme}>
      <Container
        sx={{
          color: 'blue',
          maxWidth: '1024px !important',
          fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif',
        }}
      >
        <TitleComponent>{t('PRIVACY POLICY')}</TitleComponent>
      </Container>
      <Container
        sx={{
          color: 'black',
          maxWidth: '1024px !important',
          fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif',
        }}
      >
        <SubtitleComponent>
          {t(
            'PLEASE READ THIS PRIVACY POLICY CAREFULLY BEFORE USING THE SERVICES',
          )}
        </SubtitleComponent>
        <ContentComponent>
          {t(
            'SpeakEasy Software LLC dba TappShare (“Company” or “we”), recognizes the importance of your privacy.  This Privacy Policy outlines the types of information we gather when you use any website or mobile application made available by Company with this Privacy Policy (each a “Service”), and how we use that information, as well as some of the steps we take to safeguard it.  It is important to review this Privacy Policy in its entirety.',
          )}
        </ContentComponent>
        <ContentComponent>
          {t(
            'As part of the operation of the Services, certain pieces of information are gathered about the users (individually, “you” or a “User” and collectively, “Users”).  This Privacy Policy contains explanations regarding the types of information collected, what is done with such information, and how to correct or change the information.',
          )}
        </ContentComponent>
        <SubtitleComponent>{t('The Information We Collect')}</SubtitleComponent>
        <ContentComponent>
          {t(
            'Information you Provide to Company.  We collect Personally Identifying Information that you provide to Company.  “Personally Identifying Information” is information that individually identifies you, and may include your name, physical address, phone number, and e-mail address, and any other information that would allow someone to identify or contact you.  Of course, you may choose not to provide Company with Personally Identifying Information. However, this choice may prevent Company from providing you with the information or Services you requested or that could be offered to you.',
          )}
        </ContentComponent>
        <ContentComponent>
          {t(
            'Message Content.  We collect the contents of any messages that are typed directly into the Services as part of a text to an individual user (“Message Content”).  We do not collect the content of any messages that are typed directly into the default text messaging application used by your phone and cannot read or access any such messages, even when you send an offer or other content from the Services (“Services Content”) via text messages.',
          )}
        </ContentComponent>
        <ContentComponent>
          {t(
            'Information We Collect Automatically When You Use the Services.  When you access or use the Services, we may automatically collect information about you, including:',
          )}
        </ContentComponent>
        <CollectedInformationList />
        <SubtitleComponent>{t('Using the Information')}</SubtitleComponent>
        <ContentComponent>
          {t(
            'Use in General.  We may use your information for a variety of purposes, including to:',
          )}
        </ContentComponent>
        <UsingInformationList />
        <ContentComponent>
          {t(
            'Use of Message Content.  In addition to the other uses described in this Privacy Policy, we may share any Message Content with the organization or business that originally sent the Services Content for purposes of evaluating the effectiveness of different types of Message Content and for assessing how to best use the Services.',
          )}
        </ContentComponent>
        <ContentComponent>
          {t(
            'Use of Aggregate or De-Identified Information.  In addition to the individual data use described in this Privacy Policy, we may aggregate information about you and other individual users together, or otherwise de-identify the information about you, so that the information does not identify you personally.  We may use information in these forms for a variety of purposes, including for research and analysis, administration of the Services, and advertising. We own all de-identified data collected through the Services.',
          )}
        </ContentComponent>
        <SubtitleComponent>
          {t('Sharing and Disclosure of Information')}
        </SubtitleComponent>
        <ContentComponent>
          {t(
            'We will not share Personally Identifying Information about you with any third parties except as described in this Privacy Policy:',
          )}
        </ContentComponent>
        <SharingInformationList />
        <SubtitleComponent>{t('Public Forums')}</SubtitleComponent>
        <ContentComponent>
          {t(
            'You should be aware that when you voluntarily disclose any Personally Identifying Information in a public forum available on the Services, this information, along with any information disclosed in your communication, can be collected and used by third parties and may result in unsolicited messages from third parties. Such activities are beyond our control and this Privacy Policy does not apply to such information. You accept that you, and not Company, are responsible for providing such information in any public forum. Any submissions to public areas on the Services are accepted by you with the understanding that such forums are accessible to all third parties. If you do not want your comments or any content to be viewed by third parties, you should not submit comments or contents on public forums.',
          )}
        </ContentComponent>
        <SubtitleComponent>{t('Safeguards')}</SubtitleComponent>
        <ContentComponent>
          {t(
            'Company protects your Personally Identifying Information by using information security safeguards.  Unfortunately, neither the network nor data transmission over the internet can be guaranteed to be 100% secure.  As a result, while we strive to protect a User’s information, Company cannot ensure or warrant the security of Personally Identifying Information or other information submitted through a Service.  Thus, any information a User transmits to us is done so at the User’s own risk.',
          )}
        </ContentComponent>
        <SubtitleComponent>{t('Online Tracking')}</SubtitleComponent>
        <ContentComponent>
          {t('The Services do not respond to Do Not Track signals.')}
        </ContentComponent>
        <SubtitleComponent>
          {t('Accountability and Compliance')}
        </SubtitleComponent>
        <ContentComponent>
          {t(
            'You may address any question regarding how Company complies with this Privacy Policy to the e-mail address set forth in this Privacy Policy.',
          )}
        </ContentComponent>
        <SubtitleComponent>{t('Third-Party Websites')}</SubtitleComponent>
        <ContentComponent>
          {t(
            'On occasion, the Services are connected by “hyperlinks” to other third party’s websites.  Please note that Company is not responsible in any way for the privacy practices of other websites and suggests that you review the privacy policies of those other companies’ websites before using them.',
          )}
        </ContentComponent>
        <SubtitleComponent>
          {t('Accessing Your Personally Identifying Information Once Given')}
        </SubtitleComponent>
        <ContentComponent>
          {t(
            'If you believe your Personally Identifying Information is inaccurate, you may contact Company to amend or delete your Personally Identifying Information.  You have the right to request information about the existence, use, and disclosure of your Personally Identifying Information and to challenge the accuracy and completeness of your Personally Identifying Information, and to have your Personally Identifying Information amended where inaccurate or deleted from the Company database.  To do so, simply e-mail Company at the e-mail address set forth in this Privacy Policy.',
          )}
        </ContentComponent>
        <SubtitleComponent>
          {t('Social Media Features and Widgets')}
        </SubtitleComponent>
        <ContentComponent>
          {t(
            'The Services include social media features and widgets. These features may collect your IP address, which page you are visiting through the Services, other applicable information, and may set a cookie to enable the feature to function properly. Social media functions are either hosted by a third party or hosted directly through the Services. Your interactions with these features are governed by the privacy policy of the company providing them.',
          )}
        </ContentComponent>
        <SubtitleComponent>{t('Minors')}</SubtitleComponent>
        <ContentComponent>
          {t(
            'The Services are not intended for people under the age of 13.  Further, we do not knowingly collect Personally Identifying Information from children under the age of 13.  If you are a parent or guardian of a child under the age of 13 and believe he or she has disclosed Personally Identifying Information to us, please contact us at the e-mail address set forth in this Privacy Policy and Company will delete such Personally Identifying Information.',
          )}
        </ContentComponent>
        <SubtitleComponent>{t('Consent to Transfer')}</SubtitleComponent>
        <ContentComponent>
          {t(
            'Please be aware that information we collect through the Service may be transferred to and processed in the United States, where the privacy laws may not be as comprehensive as those in the country where you reside or are a citizen.  By using the Services or providing Company with any information, you consent to transfer, processing, and storage of your information in the United States or such other countries as Company choose in its sole discretion.',
          )}
        </ContentComponent>
        <SubtitleComponent>{t('Changes to This Policy')}</SubtitleComponent>
        <ContentComponent>
          {t(
            'Please note this Privacy Policy is subject to change from time to time.  We will notify you of material changes to this Privacy Policy by either sending you an e-mail or by including prominent notice of such change on a Company website or other applicable Service.  The effective date of each version of the Privacy Policy will be included in this Privacy Policy. Prior versions of this Privacy Policy will be kept in an archive for you to view for a reasonable period of time.  ',
          )}
        </ContentComponent>

        <SubtitleComponent>{t('Contact Information')}</SubtitleComponent>
        <ContentComponent>
          {t(
            'If you want to contact Company about anything related to this Privacy Policy, please send an e-mail to ',
          )}
          <a href="https://privacy@tappshare.io">{t('privacy@tappshare.io')}</a>
        </ContentComponent>
        <ContentComponent>
          {t('Effective Date: January 1, 2022')}
        </ContentComponent>
      </Container>
    </ThemeProvider>
  );
}

export default PrivacyAndPolicy;

import React from 'react';
import PropTypes from 'prop-types';
import Button, { ButtonProps } from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

export default function AlertDialog({
  folder,
  handleClickTerminate,
  openAlertDialog,
  handleCloseAlertDialog,
}) {
  return (
    <Dialog
      open={openAlertDialog}
      onClose={handleCloseAlertDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ fontWeight: 600 }} id="alert-dialog-title">
        {`Are you sure you want to delete ${folder.name}?`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          It might contain cards already created contained in them
        </DialogContentText>
        <DialogActions>
          <Button onClick={handleCloseAlertDialog}>Disagree</Button>
          <Button
            sx={{ color: 'red' }}
            onClick={() => {
              handleClickTerminate(folder.id);
              handleCloseAlertDialog();
            }}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

AlertDialog.propTypes = {
  folder: PropTypes.object.isRequired,
  handleClickTerminate: PropTypes.func.isRequired,
  openAlertDialog: PropTypes.bool.isRequired,
  handleCloseAlertDialog: PropTypes.func,
};

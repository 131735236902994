import React, { useState } from 'react';

import { Link, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import routes from 'routes';
import { userActions } from 'store/domain/user';

const Form = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [forgotEmail, setForgotEmail] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  /* const handleForgotPassword = () => {
    setForgotPasswordState(!forgotPasswordState);
  }; */

  const handleForgotEmail = (e) => {
    setForgotEmail(e.target.value);
  };

  const passwordEmailReset = () => {
    enqueueSnackbar(`${t('passwordResetTo')} ${forgotEmail}`, {
      variant: 'success',
    });
    navigate(routes.auth.signin());
  };

  const handleForgotSubmit = () => {
    dispatch(
      userActions.resetPassword({
        email: forgotEmail,
        onSuccess: passwordEmailReset,
      }),
    );
    dispatch(userActions.clearStatus());
  };

  return (
    <Box>
      <Typography variant="h5" align="center" sx={{ mb: 4 }}>
        {t('forgotPasswordTitle')}
      </Typography>
      <form onSubmit={handleForgotSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="forgot"
              label={t('emailAddress') as any}
              name="forgot"
              autoComplete="email"
              autoFocus
              onChange={handleForgotEmail}
            />
            <Box width={1} display="flex" pt={1}>
              <Box flexGrow={1} />
              <Link
                variant="body2"
                component={RouterLink}
                to={routes.auth.signin()}
              >
                {t('login')}
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Button
              disableElevation={true}
              color="primary"
              variant="contained"
              type="submit"
              sx={{
                width: 300,
                marginTop: 2,
                borderRadius: 8,
                height: 50,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {t('resetPassword')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;

import React, { useEffect, useState } from 'react';

import colors from 'constants/colors';

import CheckIcon from '@mui/icons-material/Check';
import {
  Avatar,
  CardActionArea,
  Collapse,
  LinearProgress,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { format as formatDate } from 'date-fns';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { campaignActions } from 'store/entities/Campaigns';

import MatTable from 'components/MatTable';

const campaignTableColumns = (onSelect) => [
  {
    id: 'image',
    key: 'image',
    name: 'Image',
    render: (row) => (
      <CardActionArea
        style={{ width: 'fit-content', borderRadius: 4, margin: 10 }}
        onClick={(e) => {
          e.stopPropagation();
          onSelect(row);
        }}
      >
        <Avatar
          src={row.selected ? null : row.image}
          variant="rounded"
          style={{
            background: colors.primary,
            height: 32,
            width: 32,
          }}
        >
          <CheckIcon />
        </Avatar>
      </CardActionArea>
    ),
    hideHeader: true,
    align: 'left',
    width: 52,
  },
  {
    id: 'title',
    key: 'title',
    name: 'Title',
    align: 'left',
  },
  {
    id: 'start_date',
    key: 'start_date',
    name: 'Start Date',
    align: 'left',
    render: (row) => formatDate(new Date(row.start_date), 'MM-dd-yyyy'),
  },
  {
    id: 'end_date',
    key: 'end_date',
    name: 'End Date',
    align: 'left',
    render: (row) => formatDate(new Date(row.end_date), 'MM-dd-yyyy'),
  },
];

function campaignsWithSelection(campaigns, selection) {
  return campaigns.map((campaign) => ({
    ...campaign,
    selected: selection.includes(campaign.id),
  }));
}

const EmptyState = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: 240,
      width: '100%',
      background: '#0001',
      borderRadius: 20,
    }}
  >
    <CircularProgress />
  </div>
);

function CampaignSelector({ selection, onSelect }) {
  const dispatch = useDispatch();
  const [campaigns, setCampaigns] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(-1);
  const [loading, setLoading] = useState(false);
  const { allCampaigns } = useSelector((state: RootState) => state.campaigns);

  useEffect(() => {
    setLoading(true);
    dispatch(
      campaignActions.fetchAllCampaigns({
        page,
        limit: rowsPerPage,
      }),
    );
  }, [page, rowsPerPage]);

  useEffect(() => {
    setLoading(false);
    setTotal(allCampaigns?.total);
    setCampaigns(allCampaigns?.data);
  }, [allCampaigns]);

  const handleSelect = (campaign) => {
    if (onSelect) {
      onSelect(campaign.id);
    }
  };

  const handleChangePage = (_, val) => {
    setPage(val);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return !campaigns || !campaigns.length ? (
    <EmptyState />
  ) : (
    <div>
      <Collapse in={loading}>
        <LinearProgress />
      </Collapse>
      <MatTable
        rows={campaignsWithSelection(campaigns, selection)}
        columns={campaignTableColumns(handleSelect)}
        onRowClick={handleSelect}
        TablePaginationProps={{
          onPageChange: handleChangePage,
          onRowsPerPageChange: handleChangeRowsPerPage,
          page,
          rowsPerPage,
          count: total,
          rowsPerPageOptions: [5, 10, 15],
        }}
      />
    </div>
  );
}

CampaignSelector.propTypes = {
  selection: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default CampaignSelector;

import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';

import { FullImageSelector } from 'components/ImageSelectors';

import ActionDisplay from './ActionDisplay';
import ActionDrawer from './ActionDrawer';

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
  ],
};

const formats = [
  'bold',
  'italic',
  'underline',
  'strike',
  'list',
  'bullet',
  'indent',
  'link',
];

const InfoBuilder = ({ item, onUpdate, cardId, onDelete, tabIndex }) => {
  const [description, setDescription] = useState(item.description);
  const [showActionDrawer, setShowActionDrawer] = useState(false);
  const [editActionTwo, setEditActionTwo] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [initialImage, setInitialImage] = useState(null);

  useEffect(() => {
    // setTitle(item.title);
    setDescription(item.description);
    setInitialImage(item.image);
  }, [item]);

  const handleImageSelect = (photo) => {
    const object = { ...item };
    object.image = photo;
    onUpdate(object);
  };

  const handleDescriptionChange = (value, delta, source, editor) => {
    setDescription(editor.getContents());
    const object: any = { ...item };
    object.description = editor.getContents();
    onUpdate(object);
  };

  const handleActionButtonOne = () => {
    setSelectedAction(item.ctaOne || null);
    setEditActionTwo(false);
    setShowActionDrawer(true);
  };

  const handleActionButtonTwo = () => {
    setSelectedAction(item.ctaTwo || null);
    setEditActionTwo(true);
    setShowActionDrawer(true);
  };

  const handleActionSubmit = (data) => {
    const object = { ...item };
    if (editActionTwo) {
      object.ctaTwo = data;
    } else {
      object.ctaOne = data;
    }
    onUpdate(object);
    setShowActionDrawer(false);
  };

  const deleteActionOne = () => {
    const object = { ...item };
    if (object.ctaTwo.type !== 'none') {
      object.ctaOne = object.ctaTwo;
      object.ctaTwo = {
        type: 'none',
        name: '',
        link: '',
      };
    } else {
      object.ctaOne = {
        type: 'none',
        name: '',
        link: '',
      };
    }
    onUpdate(object);
  };

  const deleteActionTwo = () => {
    const object = { ...item };
    object.ctaTwo = {
      type: 'none',
      name: '',
      link: '',
    };
    onUpdate(object);
  };

  const renderItems = () => {
    switch (tabIndex) {
      case 1:
        return null;
      case 3:
        return (
          <FullImageSelector
            onSelect={handleImageSelect}
            defaultImage={initialImage}
          />
        );
        break;
      case 5:
        return (
          <>
            <Box
              sx={{
                width: '100%',
                borderBottomStyle: 'solid',
                borderBottomWidth: 1,
                borderBottomColor: Colors.primary,
                marginBottom: 2,
              }}
            >
              <Typography variant="h6">Actions</Typography>
            </Box>
            <Grid container spacing={2} sx={{ paddingLeft: 2 }}>
              <Grid item lg={12} xl={6}>
                {item.ctaOne.type === 'none' ? (
                  <Button
                    startIcon={<AddCircleOutlineIcon />}
                    variant="text"
                    color="primary"
                    onClick={handleActionButtonOne}
                  >
                    Add Action
                  </Button>
                ) : (
                  <ActionDisplay
                    type={item.ctaOne.type}
                    onClick={handleActionButtonOne}
                    onDelete={deleteActionOne}
                  />
                )}
              </Grid>
              {item.ctaOne.type !== 'none' && item.ctaOne.type !== null && (
                <Grid item lg={12} xl={6}>
                  {item.ctaTwo.type === 'none' ? (
                    <Button
                      startIcon={<AddCircleOutlineIcon />}
                      variant="text"
                      color="primary"
                      onClick={handleActionButtonTwo}
                    >
                      Add Action
                    </Button>
                  ) : (
                    <ActionDisplay
                      type={item.ctaTwo.type}
                      onClick={handleActionButtonTwo}
                      onDelete={deleteActionTwo}
                    />
                  )}
                </Grid>
              )}
            </Grid>
            <ActionDrawer
              defaultCta={selectedAction}
              open={showActionDrawer}
              onClose={() => setShowActionDrawer(false)}
              onSubmit={handleActionSubmit}
            />
          </>
        );
        break;
      case 7:
        return null;
        break;
      default:
        return null;
        break;
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        textAlign: 'left',
        backgroundColor: Colors.white,
        borderRadius: 2,
        paddingRight: 2,
        paddingTop: 1,
        paddingBottom: 2,
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          marginBottom: 0.5,
        }}
      >
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          {`Edit Card ${cardId}`}
        </Typography>
        {cardId !== 1 && (
          <IconButton
            aria-label="delete"
            onClick={onDelete}
            size="large"
            sx={{ color: Colors.error }}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
      {renderItems()}
    </Box>
  );
};

InfoBuilder.propTypes = {
  item: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  cardId: PropTypes.string.isRequired,
};

export default InfoBuilder;

import React from 'react';

import Colors from 'constants/colors';

import CropIcon from '@mui/icons-material/Crop';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import PropTypes from 'prop-types';

const UploadedImage = ({ image, onCrop, onDelete }) => (
  <Box width="100%" height={100} p={1} position="relative">
    <Box
      width="100%"
      height={75}
      borderRadius={2.5}
      bgcolor={Colors.lightBlueGray}
    >
      <img
        alt="custom upload"
        style={{
          borderRadius: 2.5,
          height: 75,
          width: '100%',
          objectFit: 'contain',
        }}
        src={image}
      />
    </Box>
    <Tooltip title="Delete" placement="right-start">
      <IconButton
        edge="end"
        sx={{
          bottom: 0,
          right: 0,
          backgroundColor: Colors.error,
          position: 'absolute',
          '&:hover': {
            backgroundColor: Colors.error,
          },
        }}
        size="small"
        onClick={onDelete}
      >
        <DeleteOutlineOutlinedIcon sx={{ color: Colors.white }} />
      </IconButton>
    </Tooltip>
    <Tooltip title="Crop" placement="right-start">
      <IconButton
        sx={{
          top: 0,
          right: 0,
          backgroundColor: Colors.primary,
          position: 'absolute',
          '&:hover': {
            backgroundColor: Colors.primary,
          },
        }}
        size="small"
        onClick={onCrop}
      >
        <CropIcon sx={{ color: Colors.white }} />
      </IconButton>
    </Tooltip>
  </Box>
);

UploadedImage.propTypes = {
  image: PropTypes.string.isRequired,
  onCrop: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default UploadedImage;

import React from 'react';

import Colors from 'constants/colors';

import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  boxProps?: BoxProps;
}

export default function TabPanel(props: TabPanelProps) {
  const { children, value, index, boxProps, ...other } = props;

  return (
    <Box
      className="folderCard"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      overflow="auto"
      position="absolute"
      width="100%"
      bgcolor={Colors.white}
      borderRadius={2}
      boxShadow="0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)"
      height="calc(100vh - 100px)"
      {...boxProps}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

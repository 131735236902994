import React from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

import PropTypes from 'prop-types';

const VideoCard = ({ data }) => {
  const renderVideoType = () => {
    switch (data.type) {
      case 'youtube':
        return (
          <Box textAlign="center">
            <iframe
              title="youtube-iframe"
              height={250}
              width="100%"
              src={`https://youtube.com/embed/${data.code}`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            />
          </Box>
        );
      case 'vimeo':
        return (
          <div>
            <iframe
              title="vimeo=iframe"
              src={`https://player.vimeo.com/video/${data.code}?color=a802f5`}
              height={250}
              width="100%"
              frameBorder="0"
              allow="autoplay; fullscreen"
            />
          </div>
        );
      default:
        return <div />;
    }
  };

  return (
    <Box width="100%" height="100%" pl={2} pr={2}>
      <Card
        sx={{
          width: '100%',
          textAlign: 'left',
          cursor: 'pointer',
          borderRadius: data?.styling?.cardBorder,
          position: 'relative',
        }}
      >
        {renderVideoType()}
        {data.title !== null && data.title !== '' ? (
          <Box
            color={data?.styling?.mainColor}
            textAlign={data?.styling?.mainAlignment}
            fontSize={data?.styling?.mainSize}
            fontStyle={data?.styling?.mainFormat?.includes('italic') ? 'italic' : ''}
            fontWeight={data?.styling?.mainFormat?.includes('bold') ? 'bold' : ''}
            sx={{
              padding: 1,
              textDecoration: data?.styling?.mainFormat?.includes('underline')
                ? 'underline'
                : '',
            }}
          >
            {data.title}
          </Box>
        ) : null}
      </Card>
    </Box>
  );
};

VideoCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default VideoCard;

import React from 'react';
import Box from '@mui/material/Box';

function SVG3() {
  return (
    <div
      id="SVG3"
      style={{
        height: '70px',
        width: '70px',
        display: 'flex',
      }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          height: '45px',
          width: '45px',
          stroke: '#ffffff',
          strokeWidth: 2.0,
          margin: 'auto',
        }}
      >
        <path
          d="M9.86 18.9999L11.54 17.5799C11.79 17.3699 12.21 17.3699 12.47 17.5799L14.14 18.9999C14.53 19.1999 15.01 18.9999 15.15 18.5799L15.47 17.6199C15.55 17.3899 15.47 17.0499 15.3 16.8799L13.66 15.2299C13.5337 15.089 13.4596 14.909 13.45 14.7199V12.8699C13.45 12.4499 13.76 12.2499 14.15 12.4099L17.5 13.8499C18.05 14.0899 18.51 13.7899 18.51 13.1899V12.2599C18.51 11.7799 18.15 11.2199 17.7 11.0299L13.76 9.32994C13.673 9.28763 13.5992 9.22248 13.5463 9.14146C13.4935 9.06045 13.4637 8.9666 13.46 8.86994V6.79994C13.46 6.11994 12.96 5.31994 12.36 5.00994C12.2531 4.95574 12.1349 4.92749 12.015 4.92749C11.8951 4.92749 11.7769 4.95574 11.67 5.00994C11.06 5.30994 10.57 6.11994 10.57 6.79994V8.86994C10.57 9.04994 10.43 9.25994 10.27 9.32994L6.33 11.0299C5.89 11.2199 5.52 11.7799 5.52 12.2599V13.1899C5.52 13.7899 5.97 14.0899 6.53 13.8499L9.88 12.4099C10.26 12.2399 10.58 12.4499 10.58 12.8699V14.7199C10.58 14.8899 10.48 15.1199 10.37 15.2299L8.7 16.8699C8.53 17.0399 8.45 17.3799 8.53 17.6099L8.85 18.5699C8.99 18.9999 9.46 19.1999 9.86 18.9999Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export default SVG3;

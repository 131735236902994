import React from 'react';

import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Missing = () => (
  <Box
    width="100%"
    height={175}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
  >
    <PeopleOutlineIcon />
    <Typography variant="body1">No Team Members Found</Typography>
  </Box>
);

export default Missing;

import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

import MatInput from 'components/Elements/MatInput/MatInput';

const PollBuilder = ({
  item,
  cardId,
  onDelete,
  onUpdate,
  editingMode,
  tabIndex,
}) => {
  const [question, setQuestion] = useState(item.question);
  const [answers, setAnswers] = useState(item.answers);
  const [extraAnswers, setExtraAnswers] = useState([]);

  useEffect(() => {
    if (item.question !== question) {
      setQuestion(item.question);
    }
    if (item.answers) {
      if (item.answers.length < 3) {
        setExtraAnswers([]);
      } else {
        const data: any = [];
        for (let x = 0; x < item.answers.length - 2; x++) {
          data.push({ number: item.answers.length });
        }
        setExtraAnswers(data);
      }
      setAnswers(item.answers);
    }
  }, [item, question]);

  const handleQuestion = (e) => {
    setQuestion(e.target.value);
    const object = item;
    object.question = e.target.value;
    onUpdate(object);
  };

  const handleAnswer = (e, index) => {
    const update = [...answers];
    update[index] = e.target.value;
    setAnswers(update);
    const object = item;
    object.answers = update;
    onUpdate(object);
  };

  const handleNewAnswer = () => {
    if (answers.length >= 2) {
      let valid = true;
      for (let x = 0; x < answers.length; x++) {
        if (answers[x] === null || answers[x] === '') {
          valid = false;
        }
      }
      if (answers.length !== extraAnswers.length + 2) {
        valid = false;
      }
      if (valid) {
        const newAnswers: any = [...extraAnswers];
        newAnswers.push({
          number: answers.length,
        });
        setExtraAnswers(newAnswers);
      }
    }
  };

  const deleteRow = (index) => {
    const tempAnswers = [...answers];
    if (tempAnswers.length >= index) {
      tempAnswers.splice(index, 1);
      setAnswers(tempAnswers);
      const object = item;
      object.answers = tempAnswers;
      onUpdate(object);
    }

    // Remove an Extra Answer
    const tempArray = [...extraAnswers];
    tempArray.pop();
    setExtraAnswers(tempArray);
  };

  const renderItems = () => {
    switch (tabIndex) {
      case 1:
        return (
          <>
            <MatInput
              value={question}
              variant="outlined"
              placeholder="Survey Question"
              onChange={handleQuestion}
              size="small"
            />
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                marginTop: 2,
              }}
            >
              <MatInput
                value={
                  answers[0] !== null && typeof answers[0] !== 'undefined'
                    ? answers[0]
                    : ''
                }
                variant="outlined"
                placeholder="Answer"
                onChange={(e) => handleAnswer(e, 0)}
                size="small"
              />
              {extraAnswers.length > 0 && (
                <div style={{ paddingLeft: 12, width: 36, height: 24 }}>
                  <IconButton
                    disabled={editingMode}
                    aria-label="delete"
                    onClick={() => deleteRow(0)}
                    size="large"
                  >
                    <DeleteIcon style={{ width: 16, height: 16 }} />
                  </IconButton>
                </div>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                marginTop: 2,
              }}
            >
              <MatInput
                value={
                  answers[1] !== null && typeof answers[1] !== 'undefined'
                    ? answers[1]
                    : ''
                }
                variant="outlined"
                placeholder="Answer"
                onChange={(e) => handleAnswer(e, 1)}
                size="small"
              />
              {extraAnswers.length > 0 && (
                <div style={{ paddingLeft: 12, width: 36, height: 24 }}>
                  <IconButton
                    disabled={editingMode}
                    aria-label="delete"
                    onClick={() => deleteRow(1)}
                    size="large"
                  >
                    <DeleteIcon style={{ width: 16, height: 16 }} />
                  </IconButton>
                </div>
              )}
            </Box>
            {extraAnswers.length > 0
              ? extraAnswers.map((i, index) => (
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      marginTop: 2,
                    }}
                  >
                    <MatInput
                      value={
                        answers[index + 2] !== null &&
                        typeof answers[index + 2] !== 'undefined'
                          ? answers[index + 2]
                          : ''
                      }
                      variant="outlined"
                      placeholder="Answer"
                      onChange={(e) => handleAnswer(e, index + 2)}
                      size="small"
                    />
                    <div style={{ paddingLeft: 12, width: 36, height: 24 }}>
                      <IconButton
                        aria-label="delete"
                        onClick={() => deleteRow(index + 2)}
                        disabled={editingMode}
                        size="large"
                      >
                        <DeleteIcon style={{ width: 16, height: 16 }} />
                      </IconButton>
                    </div>
                  </Box>
                ))
              : null}
            {extraAnswers.length < 3 && !editingMode ? (
              <IconButton onClick={handleNewAnswer} size="large">
                <AddCircleOutlineIcon
                  sx={{
                    width: 24,
                    height: 24,
                    color: Colors.primary,
                  }}
                />
              </IconButton>
            ) : null}
          </>
        );
        break;
      case 3:
        return null;
        break;
      case 5:
        return null;
        break;
      case 7:
        return null;
        break;
      default:
        return (
          <>
            <MatInput
              value={question}
              variant="outlined"
              placeholder="Survey Question"
              onChange={handleQuestion}
              size="small"
            />
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                marginTop: 2,
              }}
            >
              <MatInput
                value={
                  answers[0] !== null && typeof answers[0] !== 'undefined'
                    ? answers[0]
                    : ''
                }
                variant="outlined"
                placeholder="Answer"
                onChange={(e) => handleAnswer(e, 0)}
                size="small"
              />
              {extraAnswers.length > 0 && (
                <div style={{ paddingLeft: 12, width: 36, height: 24 }}>
                  <IconButton
                    disabled={editingMode}
                    aria-label="delete"
                    onClick={() => deleteRow(0)}
                    size="large"
                  >
                    <DeleteIcon style={{ width: 16, height: 16 }} />
                  </IconButton>
                </div>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                marginTop: 2,
              }}
            >
              <MatInput
                value={
                  answers[1] !== null && typeof answers[1] !== 'undefined'
                    ? answers[1]
                    : ''
                }
                variant="outlined"
                placeholder="Answer"
                onChange={(e) => handleAnswer(e, 1)}
                size="small"
              />
              {extraAnswers.length > 0 && (
                <div style={{ paddingLeft: 12, width: 36, height: 24 }}>
                  <IconButton
                    disabled={editingMode}
                    aria-label="delete"
                    onClick={() => deleteRow(1)}
                    size="large"
                  >
                    <DeleteIcon style={{ width: 16, height: 16 }} />
                  </IconButton>
                </div>
              )}
            </Box>
            {extraAnswers.length > 0
              ? extraAnswers.map((i, index) => (
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      marginTop: 2,
                    }}
                  >
                    <MatInput
                      value={
                        answers[index + 2] !== null &&
                        typeof answers[index + 2] !== 'undefined'
                          ? answers[index + 2]
                          : ''
                      }
                      variant="outlined"
                      placeholder="Answer"
                      onChange={(e) => handleAnswer(e, index + 2)}
                      size="small"
                    />
                    <div style={{ paddingLeft: 12, width: 36, height: 24 }}>
                      <IconButton
                        aria-label="delete"
                        onClick={() => deleteRow(index + 2)}
                        disabled={editingMode}
                        size="large"
                      >
                        <DeleteIcon style={{ width: 16, height: 16 }} />
                      </IconButton>
                    </div>
                  </Box>
                ))
              : null}
            {extraAnswers.length < 3 && !editingMode ? (
              <IconButton onClick={handleNewAnswer} size="large">
                <AddCircleOutlineIcon
                  sx={{
                    width: 24,
                    height: 24,
                    color: Colors.primary,
                  }}
                />
              </IconButton>
            ) : null}
          </>
        );
        break;
    }
  };

  return (
    <Paper
      sx={{
        width: '100%',
        textAlign: 'left',
        backgroundColor: Colors.white,
        borderRadius: 2,
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: 1,
        paddingBottom: 2,
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          marginBottom: 2,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            flexGrow: 1,
          }}
        >
          {`Edit Card ${cardId}`}
        </Typography>
        <IconButton
          aria-label="delete"
          onClick={onDelete}
          size="large"
          sx={{
            color: Colors.error,
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
      {renderItems()}
    </Paper>
  );
};

PollBuilder.propTypes = {
  tabIndex: PropTypes.number,
  item: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  cardId: PropTypes.string.isRequired,
  editingMode: PropTypes.bool.isRequired,
};

export default PollBuilder;

import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';

import { Card, Box } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

const PollCard = ({ item, active, onClick, cardId }) => {
  const [pieData, setPieData] = useState([]);

  useEffect(() => {
    if (item.answers.length > 1) {
      if (pieData.length !== item.answers.length) {
        const pData: any = [];
        for (let x = 0; x < item.answers.length; x++) {
          const min = Math.ceil(10);
          const max = Math.floor(100);
          const itemVal = Math.floor(Math.random() * (max - min + 1)) + min;
          pData.push({
            name: item.answers[x],
            value: itemVal,
          });
        }
        setPieData(pData);
      }
    }
  }, [item.answers]);

  return (
    <Box
      sx={{
        marginLeft: 1,
        marginRight: 1,
        marginBottom: 2,
      }}
    >
      <Card
        sx={
          active
            ? {
                textAlign: 'left',
                borderColor: Colors.primaryLight,
                borderStyle: 'solid',
                borderWidth: 3,
                position: 'relative',
              }
            : {
                width: '100%',
                textAlign: 'left',
                cursor: 'pointer',
                position: 'relative',
              }
        }
        onClick={onClick}
      >
        <CardContent
          sx={{
            paddingTop: 1,
            paddingBottom: '8px !important',
            paddingLeft: 2,
            paddingRight: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 6,
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                flexGrow: 1,
                paddingRight: 1,
              }}
            >
              {item.question === null || item.question === ''
                ? 'Question'
                : item.question}
            </Typography>
          </Box>
          {item.answers.map((answer) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Radio
                sx={{
                  padding: 0,
                  marginRight: 1,
                }}
                disabled
                size="small"
              />
              <Typography variant="caption">{answer}</Typography>
            </Box>
          ))}
        </CardContent>
        <Box
          sx={{
            top: 0,
            right: 0,
            height: 20,
            marginTop: 1.25,
            marginRight: 0.75,
            paddingLeft: 1.25,
            paddingRight: 1.25,
            position: 'absolute',
            backgroundColor: 'rgba(97,97,97 ,0.7)',
            borderRadius: 6.25,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              color: Colors.white,
              fontSize: 10,
            }}
          >
            {cardId}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
};

PollCard.propTypes = {
  active: PropTypes.bool.isRequired,
  cardId: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default PollCard;

/* eslint-disable consistent-return */

import React, { useRef, useEffect, useState } from 'react';

import Colors from 'constants/colors';

import { Button, Box } from '@mui/material';
import TextField from '@mui/material/TextField';

import PropTypes from 'prop-types';
import { HiCursorClick } from 'react-icons/hi';
import { TbEdit } from 'react-icons/tb';

export function SearchBar({
  value,
  defaultValue,
  placeholder,
  editInput,
  onFocus,
  onChange,
  size,
}) {
  return (
    <TextField
      type="text"
      value={value}
      defaultValue={defaultValue}
      disabled={!editInput}
      onFocus={onFocus}
      onChange={onChange}
      autoComplete="on"
      placeholder={
        placeholder !== null && typeof placeholder !== 'undefined'
          ? placeholder
          : 'Button Text'
      }
      sx={{
        flexGrow: 1,
        cursor: 'pointer',
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          borderColor: Colors.primary,
          border: 'none',
        },
        '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
          color: Colors.black,
          fontSize: 14,
        },
        '& .MuiInputLabel-outlined': {
          color: Colors.black,
        },
        '& .MuiInputBase-input::placeholder': {
          color: Colors.primaryBlack,
          opacity: 1,
        },
      }}
      variant="outlined"
      size={size || 'medium'}
      fullWidth
    />
  );
}

// Hook that alerts clicks outside of the passed ref

export function useOutsideAlerter(state) {
  const wrapperRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Alert if clicked on outside of element
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        state(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef]);

  return { wrapperRef };
}

export function AutoCompleteInput({
  isTeamHub,
  placeholder,
  autoComplete,
  onChange,
  handlerValue,
}) {
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const [editInput, setEditInput] = useState(false);
  const [inputChange, setInputChange] = useState('');

  const onInputChange = (newInputValue) => {
    setInputChange(newInputValue);
    onChange(newInputValue);
  };

  const [search, setSearch] = useState('');

  // eslint-disable-next-line array-callback-return
  const filteredSearch = autoComplete.filter((val) => {
    if (search === '') {
      return val;
    } else if (val.toLowerCase().includes(search.toLowerCase())) {
      return val;
    }
  });

  const { wrapperRef } = useOutsideAlerter(setIsComponentVisible);

  return (
    <Box
      ref={wrapperRef}
      sx={{ position: 'relative', width: 'inherit', height: 'inherit' }}
    >
      <Box
        sx={{
          backgroundColor: '#ffffff',
          cursor: 'not-allowed',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          px: '8px',
          borderStyle: !isTeamHub ? 'solid' : null,
          borderColor: !isTeamHub ? Colors.primary : null,
          borderWidth: !isTeamHub ? '1px' : null,
          borderRadius: '6px',
        }}
      >
        <HiCursorClick size={25} />
        <SearchBar
          editInput={editInput}
          size="small"
          value={handlerValue || inputChange}
          defaultValue={handlerValue}
          placeholder={placeholder}
          onFocus={() => setIsComponentVisible(true)}
          onChange={({ target }) => {
            setIsComponentVisible(true);
            setSearch(target.value);
            onInputChange(target.value);
          }}
        />
        <TbEdit
          style={{ cursor: 'pointer' }}
          onClick={() => setEditInput(!editInput)}
          size={25}
        />
      </Box>

      {isComponentVisible && (
        <Box
          sx={{
            mt: '2px',
            p: '8px',
            borderWidth: '1px',
            borderRadius: '6px',
            borderStyle: 'solid',
            borderColor: Colors.primary,
            backgroundColor: 'rgb(248, 248, 248)',
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            minWidth: '100%',
            gap: '8px',
            height: '200px',
            overflow: 'auto',
            zIndex: 10,
          }}
        >
          {filteredSearch.map((doc, i) => (
            <Box
              sx={{
                cursor: 'pointer',
                borderWidth: '1px',
                p: '4px',
                borderRadius: '4px',
                backgroundColor: 'rgba(255, 255, 255, 0.808)',
                position: 'relative',
              }}
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              onClick={() => {
                onInputChange(doc);
                setIsComponentVisible(false);
              }}
            >
              {doc}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}

AutoCompleteInput.propTypes = {
  isTeamHub: PropTypes.bool,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  handlerValue: PropTypes.string,
};

import SVG1_img from './aavesvgPacks.svg';
import SVG2_img from './activitysvgPacks.svg';
import SVG3_img from './airplanesquaresvgPacks.svg';
import SVG4_img from './airplanesvgPacks.svg';
import SVG5_img from './airpodssvgPacks.svg';
import SVG6_img from './airpodsvgPacks.svg';
import SVG7_img from './alarmsvgPacks.svg';
import SVG8_img from './alignbottomsvgPacks.svg';
import SVG9_img from './ankrsvgPacks.svg';
import SVG10_img from './attachsquaresvgPacks.svg';
import SVG11_img from './awardsvgPacks.svg';
import SVG1 from './SVG1';
import SVG2 from './SVG2';
import SVG3 from './SVG3';
import SVG4 from './SVG4';
import SVG5 from './SVG5';
import SVG6 from './SVG6';
import SVG7 from './SVG7';
import SVG8 from './SVG8';
import SVG9 from './SVG9';
import SVG10 from './SVG10';
import SVG11 from './SVG11';

export const SVG_images = [
  { name: 'SVG1', icon: SVG1, image: SVG1_img },
  { name: 'SVG2', icon: SVG2, image: SVG2_img },
  { name: 'SVG3', icon: SVG3, image: SVG3_img },
  { name: 'SVG4', icon: SVG4, image: SVG4_img },
  { name: 'SVG5', icon: SVG5, image: SVG5_img },
  { name: 'SVG6', icon: SVG6, image: SVG6_img },
  { name: 'SVG7', icon: SVG7, image: SVG7_img },
  { name: 'SVG8', icon: SVG8, image: SVG8_img },
  { name: 'SVG9', icon: SVG9, image: SVG9_img },
  { name: 'SVG10', icon: SVG10, image: SVG10_img },
  { name: 'SVG11', icon: SVG11, image: SVG11_img },
];

import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';

import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TablePagination from '@mui/material/TablePagination';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { teamsSelectors } from 'store/entities/Teams';

import MatInput from '../Elements/MatInput/MatInput';

import Body from './components/Body';
import FilterButton from './components/FilterButton';
import FilterDrawer from './components/FilterDrawer';
import HeaderRow from './components/HeaderRow';
import Missing from './components/Missing';
import SelectedCount from './components/SelectedCount';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableContainer,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import FilterDropDown from './components/FilterDropDown';

const DROPDOWN = 'dropdown';
const DRAWER = 'drawer';

const TeamsSelectTable = ({ handleTeamSelect, selected, filterType }) => {
  const [search, setSearch] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [rowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [filteredLocationId, setFilteredLocationId] = useState('all');
  const [teams, setTeams] = useState<any[]>([]);
  const [loadingActiveteams, setLoadingActiveteams] = useState(false);
  const [searchTeams, setsearchTeams] = useState<any[]>([]);
  const [headerChecked, setHeaderChecked] = useState(false);
  const activeLocations = useSelector(teamsSelectors.getActiveTeams);

  useEffect(() => {
    if (search !== '') {
      const searchResults: any[] = [];
      teams.forEach((team: any) => {
        if (team.name.toLowerCase().includes(search.toLowerCase())) {
          searchResults.push(team);
        }
      });
      searchResults.sort((a: any, b: any) => a.name.localeCompare(b.name));
      setPage(0);
      setsearchTeams(searchResults);
    }
  }, [search]);

  useEffect(() => {
    setLoadingActiveteams(false);
    setTeams(activeLocations);
  }, [activeLocations]);

  useEffect(() => {
    if (activeLocations.length > 0) {
      let selectedTeams: any[] = teams;
      if (searchTeams.length > 0) {
        selectedTeams = searchTeams;
      }
      if (selectedTeams.length > 0) {
        let allChecked = true;
        selectedTeams.forEach((team: any) => {
          if (!selected.includes(team.id)) {
            allChecked = false;
          }
        });
        setHeaderChecked(allChecked);
      }
    }
  }, [selected]);

  const handleChangePage = (e, val) => {
    setPage(val);
  };

  const handleSelect = (id) => {
    const values: any[] = [];
    let inArray = false;
    selected.forEach((originalValue) => {
      if (originalValue === id) {
        inArray = true;
      } else {
        values.push(originalValue);
      }
    });
    if (!inArray) {
      values.push(id);
    }
    handleTeamSelect(values);
  };

  const handleSelectAll = () => {
    const newSelected = [...selected];
    let teamsArray: any[] = teams;
    if (searchTeams.length > 0) {
      teamsArray = searchTeams;
    }
    if (headerChecked) {
      teamsArray.forEach((team: any) => {
        if (newSelected.includes(team.id)) {
          const index = newSelected.indexOf(team.id);
          if (index !== -1) {
            newSelected.splice(index, 1);
          }
        }
      });
    } else {
      teamsArray.forEach((team: any) => {
        if (!newSelected.includes(team.id)) {
          newSelected.push(team.id);
        }
      });
    }
    handleTeamSelect(newSelected);
  };

  return (
    <Box
      component="div"
      p={1}
      width="100%"
      border={0.5}
      borderColor={Colors.lightBlueGray}
      borderRadius={1}
    >
      <Box display="flex" alignItems="center">
        <Box flexGrow={2}>
          {/* <MatInput
            variant="outlined"
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search"
            value={search}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          /> */}
          <Box
            sx={{
              borderRadius: '13px',
              backgroundColor: '#EEF0F2',
              paddingX: '15px',
              paddingY: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Search
              sx={{
                fontSize: '22px',
                marginRight: '10px',
                '& .MuiBox-root': {
                  width: '100%',
                },
              }}
            />
            <input
              style={{
                outline: 'none',
                border: 'none',
                backgroundColor: 'inherit',
                fontSize: '15px',
                width: '100%',
              }}
              type="text"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>
        </Box>
      </Box>
      {/* <SelectedCount count={selected.length} loading={loadingActiveteams} /> */}
      {teams.length < 1 || (search !== '' && searchTeams.length < 1) ? (
        <Missing />
      ) : (
        <TableContainer>
          <Table
            sx={{
              borderSpacing: ' 0 10px !important',
              borderCollapse: 'separate !important' as any,
              '& .MuiTableCell-root': {
                border: 'none !important',
              },
            }}
          >
            <HeaderRow checked={headerChecked} onClick={handleSelectAll} />
            <Body
              teams={search !== '' ? searchTeams : activeLocations}
              rowsPerPage={rowsPerPage}
              page={page}
              selected={selected}
              onSelect={handleSelect}
            />
          </Table>
          {/* <TablePagination
            component="div"
            rowsPerPageOptions={[5]}
            count={search !== '' ? searchTeams.length : teams.length}
            rowsPerPage={rowsPerPage}
            labelDisplayedRows={() =>
              `${page + 1}-${Math.floor(
                (search !== '' ? searchTeams.length : teams.length) /
                  rowsPerPage,
              ) + 1}`
            }
            page={page}
            onPageChange={handleChangePage}
          /> */}
        </TableContainer>
      )}
    </Box>
  );
};

TeamsSelectTable.propTypes = {
  handleTeamSelect: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string),
  filterType: PropTypes.string,
};

TeamsSelectTable.defaultProps = {
  selected: [],
  filterType: DRAWER,
};

export default TeamsSelectTable;

import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import EnterpriseOverview from './EnterpriseOverview';
import FriendOverview from './FriendOverview';
import SubscriptionOverview from './SubscriptionOverview';
import Subscriptions from 'constants/subscriptions';
import PropTypes from 'prop-types';

const BillingOverview = ({ setUpgradePlan }) => {
  const { t } = useTranslation();

  const { subscriptionType } = useSelector((state: any) => state.subscriptions);

  const renderPlanType = () => {
    switch (subscriptionType) {
      case Subscriptions.premium.type:
        return (
          <Box display="flex" m={3}>
            <SubscriptionOverview setUpgradePlan={setUpgradePlan} />
          </Box>
        );
      case Subscriptions.premiumPro.type:
        return (
          <Box display="flex" m={3}>
            <SubscriptionOverview setUpgradePlan={setUpgradePlan} />
          </Box>
        );
      case Subscriptions.custom.type:
        return (
          <Box display="flex" m={3}>
            <EnterpriseOverview />
          </Box>
        );
      default:
        return <Box />;
    }
  };

  return (
    <Box width="100%">
      <Typography variant="h5">{t('planBilling')}</Typography>
      {renderPlanType()}
    </Box>
  );
};

BillingOverview.prototype = {
  setUpgradePlan: PropTypes.func,
};

export default BillingOverview;

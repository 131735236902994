import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import MatDrawer from 'components/Elements/MatDrawer/MatDrawer';
import { useSnackbar } from 'notistack';
import { subscriptionActions } from 'store/entities/Subscriptions';

const CheckoutDrawer = ({
  open,
  onClose,
  planName,
  planId,
  price,
  planDetails,
}) => {
  const stripe: any = useStripe();
  const elements: any = useElements();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);

  const { status } = useSelector((state: any) => state.subscriptions);

  useEffect(() => {
    switch (status) {
      case 'pending':
        setSubscriptionLoading(true);
        dispatch(subscriptionActions.clearStatus());
        break;
      case 'fulfilled':
        setSubscriptionLoading(false);
        dispatch(subscriptionActions.clearStatus());
        break;
      default:
        setSubscriptionLoading(false);
        break;
    }
  }, [status]);

  const onSubscribe = async () => {
    if (stripe) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });

      if (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      } else {
        dispatch(
          subscriptionActions.createNewSubscription({
            planId,
            paymentId: paymentMethod.id,
            planDetails,
            card: paymentMethod.card,
          }),
        );
        enqueueSnackbar('Payment Successful', { variant: 'success' });
      }
    }
  };

  return (
    <MatDrawer
      open={open}
      anchor="right"
      onClose={onClose}
      onCancel={onClose}
      onSubmit={onSubscribe}
      title={t('subscribe')}
      submitBtn={t('checkout')}
      cancelBtn={t('cancel')}
      isLoading={subscriptionLoading}
      padding="16px"
    >
      <Box maxWidth={450}>
        <Box display="flex" width="100%" alignItems="center" mb={2} padding="0">
          <Typography sx={{ flexGrow: 1, fontSize: 14 }}>
            {t('plan')}
          </Typography>
          <Typography variant="h6">{planName}</Typography>
        </Box>
        <Box display="flex" width="100%" alignItems="center" mb={2}>
          <Typography sx={{ flexGrow: 1, fontSize: 14 }}>
            {t('monthlyCost')}
          </Typography>
          <Typography variant="h6">{`$${price}`}</Typography>
        </Box>
        <Typography
          sx={{ flexGrow: 1, fontSize: 14 }}
          style={{ marginBottom: 16 }}
        >
          {t('paymentInformation')}
        </Typography>
        <CardElement />
      </Box>
    </MatDrawer>
  );
};

CheckoutDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  planName: PropTypes.string.isRequired,
  planId: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  planDetails: PropTypes.object.isRequired,
};

export default CheckoutDrawer;

import Moment from 'moment';

export const calcIncrease = (prevValue, value) => {
  let percentage = 0;

  if (prevValue === 0 || value === 0) {
    if (prevValue === 0) {
      percentage = value * 100;
    }

    if (value === 0) {
      percentage = prevValue * -100;
    }
  } else {
    percentage = ((value - prevValue) / prevValue) * 100;
  }
  return percentage;
};

export const getTimeFrame = (timeFrame) => {
  const today = Moment();
  switch (timeFrame) {
    case 'week':
      return {
        startDate: today.clone().startOf('week'),
        endDate: today.clone().add(1, 'day'),
      };
    case 'month':
      return {
        startDate: today.clone().startOf('month'),
        endDate: today.clone().add(1, 'day'),
      };
    case 'quarter':
      return {
        startDate: today.clone().startOf('quarter'),
        endDate: today.clone().add(1, 'day'),
      };
    case 'year':
    case 'current_year':
      return {
        startDate: today.clone().startOf('year'),
        endDate: today.clone().add(1, 'day'),
      };
    default:
      return {
        startDate: today.clone().startOf('month'),
        endDate: today.clone().add(1, 'day'),
      };
  }
};

export const getPreviousTimeFrame = (timeFrame) => {
  const today = Moment();
  switch (timeFrame) {
    case 'week': {
      const previous = today.clone().subtract(1, 'week');
      return {
        startDate: previous.clone().startOf('week'),
        endDate: previous.clone().add(1, 'day'),
      };
    }
    case 'month': {
      const previous = today.clone().subtract(1, 'months');
      return {
        startDate: previous.clone().startOf('month'),
        endDate: previous.clone().add(1, 'day'),
      };
    }
    case 'quarter': {
      const previous = today.clone().subtract(1, 'quarter');
      return {
        startDate: previous.clone().startOf('quarter'),
        endDate: previous.clone().add(1, 'day'),
      };
    }
    case 'year':
    case 'current_year': {
      const previous = today.clone().subtract(1, 'year');
      return {
        startDate: previous.clone().startOf('year'),
        endDate: previous.clone().add(1, 'day'),
      };
    }
    default: {
      const previous = today.clone().subtract(1, 'months');
      return {
        startDate: previous.clone().startOf('month'),
        endDate: previous.clone().add(1, 'day'),
      };
    }
  }
};

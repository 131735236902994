import React, { useEffect, useState } from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import AppBar from './components/AppBar';
import EmulatorDrawer from './components/EmulatorDrawer';
import FormCard from './components/FormCard';
import ImageCard from './components/ImageCard';
import ImageCarousel from './components/ImageCarousel';
import InfoCard from './components/InfoCard';
import MailChimpCard from './components/MailChimpCard';
import Phone from './components/Phone';
import PollCard from './components/PollCard';
import PremiumButton from './components/PremiumButton';
import Social from './components/Social';
import VideoCard from './components/VideoCard';

const getListItem = (item, results) => {
  switch (item.type) {
    case 'info':
      return <InfoCard data={item.info} results={results} />;
    case 'image':
      return <ImageCard data={item.info} results={results} />;
    case 'video':
      return <VideoCard data={item.info} />;
    case 'button':
      return <PremiumButton data={item.info} results={results} />;
    case 'social':
      return <Social data={item.info} />;
    case 'form':
      return <FormCard data={item.info} />;
    case 'poll':
      return <PollCard data={item.info} results={results} />;
    case 'carousel':
      return <ImageCarousel data={item.info} results={results} />;
    case 'mailchimp':
      return <MailChimpCard data={item.info} results={results} />;
    default:
      return <div />;
  }
};

const PremiumEmulator: React.FC<any> = ({
  rowOne,
  rowTwo,
  rowThree,
  rowFour,
  rowFive,
  conditionsHtml,
  showBusinessName,
}) => {
  const [showDrawer, setShowDrawer] = useState(false);

  const { user } = useSelector((state: any) => state.user);
  const { breakdownData } = useSelector((state: any) => state.campaigns);
  const { businesses } = useSelector((state: any) => state.businesses);
  const [businessName, setBusinessName] = useState('');
  const [businessPhoto, setBusinessPhoto] = useState('');

  useEffect(() => {
    if (businesses && user) {
      const business = businesses.filter(
        (el) => el.objectId === user.businessId,
      );
      if (business.length) {
        setBusinessName(business[0].name);
        setBusinessPhoto(business[0].logo_url);
      }
    }
  }, [user, businesses]);

  return (
    <div>
      <Phone>
        <AppBar
          title={showBusinessName ? businessName : ''}
          onOpenDrawer={() => setShowDrawer(true)}
          image={businessPhoto}
        />
        <Box
          flexGrow={1}
          bgcolor={Colors.background}
          borderRadius="0 0 20px 20px"
          sx={{
            scrollbarWidth: 'none',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '0.4em',
              display: 'none',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,.1)',
              outline: '1px solid slategrey',
            },
          }}
        >
          {rowOne !== null && typeof rowOne !== 'undefined' ? (
            <Box mt={2}>
              {getListItem(rowOne, breakdownData ? breakdownData[0] : null)}
            </Box>
          ) : null}
          {rowTwo !== null && typeof rowTwo !== 'undefined' ? (
            <Box mt={2}>
              {getListItem(rowTwo, breakdownData ? breakdownData[1] : null)}
            </Box>
          ) : null}
          {rowThree !== null && typeof rowThree !== 'undefined' ? (
            <Box mt={2}>
              {getListItem(rowThree, breakdownData ? breakdownData[2] : null)}
            </Box>
          ) : null}
          {rowFour !== null && typeof rowFour !== 'undefined' ? (
            <Box mt={2}>
              {getListItem(rowFour, breakdownData ? breakdownData[3] : null)}
            </Box>
          ) : null}
          {rowFive !== null && typeof rowFive !== 'undefined' ? (
            <Box mt={2}>
              {getListItem(rowFive, breakdownData ? breakdownData[4] : null)}
            </Box>
          ) : null}
        </Box>
        <EmulatorDrawer
          open={showDrawer}
          onClose={() => setShowDrawer(false)}
          conditions={conditionsHtml}
        />
      </Phone>
    </div>
  );
};

PremiumEmulator.propTypes = {
  rowOne: PropTypes.object,
  rowTwo: PropTypes.object,
  rowThree: PropTypes.object,
  rowFour: PropTypes.object,
  rowFive: PropTypes.object,
  conditionsHtml: PropTypes.string,
  showBusinessName: PropTypes.bool,
};

PremiumEmulator.defaultProps = {
  rowOne: null,
  rowTwo: null,
  rowThree: null,
  rowFour: null,
  rowFive: null,
  conditionsHtml: null,
  showBusinessName: false,
};

export default PremiumEmulator;

import React from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Moment from 'moment';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { Download } from '@mui/icons-material';

const BillingHistory = ({ billingItems }) => {
  const totalAmount = (val) => {
    const updatedVal = parseFloat(val) * 0.01;
    return `$${updatedVal.toFixed(2)}`;
  };

  const renderBillingItem = (item) => (
    <Box mt={1}>
      <Typography variant="caption">
        {Moment.unix(item.createdOn).format('MMM D, YYYY')}
      </Typography>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6">{totalAmount(item.total)}</Typography>
        <IconButton href={item.url} aria-label="download invoice" size="large">
          <Download />
        </IconButton>
      </Box>
    </Box>
  );

  return (
    <Box
      width={350}
      pt={1}
      pb={1}
      pl={2}
      pr={2}
      borderRadius={1}
      border={0.5}
      borderColor={Colors.lightBlueGray}
      maxHeight="calc(100vh - 100px)"
      overflow="auto"
    >
      <Box display="flex" alignItems="center">
        <Typography sx={{ flexGrow: 1, fontWeight: 'bold', fontSize: 16 }}>
          Billing History
        </Typography>
      </Box>
      {billingItems?.map((item) => renderBillingItem(item))}
    </Box>
  );
};

BillingHistory.propTypes = {
  billingItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default BillingHistory;

import React, { useEffect } from 'react';

import Colors from 'constants/colors';

import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';
import { capitalizeWords } from 'utils/formatTools';

const ButtonRow = ({ active, onClick, color, item, cardId, styling, setValue }) => {
  const renderText = () => {
    if (item.version && item.version === 2) {
      if (item.cta.type === 'event') {
        return 'Add to Calendar';
      } else if (item.cta.type === 'share') {
        return 'Share';
      } else if (item.cta.name) {
        return capitalizeWords(item.cta.name);
      }
    } else if (item.cta) {
      return capitalizeWords(item.cta);
    }

    return 'Call to Action';
  };

  return (
    <Box
      sx={{
        marginLeft: 1,
        marginRight: 1,
        marginBottom: 2,
      }}
    >
      <Box
        sx={
          active
            ? {
                textAlign: 'left',
                borderColor: Colors.primaryLight,
                borderStyle: 'solid',
                borderWidth: 3,
                position: 'relative',
              }
            : {
                width: '100%',
                textAlign: 'left',
                cursor: 'pointer',
                position: 'relative',
              }
        }
        onClick={() => {
          if (setValue) {
            onClick()
          } else {
            const link = item.cta.link.includes('https://')
              ? item.cta.link
              : `https://${item.cta.link}`;
            const win = window.open(link);
            win?.focus();
          }
        }}
        onKeyDown={onClick}
        tabIndex={0}
        role="button"
      >
        <Button
          fullWidth
          color="primary"
          variant={styling?.buttonVariant}
          sx={{
            borderRadius: styling?.buttonBorder,
            borderColor:
              styling?.buttonVariant === 'outlined' ? (styling?.buttonColor || '#2085ff') : '',
            backgroundColor:
              styling?.buttonVariant === 'contained'
                ? (styling?.buttonColor || '#2085ff')
                : '',
            color: styling?.textButtonColor,
            textTransform: 'initial',
          }}
        >
          {renderText()}
        </Button>
        <Box
          sx={{
            top: 0,
            right: 0,
            height: 20,
            marginTop: 1.25,
            marginRight: 0.75,
            paddingLeft: 1.25,
            paddingRight: 1.25,
            position: 'absolute',
            backgroundColor: 'rgba(97,97,97 ,0.7)',
            borderRadius: 6,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              color: Colors.white,
              fontSize: 10,
            }}
          >
            {cardId}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

ButtonRow.propTypes = {
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  setValue: PropTypes.func,
  item: PropTypes.object.isRequired,
  styling: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  cardId: PropTypes.string.isRequired,
};

export default ButtonRow;

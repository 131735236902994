import React from 'react';

import PropTypes from 'prop-types';

import Colors from 'constants/colors';
import { defaultStyles } from 'constants/styles';

import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

// eslint-disable-next-line prefer-arrow-callback
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any> | React.ReactNode | any;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FolderModal: React.FC<any> = ({
  open,
  children,
  onClose,
  onSubmit,
  isLoading,
  padding,
}) => (
  <Dialog
    open={open}
    TransitionComponent={Transition}
    keepMounted
    onClose={() => {
      // onSubmit();
      onClose(false);
    }}
    // sx={{
    //   backdropFilter: 'blur(5px) sepia(5%)',
    // }}
    // 👇 Props passed to Paper (modal content)
    PaperProps={{ sx: { borderRadius: '18px' } }}
  >
    {isLoading ? (
      <CircularProgress
        style={{ width: 40, height: 40, color: Colors.primary }}
      />
    ) : (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height={1}
      >
        <Box p={padding ?? 3} pt={1} flex={1}>
          {children}
        </Box>
      </Box>
    )}
  </Dialog>
);

export default FolderModal;

FolderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

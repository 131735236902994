import Parse, { JSONBaseAttributes, Pointer, Relation, Object } from 'parse';
import { createPointer } from 'store/utils/pointers';

import Business from './Business';
import User from './User';

export interface BusinessMembersAttributes {
  last_seen?: Date;
  location_id?: Pointer;
  active: boolean;
  user_tags?: Relation;
  business_id: Business;
  team_member_id: User;
  role: string;
}

export type IBusinessMembers = Object.ToJSON<BusinessMembersAttributes> & JSONBaseAttributes;

class BusinessMembers extends Parse.Object<BusinessMembersAttributes> {
  static className = 'business_members';

  constructor(init: BusinessMembersAttributes) {
    super(BusinessMembers.className, init);
  }

  static getPointer(objectId: string): Pointer {
    return createPointer(BusinessMembers.className, objectId);
  }
}

export default BusinessMembers;
